import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { combineLatestWith, distinctUntilChanged, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})
export class LoaderService {
  checkoutLoaderSubject = new BehaviorSubject<boolean>(false);
  inboundFreightLoaderSubject = new BehaviorSubject<boolean>(false);
  private sourceLoading$Subject: Subject<Observable<boolean>> = new ReplaySubject();
  private loadingSubject: Subject<boolean> = new Subject();
  private LOADER_DEFAULT_DURATION = 60000;

  /**
   * Emits `true` if at least one latest value of the registered observables is true.
   * Emits `false` if all latest values of the registered observables are false.
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public loading$ = (this.sourceLoading$Subject)
    .pipe(
      mergeMap(x => x),
      combineLatestWith(),
      map((loadingValues) => loadingValues.some((loading) => loading)),
      distinctUntilChanged()
    );

  constructor() {
    this.sourceLoading$Subject.next(this.loadingSubject);
  }

  public set loading(value: boolean) {
    this.setLoading(value);
  }

  public setLoading(value: boolean): void {
    if(value){
      setInterval(() => {
        this.setLoading(false);
      }, this.LOADER_DEFAULT_DURATION);
    }
    this.loadingSubject.next(value);
  }

  /**
   *
   * @param sourceLoading$ Should complete when the relevant component/service is destroyed
   */
  public register(sourceLoading$: Observable<boolean>) {
    this.sourceLoading$Subject.next(sourceLoading$);
  }
}
