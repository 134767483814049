import { Component, OnInit } from '@angular/core';
import { FeaturedPart } from 'entities/featured-part';
import { Observable, merge } from 'rxjs';
import { SourceLocationType } from 'services/logger.service';
import { Store } from '@ngrx/store';
import { AppState } from 'store/app-state';
import * as FeaturedPartsSelectors from 'store/featured-parts/featured-parts.selectors';

@Component({
  selector: 'featured-parts-home',
  templateUrl: './featured-parts-home.component.html'
})
export class FeaturedProductsHomeComponent implements OnInit {
  isLoading: boolean = true;

  public dataContext: Observable<boolean | FeaturedPart[]>;
  public featuredParts: FeaturedPart[];
  public featuredProductType: SourceLocationType = SourceLocationType.FeaturedProduct;

  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    const loadingObs = this.getFeaturedPartsLoadingObs();
    const featuredPartsObs = this.getFeaturedPartsObs();
    this.dataContext = merge(loadingObs, featuredPartsObs);
  }

  getFeaturedPartsLoadingObs(): Observable<boolean> {
    return this.store.select(FeaturedPartsSelectors.isLoadingFeaturedParts);
  }

  getFeaturedPartsObs(): Observable<FeaturedPart[]> {
    return this.store.select(FeaturedPartsSelectors.selectFeaturedParts);
  }
}
