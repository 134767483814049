import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AppState } from 'store/app-state';
import { MyDashboardState } from './my-dashboard.state';

export const myDashboardState = createFeatureSelector< MyDashboardState>('myDashboard');

export const showQuotesForAllBranches = createSelector(
  myDashboardState,
  (state) => state.showQuotesCountForAllBranches
);

export const getQuotesForBranchCode = createSelector(
  myDashboardState,
  (state) => state.branchCode
);
