import {Component, OnInit, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { tap, withLatestFrom } from 'rxjs/operators';
import { Permission } from 'entities/enums';
import { sortData } from 'helpers/sortData';
import { ToastService } from 'services/toast.service';
import { CustomerService } from 'services/customer.service';
import { TableService } from 'services/table.service';
import { ToastType } from 'entities/toast-type';
import { Store } from '@ngrx/store';
import { AppState } from 'store/app-state';
import * as BranchSelectors from 'store/branch/branch.selectors';
import * as CustomerSelectors from 'store/customer/customer.selectors';
import * as CustomerActions from 'store/customer/customer.actions';
import { Customer } from 'entities/customer';

@Component({
  selector: 'favourite-customers-modal',
  templateUrl: './favourite-customers-modal.component.html'
})
export class FavouriteCustomersModalComponent implements OnInit, OnDestroy {

  @ViewChild('searchFavouriteId', { static: true })
  searchRef: ElementRef;

  permission: any = Permission;
  readCustomers: any;
  favouriteCustomers: any;
  // pager object
  pager: any = {};
  // paged items
  pagedFavouriteCustomers: any[];
  filteredFavouriteCustomers: any[];
  filterKey: string = '';
  sortBy: string = '';
  sortAsc: boolean = false;
  branchCode: string;

  private subscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private toastService: ToastService,
    private pagerService: TableService,
    private favouritesService: CustomerService,
    private customerService: CustomerService,
    private activeModal: NgbActiveModal
  ) {
    const getFavouritesObs = this.store.select(CustomerSelectors.selectFavoriteCustomers)
      .pipe(
        withLatestFrom(this.store.select(BranchSelectors.selectedBranch)),
        tap(([favCustomers, branch]) => {
          this.branchCode = branch?.code;
          if (!favCustomers) {
            this.favouriteCustomers = [];
            this.filteredFavouriteCustomers = [];
          } else {
            this.favouriteCustomers = favCustomers;
            this.filteredFavouriteCustomers = favCustomers;
            this.setPage(1);
          }
        })
      );

    this.subscription = getFavouritesObs.subscribe();
  }

  ngOnInit() {
    setTimeout(() => this.searchRef.nativeElement.focus(), 0);
  }

  setPage(page: number) {
    if (page < 1 || (page > this.pager.totalPages && this.pager.totalPages > 0)) {
      return;
    }

    if (this.filteredFavouriteCustomers != undefined && this.filteredFavouriteCustomers != null) {
      // get pager object from service
      this.pager = this.pagerService.getPager(this.filteredFavouriteCustomers.length, page, 10);

      // get current page of items
      this.pagedFavouriteCustomers = this.filteredFavouriteCustomers.slice(this.pager.startIndex, this.pager.endIndex + 1);
    }
  }

  filter() {
    if (this.filterKey) {
      const filterKeyLowerCase = this.filterKey.toLowerCase();
      this.filteredFavouriteCustomers = this.favouriteCustomers.filter((e) => {
        return ((e.customerName && e.customerName.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.customerName2 && e.customerName2.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.customerNumber && e.customerNumber.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.streetAddress && e.streetAddress.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.city && e.city.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.state && e.state.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.postalCode && e.postalCode.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.phoneNumber && e.phoneNumber.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.accountManagerName && e.accountManagerName.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.accountManager && e.accountManager.toLowerCase().indexOf(filterKeyLowerCase) > -1));
      });

      this.setPage(1);
    } else {
      this.filteredFavouriteCustomers = this.favouriteCustomers;
      this.setPage(1);
    }
  }

  sortDataBy(sortBy: string) {
    if (this.sortBy == sortBy) {
      this.sortAsc = !this.sortAsc;
    } else {
      this.sortAsc = true;
    }
    this.sortBy = sortBy;
    this.filteredFavouriteCustomers = sortData(this.filteredFavouriteCustomers, this.sortBy, this.sortAsc);
    if (this.pager.pages && this.pager.pages.length) {
      this.setPage(this.pager.currentPage);
    } else {
      this.setPage(1);
    }
  }

  removeFavourites(selectedCustomer: Customer) {
    this.store.dispatch(CustomerActions.removeFavoriteCustomer({customerNumber: selectedCustomer.customerNumber}));
  }

  onSelectFavouriteCustomer(customerId) {
    this.customerService.validateCustomer(customerId, this.branchCode).then(response => {
      if (response.isValid) {
        this.activeModal.close(response.customer);
      } else {
        this.toastService.showToast('This customer account is not valid for this branch, or is no longer active. Please select a different customer account.', ToastType.Error);
      }
    }, (error) => { });
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
}
