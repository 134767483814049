import { Inject, Injectable, isDevMode } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import * as constants from 'app/app.constants';
import { AppInsightPart } from 'entities/app-insight-part';
import { environment } from 'environments/environment';
import { EMPTY } from 'rxjs';
import { catchError, first } from "rxjs/operators";
import { ConfigurationService } from './configuration.service';

export enum SeverityLevel {
  Verbose = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Critical = 4,
}

@Injectable({
  providedIn: "root"
})
export class LoggerService {
  public appInsights: ApplicationInsights;
  private maxMetricLength = 1024;

  constructor(
    @Inject(constants.APPLICATION_INSIGHTS_KEY_TOKEN) applicationInsightsKey: string,
    private configurationService: ConfigurationService
  ) {
    this.appInsights = new ApplicationInsights({ config: {
      instrumentationKey: applicationInsightsKey
    } });
    if(!environment.isRunningInUnitTest){
      this.appInsights.loadAppInsights();
    }
  }

  setServerTelemetry(): Promise<any> {
    return this.configurationService.configuration$
      .pipe(
        first(),
        catchError(() => EMPTY)
      )
      .toPromise()
      .then((configuration) => {
      });
  }

  trackPageView(){
    this.appInsights.trackPageView();
  }
  trackMetric(name: string, properties?: any): void {
    this.appInsights.trackMetric({
      name,
      properties,
      average: 0
    });
  }

  trackException(exception: Error, handledAt?: string, properties?: any): void {
    this.appInsights.trackException({
      exception,
      properties
    });
  }

  trackEvent(name: string, properties: any){
    this.appInsights.trackEvent({
      name,
      properties
    });
  }

  verbose(message: any, ...optionalParams: any[]) {
    this.write(SeverityLevel.Verbose, message, ...optionalParams);
  }

  info(message: any, ...optionalParams: any[]) {
    this.write(SeverityLevel.Information, message, ...optionalParams);
  }

  warn(message: any, ...optionalParams: any[]) {
    this.write(SeverityLevel.Warning, message, ...optionalParams);
  }

  error(message: any, ...optionalParams: any[]) {
    this.write(SeverityLevel.Error, message, ...optionalParams);
  }

  critical(message: any, ...optionalParams: any[]) {
    this.write(SeverityLevel.Critical, message, ...optionalParams);
  }

  write(severityLevel: SeverityLevel, message: any, ...optionalParams: any[]) {
    if (isDevMode()) {
      // IE11 doesn't like getting the logger as a property or variable
      switch (severityLevel) {
        case SeverityLevel.Verbose:
          console.log(message, ...optionalParams);
          break;
        case SeverityLevel.Information:
          console.info(message, ...optionalParams);
          break;
        case SeverityLevel.Warning:
          console.warn(message, ...optionalParams);
          break;
        case SeverityLevel.Error:
        case SeverityLevel.Critical:
          console.error(message, ...optionalParams);
          break;
      }
    }

    // Don't send verbose to App Insights
    if (severityLevel != SeverityLevel.Verbose) {
      const jsonMessage = JSON.stringify([message].concat(...optionalParams));
    }
  }
  getAppInsightParts(parts: any, remainingDataLength: number): string {
    if (!parts) {
      return '';
    }

    if (!(parts instanceof Array)) {
      parts = [parts];
    }

    const appInsightParts = parts.map((part): AppInsightPart => {
      return {
        partId: part.partId ? part.partId : '',
        partNumber: part.partNumber || part.rushPartNumber || '',
        partCost: part.cost ? part.cost : (part.yourPrice ? part.yourPrice : 0),
        yourPrice: part.verifiedPrice || part.adjustedPrice || part.finalPrice || part.yourPrice || part.listPrice || 0,
        quantity: part.quantity ? part.quantity : 0,
        quantityAvailable: part.quantityAvailable ? part.quantityAvailable : 0,
        verifiedPrice: part.verifiedPrice ? part.verifiedPrice : 0,
        isSpecialPricing: part.isSpecialPricing ? part.isSpecialPricing : false,
        vendorCode: part.vendorCode ? part.vendorCode : '',
        hotFlagCode: part.hotFlagCode ? part.hotFlagCode : '',
        responseReason: part.responseReason ? part.responseReason : ''
      }
    });

    const jsonString = JSON.stringify(appInsightParts);

    if (jsonString.length > (this.maxMetricLength - remainingDataLength)) {
      return this.getAppInsightPartNumbers(appInsightParts, (this.maxMetricLength - remainingDataLength));
    }

    return jsonString;
  }

  getAppInsightPartNumbers(parts: AppInsightPart[], maxLength: number): string {
    let partNumbers = parts
      .map((part) => part.partNumber);

    const jsonString = JSON.stringify(partNumbers);

    if (jsonString.length <= maxLength) {
      return jsonString;
    }

    // Remove part number values to make the json length fit
    const truncateMessage = '...';
    partNumbers = partNumbers
      .join(',')
      .substring(0, maxLength - (truncateMessage.length + '[]'.length))
      .split(',')
      .splice(-1, 1);

    partNumbers.push(truncateMessage);

    return JSON.stringify(partNumbers);
  }

  getAppInsightCoupon(coupon: any): any {
    let couponData = { id: coupon.couponId, code: coupon.couponCode, customerSegmentId: coupon.customerSegmentId };
    return JSON.stringify(couponData);
  }

  ///TrackMetricNameCollection START
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private metricKeyValue: { [metricKey: string]: string } = {
    CartComponent_removeCartItems: 'Cart\\RemoveCartItem',
    CartComponent_confirmPartsBuyout__KnownPartBuyout: 'Cart\\PartBuyout',
    CartComponent_sendHotFlag: 'Cart\\PartBuyout',
    CartComponent_checkout: 'Cart\\Checkout',
    CheckoutComponent_placeOrder_placeOrder_Quote: 'Checkout\\CreateQuote',
    CheckoutComponent_placeOrder_placeOrder_Order: 'Checkout\\CreateOrder',
    CheckoutComponent_removeItemsFromCart: 'Cart\\removeItemsFromCart',
    CheckoutComponent_removeCouponCartItems: 'Cart\\RemoveCoupon',
    PartSearch_CustomerComponent_customerSearch_getCustomers: 'Home\\CustomerSearch',
    HeaderSearch_CustomerComponent_customerSearch_getCustomers: 'SiteCustomerSearch',
    OrderConfirmationComponent_downloadInvoice__Billing: 'OrderConfirmationComponent_downloadInvoice__Billing',
    OrderConfirmationComponent_getPickTicket__PrintPickTicket: 'OrderConfirmationComponent_getPickTicket__PrintPickTicket',
    AlternateRelatedComponent_addToCart: 'AlternateRelatedComponent_addToCart',
    PartsDetailsComponent_addToCart: 'PartsDetailsComponent_addToCart',
    NationalInventoryModalComponent_addToCart__STO: 'NationalInventoryModalComponent_addToCart__STO',
    PartsComponent_getParts_getParts: 'PartsComponent_getParts_getParts',
    HeaderSearchComponent_partSearch_getPartCount: 'HeaderSearchComponent_partSearch_getPartCount',
    HeaderSearchComponent_removeCartItems: 'HeaderSearchComponent_removeCartItems',
    CustomerModalComponent_onCustomerSelect: 'CustomerModalComponent_onCustomerSelect',
    FavouriteCustomersModalComponent_onSelectFavouriteCustomer: 'FavouriteCustomersModalComponent_onSelectFavouriteCustomer',
    PartBuyoutModalComponentAddToCartUnknownPartBuyout: 'PartBuyoutModalComponent_addToCart__UnknownPartBuyout',
    PartsSearchComponent_partSearch_getPartCount: 'PartsSearchComponent_partSearch_getPartCount',
    SuggestedSellingComponent_addToCart: 'SuggestedSellingComponent_addToCart',
    SuggestedSellingComponent_declinePart: 'SuggestedSellingComponent_declinePart',
    SuggestedSellingComponent_getParts: 'SuggestedSellingComponent_getParts'
  };
  public getMetricValue(metricKey: string): string {
    return this.metricKeyValue[metricKey];
  }
  ///TrackMetricNameCollection END
}

export enum SourceLocationType {
  Home,
  PartSearch,
  HeaderSearch,
  PartSearch_CustomerSearch,
  HeaderSearch_CustomerSearch,
  PartSearch_AdvancedCustomerSearch,
  HeaderSearch_AdvancedCustomerSearch,
  PartSearch_PartSearch,
  HeaderSearch_PartSearch,
  PartListResult,
  PartListRefineSearch,
  PartDetailResult,
  AlternateParts,
  RelatedParts,
  Cart,
  Checkout,
  OrderConfirmation,
  FavouriteCustomer,
  FavoritePart,
  FavouriteVendor,
  PriceVerifyCartLineItem,
  PriceVerifyAllCartLineItems,
  PartDetailPriceVerify,
  CartCloseReason,
  PartsResult,
  SuggestedSelling,
  PartBuyoutModalComponentAddToCartUnknownPartBuyout,
  CartItemCloseReason,
  Branch,
  Branch_CustomerSearch,
  FeaturedProduct
}
