<div class="p-2 gy-2">
  <form #searchForm="ngForm" [ngClass]="{ 'was-validated': !searchForm.pending }" (ngSubmit)="updateSearchTerm(searchForm.value.searchTerm)">
    <div class="input-group">
      <input type="text" class="form-control" name="searchTerm" id="partSearchModalInput" [ngModel]="initialSearchTerm" required />
        <button class="btn btn-secondary" type="submit">
          <i class="fas fa-search" aria-hidden="true"></i>
        </button>
    </div>
  </form>
  <h5 *ngIf="loading">Loading part(s) ...</h5>
  <ng-container *ngLet="partsQuery$ | async as partsQuery">
    <part-list [displayType]="displayType" *ngIf="partsQuery && !loading" [partsData]="partsQuery.partsData" [partSearchTerm]="partsQuery.searchCriteria.partSearchTerm"
      [currentPage]="partsQuery.searchCriteria.pageNumber" [pageSize]="partsQuery.searchCriteria.pageSize" [branch]="branch"
      requireExtended [highlightPart]="part"
      (updatePage)="updatePage($event)" (updateFacets)="updateRefinements($event)" (selectPart)="selectPart($event)">
    </part-list>
  </ng-container>
</div>
