import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "bctDepartment",
  standalone: true,
})
export class BctDepartmentPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case "14":
        return "Body Shop";
      case "13":
        return "Mobile";
      case "12":
        return "Service";
      default:
        return "";
    }
  }
}
