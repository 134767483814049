<div *ngIf="part.isRushPart && part.isExtendedToBranch; else emptySpaceForAddToCart" class="px-3 py-1 d-flex justify-content-between align-items-end">
  <div>
    <div class="strong">Quantity</div>
    <div class="input-group spinner m-0" style="max-width: 110px">
      <part-quantity    
        idModifier="{{'partCardAddToCart'+idSuffix}}"
        [value]="part.quantity"
        (onChangeQuantity)="changeQuantity($event)"
      ></part-quantity>
    </div>
  </div>
  <div>
    <button id="partCardAddToCart_button_AddToCart{{idSuffix}}"
      type="button" class="btn btn-primary btn-sm text-nowrap" (click)="addToCart(part)"
      [disabled]="false">{{part.quantityAvailable > 0 ? "Add to Cart" :
      part.quantityAvailable == 0 && !commonDataService.User?.isInternal ? "Add to Cart":"Order"}}</button>
  </div>
</div>
<ng-template class="px-3 py-1 d-flex justify-content-between align-items-end" #emptySpaceForAddToCart>
  <div style="height: 56.5px"></div>
</ng-template>
