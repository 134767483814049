import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { sortData } from 'helpers/sortData';
import { TableService } from 'services/table.service';

@Component({
  selector: 'vendors-modal',
  templateUrl: './vendors-modal.component.html'
})
export class VendorsModalComponent implements OnInit {

  @Input() vendors: any;

  // pager object
  pager: any = {};
  // paged items
  pagedAdvancedVendors: any[];
  filteredAdvancedVendors: any[];
  filterKey: string = '';
  sortBy: string = '';
  sortAsc: boolean = false;

  constructor(
    private pagerService: TableService,
    private activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.filteredAdvancedVendors = this.vendors;
    this.setPage(1);
  }

  setPage(page: number) {
    if (page < 1 || (page > this.pager.totalPages && this.pager.totalPages > 0)) {
      return;
    }
    // get pager object from service
    this.pager = this.pagerService.getPager(this.filteredAdvancedVendors.length, page, 6);

    // get current page of items
    this.pagedAdvancedVendors = this.filteredAdvancedVendors.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  filter() {

    if (this.filterKey) {
      const filterKeyLowerCase = this.filterKey.toLowerCase();
      this.filteredAdvancedVendors = this.vendors.filter(function (e) {
        return ((e.name && e.name.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.name2 && e.name2.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.number && e.number.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.address && e.address.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.city && e.city.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.state && e.state.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.zipCode && e.zipCode.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.currency && e.currency.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.status && e.status.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.phone && e.phone.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.terms && e.terms.toLowerCase().indexOf(filterKeyLowerCase) > -1));

      });

      this.setPage(1);
    } else {
      this.filteredAdvancedVendors = this.vendors;
      this.setPage(1);
    }
  };

  sortDataBy(sortBy: string) {
    if (this.sortBy == sortBy) {
      this.sortAsc = !this.sortAsc;
    } else {
      this.sortAsc = true;
    }

    this.sortBy = sortBy;

    this.filteredAdvancedVendors = sortData(this.filteredAdvancedVendors, this.sortBy, this.sortAsc);

    if (this.pager.pages && this.pager.pages.length) {
      this.setPage(this.pager.currentPage);
    } else {
      this.setPage(1);
    }
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }

  onVendorSelect(vendor: any): void {
    this.activeModal.close(vendor);
  }
}
