<ng-container *ngLet="{isInternal: isInternal$ | async} as vm">
  <ng-container *ngIf="!vm.isInternal">
    <div class="dashboard-content heading-search">
      <div class="title-of-box-hold">
        <h5 class="text-white strong my-1 ms-1">Order History</h5>
      </div>
    </div>
  </ng-container>
  <div class="row mt-3">
    <div class="col-md-3">
      <find-order-panel [panelType]="'order'" (search)="searchOrders($event)" (find)="findOrder($event)"></find-order-panel>
    </div>
    <div class="col-9">
      <ng-container *ngIf="orders$ | async as orders">
        <table *ngIf="!orders.length">No records found.</table>
        <table class="table table-striped mt-1" *ngIf="orders.length">
          <thead>
            <tr>
              <th>Date Created</th>
              <th>Order Number</th>
              <ng-container *ngIf="vm.isInternal">
                <th>Customer</th>
                <th>Customer Number</th>
                <th>Ship To Number</th>
              </ng-container>
              <th>Branch</th>
              <th>Order Status</th>
              <th *ngIf="orderHistoryDeliveryEnabled$ | async" id="orderHistory_th_deliveryStatus">
                Delivery Status
                <abbr title="Click on Order Number for additional delivery information">
                  <i class="fas fa-info-circle"></i>
                </abbr>
              </th>
              <th>PO Number</th>
              <th>Sales Advisor Name</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let orderItem of orders; index as i">
              <td id="orderHistory_td_dateCreated_{{ i }}">
                {{ orderItem.dateCreated | date: 'MM/dd/yyyy': 'UTC'}}
              </td>
              <td id="orderHistory_td_orderNumber_{{ i }}">
                <a class="hyperlink" href="javascript:void(0)" (click)="showDetails(orderItem.orderNumber)">{{ orderItem.orderNumber }}</a>
              </td>
              <ng-container *ngIf="vm.isInternal">
                <td id="orderHistory_td_customerName_{{ i }}">
                  {{ isCashCustomer(orderItem.customerNumber) ? "RUSH CASH CUSTOMER": orderItem.name1 }}
                </td>
                <td id="orderHistory_td_customerNumber_{{ i }}">
                  {{ orderItem.customerNumber }}
                </td>
                <td id="orderHistory_td_customerNumber_{{ i }}">
                  {{ orderItem.shipToNumber }}
                </td>
              </ng-container>
              <td id="orderHistory_td_branch_{{ i }}">{{ orderItem.branch }}</td>
              <td id="orderHistory_td_orderStatus_{{ i }}">
                {{ OrderItemStatusEnum[orderItem.status] || OrderItemStatusEnum[0] | titlecase }}
              </td>

              <td *ngIf="orderHistoryDeliveryEnabled$ | async" id="orderHistory_td_deliveryStatus_{{ i }}">
                <ng-container *ngIf="isFreightShipped(orderItem) && !orderItem.freightDeliveryStatus?.length">
                  <abbr>Shipped</abbr>
                </ng-container>
                <ng-container *ngIf="isFreightShipped(orderItem) && orderItem.freightDeliveryStatus?.length > 0 ">
                  {{orderItem.freightDeliveryStatus}}
                </ng-container>
                <ng-container *ngIf="!isFreightShipped(orderItem)" [ngSwitch]="orderItem.deliveryStatus">
                  <ng-container *ngSwitchCase="'none'">
                    <abbr *ngIf="orderItem.status !== OrderItemStatusEnum.invoiced && orderItem.deliveryOption === 'notApplicable' && orderItem.deliveryOption !== 'freight'" title="Not yet invoiced">N/A</abbr>
                    <abbr *ngIf="orderItem.status !== OrderItemStatusEnum.invoiced && orderItem.deliveryOption !== 'notApplicable' && orderItem.deliveryOption !== 'freight'" title="Not yet invoiced">Preparing Order</abbr>
                    <abbr *ngIf="orderItem.status === OrderItemStatusEnum.invoiced && orderItem.deliveryOption !== 'freight' && orderItem.deliveryOption !== 'rtcDelivery'" title="Order does not include a Rush Truck Centers Delivery Status">N/A</abbr>
                    <abbr *ngIf="orderItem.status === OrderItemStatusEnum.invoiced && orderItem.deliveryOption === 'rtcDelivery'" title="Order does not include a Rush Truck Centers Delivery Status">Preparing Order</abbr>
                    <abbr *ngIf="orderItem.status !== OrderItemStatusEnum.invoiced && orderItem.deliveryOption === 'freight'" title="Order does not include a Rush Truck Centers Delivery Status">Preparing Order</abbr>
                    <abbr *ngIf="orderItem.status === OrderItemStatusEnum.invoiced && orderItem.deliveryOption === 'freight'" title="Order does not include a Rush Truck Centers Delivery Status">Shipped</abbr>
                  </ng-container>
                  <span *ngSwitchCase="'preparing'">Preparing Order</span>
                  <span *ngSwitchCase="'enRoute'">{{ isAnyRtcDelivery(orderItem) ? 'Out for Delivery' : 'Shipped' }}</span>
                  <ng-container *ngSwitchCase="'partial'">
                    <abbr *ngLet="orderItem.orderNumber | orderLineItems$ | async as orderLineItems"
                      title="{{ orderLineItems | deliveredCount }} of {{ orderLineItems | deliveryCount }} {{ isAnyRtcDelivery(orderItem) ? 'Delivered' : 'Shipped' }}">
                      {{ isAnyRtcDelivery(orderItem) ? 'Partially Delivered' : 'Partially Shipped' }}
                    </abbr>
                  </ng-container>
                  <ng-container *ngSwitchCase="'delivered'">
                    <abbr *ngLet="orderItem.orderNumber | orderLineItems$ | async as orderLineItems"
                      title="{{ orderLineItems | deliveredCount }} of {{ orderLineItems | deliveryCount }} Delivered">
                      Delivered
                    </abbr>
                  </ng-container>
                  <abbr *ngSwitchCase="'notDelivered'" title="Order canceled, declined, or not able to be delivered">Not to be Delivered</abbr>
                </ng-container>
              </td>

              <td id="orderHistory_td_poNumber_{{ i }}">
                {{ orderItem.customerPoNumber }}
              </td>
              <td id="orderHistory_td_salesAdvisor_{{ i }}">
                {{ orderItem.customerAdvisorLastName }}, {{ orderItem.customerAdvisorFirstName }}
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </div>
  </div>
  <pagination
    [currentPage]="currentPageNumber"
    idPrefix="orderHistory"
    [totalCount]="orderCount$"
    [pageSize]="pageSize"
    (currentPageChange)="pageChange($event)">
  </pagination>
</ng-container>
