function getStringValue(value) {
  return value != null
    ? String(value)
    : null;
}

function compare(a, b, allNumbers: boolean) {
  if (allNumbers) {
    const aNumber = Number(a);
    const bNumber = Number(b);
    return aNumber - bNumber;
  }

  const aString = getStringValue(a); 
  const bString = getStringValue(b);
  return aString?.localeCompare(bString, undefined, { sensitivity: 'base' })  // ignore upper and lowercase
    ?? (bString === null ? 0 : 1);
}

export function sortData<T, R = any>(data: T[], sortBy: string | ((item: T) => R), sortAsc: boolean = true): T[] {
  if (!data) { return data; }

  const getPropertyValue = item => typeof sortBy === 'string' ? item[sortBy] : sortBy(item);
  
  const allNumbers = data
    .every((item) => !isNaN(Number(getPropertyValue(item))));
    
  return data
    .sort((a, b)  => {
      const aProperty = getPropertyValue(a);
      const bProperty = getPropertyValue(b);

      return sortAsc ? compare(aProperty, bProperty, allNumbers) : compare(bProperty, aProperty, allNumbers);
    });
}