import { Injectable } from "@angular/core";
import { FeatureFlagApiService } from "./feature-flag-api.service";
import { LoggerService } from "./logger.service";

type FeatureFlag ="ChangeControl.NotForRelease"
  | "FeatureFlag.CustomerNotes.Enabled"
  | "Five9Chat"
  | "Hotflag.VinNumber"
  | "ProactiveChat"
  | "ShippingManagement"
  | "Analytics.FrontendApplicationInsights"
  | "CODeliveryAndBagFee";

@Injectable({
  providedIn: "root"
})
export class FeatureFlagService {
  public featureFlags: any;

  constructor(
    private featureFlagApiService: FeatureFlagApiService,
    private loggerService: LoggerService
  ) { }

  initialize(): Promise<any> {
    return this.featureFlagApiService.getFeatureFlags()
      .toPromise()
      .then((flags) => {
        this.featureFlags = flags;
        this.loggerService.verbose("Feature Flags:", this.featureFlags);
      });
  }

  isFeatureActive(featureName: FeatureFlag): boolean {
    // If no feature name provided, return true
    if (!featureName) {
      return this.featureFlags[featureName];
    }
    if (!this.featureFlags || !Object.keys(this.featureFlags).length) {
      return false;
    }
    return this.featureFlags[featureName]?.isEnabled;
  }
}
