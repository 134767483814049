import { Component, Input } from '@angular/core';
import { ICreatePartsResult } from 'entities/interfaces';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create-parts-result-modal',
  templateUrl: './create-parts-result-modal.component.html',
  styleUrls: ['./create-parts-result-modal.component.css']
})
export class CreatePartsResultModalComponent {
  @Input() models: ICreatePartsResult[];

  constructor(public activeModal: NgbActiveModal) { }

  closeModal() {
    this.activeModal.close(this.models);
  }
}
