import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { CurrencyCode } from 'entities/currency-code';
import { UserSalesMetrics } from 'entities/user-sales-metrics';
import { Observable, Subject } from 'rxjs';
import { map, merge } from 'rxjs/operators';
import { UserNotificationService } from 'services/user-notification.service';
import { AppState } from 'store/app-state';
import * as BranchSelectors from "store/branch/branch.selectors";

@Component({
  selector: 'header-sales',
  templateUrl: './header-sales.component.html'
})
export class HeaderSalesComponent {
  public salesMetrics$: Observable<UserSalesMetrics>;
  public closeSubject: Subject<{}> = new Subject();
  public currencyCode$: Observable<CurrencyCode> = this.store.select(BranchSelectors.selectCurrencyCode);

  constructor(
    public userNotificationService: UserNotificationService,
    private store: Store<AppState>
  ) {
    this.salesMetrics$ = this.userNotificationService.salesMetrics$
      .pipe(
        merge(this.closeSubject.pipe(map(() => null)))
      );
  }
}
