import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { ConfigurationService } from 'services/configuration.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'pc-footer',
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FooterComponent {
  copyrightYear: string = new Date().getFullYear().toString();
  public appVersion$: Observable<string> = this.configurationService.configuration$
    .pipe(
      map((configuration) => configuration.appVersion)
    );

  constructor(
    private activatedRoute: ActivatedRoute,
    private configurationService: ConfigurationService,
    private location: Location,
    private router: Router
  ) { }

  feedback() {
    this.router.navigate(['misc/feedback'],
      {
        queryParams: {
          partSearchTerm: this.activatedRoute.snapshot.queryParams['searchTerm'],
          previousPageUrl: this.location.path()
        }
      });
  }
}
