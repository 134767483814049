<button id="FavouriteVendorsModal_Button_Close" type="button" class="close" data-dismiss="modal" aria-label="Close" tabindex="-1" (click)="closeModal()">
  <span aria-hidden="true">&times;</span>
</button>
<div class="modal-header">
  <h4 class="modal-title">Favorite Vendors</h4>
  <div class="input-group w-33">
      <input id="FavouriteVendorsModal_Text_SearchFavouriteVendors" type="text" class="form-control" #search placeholder="Search Your Favorite Vendors..." [(ngModel)]="filterKey" (keyup)="filter()">
      <span class="input-group-text">
        <i class="fas fa-search" aria-hidden="true"></i>
      </span>
  </div>
</div>
<div id="FavouriteVendorsModal_Div_IsLoading" class="modal-body" *ngIf="!favouriteVendors">
  <div>Loading favorite vendor(s) ...</div>
</div>
<div id="FavouriteVendorsModal_HasLoaded" class="modal-body" *ngIf="favouriteVendors">
  <div *ngIf="!favouriteVendors.length">
    <h3 id="FavouriteVendorsModal_H3_NoRecordsFound" *ngIf="readVendor == 'Read Vendors'">No records found.</h3>
    <h3 id="FavouriteVendorsModal_H3_UserDoesNotHaveAccess" *ngIf="readVendor != 'Read Vendors'">User does not have access to this feature.</h3>
  </div>
  <div *ngIf="favouriteVendors.length" class="favorites-customer-list row">
    <div id="FavouriteVendorsModal_Div_FavouriteVendor_{{i}}" class="col-12 col-md-4 p-2" *ngFor="let favourite of pagedFavouriteVendors; let i = index" (click)="onSelectFavouriteVendor(favourite);">
      <a id="FavouriteVendorsModal_A_RemoveFavouriteVendor_{{i}}" class="text-danger position-absolute" style="top:15px; right:15px;" href="javascript:void(0)" (click)="removeFavouriteVendors(favourite); $event.stopPropagation()">
        <span aria-hidden="true" class="far fa-trash-alt fa-2x"></span>
      </a>
      <div class="list-grid">
        <div style="min-height: 108px;">
          <div id="FavouriteVendorsModal_Div_FavouriteVendorName_{{i}}" class="text-truncate strong" title="{{favourite.name}}">{{favourite.name}}</div>
          <div id="FavouriteVendorsModal_Div_FavouriteVendorName2_{{i}}" class="text-truncate" title="{{favourite.name2}}" *ngIf="favourite.name2 | trim">{{favourite.name2}}</div>
          <div id="FavouriteVendorsModal_Div_FavouriteVendorNumber_{{i}}" *ngIf="favourite.number | trim">{{favourite.number | customerNumber}}</div>
          <div id="FavouriteVendorsModal_Div_FavouriteVendorAddress_{{i}}" class="text-truncate" title="{{favourite.address}}" *ngIf="favourite.address | trim">{{favourite.address}}</div>
          <div id="FavouriteVendorsModal_Div_FavouriteVendorCityStateZip_{{i}}" class="text-truncate" title="{{favourite.city==null?'': favourite.city+','}} {{favourite.state}} {{favourite.zipCode}}"
            *ngIf="(favourite.city | trim) || (favourite.state | trim) || (favourite.zipCode | trim)">{{favourite.city==null?'':
            favourite.city+','}} {{favourite.state}} {{favourite.zipCode}}</div>
          <div id="FavouriteVendorsModal_Div_FavouriteVendorPhone_{{i}}" *ngIf="favourite.phone | trim">{{favourite.phone}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <nav id="FavouriteVendorsModal_Nav_Navigation" class="d-flex justify-content-end align-items-center" aria-label="Page navigation" *ngIf="pager.pages && pager.pages.length">
    <div>
      <span class="pagination-result">Showing {{pager.startIndex + 1}} - {{pager.endIndex + 1}} of {{pager.totalItems}} Results</span>
    </div>
    <div>
      <ul class="pagination ">
        <li class="page-item" [ngClass]="{disabled:pager.currentPage == 1}">
          <a id="FavouriteVendorsModal_A_FirstPage" class="page-link" aria-label="First" (click)="setPage(1)"><span aria-hidden="true">&laquo;</span></a>
        </li>
        <li class="page-item" [ngClass]="{disabled:pager.currentPage == 1}">
          <a id="FavouriteVendorsModal_A_PreviousPage" class="page-link" aria-label="Previous" (click)="setPage(pager.currentPage - 1)"><span aria-hidden="true">&lsaquo;</span></a>
        </li>
        <li *ngFor="let page of pager.pages" class="page-item" [ngClass]="{active:pager.currentPage == page}">
          <a id="FavouriteVendorsModal_A_CurrentPage" class="page-link" (click)="setPage(page)">{{page}}</a>
        </li>
        <li class="page-item" [ngClass]="{disabled:pager.currentPage == pager.totalPages}">
          <a id="FavouriteVendorsModal_A_NextPage" class="page-link" aria-label="Next" (click)="setPage(pager.currentPage + 1)"><span aria-hidden="true">&rsaquo;</span></a>
        </li>
        <li class="page-item" [ngClass]="{disabled:pager.currentPage == pager.totalPages}">
          <a id="FavouriteVendorsModal_A_LastPage" class="page-link" aria-label="Last" (click)="setPage(pager.totalPages)"><span aria-hidden="true">&raquo;</span></a>
        </li>
      </ul>
    </div>
  </nav>
</div>
