export enum selectedPayment {
  StoredCC = 'StoredCC',
  AddedCC = 'AddedCC',
  CCI2P = 'CCI2P',
  BillTo = 'BillTo'

}

export enum billToType {
  CASH_CCOnline = 'CASH-CCOnline',
  CASH_CCInHand = 'CASH-CardInHand',
  EMPTY = '',
}

export enum i2pType {
  Email = 'Email',
  SMS = 'Sms'
}

export enum addCreditCardTransactionResult {
  Added = 'Added',
  Failed = 'Failed',
  Unknown = ''
}
