import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Buffer } from 'buffer';

export interface HttpJsonPart {
  type: 'json';
  value: any;
};

export interface HttpBlobPart {
  type: 'blob';
  filename: string;
  blob: Blob;
}

export type HttpPart = 
  | HttpJsonPart
  | HttpBlobPart;

/**
 * Would prefer not to use this. Do not mix HTTP requests for data and files. Use HTTP Headers for file metadata.
 * Started with https://github.com/freesoftwarefactory/parse-multipart/blob/master/multipart.js
 */
@Injectable({
  providedIn: 'root'
})
export class MultipartService {
  getParts(response: HttpResponse<ArrayBuffer>) {
    const contentType = response.headers.get('content-type');
    const boundary = this.getBoundary(contentType);
    const bodyBuffer = Buffer.from(response.body);
    return this.parse(bodyBuffer, boundary);
  }

  getBoundary(header: string) {
    // expecting e.g. `application/mixed; boundary="12345"`
    const items = header.split(';');
    if(items) {
      for (const item of items) {
        const trimmed = (new String(item)).trim();
        if(trimmed.indexOf('boundary') >= 0){
          var k = trimmed.split('=');
          return (new String(k[1])).trim().replace(/"/g, '');
        }
      }
    }
    return '';
  }

  parse(multipartBodyBuffer: Buffer, boundary: string): HttpPart[] {
    const boundaryBuffer = Buffer.from(`--${boundary}\r\n`);
    const newLineBuffer = Buffer.from("\r\n");

    return this.bufferMatches(multipartBodyBuffer, boundaryBuffer)
      // separate into part buffers by boundaries
      .map((boundaryIndex, index, arr) => ({
        startIndex: boundaryIndex + boundaryBuffer.length,
        // part data ends with a \r\n preceding the next boundary
        // final part has an end-boundary followed by an extra '--' before the final \r\n
        endIndex: arr.length > (index + 1)
          ? arr[index + 1] - newLineBuffer.length
          : multipartBodyBuffer.length - boundaryBuffer.length - 2 - newLineBuffer.length
      }))
      .map(({ startIndex, endIndex}): Buffer => 
        multipartBodyBuffer.slice(startIndex, endIndex)
      )
      // get headers and data buffer for each part
      .map((partBuffer): { headers: { [key: string]: string }, dataBuffer: Buffer } => {
        const lineIndexes = this.bufferMatches(partBuffer, newLineBuffer)
          .map((newLineIndex, index, arr) => ({
            startIndex: index > 0 ? arr[index - 1] + newLineBuffer.length : 0,
            endIndex: newLineIndex
          }));
        const endOfHeaders = lineIndexes.findIndex(({ startIndex, endIndex}) => startIndex === endIndex);
        // split header lines into key-value pairs
        const headers = lineIndexes
          .slice(0, endOfHeaders)
          .map(({ startIndex, endIndex }) => partBuffer
            .slice(startIndex, endIndex)
            .toString()
            .split(':')
            .map((s) => s.trim())
          )
          .map(([headerKey, headerValue]) => [headerKey.toLowerCase(), headerValue])
          .reduce((acc, [headerKey, headerValue]) => ({
            ...acc,
            [headerKey]: acc.hasOwnProperty(headerKey)
              ? `${acc[headerKey]},${headerValue}`
              : headerValue
          }), {});
        
        return { headers, dataBuffer: partBuffer.slice(lineIndexes[endOfHeaders].endIndex + newLineBuffer.length) };
      })
      // transform to HttpPart
      .map(({ headers, dataBuffer }): HttpPart => {
        // expecting e.g. `application/json; charset=utf-8`
        const type = headers['content-type']
          .split(';')[0]
          .trim();
        if (type === 'application/json') {
          const json = dataBuffer.toString();
          const value = JSON.parse(json);
          return { type: 'json', value };
        }
        else {
          const contentDisposition = headers['content-disposition'];
          // expecting e.g. `attachment; filename=Invoice.html`
          const dispositions = contentDisposition
            ?.split(';')
            ?.map((s1) => s1
              .trim()
              .split('=')
              .map((s2) => s2.trim())
            )
            ?.reduce((acc, pair) => ({ ...acc, [pair[0].toLowerCase()]: pair[1] }), {})
            || {};
          const filename = dispositions['filename']?.replace(/"/g, '');
          const blob = new Blob([dataBuffer], { type });
          return { type: 'blob', filename, blob };
        }
      });
  }

  private bufferMatches(source: Buffer, target: Buffer): number[] {
    return source
      .reduce((matches, _, index) => {
        if (source.length - index > target.length && source.compare(target, 0, target.length - 1, index, index + target.length - 1) === 0) {
          matches.push(index);
        }
        return matches;
      }, []);
  }
}
