<div class="modal-header">
    <h4 class="modal-title">{{headerTitleText}}</h4>
    <button id="CloseReasonModal_Button_Close" type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
</button>
  </div>
<div class="modal-body">
    <div *ngFor="let reason of closeReasons; let i = index" class="ps-4">
        <input id="CloseReasonModal_RadioButton_Reason_{{i}}" class="form-check-input" type="radio"  [(ngModel)]="closeReasonId" name="closeReasonOptions" value="{{reason.reasonId}}"  required />
        <label for="CloseReasonModal_RadioButton_Reason_{{i}}"><span class="ms-2"></span>{{reason.reasonText}}</label>
    </div>
  </div>
  <div class="modal-footer">
    <button id="CloseReasonModal_Button_Submit" type="button" class="btn btn-default yellow-btn" [disabled]="!closeReasonId" (click)="submit()">Submit</button>
</div>
