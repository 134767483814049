import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Discount, DiscountProgram } from 'entities/discount';
import { Store } from '@ngrx/store';
import { AppState } from 'store/app-state';
import * as BranchSelectors from "store/branch/branch.selectors";
import { PartPriceOverride } from 'entities/parts/part-price-override';
import { CurrencyCode } from 'entities/currency-code';

type DiscountProgramMap = { [program in DiscountProgram]?: Discount };

@Component({
  selector: 'coupons',
  templateUrl: 'coupons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CouponsComponent {
  private partSubject: Subject<PartPriceOverride> = new BehaviorSubject(null);
  @Input('part') set partInput(value: PartPriceOverride) {
    this.partSubject.next(value);
  }
  public part$: Observable<PartPriceOverride> = this.partSubject.asObservable();
  public currencyCode$: Observable<CurrencyCode> = this.store.select(BranchSelectors.selectCurrencyCode);

  private programDiscountsSubject: Subject<DiscountProgramMap> = new BehaviorSubject<DiscountProgramMap>(null);
  @Input('programDiscounts') set programDiscountsInput(value: DiscountProgramMap) {
    this.programDiscountsSubject.next(value);
  }
  public programDiscountEntities$: Observable<DiscountProgramMap> = this.programDiscountsSubject
    .pipe(
      map((programDiscounts) => programDiscounts ?? {})
    );
  public allProgramDiscounts$: Observable<{ program: string, discounts: Discount }[]> = this.programDiscountEntities$
    .pipe(
      map((entities: DiscountProgramMap) => Object.entries(entities)),
      map((entries: [string, Discount][]) => entries.map(([program, discounts]) => ({ program, discounts })))
    );
  public discountTotal$ = this.allProgramDiscounts$
    .pipe(
      map((allProgramDiscounts: { program: string, discounts: Discount }[]) => allProgramDiscounts
        .flatMap(({ discounts }: { discounts: Discount }) => discounts)
        .reduce((sum: number, discount: Discount) => sum + discount.amount, 0)
      )
    );

  idSuffix: string;
  @Input('idSuffix') set _idSuffix(value: string) {
    if (value) {
      this.idSuffix = `_${value}`;
    }
  }

  public couponTotal$ = combineLatest(this.part$, this.discountTotal$)
    .pipe(
      map(([part, discountTotal]: [PartPriceOverride, number]) => part.couponTotal + discountTotal)
    );
  public finalPrice$ = combineLatest(this.part$, this.couponTotal$)
    .pipe(
      map(([part, couponTotal]: [PartPriceOverride, number]) => part.lowestPrice - couponTotal)
    );

    constructor(private store: Store<AppState>){}
}
