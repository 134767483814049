import { createAction, props } from '@ngrx/store';
import { CreateBillingRequest } from 'entities/create-billing-request';
import { DeviceResponse } from 'entities/order-confirmation/payment-device';
import { SelectedPartBinLocation } from 'entities/selected-part-bin-location';
import { SimulateTransferOrderRequest } from 'entities/simulate-transfer-order/simulate-transfer-order-request';
import { OrderConfirmationTotal } from './order-confirmation.state';

export const getPartBinLocations = createAction(
  '[Order Confirmation] Get Part Bin Locations',
  props<SimulateTransferOrderRequest>()
);
export const setPartBinLocations = createAction(
  '[Order Confirmation] Set Part Bin Locations',
  props<{partBins: {[key: string]: any[]}}>()
);

export const selectPartBinLocations = createAction(
  '[Order Confirmation] Set Part Bin Locations',
  props<{partBins: SelectedPartBinLocation[]}>()
);

export const setOrder = createAction(
  '[Order Confirmation] Set Order',
  props<{order: any}>()
);

export const setCreateBillingVisible = createAction(
  '[Order Confirmation] Set Create Billing Visible',
  props<{createBillingEnabled: boolean}>()
);

export const setCreateBillingEnabled = createAction(
  '[Order Confirmation] Set Create Billing Enabled',
  props<{ createBillingEnabled: boolean }>()
);

export const readyToPickButtonPressed = createAction(
  '[Order Confirmation] Ready To Pick Button Pressed'
);

export const readyToPickSuccess = createAction(
  '[Order Confirmation] Ready To Pick Success'
);

export const clearOrderConfirmationData = createAction(
  '[Order Confirmation] Clear Data'
);

export const createBillingButtonPressed = createAction(
  '[Order Confirmation] Create Billing Button Pressed',
  props<{request: CreateBillingRequest }>()
);

export const getInvoiceButtonPressed = createAction(
  '[Order Confirmation] Get Invoice Button Pressed'
);

export const createBillingDownloadInvoice = createAction(
  '[Order Confirmation] Create Billing Download Invoice',
  props<{ request: CreateBillingRequest }>()
);

export const displayCustomerReviewForm = createAction(
  '[Order Confirmation] Display Customer Review Form',
  props<{request: CreateBillingRequest}>()
);

export const displayDeviceList = createAction(
  '[Order Confirmation] Display Device List',
  props<{request: CreateBillingRequest}>()
);

export const displayDeviceListFailure = createAction(
  '[Order Confirmation] Display Device List Failed',
  props<{error: any}>()
);

export const unknownPaymentMessageReceived = createAction(
  '[Order Confirmation] Unknown Status Payment Message Recieved',
  props<{eventInfo: DeviceResponse}>()
);

export const failingDevicePaymentMessageReceived = createAction(
  '[Order Confirmation] Failed Payment Message Recieved',
  props<{eventInfo: DeviceResponse}>()
);

export const acceptedDevicePaymentMessageReceived = createAction(
  '[Order Confirmation] Successful Payment Message Recieved',
  props<{eventInfo: DeviceResponse}>()
);

export const setOrderConfirmationTotal = createAction(
  '[Order Confirmation] Set Order Confirmation Total Amount',
  props<{orderConfirmationTotal: OrderConfirmationTotal}>()
);
