import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Note, CreateNoteRequest } from 'entities/notes';

@Injectable({
  providedIn: "root"
})
export class NotesService {
  constructor(
    private http: HttpClient
  ) { }

  getNotes(partNumber: string, noteId?: number): Observable<{ notes: Note[], sapCuratedNote: string }> {
    const url: string = `api/notes?partNumber=${partNumber}&noteId=${noteId ?? ''}`;
    return this.http.get<{ notes: Note[], sapCuratedNote: string }>(url);
  }

  createNotes(newNote: CreateNoteRequest): Observable<Note> {
    return this.http.post<Note>(`api/notes`, newNote);
  }

  putNote(noteId: number, newNote: CreateNoteRequest): Observable<Note> {
    return this.http.put<Note>(`api/notes/${noteId}`, newNote);
  }

  deleteNote(noteId: number): Observable<void> {
    return this.http.delete<void>(`api/notes/${noteId}`);
  }
}
