import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from 'store/app-state';
import { CartResultLineItemSelection } from 'entities/cart-result';
import * as MyCartSelectors from 'store/my-cart/my-cart.selectors';
import * as MyCartActions from 'store/my-cart/my-cart.actions';
import * as CartSelectors from 'store/cart/cart.selectors';
import * as CartActions from 'store/cart/cart.actions';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { debounceTime, map, tap, withLatestFrom } from 'rxjs/operators';
import { HotFlagResult } from 'entities/hot-flag/hot-flag-result';
import * as FeatureFlagSelectors from 'store/feature-flags/feature-flags.selectors';
import { FeatureFlagService } from 'services/feature-flag.service';

type CartItemFormGroup = {
    cartItemId: FormControl<string>;
    hotFlagCode: FormControl<string>;
};

type HotFlagFormGroup = {
    vin: FormControl<string | null>;
    cartItems: FormArray<FormGroup<CartItemFormGroup>>;
};

@Component({
    selector: 'hot-flag',
    templateUrl: './hot-flag.component.html'
})
export class HotFlagComponent implements OnInit, OnDestroy {

    @Input() public editHotFlagCode?: string;

    form: FormGroup<HotFlagFormGroup> = this.fb.group({
        vin: new FormControl<string>('', []),
        cartItems: new FormArray([])
    });;

    private vinValidators = [Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9]*')])];
    private filterSubject = new BehaviorSubject<string>('');
    filterChangeAction$: Observable<string> = this.filterSubject.asObservable();

    hotFlags$: Observable<HotFlagResult[]> = this.store.select(MyCartSelectors.hotFlags);
    vinHotFlagCodes$: Observable<string[]> = this.hotFlags$.pipe(
        map((hotFlags) => hotFlags.filter(hf => hf.isVINRequired === true).map(hf => hf.code)),
        tap((hotFlags) => this.vinHotFlagCodes = hotFlags)
    );
    cartVin: Subscription = this.store.select(CartSelectors.selectVin).pipe(
        tap((vin) => {
            this.form.patchValue({vin});
        })
    ).subscribe();
    selectedCartItems$: Observable<CartResultLineItemSelection[]> = this.store.select(MyCartSelectors.selectedCartItems).pipe(
        tap((selectedCartItems) => {
            if (selectedCartItems.length === 0) {
                this.activeModal.close();
            }
        })
    );
    filteredCartItems$: Observable<CartResultLineItemSelection[]> = combineLatest([this.selectedCartItems$, this.filterChangeAction$]).pipe(
        map(([selectedCartItems, filterString]: [CartResultLineItemSelection[], string]) =>
            selectedCartItems.filter(selectedCartItem => {
                if (filterString === '') {
                    return true;
                }
                return (selectedCartItem.partNumber && selectedCartItem.partNumber.toLowerCase().includes(filterString.toLocaleLowerCase())) ||
                    (selectedCartItem.corePartNumber && selectedCartItem.corePartNumber.toLowerCase().includes(filterString.toLocaleLowerCase()));
            })
        ),
        withLatestFrom(this.vinHotFlagCodes$),
        tap(([selectedCartItems, vinHotFlagCodes]: [CartResultLineItemSelection[], string[]]) => {
            if (this.editHotFlagCode) {
                this.lastExpandedIndex = 0;
                this.focusElementId = 'hotFlag_vin_cartItemHotFlag_0';
            }

            this.form.controls.cartItems.clear();
            selectedCartItems.forEach((ci: CartResultLineItemSelection, index: number) => {
                const hotFlagCode = this.editHotFlagCode && index === 0
                    ? this.editHotFlagCode
                    : ci.hotFlagCode;

                this.form.controls.cartItems.push(this.fb.group({
                    cartItemId: new FormControl<string>(ci.cartItemId),
                    hotFlagCode: new FormControl<string>(hotFlagCode, [Validators.required]),
                }));
            });

            if(this.featureFlagService.isFeatureActive('Hotflag.VinNumber')){
              this.setVinValidators();
            }
            this.form.updateValueAndValidity();

            if (this.focusElementId) {
                setTimeout(() => (document.querySelector(`#${this.focusElementId}`) as HTMLElement)?.focus());
            }
        }),
        map(([selectedCartItems, vinHotFlagCodes]: [CartResultLineItemSelection[], string[]]) => selectedCartItems)
    );

    filterKey: string = '';
    partNumber: string = '';

    fixedVendor$: Observable<boolean> = this.store.select(MyCartSelectors.selectedCartItems).pipe(
        map(selectedItems => !selectedItems.some(selected => selected.vendorCode?.length === 0))
    );
    isHotFlagVinEnabled$ = this.store.select(FeatureFlagSelectors.isFeatureActive('Hotflag.VinNumber'));
    lastExpandedIndex: number = null;
    vinHotFlagCodes: string[] = [];
    subscription: Subscription;
    isVINUpdating: boolean = false;
    vinUpdateSubject = new Subject<{event: InputEvent}>();
    vinUpdate = this.vinUpdateSubject.pipe(
        tap(() => this.isVINUpdating = true),
        debounceTime(250),
        tap(({ event }: { event: InputEvent }) => {
            this.focusElementId = (event.target as HTMLElement).id;
            if (!this.form.controls.vin.valid) {
                return;
            }

            this.store.dispatch(CartActions.updateVIN({ vin: this.form.controls.vin.value }));
            this.form.controls.cartItems.controls.forEach((g: FormGroup<CartItemFormGroup>) => {
                if (this.isVINRequiredForHotFlag(g.controls.hotFlagCode.value)) {
                    this.store.dispatch(MyCartActions.updateCartItemHotFlag({
                        cartItemId: g.controls.cartItemId.value,
                        hotFlagCode: g.controls.hotFlagCode.value,
                    }));
                }
            });
        }),
        tap(() => this.isVINUpdating = false)
    ).subscribe();
    focusElementId: string;

    constructor(
        private featureFlagService: FeatureFlagService,
        private store: Store<AppState>,
        private activeModal: NgbActiveModal,
        private fb: FormBuilder,
    ) { }

    ngOnInit() {
        this.store.dispatch(MyCartActions.getHotFlags());
        this.store.dispatch(MyCartActions.getVendorPrices({ vendorCode: null }));
    }

    ngOnDestroy(): void {
        this.cartVin.unsubscribe();
        this.vinUpdate.unsubscribe();
    }


    public closeModal(): void {
        this.activeModal.close();
    }

    filter() {
        this.filterSubject.next(this.filterKey);
    };

    removeCartItem(cartItem: CartResultLineItemSelection) {
        this.store.dispatch(MyCartActions.toggleSelection({ cartItemId: cartItem.cartItemId }));
    }

    sendHotFlags() {
        this.store.dispatch(MyCartActions.sendHotFlag());
    }

    onHotFlagChange(index: number, hotFlagValue: string) {
       const controls = this.getCartItemControls(index);
       controls.hotFlagCode = new FormControl(hotFlagValue);

        if(this.featureFlagService.isFeatureActive('Hotflag.VinNumber')){
          this.setVinValidators();
        }
        this.updateCartItemHotFlag(controls);
    }

    private setVinValidators() {
        const isVINRequired = this.form.controls.cartItems.controls.some((g: FormGroup<CartItemFormGroup>) => this.isVINRequiredForHotFlag(g.controls.hotFlagCode.value));

        if (isVINRequired) {
            this.form.controls.vin.setValidators(this.vinValidators);
        } else {
            this.form.controls.vin.clearValidators();
        }

        this.form.controls.vin.updateValueAndValidity();
    }

    updateCartItemHotFlag(controls: CartItemFormGroup) {
        if (!controls.hotFlagCode.valid) {
            return;
        }

        if (!this.isVINRequiredForHotFlag(controls.hotFlagCode.value) || (this.form.controls.vin.valid)) {
            this.store.dispatch(MyCartActions.updateCartItemHotFlag({
                cartItemId: controls.cartItemId.value,
                hotFlagCode: controls.hotFlagCode.value,
            }));
        }
    }

    isShowVINControl(index: number): boolean {
        return this.isVINRequiredForHotFlag(this.form.value.cartItems[index].hotFlagCode);
    }

    isVINRequiredForHotFlag(hotFlagCode: string): boolean {
        return this.vinHotFlagCodes.includes(hotFlagCode);
    }

    getCartItemControls(index: number): CartItemFormGroup {
        return this.form.controls.cartItems.controls[index].controls;
    }

    hasHotFlagCodeRequiredError(): boolean {
        return this.form.controls.cartItems.controls.some((g: FormGroup<CartItemFormGroup>) => g.controls.hotFlagCode.errors?.required === true);
    }

    hasVINRequiredError(): boolean {
        return this.form.controls.vin.errors?.required === true;
    }

    hasVINPatternError(): boolean {
        return this.form.controls.vin.errors?.pattern;
    }
}
