import { EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { VerifiedPrice } from 'entities/parts/verified-price';
import * as MscPriceVerifiedActions from './verified-prices.actions';
import { verifiedPricesAdapter } from "./verified-prices.adapter";

export const initialState = verifiedPricesAdapter.getInitialState();

const _verifiedPricesReducer = createReducer(
  initialState,
  on(MscPriceVerifiedActions.upsertVerifiedPrice, (state, { verifiedPrice }) => verifiedPricesAdapter.upsertOne(verifiedPrice, state)),
  on(MscPriceVerifiedActions.clearVerifiedPrices, (state) => verifiedPricesAdapter.removeAll(state))
);

export function verifiedPricesReducer(state: EntityState<VerifiedPrice> | undefined, action: Action) {
   return _verifiedPricesReducer(state, action);
}
