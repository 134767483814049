<button id="CheckoutBagQuantityModal_Button_Close" type="button" class="close" data-dismiss="modal" aria-label="Close"
  tabindex="-1" (click)="closeModal()">
  <span aria-hidden="true">&times;</span>
</button>
<div class="modal-header">
  <h4 class="modal-title"></h4>
</div>
<div class="modal-body">
  <form [formGroup]="bagQuantityForm" (ngSubmit)="onSubmit()">
    <label class="form-label"><strong>Number of bags to be used for the purchase</strong></label>
    <input id="CheckoutBagQuantityModalNumber" type="text" digitsOnly formControlName="bagQuantity"
      class="form-control" (keypress)="keyPressed($event)" (paste)="onPaste($event)" maxlength="10"/>
    <div *ngIf="bagQuantityForm.controls['bagQuantity']?.errors?.required">
      <span class="text-danger">Field is required</span>
    </div>
    <div *ngIf="bagQuantityForm.controls['bagQuantity']?.errors?.min">
      <span class="text-danger">Negative number is not allowed</span>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button id="CheckoutBagQuantityModal_Button_Cancel" type="button" class="btn btn-md black-text btnBg"
    (click)="closeModal()" data-dismiss="modal">
    Go back
  </button>
  <button id="CheckoutBagQuantityModal_Button_Submit" type="button" class="btn btn-md black-text btnBg yellow-btn"
    [disabled]="!bagQuantityForm.valid" (click)="onSubmit()">
    Submit
  </button>
</div>