import { getCurrencySymbol } from "@angular/common";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { Branch } from "entities/branch";
import { AppState } from "store/app-state";
import { BranchState } from "./branch.state";

export const selectBranchState = createFeatureSelector< BranchState>('branch');

export const selectBranchesForOrdering = createSelector(
  selectBranchState,
  (state) => state.branchesForOrdering
);

export const selectedBranch = createSelector(
  selectBranchState,
  (state) => state.selectedBranch
);

export const selectedBranchCountryCode = createSelector(
  selectBranchState,
  (state) => state.selectedBranch.country
);

export const selectCurrencyCode = createSelector(
  selectedBranch,
  (branch) => branch?.currencyCode
);

export const selectCurrencySymbol = createSelector(
  selectCurrencyCode,
  (code) => getCurrencySymbol(code, "wide")
);
