import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { LoyaltyAccount, LoyaltyProgram, LoyaltyProgramAccountsSearchCriteria } from 'entities/loyalty-account';
import { ConfigurationService } from 'services/configuration.service';
import { FindLoyaltyProgramAccountModalComponent } from './find-loyalty-program-account-modal/find-loyalty-program-account-modal.component';
import { SearchLoyaltyProgramAccountsModalComponent } from './search-loyalty-program-accounts-modal/search-loyalty-program-accounts-modal.component';
import { Pager } from 'entities/pager';
import * as LoyaltySelectors from 'store/loyalty/loyalty.selectors';
import { LoyaltyService } from 'services/loyalty.service';
import { LoaderService } from 'services/loader.service';
import { Store } from '@ngrx/store';
import { AppState } from 'store/app-state';

@Component({
  selector: 'select-loyalty-accounts-modal',
  templateUrl: './select-loyalty-accounts-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectLoyaltyAccountsModalComponent implements OnInit, OnDestroy {
  @Input() branchCode: string;
  @Input() loyaltyProgram: LoyaltyProgram;
  @Input() customerNumber: string;
  @Input() loyaltyAccounts: LoyaltyAccount[] = [];

  initialSearch$: Observable<LoyaltyAccount[]>;
  subscriptions: Subscription[];
  private searchCriteria: LoyaltyProgramAccountsSearchCriteria = {
    branchCode: "",
    companyName: "",
    customerSapCode: "",
    emailAddress: "",
    loyaltyAccountIdentifier: "",
    loyaltyProgram: "peterbilt",
    phone: "",
    postalCode: "",
    userId: ""
  };

  public selectedLoyaltyAccount$ = this.store.select(LoyaltySelectors.loyaltyAccount);

  public selectedLoyaltyAccount: LoyaltyAccount = null;

  public pageNumber = 1;
  public pageSize = 10;
  public pager = new Pager(Object.values(this.loyaltyAccounts).length, this.pageNumber, this.pageSize);
  public isInternal$: Observable<boolean> = this.configurationService.user$
    .pipe(
      map((user) => user.isInternal)
    );

    loadingSubject = new BehaviorSubject(false);
  constructor(
    private store: Store<AppState>,
    private ngbActiveModal: NgbActiveModal,
    private ngbModal: NgbModal,
    private changeDetectorRef: ChangeDetectorRef,
    private configurationService: ConfigurationService,
    private loyaltyService: LoyaltyService,
    private loaderService: LoaderService
  ) { }
  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  ngOnInit(): void {
    this.searchCriteria.branchCode = this.branchCode;
    this.searchCriteria.customerSapCode = this.customerNumber;
    if (this.searchCriteria.customerSapCode !== "200000") {
      this.loadingSubject.next(true);
      this.initialSearch$ = this.loyaltyService.searchLoyaltyProgramAccounts({
        branchCode: this.branchCode,
        companyName: null,
        customerSapCode: this.customerNumber,
        emailAddress: null,
        loyaltyAccountIdentifier: null,
        loyaltyProgram: this.loyaltyProgram,
        phone: null,
        postalCode: null,
        userId: null
      }).pipe(
        catchError(x => {
          this.loadingSubject.next(false);
          return x;
        }),
        tap((searchResults: LoyaltyAccount[]) => {
          if (searchResults) {
            this.addToLoyaltyAccounts("peterbilt", searchResults);
          }
          this.loadingSubject.next(false);
        })
      );
    }

    this.loaderService.register(this.loadingSubject);
    this.subscriptions = [this.loadingSubject.subscribe()];
  }

  findLoyaltyProgramAccount(loyaltyProgram: LoyaltyProgram) {
    const modalRef = this.ngbModal.open(FindLoyaltyProgramAccountModalComponent);
    modalRef.componentInstance.branchCode = this.branchCode;
    modalRef.componentInstance.loyaltyProgram = loyaltyProgram;
    modalRef.componentInstance.customerNumber = this.customerNumber;

    modalRef.result
      .then((loyaltyAccount: LoyaltyAccount) => {
        this.addToLoyaltyAccounts(loyaltyProgram, [loyaltyAccount]);
      })
      // Modal dismissed
      .catch(() => { });
  }

  searchLoyaltyProgramAccount(loyaltyProgram: LoyaltyProgram) {
    const modalRef = this.ngbModal.open(SearchLoyaltyProgramAccountsModalComponent, { size: 'lg' });
    modalRef.componentInstance.branchCode = this.branchCode;
    modalRef.componentInstance.loyaltyProgram = loyaltyProgram;
    modalRef.componentInstance.customerNumber = this.customerNumber;
    modalRef.result
      .then((loyaltyAccount: LoyaltyAccount) => {
        this.addToLoyaltyAccounts(loyaltyProgram, [loyaltyAccount]);
      })
      // Modal dismissed
      .catch(() => { });
  }

  addToLoyaltyAccounts(loyaltyProgram: LoyaltyProgram, loyaltyAccount: LoyaltyAccount[]) {
    this.loyaltyAccounts = [
      // ...(this.loyaltyAccounts ?? []),
      ...loyaltyAccount
    ];
    this.pager = new Pager(Object.values(this.loyaltyAccounts).length, this.pageNumber, this.pageSize);
    this.changeDetectorRef.markForCheck();
  }

  setPage(pageNumber: number) {
    this.pager.currentPage = pageNumber;
  }

  selectLoyaltyAccount(loyaltyAccount: LoyaltyAccount) {
    this.selectedLoyaltyAccount = loyaltyAccount;
  }

  cancel() {
    this.ngbActiveModal.dismiss();
  }

  submit() {
    this.ngbActiveModal.close(this.selectedLoyaltyAccount);
  }
}
