import { Injectable } from '@angular/core';
import { Observable, merge } from 'rxjs';
import { filter, first, map, scan, shareReplay, withLatestFrom } from 'rxjs/operators';
import { ConfigurationService } from 'services/configuration.service';
import { ActivatedRoute } from '@angular/router';
import { OrderHistoryRequest } from 'entities/order-history';
import { maxDate, minDate } from 'helpers';
import { FeatureFlagService } from 'services/feature-flag.service';

export type FindOrderPanelActions =
  | { type: 'setOrderNumber', orderNumber: string }
  | { type: 'setRequest', request: OrderHistoryRequest }
  | { type: 'setStartDate', startDate: Date, isQuote: boolean }
  | { type: 'setEndDate', endDate: Date, isQuote: boolean }
  | { type: 'setCustomerNumber', customerNumber: string }
  | { type: 'setShipTo', shipTo: string }
  | { type: 'setBranch', branchCode: string }
  | { type: 'setPartNumber', partNumber: string }
  | { type: 'setPoNumber', poNumber: string }
  | { type: 'setType', orderType: "order" | "quote"};

export interface FindOrderPanelState {
  request: OrderHistoryRequest;
  orderNumber: string;
  initialized: boolean;
}

@Injectable()
export class FindOrderPanelComponentService {
  private readonly today = new Date();
  private readonly initialState: FindOrderPanelState = {
    request: {
      startNum: 0,
      count: 10,
      startDate: new Date(Date.UTC(this.today.getUTCFullYear(), this.today.getUTCMonth(), this.today.getUTCDate() - 30)),
      endDate: new Date(Date.UTC(this.today.getUTCFullYear(), this.today.getUTCMonth(), this.today.getUTCDate())),
      customerNumber: null,
      branchCode: null,
      shipTo: null,
      partNumber: null,
      poNumber: null,
      type: null
    },
    orderNumber: null,
    initialized: false
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private configurationService: ConfigurationService,
    private featureFlagService: FeatureFlagService
  ) { }

  getDatesForSetStartDate(state, action, earliestQuoteDate, today){
    return {
      startDate: action.isQuote ?
        maxDate(earliestQuoteDate, minDate(today, action.startDate)) :
        minDate(today, action.startDate),
      endDate: maxDate(state.request.endDate, minDate(action.startDate, today))
    }
  }

  getDatesForSetEndDate(state, action, earliestQuoteDate, today){
    return {
      startDate: action.isQuote ?
      maxDate(earliestQuoteDate, minDate(state.request.startDate, action.endDate)) :
      minDate(state.request.startDate, action.endDate),
    endDate:  action.isQuote ?
      maxDate(earliestQuoteDate , minDate(action.endDate, today)) :
      minDate(action.endDate, today)
    };
  }

  getSelectors(action$: Observable<FindOrderPanelActions>) {
    const queryParamsRequest$: Observable<FindOrderPanelActions> = this.activatedRoute.queryParams
      .pipe(
        map((queryParams) => ({
          customerNumber: queryParams['customerNumber'],
          shipTo: queryParams['shipTo']
        })),
        withLatestFrom(this.configurationService.configuration$),
        map(([{ customerNumber, shipTo }, configuration]) => ({
          customerNumber: configuration.user.isInternal
            ? customerNumber
            : configuration.customer.customerNumber,
          shipTo: configuration.user.isInternal
            ? shipTo
            : null
        })),
        map(({ customerNumber, shipTo }): OrderHistoryRequest => ({
          ...this.initialState.request,
          customerNumber,
          shipTo
        })),
        map((request) => ({ type: 'setRequest', request }))
      );

    const state$ = merge(queryParamsRequest$, action$)
      .pipe(
        scan(
          (state, action): FindOrderPanelState => {
            const today = new Date(Date.UTC(this.today.getUTCFullYear(), this.today.getUTCMonth(), this.today.getUTCDate()));
            const earliestQuoteDate = new Date(Date.UTC(this.today.getUTCFullYear(), this.today.getUTCMonth(), this.today.getUTCDate() - 30));
            switch (action.type) {
              case 'setRequest':
                return { ...state, request: action.request, initialized: true };
              case 'setStartDate':
                return {
                  ...state,
                  request: {
                    ...state.request,
                    startDate: this.getDatesForSetStartDate(state, action, earliestQuoteDate, today).startDate,
                    endDate: this.getDatesForSetStartDate(state, action, earliestQuoteDate, today).endDate
                  }
                };
              case 'setEndDate':
                return {
                  ...state,
                  request: {
                    ...state.request,
                    startDate: this.getDatesForSetEndDate(state, action, earliestQuoteDate, today).startDate,
                    endDate: this.getDatesForSetEndDate(state, action, earliestQuoteDate, today).endDate
                  }
                };
              case 'setOrderNumber':
                return { ...state, request: { ...state.request }, orderNumber: action.orderNumber };
              case 'setCustomerNumber':
                return { ...state, request: { ...state.request, customerNumber: action.customerNumber } };
              case 'setShipTo':
                return { ...state, request: { ...state.request, shipTo: action.shipTo } };
              case 'setBranch':
                return { ...state, request: { ...state.request, branchCode: action.branchCode } };
              case 'setPartNumber':
                return { ...state, request: { ...state.request, partNumber: action.partNumber } };
              case 'setPoNumber':
                return { ...state, request: { ...state.request, poNumber: action.poNumber } };
              case 'setType':
                return { ...state, request: { ...state.request, type: action.orderType }}
              default:
                return { ...state };
            }
          },
          this.initialState
        ),
        shareReplay(1)
      );

    const searchRequest$ = state$
      .pipe(
        map((state) => state.request)
      );

    const orderNumber$ = state$
      .pipe(
        map((state) => state.orderNumber)
      );

    const initialized$ = state$
      .pipe(
        filter((state) => state.initialized), first()
      );
    return {
      searchRequest$,
      orderNumber$,
      initialized$
    };
  }
}
