import { Component, OnInit, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoggerService } from 'services/logger.service';
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

@Component({
  selector: 'app-device-list-modal',
  templateUrl: './device-list-modal.component.html',
  styleUrls: ['./device-list-modal.component.scss']
})
export class DeviceListModalComponent implements OnInit {

  public deviceListFormGroup: UntypedFormGroup;

  constructor( private activeModal: NgbActiveModal, private loggerService: LoggerService) { }
  @Input() devices = [];
  @Input() branchCode = '';
  @Output() deviceKey = '';

  ngOnInit(): void {

    this.deviceListFormGroup = new UntypedFormGroup({
      deviceSelection: new UntypedFormControl()

    });

  }

  setSelectedDevice(key: any) {
    this.deviceKey = key;
  }

  public continue(): void {
    this.activeModal.close(this.deviceKey);
  }

  public cancel(): void {
    this.activeModal.dismiss();
  }

}
