import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import { Observable, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from "rxjs";
import { AutoCompleteResult } from "./autocomplete.entities";

@Component({
  selector: 'autocomplete',
  templateUrl: './autocomplete.component.html'
})
export class AutoCompleteComponent {

  @Input()
  url: string = "";

  @Input()
  prop: string = "";

  @Input()
  label: string = "";

  @Input()
  branches: string[];

  @Input()
  disabled: boolean;

  @Input('selected') set selected(value: string) {
    this.value = { name: value };
  }

  @Input()
  value: any = "";

  @Output()
  onChange: EventEmitter<AutoCompleteResult> = new EventEmitter();

  @Output()
  onClear: EventEmitter<void> = new EventEmitter();

  public readonly search: (text$: Observable<string>) => Observable<any>;

  public loading: boolean = false;
  public intialized: boolean = false;
  public waitBeforeSendingRequest: number = 500;
  public dropdownOption: number = 0;
  public isSearching: boolean = false;

  public readonly http: HttpClient = inject(HttpClient);

  constructor() {
    this.search = (text$: Observable<string>) => text$
      .pipe(
        debounceTime(this.waitBeforeSendingRequest),
        distinctUntilChanged(),
        filter((text: string) => text.length >= 3),
        tap(() => this.isSearching = true),
        switchMap((text: string) => this.http.post<any>(this.url, {
          branchCodes: this.branches,
          userName: text,
          pageNumber: 1
        }).pipe(
          filter((results) => {
            if (!results) {
              this.isSearching = false
            }
            return !!results;
          }),
          map((results) => this.mapResults(results)?.filter(r => {
            return r.name.startsWith(text) ||
              r.name.endsWith(text) ||
              r.name === text ||
              r.name.includes(text) ||
              r.name.toUpperCase().includes(text.toUpperCase())
          }))
        )),
        tap(() => this.isSearching = false)
      );
  }

  mapResults(results) {
    return results[this.prop] || [];
  }

  onSelectChange(event: NgbTypeaheadSelectItemEvent<AutoCompleteResult>): void {
    this.dropdownOption = 0;
    this.onChange.emit(event.item);
  }

  onInputChanged(value: string) {
    if(value && value.length > 0) {
      return;
    }
    this.onClear.emit();
  }

  displaySelected(item: AutoCompleteResult): string | null {
    if (!item) {
      return;
    }
    return item.name;
  }

  selectAllContent(target: HTMLInputElement): void {
    target.select();
  }

  onKeydown(event: KeyboardEvent, selectionGroup: HTMLElement): void {
    const menu: HTMLElement = (selectionGroup.querySelector(`ngb-typeahead-window`) as HTMLElement);
    if (menu && (event.key.startsWith(`Arrow`) || event.code.startsWith(`Arrow`))) {
      const options: NodeListOf<Element> = document.querySelectorAll(`ngb-typeahead-window button`);
      const optionHeight: number = (options[0] as HTMLElement).offsetHeight;
      const currentPosition: number = this.dropdownOption ? this.dropdownOption * optionHeight : 0;
      const maxPosition: number = (options.length - 1) * optionHeight;
      let newPosition: number = currentPosition;
      if ((event.key === `ArrowDown`) || (event.code === `ArrowDown`)) {
        newPosition = (currentPosition + optionHeight) >= maxPosition ? maxPosition : currentPosition + optionHeight;
        this.dropdownOption++;
      } else if ((event.key === `ArrowUp`) || (event.code === `ArrowUp`)) {
        newPosition = (currentPosition - optionHeight) <= 0 ? 0 : currentPosition - optionHeight;
        this.dropdownOption--;
      }
      menu.scrollTop = newPosition;
    } else {
      this.dropdownOption = 0;
    }
  }
}



