import { createAction, props } from '@ngrx/store';

export const addCartItemSuccess = createAction(
  '[Part Card Add To Cart Component] Add Cart Item Success',
  props<{ cartId: string }>()
);

export const updateCartItemPriceSuccess = createAction(
  '[Part Card Add To Cart Component] Update Cart Item Price Success',
  props<{ cartId: string }>()
);
