import { Component, Inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { getImageHelper } from "helpers/get-image";
import { IMAGE_BASE_URL, DEFAULT_IMAGE_URL } from "app/app.constants";
import { Store } from "@ngrx/store";
import { AppState } from "store/app-state";
import * as CartSelectors from "store/cart/cart.selectors";
import * as BranchSelectors from "store/branch/branch.selectors";

@Component({
  selector: "app-view-all-parts",
  templateUrl: "./view-all-parts.component.html",
  styleUrls: ["./view-all-parts.component.scss"],
})
export class ViewAllPartsComponent {

  hasCouponData$ = this.store.select(CartSelectors.hasSapCoupons);
  selectCartPartItems$ = this.store.select(CartSelectors.selectCartPartItems);
  currencyCode$ = this.store.select(BranchSelectors.selectCurrencyCode);

  constructor(
    private activeModal: NgbActiveModal,
    @Inject(IMAGE_BASE_URL) public imageBaseUrl: string,
    @Inject(DEFAULT_IMAGE_URL) public defaultImage: string,
    private store: Store<AppState>
  ) {}

  closeModal(): void {
    this.activeModal.dismiss();
  }

  getImage(image: string, imageBaseUrl: string, imageSize: string) {
    return getImageHelper(image, imageBaseUrl, imageSize);
  }
}
