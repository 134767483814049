import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Part } from 'entities/part';
import { PunchoutPart } from 'entities/punchout-part';

interface PunchoutPartMap {
  partNumber: string;
  quantity: number;
  description: string;
  partResult: Part;
}

@Injectable({
  providedIn: 'root'
})
export class PunchoutService {
  constructor(private http: HttpClient) { }
  setParameters(params: { vin: string }): Observable<unknown> {
    return this.http.post('api/punchout/parameters/navistar', params);
  }

  anyPunchoutParts(punchoutId: string): Observable<boolean> {
    return this.http.get<boolean>(`api/punchout/parts/any?punchoutId=${punchoutId || ''}`);
  }

  getPunchoutParts(punchoutId: string, branchCode: string, customerNumber: string): Observable<PunchoutPart[]> {
    return this.http.post<PunchoutPartMap[]>(`api/punchout/parts/map`, { punchoutId, branchCode, customerNumber })
      .pipe(
        map((punchoutParts) => 
          punchoutParts.map((punchoutPart) => ({
            partNumber: punchoutPart.partNumber,
            description: punchoutPart.description,
            quantity: punchoutPart.quantity,
            rushPartNumber: punchoutPart.partResult && punchoutPart.partResult.isExtendedToBranch && punchoutPart.partResult.rushPartNumber || null
          }))
        )
      );
  }
}
