<div class="d-flex flex-column">
  <div id="partDetails_div_SAPCuratedNote" *ngIf="sapCuratedNote" class="p-3 border-bottom">
    <h5 class="strong text-muted">SAP Part Administrator Notes:</h5>
    <div>{{sapCuratedNote}}</div>
  </div>

  <div class="px-3 py-2 gx-2 border-bottom">
    <span>Sort By:</span>
    <span ngbDropdown>
      <button id="partDetails_button_NoteSortDirection" class="btn btn-sm btn-outline-secondary" ngbDropdownToggle [ngSwitch]="sortAscending$ | async">
        <ng-container *ngSwitchCase="false">
          Newest to Oldest
        </ng-container>
        <ng-container *ngSwitchCase="true">
          Oldest to Newest
        </ng-container>
      </button>
      <div ngbDropdownMenu>
        <button id="partDetails_button_NewestToOldest" class="dropdown-item" (click)="setSortAscending(false)">
          Newest to Oldest
        </button>
        <button id="partDetails_button_OldestToNewest" class="dropdown-item" (click)="setSortAscending(true)">
          Oldest to Newest
        </button>
      </div>
    </span>
  </div>

  <div *ngLet="edit$ | async as edit" class="flex-grow-1" style="overflow-y:auto; max-height:190px;">
    <div id="partDetails_div_Note_{{i}}" *ngFor="let note of (notes$ | async); let i = index"
      class="p-3 border-bottom d-flex justify-content-between align-items-baseline">
      <form *ngIf="edit && edit.id === note.id; else noteTemplate" class="w-100" #editNgForm="ngForm" (ngSubmit)="updateNote(editNgForm.form)">
        <fieldset [disabled]="edit.submitting">
          <div class="input-group input-group-sm">
            <input type="hidden" name="id" [ngModel]="edit.id">
            <input id="partNotes_editForm_text_description" type="text" class="form-control" name="description" placeholder="Add a Note" [ngModel]="edit.description" required>
              <span class="input-group-text">
                <i class="far fa-comment" aria-hidden="true"></i>
              </span>
              <button id="partNotes_editForm_button_cancel" type="button" class="btn btn-secondary" (click)="cancelEdit()">Cancel</button>
              <button id="partNotes_editForm_button_submit" type="submit" class="btn btn-secondary"
                [disabled]="!editNgForm.form.dirty || !editNgForm.form.valid">
                Update Note
              </button>
          </div>
        </fieldset>
      </form>
      <ng-template #noteTemplate>
        <div>
          <div>{{note.createdDate | date: 'MM/dd/yyyy'}}</div>
          <div>{{note.description}}</div>
          <div *ngIf="isInternal$">Submitted by: {{note.createdByUserName | emailToUserName | uppercase}}</div>
        </div>
        <div *ngIf="(hasSomeModifyPermission$ | async) || note.createdBy === (userId$ | async)" ngbDropdown container="body" placement="bottom-right">
          <button ngbDropdownToggle class="btn btn-sm btn-outline-secondary">
            <i class="fas fa-ellipsis-h"></i>
          </button>
          <div ngbDropdownMenu>
            <button *ngIf="(hasEditPermission$ | async) || note.createdBy === (userId$ | async)" ngbDropdownItem (click)="editNote(note.id, note.description)">
              <i class="fas fa-pencil-alt"></i>
              Edit
            </button>
            <button *ngIf="(hasDeletePermission$ | async) || note.createdBy === (userId$ | async)" ngbDropdownItem (click)="deleteNote(note.id)">
              <i class="far fa-trash-alt"></i>
              Delete
            </button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <form id="partDetails_form_CreatePartNotes" *ngIf="authorizationService.hasPermission(permission.CreatePartNotes)" class="p-3"
    #ngForm="ngForm" (ngSubmit)="createNote(ngForm.form)">
    <fieldset [disabled]="!partNumber">
      <div class="input-group input-group-sm">
        <input id="partDetails_text_NoteText" type="text" class="form-control" name="description" placeholder="Add a Note" ngModel required>
          <span class="input-group-text">
            <i class="far fa-comment" aria-hidden="true"></i>
          </span>
          <button id="partDetails_button_AddNoteSubmit" type="submit" class="btn btn-secondary"
            [disabled]="!ngForm.form.dirty || !ngForm.form.valid">
            Add Note
          </button>
      </div>
    </fieldset>
    <div *ngIf="!partNumber" class="alert alert-warning m-0">Product notes cannot be created for a non-SAP part.</div>
  </form>
</div>