<div *ngLet="{
  currencyCode: currencyCode$ | async
} as vm">

  <div id="CrfDialog" *ngIf="!isVerify">
    <span id="Title">Customer Review Form</span>
    <button id="Continue" type="submit" class="btn btn-primary" (click)="verify()">Continue</button>
    <hr />
    <iframe [src]="crfDocumentSrc" type="application/pdf" width="100%" height="90%" style="overflow:auto"></iframe>
  </div>

  <div *ngIf="isVerify">
    <button style="position:relative; z-index: 10;" id="CustomerNotesModal_Button_Close" type="button" class="close" data-dismiss="modal" aria-label="Close"
      tabindex="-1" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>

    <div class="modal-body">
      <span id="CrfVerifyTitle">APPROVE AMOUNT - {{paymentDue | currency: vm.currencyCode
        :'symbol':'1.2-2' }}</span>
      <br /><br />
      <span id="CrfVerifyPrompt">Do you agree to a payment of {{paymentDue | currency:
        vm.currencyCode :'symbol':'1.2-2' }}?</span>
    </div>

    <div class="modal-footer">
      <button id="CrfVerify_button_Cancel" type="button" class="btn btn-default black-btn"
        (click)="cancel()">Cancel</button>
      <button id="CrfVerify_button_Approve" type="button" class="btn btn-default yellow-btn"
        (click)="approve()">Approve</button>
    </div>
  </div>
</div>
