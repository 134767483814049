import { Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { CurrencyCode } from "entities/currency-code";
import { Discount, DiscountProgram } from "entities/discount";
import { Observable } from "rxjs";
import { AppState } from "store/app-state";
import * as BranchSelectors from "store/branch/branch.selectors";


@Component({
  selector: 'coupon-popover',
  templateUrl: 'coupon-popover.component.html'
})

export class CouponPopoverComponent {
  @Input() coupons: any[];
  @Input() unitPrice: number;
  @Input() allProgramDiscounts: { program: DiscountProgram, discount: Discount }[];
  @Input() quantity: number;

  @Input('idSuffix') set _idSuffix(value: string) {
    if (value) {
      this.idSuffix = `_${value}`;
    }
  }
  idSuffix: string;
  public currencyCode$: Observable<CurrencyCode> = this.store.select(BranchSelectors.selectCurrencyCode);

  constructor(
    private store: Store<AppState>
  ){}

}
