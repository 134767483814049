import { Component, ChangeDetectionStrategy, OnDestroy, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { PUNCHOUT_NAVISTAR_URL_TOKEN } from 'app/app.constants';
import { PunchoutService } from 'services/punchout.service';

@Component({
  selector: 'navistar-punchout-modal',
  templateUrl: './navistar-punchout-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavistarPunchoutModalComponent implements OnDestroy {
  public vin: string;

  private navigationSubject: Subject<{ vin: string }> = new Subject();
  public navigating: boolean;
  private navigation$ = this.navigationSubject
    .pipe(
      tap(() => {
        this.navigating = true;
      }),
      switchMap((params: { vin: string }) => this.punchoutservice.setParameters(params)),
      // Use setTimeout so Internet Explorer doesn't break
      tap(() => setTimeout(() => location.assign(this.navistarUrl)))
    );

  private subscription = this.navigation$.subscribe();

  constructor(
    private activeModal: NgbActiveModal,
    private punchoutservice: PunchoutService,
    @Inject(PUNCHOUT_NAVISTAR_URL_TOKEN) private navistarUrl: string
  ) { }

  onContinueClick(params: { vin: string }) {
    this.navigationSubject.next(params);
  }

  cancel() {
    this.activeModal.dismiss();
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
}
