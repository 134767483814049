<button id="LineItemNotesModal_Button_Close" type="button" class="close" data-dismiss="modal" aria-label="Close"
  tabindex="-1" (click)="closeModal()">
  <span aria-hidden="true">&times;</span>
</button>
<div class="modal-wrapper" id="lineItemNotesModal">

  <div class="modal-header">
    <div class="container">
      <div class="row">
        <div class="text-white title-of-box-hold w-100 p-2">
          <h4 id="LineItemNotesModal_H4_PartNumberHeader" class="modal-title">Line Item Notes <small
              *ngIf="partNumber">(Part
              Number - {{partNumber}})</small></h4>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-sm-5">
          <ul>
            <li id="LineItemNotesModal_LI_WorkShopText" class="clickable" [ngClass]="{'active':selected=='workshop'}"
              (click)="selected='workshop'; placeholderText='Add Workshop Text'; updateNoteTextField('workshop')">
              Workshop<i *ngIf="existingNoteTypes?.includes('workshop')" class="fas fa-pencil-alt fa-pull-right"></i>
            </li>
            <li id="LineItemNotesModal_LI_InvoiceText" class="clickable" [ngClass]="{'active':selected=='invoice'}"
              (click)="selected='invoice'; placeholderText='Add Invoice Text'; updateNoteTextField('invoice')">
              Invoice<i *ngIf="existingNoteTypes?.includes('invoice')" class="fas fa-pencil-alt fa-pull-right"></i>
            </li>
            <li id="LineItemNotesModal_LI_SerialNumber" class="clickable"
              [ngClass]="{'active':selected=='serialNumber'}"
              (click)="selected='serialNumber'; placeholderText='Add Serial Number Text'; updateNoteTextField('serialNumber')">
              Serial Number<i *ngIf="existingNoteTypes?.includes('serialNumber')"
                class="fas fa-pencil-alt fa-pull-right"></i>
            </li>
            <li id="LineItemNotesModal_LI_Material" class="clickable" [ngClass]="{'active':selected=='material'}"
              (click)="selected='material'; placeholderText='Add Material Text'; updateNoteTextField('material')">
              Material<i *ngIf="existingNoteTypes?.includes('material')" class="fas fa-pencil-alt fa-pull-right"></i>
            </li>
            <li id="LineItemNotesModal_LI_STOText" class="clickable" [ngClass]="{'active':selected=='sto'}"
              (click)="selected='sto'; placeholderText='Add STO Text'; updateNoteTextField('sto')">
              STO<i *ngIf="existingNoteTypes?.includes('sto')" class="fas fa-pencil-alt fa-pull-right"></i>
            </li>
            <li id="LineItemNotesModal_LI_PickTicketLineItem" class="clickable"
              [ngClass]="{'active':selected=='pickTicketLineItem'}"
              (click)="selected='pickTicketLineItem'; placeholderText='Add Pick Ticket Line Item Text'; updateNoteTextField('pickTicketLineItem')">
              Pick Ticket Line Item<i *ngIf="existingNoteTypes?.includes('pickTicketLineItem')"
                class="fas fa-pencil-alt fa-pull-right"></i>
            </li>
          </ul>
        </div>
        <div class="col-sm-7">
          <textarea id="LineItemNotesModal_TextArea_CartItemNoteTextArea" class="form-control" rows="10"
            style="resize: none;" #cartItemNote="ngModel" [(ngModel)]="cartItemNoteText"
            placeholder="{{placeholderText}}"></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button id="LineItemNotesModal_Button_FooterClose" type="button" class="btn btn-secondary"
      (click)="closeModal()">Close</button>
    <button id="LineItemNotesModal_Button_SaveChanges" type="button" class="btn btn-primary"
      (click)="saveLineItemNotes()" title="Save Changes">Save changes</button>
  </div>
</div>