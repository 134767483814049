import { CartDetails } from 'entities/cart-details';
export class AppInsightSto {
    userId: string = '';
    customerNumber: string = '';
    customerName: string = '';
    branchNumber?: string = '';
    cartDetails?: CartDetails;
    vendorNumber: string = '';
    vendorName: string = '';
    source: string = '';
    product?: any = null;
    plMetricName: string = '';
    cartId: string;
    branchCode: string;

}
