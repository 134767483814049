import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { CommonDataService } from 'services/common-data.service';
import { AppState } from 'store/app-state';

@Component({
  selector: 'order-item-list',
  templateUrl: './order-item-list.component.html',
  styles: []
})
export class OrderItemListComponent {
  @Input() public orderItems: any[];
  @Input() public idSuffix: string;
  @Input() public showCouponColumn: boolean;
  @Input() public showHotFlagColumn: boolean;
  @Input() public notHotFlag95And99: boolean;
  @Input() public header: string;
  @Input() public headerRight: string;
  @Input() public orderItemCouponValues: { [id: string]: number } = {};

  constructor(public commonDataService: CommonDataService, private store: Store<AppState>) { }
}
