import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { CartResult, CartResultLineItem, CartResultLineItemSelection, PartVendorPriceRequest } from 'entities/cart-result';
import { Observable } from 'rxjs';
import { AppState } from 'store/app-state';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CartService } from 'services/cart.service';
import { HotFlagResult } from 'entities/hot-flag/hot-flag-result';
import { ToastService } from 'services/toast.service';
import { ModalService } from 'services/modal.service';
import * as CartSelectors from 'store/cart/cart.selectors';
import * as CartActions from 'store/cart/cart.actions';
import { UpdateCartItemFlagRequest } from 'entities/carts/updat-cart-item-flag-request';
import { UpdateFlagCartItem } from 'entities/carts/update-flag-cart-item';
import { ToastType } from 'entities/toast-type';
import { VendorService } from 'services/vendor.service';
import { ItemToRemoveFromCart } from 'entities/carts/item-to-remove-from-cart';
import { NationalInventoryService } from 'services/national-inventory.service';
import * as CustomerSelectors from 'store/customer/customer.selectors';
import * as BranchSelectors from 'store/branch/branch.selectors';
import { GetInventoryRequest } from 'entities/national-inventory/get-inventory-request';
import { InventoryResult } from 'entities/national-inventory/inventory-result';
import { InventoryInfo } from 'entities/national-inventory/inventory-info';
import { AddStoCartItemRequest } from 'entities/carts/add-sto-cart-item-request';

export interface MyCartComponentState {
  cartItems: CartResultLineItemSelection[],
  hotFlags: HotFlagResult[],
  fixedVendor: boolean,
  loading: boolean
}

@Injectable({providedIn: 'root'})
export class MyCartComponentStore extends ComponentStore<MyCartComponentState> {

  public readonly allCartItems$: Observable<CartResultLineItemSelection[]> = this.select((state) => state.cartItems);
  public readonly selectedCartItems$: Observable<CartResultLineItemSelection[]> = this.select((state) => state.cartItems.filter(x => x.selected));
  public readonly hotFlagCartItems$: Observable<CartResultLineItemSelection[]> = this.select((state) => state.cartItems.filter(x => x.isHotFlag));
  public readonly stoCartItems$: Observable<CartResultLineItemSelection[]> = this.select((state) => state.cartItems.filter(x => x.isSTO));
  public readonly buyoutCartItems$: Observable<CartResultLineItemSelection[]> = this.select((state) => state.cartItems.filter(x => x.isBuyout));
  public readonly nonProcuredItems$: Observable<CartResultLineItemSelection[]> = this.select((state) => state.cartItems.filter(x => !x.isBuyout && !x.isHotFlag && !x.isSTO));
  public readonly hotFlags$: Observable<HotFlagResult[]> = this.select((state) => state.hotFlags);
  public readonly loading$: Observable<boolean> = this.select((state) => state.loading);

  constructor(
    private nationalInventoryService: NationalInventoryService,
    private modalService: ModalService,
    private toastService: ToastService,
    private cartService: CartService,
    private vendorService: VendorService,
    private store: Store<AppState>) {
    super({
      cartItems: [],
      hotFlags: [],
      fixedVendor: false,
      loading: false
    });
  }

  public readonly addStoToCart = this.effect((params$: Observable<{addStoRequest: AddStoCartItemRequest, selectedCartItem: CartResultLineItemSelection}>) =>
    params$.pipe(
      tap(({addStoRequest, selectedCartItem}: {addStoRequest: AddStoCartItemRequest, selectedCartItem: CartResultLineItemSelection}) => {
        this.store.dispatch(CartActions.addStoItemToCart({ addCartItemRequest: addStoRequest, cartItem: selectedCartItem }));
      })
    )
  );

  public readonly selectAllItems = this.effect((params$: Observable<void>) =>
    params$.pipe(
      withLatestFrom(this.allCartItems$),
      tap(([_, cartItems]) => {
        this.patchState({cartItems: cartItems.map(x => ({...x, selected: true}))});
      })
    )
  );

  public readonly unSelectAllItems = this.effect((params$: Observable<void>) =>
    params$.pipe(
      withLatestFrom(this.allCartItems$),
      tap(([_, cartItems]) => {
        this.patchState({cartItems: cartItems.map(x => ({...x, selected: false}))});
      })
    )
  );

  public readonly getInventory = this.effect((params$: Observable<void>) =>
    params$.pipe(
      withLatestFrom(this.allCartItems$, this.store.select(CustomerSelectors.selectedCustomer), this.store.select(BranchSelectors.selectedBranch)),
      filter(([_, selectedCartItems, customer, branch]) => selectedCartItems.length > 0),
      switchMap(([_, selectedCartItems, customer, branch]) => {
        const cartItems: CartResultLineItemSelection[] = selectedCartItems;
        const getInventoryRequest: GetInventoryRequest = {
          branchCode: branch.code,
          customerNumber: customer.customerNumber,
          partInventories: cartItems.map(x => ({partId: x.partId, partNumber: x.partNumber}))
        };
        this.patchState({loading: true});
        return this.nationalInventoryService.getInventoryObs(getInventoryRequest).pipe(
          tap((inventory: InventoryResult[]) => {
            const cartItemsWithInventory = cartItems.map((cartItems) => {
              const itemInventory = inventory.find(x => x.partNumber === cartItems.partNumber)?.inventory;
              if(itemInventory){
                return {...cartItems, inventory: itemInventory.map(x => ({...x, partsQuantity: 1}))};
              }
              return cartItems;
            });
            this.patchState({cartItems: cartItemsWithInventory, loading: false});
          }),
          catchError(error => {
            this.toastService.errorMessage('STOModalComponent', 'getInventory', 'getInventory', error);
            return error;
          })
        );
      })
    )
  );

  public readonly showPriceVerifyModal = this.effect((params$: Observable<void>) =>
      params$.pipe(
        withLatestFrom(this.selectedCartItems$),
        tap(([_, items]) => {
          this.store.dispatch(CartActions.showPriceVerifyModal({ items }));
        })
      )
  );

  public readonly getVendorPrices = this.effect((params$: Observable<void>) =>
    params$.pipe(
      withLatestFrom(this.selectedCartItems$, this.store.select(BranchSelectors.selectedBranch)),
      switchMap(([_, selectedCartItems, branch]) => {
        const partVendorPriceRequestArray = selectedCartItems.map( (item) => {
            var partVendorPriceRequest : PartVendorPriceRequest  =
            {
              branchCode : branch.code,
              corePartNumber :item.corePartNumber,
              corePrice: item.corePrice,
              partNumber :item.partNumber,
              vendorCode : item.vendorCode,
              hasCore: item.corePrice > 0
           }
           return partVendorPriceRequest;
        });
        return this.vendorService.getPartVendorPriceObs(partVendorPriceRequestArray).pipe(
          tap((vendorPartsPriceResult) => {
            const cartItems = this.get().cartItems.map(cartItem => {
              const vendorPart = vendorPartsPriceResult.find(x => x.partNumber === cartItem.partNumber);
              if(vendorPart){
                const knownPartBuyoutAmount = cartItem.knownPartBuyoutAmount = vendorPart.purchasePrice > 0 ? vendorPart.purchasePrice : 1.00;
                const knownPartPrice = cartItem.vendorCode != null? (vendorPart.purchasePrice > 0 ? (parseFloat((vendorPart.purchasePrice * 1.50).toFixed(2))) : 1.50) : (vendorPart.purchasePrice <= 0 ? 1.50 : cartItem.finalPrice);
                const coreknownPartBuyoutAmount = vendorPart.hasCore ? (vendorPart.corePurchasePrice > 0 ? vendorPart.corePurchasePrice : 1) : 0;
                const coreknownPartPrice = vendorPart.hasCore ? (cartItem.vendorCode != null ? (vendorPart.corePurchasePrice > 0 ? Number((vendorPart.corePurchasePrice * 1.33).toFixed(2)): 1.33) : cartItem.corePrice) : 0;
                return {...cartItem, vendorCode: vendorPart.vendorNumber, knownPartBuyoutAmount, knownPartPrice, coreknownPartBuyoutAmount, coreknownPartPrice}
              }
              return cartItem;
            });
            const fixedVendor = cartItems.some(x => x.vendorCode === "" || !x.vendorCode)? false: true;
            this.patchState({ fixedVendor, cartItems });
          }),
          catchError(error => {
            this.toastService.errorMessage('HotFlagComponent', 'getVendorPrices', 'getVendorPrices', error);
            return error;
          })
        );
      })
    )
  );

  public readonly getCartItems = this.effect((cartItems$: Observable<CartResultLineItem[]>) =>
    cartItems$.pipe(
      withLatestFrom(this.selectedCartItems$),
      tap(([cartItems, selectedCartItems]) => {
        const initialCartItemSelections = cartItems.map(x => {
          const cartItem = selectedCartItems.find(selectedItem => selectedItem.cartItemId === x.cartItemId);
          const selected = cartItem?.selected;
          const inventory = cartItem?.inventory;
          return ({...x, selected, inventory});
        });
        this.patchState({ cartItems: initialCartItemSelections });
      })
    )
  );

  public readonly toggleSelection = this.effect((cartItemId$: Observable<string>) =>
    cartItemId$.pipe(
      tap((cartItemId: string) => {
        const cartItems = this.get().cartItems.map(cartItem => {
          if(cartItem.cartItemId === cartItemId){
            return {...cartItem, selected: !cartItem.selected};
          }
          return cartItem
        })
        this.patchState({ cartItems });
      })
    )
  );

  public readonly deleteSelectedItems = this.effect((params$: Observable<void>) =>
      params$.pipe(
        withLatestFrom(this.selectedCartItems$),
        tap(([_, cartItems]) => {
          const selectedItems: CartResultLineItemSelection[] = cartItems;
          const isPickup = selectedItems.some(x => x.deliveryOption === "W");
          const itemsToRemoveFromCart = selectedItems.map(selectedItem => {
            return {
              cartItemId: selectedItem.cartItemId,
              quantityAvailable: selectedItem.quantityAvailable
            } as ItemToRemoveFromCart;
          });
          this.store.dispatch(CartActions.removeItemsFromCart({ itemsToRemoveFromCart, isPickupDeliveryType: isPickup }))
        })
      )
  );

  public readonly getHotFlags = this.effect((params$: Observable<void>) =>
    params$.pipe(
      switchMap(() => this.cartService.getHotFlags()
        .pipe(
          tap((hotFlags) => {
            this.patchState({hotFlags});
          }),
          catchError((error) => {
            this.toastService.errorMessage("MyCartComponentStore", "GetHotFlags", "GetHotFlags", error);
            return error;
          })
        )
      )
    )
  );

  public readonly updateCartItemHotFlag = this.effect((params$: Observable<void>) =>
    params$.pipe(
        withLatestFrom(this.store.select(CartSelectors.selectCart), this.selectedCartItems$),
        switchMap(([_, selectedCart, selectedCartItems]: [any, CartResult, CartResultLineItemSelection[]]) => {

          let updateCartData: UpdateCartItemFlagRequest = {
            cartId: selectedCart.cartId,
            cartItemFlag: 'IsHotFlag',
            vin: selectedCart.vin,
            updateFlagCartItems: selectedCartItems as any[]
          };

          return this.cartService.updateCartItemFlag(updateCartData).pipe(
            map((cart) => {
              this.toastService.showToast('Hot Flag Updated Successfully for selected part(s).', ToastType.Success);
              this.store.dispatch(CartActions.updateHotFlagOnCartItemSuccess({ cart }));
              // this.patchState({cartItems: cart.lineItems.map(x => ({...x, selected: false}))})
            }),
            catchError(error => {
              this.toastService.errorMessage('HotFlagComponent', 'updateCartItemsHotFlag', 'UpdateCartItemFlag', error);
              return error;
            })
          )
        })
      )
  );


  public readonly confirmPartsBuyout = this.effect((cartItems$: Observable<void>) =>
    cartItems$.pipe(
      withLatestFrom(this.selectedCartItems$),
      tap(([_, selectedCartItems]) => {
        this.store.dispatch(CartActions.confirmPartsBuyout({ selectedCartItems }));
      })
    )
  );

}
