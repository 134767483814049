import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { REGEX_PHONE_PATTERN } from "app/app.constants";
import { OrderDetails } from "entities/order-details";
import { Observable } from "rxjs";
import { AppState } from "store/app-state";
import { inviteToPayResend } from "store/customer/customer.actions";
import { selectInviteToPayResendLoading } from "store/customer/customer.selectors";
import { InviteToPayFlag, InviteToPayForm, InviteToPayOptions } from "./invite-to-pay.model";
import { inviteToPayValidator } from "./invite-to-pay.validator";

@Component({
  selector: 'invite-to-pay',
  templateUrl: './invite-to-pay.component.html',
  styleUrls: ['./invite-to-pay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InviteToPayComponent implements OnInit {

  @Input()
  i2p: InviteToPayForm;

  @Input()
  orderDetails: OrderDetails;

  @Output()
  onSuccess: EventEmitter<void> = new EventEmitter();

  public options = InviteToPayOptions;
  public i2pForm: UntypedFormGroup = this.formBuilder.group({
    i2p: [this.options.email],
    i2pEmail: [''],
    i2pSms: ['']
  }, {
    validators: inviteToPayValidator(this.regexPhonePattern)
  });

  public busy$: Observable<boolean> = this.store.select(selectInviteToPayResendLoading);

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    @Inject(REGEX_PHONE_PATTERN) public regexPhonePattern: RegExp
  ) { }

  ngOnInit(): void {
    this.i2pForm.patchValue(this.i2p);
  }

  keyPressed(event: KeyboardEvent): void {
    const pattern = /[0-9\-\ ]/;
    const inputChar = event.key;
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onSubmit(): void {
    const values = this.i2pForm.getRawValue();
    this.store.dispatch(inviteToPayResend({
      i2pResendRequest: {
        flag: values.i2p === this.options.email ? InviteToPayFlag.email : InviteToPayFlag.sms,
        email: values.i2pEmail,
        phoneNumber: values.i2pSms,
        orderNumber: this.orderDetails?.orderHistory?.orderNumber,
        shipToCustomer: this.orderDetails?.orderHistory?.shipToNumber
      },
      onSuccess: () => {
        this.onSuccess.emit();
      }
    }));
  }

}
