import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CurrencyCode } from 'entities/currency-code';
import { HotFlagCodes } from 'entities/purchase-order/hot-flag';
import { ColumnName, createOrderItemColumnClass } from 'helpers/order-item-column-creator';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { CommonDataService } from 'services/common-data.service';
import { ConfigurationService } from 'services/configuration.service';
import { AppState } from 'store/app-state';
import * as BranchSelectors from "store/branch/branch.selectors";


@Component({
  selector: 'order-item',
  templateUrl: './order-item.component.html',
  styles: []
})
export class OrderItemComponent implements OnInit {
  @Input() public idSuffix: string;
  @Input() public orderItem: any;
  public showCouponColumn: boolean = false;
  @Input('showCouponColumn') set showCouponColumnAttribute(value: any) {
    this.showCouponColumn = value === '' || value;
  }
  @Input() public couponValue: number;
  @Input() public index: number;

  public isPartsBuyout: boolean;
  public coreOptionText: string;
  public orderItemValue: number;
  public isHotFlagValid: boolean = false;

  public isInternalUser$: Observable<boolean> = this.configurationService.user$
  .pipe(
    map((user) => user.isInternal),
    shareReplay(1)
  );

  public isWmBranch$: Observable<boolean> = this.commonDataService.branch$
  .pipe(
    map(branch => branch.isWarehouseManaged)
  );

  public currencyCode$: Observable<CurrencyCode> = this.store.select(BranchSelectors.selectCurrencyCode);

  constructor(
    private configurationService: ConfigurationService,
    private commonDataService: CommonDataService,
    private store: Store<AppState>
    ) { }

  ngOnInit() {
    this.isPartsBuyout = this.orderItem.partNumber && this.orderItem.partNumber.toUpperCase() === "PARTSBUYOUTTX";
    this.coreOptionText = this.getCoreOptionText(this.isPartsBuyout);
    this.orderItemValue = this.calculateOrderItemValue();
    this.checkHotFlag();
  }

  private checkHotFlag() {
    if (!!this.orderItem &&  this.orderItem?.isHotFlag) {
        if (this.orderItem?.hotFlagCode === "95" || this.orderItem?.hotFlagCode === "99") {
          this.isHotFlagValid = false;
        }
        else {
          this.isHotFlagValid = true;
        }
    }
  }

  private getCoreOptionText(isPartsBuyout: boolean): string {
    if (!this.orderItem.corePrice) { return ""; }

    if (isPartsBuyout) {
      switch (this.orderItem.coreOption.toUpperCase()) {
        case "NOCORE": return "No Core";
        case "NICORE": return "Non-Inventory Core";
        case "NICORER": return "Non-Inventory Core w Return";
        default: return "";
      }
    }

    switch (this.orderItem.coreOption.toUpperCase()) {
      case "NOCORER": return "No Core Returned";
      default: return "Core Returned";
    }
  }

  private calculateOrderItemValue(): number {
    let pricePerUnit = this.orderItem.finalPrice;
    if (this.orderItem.coreOption === 'NOCORER' || this.orderItem.partBuyoutCoreOption === 'NICORE') {
      pricePerUnit = this.orderItem.priceWithCore;
    }
    return pricePerUnit * this.orderItem.quantity + (this.couponValue || 0);
  }

  getHotFlagValueByCode(hotFlagCode?: string): string {
    let hotFlagValue = HotFlagCodes.find(f => f.key === hotFlagCode)?.value ?? "";
    let hotFlagKey = HotFlagCodes.find(f => f.key === hotFlagCode)?.key ?? "";
    let retVal = hotFlagKey + ' - ' + hotFlagValue;
    return retVal;
  }

  createColumnClass(name: ColumnName, isInternalUser: boolean) {
    return createOrderItemColumnClass(name, isInternalUser, this.showCouponColumn, this.orderItem?.isHotFlag);
  }
}
