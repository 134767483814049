import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggerService } from 'services/logger.service';
import { ErrorInformation } from 'entities/error-information';
import { ToastService } from 'services/toast.service';
import { ToastType } from 'entities/toast-type';
import { HttpResponseStatus } from './http-response-status';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  private bypassUrls: string[] = [
    'DownloadGmInvoicePdfFile',
    'GetPickTicket',
    'GetPrintTicket',
    'autocomplete'
  ];
  private rethrowUrls: RegExp[] = [
    /\/api\/clientConfiguration/i,
    /\/api\/priceExceptionDashboard\/getPriceExceptionRequests/i,
    /\/api\/coupon\/ImportCouponJsonFile/i,
    /\/api\/customers\/GetCustomerNotes/i,
    /\/api\/dashboards\/getopencartsquotesorders/i,
    /\/api\/orders\/PlaceOrder/i,
    /\/api\/orders\/CreateQuote/i,
    /\/api\/dashboards\/getopenitemscount/i,
    /\/api\/dashboards\/GetOrderDetails/i,
    /\/api\/orders\/[^\/]*\/procurement/i,
    /\/api\/orders\/UpdateQuote/i,
    /\/api\/carts\/GetCartConsolidatedData/i,
    /\/api\/carts\/[^\/]*\/updateBillTo/i,
    /\/api\/carts\/[^\/]*\/updateShipTo/i,
    /\/api\/carts\/[^\/]*\/updateSmsOpt/i,
    /\/api\/loyalty\/[^\/]*\/my/i,
    /\/api\/loyalty\/[^\/]*\/[^\/]*/i,
    /\/api\/loyalty\/[^\/]*\/[^\/]*\/search/i,
    /\/api\/loyalty\/[^\/]*\/[^\/]*\/[^\/]*/i,
    /\/api\/orders\/CreateBilling/i,
    /\/api\/parts\/getPartBinLocations/i,
    /\/api\/parts\/GetRelatedParts/i,
    /\/api\/parts\/GetAlternateParts/i,
    /\/api\/purchaseOrders\/batch/i,
    /\/api\/user\/accepteula/i,
    /\/api\/carts\/[^\/]*\/batch-add/i,
    /\/api\/punchout\/parts\/any/i,
    /\/api\/notes/i,
    /\/api\/cartitem\/[^\/]*\/notes/i,
    /\/api\/cartitem\/UpdateCartItemNotes/i
  ];

  constructor(
    private router: Router,
    private loggerService: LoggerService,
    private toastService: ToastService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(catchError((error) => {
        return this.handleResponseError(error, req.body);
      }) as any);
  }

  private handleResponseError(response: HttpErrorResponse, requestBody: any): Observable<any> {
    // TODO: check if this is ok, but should make sense to log all errors equally and on one place
    if (response.error) this.loggerService.error(response.error);

    if (response.status === HttpResponseStatus.INTERNAL_SERVER_ERROR) {
      return throwError(response.error);
    }

    if (
      response.status === HttpResponseStatus.BAD_REQUEST ||
      response.status === HttpResponseStatus.GONE
    ) {
      this.notifyUserAboutError(response);
      return throwError(response.error);
    } else if (this.rethrowUrls.some(rethrowUrl => rethrowUrl.test(response.url))) {
      //TODO: why rethrow just for these urls? Think this can be removed if all the response statuses are properly handled,
      // shouldn't be required to handle specific urls, except with some really special reason or case

      // throw the error so the service can handle the actual error...
      return throwError(response.error);
    } else {
      const errorMessage = this.errorMessage(response);
      const errorObj: Error = new Error(
        JSON.stringify({
          Message: response.status == HttpResponseStatus.UNAUTHORIZED ? 'Unauthorized: Access is denied.' : errorMessage,
          Type: response.status,
          url: response.url,
          Paramters: requestBody
        }));
      this.loggerService.trackException(errorObj, 'Http service');

      this.loggerService.verbose('URL : ' + response.url + ' || Response Type : ' + response.status);
      if (response.status == HttpResponseStatus.FORBIDDEN) {
        this.router.navigate(['/']);
        return this.getErrorResponse(response.status, response.statusText, errorMessage);
      } else {
        return this.getErrorResponse(response.status, response.statusText, errorMessage);
      }
    }
  }

  notifyUserAboutError(response: HttpErrorResponse) {
    if (Array.isArray(response.error)) {
      const toasts = response.error
        .map((message: string) => ({ message, type: ToastType.Error }));
      this.toastService.showMultilineToast(toasts);
    } else {
     this.toastService.showToast(response.error, ToastType.Error);
    }
  }

  errorMessage(response: { error: { Message: string; }; }): string {
    return response.error != null ? response.error.Message : "No error information";
  }

  getErrorResponse(status: number, statusText: string, message: string): any {
    return of(new HttpResponse(
      {
        body: Object.assign(new ErrorInformation(),
          {
            Message: message,
            ErrorType: status
          }),
        status: status,
        statusText: statusText
      }));
  }
}
