import { CheckoutValidationMessages } from "app/_components/checkout-validation/checkout-validation.messages";

export function mapErrorKeysToMessages(
  errorKeys: { [key: string]: boolean }, prefix = ""): { [key: string]: string } {
  let errors = null;
  for (const key in errorKeys) {
    if (Object.prototype.hasOwnProperty.call(errorKeys, key) && errorKeys[key] === true) {
      errors = { ...errors, [prefix + key]: CheckoutValidationMessages[`${prefix}${key}`] }
    }
  }
  return errors;
}
