import { Component } from '@angular/core';
import { ToastService } from 'services/toast.service';
import { ToastType } from 'entities/toast-type';

@Component({
  selector: 'pc-toast',
  templateUrl: './toast.component.html'
})
export class ToastComponent {
  toastType = ToastType
  constructor(
    public toastService: ToastService
  ) { }

  closeToast() {
    this.toastService.hideToast();
  }

  closeStaticToast() {
    this.toastService.hideStaticToast();
  }
}
