import { CartDetails } from 'entities/cart-details';

export class AppInsightPartSearch {
    userId: string = '';
    customerNumber: string = '';
    branchNumber: string = '';
    searchTerm: string = '';
    source: string = '';
    cartDetails: CartDetails;
    results: string = '';
    plMetricName: string = '';
}
