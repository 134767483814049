import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { first, map } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class AuthenticationGuardOptions {
  action: "login" | "redirect" | "stop";
  redirectUrl?: string | null;
}

@Injectable({
  providedIn: "root"
})
export class AuthenticationGuard  {
  constructor(
    private options: AuthenticationGuardOptions,
    private authenticationService: AuthenticationService,
    private router: Router,
    @Inject(DOCUMENT) private document: any
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.authenticationService.userProfile$
        .pipe(
          first(),
          map((user) => {
            if (user) {
              return true;
            }

            switch (this.options.action) {
              case "login":
                this.authenticationService.login();
                return false;
              case "redirect":
                if (this.options.redirectUrl) {
                  this.document.location.assign(this.options.redirectUrl);
                  return false;
                }
                break;
              case "stop":
                break;
            }

            const tree = this.router.parseUrl("");
            return tree;
          })
        );
  }
}
