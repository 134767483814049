
<button id="MSCPayersModal_Button_Close" type="button" class="close" data-dismiss="modal" aria-label="Close" tabindex="-1" (click)="closeModal()">
  <span aria-hidden="true">&times;</span>
</button>
<div class="modal-header">
  <h4 class="modal-title">MSC Payers</h4>
</div>
<div class="modal-body">
  <div id="divMSCPayers">
    <form>
      <div class="form-group">
        <label class="strong" for="MSCPayersModal_MSCCardNumber">MSC card Number </label>
        <input id="MSCPayersModal_Text_MSCCardNumber" type="text" class="form-control" name="txtmscCardNumber" [(ngModel)]="mscCardNumber">
      </div>
      <div class="row">
        <div class="col-md-6 msc-payer-list">
          <div class="form-group" *ngIf="payerOptions">
            <label id="MSCPayersModal_Label_PayersLabel" *ngIf="payerOptions.length > 0 " class="strong">Payers </label>
            <div *ngFor="let payerOption of payerOptions; let i = index">
              <input id="MSCPayersModal_Radio_PayerOptions_{{i}}" type="radio" name="payerNumberOptions"
                [(ngModel)]="payer" [value]="payerOption" required>
              <label for="MSCPayersModal_Radio_PayerOptions_{{i}}">{{payerOption.name}}</label>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-6 msc-payer-list">
          <div class="form-group" *ngIf="customerMSCPayerData.shipTos">
                            <label *ngIf="customerMSCPayerData.shipTos.length > 0 ">Ship To </label>
                            <table class="table ">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Payer Number</th>
                                        <th>MSC Card Number</th>
                                        <th>Is Default</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let shipTo of customerMSCPayerData.shipTos" >
                                        <td>{{shipTo.name}}</td>
                                        <td>{{shipTo.payerNumber}}</td>
                                        <td>{{shipTo.mscCardNumber}}</td>
                                        <td>{{shipTo.isDefault}}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
        </div> -->
      </div>
    </form>
  </div>
</div>
<div class="modal-footer">
    <button id="MSCPayersModal_Button_SetPayerDetails" type="submit" class="btn btn-primary" (click)="setPayer()">Set Payer Details</button>
</div>
