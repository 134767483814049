export const BackCounterConfig = {
  activeRequestsLimit: 3,
  requestsTablePageSize: 25,
  mockData: false,
  routes: {
    dashboard: '/back-counter/dashboard'
  }
};

export const ToastMessages = {
  ERROR_COUNTERS: "Error while loading requests overview",
  ERROR_DETAILED_COUNTERS: (detail: string) => `Error while loading requests overview: ${detail}`,
  ERROR_UPDATEREQUEST: "Error while updating request",
  ERROR_DETAILED_UPDATEREQUEST: (detail: string) => `Error while updating request: ${detail}`,
  ERROR_DETAILED_COMPLETING_REQUEST: (detail: string) => `Error while completing request: ${detail}`,
  ERROR_LOADINGREQUESTS: "Error while loading requests",
  ERROR_DETAILED_LOADINGREQUESTS:  (detail: string) => `Error while loading requests: ${detail}`,
  ERROR_LIMIT: "Only 3 requests can be open at one time. Please close a request before opening another",
  REQUEST_LOCKED: (username: string) => `This request is locked for editing by ${username}. Please refresh the dashboard or wait till another user unlocks it.`,
  ADD_PART_SUCCESS: "Part was added to the request successfully.",
  UPDATE_SUCCESS: "Changes saved successfully.",
  UPDATE_FAILED: "Error has occured while saving request changes.",
  UPDATE_DETAILED_FAILED: (detail: string) => `Error while saving request: ${detail}`,
  ADD_PART_FAILED: "Adding part to request has failed.",
  GENERAL_REQUEST_ADD_SUCCESS: "Request Added Successfully.",
  GENERAL_REQUEST_COMPLETED: "General Request Completed Successfully.",
  REQUEST_COMPLETED: "Request Completed Successfully.",
  DEFAULT_ERROR: "Error has occured."
};
