import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterbykey' })
export class FilterByKeyPipe implements PipeTransform {
  transform(items: any[], key: string): any[] {
    if (!key || key === "") {
      return items;
    }
    return items?.filter(i => i.key.includes(key));
  }
}
