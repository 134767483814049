import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of } from "rxjs";
import { catchError, filter, map } from "rxjs/operators";
import { ConfigurationService } from 'services/configuration.service';
import { LoggerService } from 'services/logger.service';
import { EULA_VERSION } from 'app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class EulaGuard  {

  constructor(
    private router: Router,
    private configurationService: ConfigurationService,
    private loggerService: LoggerService,
    @Inject(EULA_VERSION) public eulaVersion: string
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    this.loggerService.trackPageView();

    return this.configurationService.configuration$
      .pipe(
        filter((configuration) => Boolean(configuration)),
        map((configuration) => {
          if (configuration.user.eulaAcceptedVersion !== this.eulaVersion) {
            this.loggerService.verbose(`eulaguard blocked navigation to '${route.url}'`, configuration.user);
            const tree = this.router.parseUrl('/misc/eula');
            tree.queryParams = { returnUrl: state.url };
            return tree;
          }
          return true;
        })
      );
  }
}

