<div class="container bg-light2"
  *ngLet="{ quoteExpirationDate: quoteExpirationDate$ | async,
            currentUser: currentUser$ | async,
            branch: branch$ | async,
            customer: customer$ | async,
            cartItems: cartItems$ | async,
            includesCouponData: includesCouponData$ | async,
            cartId: cartId$ | async,
            cartCouponTotal: cartCouponTotal$ | async,
            cartCoupons: cartCoupons$ | async,
            subTotal: subTotal$ | async,
            isQuote: isQuote$ | async,
            navigationProperties: navigationProperties$ | async,
            partsSearchNavigate: partsSearchNavigate$ | async,
            isDefaultCustomer: isDefaultCustomer$ | async,
            loyaltyEnabled: loyaltyEnabled$ | async,
            hasLoyaltyProgram: hasLoyaltyProgram$ | async,
            currencyCode: currencyCode$ | async,
            cartLoading: cartLoading$ | async
          } as vm">
  <div class="row">
    <div *ngIf="vm.customer" class="col-sm-12 col-md-4 col-lg-3 customer-name py-1">
      <div *ngIf="vm.currentUser?.isInternal; else extCustomerDisplay" id="headerSearch_div_ReadCustomers" class="gx-1 d-flex align-items-center">
        <span>Customer:</span>
        <span class="header-customer-search" ngbDropdown autoClose="outside"  >
          <button id="headerSearch_button_CustomerDropdown" class="btn btn-outline-secondary" id="header-customer-search-name-btn" title="{{vm.customer?.customerName}} ({{vm.customer?.customerNumber}})" ngbDropdownToggle>
            <span id="header-customer-search-name">{{vm.customer?.customerName}}</span>
            <span>&nbsp;({{(vm.customer?.customerNumber | customerNumber) || 'loading customer data...'}})</span>
            <span class="caret"></span>
          </button>
          <form id="headerSearch_form_DropdownMenu" ngbDropdownMenu class="p-4">
            <div>
              <button id="headerSearch_button_UseDefaultCustomer" class="btn btn-secondary btn-sm" (click)="selectDefaultCustomer()">Use Default Customer</button>
            </div>
            <customer-panel id="headerSearch_customer-panel_Customers" (customerSelect)="onCustomerSelect($event)"></customer-panel>
          </form>
        </span>
      </div>
      <ng-template #extCustomerDisplay>
        <div id="headerSearch_div_CustomerName" class="gx-1">
          <span>Customer Name:</span>
          <span>{{vm.customer?.customerName}}</span>
          <span id="headerSearch_div_CustomerNumber">({{ (vm.customer?.customerNumber | customerNumber) || 'loading customer data...' }})</span>
        </div>
      </ng-template>
      <div class="d-flex">
        <div id="headerSearch_div_CustomerNotes" class="me-4" *ngIf="vm.currentUser?.isInternal && !vm.isDefaultCustomer">
          <a class="hyperlink" href="javascript:void(0)" (click)="showCustomerNotes(vm.customer.customerNumber)">Customer Notes</a>
        </div>
        <loyalty-accounts-display></loyalty-accounts-display>
      </div>
    </div>

    <div class="col-md-2 col-lg-2 py-1">
      <button id="loyaltyAccounts_button_selectloyalty" type="button" class="btn btn-primary position-relative"
        (click)="selectLoyaltyAccounts()" *ngIf="vm.loyaltyEnabled && vm.hasLoyaltyProgram"
      >
        Select Loyalty Account
        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{numberOfLoyaltyAccounts$ | async}}</span>
      </button>
    </div>

    <div class="col-md-4 col-lg-2 py-1" *ngLet="vm.currentUser">
      <div class="d-flex align-items-center gx-1">
        <span>Branch:</span>
          <branch id="headerSearch_div_Branch" [ngModel]="vm.branch" (ngModelChange)="onBranchSelect($event)" class="w-100"></branch>
      </div>
    </div>
    <div class="col-sm-6 col-md-8 col-lg-3 customer-search py-1">
      <form id="headerSearch_form_Search" #searchForm="ngForm" class="needs-validation" [ngClass]="{ 'was-validated': !searchForm.pending }" (ngSubmit)="partSearch()">
        <div class="form-group">
          <div class="input-group">
            <input id="headerSearch_text_SearchTerm" type="text" class="form-control" onfocus="this.select();"
              name="headerSearch_text_SearchTerm" aria-describedby="txtSearchTerm" ngModel="{{searchTerm}}"
              #txtSearchTerm [ngbTypeahead]="autocomplete" (selectItem)="selectSearchTerm($event)"
              (ngModelChange)="updateSearchTerm($event)" [focusFirst]="false">
              <a (click)="partSearch()" href="javascript:void(0)" class="input-group-text">
                <i class="fas fa-search" aria-hidden="true"></i>
              </a>
          </div>
          <div class="d-flex">
            <input id="headerSearch_checkbox_IsExpandedSearch" type="checkbox" name="headerSearch_checkbox_IsExpandedSearch" #isExpandedSearch ngModel (ngModelChange)="updateIsExpandedSearch($event)">
            <label for="headerSearch_checkbox_IsExpandedSearch">Expanded Industry Search</label>
            <a class="d-inline-block" id="headerSearch_a_PartsBuyout" *ngIf="vm.currentUser?.isInternal" role="button" class="hyperlink ms-auto" href="javascript:void(0)" (click)="partBuyoutModal()">Parts Buyout
            </a>
          </div>
      </div>
      </form>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-2 d-flex justify-content-end position-relative">
      <span (mouseover)="showCarts = true" (mouseout)="showCarts = false" >
        <button id="headerSearch_button_MyCart" class="my-cart-btn bg-primary position-relative" role="button" (click)="onMyCartClick();">
          <a style="vertical-align: text-bottom;">My Cart</a>
          <i class="fas fa-shopping-cart fa-2x"></i>
          <span *ngIf="!vm.cartLoading; else cartLoading">{{vm.cartItems ? vm.cartItems?.length : 0}}</span>
          <ng-template #cartLoading>
            <span class="countLoader"></span>
          </ng-template>
        </button>
        <div id="headerSearch_div_FindMyCartClass" class="my-cart-list" [ngClass]="{ 'd-none': !showCarts }">
          <div class="py-2 d-flex align-items-baseline gx-2">
            <h4 class="strong m-0">My Cart</h4>
            <div id="headerSearch_div_CartCount" *ngIf="vm.cartItems">{{ vm.cartItems?.length }} Items</div>
          </div>
          <ng-container>
            <div id="headerSearch_div_NoCartData" *ngIf="!vm.cartItems?.length">cart item is not available.</div>
            <ng-container *ngIf="vm.cartItems?.length">
              <div id="headerSearch_div_CartData" style="overflow-y:scroll; height:200px">
                <table class="table">
                  <thead>
                    <tr>
                      <th width="50px">Part</th>
                      <th width="250px">Item</th>
                      <th width="20px">Qty</th>
                      <th width="120px">Everyday Price</th>
                      <th id="headerSearch_th_TotalCoupons" *ngIf="vm.includesCouponData" width="120px">Total Coupons</th>
                      <th id="headerSearch_th_FinalPrice" *ngIf="vm.includesCouponData" width="120px">Final Price</th>
                      <th id="headerSearch_th_Total" *ngIf="!vm.includesCouponData" width="120px">Total</th>
                      <th width="20px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr id="headerSearch_th_CartData_{{i}}" *ngFor="let item of vm.cartItems; let i = index">
                      <td>{{item.partNumber}}</td>
                      <td>
                        <div>{{item.description}}</div>
                        <div id="headerSearch_div_NotPartsBuyoutTxCoreOption_{{i}}" *ngIf="item.corePrice>0 && item.partNumber != 'PARTSBUYOUTTX' " class="small">
                          {{(item.coreOption == "NOCORER") ? "No Core Returned" : "Core Returned"}}
                        </div>
                        <div id="headerSearch_th_PartsBuyoutTxCoreOption_{{i}}" *ngIf="item.corePrice>0 && item.partNumber == 'PARTSBUYOUTTX'" class="small">
                          <ng-container [ngSwitch]="item.partBuyoutCoreOption">
                            <ng-container *ngSwitchCase="'NOCORE'">No Core</ng-container>
                            <ng-container *ngSwitchCase="'NICORE'">Non-Inventory Core</ng-container>
                            <ng-container *ngSwitchCase="'NICORER'">Non-Inventory Core w Return</ng-container>
                          </ng-container>
                        </div>
                      </td>
                      <td class="text-end">{{item.quantity}}</td>
                      <td>
                        {{(item.finalPrice + ((item.coreOption == "NOCORER" || item.partBuyoutCoreOption
                        == 'NICORE') ? item.corePrice: 0))| currency: vm.currencyCode :'symbol':'1.2-2'}}
                      </td>
                      <td id="headerSearch_td_IncludeCouponData" *ngIf="vm.includesCouponData" class="gy-2 pricingContainer">
                        <div class="m-0">
                          <a id="headerSearch_a_PartCouponTotalPopover" [ngbPopover]="myPartcouponPopover" href="javascript:void(0);" placement="bottom"
                            class="popoverlink text-danger"
                            *ngIf="item.couponTotal > 0">
                            {{ ( item.couponTotal * item.quantity ) || 0 | currency: vm.currencyCode :'symbol':'1.2-2'}}
                            <i class="fas fa-caret-down align-text-bottom"></i>
                          </a>
                        </div>
                        <ng-template id="headerSearch_template_PartCouponPopover" #myPartcouponPopover>
                          <coupon-popover [coupons]="item.sapCoupons" [idSuffix]="i" [quantity]="item.quantity"></coupon-popover>
                        </ng-template>
                      </td>
                      <td class="text-danger">
                        {{(item.quantity * (item.finalPrice + ((item.coreOption == "NOCORER" || item.partBuyoutCoreOption
                        == 'NICORE') ? item.corePrice: 0))) - ( item.couponTotal * item.quantity ) | currency: vm.currencyCode :'symbol':'1.2-2'}}
                      </td>
                      <td>
                        <a class="text-danger" role="button" href="javascript:void(0)" [ngClass]="{'switchcartdisabled': false}"
                          (click)="removeCartItems(item);">
                          <i class="far fa-trash-alt" aria-hidden="true"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div id="headerSearch_div_DeleteCartData" >
                <div class="p-3 d-flex justify-content-between">
                  <a id="headerSearch_a_DeleteCart" href="javascript:void(0)" (click)="deleteCart(vm.cartId)">{{vm.currentUser?.isInternal ? "Delete Cart": "Clear Cart"}}</a>
                    <div>
                      <h5 *ngIf="vm.cartCouponTotal" class="strong">Cart Coupons</h5>
                      <h5 class="strong">Subtotal</h5>
                    </div>
                    <div>
                      <h5 *ngIf="vm.cartCouponTotal" class="strong">
                        <a id="headerSearch_a_PartCouponTotalPopover" [ngbPopover]="myPartcouponPopover" href="javascript:void(0);" placement="bottom"
                            class="popoverlink text-danger">
                            {{vm.cartCouponTotal | currency: vm.currencyCode :'symbol':'1.2-2'}}
                            <i class="fas fa-caret-down align-text-bottom"></i>
                        </a>
                        <ng-template id="headerSearch_template_PartCouponPopover" #myPartcouponPopover>
                          <coupon-popover [coupons]="vm.cartCoupons"></coupon-popover>
                        </ng-template>
                      </h5>
                      <h5 class="strong">{{vm.subTotal | currency: vm.currencyCode :'symbol':'1.2-2'}}</h5>
                    </div>
                </div>
                <div>
                  <button id="headerSearch_button_Checkout" class="btn btn-secondary w-100" (click)="onMyCartCheckoutClick();">My Cart</button>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </span>
    </div>
  </div>
  <div id="headerSearch_div_IsQuote" class="px-3 gx-2" *ngIf="vm.isQuote">
    <span>Quote Number: {{quoteNumber$ | async}}</span>
    <span id="headerSearch_span_HasUnsavedChanges" *ngIf="vm.hasUnsavedChanges" class="text-danger">Unsaved Changes</span>
    <span>Expiration Date: {{vm.quoteExpirationDate | date:'MM/dd/yy'}}</span>
  </div>
</div>
