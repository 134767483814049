import { Injectable } from '@angular/core';
import { PartService } from './part.service';
import { LoggerService } from './logger.service';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, withLatestFrom, switchMap, catchError } from 'rxjs/operators';
import { CommonDataService } from './common-data.service';
import { Store } from '@ngrx/store';
import { AppState } from 'store/app-state';
import * as BranchSelectors from 'store/branch/branch.selectors';
import * as CustomerSelectors from 'store/customer/customer.selectors';

@Injectable({
  providedIn: 'root'
})
export class AutocompleteService {

  public clearFilters$: Subject<void> = new Subject();

  constructor(
    private partService: PartService,
    private loggerService: LoggerService,
    private store: Store<AppState>) { }

  public autocomplete = (text$: Observable<[string, boolean]>): Observable<string[]> =>
    text$.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      withLatestFrom(this.store.select(BranchSelectors.selectedBranch), this.store.select(CustomerSelectors.selectedCustomer)),
      switchMap(([[term, isExpandedSearch], branch, customer]) => {
        if (term && term.length > 2) {
          return this.partService.autocomplete(term, branch.code, customer.customerNumber, isExpandedSearch);
        }
        return of([]);
      }),
      catchError(error => {
        this.loggerService.error(error);
        return of([]);
      })
    );

    public clearFilters(): void {
      this.clearFilters$.next(null);
    }
}
