import { DefaultProjectorFn, MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { DeliveryOptions } from 'app/_components/checkout-line-item-delivery/checkout-line-item-delivery.component.state';
import { CartResult, CartResultLineClient } from 'entities/cart-result';
import { DeliverySummary } from 'entities/carts/delivery-summary';
import { CheckoutSmsOpt } from 'entities/checkout-sms-opt';
import { ShipToCustomer } from 'entities/ship-to-customer';
import { isFreightOrDelivery } from 'helpers';
import { calculatePartCouponTotals } from 'services/coupon.service';
import { AppState } from 'store/app-state';
import * as ConfigurationSelectors from 'store/configuration/configuration.selectors';


export const selectCart = createFeatureSelector< CartResult>('selectedCart');

export const selectCartShipToCustomer = createSelector(selectCart,
  (state) => {
    if(state.shipToAddressNo === null || state.shipToAddressNo === undefined){
      return null;
    }
    const shipToCustomer = {
      addressNumber: state.shipToAddressNo,
      city: state.city,
      customerName: state.name1,
      customerName2: state.name2,
      mscAccountNumber: state.msc,
      phoneNumber: state.phone,
      postalCode: state.zip,
      state: state.state,
      streetAddress: state.address,
      customerNumber: state.shipToAddressNo,
      isEdited: state.isAlternateAddressEdited,
      salesperson: state.salesPerson
    } as ShipToCustomer;
    return shipToCustomer;
  }
);

export const selectCartItems = createSelector(
  selectCart,
  (state) => state.lineItems
);

export const selectIsShippingRatesLoading = createSelector(
  selectCart,
  (state) => state.isShippingRatesLoading
);

export const selectShippingRates = createSelector(
  selectCart,
  (state) => state.shippingRates
);

export const selectCartHeaderDeliveryType = createSelector(
  selectCart,
  (state) => state.deliveryType
);


export const selectCartShipToAddressNo = createSelector(
  selectCart,
  (state) => state.shipToAddressNo
);

export const selectCartId = createSelector(
  selectCart,
  (state) => state.cartId
);

export const selectShippingRate = createSelector(
  selectCart,
  (state) => state.shippingRate
);

export const selectProp65 = createSelector(
  selectCart,
  (state) => state.prop65 ?? false
);

export const selectPayers = createSelector(
  selectCart,
  (state) => state.lineItems
);

export const selectIsQuote = createSelector(
  selectCart,
  (state) => !!state.orderNumber
);

export const selectQuoteNumber = createSelector(
  selectCart,
  (state) => state.orderNumber != null && state.orderNumber != "" ? state.orderNumber : null
);

export const hasUnsavedChanges = createSelector(
  selectCart,
  state => state.hasUnsavedChanges
);

export const selectPONumber = createSelector(
  selectCart,
  state => state.poNumber
);

export const selectUnitNumber = createSelector(
  selectCart,
  state => state.unitNumber
);

export const selectSpecialInstruction = createSelector(
  selectCart,
  (state) => state.specialInstruction
);

export const selectAlternateEmail = createSelector(
  selectCart,
  state => state.alternateEmail
);

export const selectIsCartLocked = createSelector(
  selectCart,
  ConfigurationSelectors.selectUserInfo,
  (cart, user) => cart.isLocked && cart.updatedById !== user?.id
);

export const selectIsCartLockedForOthers = createSelector(
  selectCart,
  ConfigurationSelectors.selectUserInfo,
  (cart, user) => cart.isLocked && cart.updatedById === user?.id
);

export const selectNonProcuredCartItems = createSelector(
  selectCart,
  (state) => state.lineItems?.filter(x => !x.isHotFlag && !x.isBuyout && !x.isSTO)
);

export const selectedCartId = createSelector(
  selectCart,
  (state) => state.cartId
);

export const selectCartBranchCode = createSelector(
  selectCart,
  cart => cart.branchCode
);

export const hasSelectedCart = createSelector(
  selectedCartId,
  (cartId) => cartId !== "" && cartId !== null
);

export const selectCartCoupons = createSelector(
  selectCart,
  (state) => state.cartCoupons
);

export const selectTaxes = createSelector(
  selectCart,
  (state) => state.taxes
);

export const selectCartCouponTotal = createSelector(
  selectCartCoupons,
  (cartCoupons) => cartCoupons && cartCoupons.reduce((sum, currentCoupon) => sum + currentCoupon.amount, 0)
);

export const selectBillTo = createSelector(
  selectCart,
  (cart) => cart.billToAddressNo
);

export const selectBillingType = createSelector(
  selectCart,
  (cart) => cart.billingType
);

export const hasSapCoupons = createSelector(
  selectCartItems,
  lineItems => lineItems && lineItems.some(part => part.sapCoupons?.length)
);

export const selectOrderChannel = createSelector(
  selectCart,
  (state) => state.orderChannel
);

export const cartLoading = createSelector(
  selectCart,
  (state) => state.isLoading
);

export const selectQuoteExpirationDate = createSelector(
  selectCart,
  (state) => state.quoteExpiryDate
);

export const selectVin = createSelector(
  selectCart,
  (state) => state.vin
);


export const selectCartPartItems: MemoizedSelector<AppState, CartResultLineClient[], DefaultProjectorFn<CartResultLineClient[]>> = createSelector(
  selectCartItems,
  (lineItems) => !lineItems ? [] : lineItems
    .filter((item) => !isFreightOrDelivery(item))
    .map((item) => ({
      ...calculatePartCouponTotals(item),
      originalQuantity: item.quantity,
      lowestPrice: Math.min(
        ...[
          item.finalPrice,
          item.listPrice,
          item.adjustedPrice,
          item.verifiedPrice,
          item.priceWithCouponApplied
        ]
          .filter((price) => !isNaN(price) && price > 0)
      ),
      lowestPriceWithoutCoupon: item.finalPrice
    }))
);

export const cartHasItems = createSelector(
  selectCartItems,
  lineItems => lineItems && lineItems.filter((item) => !isFreightOrDelivery(item)).length > 0
);

export const selectDeliveryPrice = createSelector(
  selectCart,
  (state) => state.deliveryPrice
)

export const selectEstimatedFreight = createSelector(
  selectCart,
  (state) => state.freightPrice
)

export const selectIncludesProcurementFlag = createSelector(
  selectCartItems,
  lineItems => lineItems && lineItems.map(calculatePartCouponTotals)
    // Calculate derived client properties
    .map((lineItem) => ({
      // May update hasCoupon and couponTotal
      ...calculatePartCouponTotals(lineItem),
      originalQuantity: lineItem.quantity,
      lowestPriceWithoutCoupon: lineItem.finalPrice,
      lowestPrice: Math.min(
        lineItem.finalPrice,
        ...[
          lineItem.finalPrice,
          lineItem.adjustedPrice || lineItem.finalPrice,
          lineItem.verifiedPrice || lineItem.finalPrice
        ]
          .filter(price => !isNaN(price))
      )
    })).some(l =>
      l.isBuyout
      || l.isHotFlag
      || l.isSTO
      || (!isFreightOrDelivery(l) && l.quantityAvailable == 0))
);

export const getDeliveryType = createSelector(
  selectCart,
  state => state.deliveryType
);

// TODO: Consider moving this to the API as calculating a cart total can hardly be called a UI concern
export const selectCartSubtotal = createSelector(
  selectCartPartItems,
  selectCartCouponTotal,
  (partItems, cartCouponTotal) =>
    partItems && partItems
      .map((item) => item.quantity * (item.coreOption == 'NOCORER' || item.partBuyoutCoreOption == 'NICORE' ? item.corePrice + item.finalPrice : item.finalPrice) - (item.couponTotal * item.quantity) )
      .reduce((sum, extendedPrice) => sum + extendedPrice, 0)
    - cartCouponTotal
);

export const selectSmsOpt = createSelector(
  selectCart,
  state => (<CheckoutSmsOpt>{ optIn: state.smsOptIn, contactName: state.smsContactName, mobileNumber: state.smsMobileNumber })
)


export const yourSavingsSelector = createSelector(
  selectCartPartItems,
  (partItems) =>
    partItems && partItems
      .reduce((sum, li) => sum + (li.couponTotal * li.quantity), 0)
);

export const selectFreightCosts = createSelector(selectCartItems, (lineItems) =>
  lineItems && lineItems
    .filter((item) => item.isFreight)
    .reduce((sum, li) => sum + li.finalPrice, 0)
);

export const selectDeliveryCosts = createSelector(selectCartItems, (lineItems) =>
  lineItems && lineItems
    .filter(
      (item) =>
        item.isCustomerDelivery ||
        item.isRTCDelivery ||
        item.partNumber === "DELIVERY:90"
    )
    .reduce((sum, li) => sum + li.finalPrice, 0)
);

export const selectTotal = createSelector(
  selectCartSubtotal,
  selectTaxes,
  selectCartCouponTotal,
  selectFreightCosts,
  selectDeliveryCosts,
  (subTotal, taxes, cartCoupons, freightCost, deliveryCost) => subTotal + (taxes?.taxes ?? 0) + (taxes?.deliveryFee > 0 ? taxes?.deliveryFee + taxes?.deliveryFeeCredit : 0) + cartCoupons + freightCost + deliveryCost
);

export const selectFreightCartItem = createSelector(selectCartItems, (lineItems) =>
  lineItems && lineItems
    .filter((item) =>
      item.isFreight)
);

export const selectDeliveryCartItem = createSelector(selectCartItems, (lineItems) =>
  lineItems && lineItems
    .filter(
      (item) =>
        item.isCustomerDelivery ||
        item.isRTCDelivery ||
        item.partNumber === "DELIVERY:90"
    )
);

export const selectShippingCartItems = createSelector(selectCartItems, (lineItems) =>
  lineItems && lineItems
    .filter((item) =>
      item.isFreight || item.partNumber === "DELIVERY:90")
);

export const hasShoppingBasketItems = createSelector(
  selectCartItems,
  (lineItems) => lineItems && lineItems.some(lineItem => lineItem.binLocation !== null && lineItem.binLocation.startsWith('SB-'))
);

export const hasHazmat = createSelector(
  selectCartItems,
  (lineItems) => lineItems && lineItems.some(lineItem => lineItem.hazmat === true)
);

export const selectCartLines = createSelector(
  selectCart,
  state => state.lineItems && state.lineItems
    // May update hasCoupon and couponTotal
    .map(calculatePartCouponTotals)
    // Calculate derived client properties
    .map((lineItem) => ({
      // May update hasCoupon and couponTotal
      ...calculatePartCouponTotals(lineItem),
      originalQuantity: lineItem.quantity,
      lowestPriceWithoutCoupon: lineItem.finalPrice,
      lowestPrice: Math.min(
        lineItem.finalPrice,
        ...[
          lineItem.finalPrice,
          lineItem.adjustedPrice || lineItem.finalPrice,
          lineItem.verifiedPrice || lineItem.finalPrice
        ]
          .filter(price => !isNaN(price))
      )
    }))
);

export const selectDeliverySummary = createSelector(
  selectCartPartItems,
  (partItems: CartResultLineClient[]) => {
    const total = partItems?.length;
    const prefix = total > 1 ? "items" : "item";
    const deliveryOptions = partItems && partItems.reduce((res, item) => {
      const label = DeliveryOptions.find(option => option.key === item.deliveryOption)?.label;
      if (label && res.indexOf(label) === -1) {
        res.push(label)
      }
      return res;
    }, <string[]>[]);

    return <DeliverySummary>{
      total,
      message: `${prefix} fulfilled through ${deliveryOptions?.join(',')}`,
      multipleOptionsSelected: deliveryOptions?.length > 1,
      deliveryOptions: deliveryOptions
    }
  }
);

export const deliveryRequiresAddress = createSelector(
  selectCart,
  (cart: CartResult) => {
    const typesRequireAddress = ['delivery', 'shipTo', 'shipDirect'];
    const optionsRequireAddress = ['D', 'C', 'S'];
    return typesRequireAddress.includes(cart.deliveryType) || cart.lineItems?.some((cartLine) =>
      optionsRequireAddress.includes(cartLine.deliveryOption)
    );
  }
);


  export const getPaymentTypeInfo = createSelector(
    selectCart,
    (cart: CartResult) =>
       ({ billingType: cart.billingType, billToAddressNo: cart.billToAddressNo, paymentSelection: cart.paymentSelection, tokenId: cart.tokenId, i2PSmsNumber: cart.i2pSmsNumber, i2PEmail: cart.i2pEmail })
  )

export const selectCartCustomerNumber = createSelector(
  selectCart,
  (cart: CartResult) => cart.customerNumber
);

export const getTransactionType = createSelector(
  selectCart,
  (cart: CartResult) =>
      ({ transactionType: cart.transactionType })
);

export const selectCartShipTo = createSelector(
  selectCart,
  (cart: CartResult) => (cart.shipToAddressNo && cart.shipToAddressNo !== "") ? {
      addressNumber: cart.shipToAddressNo,
      customerName: cart.name1,
      customerName2: cart.name2,
      salesperson: cart.salesPerson,
      mscAccountNumber: cart.msc,
      streetAddress: cart.address,
      city: cart.city,
      state: cart.state,
      postalCode: cart.zip,
      phoneNumber: cart.phone,
      customerNumber: cart.customerNumber,
      isEdited: cart.isAlternateAddressEdited
    } as ShipToCustomer : null
);

export const paymentSelection = createSelector(
  selectCart,
  (state) => state.paymentSelection
);

export const selectSubmitLocked = createSelector(
  selectCart,
  (state) => state.isSubmitLocked
);

export const selectDeliveryItems = createSelector(
  selectCart,
  (state) => state.deliveryItems
);
