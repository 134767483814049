import { createAction, props } from '@ngrx/store';

export const toggleShowAllQuotesFlag = createAction(
  '[My Dashboard] ToggleShowAllQuotesFlag'
);

export const disableShowAllQuotesFlag = createAction(
  '[My Dashboard] DisableShowAllQuotesFlag'
);

export const setDashboardBranch = createAction(
  '[My Dashboard] Set Dashboard Branch', props<{ branchCode: string}>()
);

export const clearDashboardBranch = createAction(
  '[My Dashboard] Clear Dashboard Branch'
);

export const unlockQuoteForOthers = createAction(
  '[My Dashboard] Unlock Quote',
  props<{ cartId: string; }>()
);

export const unlockQuoteForOthersSuccess = createAction(
  '[My Dashboard] Unlock Quote Success'
);

export const unlockQuoteForOthersFailed = createAction(
  '[My Dashboard] Unlock Quote Failed',
  props<{ error: any; }>()
);
