import { Action, createReducer, on } from "@ngrx/store";
import { LoyaltyState } from "./loyalty.state";
import * as LoyaltyActions from './loyalty.actions';
import * as CustomerActions from 'store/customer/customer.actions';

export const initialState: LoyaltyState = {
  availableAccounts: [],
  account: null,
  discounts: null,
  loading: false
};

const _loyaltyReducer = createReducer<LoyaltyState, Action>(
  initialState,
  on(LoyaltyActions.setLoyaltyDiscounts,
    (state, { cachedState }) =>  cachedState
  ),
  on(
    LoyaltyActions.getLoyaltyDiscounts,
    (state, {}) => ({...state, loading: true })
  ),
  on(LoyaltyActions.loadLoyaltyDiscountsSuccess,
    (state, {discounts}) => ({...state, discounts, loading: false})
  ),
  on(LoyaltyActions.getAvailableLoyaltyAccountsSuccess,
    (state, { loyaltyAccounts }) => ({...state, availableAccounts: loyaltyAccounts})
  ),
  on(CustomerActions.clearCustomer,
    (state) =>
      ({...state, availableAccounts: initialState.availableAccounts})
  ),
  on(LoyaltyActions.getAvailableLoyaltyAccountsFailed, (state) =>
    ({...state, availableAccounts: initialState.availableAccounts})),
  on(
    LoyaltyActions.selectLoyaltyAccountSuccess,
    (state, { loyaltyAccount }) => ({
      ...state,
      account: {
        loyaltyAccountIdentifier: loyaltyAccount?.memberId,
        loyaltyProgram: "peterbilt"
      },
      loading: false
    })
  ),
  on(LoyaltyActions.clearLoyaltyAccount,
    (state, { }) => initialState)
);


export function loyaltyReducer(state: LoyaltyState | undefined, action: Action) {
  return _loyaltyReducer(state, action);
}
