import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AppState } from "store/app-state";
import { ProvinceState } from "./province.state";

export const selectProvince = createFeatureSelector< ProvinceState>('province');

export const selectProvinceLoading = createSelector(
  selectProvince,
  (state) => state.loading
);

export const selectProvinceList = createSelector(
  selectProvince,
  (state) => state.provinceList
);

export const selectProvinceCount = createSelector(
  selectProvince,
  (state) => state.provinceList.length
);

export const provinceLoaded = createSelector(
  selectProvince,
  (state) => state.provinceLoaded
);
