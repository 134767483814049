import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigurationService } from 'services/configuration.service';

@Injectable({
  providedIn: "root"
})
export class AuthorizationService {
  constructor(private configurationService: ConfigurationService) { }

  hasPermission(permission: string): boolean {
     return this.configurationService.allPermissions.includes(permission);
  }

  hasPermissionAsync(permission: string): Observable<boolean> {
    return this.configurationService.allPermissions$
      .pipe(
        map((allPermissions) => allPermissions.includes(permission))
      );
  }

  hasRole(role: string): boolean {
    return this.configurationService.allRoles.includes(role);
  }

  hasRoleAsync(role: string): Observable<boolean> {
    return this.configurationService.allRoles$
      .pipe(
        map((allRoles) => allRoles.includes(role))
      );
  }
}
