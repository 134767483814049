import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { Permission } from 'entities/enums';
import { Note } from 'entities/notes';
import { Part } from 'entities/part';
import { Observable, OperatorFunction } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AuthorizationService } from 'services/authorization.service';
import { LoyaltyService } from 'services/loyalty.service';
import { NotesService } from 'services/notes.service';
import { ToastService } from 'services/toast.service';
import { AppState } from 'store/app-state';

interface PartDetailsComponentState {
  part: Part;
  notes: Note[];
  sapCuratedNote: string;
}

@Injectable()
export class PartDetailsComponentStore extends ComponentStore<PartDetailsComponentState> {
  constructor(
    private authorizationService: AuthorizationService,
    private notesService: NotesService,
    private toastService: ToastService,
    private store: Store<AppState>,
    private loyaltyService: LoyaltyService
  ) {
    super({
      part: null,
      notes: [],
      sapCuratedNote: null
    });
  }

  public readonly part$ = this.select((state) => state.part);
  public readonly displayPartNumber$ = this.select(this.part$, (part) => part?.rushPartNumber || part?.number || 'No Part Number');
  public readonly sapCuratedNote$ = this.select((state) => state.sapCuratedNote);
  public readonly notes$ = this.select((state) => state.notes);
  public readonly noteCount$ = this.select((state) => state.notes.length + (state.sapCuratedNote ? 1 : 0));

  public readonly setPart = this.effect((part$: Observable<Part>) => part$
    .pipe(
      map((part) => part
        ? {
          ...part,
          coreOption: part?.corePrice && !part?.coreOption
            ? 'NOCORER'
            : null,
          quantity: 1
        }
        : null
      ),
      tap((part) => {
        this.patchState({ part });
      }),
      this.getNotes()
    )
  );

  public readonly changePart = this.updater((state, part: Part) => ({
    ...state,
    part: {
      ...part,
      ...this.calculateCoupons(state, part)
  }}));

  public readonly changeQuantity = this.updater((state, quantity: number) => ({
    ...state,
    part: {
      ...state.part,
      quantity
    }
  }));

  public readonly changeCoreOption = this.updater((state, coreOption: string) => ({
    ...state,
    part: {
      ...state.part,
      coreOption
    }
  }));

  public readonly refreshNotes = this.effect((source$: Observable<void>) => source$
    .pipe(
      this.getNotes()
    )
  );

  private getNotes(): OperatorFunction<any, void> {
    return (source$: Observable<void>) => source$
      .pipe(
        filter(() => this.authorizationService.hasPermission(Permission.ReadPartNotes)),
        map(() => this.get((state) => state.part?.rushPartNumber)),
        filter((partNumber) => Boolean(partNumber)),
        switchMap((partNumber) => this.notesService.getNotes(partNumber)
          .pipe(
            tapResponse(
              (result) => {
                this.patchState(result);
              },
              (error) => {
                this.toastService.errorMessage('PartsDetailsComponent', 'getNotes', 'getNotes', null);
              }
            )
          )
        ),
        map(() => null)
      );
  }

  private calculateCoupons(state: PartDetailsComponentState, part: Part): Partial<Part> {
    const coupons = part.sapCoupons.length ?[...part.sapCoupons] : [...state.part.sapCoupons];
    return {
      sapCoupons: coupons,
      couponTotal: coupons.map(x => x.amount ?? 0).reduce((prev, curr) => prev + curr, 0)
    };
  }

}
