import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { sortData } from 'helpers/sortData';
import { LoggerService, SourceLocationType } from 'services/logger.service';
import { AppInsightSto } from 'entities/app-insight-sto';
import { Permission } from 'entities/enums';
import { Cart } from 'entities/cart-search';
import { AuthorizationService } from 'services/authorization.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddStoCartItemRequest } from 'entities/carts/add-sto-cart-item-request';
import { AppState } from 'store/app-state';
import { CartResultLineItem, CartResultLineItemSelection } from 'entities/cart-result';
import { InventoryInfo } from 'entities/national-inventory/inventory-info';
import * as MyCartSelectors from 'store/my-cart/my-cart.selectors';
import * as MyCartActions from 'store/my-cart/my-cart.actions';
import * as BranchSelectors from "store/branch/branch.selectors";
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

type CartResultLineItemSelectionSto = CartResultLineItemSelection & {
  sortAsc: boolean;
  sortBy: string;
  filteredInventories: InventoryInfo[]
};

@Component({
  selector: 'stock-transfer-order',
  templateUrl: './stock-transfer-order.component.html'
})
export class StockTransferOrderComponent implements OnInit, OnDestroy {

  inventory: InventoryInfo;
  filteredInventories: InventoryInfo[];
  partId: string;
  partNumber: string;
  sortBy: string = '';
  sortAsc: boolean = false;
  removeLineItem = false;

  @Input() selectedCartItems: CartResultLineItemSelection[] = new Array<CartResultLineItemSelection>();
  filterSubject = new BehaviorSubject<string>('');
  filterChangeAction$: Observable<string> = this.filterSubject.asObservable();
  selectedCartItems$: Observable<CartResultLineItemSelection[]> = this.store.select(MyCartSelectors.selectedCartItems);
  filteredCartItems$: Observable<CartResultLineItemSelectionSto[]> = combineLatest([this.selectedCartItems$, this.filterChangeAction$]).pipe(
    map(([selectedCartItems, filterString]: [CartResultLineItemSelection[], string]) => selectedCartItems
      .map((ci: CartResultLineItemSelection) => <CartResultLineItemSelectionSto>{
        ...ci,
        filteredInventories: ci.inventory ? ci.inventory.map(inv => ({ ...inv, partsQuantity: 1 })) : []
      })
      .filter((selectedCartItem: CartResultLineItemSelection) => {
        if (filterString === '') {
          return true;
        }
        return (selectedCartItem.partNumber && selectedCartItem.partNumber.toLowerCase().includes(filterString.toLocaleLowerCase())) ||
          (selectedCartItem.corePartNumber && selectedCartItem.corePartNumber.toLowerCase().includes(filterString.toLocaleLowerCase()));
      }))
  );

  filterKey: string = '';

  cartId: string;
  userId: string;
  branchCode: string;
  customerNumber: string;
  customerName: string;

  permission: any = Permission;
  currencyCode$ = this.store.select(BranchSelectors.selectCurrencyCode);


  constructor(
    private store: Store<AppState>,
    private loggerService: LoggerService,
    public authorizationService: AuthorizationService,
    private activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.store.dispatch(MyCartActions.getInventory());
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  filter() {
    this.filterSubject.next(this.filterKey);
  };

  sortDataBy(cartItem: CartResultLineItemSelectionSto, sortBy: string) {
    if (cartItem.sortBy == sortBy) {
      cartItem.sortAsc = !cartItem.sortAsc;
    } else {
      cartItem.sortAsc = true;
    }

    cartItem.sortBy = sortBy;

    cartItem.filteredInventories = sortData(cartItem.filteredInventories, cartItem.sortBy, cartItem.sortAsc);
  }

  addToCart(addToCartItem: InventoryInfo, cartItem: CartResultLineItemSelection) {
    this.logAddToCartToAppInsights(addToCartItem, cartItem);
    const stoCartItemRequest: AddStoCartItemRequest = {
      addCartItemRequest: {
        binLocation: '',
        partId: cartItem.partId,
        coreOption: 'NOCORER',
        partNumber: cartItem.partNumber,
        quantity: Number(addToCartItem.partsQuantity),
        trackingParameters: '',
        adjustedPrice: cartItem.adjustedPrice,
        sapCoupons: cartItem.sapCoupons
      },
      originalPartCartItemId: cartItem.cartItemId,
      removeItemsFromCartRequest: null,
      vendorCode: addToCartItem.branchCode != null ? addToCartItem.branchCode : "",
      vendorBranchCode: addToCartItem.branchCode != null ? addToCartItem.branchCode : "",
      vendorBranchName: addToCartItem.branchName != null ? addToCartItem.branchName : "",
    };

    if(addToCartItem.partsQuantity >= cartItem.quantity){
      stoCartItemRequest.removeItemsFromCartRequest = {
        cartId: cartItem.cartId,
        isPickupDeliveryType: false,
        itemsToRemoveFromCart: [{
          cartItemId: cartItem.cartItemId
        }]
      };
    }

    this.store.dispatch(MyCartActions.addStoToCart({addStoRequest: stoCartItemRequest, selectedCartItem: cartItem}));
  }

  logAddToCartToAppInsights(addToCartItem: InventoryInfo, cartItem: CartResultLineItem) {
    const addtoCartParameters = Object.assign(new Cart(), {
      cartId: this.cartId,
      userId: this.userId,
      branchCode: this.branchCode,
      customerNumber: this.customerNumber,
      partNumber: cartItem.partNumber,
      description: cartItem.description,
      yourPrice: cartItem.yourPrice,
      quantity: addToCartItem.partsQuantity,
      coreOption: 'NOCORER',
      corePrice: cartItem.corePrice,
      corePartNumber: cartItem.corePartNumber,
      partId: cartItem.partId,
      cost: cartItem.cost,
      listPrice: cartItem.listPrice,
      adjustedPrice: cartItem.adjustedPrice,
      rebateField: cartItem.rebateField,
      isPriceVerified: cartItem.isPriceVerified,
      verifiedPrice: cartItem.verifiedPrice,
      priceOverrideReasonId: cartItem.priceOverrideReasonId,
      partNumOnly: cartItem.partNumOnly,
      vmrsCode: cartItem.vmrsCode,
      vmrsDescription: cartItem.vmrsDescription,
      manufacturer: cartItem.manufacturer,
      cateogory: cartItem.cateogory,
      binLocation: cartItem.binLocation,
      deliveryType: '',
      vendorCode: addToCartItem.branchCode,
      VendorBranchCode: addToCartItem.branchCode,
      VendorName: addToCartItem.branchName,
      TrackingParameters: '',
      IsSTO: true,
      QuantityAvailable: addToCartItem.quantity
    });

    let sourceName = 'NationalInventoryComponent_addToCart__STO';
    let metricName: string = this.loggerService.getMetricValue(sourceName);
    let appInsightSTO: AppInsightSto = {
      customerName: this.customerName,
      cartId: this.cartId,
      userId: this.userId,
      branchCode: this.branchCode,
      customerNumber: this.customerNumber,
      vendorNumber: addToCartItem.branchCode,
      vendorName: addToCartItem.branchName,
      source: SourceLocationType[SourceLocationType.Cart],
      plMetricName: sourceName
    };
    appInsightSTO.product = this.loggerService.getAppInsightParts(addtoCartParameters, JSON.stringify(appInsightSTO).length);
    this.loggerService.trackMetric(metricName, appInsightSTO);
  }

  changeQuantity(event: number, inventory: InventoryInfo) {
    inventory.partsQuantity = event;
  }

  ngOnDestroy() {
  }

  removeCartItem(cartItem: CartResultLineItemSelection) {
    this.store.dispatch(MyCartActions.toggleSelection({ cartItemId: cartItem.cartItemId }));
  }
}
