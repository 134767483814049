<ng-container *ngIf="pager$ | async as pager">
  <div *ngIf="pager" class="d-flex ps-3 pe-3 pb-3 justify-content-end">
    <nav id="{{idPrefix}}_nav_Pages" aria-label="Page navigation example" *ngIf="pager.pages && pager.pages.length">
      <div class="d-inline-flex">
        <span class="d-block mt-3">Showing {{pager.startIndex + 1 | number:'.0'}} - {{pager.endIndex + 1 | number:'.0'}} of {{pager.totalItems | number:'.0'}} Results</span>
        <ul class="pagination">
          <li class="page-item" [ngClass]="{disabled:pager.currentPage == 1}">
            <a id="{{idPrefix}}_a_First" class="page-link" aria-label="First" (click)="setPage(1)">
              <span aria-hidden="true">&laquo;</span>
              <span class="sr-only">First</span>
            </a>
          </li>
          <li class="page-item" [ngClass]="{disabled:pager.currentPage == 1}">
            <a id="{{idPrefix}}_a_Previous" class="page-link" aria-label="Previous" (click)="setPage(pager.currentPage - 1)">
              <span aria-hidden="true">&lsaquo;</span>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li id="{{idPrefix}}_li_Pages_{{i}}" class="page-item" *ngFor="let page of pager.pages; let i = index"
            [ngClass]="{active:pager.currentPage == page}">
            <a id="{{idPrefix}}_a_Page_{{i}}" class="page-link" (click)="setPage(page)">{{page | number:'.0'}}</a>
          </li>
          <li class="page-item" [ngClass]="{disabled:pager.currentPage == pager.totalPages}">
            <a id="{{idPrefix}}_a_Next" class="page-link" aria-label="Next" (click)="setPage(pager.currentPage + 1)">
              <span aria-hidden="true">&rsaquo;</span>
              <span class="sr-only">Next</span>
            </a>
          </li>
          <li class="page-item" [ngClass]="{disabled:pager.currentPage == pager.totalPages}">
            <a id="{{idPrefix}}_a_Last" class="page-link" aria-label="Last" (click)="setPage(pager.totalPages)">
              <span aria-hidden="true">&raquo;</span>
              <span class="sr-only">Last</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</ng-container>
