export type ColumnName =
  | "partNumber"
  | "item"
  | "hotFlag"
  | "qty"
  | "totalCoupon"
  | "finalPrice"
  | "total"
  | "jobNumber";

export const createOrderItemColumnClass = (
  columnName: ColumnName,
  isInternalUser: boolean = false,
  showCouponColumn: boolean = false,
  showHotFlagColumn: boolean = false
) => {
  switch (columnName) {
    case "partNumber":
      if (
        (showHotFlagColumn && isInternalUser) ||
        (showHotFlagColumn && isInternalUser && showCouponColumn)
      )
        return "col-2";
      else return "col-3";
    case "item":
      if (showCouponColumn && showHotFlagColumn && isInternalUser)
        return "col-2";
      else return "col-3";
    case "hotFlag":
      if (showHotFlagColumn) return "col-2";
    case "qty":
      if (!showCouponColumn && !showHotFlagColumn && !isInternalUser) {
        return "col-3";
      } else if (
        (!showCouponColumn && showHotFlagColumn && !isInternalUser) ||
        (!showCouponColumn && !showHotFlagColumn && isInternalUser)
      )
        return "col-2";
      else return "col-1";
    case "totalCoupon":
      if (showCouponColumn) return "col-1";
    case "finalPrice":
      if (showCouponColumn) return "col-2";
    case "total":
      if (!showCouponColumn && !showHotFlagColumn && !isInternalUser)
        return "col-3";
      else return "col-2";
    case "jobNumber":
      if (isInternalUser) {
        return "col-2";
      }
    default:
      break;
  }
};
