import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from 'services/loader.service';

@Component({
    selector: 'pc-loader',
    templateUrl: './loader.component.html',
})
export class LoaderComponent {
    showLoader$: Observable<boolean> = this.loader.loading$;
    constructor (
    private loader: LoaderService,
        ) { }
}
