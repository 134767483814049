<div *ngLet="{currencyCode: currencyCode$ | async} as vm">
  <!-- Call for Pricing -->
  <div *ngIf="!(isInternal$ | async)" class="gy-3">
    <div *ngIf="!part.isRushPart || (part.isRushPart && !part.isExtendedToBranch); else pricingAvailability;">
      <div class="strong text-danger">Call for pricing</div>
    </div>
  </div>
  <!-- Create Part -->
  <div *ngIf="isInternal$ | async"  class="gy-3">
    <div *ngIf="!part.isRushPart || (part.isRushPart && !part.isExtendedToBranch); else pricingAvailability;">
        <button type="button" *ngIf="createPart$ | async"
        class="btn btn-default yellow-btn d-flex align-items-center gx-1"
        (click)="createPart(part.rushPartNumber);" [disabled]="(cartIsLocked$ | async)">
          <span *ngIf="!showCreatePartLoader">Create Part</span>
          <ng-container *ngIf="showCreatePartLoader">
            <span>Processing...</span>
            <span class="countLoader"></span>
          </ng-container>
        </button>
    </div>
  </div>
  <!-- Pricing & Availability -->
  <ng-template class="gy-3" #pricingAvailability>
    <div>
      <!-- Zilliant Pricing -->
      <div id="pricing_div_ZilliantPricing{{idSuffix}}" *ngIf="showPriceIq && part.walkIn > part.listPrice">
        <div class="strong text-muted">{{ useCaps ? "EVERYDAY PRICE" : "Everyday Price" }}</div>
        <h3 class="m-0 mb-1 strong strikethrough">{{part.walkIn || 0 | currency: vm.currencyCode :'symbol':'1.2-2'}}</h3>
      </div>
      <div id="pricing_div_ZilliantYourPrice{{idSuffix}}" *ngIf="showPriceIq" class="strong text-danger">{{ useCaps ? "YOUR PRICE" : "Your Price" }}</div>
      <!-- Non-Zilliant -->
      <div id="pricing_div_NonZilliantPricing{{idSuffix}}" *ngIf="!showPriceIq" class="strong text-muted">{{ useCaps ? "CUSTOMER'S PRICE" : "Customer's Price" }}</div>
      <div id="pricing_div_IsSpecialPricing{{idSuffix}}" *ngIf="part.isSpecialPricing" class="text-danger">Special Sale Price</div>
      <h3 id="pricing_h3_IsSpecialSalePrice{{idSuffix}}" *ngIf="part.adjustedPrice" class="m-0 strong">
        <span id="pricing_span_price{{idSuffix}}" [ngClass]="{ 'text-danger': showPriceIq }">
          {{part.adjustedPrice || 0 | currency: vm.currencyCode :'symbol':'1.2-2'}}</span>
      </h3>
      <!-- Price Verification -->
      <h3 class="m-0 strong align-items-center" style="font-size: 1.65rem">
        <span id="{{idPrefix}}pricing_span_verifiedPrice{{idSuffix}}" [ngClass]="{ 'text-danger': showPriceIq, 'strikethrough': part.adjustedPrice }">
          {{ (part.isPriceVerified ? part.verifiedPrice : part.listPrice) || 0 | currency: vm.currencyCode :'symbol':'1.2-2'}}
        </span>
        <a id="pricing_a_PriceAdjust{{idSuffix}}" *ngIf="priceAdjust$ | async" class="hyperlink text-danger"
          (click)="showPriceOverride()" href="javascript:void(0)">
          <i class="fas fa-tag fa-sm fa-rotate-90" aria-hidden="true"></i>
        </a>
        <span id="pricing_span_IsPriceVerified{{idSuffix}}" *ngIf="part.isPriceVerified" class="btn price-verify-icon" title="Price Verified">v</span>
      </h3>
    </div>

    <!-- Coupons -->
    <ng-container *ngIf="partsData.sapCoupons?.length">
      <coupons [part]="partsData" [idSuffix]="idSuffix"></coupons>
    </ng-container>
    <div *ngIf="loadingAnyProgramDiscounts$ | async">Looking for more discounts...</div>

    <!-- Price Verification -->
    <div id="pricing_div_isMscOrFleetCustomer{{idSuffix}}" *ngIf="(isMscOrFleetCustomer$ | async)" class="gx-1">
      <a id="pricing_a_isMscOrFleetCustomer{{idSuffix}}" role="button" class="hyperlink" href="javascript:void(0)" (click)="showMscPriceVerifyPopup(part)">
        {{
          (isInternal$ | async)
          ? "Price Verify for MSC or Fleet"
          : "Click here to see whether you qualify for a lower negotiated price!"
        }}
      </a>
      <sup class="fas fa-info-circle" aria-hidden="true"></sup>
    </div>

    <!-- Core Price -->
    <div id="pricing_div_CorePrice{{idSuffix}}" *ngIf="part.corePrice > 0">
      <div class="strong text-muted">Core Price</div>
      <h3 class="m-0 strong">
        <span>{{part.corePrice || 0 | currency: vm.currencyCode :'symbol':'1.2-2'}}</span>
      </h3>
    </div>
  </ng-template>
</div>
