import { Action, createReducer, on } from "@ngrx/store";
import { Branch } from "entities/branch";
import { Configuration } from "entities/configuration";
import * as ConfigurationActions from "./configuration.actions";

export const initialState: Configuration = {
  allRoles: [],
  appVersion: null,
  branch: null,
  customer: null,
  entityPermissions: null,
  globalPermissions: null,
  user: null
}

export const configurationReducer = createReducer<Configuration, Action>(
  initialState,
  on(ConfigurationActions.getConfigurationSuccess, (state, { configuration }) => ({
    ...configuration
  })),
);
