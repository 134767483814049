import { BackCounterNote, Note } from "entities/notes";
import * as moment from "moment";

export interface NgbDate {
  year: number,
  day: number,
  month: number
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions, @typescript-eslint/ban-types
export function getDateAsObject(date = new Date(), constructDate?: Function): Date {
  if( constructDate ) {
    date = constructDate();
  }
 return date;
}

export function getDateInfoForNewNote(note: Note): BackCounterNote {
  return {
    ...note,
    utcTimestamp: ""
  }
}

export function getStringFromDateInclusive(ngbDate?: string): string {
  const date = ngbDate ?
    moment(ngbDate).add(1, 'days') :
    moment().add(1,'days');
  return date.toISOString();
}

export function getISOStringFromDate(ngbDate?: string): string {
  const date = ngbDate ? new Date(ngbDate) : new Date();
  return date.toISOString();
}

export function formatDateForInputSelection(date: Date): string {
  return date.toISOString().split("T")[0];
}

export function removeFalsyValues(object: any): any {
  for (let key in object) {
    if (object.hasOwnProperty(key) && !object[key]) {
      delete object[key];
    }
  }
  return object;
}
