import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, first, switchMap } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class AuthenticationInterceptorOptions {
  defaultDomain: string;
}

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private options: AuthenticationInterceptorOptions, private authenticationService: AuthenticationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const startsWithDomain = this.options.defaultDomain && req.url.startsWith(this.options.defaultDomain);

    // Continue for absolute urls starting with http:// or https://
    const absoluteUrlRegex = /^https?:\/\//i;
    if (!startsWithDomain && absoluteUrlRegex.test(req.url)) {
      return next.handle(req);
    }

    const url = this.options.defaultDomain && !startsWithDomain ? this.options.defaultDomain + req.url : req.url;

    req = req.clone({
      url: url
    });

    return this.authenticationService.accessToken$
      .pipe(
        first(),
        catchError((error) => {
          // Make API call without authorization header
          console.log("interceptor error", error);
          return of(null)
        }),
        switchMap((accessToken) => {
          if (accessToken) {
            req = req.clone({
              setHeaders: {
                Authorization: `Bearer ${accessToken}`
              }
            });
          }

          return next.handle(req);
        })
      );
  }
}
