import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { PartsSearchComponent } from "app/_components/parts-search/parts-search.component";
import { Basket } from "entities/basket";
import { BasketLineItem } from "entities/basket-line-item";
import { Subject, Subscription } from "rxjs";
import { tap, withLatestFrom } from "rxjs/operators";
import { CommonDataService } from "services/common-data.service";
import { AppState } from "store/app-state";
import { changeBasketItemQty, selectItemInBasket } from "store/basket/basket.actions";
import * as BasketSelectors from 'store/basket/basket.selectors';

@Component({
  selector: "app-basket-list",
  templateUrl: "./basket-list.component.html",
  styleUrls: ["./basket-list.component.scss"],
})
export class BasketListComponent implements OnInit, OnDestroy{
  selectedBaskets: Basket[] = [];
  @Input() sourceComponent: any;
  @Input() basketList: Basket[] = new Array<Basket>(); // Immutable Grouped list for display as seperate baskets
  @Output() actionList: BasketLineItem[] = new Array<BasketLineItem>();

  confirmButtonPressedSubject = new Subject<void>();
  basketList$ = this.store.select(BasketSelectors.selectBasketList);
  confirm$ = this.confirmButtonPressedSubject.asObservable().pipe(
    withLatestFrom(this.basketList$),
    tap(([_, basketList]) => {
      this.activeModal.close(basketList);
    })
  );
  subscriptions: Subscription;
  private focusMessage = ''; // It really is used ; don't delete
  private itemInError: BasketLineItem = null;
  private timer: any;

  constructor(
    private store: Store<AppState>,
    private activeModal: NgbActiveModal,
    public commonDataService: CommonDataService,
    private cd: ChangeDetectorRef
  ) {}
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  ngOnInit(): void {
    this.subscriptions = this.confirm$.subscribe();
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  public selectBasketItem(item: BasketLineItem, basket: Basket): void {
    const newBasket = {...basket};
    const newBasketItems: BasketLineItem[] = [];
    for(var basketLineItem of newBasket.lineItems){
      if(item === basketLineItem){
        basketLineItem = {...item, addToCart: true};
      }
      newBasketItems.push(basketLineItem)
    }
    newBasket.lineItems = newBasketItems;
    this.selectedBaskets.push(newBasket);
    this.store.dispatch(selectItemInBasket({ item: item }));
  }

  ngAfterContentChanged() {
    this.cd.detectChanges();
  }

  confirm = () => {
    if(this.sourceComponent === PartsSearchComponent){
      this.confirmButtonPressedSubject.next();
    } else {
      this.activeModal.close(this.basketList);
    }
  };

  handleQuantityKeypress(event: any, item: BasketLineItem) {
    this.focusMessage = ``;
    // Trigger update on enter key
    if (event.which == 13) {
      let proposedQty: number;
      proposedQty = event.srcElement.value;
      if (proposedQty > 0 && proposedQty <= item.quantity) {
        this.store.dispatch(
          changeBasketItemQty({ item: item, newQuantity: proposedQty })
        );
      } else {
        this.focusMessage = `1 to ${item.quantity}`;
        this.store.dispatch(
          changeBasketItemQty({
            item: item,
            newQuantity: item.quantity.valueOf(),
          })
        );
      }
      return;
    }

    // Disallow non-numeric keystrokes
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  quantityUp(item: BasketLineItem) {
    var proposedQty: number;
    proposedQty = item.quantitySelected.valueOf() + 1;
    if (proposedQty > 0 && proposedQty <= item.quantity) {
      this.store.dispatch(
        changeBasketItemQty({ item: item, newQuantity: proposedQty })
      );
    }
  }

  quantityDown(item: BasketLineItem) {
    if (item.quantitySelected == 0) {
      return;
    }
    var proposedQty: number;
    proposedQty = item.quantitySelected.valueOf() - 1;
    if (proposedQty > 0 && proposedQty <= item.quantity) {
      this.store.dispatch(
        changeBasketItemQty({ item: item, newQuantity: proposedQty })
      );
    }
  }

  onQuantityFocusout(event: any, item: BasketLineItem) {
    this.focusMessage = ``;
    this.itemInError = null;
    var proposedQty: number;
    proposedQty = event;
    if (proposedQty > 0 && proposedQty <= item.quantity) {
      this.store.dispatch(
        changeBasketItemQty({ item: item, newQuantity: proposedQty })
      );
    } else {
      this.focusMessage = `1 to ${item.quantity}`;
      this.itemInError = item;
      this.store.dispatch(
        changeBasketItemQty({
          item: item,
          newQuantity: item.quantity.valueOf(),
        })
      );
    }
  }

  checkRange(item: BasketLineItem): string {
    window.clearTimeout(this.timer);
    this.timer = window.setTimeout(() => {
      this.itemInError = null;
      this.focusMessage = "";
    }, 2000);
    if (this.itemInError === null || item === null) {
      return "";
    }

    this.focusMessage = `0 < qty <= ${this.itemInError.quantity}`;
    return this.itemInError.quantity === item.quantity &&
      this.itemInError.binNumber === item.binNumber &&
      this.itemInError.partNumber === item.partNumber
      ? "qty-range-error"
      : "";
  }
}
