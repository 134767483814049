import { Component, ChangeDetectionStrategy, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoyaltyProgram, LoyaltyAccount } from 'entities/loyalty-account';
import { LoaderService } from 'services/loader.service';
import { SearchLoyaltyProgramAccountsModalComponentStore } from './search-loyalty-program-accounts-modal.component.store';

@Component({
  selector: 'search-loyalty-program-accounts-modal',
  styleUrls: ['./search-loyalty-program-accounts-modal.component.scss'],
  templateUrl: './search-loyalty-program-accounts-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SearchLoyaltyProgramAccountsModalComponentStore]
})
export class SearchLoyaltyProgramAccountsModalComponent implements OnInit {
  public branchCode: string;
  public loyaltyProgram: LoyaltyProgram;

  public searchCriteria$ = this.componentStore.searchCriteria$;
  public atLeastOneSearchCriteria$ = this.componentStore.atLeastOneSearchCriteria$;
  public loading$ = this.componentStore.loading$;
  public loyaltyAccounts$ = this.componentStore.loyaltyAccounts$;
  public selectedMemberId$ = this.componentStore.selectedMemberId$;
  public selectedLoyaltyAccount$ = this.componentStore.selectedLoyaltyAccount$;
  public pager$ = this.componentStore.pager$;
  public customerNumber: string = null;

  constructor (
    private componentStore: SearchLoyaltyProgramAccountsModalComponentStore,
    private ngbActiveModal: NgbActiveModal,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.componentStore.init({ branchCode: this.branchCode, loyaltyProgram: this.loyaltyProgram, customerNumber: this.customerNumber });
    this.loaderService.register(this.loading$);
  }

  emailAddressChange(emailAddress: string) {
    this.componentStore.emailAddressChange(emailAddress);
  }

  phoneChange(phone: string) {
    this.componentStore.phoneChange(phone);
  }

  companyNameChange(companyName: string) {
    this.componentStore.companyNameChange(companyName);
  }

  postalCodeChange(postalCode: string) {
    this.componentStore.postalCodeChange(postalCode);
  }

  search() {
    this.componentStore.search();
  }

  selectAccount(loyaltyAccount: LoyaltyAccount) {
    this.componentStore.selectAccount(loyaltyAccount);
  }

  setPage(pageNumber: number) {
    this.componentStore.setPageNumber(pageNumber);
  }

  cancel() {
    this.ngbActiveModal.dismiss();
  }

  submit() {
    this.componentStore.linkCustomerLoyaltyAccount();
    this.componentStore.submit();
  }
}
