import { Component, OnInit, Input, Self, EventEmitter, Output } from '@angular/core';
import { Pager } from 'entities/pager';
import { Part } from 'entities/part';
import { Observable, of } from 'rxjs';
import { TableService } from 'services/table.service';
import { SourceLocationType } from 'services/logger.service';
import { LoyaltyDiscountsStore } from 'app/_components/loyalty-discounts/loyalty-discounts.store';

@Component({
  selector: 'part-card-list',
  templateUrl: './part-card-list.component.html',
  providers: [LoyaltyDiscountsStore]
})
export class PartCardListComponent implements OnInit {
  @Input() title: string = "";
  @Input() sourcePart: Part = null;
  @Input() parts: Part[] = [];
  @Input() pageSize: number = 4;
  @Input() partType: SourceLocationType;
  @Output() partChange = new EventEmitter<Part>();
  pager: Pager = new Pager(1, 1, 4);
  // paged items
  pagedParts: Part[];
  currentPage: number = 1;
  length$: Observable<number> = of(this.parts && this.parts.length || 0);
  featuredProductType: SourceLocationType = SourceLocationType.FeaturedProduct;

  get hasParts(): boolean {
    return this.pagedParts && this.pagedParts.length > 0;
  }

  constructor(
    @Self() private loyaltyDiscountsStore: LoyaltyDiscountsStore,
    private pagerService: TableService
  ) { }

  ngOnInit() {
    this.loyaltyDiscountsStore.getDiscounts(this.parts);
    this.setPage(1);
  }
  changeFavorite(part: Part): void {
    this.partChange.emit(part);
  }
  setPage(page: number) {
    if (!this.pager) {
      this.pager = this.pagerService.getPager(0, 1, this.pageSize);
    }

    if (page < 1 || (page > this.pager.totalPages && this.pager.totalPages > 0)) {
      return;
    }
    this.currentPage = page;
    // get pager object from service
    this.pager = this.pagerService.getPager(this.parts.length, page, 4);

    // get current page of items
    this.pagedParts = this.parts.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }
}
