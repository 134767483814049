import { Action, createReducer, on } from "@ngrx/store";
import ApplyDiscounts from 'helpers/apply-discounts';
import * as FeaturedPartsActions from "./featured-parts.actions";
import { FeaturedPartsState } from "./featured-parts.state";

export const initialState: FeaturedPartsState = {
    modalActive: false,
    featuredParts: [],
    featuredPartsLoading: false,
    featurePartsCheckedForCurrentBranchAndCustomer: false
};

const _featuredPartsReducer = createReducer<FeaturedPartsState, Action>(
    initialState,
    on(FeaturedPartsActions.openPromosModal, (state) => ({
        ...state,
        modalActive: true
    })),
    on(FeaturedPartsActions.closePromosModal, (state) => ({
        ...state,
        modalActive: false
    })),
    on(FeaturedPartsActions.getFeaturedParts, (state) => ({
        ...state,
        featuredPartsLoading: true
    })),
    on(FeaturedPartsActions.getFeaturedPartsSuccess, (state, { featuredParts }) => ({
        ...state,
        featuredPartsLoading: false,
        featurePartsCheckedForCurrentBranchAndCustomer: true,
        featuredParts: featuredParts
    })),
    on(FeaturedPartsActions.getFeaturedPartsFailure, (state) => ({
        ...state,
        featuredPartsLoading: false
    })),
    on(FeaturedPartsActions.clearFeaturedParts, (state) => ({
        ...state,
        featurePartsCheckedForCurrentBranchAndCustomer: false,
        featuredParts: []
    })),
    on(
      FeaturedPartsActions.applyLoyaltyDiscountsOnFeaturedParts,(state, {discounts}) => ({
        ...state,
        featuredParts: ApplyDiscounts(state.featuredParts, discounts)
    }))
);

export function featuredPartsReducer(state: FeaturedPartsState | undefined, action: Action) {
    return _featuredPartsReducer(state, action);
}
