<button id="CustomerNotesModal_Button_Close" type="button" class="close" data-dismiss="modal" aria-label="Close" tabindex="-1" (click)="closeModal()">
  <span aria-hidden="true">&times;</span>
</button>
<div class="modal-header">
  <h4 class="modal-title">Customer Notes</h4>
</div>
<div id="CustomerNotesModal_Div_IsLoading" class="modal-body" *ngIf="isLoading">
  <h5>Loading customer note(s) ...</h5>
</div>
<div id="CustomerNotesModal_Div_HasLoaded" *ngIf="customerNotes$ | async as customerNotes; else noRecords" class="modal-body">
  <div id="CustomerNotesModal_Div_HasNotes" *ngIf="customerNotes.notes.length" class="gy-2" style="white-space: pre-line;">{{ customerNotes.notes }}</div>
</div>
<ng-template #noRecords>
  <div class="modal-body" *ngIf="!isLoading">
    <h3>No records found.</h3>
  </div>
</ng-template>
