import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable, of, from } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class AudienceAuthenticationInterceptorOptions {
  audienceDomains!: string[];
}

@Injectable()
export class AudienceAuthenticationInterceptor implements HttpInterceptor {
  constructor(private options: AudienceAuthenticationInterceptorOptions, private authenticationService: AuthenticationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Continue if there's no domains to look for
    if (!this.options.audienceDomains || !this.options.audienceDomains.length)
    {
      return next.handle(req);
    }

    const audienceDomain = this.options.audienceDomains
      .find((domain) => req.url.startsWith(domain));

    if (!audienceDomain) {
      return next.handle(req);
    }

    return from(this.authenticationService.getAudienceToken(audienceDomain))
      .pipe(
        catchError((error) => {
          // Make API call without authorization header
          console.log("interceptor error", error);
          return of(null)
        }),
        switchMap((accessToken) => {
          if (accessToken) {
            req = req.clone({
              setHeaders: {
                Authorization: `Bearer ${accessToken}`
              }
            });
          }

          return next.handle(req);
        })
      );
  }
}
