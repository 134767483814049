import { OrderDetails } from "entities/order-details";
import { OrderDetailsLineItem, OrderLineItemDeliveryStatus } from "entities/order-details-line-item";

export const calculateShippingStatus = (
    lineItem: OrderDetailsLineItem,
    orderDetails: OrderDetails,
    shippingManagementEnabled: boolean
): OrderLineItemDeliveryStatus => {

    const invoices = orderDetails.invoiceNumbers;
    const hasFreightTrackingNumber = orderDetails.freightDetails?.some(d => d.lineItemNumber === (parseInt(lineItem.lineItemNumber, 10) / 10) && d.trackingId);
    const isFreightShipped = lineItem.deliveryOption === 'freight' && hasFreightTrackingNumber;
    const freightDeliveryStatus = orderDetails.freightDetails?.find(f => f.lineItemNumber === (parseInt(lineItem.lineItemNumber, 10) / 10))?.trackingText;
    const deliveryOption = lineItem.deliveryOption;

    // DeliveryOptions of fcKnown, adHoc , selfPick , WillCall or shipDirect
    // will have a deliveryStatus of NotApplicable (N/A)
    if (['fcKnown', 'adHoc', 'selfPick', 'willCall', 'shipDirect'].includes(deliveryOption)) return 'notApplicable';
    // Shipped but no delivery Status
    if (isFreightShipped && freightDeliveryStatus?.length <= 0) return 'shipped';
    // Shipped and we DO have some delivery status
    if (isFreightShipped && freightDeliveryStatus?.length >= 0) return freightDeliveryStatus as OrderLineItemDeliveryStatus;

    if (!isFreightShipped && ['notPicked', 'picked', 'ready'].includes(lineItem.deliveryStatus)) return 'preparing';
    if (!isFreightShipped && lineItem.deliveryStatus === 'enRoute') return 'enRoute';
    if (!isFreightShipped && lineItem.deliveryStatus === 'delivered') return 'delivered';
    if ((deliveryOption === 'freight' || deliveryOption === 'rtcDelivery') && !shippingManagementEnabled) return 'notApplicable';

    // NOT Shipped but DOES have deliveryOption of 'willCall'
    if (!isFreightShipped && lineItem.isCore && deliveryOption !== 'willCall') return 'notApplicable';

    // Not Shipped and has delivery type NOT 'willCall'
    if (!isFreightShipped && lineItem.deliveryOption !== 'willCall') {
        return getStatusIfNotShipped(lineItem);
    }

    // RTC or Freight will only have delivery status of N/A if the order is in a branch with FF off.
    if (['freight', 'rtcDelivery'].includes(lineItem.deliveryOption) && !shippingManagementEnabled) {
        return 'notApplicable' as OrderLineItemDeliveryStatus;
    }

    // Invoiced and Not freight
    if (invoices.length > 0 && lineItem.deliveryOption !== 'freight') {
        return 'notApplicable' as OrderLineItemDeliveryStatus;
    }

    return 'preparing';
}

export const getShippingStatusLabel = (
    status: OrderLineItemDeliveryStatus,
): string => {
    switch (status) {
        case 'notApplicable':
            return 'N/A';
        case 'delivered':
            return 'Delivered';
        case 'enRoute':
            return 'Out for Delivery';
        case 'preparing':
            return 'Preparing Order';
        case 'notDelivered':
            return 'Not to be Delivered';
        case 'shipped':
            return 'Shipped';
        default:
            return 'N/A';
    }
}


const getStatusIfNotShipped = (lineItem: OrderDetailsLineItem) => {
    switch (lineItem.deliveryStatus) {
        case 'notPicked':
        case 'picked':
        case 'ready':
            return 'preparing' as OrderLineItemDeliveryStatus;
        case 'enRoute':
            return 'enRoute' as OrderLineItemDeliveryStatus;
        case 'delivered':
            return 'delivered' as OrderLineItemDeliveryStatus;
        case 'notDelivered':
            return 'notDelivered' as OrderLineItemDeliveryStatus;
        default:
            return lineItem.isCore && lineItem.deliveryOption !== 'freight' && lineItem.deliveryOption !== 'rtcDelivery' ?
                'notApplicable' as OrderLineItemDeliveryStatus :
                'preparing' as OrderLineItemDeliveryStatus;
    }
}