import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { Observable, filter, of, tap } from "rxjs";
import { AppState } from "store/app-state";
import * as ConfigSelectors from 'store/configuration/configuration.selectors';
import { NotesComponentStore } from "./notes.component.store";
import { NoteMessage, NotesComponentConfig } from "./notes.component.state";
import { BackCounterNote } from "entities/notes";
import * as ConfigurationSelectors from 'store/configuration/configuration.selectors';
import { Permission } from "entities/enums";
import { closeNotesModal } from "store/back-counter/back-counter.actions";

@Component({
  selector: "notes",
  templateUrl: "./notes.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NotesComponentStore]
})
export class NotesComponent implements OnInit {

  @Input()
  config: NotesComponentConfig;

  public closeBtn: boolean = true;
  public edit: BackCounterNote;

  public store: Store<AppState> = inject(Store);
  private activeModal = inject(NgbActiveModal);
  public componentStore = inject(NotesComponentStore);

  public title: string = "";
  public editBackcounterRequestNotes$: Observable<boolean> = this.store.select(ConfigurationSelectors.hasPermission(Permission.EditBackcounterRequestNotes));
  public loading$: Observable<boolean> = this.componentStore.loading$;
  public notes$: Observable<BackCounterNote[]> = this.componentStore.notes$;
  public messages$: Observable<NoteMessage> = this.componentStore.messages$.pipe(
    filter((m: NoteMessage) => (this.config.listenForNewMessages?.indexOf(m.eventId) !== -1) && this.config.id.padStart(10, '0') === m.orderNumber),
    tap((m: NoteMessage) => this.componentStore.getNotes({ id: this.config.id }))
  );

  public readonly user$ = this.store.select(ConfigSelectors.selectUserInfo);
  public notForRelease: boolean = true;

  ngOnInit(): void {
    this.title = `Request ${this.config.id} Notes`;
    this.componentStore.setConfig(this.config);
    this.componentStore.getNotes({ id: this.config.id });
    this.notForRelease = this.config.notForRelease;
  }

  public createNote(form: UntypedFormGroup, username: string): void {
    if (!form.valid) {
      return;
    }

    const note = {
      techname: username,
      notetext: form.value.description
    } as BackCounterNote;

    this.componentStore.createNote({ id: this.config.id, note });

    form.reset({ description: '' });
  }

  public closeModal(): void {
    this.store.dispatch(closeNotesModal({config: this.config}));
    this.activeModal.close();
  }

  public editNote(note: BackCounterNote): void {
    this.edit = note;
  }

  public cancelEdit(): void {
    this.edit = null;
  }

}
