import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Permission } from 'entities/enums';
import { ExtendPartRequest } from 'entities/extend-part-request';
import { ToastType } from 'entities/toast-type';
import { CreatePartModalComponent } from 'modals/create-part-modal/create-part-modal.component';
import { AuthorizationService } from 'services/authorization.service';
import { CommonDataService } from 'services/common-data.service';
import { LoaderService } from 'services/loader.service';
import { LoggerService } from 'services/logger.service';
import { PartService } from 'services/part.service';
import { ToastService } from 'services/toast.service';
import { Store } from "@ngrx/store";
import { AppState } from "store/app-state";
import { Observable } from "rxjs";
import * as CartSelectors from 'store/cart/cart.selectors';
import { PriceTapeResult, SapCreateExtendPartResults } from 'entities/parts/create-extend-part-result';

@Component({
  selector: 'create-part',
  templateUrl: './create-part.component.html'
})
export class CreatePartComponent {
  @Input() partSearchTerm: string;
  @Output('dismiss') dismissEmitter: EventEmitter<void> = new EventEmitter();

  permission: typeof Permission = Permission;

  showCreatePartLoader: boolean = false;

  cartIsLocked$: Observable<boolean> = this.store.select(CartSelectors.selectIsCartLocked);

  constructor(
    private store: Store<AppState>,
    public commonDataService: CommonDataService,
    private modalService: NgbModal,
    private router: Router,
    private authorizationService: AuthorizationService,
    private toastService: ToastService,
    private partService: PartService,
    private loggerService: LoggerService,
    private loader: LoaderService,
  ) { }

  createPart(createPartNumber: string) {
    const extendPartData: ExtendPartRequest = {
      partNumber: createPartNumber,
      branchCode: this.commonDataService.Branch.code,
      allowReplacedPart: true,
      isSimulated: false
    };
    this.showCreatePartLoader = true;
    this.partService.createExtendedPart(extendPartData)
      .then(responseMsgResult => {
        this.showCreatePartLoader = false;
         if (responseMsgResult.error && responseMsgResult.error.errorCode === 'error') {
          this.toastService.showToast(responseMsgResult.error.errorMessage, ToastType.Error);
        } else {
          // this.partService.notifyShowCreateModal({ data: responseMsgResult });
          this.openPartCreatedResultModal(responseMsgResult);
          this.loggerService.verbose('Create Extended Part: ', responseMsgResult);
          this.loader.loading = false;
          // this.router.navigate(['parts'], { queryParams: { searchTerm: extendPartData.partNumber } });
        }
      },
        () => { this.loader.loading = false; this.showCreatePartLoader = false; });
  }

  openPartCreatedResultModal(data: SapCreateExtendPartResults) {
    const multiplePartsData: PriceTapeResult[] = data.priceTapeResults;
    const externalPartNumber: string = data.externalPartNumber;
    if (multiplePartsData == null || multiplePartsData == undefined || multiplePartsData.length == 0) {
      this.toastService.showMultilineToast(data.messages);
    } else if (externalPartNumber != null && externalPartNumber.length > 0 && (multiplePartsData.length == 1)) {
      this.toastService.showMultilineToast(data.messages);
      this.loggerService.verbose('Single part returned, part number: ', externalPartNumber);
      this.router.navigate(['parts'], { queryParams: { searchTerm: externalPartNumber } });
    } else if (multiplePartsData.length > 1) {
      const modalRef = this.modalService.open(CreatePartModalComponent);
      modalRef.componentInstance.multiplePartsData = multiplePartsData;
      modalRef.componentInstance.branch = this.commonDataService.Branch;
      modalRef.result
        .then((createdPartNumber: string) => {
          this.router.navigate(['parts'], { queryParams: { searchTerm: createdPartNumber } });
        })
        .catch(() => {
          this.showCreatePartLoader = false;
        });
    }
  }

  dismiss() {
    this.dismissEmitter.emit();
  }
}
