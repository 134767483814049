<!-- HotFlag Popup -->
<button id="hotFlag_button_CloseModal" type="button" class="close" data-dismiss="modal" aria-label="Close" tabindex="-1" (click)="closeModal()">
  <span aria-hidden="true">&times;</span>
</button>
<div class="modal-header">
  <h4 class="modal-title">Hot Flag</h4>
  <div class="input-group w-50 w-md-33">
    <input id="hotFlag_text_SearchFavoriteId" type="text" class="form-control customer-search-textbox" [(ngModel)]=filterKey
      (keyup)="filter()" placeholder="Search...">
      <span class="input-group-text">
        <i class="fas fa-search" aria-hidden="true"></i>
      </span>
  </div>
</div>
<div class="modal-body">
  <div id="hotFlag_div_Procurement" class="gy-2" *ngLet="(fixedVendor$ | async) as fixedVendor">
    <form id="hotFlag_form_HotFlagForm" [formGroup]="form">
      <div ngbAccordion #acc="ngbAccordion" class="accordion-sm gy-1" [closeOthers]="true" formArrayName="cartItems">
        <div ngbAccordionItem="hotFlag_panel_HotFlag_{{i}}" #accItem="ngbAccordionItem" *ngFor="let cartItem of filteredCartItems$ | async; let i=index" [destroyOnHide]="false"
          [formGroupName]="i" (hidden)="lastExpandedIndex = null" (shown)="lastExpandedIndex = i" [collapsed]="lastExpandedIndex !== i">
          <div ngbAccordionHeader class="bg-header">
            <button ngbAccordionToggle type="button" class="btn accordion-toggle">
              <div class="d-flex">
                <div class="px-2">
                  <div>
                    <i class="fas" [ngClass]="accItem.collapsed ? 'fa-plus' : 'fa-minus'" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="flex-grow-1 px-3 text-start gx-3">
                  <span class="gx-1">
                    <i id="hotFlag_i_HotFlagIsRequired_{{i}}" *ngIf="getCartItemControls(i).hotFlagCode.errors?.required" title="Hot Flag is required." class="fas fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                    <i id="hotFlag_i_VINIsRequired_{{i}}" *ngIf="form.controls.vin.errors?.required" title="VIN is required." class="fas fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                    <i id="hotFlag_i_VINIsInvalid_{{i}}" *ngIf="form.controls.vin.errors?.pattern" title="VIN is invalid." class="fas fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                    <b>Part Number:</b>
                    <span>{{cartItem.partNumber}}</span>
                  </span>
                  <span class="gx-1">
                    <b>Vendor Number:</b>
                    <span id="hotFlag_span_VendorCode_{{i}}" *ngIf="cartItem.vendorCode">{{cartItem.vendorCode | customerNumber}}</span>
                  </span>
                </div>
                <div class="ms-auto">
                  <button id="hotFlag_button_RemoveCartItem_{{i}}" style="background: transparent; border: none !important;" role="button" (click)="removeCartItem(cartItem)">
                    <i class="fas fa-times text-danger" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="row align-items-center pb-3">
                  <div class="col-sm-3 strong">Select Hot Flag</div>
                  <div class="col-sm-9">
                    <select id="hotFlag_select_cartItemHotFlag_{{i}}" name="hotFlagCode" class="form-control" formControlName="hotFlagCode" #hf (change)="onHotFlagChange(i, hf.value)">
                      <option value="">Please Select Hot Flag</option>
                      <option id="hotFlag_select_flag.code_{{f}}" value="{{flag.code}}" *ngFor="let flag of (hotFlags$ | async); let f = index">{{flag.code}} - {{flag.description}}</option>
                    </select>
                  </div>
                </div>
                <div class="row align-items-center" *ngIf="isShowVINControl(i) && (isHotFlagVinEnabled$ | async)">
                  <div class="col-sm-3 strong">Enter VIN</div>
                  <div class="col-sm-9">
                    <input type="text" id="hotFlag_vin_cartItemHotFlag_{{i}}" name="hotFlag_vin_cartItemHotFlag_{{i}}" class="form-control" [formControl]="form.controls.vin" maxlength="35" (input)="vinUpdateSubject.next({event: $event})" />
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div>
      <button id="hotFlag_button_SendHotFlag" type="button" class="btn yellow-btn w-100 text-uppercase" [disabled]="!form.valid || fixedVendor===false || isVINUpdating"
        (click)="sendHotFlags();">Send Hot Flag</button>
    </div>
    <div id="hotFlag_div_InvalidForm" *ngIf="!(form.valid && fixedVendor)" class="alert alert-danger m-0">
      <div [hidden]="!hasHotFlagCodeRequiredError()">Hot Flag is required.</div>
      <ng-container *ngIf="isHotFlagVinEnabled$ | async">
        <div [hidden]="!hasVINRequiredError()">VIN is required.</div>
        <div [hidden]="!hasVINPatternError()">VIN is invalid. VIN can only contain alphanumeric characters.</div>
      </ng-container>
      <div [hidden]="fixedVendor">{{partNumber}} cannot be procured, it does not have a fixed vendor. Send
        a request to PartAdds to add fixed vendor.</div>
    </div>
  </div>
</div>
<!-- end HotFlag Popup-->
