import { NgModule } from '@angular/core';
import { OrderConfirmationComponent } from '../_components/order-confirmation/order-confirmation.component';
import { OrderDetailsComponent } from '../_components/order-details/order-details.component';
import { OrderDetailsTableComponent } from '../_components/order-details-table/order-details-table.component';
import { OrderHistoryComponent } from '../_components/order-history/order-history.component';
import { FindOrderPanelComponent } from '../_components/find-order-panel/find-order-panel.component';
import { SharedModule } from 'app/_modules/shared.module';
import { OrderBranchComponent } from '../_components/order-branch/order-branch.component';
import { OrderCustomerComponent } from '../_components/order-customer/order-customer.component';
import { OrderInvoiceComponent } from '../_components/order-invoice/order-invoice.component';
import { BuyAgainComponent } from '../_components/buy-again/buy-again.component';
import { OrderItemComponent } from '../_components/order-item/order-item.component';
import { OrderItemHeaderComponent } from '../_components/order-item-header/order-item-header.component';
import { OrderItemListComponent } from '../_components/order-item-list/order-item-list.component';
import { OrderLineItemsDeliveryStatusFilterComponent } from '../_components/order-line-items-delivery-status-filter/order-line-items-delivery-status-filter.component';
import { PurchaseOrderCreateModalComponent } from '../_modals/purchase-order-create-modal/purchase-order-create-modal.component';
import { DeliveredCountPipe } from '../_pipes/delivered-count.pipe';
import { OrderService } from '../_services/order.service';
import { QuotesComponent } from '../_components/quotes/quotes.component';
import { DeliveryCountPipe } from 'pipes/delivery-count.pipe';
import { OrderLineItemsPipe } from 'pipes/order-line-items.pipe';
import { PurchaseOrderService } from 'services/purchase-order.service';
import { ProofOfDeliveryComponent } from 'app/_components/proof-of-delivery/proof-of-delivery.component';
import { CustomerReviewFormComponent } from '../_modals/customer-review-form/customer-review-form.component';
import { DeviceListModalComponent } from '../_modals/device-list-modal/device-list-modal.component';


@NgModule({
  declarations: [
    OrderConfirmationComponent,
    OrderDetailsComponent,
    OrderDetailsTableComponent,
    OrderLineItemsDeliveryStatusFilterComponent,
    OrderHistoryComponent,
    FindOrderPanelComponent,
    OrderBranchComponent,
    OrderCustomerComponent,
    OrderInvoiceComponent,
    BuyAgainComponent,
    OrderItemComponent,
    OrderItemHeaderComponent,
    OrderItemListComponent,
    QuotesComponent,
    ProofOfDeliveryComponent,

    // Modals
    PurchaseOrderCreateModalComponent,
    CustomerReviewFormComponent,
    DeviceListModalComponent,

    // Pipes
    DeliveredCountPipe,
    DeliveryCountPipe,
    OrderLineItemsPipe

  ],
  providers: [
    OrderService,
    PurchaseOrderService
  ],
  imports: [
    SharedModule
  ],
  exports: [
    OrderConfirmationComponent,
    OrderDetailsComponent,
    OrderDetailsTableComponent,
    OrderHistoryComponent,
    FindOrderPanelComponent,
    QuotesComponent
  ]
})
export class OrdersModule { }
