  <div class="row mt-3">
    <div class="col-md-3">
      <find-order-panel [panelType]="'quote'" (find)="findQuote($event)" (search)="searchQuotes($event)"></find-order-panel>
    </div>
  <div class="col-12 col-md-9" *ngLet="{currencyCode: currencyCode$ | async} as vm">
    <ng-container *ngIf="quotes$ | async as quotes">
    <table *ngIf="!quotes.length">No records found.</table>
      <div class="table-responsive">
        <table class="table table-striped mt-1" *ngIf="quotes.length">
          <thead>
            <tr>
              <th>Quote Number</th>
              <ng-container *ngIf="currentUser.isInternal">
                <th>Customer</th>
                <th>Customer Number</th>
                <th>Ship To Number</th>
              </ng-container>
              <th>Contact Information</th>
              <th>Branch</th>
              <th>Branch Name</th>
              <th>Created Date</th>
              <th>Expiration Date</th>
              <th>Subtotal</th>
              <th colspan="2">Status</th>
              <th>Sales Advisor Name</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let quoteItem of quotes; index as i">
              <ng-container>
                <td id="quote_td_quoteNumber_{{ i }}">
                  <span *ngIf="!quoteItem.isLocked && quoteItem.cartId != null && quoteItem.status != OrderStatus.closed; else lockedQuote;">
                    <a role="button" class="hyperlink" href="javascript:void(0);"
                    (click)="goToCheckoutFromQuotesPanel(quoteItem)">{{quoteItem.orderNumber}}
                    </a>
                  </span>
                  <ng-template #lockedQuote>
                    <span class="d-flex">
                      <a *ngIf="commonDataService.User.id === quoteItem.updatedById && quoteItem.isLocked; else noLink;" role="button" class="hyperlink" href="javascript:void(0);" (click)="goToCheckoutFromQuotesPanel(quoteItem)">
                        {{quoteItem.orderNumber}}
                      </a>
                      <ng-template #noLink>
                        {{quoteItem.orderNumber}}
                      </ng-template>
                    <ng-container *ngIf="quoteItem.cartId == null">
                        <i class="fas fa-question-circle" style="padding-left: 1px;" [ngbTooltip]="quoteTipContent" [openDelay]="300" [closeDelay]="300" placement="right" tooltipClass="part-number-tooltip"></i>
                        <ng-template #quoteTipContent>
                          <div class="p-2"><b>This quote can be accessed in SAP for viewing, editing and conversion to order.</b></div>
                        </ng-template>
                      </ng-container>
                    </span>
                  </ng-template>
                </td>
              <ng-container *ngIf="currentUser.isInternal">
                <td id="quote_td_Customer_{{ i }}">
                  {{ isCashCustomer(quoteItem.customerNumber) ? "RUSH CASH CUSTOMER": quoteItem.name1}}
                </td>
                <td id="quote_td_customerNumber_{{ i }}" class = "ps-8">
                  {{quoteItem.customerNumber}}
                </td>
                <td id="quote_td_shipToNumber_{{ i }}">
                  {{quoteItem.shipToNumber}}
                </td>
              </ng-container>
              <td id="quote_td_contactInfo_{{ i }}">
                <div *ngIf="quoteItem.street">{{quoteItem.street}}</div>
                <div *ngIf="quoteItem.city || quoteItem.region || quoteItem.postcode">
                  {{ [([quoteItem.city, quoteItem.region] | join:', '), quoteItem.postcode] | join:' ' }}
                </div>
                <span *ngIf="quoteItem.telephone!='' && quoteItem.telephone!=' '">{{quoteItem.telephone}}<br /></span>
              </td>
              <td id="quote_td_branchCode_{{ i }}">
                {{quoteItem.branch}}
              </td>
              <td id="quote_td_branchName_{{ i }}">
                {{quoteItem.branchName}}
              </td>
              <td id="quote_td_dateCreated_{{ i }}">
                {{quoteItem.dateCreated | date: 'MM/dd/yyyy': 'UTC'}}
              </td>
              <td id="quote_td_expirationDate_{{ i }}">
                {{quoteItem.expirationDate | date: 'MM/dd/yyyy': 'UTC'}}
              </td>
              <td id="quote_td_subtotal_{{ i }}" style="text-align: right;">
                {{quoteItem.total | currency: vm.currencyCode }}
              </td>
              <td id="quote_td_orderStatus">
                <i *ngIf="quoteItem.status == OrderStatus.closed" class="fas fa-times" style="color:darkred;" aria-hidden="true"></i>
                <i *ngIf="quoteItem.status == OrderStatus.open" class="fas fa-check" style="color:forestgreen;" aria-hidden="true"></i>
              </td>
              <td>
                <span *ngIf="quoteItem.isLocked && commonDataService.User.id !== quoteItem.updatedById" class="fas fa-lock" aria-hidden="true" style="color:darkred;"></span>
                <span *ngIf="!quoteItem.isLocked || commonDataService.User.id === quoteItem.updatedById" class="fas fa-unlock" aria-hidden="true" style="color:forestgreen;"></span>
              </td>
              <td id="quote_td_salesAdvisorName">
                <div *ngIf="quoteItem.customerAdvisorFirstName">{{quoteItem.customerAdvisorFirstName}}</div>
                <div *ngIf="quoteItem.customerAdvisorLastName">{{quoteItem.customerAdvisorLastName}}</div>
              </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </div>
</div>
<pagination
  [currentPage]="currentPageNumber"
  idPrefix="orderHistory"
  [totalCount]="quoteCount$"
  [pageSize]="pageSize"
  (currentPageChange)="pageChange($event)">
</pagination>
