<div class="col-md-12" *ngLet="{
  loading: loading$ | async,
  items: items$ | async,
  hasUnsavedChanges: hasUnsavedChanges$ | async,
  currencyCode: currencyCode$ | async,
  userProfile: userProfile$ | async
} as vm">
    <div class="dashboard-content heading-search">
      <div class="title-of-box-hold">
        <div class="title-of-box pull-left">
          <div class="form-group" *ngIf="showOpenCartLoader">
            <span class="countLoader"></span>
          </div>
          Open Items
        </div>
        <a id="myDashboard_a_RefreshOpenItems" role="button" class="fas fa-sync-alt pull-right" style="cursor:pointer; color:#FFFFFF" (click)="refreshOpenItems()"></a>
      </div>

      <div class="row dashboard-filter p-2">
        <div class="col-3 ms-3 my-auto">
          <input id="myDashboard_checkbox_UserFilter" name="myDashboard_checkbox_UserFilter"
            type="checkbox" [ngModel]="filterByUser$ | async" (ngModelChange)="filterByUserClicked()">
          <label for="myDashboard_checkbox_UserFilter" class="my-0">My Open Items</label>
        </div>
        <div class="col-5 row">
          <div class="col-3 pe-0 my-auto">
            <input id="myDashboard_checkbox_AllBranches" type="checkbox" [ngModel]="!(filterByBranch$ | async)"
              (ngModelChange)="filterByBranchClicked()" [disabled]="!(filterByUser$ | async)">
            <label for="myDashboard_checkbox_AllBranches" class="my-0">All Branches</label>
          </div>
          <div class="col-7 ps-0 pe-5">
            <branch id="myDashboard_branch_SingleBranch" class="w-100" *ngIf="filterByBranch$ | async"
              [ngModel]="branch$ | async" (ngModelChange)="branchSelected($event)">
            </branch>
          </div>
        </div>
        <div class="col-3 ms-auto">
          <div class="right-inner-addon ">
            <i class="fas fa-search" aria-hidden="true"></i>
            <input id="myDashboard_search_OpenItems" type="search" class="form-control" placeholder="Search" name="myDashboard_search_OpenItems"
              [ngModel]="filterKey$ | async" (ngModelChange)="filter($event)" />
          </div>
        </div>
      </div>

      <div id="myDashboard_div_LoadingOpenItems" class="form-group" style="margin: 0 10px 15px;" *ngIf="vm.loading">
        Loading open items...
        <span class="countLoader"></span>
      </div>

      <div id="myDashboard_div_NoRecordsFound" *ngIf="!vm.items || !vm.items.length && !vm.loading">
        <h4 class="col-xs-12"> No records found.</h4>
      </div>

      <div id="myDashboard_div_ShowOpenItems" *ngIf="vm.items && vm.items.length" class="open-items-container">
        <table class="table" [hidden]="!vm.items.length">
          <thead>
            <tr *ngLet="sort$ | async as sort">
              <th>
                <a id="myDashboard_a_sortId" role="button" class="hyperlink" href="javascript:void(0)" (click)="sortDataBy('sapOrderNo');">
                  ID
                  <span [hidden]="sort?.by !== 'sapOrderNo'" class="fas" [ngClass]="{ 'fa-caret-up': sort?.ascending, 'fa-caret-down': !sort?.ascending }">
                  </span>
                </a>
              </th>
              <th>
                <a id="myDashboard_a_sortCustomer" role="button" class="hyperlink" href="javascript:void(0)" (click)="sortDataBy('customerName');">
                  Customer
                  <span [hidden]="sort?.by !== 'customerName'" class="fas" [ngClass]="{ 'fa-caret-up': sort?.ascending, 'fa-caret-down': !sort?.ascending }">
                  </span>
                </a>
              </th>
              <th *ngIf="includeAllBranches">
                <a id="myDashboard_a_sortBranch" role="button" class="hyperlink" href="javascript:void(0)" (click)="sortDataBy('branchName');">
                  Branch
                  <span [hidden]="sort?.by !== 'branchName'" class="fas" [ngClass]="{ 'fa-caret-up': sort?.ascending, 'fa-caret-down': !sort?.ascending }">
                  </span>
                </a>
              </th>
              <th style="text-align:center;">
                <a id="myDashboard_a_sortType" role="button" class="hyperlink" href="javascript:void(0)" (click)="sortDataBy('type');">
                  Type
                  <span [hidden]="sort?.by !== 'type'" class="fas" [ngClass]="{ 'fa-caret-up': sort?.ascending, 'fa-caret-down': !sort?.ascending }">
                  </span>
                </a>
              </th>
              <th>
                <a id="myDashboard_a_sortCustomerNumber" role="button" class="hyperlink" href="javascript:void(0)" (click)="sortDataBy('customerNumber');">
                  Customer Number
                  <span [hidden]="sort?.by !== 'customerNumber'" class="fas" [ngClass]="{ 'fa-caret-up': sort?.ascending, 'fa-caret-down': !sort?.ascending }">
                  </span>
                </a>
              </th>
              <th>
                <a id="myDashboard_a_sortContactInfo" role="button" class="hyperlink" href="javascript:void(0)" (click)="sortDataBy('contactInformation');">
                  Contact Information
                  <span [hidden]="sort?.by !== 'contactInformation'" class="fas" [ngClass]="{ 'fa-caret-up': sort?.ascending, 'fa-caret-down': !sort?.ascending }">
                  </span>
                </a>
              </th>
              <th>
                <a id="myDashboard_a_sortCreatedDate" role="button" class="hyperlink" href="javascript:void(0)" (click)="sortDataBy('createdDate');">
                  Created Date
                  <span [hidden]="sort?.by !== 'createdDate'" class="fas" [ngClass]="{ 'fa-caret-up': sort?.ascending, 'fa-caret-down': !sort?.ascending }">
                  </span>
                </a>
              </th>
              <th colspan="2">
                <a id="myDashboard_a_sortExpDate" role="button" class="hyperlink" href="javascript:void(0)" (click)="sortDataBy('expiryDate');">
                  Expiration Date
                  <span [hidden]="sort?.by !== 'expiryDate'" class="fas" [ngClass]="{ 'fa-caret-up': sort?.ascending, 'fa-caret-down': !sort?.ascending }">
                  </span>
                </a>
              </th>
              <th>
                <a id="myDashboard_a_sortLineItemTotal" role="button" class="hyperlink" href="javascript:void(0)" (click)="sortDataBy('cartItemCount');">
                  Line Item Total
                  <span [hidden]="sort?.by !== 'cartItemCount'" class="fas" [ngClass]="{ 'fa-caret-up': sort?.ascending, 'fa-caret-down': !sort?.ascending }">
                  </span>
                </a>
              </th>
              <th>
                <a id="myDashboard_a_sortSubtotal" role="button" class="hyperlink" href="javascript:void(0)" (click)="sortDataBy('price');">
                  Subtotal
                  <span [hidden]="sort?.by !== 'price'" class="fas" [ngClass]="{ 'fa-caret-up': sort?.ascending, 'fa-caret-down': !sort?.ascending }">
                  </span>
                </a>
              </th>
              <th>Actions</th>
              <th style="text-align:center;">Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let cart of vm.items; let i = index">
              <tr [ngClass]="cart.applyRedClass == true ? 'open-items-row-expiry' : ''">
                <td id="myDashboard_td_sapOrderNumber_{{i}}">{{cart.sapOrderNo}}</td>
                <td id="myDashboard_td_customerName_{{i}}">{{cart.customerName}}</td>
                <td *ngIf="includeAllBranches" id="myDashboard_td_branch_{{i}}">{{cart.branchCode}} - {{cart.branchName}}</td>
                <td id="myDashboard_td_itemType_{{i}}">
                  <div *ngIf="cart.type === 'cart'" style="text-align:center;">
                    <i class="fas fa-shopping-cart" aria-hidden="true"></i>
                    <span>Open Cart</span>
                  </div>
                  <div *ngIf="cart.type === 'quote'" style="text-align:center;">
                    <i class="fas fa-file-alt" aria-hidden="true"></i>
                    <span>Open Quote</span>
                  </div>
                  <div *ngIf="cart.type === 'order'" style="text-align:center;">
                    <i class="far fa-file-alt" aria-hidden="true"></i>
                    <span>Open Order</span>
                  </div>
                </td>
                <td id="myDashboard_td_customerNumber_{{i}}">{{cart.customerNumber}}</td>
                <td id="myDashboard_td_contactInfo_{{i}}">
                  <div *ngIf="cart.contactInformation!=null">
                    <span *ngIf="cart.contactInformation.streetAddress!='' && cart.contactInformation.streetAddress!=' '">{{cart.contactInformation.streetAddress}}<br /></span>
                    <span *ngIf="(cart.contactInformation.city!='' && cart.contactInformation.city!=' ') || (cart.contactInformation.state!='' && cart.contactInformation.state!=' ')">{{cart.contactInformation.city==null?'':
                    cart.contactInformation.city+','}} {{cart.contactInformation.state}}
                    {{cart.contactInformation.postalCode}}<br /></span>
                    <span *ngIf="cart.contactInformation.phoneNumber!='' && cart.contactInformation.phoneNumber!=' '">{{cart.contactInformation.phoneNumber}}<br /></span>
                  </div>
                </td>
                <td id="myDashboard_td_createdDate_{{i}}">{{cart.createdDate| date: 'MM/dd/yyyy'}}</td>
                <td id="myDashboard_td_expiryDate_{{i}}">{{cart.expiryDate| date: 'MM/dd/yyyy'}}</td>
                <td id="myDashboard_td_countDown_{{i}}">
                  <count-down units="Hours|Minutes|Seconds" end="{{cart.expiryDate}}" [hasDiplayCondition]="true"
                    [diplayThresholdHours]="24" [data]="cart" (timeTickEvent)="timeTickEvent($event)"
                    class="text-danger">
                  </count-down>
                </td>
                <td id="myDashboard_td_itemCount_{{i}}">{{cart.cartItemCount}}</td>
                <td id="myDashboard_td_subtotal_{{i}}">{{cart.price | currency: vm.currencyCode :'symbol':'1.2-2'}}</td>
                <td id="myDashboard_td_actions_{{i}}">
                  <div *ngIf="cart.type !== 'quote'">
                    <a role="button" class="hyperlink" href="javascript:void(0);" [ngClass]="cart.applyRedClass == true ? 'text-danger' : ''"
                    (click)="editClick(cart)">Edit
                    </a>
                  </div>
                  <div *ngIf="cart.type === 'quote';">
                    <a role="button" class="hyperlink" href="javascript:void(0);" [ngClass]="cart.applyRedClass == true ? 'text-danger' : ''"
                      (click)="editClick(cart, vm.userProfile.toLowerCase() !== cart?.updatedBy?.toLowerCase() && cart?.isLocked, cart?.updatedBy)">View
                    </a>
                 </div>
                </td>
                <td id="myDashboard_td_status_{{i}}" style="text-align:center;">
                  <i *ngIf="vm.hasUnsavedChanges" class="fas fa-times" style="color:darkred;" aria-hidden="true"></i>
                  <i *ngIf="!vm.hasUnsavedChanges" class="fas fa-check" style="color:forestgreen;" aria-hidden="true"></i>
                </td>
                <td id="myDashboard_td_lock_{{i}}" style="text-align:center;" *ngLet="{
                  canEdit: commonDataService.User.id === cart.updatedById || (commonDataService.User.id !== cart.updatedById && !cart.isLocked),
                  isLocked: commonDataService.User.id !== cart.updatedById && cart.isLocked
                } as cartPermissions">
                  <i *ngIf="cartPermissions.canEdit"
                    class="fas fa-unlock" aria-hidden="true" style="color:forestgreen;">
                  </i>
                  <div *ngIf="cartPermissions.isLocked">
                    <i class="fas fa-lock" aria-hidden="true" style="color:darkred;"></i>
                    <span>{{cart.updatedBy}}</span>
                  </div>
                  <a role="button" class="hyperlink" (click)="unlockClick(cart)" *ngIf="commonDataService.User.id == cart.updatedById && cart.isLocked">
                    Unlock for others
                  </a>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>

        <!--Show Paginator if there are items in the table after filtering-->
        <div *ngLet="pager$ | async as pager" class="mb-2" [hidden]="!vm.items.length">
          <nav id="myDashboard_div_ShowResults" class="d-flex justify-content-end align-items-center" aria-label="Page navigation" *ngIf="pager.pages && pager.pages.length">
            <div>
              <span class="pagination-result">Showing {{pager.startIndex + 1}} - {{pager.endIndex + 1}} of
                {{pager.totalItems}} Results
              </span>
            </div>
            <div>
              <ul class="pagination">
                <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                  <a id="myDashboard_a_First" class="page-link" aria-label="First" (click)="setPage(1)"><span aria-hidden="true">&laquo;</span></a>
                </li>
                <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                  <a id="myDashboard_a_Previous" class="page-link" aria-label="Previous" (click)="setPage(pager.currentPage - 1)"><span aria-hidden="true">&lsaquo;</span></a>
                </li>
                <li id="myDashboard_li_Page_{{i}}" *ngFor="let page of pager.pages; let i = index" class="page-item" [ngClass]="{active:pager.currentPage === page}">
                  <a id="myDashboard_a_Page_{{i}}" class="page-link" (click)="setPage(page)">{{page}}</a>
                </li>
                <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                  <a id="myDashboard_a_Next" class="page-link" aria-label="Next" (click)="setPage(pager.currentPage + 1)"><span aria-hidden="true">&rsaquo;</span></a>
                </li>
                <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                  <a id="myDashboard_a_Last" class="page-link" aria-label="Last" (click)="setPage(pager.totalPages)"><span aria-hidden="true">&raquo;</span></a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
</div>
