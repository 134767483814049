import { Component, OnDestroy, ViewChild, ElementRef, Input, Output, EventEmitter, AfterViewInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
import { LoaderService } from 'services/loader.service';
import { CommonDataService } from 'services/common-data.service';
import { AuthorizationService } from 'services/authorization.service';
import { Customer } from "entities/customer";
import { Permission } from 'entities/enums';
import { FavouriteCustomersModalComponent } from 'modals/favourite-customers-modal/favourite-customers-modal.component';
import { Store } from '@ngrx/store';
import { AppState } from 'store/app-state';
import * as CustomerActions from 'store/customer/customer.actions';
import * as CustomerSelectors from 'store/customer/customer.selectors';
import * as BranchSelectors from 'store/branch/branch.selectors';
import * as ProvinceSelectors from "store/province/province.selectors";
import * as ConfigurationSelectors from 'store/configuration/configuration.selectors';
import { REGEX_CANADA_POSTAL_PATTERN, REGEX_USA_POSTAL_PATTERN } from 'app/app.constants';
import { Observable } from 'rxjs';
import { Province } from 'entities/province';
import { Branch } from 'entities/branch';
import { SelectedCustomer } from 'store/customer/customer.state';

@Component({
    selector: 'customer-panel',
    templateUrl: './customer-panel.component.html'
})
export class CustomerPanelComponent implements AfterViewInit, OnDestroy {
    @ViewChild('txtCustomerNumber') customerNumberRef: ElementRef<HTMLInputElement>;
    @ViewChild('advCustSearchForm') advCustSearchForm: ElementRef<HTMLFormElement>;
    @Input('source') parentSource: string = '';
    @Input() autofocus: boolean = false;

    customerNumber: string = '';
    advCustomerName: string = '';
    advCity: string = '';
    advState: string = '';
    advPostalCode: string = '';
    advPhoneNumber: string = '';
    hasMessage: boolean = false;
    PayerInValidInBranch: boolean = false;
    customerMesssage: string = '';
    postalCodeInvalid: boolean = false;
    openTab: string = "customerNumber";

    @Output() customerSelect: EventEmitter<Customer> = new EventEmitter();
    @Output() customerNumberChanged: EventEmitter<string> = new EventEmitter();

    public permission: any = Permission;

    isInternal$: Observable<boolean> = this.store.select(ConfigurationSelectors.isInternal);
    countryCode$: Observable<string> = this.store.select(BranchSelectors.selectedBranchCountryCode);
    public provinceList$: Observable<Province[]> = this.store.select(ProvinceSelectors.selectProvinceList);
    public isCanadaUser$: Observable<boolean> = this.store.select(ConfigurationSelectors.isCanadaUser);
    public clearOnBranchChange$: Observable<[Branch, boolean]> = this.store.select(BranchSelectors.selectedBranch).pipe(
      withLatestFrom(this.store.select(ConfigurationSelectors.isInternal)),
      filter(([_, isInternal]) => isInternal),
      tap(() => this.customerNumber = "")
    )

    constructor(
        private store: Store<AppState>,
        private loader: LoaderService,
        public commonDataService: CommonDataService,
        public authorizationService: AuthorizationService,
        private activatedRoute: ActivatedRoute,
        private modalService: NgbModal,
        @Inject(REGEX_CANADA_POSTAL_PATTERN) public regexCanadaPostalPattern: RegExp,
        @Inject(REGEX_USA_POSTAL_PATTERN) public regexUSAPostalPattern: RegExp) {
    }

    ngAfterViewInit() {
      const customer$: Observable<SelectedCustomer> = this.store.select(CustomerSelectors.selectedCustomer)
        .pipe(
          tap(() => {
              this.resetForm();
          })
      );

      if (this.autofocus) {
        this.customerNumberRef.nativeElement.focus();
      }
    }

    resetForm() {
        this.hasMessage = false;
        this.customerMesssage = '';
        this.customerNumber = '';
        this.advCustomerName = '';
        this.advCity = '';
        this.advState = '';
        this.advPostalCode = '';
        this.advPhoneNumber = '';
        this.PayerInValidInBranch = false;
        this.openTab = "customerNumber";
    }

    onNavChange(changeEvent: NgbNavChangeEvent) {
        if (changeEvent.nextId === "favoriteCustomer") {
          changeEvent.preventDefault();
          this.viewFavorites();
        }
        this.resetForm();
    }

    findCustomers() {
      if (!this.customerNumber && !this.advCustomerName && !this.advCity && !this.advState && !this.advPostalCode && !this.advPhoneNumber) {
        return null;
      }

      const searchTerm: string = this.activatedRoute.snapshot.queryParams['searchTerm'];

      this.hasMessage = false;
      this.customerMesssage = '';
      this.loader.loading = true;
      this.PayerInValidInBranch = false;
      this.store.dispatch(CustomerActions.loadShipToCustomers({openShipToModal: false, customerNumber: this.customerNumber}));
      const formattedPostalCode = this.advPostalCode.replace(/-/g, '');

      this.store.dispatch(CustomerActions.findCustomers({
        parentSource: this.parentSource,
        searchTerm,
        customerNumber: this.customerNumber,
        customerName: this.advCustomerName,
        searchCity: this.advCity,
        searchState: this.advState,
        searchPostalCode: formattedPostalCode,
        searchPhoneNumber: this.advPhoneNumber
       }));
    }

    onCustomerKeyUp(event: KeyboardEvent) {
      this.customerNumberChanged.emit(this.customerNumber);
    }

    onCustomerKeypress(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            this.findCustomers();
        }
        const regex = new RegExp('^[a-zA-Z0-9*]+$');
        const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
    }

    onCustomerKeydown(event: KeyboardEvent) {
        if (event.keyCode === 9) {
            this.findCustomers();
        }
    }

    onCustomerNameKeypress(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            this.findCustomers();
        }
    }

    onPhoneNoKeypress(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            this.findCustomers();
        }
    }

    onPostalCodeKeypress(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            this.findCustomers();
        }
    }

    onCityKeypress(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            this.findCustomers();
        }
    }

    onStateKeypress(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            this.findCustomers();
        }
    }

    postalCodeChanged(event: InputEvent, countryCode: string) {
      const value = (event.target as HTMLInputElement).value;
      const valid = countryCode === 'US' ? this.regexUSAPostalPattern.test(value) : this.regexCanadaPostalPattern.test(value);
      this.postalCodeInvalid = !valid && value !== "";
    }

    viewFavorites() {
        const modalRef = this.modalService.open(FavouriteCustomersModalComponent, { size: 'lg'});

        modalRef.result
            .then((customer: Customer) => {
                this.store.dispatch(CustomerActions.selectCustomer({customer}));
                this.customerSelect.emit(customer);
            })
            .catch(() => { });
    }

    ngOnDestroy() {
    }
}
