import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "input[floatOnly]"
})
export class FloatOnlyDirective {
  @HostListener("input", ["$event.target"])
  public onInput(target: { value: string }) {
    const pattern = /^[\d]*[\.]{0,1}[\d]+$/;
    if (!pattern.test(target.value)) {
      target.value = target.value.replace(/[^\d\.?]/g, "");

      const parts: string[] = target.value.split('.');
      if (parts.length >  2) {
        target.value = parts[0]+'.'+parts[1];
      }
    }
  }
}
