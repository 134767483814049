import { CartItemAndDelivery } from "app/_components/checkout-line-item-delivery/checkout-line-item-delivery.component.state";
import { CartResultLineItem } from "entities/cart-result";
import { DeliveryTypes } from "entities/cart-search";

export function getHeaderDelivery(items: CartResultLineItem[]):DeliveryTypes {
  const pickupTotal = items
            .filter((item) => ["W", "L", "K", "A"].indexOf(item.deliveryOption) !== -1)
            .reduce((total, item) => total + (item.quantity * item.finalPrice), 0);

        const deliveryTotal = items
            .filter((item) => ["D", "C", "S"].indexOf(item.deliveryOption) !== -1)
            .reduce((total, item) => total + (item.quantity * item.finalPrice), 0);

        return pickupTotal >= deliveryTotal ? DeliveryTypes.pickup : DeliveryTypes.delivery;
}
