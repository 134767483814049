<div *ngLet="{
  navigationProperties: navigationProperties$ | async,
  partsSearchNavigate: partsSearchNavigate$ | async,
  branchCode: branchCode$ | async
} as vm">
  <h5 class="strong">Part</h5>
</div>
<label>
  Enter part name, part number, manufacturer or keyword.
  <span class="badge bg-danger small">Required</span>
</label>

<form id="partsSearch_form_PartsSearch" #searchForm="ngForm" class="needs-validation" [ngClass]="{ 'was-validated': !searchForm.pending }" (ngSubmit)="partSearch()">
  <div class="form-group">
    <input id="partsSearch_text_SearchTerm" type="text" class="form-control" name="partsSearch_text_SearchTerm" #txtSearchTerm ngModel (ngModelChange)="updateSearchTerm($event)" [ngbTypeahead]="autocomplete" (selectItem)="selectSearchTerm($event)" [focusFirst]="false" requiredTrimmed>
  </div>
  <div id="partsSearch_div_HasCreatePartBuyoutPermission" *ngIf="(hasCreatePartBuyoutPermission$ | async) && (hasAllowProcurementOptionsPermission$ | async) && !(isQuote$ | async)"
    class="form-group">
    <a id="partsSearch_a_PartsBuyOut" class="hyperlink" href="javascript:void(0)" (click)="partBuyoutModal()">Parts Buyout</a>
  </div>
  <button id="partsSearch_button_Submit" type="submit" class="d-none">Submit On Enter</button>
  <div class="form-group gx-1">
    <button id="partsSearch_button_Search" type="button" class="btn btn-primary" [disabled]="!searchForm.form.valid" (click)="searchForm.onSubmit()">Search</button>
    <a id="partsSearch_a_Favorites" class="btn btn-primary" routerLink="/favoriteparts">Favorites</a>
    <button *ngIf="(isWarehouseManaged$ | async) && (isInternal$ | async)" _ngcontent-ceu-c193="" id="myCart_button_OpenBaskets" type="button"
    class="btn btn-primary me-2 btn-shopping-basket-icon-homepg"
            (click)="ShowOpenBasketsModal();">
      <i _ngcontent-ceu-c193="" aria-hidden="true" class="fas fa-shopping-basket"></i>
      Show Open Shopping Baskets
      <span class="shopping-basket-icon-homepg">{{openBasketCount$ | async}}</span>
    </button>
    <div class="float-end pt-2">
      <input id="partSearch_checkbox_IsExpandedSearch" type="checkbox" name="partSearch_checkbox_IsExpandedSearch" #chkIsExpandedSearch ngModel (ngModelChange)="updateIsExpandedSearch($event)">
      <label for="partSearch_checkbox_IsExpandedSearch">Expanded Industry Search</label>
    </div>
  </div>
</form>
