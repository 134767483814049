import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "join",
    pure: true
})
export class JoinPipe implements PipeTransform {
    transform(value: any[], delimiter: string, defaultValue: string): string {
        if (!value) return;

        if (!Array.isArray(value)) {
            throw new Error(`Invalid argument '${value}' for pipe JoinPipe`);
        }

        if (!delimiter && delimiter !== '') delimiter = ', ';

        return value.filter(v => v).join(delimiter) || defaultValue;
    }
}
