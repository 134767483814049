<div>
    <button id="PartBinLocationsModal_Button_Close" type="button" class="close" data-dismiss="modal" aria-label="Close"
        tabindex="-1" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-header">
        <h4 class="modal-title">Part Bin Locations</h4>
    </div>
    <div class="modal-body">
        <table id="PartBinLocationsModal_Table_Information" *ngIf="partBinLocationItems">
            <thead class="col-12" style="background:#111 none repeat scroll 0 0!important;font-size:15px;color:#fff">
                <tr>
                    <th style="width: 9em;" class="IEtablepadding center-text">Warehouse</th>
                    <th style="width: 9em;" class="IEtablepadding center-text">Storage Type</th>
                    <th style="width: 9em;" class="IEtablepadding center-text">Bin Location</th>
                    <th style="width: 9em;" class="IEtablepadding center-text">Quantity Available</th>
                    <th style="width: 3em;"></th>
                </tr>
            </thead>
            <tbody class="table">
                <tr *ngFor="let row of partBinLocationItems; let i = index;">
                    <th id="PartBinLocationsModal_TH_WarehouseNumber_{{i}}" class="center-text">{{row.warehouseNo}}</th>
                    <th id="PartBinLocationsModal_TH_StorageType_{{i}}" class="center-text">{{row.storageType}}</th>
                    <th id="PartBinLocationsModal_TH_BinLocation_{{i}}" class="center-text">{{row.binLocation}}</th>
                    <th id="PartBinLocationsModal_TH_QuantityAvailable_{{i}}" class="center-text">
                        {{row.quantityAvailable}}</th>
                    <th *ngIf="row.fixedBin" id="PartBinLocationsModal_TH_FixedBin_{{i}}" class="center-text"
                        style="background-color: black; color: white;">Fixed</th>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer">
        <span class="alert-danger m-0 mt-2 col text-center" *ngIf="partBinLocationItems.length < 1">Nothing To
            Show</span>
    </div>
</div>