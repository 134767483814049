import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ChipClipLineItems } from 'entities/chip-clip-line-item';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonDataService } from 'services/common-data.service';
import { switchMap, map, takeUntil } from 'rxjs/operators';
import { PartService } from 'services/part.service';
import { Store } from '@ngrx/store';
import { AppState } from 'store/app-state';
import { Subject } from 'rxjs';
import { PartBinLocationCollection } from 'entities/part-bin-location-item';
import * as BranchSelectors from 'store/branch/branch.selectors';

@Component({
  selector: 'chip-clip-input-modal',
  templateUrl: './chip-clip-input-modal.component.html'
})
export class ChipClipInputModalComponent implements OnInit, OnDestroy {

  @Input() chipClipLineItems: ChipClipLineItems[];

  public chipClipSBRLineItems: ChipClipLineItems[] = [];
  public chipClipSelfPickLineItems: ChipClipLineItems[] = [];
  public preferredCLPBin: string = null;
  public binLocations: PartBinLocationCollection = {};
  public binLocationDropDown: PartBinLocationCollection = {};
  private unsubscribeSubject = new Subject<void>();

  currencyCode$ = this.store.select(BranchSelectors.selectCurrencyCode);
  getSBRBinLocations$ = this.store.select(BranchSelectors.selectedBranch)
    .pipe(
      switchMap(branch => {
        const partNumbers = this.chipClipLineItems?.filter(items => items?.storageType !== 'BAS')
          .map((x) => x.partNumber);
        return this.partService.getPartBinLocations(partNumbers, branch.code);
      }),
      map((binLocations) => { this.binLocations = binLocations; }
      ),
      takeUntil(this.unsubscribeSubject)
    );

  constructor(
    public commonDataService: CommonDataService,
    private activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private partService: PartService
  ) { }

  ngOnInit() {

    this.getSBRBinLocations$.subscribe(() => { });

    this.chipClipSBRLineItems = this.chipClipLineItems.filter(items => items.deliveryOption === 'A');
    this.chipClipSelfPickLineItems = this.chipClipLineItems.filter(items => items.deliveryOption === 'L');

  }

  closeModal() {
    let billingRequestLineItems: ChipClipLineItems[] = [];
    if (this.chipClipSelfPickLineItems?.length > 0) {
      billingRequestLineItems = this.chipClipSBRLineItems.concat(this.chipClipSelfPickLineItems);
    } else {
      billingRequestLineItems = this.chipClipSBRLineItems;
    }
    this.activeModal.close(billingRequestLineItems);
  }

  upperCaseStorageBin(newValue: string, index: number) {
     this.chipClipSBRLineItems[index].storageBin = newValue ? newValue.toUpperCase() : "";
  }

  setCLPBin(lineItem: ChipClipLineItems) {
    if (lineItem.storageType === "CLP") {
      this.preferredCLPBin = lineItem.storageBin;
    }
  }

  setBinLocation(lineItem: ChipClipLineItems, storageBin: string, index: number) {
    this.chipClipSBRLineItems[index].storageBin = storageBin.substring(3);
  }

  populateStorageBin(lineItem: ChipClipLineItems, storageType: string) {
    if (storageType === "CLP") {
      lineItem.storageBin = this.preferredCLPBin;
    } else {
      const binLocationContainer = this.binLocations[lineItem.partNumber.toLowerCase()]?.filter(x => x.storageType === lineItem.storageType && (x.quantityAvailable > 0 || x.fixedBin === 'X' ) );
      if (binLocationContainer.length > 1) {
        this.binLocationDropDown[lineItem.partNumber.toLowerCase()] = [];
        for (let i = 0; i < binLocationContainer.length; i++) {
          this.binLocationDropDown[lineItem.partNumber.toLowerCase()].push(binLocationContainer[i]);
        }
      } else {
         lineItem.storageBin = binLocationContainer[0]?.binLocation;
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}

