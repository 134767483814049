export interface CustomerSearch {
    customerType?: null|'AG'|'RG'|'WE';
    customerNumber?: string;
    customerName?: string;
    phoneNumber?: string;
    streetAddress?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    payerNumber?: string;
    accountManager?: string;
    accountGroups?: string[];
    branchCode?: string;
}

export class MscPayerSearch {
    CustomerNumber?: string = '';
    AccountGroups?: string[] = null;
    BranchCode?: string = '';
    SalesOrganization: string = '1000';
    IncludeShipTo: boolean = true;
}

export interface MscPayerSearchResult {
    mscCardNumber: string;
    payers: Payer[];
    shipTos: ShipTo[];
}

export interface CustomerNotes {
    notes: string;
}

export interface MscPayer {
    payerNumber: string;
    mscCardNumber: string;
}

export interface Payer {
    isDefault: boolean;
    name: string;
    payerNumber: string;
}
export interface ShipTo {
    isDefault: boolean;
    mscCardNumber: string;
    name: string;
    payerNumber: string;
}
