import { createAction, props } from '@ngrx/store';
import { Discount } from 'entities/discount';
import { LoyaltyAccount } from 'entities/loyalty-account';
import { LoyaltyState } from './loyalty.state';

export const getLoyaltyDiscounts = createAction(
  '[Loyalty] Get Loyalty Discounts'
);

export const setLoyaltyDiscounts = createAction(
  '[Loyalty] Set Loyalty Discounts',
  props<{ cachedState: LoyaltyState}>()
);

export const getLoyaltyDiscountsWithPromoParts = createAction(
  '[Loyalty] Get Loyalty Discounts With Promo Parts'
);

export const loadLoyaltyDiscountsSuccess = createAction(
  '[Loyalty] Load Loyalty Discounts Sucess',
  props<{ discounts: { [partNumber: string]: Discount[]}}>()
);

export const getAvailableLoyaltyAccountsSuccess = createAction(
  '[Loyalty] Get Available Loyalty Accounts Success', props<{ loyaltyAccounts: LoyaltyAccount[] }>()
);

export const getAvailableLoyaltyAccountsFailed = createAction(
  '[Loyalty] Get Available Loyalty Accounts Failed', props<{ error: any }>()
);

export const changeLoyaltyAccounts = createAction(
  '[Loyalty] Change Loyalty Accounts', props<{ loyaltyAccount: LoyaltyAccount }>()
);

export const selectLoyaltyAccountSuccess = createAction(
  '[Loyalty] Select Loyalty Account Success', props<{ loyaltyAccount: LoyaltyAccount }>()
);

export const linkLoyaltyAccount = createAction(
  '[Loyalty] Link Loyalty Account', props<{ loyaltyAccount: LoyaltyAccount }>()
);

export const getLoyaltyDiscount = createAction(
  '[Loyalty] Get Loyalty Discount', props<{ partNumber: string }>()
);

export const clearLoyaltyAccount = createAction(
  '[Loyalty] Clear Loyalty Account'
);

export const linkLoyaltyAccountSuccess = createAction(
  '[Loyalty] link Loyalty Account Success'
);

export const openLoyaltyAccountModal = createAction(
  '[Loyalty] Open Loyalty Account Modal'
);
