import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { sortData } from 'helpers/sortData';
import { CommonDataService } from 'services/common-data.service';
import { LoggerService } from 'services/logger.service';
import { AppInsightCustomerSearch } from 'entities/app-insight-customer-search';
import { TableService } from 'services/table.service';

@Component({
  selector: 'customer-modal',
  templateUrl: './customer-modal.component.html'
})
export class CustomerModalComponent implements OnInit {

  @Input() source: string;
  @Input() customers: any;
  @Input() customerSearchTerm: any;

  // pager object
  pager: any = {};
  // paged items
  pagedAdvancedCustomers: any[];
  filteredAdvancedCustomers: any[];
  filterKey: string = '';
  sortBy: string = '';
  sortAsc: boolean = false;

  constructor(
    private pagerService: TableService,
    private commonDataService: CommonDataService,
    private loggerService: LoggerService,
    private activatedRoute: ActivatedRoute,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.filteredAdvancedCustomers = this.customers.filter((c) => !c.addressNumber.startsWith('CASH-') );
    this.setPage(1);
  }

  setPage(page: number) {
    if (page < 1 || (page > this.pager.totalPages && this.pager.totalPages > 0)) {
      return;
    }
    // get pager object from service
    this.pager = this.pagerService.getPager(this.filteredAdvancedCustomers.length, page, 6);

    // get current page of items
    this.pagedAdvancedCustomers = this.filteredAdvancedCustomers.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  filter() {
    if (this.filterKey) {
      const filterKeyLowerCase = this.filterKey.toLowerCase();
      this.filteredAdvancedCustomers = this.customers.filter((e)  =>{
        return ((e.customerName && e.customerName.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.customerName2 && e.customerName2.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.customerNumber && ('' + e.customerNumber).toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.streetAddress && e.streetAddress.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.city && e.city.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.state && e.state.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.postalCode && ('' + e.postalCode).toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.phoneNumber && ('' + e.phoneNumber).toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.orderCount && ('' + e.orderCount).toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.accountManagerName && e.accountManagerName.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.accountManager && ('' + e.accountManager).toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.mscAccountNumber && ('' + e.mscAccountNumber).toLowerCase().indexOf(filterKeyLowerCase) > -1));
      });

      this.setPage(1);
    } else {
      this.filteredAdvancedCustomers = this.customers;
      this.setPage(1);
    }
  }

  sortDataBy(sortBy: string) {
    if (this.sortBy == sortBy) {
      this.sortAsc = !this.sortAsc;
    } else {
      this.sortAsc = true;
    }

    this.sortBy = sortBy;

    this.filteredAdvancedCustomers = sortData(this.filteredAdvancedCustomers, this.sortBy, this.sortAsc);

    if (this.pager.pages && this.pager.pages.length) {
      this.setPage(this.pager.currentPage);
    } else {
      this.setPage(1);
    }
  }

  closeModal(): void {
   this.activeModal.dismiss();
  }

  onCustomerSelect(customer: any): void {
    if (this.customerSearchTerm) {
      const searchTerm = this.activatedRoute.snapshot.queryParams['searchTerm'];
      let sourceName = 'CustomerModalComponent_onCustomerSelect';
      let metricName = this.loggerService.getMetricValue(sourceName);
      let appInsightCustomerSearch = Object.assign(new AppInsightCustomerSearch(), {
        userId: this.commonDataService.User.id,
        branchNumber: this.commonDataService.Branch.code,
        searchTerm: '',
        customerSearchTerm: JSON.stringify(this.customerSearchTerm),
        source: this.source,
        result: JSON.stringify(customer),
        customerNotFound: false,
        plMetricName: sourceName
      });
      this.loggerService.trackMetric(metricName, appInsightCustomerSearch);
    }

    this.activeModal.close(customer);
  }
}
