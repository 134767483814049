<button *ngFor="let option of options$ | async"
  ngbDropdownItem [ngClass]="{ 'active': option.value }" 
  [disabled]="disabled" (click)="toggleOption(option.key)">
  {{ shippingStatusLabel(option.key) }}
</button>
<div class="dropdown-divider"></div>
<button ngbDropdownItem class="text-end" [disabled]="disabled" (click)="clear()">
  <i class="fas fa-times"></i>
  Clear
</button>
