import { Component, OnInit, Input } from '@angular/core';
import { OrderService } from 'services/order.service';
import { Observable, Subject, of } from 'rxjs';
import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { ToastService } from 'services/toast.service';
import { DownloadService } from 'services/download.service';
import { ErrorInformation } from 'entities/error-information';
import { LoaderService } from 'services/loader.service';

@Component({
  selector: 'order-invoice',
  templateUrl: './order-invoice.component.html',
  styleUrls: ['./order-invoice.component.scss']
})
export class OrderInvoiceComponent implements OnInit {
  @Input() invoiceNumber: string;

  isDisabled: boolean;

  private invoiceSubject: Subject<{}> = new Subject();
  public invoice$: Observable<boolean> = this.invoiceSubject
  .pipe(
    switchMap(() => {
      this.isDisabled = true;
      this.loader.loading = true;
      return this.orderService.getInvoice(this.invoiceNumber).pipe(
        map((invoice) => {
          if (invoice.pdf?.length) {
            const filename = `Invoice_${this.invoiceNumber}.pdf`;
            this.downloadService.downloadPDF(invoice.pdf, filename );
            this.loader.loading = false;
            return (true);
          } else {
            const error : ErrorInformation = {
              Message: invoice.message?.length ? invoice.message : 'Could not obtain invoice file to download'
            };
            this.toastService.errorMessage("OrderInvoiceComponent", "downloadInvoice", "downloadInvoice", error);
            this.loader.loading = false;
            return (false);
          }
        })
      );
    }),
    tap(() => this.isDisabled = false)
  );

  constructor(private orderService: OrderService,
    private toastService: ToastService,
    private downloadService: DownloadService,
    private loader: LoaderService) {
  }

  ngOnInit() {
  }

  downloadInvoice() {
    this.invoiceSubject.next(null);
  }

}
