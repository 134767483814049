import { Injectable } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { LinkCustomerLoyaltyAccount, LoyaltyAccount, LoyaltyProgram, LoyaltyProgramAccountsSearchCriteria } from 'entities/loyalty-account';
import { Pager } from 'entities/pager';
import { ToastType } from 'entities/toast-type';
import { isCashCustomer } from 'helpers/is-cash-customer';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { LoyaltyService } from 'services/loyalty.service';
import { ToastService } from 'services/toast.service';

interface SearchLoyaltyProgramAccountsModalComponentState {
  loyaltyProgram: LoyaltyProgram;
  searchCriteria: LoyaltyProgramAccountsSearchCriteria;
  loading: boolean;
  loyaltyAccounts: LoyaltyAccount[];
  pageNumber: number;
  selectedLoyaltyAccount: LoyaltyAccount;
}

@Injectable()
export class SearchLoyaltyProgramAccountsModalComponentStore extends ComponentStore<SearchLoyaltyProgramAccountsModalComponentState> {
  private readonly pageSize = 10;

  constructor (
    private ngbActiveModal: NgbActiveModal,
    private loyaltyService: LoyaltyService,
    private toastService: ToastService
  ) {
    super({
      loyaltyProgram: null,
      searchCriteria: {
        branchCode: null,
        companyName: null,
        customerSapCode: null,
        emailAddress: null,
        loyaltyAccountIdentifier: null,
        loyaltyProgram: null,
        phone: null,
        postalCode: null,
        userId: null
      },
      loading: false,
      loyaltyAccounts: [],
      pageNumber: 1,
      selectedLoyaltyAccount: null
    });
  }

  public readonly init = this.updater((state, { branchCode, loyaltyProgram, customerNumber }: { branchCode: string, loyaltyProgram: LoyaltyProgram, customerNumber: string }) =>{
    state.searchCriteria = {
      ...state.searchCriteria,
      customerSapCode: customerNumber,
      branchCode,
      loyaltyProgram
    }
    return { ...state };
  }
);

  public readonly search = this.effect((source$) =>
    source$
      .pipe(
        tap(() => {
          this.patchState({ loading: true, selectedLoyaltyAccount: null });
        }),
        map(() => this.get()),
        switchMap(({ searchCriteria }) =>
        {
            return this.loyaltyService.searchLoyaltyProgramAccounts(searchCriteria)
            .pipe(
              tapResponse(
                (loyaltyAccounts) => {
                  this.patchState({ loading: false, loyaltyAccounts, pageNumber: 1 });
                },
                (error) => {
                  this.toastService.showToast('Error while searching for loyalty program accounts', ToastType.Error);
                  this.patchState({ loading: false, loyaltyAccounts: [], pageNumber: 1 });
                }
              )
            );
          }
        )
      )
  );

  public readonly submit = this.effect((source$) =>
    source$
      .pipe(
        map(() => this.get((state) => state.selectedLoyaltyAccount)),
        tap((selectedLoyaltyAccount) => this.ngbActiveModal.close(selectedLoyaltyAccount))
      )
  );

  public readonly linkCustomerLoyaltyAccount = this.effect((source$) =>
    source$
      .pipe(
        map(() => this.get((state) =>  {
          const loyaltyAccount: LinkCustomerLoyaltyAccount = {
            customerSapCode: state.searchCriteria.customerSapCode,
            loyaltyAccountIdentifier: state.selectedLoyaltyAccount.memberId,
            loyaltyProgramCode: state.selectedLoyaltyAccount.loyaltyProgram
          }
          return loyaltyAccount;
        })),
        filter(x => !isCashCustomer(x.customerSapCode)),
        switchMap((selectedLoyaltyAccount: LinkCustomerLoyaltyAccount) => {
          return this.loyaltyService.linkCustomerLoyaltyProgramAccount(selectedLoyaltyAccount)
            .pipe(
              tapResponse(
                (response) => {
                  //Do Something?
                },
                (error) => {
                  this.toastService.showToast('Error while Linking Loyalty Account', ToastType.Error);
                }
              )
            );
        })
    ));

  public readonly emailAddressChange = this.updater((state, emailAddress: string) => ({
    ...state,
    searchCriteria: {
      ...state.searchCriteria,
      emailAddress
    }
  }));

  public readonly phoneChange = this.updater((state, phone: string) => ({
    ...state,
    searchCriteria: {
      ...state.searchCriteria,
      phone
    }
  }));

  public readonly companyNameChange = this.updater((state, companyName: string) => ({
    ...state,
    searchCriteria: {
      ...state.searchCriteria,
      companyName
    }
  }));

  public readonly postalCodeChange = this.updater((state, postalCode: string) => ({
    ...state,
    searchCriteria: {
      ...state.searchCriteria,
      postalCode
    }
  }));

  public readonly selectAccount = this.updater((state, loyaltyAccount: LoyaltyAccount) => ({
    ...state,
    selectedLoyaltyAccount: loyaltyAccount
  }));

  public readonly setPageNumber = this.updater((state, pageNumber: number) => ({
    ...state,
    pageNumber,
    selectedLoyaltyAccount: null
  }));

  public readonly searchCriteria$ = this.select((state) => state.searchCriteria);

  public readonly atLeastOneSearchCriteria$ = this.select(this.searchCriteria$, (searchCriteria) =>
    Object.values(searchCriteria)
      .some((value) => Boolean(value))
  );

  public readonly loading$ = this.select((state) => state.loading);

  public readonly loyaltyAccounts$ = this.select((state) =>{
      return state.loyaltyAccounts
        .filter((_, index) => index >= ((state.pageNumber - 1) * this.pageSize) && index < (state.pageNumber * this.pageSize))
    }
  );

  public readonly selectedLoyaltyAccount$ = this.select((state) => state.selectedLoyaltyAccount);

  public readonly selectedMemberId$ = this.select(this.selectedLoyaltyAccount$, (selectedLoyaltyAccount) => selectedLoyaltyAccount?.memberId);

  public readonly pager$ = this.select((state) =>
    new Pager(state.loyaltyAccounts.length, state.pageNumber, this.pageSize)
  );
}
