import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { OpenCartAction, OpenCartComponentService, OpenCartSelectors } from 'app/_components/open-cart/open-cart.component.service';
import { Basket } from 'entities/basket';
import { Branch } from 'entities/branch';
import { Customer } from 'entities/customer';
import { Permission } from 'entities/enums';
import { Pager } from 'entities/pager';
import { ToastType } from 'entities/toast-type';
import { BasketListComponent } from 'modals/basket-list/basket-list.component';
import { BehaviorSubject, EMPTY, Observable, Subject, Subscription, combineLatest, merge, of } from 'rxjs';
import { filter, finalize, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthorizationService } from 'services/authorization.service';
import { AutocompleteService } from 'services/auto-complete.service';
import { CommonDataService } from 'services/common-data.service';
import { ConfigurationService } from 'services/configuration.service';
import { ToastService } from 'services/toast.service';
import { AppState } from 'store/app-state';
import * as BasketActions from 'store/basket/basket.actions';
import { basketsLoaded, selectBasketCount, selectBasketList } from 'store/basket/basket.selectors';
import * as BranchSelectors from 'store/branch/branch.selectors';
import * as CartSelectors from 'store/cart/cart.selectors';
import * as CustomerSelectors from 'store/customer/customer.selectors';
import { SelectedCustomer } from 'store/customer/customer.state';
import * as PartsBuyoutActions from 'store/parts-buyout/parts-buyout.actions';
import { PartsSearchBaseComponent } from '../part-search-base/parts-search.component.base';

@Component({
  selector: 'parts-search',
  templateUrl: './parts-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OpenCartComponentService]
})
export class PartsSearchComponent extends PartsSearchBaseComponent implements AfterViewInit, OnDestroy {
  basketList$: Observable<Basket[]> = this.store.select(selectBasketList);
  private action$: Subject<OpenCartAction> = new Subject();
  private selectors: OpenCartSelectors = this.service.getSelectors(this.action$);
  public createCart$: Observable<OpenCartAction> = this.selectors.createCart$;
  public filteredOpenCarts$: Observable<any[]> = this.selectors.filteredOpenCarts$;
  public filters$: Observable<{ byCustomer: boolean, byBranch: boolean}> = this.selectors.filters$;
  public anyOpenCarts$: Observable<boolean> = this.selectors.anyOpenCarts$;
  public loading$: Observable<boolean> = this.selectors.loading$;
  public pager$: Observable<Pager> = this.selectors.pager$;
  openAddBasketItemsModalSubject = new BehaviorSubject<boolean>(false);
  basketsLoaded$: Observable<boolean> = this.store.select(basketsLoaded);
  isQuote$: Observable<boolean> = this.store.select(CartSelectors.selectIsQuote);

  addBasketItemsToCart$ = combineLatest([this.openAddBasketItemsModalSubject, this.basketsLoaded$]).pipe(
    filter(([isOpenBasketButtonClicked, isLoaded]) => isLoaded && isOpenBasketButtonClicked),
    withLatestFrom(this.basketList$),
    switchMap(([_, basketList]: [[boolean, boolean], Basket[]]) => {
      if(basketList?.length){
        const modalRef = this.modalService.open(BasketListComponent);
        modalRef.componentInstance.basketList = basketList;
        modalRef.componentInstance.sourceComponent = PartsSearchComponent;

        return modalRef.result;
      } else {
        this.toastService.showToast(
          "No open shopping baskets.",
          ToastType.Error
        );
        return EMPTY;
      }
    }),
    filter((x: Basket[]) => x != null),
    tap((modalResults: Basket[]) => {
      // dispatch action to create a cart
      if (modalResults.length) {
        const addItemsToCart = {
          cartId: null,
          customerNumber: this.commonDataService.Customer.customerNumber,
          branchCode: this.commonDataService.Branch.code,
          baskets: modalResults,
        };
        this.action$.next({ type: 'create', addItemsToCart });
      }
    }),
    finalize(() => {
      this.openAddBasketItemsModalSubject.next(false);
    })
  );

  // isInternal Observable = bool to distinguish what to display for only internal users
  public isInternal$: Observable<boolean> = this.configurationService.user$
    .pipe(
      map((user) => user.isInternal)
  );

  public isWarehouseManaged$: Observable<boolean> = this.store.select(BranchSelectors.selectedBranch)
  .pipe(
    map((branch) => branch?.isWarehouseManaged ?? false)
  );

  // get open carts again whenever we are forced to a new cart (only happens when a cart is deleted)
  private loadCarts$: Observable<boolean> = of(true);
  private subscription: Subscription =
  combineLatest([
    this.store.select(CustomerSelectors.selectedCustomer),
    this.configurationService.customer$,
    this.loadCarts$,
    this.isWarehouseManaged$
  ])
    .pipe(
      map(([customer, defaultCustomer]: [SelectedCustomer, Customer, boolean, boolean]) =>
        customer?.customerNumber === defaultCustomer.customerNumber
      ),
      withLatestFrom(this.selectors.anyDefaultCustomerOpenCarts$),
      filter(([isCashCustomer, anyDefaultCustomerOpenCarts]: [boolean, boolean]) =>
        !isCashCustomer
        || !anyDefaultCustomerOpenCarts
      ),
      tap(([isCashCustomer, _]) => {
        this.setFilterByCustomer(!isCashCustomer);
      })
    )
    .subscribe();

  constructor(
    private store: Store<AppState>,
    public toastService: ToastService,
    public commonDataService: CommonDataService,
    private configurationService: ConfigurationService,
    public router: Router,
    private authorizationService: AuthorizationService,
    private modalService: NgbModal,
    public autoCompleteService: AutocompleteService,
    private service: OpenCartComponentService) {
      super(
        toastService,
        router,
        autoCompleteService
      );
    this.hasCreatePartBuyoutPermission$ = this.authorizationService.hasPermissionAsync(Permission.CreatePartBuyout);
    this.hasAllowProcurementOptionsPermission$ = this.authorizationService.hasPermissionAsync(Permission.AllowProcurementOptions);
  }
  public openBasketCount$: Observable<number> = this.store.select(selectBasketCount);
  @ViewChild('txtSearchTerm') partSearchRef: ElementRef<HTMLInputElement>;
  public hasCreatePartBuyoutPermission$: Observable<boolean>;
  public hasAllowProcurementOptionsPermission$: Observable<boolean>;

  branchCode$: Observable<Branch> = this.store.select(BranchSelectors.selectedBranch).pipe(
    filter( (branch) => branch !== undefined ),
    tap((selectedBranch: Branch) => {
      this.store.dispatch(BasketActions.basketsLoad({branchCode: selectedBranch.code}));
    })
  );

  ngAfterViewInit() {
    const partsSearchFocus$ =
      combineLatest([
        this.store.select(CustomerSelectors.selectedCustomer),
        this.configurationService.customer$,
        this.store.select(BranchSelectors.selectedBranch)
      ])
        .pipe(
          withLatestFrom(this.configurationService.user$),
          filter(([[customer, defaultCustomer], user]) => !user.isInternal || customer?.customerNumber !== defaultCustomer.customerNumber),
          tap(() => {
            this.partSearchRef.nativeElement.focus();
          })
        );

    this.subscription = merge(partsSearchFocus$, this.addBasketItemsToCart$).subscribe();
    this.partSearchRef.nativeElement.focus();
  }

  partBuyoutModal() {
    this.store.dispatch(PartsBuyoutActions.opetPartsBuyoutModal());
  }

  setFilterByCustomer(filterByCustomer: boolean) {
    this.action$.next({ type: 'setFilterByCustomer', filterByCustomer });
  }

   ShowOpenBasketsModal() {
    this.store.dispatch(BasketActions.addBasketToNewCart());
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

}
