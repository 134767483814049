import { Injectable } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { CartResult, CartResultLineItemSelection, PartVendorPriceRequest } from 'entities/cart-result';
import { ItemToRemoveFromCart } from 'entities/carts/item-to-remove-from-cart';
import { UpdateCartItemFlagRequest } from 'entities/carts/updat-cart-item-flag-request';
import { UpdateFlagCartItem } from 'entities/carts/update-flag-cart-item';
import { HotFlagResult } from 'entities/hot-flag/hot-flag-result';
import { GetInventoryRequest } from 'entities/national-inventory/get-inventory-request';
import { InventoryResult } from 'entities/national-inventory/inventory-result';
import { ToastType } from 'entities/toast-type';
import { forkJoin, of, zip } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CartService } from 'services/cart.service';
import { LoaderService } from 'services/loader.service';
import { NationalInventoryService } from 'services/national-inventory.service';
import { ToastService } from 'services/toast.service';
import { VendorService } from 'services/vendor.service';
import { AppState } from 'store/app-state';
import * as BranchSelectors from 'store/branch/branch.selectors';
import * as CartActions from 'store/cart/cart.actions';
import * as CartSelectors from 'store/cart/cart.selectors';
import * as CustomerSelectors from 'store/customer/customer.selectors';
import * as MyCartActions from './my-cart.actions';
import * as MyCartSelectors from './my-cart.selectors';

@Injectable()
export class MyCartEffects {

  patchInboundFreight$ = createEffect(() => zip(
    this.loaderSerivce.checkoutLoaderSubject.pipe(filter(x => !x)),
    this.action$.pipe(ofType(CartActions.getFreightAndAddToCartSuccess))
  ).pipe(tap(([_, {cart}]) => {
    this.store.dispatch(CartActions.setInboundFreight({inboundFreightLineItem: cart.lineItems.find(x => x.partNumber === "FREIGHT")}));
  })), {dispatch: false});

  getCartItems$ = createEffect(() =>
    this.store.select(CartSelectors.selectCartPartItems).pipe(
      map((cartItems) => MyCartActions.getCartDataSuccess({cartItems }))
    )
  );

  addStoToCart$ = createEffect(() =>
    this.action$.pipe(
      ofType(MyCartActions.addStoToCart),
      map(({addStoRequest, selectedCartItem}) => CartActions.addStoItemToCart({ addCartItemRequest: addStoRequest, cartItem: selectedCartItem }))
    )
  );

  getHotFlagsIfCartContainsHotFlagItems$ = createEffect(() =>
    this.store.select(CartSelectors.selectCartPartItems).pipe(
      filter((cartItems) => cartItems?.some(x => x.isHotFlag)),
      map(() => MyCartActions.getHotFlags())
    ));

  getInventory$ = createEffect(() =>
    this.action$.pipe(
      ofType(MyCartActions.getInventory),
      withLatestFrom(this.store.select(MyCartSelectors.selectedCartItems),
        this.store.select(CustomerSelectors.selectedCustomer), this.store.select(BranchSelectors.selectedBranch)),
      filter(([_, selectedCartItems, customer, branch]) => selectedCartItems.length > 0),
      switchMap(([_, selectedCartItems, customer, branch]) => {
        const cartItems: CartResultLineItemSelection[] = selectedCartItems;
        const getInventoryRequest: GetInventoryRequest = {
          branchCode: branch.code,
          customerNumber: customer.customerNumber,
          partInventories: cartItems.map(x => ({partId: x.partId, partNumber: x.partNumber}))
        };
        this.loaderSerivce.loading = true;
        return this.nationalInventoryService.getInventoryObs(getInventoryRequest).pipe(
          map((inventory: InventoryResult[]) => {
            const cartItemsWithInventory = cartItems.map((cartItems) => {
              const itemInventory = inventory.find(x => x.partNumber === cartItems.partNumber)?.inventory;
              if(itemInventory){
                return {...cartItems, inventory: itemInventory.map(x => ({...x, partsQuantity: 1}))};
              }
              return cartItems;
            });
            // this.patchState({cartItems: cartItemsWithInventory, loading: false});
            return MyCartActions.getInventorySuccess({cartItemsWithInventory});
          }),
          catchError(error => {
            this.toastService.errorMessage('STOModalComponent', 'getInventory', 'getInventory', error);
            return of(MyCartActions.getInventoryFailed({error}));
          }),
          tap(() => this.loaderSerivce.loading = false)
        );
      })
    )
  );

  showPriceVerifyModal$ = createEffect(() =>
      this.action$.pipe(
        ofType(MyCartActions.showPriceVerifyModal),
        withLatestFrom(this.store.select(MyCartSelectors.selectedCartItems)),
        map(( [_, items] ) => CartActions.showPriceVerifyModal({ items }))
      )
  );

  getVendorPrices$ = createEffect(() =>
    this.action$.pipe(
      ofType(MyCartActions.getVendorPrices),
      withLatestFrom(
        this.store.select(MyCartSelectors.selectedCartItems),
        this.store.select(BranchSelectors.selectedBranch)
      ),
      switchMap(( [{vendorCode}, selectedCartItems, branch] ) => {
        const partVendorPriceRequestArray = selectedCartItems.map( (item) => {
            const partVendorPriceRequest: PartVendorPriceRequest  =
            {
              branchCode : branch.code,
              corePartNumber :item.corePartNumber,
              corePrice: item.corePrice,
              partNumber :item.partNumber,
              vendorCode,
              hasCore: item.corePrice > 0
           };
           return partVendorPriceRequest;
        });
        return this.vendorService.getPartVendorPriceObs(partVendorPriceRequestArray).pipe(
          map((vendorPartsPriceResult) => {
            const cartItemsWithVendorPrices = selectedCartItems.map(cartItem => {
              const vendorPart = vendorPartsPriceResult.find(x => x.partNumber === cartItem.partNumber);
              if(vendorPart){
                const knownPartBuyoutAmount = vendorPart.purchasePrice > 0 ? vendorPart.purchasePrice : 1.00;
                const knownPartPrice = cartItem.vendorCode != null?
                (vendorPart.purchasePrice > 0 ? (parseFloat((vendorPart.purchasePrice * 1.50).toFixed(2))) : 1.50) : (vendorPart.purchasePrice <= 0 ? 1.50 : cartItem.finalPrice);
                const coreknownPartBuyoutAmount = vendorPart.hasCore ? (vendorPart.corePurchasePrice > 0 ? vendorPart.corePurchasePrice : 1) : 0;
                const coreknownPartPrice = vendorPart.hasCore ?
                  (cartItem.vendorCode != null ? (vendorPart.corePurchasePrice > 0 ? Number((vendorPart.corePurchasePrice * 1.33).toFixed(2)): 1.33) : cartItem.corePrice) : 0;
                return {
                  ...cartItem,
                  vendorCode: vendorCode != null ? vendorCode : vendorPart.vendorNumber,
                  knownPartBuyoutAmount,
                  knownPartPrice,
                  coreknownPartBuyoutAmount,
                  coreknownPartPrice,
                  branchCode: (cartItem.branchCode || cartItem.branchCode === "") ? vendorPart.branchCode : cartItem.branchCode
                };
              }
              return cartItem;
            });
            const fixedVendor = cartItemsWithVendorPrices.some(x => x.vendorCode === "" || !x.vendorCode)? false: true;
            return MyCartActions.getVendorPricesSuccess({fixedVendor, cartItemsWithVendorPrices});
          }),
          catchError(error => {
            this.toastService.errorMessage('HotFlagComponent', 'getVendorPrices', 'getVendorPrices', error);
            return of(MyCartActions.getVendorPricesFailed({error}));
          })
        );
      })
    )
  );

  searchVendors$ = createEffect(() => this.action$
    .pipe(
      ofType(MyCartActions.getVendorPricesSuccess),
      switchMap(({cartItemsWithVendorPrices}) => {
        const getVendorRequests = cartItemsWithVendorPrices.map(x => this.vendorService.getVendor(x.vendorCode, x.branchCode)
            .pipe(
              tap((response) => {
                const vendor = response[0];
                if(vendor){
                  this.store.dispatch(MyCartActions.searchVendorSuccess({partNumber: x.partNumber, vendorCode: vendor.number, vendorName: vendor.name}));
                }
              })));
        return forkJoin(getVendorRequests);
      })
    ), {dispatch: false});

  deleteSelectedItems$ = createEffect(() =>
      this.action$.pipe(
        ofType(MyCartActions.deleteSelectedItems),
        withLatestFrom(this.store.select(MyCartSelectors.selectedCartItems)),
        map(([_, selectedItems]) => {
          const isPickup = selectedItems.some(x => x.deliveryOption === "W");
          const itemsToRemoveFromCart = selectedItems.map(selectedItem => ({
              cartItemId: selectedItem.cartItemId,
              quantityAvailable: selectedItem.quantityAvailable
            } as ItemToRemoveFromCart));
          return CartActions.removeItemsFromCart({ itemsToRemoveFromCart, isPickupDeliveryType: isPickup });
        })
      )
  );

  closeQuoteItems$ = createEffect(() =>
    this.action$.pipe(
      ofType(MyCartActions.closeQuoteItems),
      withLatestFrom(this.store.select(MyCartSelectors.selectedCartItems)),
      map(([_, selectedItems]) => CartActions.closeQuoteItems({ itemsToClose: selectedItems }))
    )
  );

  getHotFlags$ = createEffect(() =>
    this.action$.pipe(
      ofType(MyCartActions.getHotFlags),
      switchMap(() => this.cartService.getHotFlags()
        .pipe(
          map((hotFlags) => MyCartActions.getHotFlagsSuccess({hotFlags})),
          catchError((error) => {
            this.toastService.errorMessage("MyCartComponentStore", "GetHotFlags", "GetHotFlags", error);
            return of(MyCartActions.getHotFlagsFailed({error}));
          })
        )
      )
    )
  );

  sendHotFlag$ = createEffect(() =>
    this.action$.pipe(
        ofType(MyCartActions.sendHotFlag),
        withLatestFrom(
          this.store.select(CartSelectors.selectCart),
          this.store.select(MyCartSelectors.selectedCartItems),
          this.store.select(MyCartSelectors.allCartItems),
          this.store.select(MyCartSelectors.hotFlags)
        ),
        switchMap(([_, selectedCart, selectedCartItems, allCartItems, hotFlags]:
            [Action, CartResult, CartResultLineItemSelection[], CartResultLineItemSelection[], HotFlagResult[]]) => {
          const vinHotFlagCodes = hotFlags.filter(hf => hf.isVINRequired === true).map(hf => hf.code);
          const updateCartData: UpdateCartItemFlagRequest = {
            cartId: selectedCart.cartId,
            cartItemFlag: 'IsHotFlag',
            vin: selectedCart.vin,
            updateFlagCartItems: selectedCartItems as any[]
          };
          let shouldDeselect: boolean = true;

          if (allCartItems.some(ci => ci.isHotFlag && vinHotFlagCodes.includes(ci.hotFlagCode))) {
            updateCartData.vin = selectedCart.vin;
          }

          return this.cartService.updateCartItemFlag(updateCartData).pipe(
            map((cart) => {
              this.toastService.showToast('Hot Flag Updated Successfully for selected part(s).', ToastType.Success);
              // this.patchState({cartItems: cart.lineItems.map(x => ({...x, selected: false}))})
              return CartActions.updateHotFlagOnCartItemSuccess({ cart });
            }),
            catchError(error => {
              shouldDeselect = false;
              this.toastService.errorMessage('HotFlagComponent', 'updateCartItemsHotFlag', 'UpdateCartItemFlag', error);
              return of(CartActions.updateHotFlagOnCartItemFailed({ error }));
            }),
            tap(() => {
              this.activeModal.close();
              if (shouldDeselect) {
                this.store.dispatch(MyCartActions.unSelectAllItems());
              }
            })
          );
        })
      )
  );

  updateAndSendCartItemHotFlag$ = createEffect(() =>
    this.action$.pipe(
      ofType(MyCartActions.updateAndSendCartItemHotFlag),
      switchMap(({cartItem, hotFlagCode, vin}) => {
        const updateCartData: UpdateCartItemFlagRequest = {
          cartId: cartItem.cartId,
          cartItemFlag: 'IsHotFlag',
          vin,
          updateFlagCartItems: [{...cartItem, hotFlagCode}] as any[]
        };
        this.loaderSerivce.loading = true;
        return this.cartService.updateCartItemFlag(updateCartData).pipe(
          map((cart) => {
            this.toastService.showToast('Hot Flag Updated Successfully for selected part(s).', ToastType.Success);
            // this.patchState({cartItems: cart.lineItems.map(x => ({...x, selected: false}))})
            return CartActions.updateHotFlagOnCartItemSuccess({ cart });
          }),
          catchError(error => {
            this.toastService.errorMessage('HotFlagComponent', 'updateCartItemsHotFlag', 'UpdateCartItemFlag', error);
            return of(CartActions.updateHotFlagOnCartItemFailed({ error }));
          }),
          tap(() => this.loaderSerivce.loading = false)
        );
      })
    ));


  confirmPartsBuyout$ = createEffect(() =>
    this.action$.pipe(
      ofType(MyCartActions.confirmPartsBuyout),
      withLatestFrom(this.store.select(MyCartSelectors.selectedCartItems)),
      map(([_, cartItems]) => {
        const selectedCartItems = cartItems.map(x => {
          const newItem: UpdateFlagCartItem = {
            buyoutPrice: x.knownPartBuyoutAmount,
            cartItemId: x.cartItemId,
            corePrice: x.corePrice,
            corePurchasePrice: x.corePurchasePrice,
            hotFlagCode: x.hotFlagCode,
            salesPrice: x.knownPartPrice,
            vendorCode: x.vendorCode,
            cartItemFlag: null,
            isHotFlag: ""
          };
          return newItem;
        });
        return CartActions.confirmPartsBuyout({ selectedCartItems });
      })
    )
  );

  constructor(
    private action$: Actions,
    private cartService: CartService,
    private store: Store<AppState>,
    private nationalInventoryService: NationalInventoryService,
    private toastService: ToastService,
    private vendorService: VendorService,
    private loaderSerivce: LoaderService,
    private activeModal: NgbActiveModal
  ) { }
}
