<div *ngLet=" {
     partsData: selectCartPartItems$ | async,
     hasCouponData: hasCouponData$ | async,
     currencyCode: currencyCode$ | async
    } as vm">
  <div class="billing-table table-responsive view-all p-5">
    <div class="billing-right d-flex justify-content-between py-3 my-3">
      <div style="float: left; ">
        <h5 class="strong mb-0">My cart</h5>
        <span id="checkout_span_CartsDataEqualSingleItem" *ngIf="vm.partsData && vm.partsData.length == 1">Item
          ({{vm.partsData.length}})</span>
        <span id="checkout_span_CartsDataEqualMultipleItems" *ngIf="vm.partsData && vm.partsData.length > 1">Items
          ({{vm.partsData.length}})</span>
      </div>
      <button id="x" (click)="closeModal()">
        X
      </button>
    </div>
    <ng-container>
      <div id="checkout_div_NoCartDataMessage" *ngIf="!vm.partsData || vm.partsData.length <= 0">
        cart item is not available.
      </div>
      <div id="checkout_div_CartDataPresent" *ngIf="vm.partsData && vm.partsData.length > 0">
        <div id="parts_list" cardClass="checkout-cart">
          <table style="width: 100%;">
            <thead>
              <tr>
                <th style="width: 20%; padding-left: 2%; font-weight: 200; ">Part</th>
                <th style="width: 66%; text-align: end; font-weight: 200; ">Quantity</th>
                <th style="width: 8%; padding-right: 2%; text-align: end; font-weight: 200; ">Price</th>
                <th ></th>
              </tr>
            </thead>
          </table>

          <div class="table-scroll parts-list">
            <table class="table tbody">
              <tbody id="checkout_tbody_CartItem_{{i}}" *ngFor="let partItem of vm.partsData; let i = index">
                <tr class="non-coupon-row">
                  <td >
                    <div class="d-flex flex-row" style="width: 95%; " >
                      <div style="float:left">
                        <img [src]="getImage(partItem.image, imageBaseUrl, '48X48')"
                          [default]="'assets/img/48X48_No_Image.png'" tabindex="-1" alt="No Image">
                      </div>
                      <div style="float:right; margin-left: 4%;">
                        <div style="margin-bottom: 2%;">
                          {{partItem.description}}
                        </div>
                        <div class="text-truncate" title="{{partItem.partNumber}}">{{partItem.partNumber}}</div>
                        <div>
                          <span id="checkout_span_CorePriceAndNotPartsBuyoutTX"
                            *ngIf="partItem.corePrice>0 && partItem.partNumber != 'PARTSBUYOUTTX' ">
                            {{
                            (partItem.coreOption
                            == "NOCORER") ? "No Core Returned" : "Core Returned"
                            }}
                          </span>
                          <span id="checkout_span_CorePriceAndPartsBuyoutTX"
                            *ngIf="partItem.corePrice>0 && partItem.partNumber == 'PARTSBUYOUTTX'">
                            {{ partItem.partBuyoutCoreOption == 'NOCORE' ? 'No Core' : '' }}
                            {{ partItem.partBuyoutCoreOption == 'NICORE' ? 'Non-Inventory Core' : '' }}
                            {{ partItem.partBuyoutCoreOption == 'NICORER' ? 'Non-Inventory Core w Return' : '' }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>

                  <td class="text-start" style="margin-left: -20px; " >{{partItem.quantity}}</td>

                  <td class="text-end" style="margin-left: -20px; ">
                    <ng-container *ngIf="partItem.couponTotal > 0">
                      <div class="checkout-cart -price"
                        [ngClass]="[partItem.isSpecialPricing || vm.hasCouponData ? 'text-danger':'',partItem.lowestPriceWithoutCoupon - partItem.couponTotal != partItem.finalPrice?'strikethrough':'']">
                        {{(partItem.quantity * (partItem.finalPrice + ((partItem.coreOption == "NOCORER" ||
                        partItem.partBuyoutCoreOption == 'NICORE')? partItem.corePrice : 0)) ) |
                        currency: vm.currencyCode :'symbol':'1.2-2'}}
                      </div>
                      <div class="checkout-cart-price">
                        {{(partItem.quantity * (partItem.finalPrice + ((partItem.coreOption == "NOCORER" ||
                        partItem.partBuyoutCoreOption == 'NICORE')? partItem.corePrice : 0)) - partItem.couponTotal) |
                        currency: vm.currencyCode :'symbol':'1.2-2'}}
                      </div>
                    </ng-container>
                    <ng-container *ngIf="partItem.couponTotal <= 0">
                      <div class="checkout-cart-price">
                        {{(partItem.quantity * (partItem.finalPrice + ((partItem.coreOption == "NOCORER" ||
                        partItem.partBuyoutCoreOption == 'NICORE')? partItem.corePrice : 0)) ) |
                        currency: vm.currencyCode :'symbol':'1.2-2'}}
                      </div>
                    </ng-container>
                  </td>
                  <td>
                    <a id="checkout_div_CartItemIsPriceVerified" *ngIf="partItem.isPriceVerified"
                      class="btn price-verify-icon" style="cursor:default; color:#fff;">
                      v
                    </a>
                  </td>
                </tr>
                <tr style="line-height: 10px; height: 1px;" height="5px" *ngFor="let coupon of partItem.sapCoupons"
                  class="coupon-row">
                  <td style="width: 95%; height: 1px; text-align: start; padding-left: 10.5%;">
                    <span *ngIf="coupon.type === 'part'" style="color:green;"> {{coupon.description}} applied</span>
                  </td>
                  <td style="margin-left: -20px; " ></td>
                  <td style="margin-left: -20px; " ></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<button (click)="closeModal()" class="btn close-btn btn-default black-btn">Close</button>
