<button type="button" class="close" data-dismiss="modal" aria-label="Close" tabindex="-1" (click)="cancel()">
  <span aria-hidden="true">&times;</span>
</button>
<div class="modal-header">
  <h4 class="modal-title">OEM Parts Catalog</h4>
</div>
<form #ngForm="ngForm" [ngClass]="{ 'was-validated': !ngForm.form.pending }" (ngSubmit)="onContinueClick({ vin: vin })">
  <fieldset [disabled]="navigating">
    <div class="modal-body gy-3">
      <h5 class="strong">You are about to be redirected to the Navistar VIN-based catalog.</h5>
      <div class="input-group">
          <span class="input-group-text">Enter VIN</span>
        <input type="text" class="form-control" name="vin" [(ngModel)]="vin" placeholder="VIN must be from a vehicle no older than model year 2000" required maxlength="32">
      </div>
      <p>In the catalog, you can build your cart using parts based on the VIN you provide.</p>
    </div>
    <div class="modal-body">
      <ul>
        <li>On the OEM Parts Catalog site, select the “My Parts List” icon to review your parts in the cart.</li>
        <li>Once your cart is complete, simply click on the “Add Selected Parts to the RushCare Parts Connect Cart” button to return to this site.</li>
        <li>Once back on RushCare Parts Connect, you can create a new cart with the products you selected or add your products to an existing cart.</li>
      </ul>
      <div class="d-flex justify-content-center">
        <button type="submit" class="btn btn-primary" [disabled]="!ngForm.form.dirty || !ngForm.form.valid">
          Search Navistar Catalog
          <i class="fas fa-external-link-alt"></i>
        </button>
      </div>
    </div>
  </fieldset>
</form>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
</div>
