import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimLeadingZeros'
})
export class TrimLeadingZerosPipe implements PipeTransform {
  transform(val: string): any {
    if (val !== null) {
        val = val.replace(/^0+/, '')
    }

    return val;
  }
}