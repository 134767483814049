import { Pipe, PipeTransform } from '@angular/core';
import { OrderDetailsLineItem } from 'entities/order-details-line-item';
import { OrderType } from 'entities/order-type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderService } from 'services/order.service';

@Pipe({ name: 'orderLineItems$' })
export class OrderLineItemsPipe implements PipeTransform {
  constructor(private orderService: OrderService) { }

  transform(orderNumber: string): Observable<OrderDetailsLineItem[]> {
    return this.orderService.getOrderDetails(orderNumber, OrderType.Order)
      .pipe(
        map((orderDetails) => orderDetails.lineItems)
      );
  }
}
