import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Permission } from 'entities/enums';
import { AuthorizationGuard, ClientConfigurationGuard, EulaGuard, internalUserGuard } from 'guards';
import { FavoritePartsComponent } from './_components/favorite-parts/favorite-parts.component';
import { HomeComponent } from './_components/home/home.component';
import { MyDashboardComponent } from './_components/my-dashboard/my-dashboard.component';
import { OrderConfirmationComponent } from './_components/order-confirmation/order-confirmation.component';
import { OrderHistoryComponent } from './_components/order-history/order-history.component';
import { AuthenticationGuard } from './_modules/authentication/authentication.guard';
import { AppPreloadStrategy } from './app-preload-strategy';

export const routes: Routes = [
  { path: '', pathMatch: 'full', component: HomeComponent, canActivate: [AuthenticationGuard, ClientConfigurationGuard, EulaGuard] },
  { path: 'orderconfirmation', component: OrderConfirmationComponent, canActivate: [AuthenticationGuard, ClientConfigurationGuard, EulaGuard] },
  { path: 'mydashboard', component: MyDashboardComponent, canActivate: [AuthenticationGuard, ClientConfigurationGuard, internalUserGuard] },
  { path: 'orderhistory', component: OrderHistoryComponent, canActivate: [AuthenticationGuard, ClientConfigurationGuard] },
  { path: 'favoriteparts', component: FavoritePartsComponent, canActivate: [AuthenticationGuard, ClientConfigurationGuard] },
  {
    path: 'cart',
    loadChildren: () => import('./_modules/cart.module').then(m => m.CartModule),
    canActivate: [AuthenticationGuard, ClientConfigurationGuard, EulaGuard],
    data: { preload: false }
  },
  {
    path: 'checkout',
    loadChildren: () => import('./_modules/checkout.module').then(m => m.CheckoutModule),
    canActivate: [AuthenticationGuard, ClientConfigurationGuard, EulaGuard],
    data: { preload: false }
  },
  {
    path: 'parts',
    loadChildren: () => import('./_modules/part.module').then(m => m.PartModule),
    canActivate: [AuthenticationGuard, ClientConfigurationGuard, EulaGuard],
    data: { preload: true }
  },
  {
    path: 'misc',
    loadChildren: () => import('./_modules/misc.module').then(m => m.MiscModule),
    data: { preload: false }
  },
  {
    path: 'users',
    loadChildren: () => import('./_modules/user.module').then(m => m.UserModule),
    canActivate: [AuthenticationGuard, ClientConfigurationGuard, EulaGuard, AuthorizationGuard],
    data: { preload: false, permission: Permission.ReadUsers }
  },
  {
    path: 'price-exception',
    loadChildren: () => import('./_modules/price-exception.module').then(m => m.PriceExceptionModule),
    canActivate: [AuthenticationGuard, ClientConfigurationGuard, EulaGuard],
    data: { preload: false }
  },
  {
    path: 'punchout',
    loadChildren: () => import('./_modules/punchout.module').then(m => m.PunchoutModule),
    canActivate: [AuthenticationGuard, ClientConfigurationGuard, EulaGuard],
    data: { preload: false }
  },
  {
    path: 'back-counter',
    loadChildren: () => import('./_modules/back-counter.module').then(m => m.BackCounterModule),
    canActivate: [AuthenticationGuard, ClientConfigurationGuard, EulaGuard, AuthorizationGuard],
    data: {
      preload: false,
      permission: Permission.Backcounter
     }
  },
  // NOTE:add your route above this line
  { path: '**', pathMatch: 'full', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: AppPreloadStrategy
})
  ],
  exports: [RouterModule],
  providers: [AppPreloadStrategy]
})
export class AppRouteModule { }
