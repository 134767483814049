<!--START modal-->
<button id="stockTransferOrder_button_CloseModal" type="button" class="close" data-dismiss="modal" aria-label="Close" tabindex="-1" (click)="closeModal()">
  <span aria-hidden="true">&times;</span>
</button>
<div class="modal-header">
  <h4 class="modal-title">National Inventory Search</h4>
  <div class="input-group w-50 w-md-33">
    <input id="stockTransferOrder_text_SearchFavouriteId" type="text" class="form-control customer-search-textbox" [(ngModel)]="filterKey"
      (keyup)="filter()" placeholder="Search...">
      <span class="input-group-text">
        <i class="fas fa-search" aria-hidden="true"></i>
      </span>
  </div>
</div>

<!-- <h5 id="stockTransferOrder_h5_LoadingNationalInventory" class="row modal-body" *ngIf="!filteredCartItems">Loading national inventory...</h5> -->
<div id="stockTransferOrder_div_FilteredCartItemsSection" class="modal-body">

  <div id="stockTransferOrder_div_FilteredCartItems" *ngLet="{currencyCode: currencyCode$ | async} as vm">

    <div id="divProcurement" class="gy-2">
      <form id="stockTransferOrder_form_StockTransferOrderForm" #StockTransferOrderForm="ngForm" *ngIf="(filteredCartItems$ | async).length; else noMoreItemsToProcure">

        <div ngbAccordion #acc="ngbAccordion" class="accordion-sm gy-1" [closeOthers]="true">
          <div ngbAccordionItem="stockTransferOrder_panel_StockOrderTransfer_{{i}}" #accItem="ngbAccordionItem" *ngFor="let cartItem of (filteredCartItems$ | async);let i=index">
            <div ngbAccordionHeader class="bg-header">
              <button ngbAccordionToggle type="button" class="btn accordion-toggle">
                <div class="d-flex">
                  <div class="px-2">
                    <div>
                      <i id="stockTransferOrder_i_CartItem_{{i}}" class='fa' [ngClass]="accItem.collapsed ? 'fa-plus' : 'fa-minus'" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div class="flex-grow-1 px-3 d-flex gx-3 text-start">
                    <div>
                      <div>
                        <b>Part Number:</b>
                      </div>
                      <div>{{cartItem.partNumber}}</div>
                    </div>
                    <div>
                      <div>
                        <b>Unit Price:</b>
                      </div>
                      <div id="stockTransferOrder_div_IsSpecialPricing" class="text-danger" *ngIf="cartItem.isSpecialPricing">Special Sale Price</div>
                      <div [ngClass]="{ 'text-danger': cartItem.isSpecialPricing }">
                        <span id="stockTransferOrder_span_IsAdjustedPrice" *ngIf="cartItem.isAdjustedPrice"  style="width: 70px;">
                          {{cartItem.finalPrice==null?"0":cartItem.finalPrice| currency: vm.currencyCode :'symbol':'1.2-2'}}<br />
                          <span>orig. {{cartItem.listPrice==null?"0":cartItem.listPrice |
                            currency: vm.currencyCode :'symbol':'1.2-2'}}</span>
                          <span id="stockTransferOrder_span_IsCorePrice" *ngIf="cartItem.corePrice>0"  [ngClass]="{'form-control-removed': cartItem.coreOption == 'CORE1'}"
                            style="width: 70px;">{{cartItem.corePrice==null?"0":cartItem.corePrice |
                            currency: vm.currencyCode :'symbol':'1.2-2'}}</span>
                        </span>
                        <span id="stockTransferOrder_span_IsNotAdjustedPrice" *ngIf="!cartItem.isAdjustedPrice"  style="width: 70px;">
                          {{cartItem.finalPrice==null?"0":cartItem.finalPrice| currency: vm.currencyCode :'symbol':'1.2-2'}}
                          <span *ngIf="cartItem.corePrice>0" [ngClass]="{'form-control-removed': cartItem.coreOption == 'CORE1'}"
                            style="width: 70px;">{{cartItem.corePrice==null?"0":cartItem.corePrice |
                            currency: vm.currencyCode :'symbol':'1.2-2'}}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="ms-auto">
                    <button id="stockTransferOrder_button_RemoveCartItem" style="background: transparent; border: none !important;" role="button" (click)="removeCartItem(cartItem)">
                      <i class="fas fa-times text-danger" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </button>
            </div>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <div class="row align-items-center">
                    <div id="stockTransferOrder_div_NoInventory" *ngIf="!cartItem.inventory || !cartItem.inventory.length">
                      <h5>None available at any other branches.</h5>
                    </div>
                    <div id="stockTransferOrder_div_Inventory" class="inventory-search-table" *ngIf="cartItem.inventory && cartItem.inventory.length>0">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>
                              <a id="stockTransferOrder_a_Branch" role="button" class="hyperlink" href="javascript:void(0)" (click)="sortDataBy(cartItem,'branchCode');">
                                Branch
                                <span [hidden]="cartItem.sortBy != 'branchCode'" class="fas" [ngClass]="{'fa-caret-up': cartItem.sortBy == 'branchCode' && cartItem.sortAsc, 'fa-caret-down': cartItem.sortBy == 'branchCode' && !cartItem.sortAsc}">
                                </span>
                              </a>
                            </th>
                            <th>
                              <a id="stockTransferOrder_a_Distance" role="button" class="hyperlink" href="javascript:void(0)" (click)="sortDataBy(cartItem,'milesFromSource');">
                                Distance
                                <span [hidden]="cartItem.sortBy != 'milesFromSource'" class="fas" [ngClass]="{'fa-caret-up': cartItem.sortBy == 'milesFromSource' && cartItem.sortAsc, 'fa-caret-down': cartItem.sortBy == 'milesFromSource' && ! cartItem.sortAsc}">
                                </span>
                              </a>
                            </th>
                            <th>
                              <a id="stockTransferOrder_a_Inventory" role="button" class="hyperlink" href="javascript:void(0)" (click)="sortDataBy(cartItem,'quantity');">
                                Inventory
                                <span [hidden]=" cartItem.sortBy != 'quantity'" class="fas" [ngClass]="{'fa-caret-up':  cartItem.sortBy == 'quantity' &&  cartItem.sortAsc, 'fa-caret-down':  cartItem.sortBy == 'quantity' && ! cartItem.sortAsc}">
                                </span>
                              </a>
                            </th>
                            <th id="stockTransferOrder_th_Quantity" *ngIf="authorizationService.hasPermission(permission.AllowProcurementOptions)">
                              Quantity
                            </th>
                            <th id="stockTransferOrder_th_BlankSpace" *ngIf="authorizationService.hasPermission(permission.AllowProcurementOptions)">
                              &nbsp;
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr id="stockTransferOrder_tr_FilteredInventoryList_{{i}}_{{j}}" *ngFor="let inventory of cartItem.filteredInventories;let j =index">
                            <!--| inventoryFilterPipe:inventoryFilter;-->
                            <td style="width:30%"><b id="stockTransferOrder_b_branchname_{{i}}_{{j}}">{{inventory.branchCode +" - "+ inventory.branchName}}</b></td>
                            <td style="width:20%"> {{inventory.milesFromSource}} Miles</td>
                            <td style="width:20%">{{inventory.quantity}}</td>
                            <td id="stockTransferOrder_td_AllowProcureOptions_{{i}}_{{j}}" style="width:20%; padding-right: 0px; padding-left: 0px;" *ngIf="authorizationService.hasPermission(permission.AllowProcurementOptions)">
                              <div class="input-group spinner">
                                <div class="range-text-box" style="width: 100% !important;">
                                  <part-quantity
                                  inputName="stockTransferOrder_text_PartsBuyoutQuantity_{{i}}_{{j}}"
                                  placeholder="Quantity"
                                  [value]="inventory.partsQuantity"
                                  [max]="inventory.quantity"
                                  (onChangeQuantity)="changeQuantity($event, inventory);"
                                  idModifier="stockTransferOrder_text_PartsBuyoutQuantity_{{i}}"
                                  [index]="j"
                                  inputAutoComplete="off"
                                ></part-quantity>
                                </div>
                              </div>
                            </td>
                            <td style="width:10%" *ngIf="authorizationService.hasPermission(permission.AllowProcurementOptions)">
                              <button id="stockTransferOrder_button_SubmitAddToCart_{{i}}_{{j}}" type="submit" class="btn btn-default yellow-btn" (click)="addToCart(inventory,cartItem)">Add&nbsp;to&nbsp;Cart</button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ng-template #noMoreItemsToProcure>
        There is nothing else to procure.
      </ng-template>
    </div>
  </div>
</div>

<!--END modal-->
