import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { withLatestFrom, switchMap, map, tap, filter, catchError } from "rxjs/operators";
import * as FeatureFlagActions from 'store/feature-flags/feature-flags.actions';
import { of } from "rxjs";
import { FeatureFlagApiService } from "services/feature-flag-api.service";

@Injectable()
export class FeatureFlagsEffects {


  getFeatureFlags$ =
    createEffect(() =>
      this.action$
        .pipe(
          ofType(FeatureFlagActions.getFeatureFlags),
          switchMap(() =>
            this.featureFlagApiService.getFeatureFlags().pipe(
              map(featureFlags => FeatureFlagActions.loadFeatureFlags({featureFlags})),
              catchError(error => of(FeatureFlagActions.getFeatureFlagsFailed({error})))
            )
          )
        )
    );

  constructor(
    private action$: Actions,
    private featureFlagApiService: FeatureFlagApiService
  ) { }

}
