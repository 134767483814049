import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Subject, of, Observable, merge } from 'rxjs';
import { switchMap, catchError, shareReplay, map, tap } from 'rxjs/operators';
import { CommonDataService } from 'services/common-data.service';
import { DiscretePage } from 'entities/discrete-page';
import { User } from 'entities/user';
import { OrderService } from 'services/order.service';
import { LoaderService } from 'services/loader.service';
import { ToastService } from 'services/toast.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import { isCashCustomer } from 'helpers/is-cash-customer';
import { Store } from '@ngrx/store';
import { AppState } from 'store/app-state';
import * as FeatureFlagSelectors from 'store/feature-flags/feature-flags.selectors';
import { OrderHistoryRequest, OrderHistory } from 'entities/order-history';
import { OrderType } from 'entities/order-type';
import * as ConfigurationSelectors from 'store/configuration/configuration.selectors';
import { OrderStatus } from 'entities/order-confirmation/order-status';
import { OrderItemStatus } from 'entities/order-details-line-item';
import { ToastType } from 'entities/toast-type';
import { OrderDetails } from 'entities/order-details';
import { OrderFreight } from 'entities/order-freight';

@Component({
  selector: 'order-history',
  templateUrl: './order-history.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderHistoryComponent {
  currentPageNumber: number = 1;
  pageSize: number = 10;
  public OrderItemStatusEnum = OrderItemStatus;

  private lastRequest: OrderHistoryRequest;

  isInternal$: Observable<boolean> = this.store.select(ConfigurationSelectors.isInternal);
  orderStatuses: OrderStatus[] = Object.values(OrderStatus);

  private searchOrdersSubject: Subject<OrderHistoryRequest> = new Subject();
  private findOrderSubject: Subject<string> = new Subject();
  private ordersPage$: Observable<DiscretePage<OrderHistory>> = this.searchOrdersSubject.pipe(
    tap(() => {
      this.loaderService.loading = true;
    }),
    switchMap(request => this.orderService.getOrderHistory(request)
      .pipe(
        catchError((err): Observable<DiscretePage<OrderHistory>> => {
          if (err && err.message && err.ErrorType === undefined) {
            this.toastService.showToast('Failed to get order history. ' + err.message, ToastType.Error);
          } else {
            this.toastService.errorMessage("order-history", "getOrderHistory", "getOrderHistory", err);
          }
          return of({ count: 0, items: [] });
        })
      )
    ),
    tap(() => {
      this.loaderService.loading = false;
    }),
    shareReplay(1)
  );

  private findResponse$: Observable<DiscretePage<OrderHistory>> = this.findOrderSubject.pipe(
    tap(() => {
      this.loaderService.loading = true;
    }),
    switchMap(request => this.orderService.getOrderDetails(request, OrderType.Order)
      .pipe(
        map((response : OrderDetails): DiscretePage<OrderHistory> => {
          return {
            count: 1,
            items: [{
              ...response.orderHistory,
              hasFreightTrackingNumber: response.freightDetails
                && response.freightDetails.some(d => d.trackingId) ,
                freightDeliveryStatus:  response.freightDetails?.reduce( (prev, curr, index) => {
                   if (index !==0 && prev.trackingText !== curr.trackingText)
                     { return {trackingText: ''} as OrderFreight }
                     else {
                      return curr
                    }
                } , {} as OrderFreight).trackingText
            }]
          };
        }),
        catchError((err): Observable<DiscretePage<OrderHistory>> => {
          console.log("error:", err);
          return of({ count: 0, items: [] });
        })
      )
    ),
    tap(() => {
      this.loaderService.loading = false;
    }),
    shareReplay(1)
  );

  public orders$: Observable<OrderHistory[]> = merge(this.findResponse$, this.ordersPage$)
    .pipe(
      map((ordersPage) => ordersPage.items)
    );

  public orderCount$: Observable<number> = merge(this.findResponse$, this.ordersPage$)
    .pipe(
      tap(ordersPage => {
        if (ordersPage.count === 1) {
          this.showDetails(ordersPage.items[0].orderNumber);
        }
      }),
      map((ordersPage) => ordersPage.count)
    );

  public orderHistoryDeliveryEnabled$: Observable<boolean> = this.store.select(FeatureFlagSelectors.isFeatureActive('OrderHistoryDelivery'));

  constructor(public commonDataService: CommonDataService,
    private orderService: OrderService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private store: Store<AppState>) {
  }

  searchOrders(request: OrderHistoryRequest) {
    this.lastRequest = request;
    this.currentPageNumber = request.startNum / request.count + 1;
    this.searchOrdersSubject.next(request);
  }

  findOrder(orderNumber: string) {
    this.lastRequest = null;
    this.currentPageNumber = 1;
    this.findOrderSubject.next(orderNumber);
  }

  pageChange(event: number) {
    this.currentPageNumber = event;

    const req = this.lastRequest;
    req.startNum = (this.currentPageNumber - 1) * req.count;
    this.searchOrders(req);
  }

  showDetails(orderNumber: string) {
    const modalRef = this.modalService.open(OrderDetailsComponent, { size: 'lg' });
    modalRef.componentInstance.orderNumber = orderNumber;
  }

  isCashCustomer(customerNumber: string) {
    return isCashCustomer(customerNumber);
  }

  isAnyFreightDelivery(orderItem: OrderHistory): boolean {
    return orderItem.deliveryOption === 'freight'
      || orderItem.deliveryOption === 'composite';
  }

  isAnyRtcDelivery(orderItem: OrderHistory): boolean {
    return orderItem.deliveryOption === 'rtcDelivery'
      || orderItem.deliveryOption === 'composite';
  }

  isFreightShipped(orderItem: OrderHistory): boolean {
    return this.isAnyFreightDelivery(orderItem) && orderItem.hasFreightTrackingNumber;
  }

}
