
  <div class="no-part-found-box-outer-wrapper">
    <h4 class="p-4 text-center strong">Sorry, we cannot find a part matching your search term {{partSearchTerm}}</h4>
    <div class="row p-1 no-part-found-box-inner-wrapper">
        <div class="col-5">
            <div class="error-image-wrapper">
                <img src="assets/img/RTC-Logo.svg">
            </div>
        </div>
        <div class="col-6 d-flex align-items-center no-part-found-option-box" style="border-start: 4px solid #000;">
            <div>
                <h5 class="strong">
                    <u>Options</u>
                </h5>
                <ul class="empty-part-search-text">
                    <li>Search another part number</li>
                    <li>Do a Keyword search</li>
                    <li *ngIf="!commonDataService.User?.isInternal">Contact RushCare at 855-787-4223</li>
                    <li *ngIf="commonDataService.User?.isInternal">Create part using the button below</li>
                </ul>
            </div>
        </div>
    </div>
    <div *ngIf="commonDataService.User?.isInternal" class="p-2 pb-5">
        <div class="bg-white p-2 d-flex justify-content-between align-items-center empty-part-search-text">
            <div class="gx-1">
              <i class="fas fa-exclamation-circle text-danger" aria-hidden="true"></i>
              <span>There are no search results for part {{partSearchTerm}} in your branch. </span>
              <span *ngIf="authorizationService.hasPermission(permission.CreatePart)">Choose another part or attempt to Create Part. Create Part will occur if part exists on a price file. </span>
            </div>
            <button type="button" *ngIf="authorizationService.hasPermission(permission.CreatePart)"
                class="btn btn-default yellow-btn d-flex align-items-center gx-1"
                (click)="createPart(partSearchTerm)" [disabled]="(cartIsLocked$ | async)">
                <span *ngIf="!showCreatePartLoader" style="white-space: nowrap;">Create Part</span>
                <ng-container *ngIf="showCreatePartLoader">
                  <span>Processing...</span>
                  <span class="countLoader"></span>
                </ng-container>
            </button>
            <button type="button" class="close mx-3" (click)="dismiss()">&times;</button>
        </div>
    </div>
  </div>
