import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { takeUntil, debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { Subject } from "rxjs";

/**
 * Debounce input change
 *
 * @param {number} debounceTime void (default value 700ms)
 * @param {function} onDebouncedInput event
 */
@Directive({
  selector: "[debounceInput]"
})
export class DebounceInputDirective implements OnInit, OnDestroy {

  @Input()
  public debounceTime: number = 700;

  @Output()
  public onDebouncedInput: EventEmitter<any> = new EventEmitter();

  protected emitEvent$: Subject<any> = new Subject();
  protected active$: Subject<void> = new Subject<void>();

  @HostListener("input", ["$event"])
  public onKeyUp(event: any): void {
    event.preventDefault();
    this.emitEvent$.next(event);
  }

  ngOnInit(): void {
    this.emitEvent$.pipe(
      takeUntil(this.active$),
      debounceTime(this.debounceTime),
      distinctUntilChanged(),
      tap((value) => {
        this.onDebouncedInput.emit(value);
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.active$.next();
    this.active$.complete();
  }

}
