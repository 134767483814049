import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AppState } from 'store/app-state';
import { MyCartState } from './my-cart-state';


export const myCartState = createFeatureSelector< MyCartState>('myCart');


export const allCartItems = createSelector(
  myCartState,
  (state) => state.cartItems
);
export const selectedCartItems = createSelector(
  myCartState,
  (state) => state.cartItems?.filter(x => x.selected)
);
export const hotFlagCartItems = createSelector(
  myCartState,
  (state) => state.cartItems?.filter(x => x.isHotFlag)
);
export const stoCartItems = createSelector(
  myCartState,
  (state) => state.cartItems?.filter(x => x.isSTO)
);
export const buyoutCartItems = createSelector(
  myCartState,
  (state) => state.cartItems?.filter(x => x.isBuyout)
);
export const nonProcuredItems = createSelector(
  myCartState,
  (state) => state.cartItems?.filter(x => !x.isBuyout && !x.isHotFlag && !x.isSTO)
);
export const hotFlags = createSelector(
  myCartState,
  (state) => state.hotFlags
);
export const selectedCartId = createSelector(
  myCartState,
  (state) => state.cartItems[0]?.cartId
);
export const autoSelectQuote = createSelector(
  myCartState,
  (state) => state.autoSelectQuote
);

export const getPartVendorName = (partNumber) => {
  return createSelector(
      myCartState,
      (state) => state.cartItems.find(x => x.partNumber === partNumber).vendorName
    );
}












