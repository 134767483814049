import { createAction, props } from "@ngrx/store";
import { Basket } from "entities/basket";
import { BasketLineItem } from "entities/basket-line-item";
import { CartResult } from "entities/cart-result";

// OLD BASKET ACTIONS SECTION
// when you check that action is usable with new state management move it to lower NEW section
// otherwise delete it
// remove comments when old section is emptied.

export const basketsLoad = createAction(
  "[Basket] Load Baskets",
  props<{ branchCode: string }>()
);

export const basketsLoadSuccess = createAction(
  "[Basket] Load Baskets Success",
  props<{ baskets: Basket[] }>()
);

export const basketsLoadFailure = createAction(
  "[Basket] Load Baskets Failure",
  props<{ error: any }>()
);

export const selectItemInBasket = createAction(
  "[Basket] Select Item",
  props<{ item: BasketLineItem }>()
);

export const basketItemQtyChanged = createAction(
  "[Basket] Item Quantity Changed",
  props<{ item: BasketLineItem }>()
);

export const changeBasketItemQty = createAction(
  "[Basket] Change Item Quantity",
  props<{ item: BasketLineItem; newQuantity: number }>()
);

export const refreshBasketItemsList = createAction(
  "[Basket] Refresh Basket Items List"
);

// END OF OLD BASKET ACTIONS SECTION


// NEW BASKET ACTIONS SECTION
// remove the section comments when all actions are rechecked and moved here or deleted

export const addBasketItemsToCart = createAction(
  "[Basket] Add Basket Items To Cart"
);

export const addBasketToCart = createAction(
  "[Basket] Add Basket To Cart",
  props<{ cartId: string; customerNumber: string; branchCode: string ; baskets: Basket[] }>()
);

export const addBasketToNewCart = createAction(
  "[Basket] Add Basket To New Cart"
);

export const addBasketToCartSuccess = createAction(
  "[Basket] Add Basket To Cart Success",
  props<{ cart: CartResult }>()
);

export const addBasketToCartFailed = createAction(
  "[Basket] Add Basket To Cart Failed",
  props<{ error: any }>()
);

// END OF NEW BASKET ACTIONS SECTION
