import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { CheckoutValidationMessages } from "app/_components/checkout-validation/checkout-validation.messages";
import {
  CheckoutFormGroup
} from "app/_components/checkout/checkout.component";
import { Branch } from "entities/branch";
import { CartResultLineClient } from "entities/cart-result";
import { DeliveryTypes } from "entities/cart-search";
import { OrderType } from "entities/order-type";
import { isNullOrEmpty } from "validators/null-or-empty.validator";

export const smsOptInEnabled = (branch: Branch, form: any): boolean => {
  const deliveryType = form.shippingAndDelivery?.deliveryType;
  const lineItems = form.shippingAndDelivery?.cartData?.lineItems;
  return branch?.isDeliveryNotificationsEnabled &&
    (deliveryType === 'delivery' || lineItems?.some((cartLine) => cartLine.deliveryOption === 'D'));
}

export const isPrimaryEmailRequired = (lineItems: CartResultLineClient[], deliveryType: string, orderType: OrderType): boolean => {
  const hasPartsBoyoutX = lineItems.some(lineItem => lineItem.partNumber === 'PARTSBUYOUTTX');
  if(hasPartsBoyoutX) {
    return true;
  }
  if(OrderType.Quote === orderType) {
    return false;
  }
  if (deliveryType === "shipto") {
    return true;
  }
  if (lineItems.length > 0) {
    const isInStock = lineItems?.every(item => (item.partId && item.quantityAvailable > 0 && !item.isSTO) || (!item.partId));
    return !isInStock;
  }
  return false;
};

export const validatePrimaryEmailRequired = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const checkoutFormGroup = control.root as FormGroup<CheckoutFormGroup>;
    const lineItems = checkoutFormGroup.value.shippingAndDelivery?.cartData?.lineItems ?? [];
    const shipto = checkoutFormGroup.value.shippingAndDelivery?.deliveryType;
    const isUserInternal = checkoutFormGroup.value?.shippingAndDelivery?.cartData?.isUserInternal;
    const deliveryTypeValue = checkoutFormGroup.value.shippingAndDelivery?.deliveryType ?? "";
    const altEmailControlValue = checkoutFormGroup.value.additionalDetails?.altEmail;
    const transactionTypeValue = checkoutFormGroup.value.checkoutDetails?.transactionType;
    const emailRequired = isPrimaryEmailRequired(lineItems as CartResultLineClient[], deliveryTypeValue, transactionTypeValue);
    if (isNullOrEmpty(altEmailControlValue) && emailRequired && isUserInternal) {
      return {
        "additionalDetails.altEmail.required": shipto ?
          CheckoutValidationMessages["altEmail.internal-freight.required"] :
          CheckoutValidationMessages["altEmail.required"]
      };
    }
    return null;
  };
};

const validateEmailPattern = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const checkoutFormGroup = control.root as FormGroup<CheckoutFormGroup>;
    const altEmailControlValue = checkoutFormGroup.value.additionalDetails?.altEmail;
    if (altEmailControlValue && !emailRegex.test(altEmailControlValue)) {
      return { "additionalDetails.altEmail.pattern": CheckoutValidationMessages["altEmail.email"] };
    }
    return null;
  };
};

const validateContactNameRequired = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const checkoutFormGroup = control.root as FormGroup<CheckoutFormGroup>;
    const deliveryTypeValue =
      checkoutFormGroup.value.shippingAndDelivery?.deliveryType ?? "";
    const isRTCDelivery = DeliveryTypes.delivery === deliveryTypeValue;
    const isOptIn = checkoutFormGroup.value.smsOpt?.optIn ?? false;
    const contactNameValue = checkoutFormGroup.value.smsOpt?.contactName;
    if (isRTCDelivery && isOptIn && isNullOrEmpty(contactNameValue)) {
      return { "additionalDetails.contactName.required": CheckoutValidationMessages["contactName.required"] };
    }
    return null;
  };
};

const validateMobileNumberRequired = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const checkoutFormGroup = control.root as FormGroup<CheckoutFormGroup>;
    const deliveryTypeValue =
      checkoutFormGroup.value.shippingAndDelivery?.deliveryType ?? "";
    const isRTCDelivery = DeliveryTypes.delivery === deliveryTypeValue;
    const isOptIn = checkoutFormGroup.value.smsOpt?.optIn ?? false;
    const mobileNumberValue = checkoutFormGroup.value.smsOpt?.mobileNumber;
    if (isRTCDelivery && isOptIn && isNullOrEmpty(mobileNumberValue)) {
      return { "additionalDetails.mobileNumber.required": CheckoutValidationMessages["mobileNumber.required"] };
    }
    return null;
  };
};

export const additionalDetailsValidators: ValidatorFn[] = [
  validatePrimaryEmailRequired(),
  validateEmailPattern(),
  validateContactNameRequired(),
  validateMobileNumberRequired(),
];
