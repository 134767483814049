import { DOCUMENT } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppInsightBilling } from "entities/app-insight-billing";
import { AppInsightCustomerChange } from "entities/app-insight-customer-change";
import { Branch } from 'entities/branch';
import { CustomWindow } from 'entities/custom-window';
import { Customer } from 'entities/customer';
import { User } from 'entities/user';
import { EMPTY, Observable, Subject, Subscription, from, merge } from 'rxjs';
import { filter, map, pairwise, skip, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthorizationService } from 'services/authorization.service';
import { BranchService } from 'services/branch.service';
import { CartService } from 'services/cart.service';
import { CommonDataService } from 'services/common-data.service';
import { ConfigurationService } from 'services/configuration.service';
import { FeatureFlagService } from 'services/feature-flag.service';
import { LoggerService } from "services/logger.service";
import { ToastService } from 'services/toast.service';
import { ViewConfigurationService } from 'services/view-configuration.service';
import { AppState } from 'store/app-state';
import { AuthenticationService } from './_modules/authentication/authentication.service';

declare const window: CustomWindow;

@Component({
  selector: 'partsconnect-app',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('fixedTop') fixedTop : ElementRef<HTMLElement>;
  fixedTopValue$ = new Subject<number>();
  subscription: Subscription;
  showSearchHeader$: Observable<boolean>;
  isFullSizeWidth$: Observable<boolean> = this.viewConfigService.fullSizeWidth$;

  constructor(
    public store: Store<AppState>,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public toastService: ToastService,
    private cartService: CartService,
    public commonDataService: CommonDataService,
    private authenticationService: AuthenticationService,
    public authorizationService: AuthorizationService,
    private changeDetectorRef: ChangeDetectorRef,
    private configurationService: ConfigurationService,
    private branchService: BranchService,
    @Inject(DOCUMENT) private document: Document,
    private loggerService: LoggerService,
    private viewConfigService: ViewConfigurationService,
    private featureFlagService: FeatureFlagService
  ) {
    const partSearchRoutes = [
      '/parts',
      '/cart',
      '/favoriteparts'
    ];
    this.showSearchHeader$ = this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        map((event: Event) => <NavigationEnd>event),
        map((event: RouterEvent) => partSearchRoutes.some(r => event.url.startsWith(r)))
      );
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent){
    if(this.featureFlagService.isFeatureActive('Analytics.FrontendApplicationInsights')){
      const target: any = event.target;
      const id = target?.id;
      const srcElement = target?.innerHTML;
      this.loggerService.trackEvent('MouseClick', {
        id,
        srcElement
      });
    }
  }

  ngAfterViewChecked() {
    this.fixedTopValue$.next(this.fixedTop.nativeElement.offsetHeight);
    // explicit change detection to avoid "expression-has-changed-after-it-was-checked-error"
    this.changeDetectorRef.detectChanges();
  }

  @HostListener('window:resize')
  onResize() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    const renewTokenObs = this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        // Don't call on the initial-page-load navigation event
        skip(1),
        tap(() => this.authenticationService.renewOnExpiring())
      );

    // Would like to have this in common-data.service, but that results in circular service injection
    const userObs = this.configurationService.configuration$
      .pipe(
        switchMap(() => this.configurationService.allPermissions$),
        // TODO: Use a route guard instead
        switchMap((allPermissions: string[]) => {
          if (!allPermissions.length) {
            return from(this.router.navigateByUrl("/misc/unauthorized")).pipe(switchMap(() => EMPTY));
          }
          return this.configurationService.user$;
        }),
        // Set empty entityPermissions to sessionStorage,
        // since it can be a large collection for internal users with Branch roles assigned
        // and reading that large collection from sessionStorage has a noticeable performance impact
        // during page redraw in aggregate from all the page-redraw references to sessionStorage User
        // Nothing reading User from sessionStorage references the entityPermissions property
        map((user: User) => ({
          ...user,
          entityPermissions: {}
        })),
        tap((user: User) => {
          this.commonDataService.User = user;
        })
      );

    const routeChangeObs = this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => <NavigationEnd>event),
        map((event: NavigationEnd): [NavigationEnd, ActivatedRoute] => {
          let route: ActivatedRoute = this.activatedRoute;
          while (route.firstChild) { route = route.firstChild; }
          return [event, route];
        }),
        filter(([event, route]) => route.outlet === "primary"),
        // Log Page View
        tap(([event, route]) => {
          this.loggerService.trackPageView();
        }),
      );

    const cartIdObs: Observable<string> = this.cartService.cartUpdate$
      .pipe(
        map((value: { cartId: string }) => value?.cartId),
        filter((cartId: string) => Boolean(cartId)),
        tap((cartId: string) => {
          this.commonDataService.updateCartDetailsCartId(cartId);
        })
      );

    const trackCustomerChangeObs: Observable<[[Customer, Customer], User, Branch]> = this.commonDataService.customer$
      .pipe(
        pairwise(),
        withLatestFrom(this.configurationService.user$, this.commonDataService.branch$),
        tap(([[oldCustomer, newCustomer], user, branch]) => {
          const appInsightCustomerChange: AppInsightCustomerChange = Object.assign<AppInsightCustomerChange, AppInsightCustomerChange>(new AppInsightCustomerChange(), {
            userId: user.id,
            branchNumber: branch.code,
            cartDetails: JSON.stringify(this.commonDataService.CartDetails)
          });

          if (oldCustomer) {
            appInsightCustomerChange.previousCustomerNumber = oldCustomer.customerNumber;
            appInsightCustomerChange.previousCustomerName = oldCustomer.customerName;
          }
          if (newCustomer) {
            appInsightCustomerChange.currentCustomerNumber = newCustomer.customerNumber;
            appInsightCustomerChange.currentCustomerName = newCustomer.customerName;
          }
          this.loggerService.trackMetric('CustomerChange', appInsightCustomerChange);
        })
      );

    const trackBranchChangeObs: Observable<[[Branch, Branch], User, Customer]> = this.commonDataService.branch$
      .pipe(
        pairwise(),
        withLatestFrom(this.configurationService.user$, this.commonDataService.customer$),
        tap(([[oldBranch, newBranch], user, customer]) => {
          const appInsightBilling = Object.assign<AppInsightBilling, AppInsightBilling>(new AppInsightBilling(), {
            userId: user.id,
            customerNumber: customer.customerNumber,
            customerName: customer.customerName,
            cartDetails: JSON.stringify(this.commonDataService.CartDetails)
          });

          if (oldBranch) {
            appInsightBilling.previousBranch = oldBranch.code;
          }
          if (newBranch) {
            appInsightBilling.currentBranch = newBranch.code;
          }
          this.loggerService.trackMetric('BranchChange', appInsightBilling);
        })
      );

    // Make sure all branches is called at least once to initialize branchService.branches array
    const allBranchesObs: Observable<Branch[]> =  this.branchService.branches$;
    // validateTokenObs is included in userObs
    this.subscription = merge(
      renewTokenObs,
      userObs,
      cartIdObs,
      trackCustomerChangeObs,
      trackBranchChangeObs,
      allBranchesObs,
      routeChangeObs,
    ).subscribe();

    window.fixedTopHeightOffset = this.fixedTopValue$.asObservable();
  }

  appClicked() {
    if (this.toastService.shown) {
      this.toastService.hideToast();
    }
  }

  ngOnDestroy() {
    this.changeDetectorRef.detach();
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
}
