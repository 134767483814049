import { Component, OnInit, OnDestroy, Input, Inject, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Part } from 'entities/part';
import { CommonDataService } from 'services/common-data.service';
import { AuthorizationService } from 'services/authorization.service';
import { Permission } from 'entities/enums';
import { filter, map, tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { IMAGE_BASE_URL, DEFAULT_IMAGE_URL } from 'app/app.constants';
import { getImageHelper } from 'helpers/get-image';



@Component({
  selector: 'part-card-item',
  templateUrl: './part-card-item.component.html'
})
export class PartCardItemComponent implements OnInit, OnDestroy {
  @Input() idSuffix: string = "";
  @Input() part: Part;
  @Output() partChange = new EventEmitter<Part>();
  @Input() allowPriceAdjust: boolean;

  canReturnCore$: Observable<boolean> = this.authorizationService.hasPermissionAsync(Permission.ReadCoreReturnOption)
    .pipe(
      map((hasPermission) => hasPermission && true)
    );
  branch$ = this.commonDataService.branch$;

  private subscription: Subscription;

  constructor(
    private commonDataService: CommonDataService,
    private authorizationService: AuthorizationService,
    private router: Router,
    @Inject(IMAGE_BASE_URL) public imageBaseUrl: string,
    @Inject(DEFAULT_IMAGE_URL) public defaultImage: string
    ) {
  }

  ngOnInit() {
    if(this.part.corePrice && !this.part.coreOption) {
      this.part.coreOption = 'NOCORER';
    }
    this.subscription = this.canReturnCore$.subscribe();
  }

  onExtendPart(extendedPartNumber: string) {
    this.router.navigate(['parts'], { queryParams: { searchTerm: extendedPartNumber } });
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
  onPartChange(part: Part) {
    this.partChange.emit(part);
  }

  getImage(image: string, imageBaseUrl: string, imageSize: string) {
    return getImageHelper(image, imageBaseUrl, imageSize )
  }

}
