import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { UnknownPartBuyoutModalComponent } from 'modals/unknown-part-buyout-modal/unknown-part-buyout-modal.component';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'store/app-state';
import * as PartsBuyoutActions from "./parts-buyout.actions";
import * as CartSelectors from 'store/cart/cart.selectors';
import * as CartActions from 'store/cart/cart.actions';
import * as ConfigurationSelectors from 'store/configuration/configuration.selectors';
import { LoggerService } from 'services/logger.service';
import { AppInsightPartBuyout } from 'entities/app-insight-part-buyout';
import { CartResult } from 'entities/cart-result';
import { ToastService } from 'services/toast.service';
import { ToastType } from 'entities/toast-type';
import { CartService } from 'services/cart.service';
import { EMPTY, of } from 'rxjs';
import { Router } from '@angular/router';
import * as CustomerSelectors from 'store/customer/customer.selectors';
import * as BranchSelectors from 'store/branch/branch.selectors';
import { CreateCart } from 'entities/cart-search';
import { branch } from 'app/_specs/helpers/store-data';
import { blankId } from 'helpers/blank-id';
@Injectable()
export class PartsBuyoutEffects {

  openModal$ = createEffect(() =>
    this.action$.pipe(
      ofType(PartsBuyoutActions.opetPartsBuyoutModal),
      withLatestFrom(
        this.store.select(CartSelectors.selectCart),
        this.store.select(ConfigurationSelectors.selectUserInfo),
        this.store.select(BranchSelectors.selectedBranch),
        this.store.select(CustomerSelectors.selectedCustomer)
      ),
      switchMap(
        ([_, cart, { id }, branch, customer]) => {
          if (!cart?.cartId) {
            const createCartRequest: CreateCart = {
              branchCode: branch.code,
              customerNumber: customer.customerNumber,
              forceCreate: false
            }
            return this.cartService.getOrCreateCart(createCartRequest).pipe(
              map(cartResult => {
                this.openPartsBuyoutModal(cartResult.cartId, id, cartResult.customerName, cartResult.customerNumber, cartResult.branchCode);
                return CartActions.selectCartSuccess({ cart: cartResult });
              })
            )
          } else {
            this.openPartsBuyoutModal(cart.cartId, id, cart.customerName, cart.customerNumber, cart.branchCode);
            return EMPTY;
          }
        }
      )
    )
  );

  openPartsBuyoutModal(cartId: string, userId: string, customerName: string, customerNumber: string, branchCode: string) {
    const modalRef = this.modalService.open(UnknownPartBuyoutModalComponent);
    modalRef.componentInstance.cartId = cartId;
    modalRef.componentInstance.userId = userId;
    modalRef.componentInstance.customerName = customerName;
    modalRef.componentInstance.customerNumber = customerNumber;
    modalRef.componentInstance.branchCode = branchCode;
  }

  addItemToCartFromPartsBuyout$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.addItemToCartFromPartsBuyout),
      withLatestFrom(
        this.store.select(ConfigurationSelectors.selectUserInfo),
        this.store.select(CustomerSelectors.selectedCustomer),
        this.store.select(BranchSelectors.selectedBranch),
        this.store.select(CartSelectors.selectCart)
      ),
      switchMap(
        ([{ addNonInventoryPartBuyout, modal, partNumber, successMessage, price, vendor, buyoutAmount, quantity }, { id }, {customerNumber}, {code}, selectedCart]) =>
            {
              if (selectedCart.cartId !== blankId) {
                return this.cartService
                  .addNonInventoryPartBuyoutToCart(
                    selectedCart.cartId,
                    addNonInventoryPartBuyout
                  )
                  .pipe(
                    map((cart) => {
                      this.toastService.showToast(
                        successMessage,
                        ToastType.Success
                      );
                      this.logAddItemToCartFromPartsBuyoutToAppInsight(
                        id,
                        cart,
                        partNumber,
                        quantity,
                        price,
                        vendor,
                        buyoutAmount
                      );
                      modal.close();
                      this.router.navigate(["/cart"]);
                      return CartActions.addItemToCartFromPartsBuyoutSuccess({
                        cart,
                      });
                    }),
                    catchError((error) =>
                      of(
                        CartActions.addItemToCartFromPartsBuyoutFailed({
                          error,
                        })
                      )
                    )
                  );
              } else {
                return this.cartService
                  .getOrCreateCart({
                    branchCode: code,
                    customerNumber: customerNumber,
                    forceCreate: false,
                  })
                  .pipe(
                    switchMap((cartResult) =>
                      this.cartService
                        .addNonInventoryPartBuyoutToCart(
                          cartResult.cartId,
                          addNonInventoryPartBuyout
                        )
                        .pipe(
                          map((cart) => {
                            this.toastService.showToast(
                              successMessage,
                              ToastType.Success
                            );
                            this.logAddItemToCartFromPartsBuyoutToAppInsight(
                              id,
                              cart,
                              partNumber,
                              quantity,
                              price,
                              vendor,
                              buyoutAmount
                            );
                            modal.close();
                            this.router.navigate(["/cart"]);
                            return CartActions.addItemToCartFromPartsBuyoutSuccess(
                              { cart }
                            );
                          }),
                          catchError((error) =>
                            of(
                              CartActions.addItemToCartFromPartsBuyoutFailed({
                                error,
                              })
                            )
                          )
                        )
                    )
                  );
              }
            }
      )
    )
  );

  constructor(
    private action$: Actions,
    private modalService: NgbModal,
    private store: Store<AppState>,
    private loggerService: LoggerService,
    private toastService: ToastService,
    private cartService: CartService,
    private router: Router
  ) { }

  logAddItemToCartFromPartsBuyoutToAppInsight(userId: string, cart: CartResult, partNumber: string, quantity, price, vendor, buyoutAmount) {
    let sourceName = 'PartBuyoutModalComponent_addToCart__UnknownPartBuyout';
    let metricName = this.loggerService.getMetricValue(sourceName);
    let appInsightPartBuyout: AppInsightPartBuyout = {
      userId: userId,
      customerNumber: cart.customerNumber,
      customerName: cart.customerName,
      branchNumber: cart.branchCode,
      cartDetails: JSON.stringify(cart),
      partNumber: partNumber,
      vendor: JSON.stringify(vendor).replace(/'/g, '"'),
      defaultVendorPartPurchasePrice: 1,
      defaultCustomerPartPurchasePrice: 1.5,
      vendorPartPurchasePrice: buyoutAmount,
      customerPartPurchasePrice: price,
      quantity: quantity,
      plMetricName: sourceName
    };
    this.loggerService.trackMetric(metricName, appInsightPartBuyout);
  }

}
