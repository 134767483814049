import { FormControl } from "@angular/forms";

export const CheckoutShipToFormConfig = {
  customerName: { maxLength: 35 },
  streetAddress: { maxLength: 60 }
}

export type CheckoutShipToFormGroup = {
  customerName: FormControl<string>;
  streetAddress: FormControl<string>;
  city: FormControl<string>;
  state: FormControl<string>;
  postalCode: FormControl<string>;
  phoneNumber: FormControl<string>;
  mscAccountNumber: FormControl<string>;
};

export type CheckoutShipToForm = {
  customerName: string;
  streetAddress: string;
  city: string;
  state: string;
  postalCode: string;
  phoneNumber: string;
  mscAccountNumber: string;
};
