<div *ngIf="displayProactiveChat$ | async" class="card mx-4"
  [ngClass]="(displayProactiveChatAnimation$ | async) ? 'proactivechat-card' : 'proactivechat-card-dismissed'">
  <button type="button" id="proactivechat_button_dismiss" (click)="chatNow(false)" aria-label="Close">
   X
  </button>
  <div class="card-header proactivechat-card-header"></div>
  <div class="card-body">
    <div class="proactivechat-user"></div>
    <h5 class="card-title">Need help?</h5>
    <p class="card-text mt-3">Chat with an agent to search for parts or confirm availability.
    </p>
  </div>
  <div class="card-footer">
    <div class="row">
      <div class="col">
        <button id="proactivechat_button_chatnow" class="btn btn-primary w-100" type="button" (click)="chatNow(true)">Chat
          Now</button>
      </div>
      <div class="col">
        <button id="proactivechat_button_nothanks" class="btn btn-secondary w-100" type="button" (click)="chatNow(false)">No Thanks</button>
      </div>
    </div>
  </div>
</div>