import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AppState } from "store/app-state";
import { BasketState } from "./basket.state";

export const selectBasket = createFeatureSelector< BasketState>('basket');

export const selectBasketLoading = createSelector(
  selectBasket,
  (state) => state.loading
);

export const selectBasketList = createSelector(
  selectBasket,
  (state) => state.basketList
);

export const selectBasketPartsQuantityAvailable = createSelector(
  selectBasket,
  (state) => {
    return state.basketList.reduce((prev, curr) => {
        prev[curr.binNumber] = curr.lineItems.map(x => ({quantityAvailable: x.quantity, partNumber: x.partNumber}))
          .reduce((prev2, curr2) => {
            prev2[curr2.partNumber] = curr2.quantityAvailable;
            return {...prev2};
          }, {} as {[key: string]: Number;});
        return {...prev};
      }, {}) as {[key: string]: {quantityAvailable: Number; partNumber: string;}};
  }
);

export const selectBasketCount = createSelector(
  selectBasket,
  (state) =>  state.basketList.length
);

export const basketsLoaded = createSelector(
  selectBasket,
  (state) => state.basketLoaded
);

export const selectRefreshLists = createSelector(
  selectBasket,
  (state) =>  state.refreshList
);
