import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { CurrencyCode } from 'entities/currency-code';
import { UsStatePostal } from 'entities/enums';
import { OrderDetails } from 'entities/order-details';
import { OrderDetailsLineItem, OrderLineItemDeliveryStatus, OrderLineItemDeliveryStatusOption } from 'entities/order-details-line-item';
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ConfigurationService } from 'services/configuration.service';
import { AppState } from 'store/app-state';
import * as BranchSelectors from "store/branch/branch.selectors";
import * as ConfigurationSelectors from 'store/configuration/configuration.selectors';
import * as FeatureFlagSelectors from 'store/feature-flags/feature-flags.selectors';
import { calculateShippingStatus, getShippingStatusLabel } from './order-details-table-utils';

@Component({
  selector: 'order-details-table',
  templateUrl: './order-details-table.component.html',
  styleUrls: ['./order-details-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDetailsTableComponent {

  isInternal$: Observable<boolean> = this.store.select(ConfigurationSelectors.isInternal).pipe(
    tap((isInternal) => {
      this.DeliveryMethodsLookup = {
      notApplicable: 'N/A',
      willCall: 'Will Call',
      adHoc: 'Basket/Returns/Showroom',
      selfPick: 'Front Counter Self Pick',
      fcKnown: 'Front Counter Warehouse Pick',
      rtcDelivery: 'Rush Truck Centers Delivery',
      freight: 'Freight Delivery',
      shipDirect: 'Ship Direct to Customer'
      };
      if (!isInternal) {
        this.DeliveryMethodsLookup.willCall = 'Pick Up';
        this.DeliveryMethodsLookup.adHoc = 'Pick Up';
        this.DeliveryMethodsLookup.selfPick = 'Pick Up';
        this.DeliveryMethodsLookup.fcKnown = 'Pick Up';
      }
    }
  ));

  private orderDetailsSubject: Subject<OrderDetails> = new ReplaySubject(1);
  @Input() set orderDetails(value: OrderDetails) {
    this.orderDetailsSubject.next(value);
  }

  public DeliveryMethodsLookup;

  public orderHistoryDeliveryEnabled$: Observable<boolean> = this.store.select(FeatureFlagSelectors.isFeatureActive('OrderHistoryDelivery'));

  public shippingManagementEnabled$: Observable<boolean> = this.isFeatureFlagForCustomerOrBranch(this.orderDetailsSubject).pipe(map(x => x.isShippingManagementEnabled));

  currencyCode$: Observable<CurrencyCode> = this.store.select(BranchSelectors.selectCurrencyCode);

  orderDetails$: Observable<OrderDetails> = combineLatest([
    this.orderDetailsSubject.asObservable(), 
    this.shippingManagementEnabled$
  ]).pipe(
    map(([details, shippingManagementEnabled]) => ({
        ...details,
        lineItems: details.lineItems.map((item: OrderDetailsLineItem) => {
          const status = calculateShippingStatus(item, details, shippingManagementEnabled);
          return {
            ...item,
            calculatedDeliveryStatus: status,
            deliveryStatusLabel: getShippingStatusLabel(status)
          }
        }),
        shippingManagementEnabled: shippingManagementEnabled
      })
    )
  );

  private deliveryStatusFilterSubject: Subject<OrderLineItemDeliveryStatusOption[]> = new BehaviorSubject([]);
  lineItems$ = combineLatest([this.orderDetails$, this.deliveryStatusFilterSubject]).pipe(
      map(([orderDetails, deliveryStatusFilter]: [OrderDetails, OrderLineItemDeliveryStatusOption[]]) => orderDetails.lineItems
          .map((lineItem: OrderDetailsLineItem, index: number) => ({
            ...lineItem,
            hasFreightTrackingNumber: orderDetails.freightDetails?.some(d => d.lineItemNumber === (parseInt(lineItem.lineItemNumber)/10) && d.trackingId),
            freightDeliveryStatus: orderDetails.freightDetails?.find(f => f.lineItemNumber === (parseInt(lineItem.lineItemNumber)/10))?.trackingText,
            invoiceNumbers: orderDetails.invoiceNumbers
          }))
          .filter((lineItem: OrderDetailsLineItem) =>
            !deliveryStatusFilter.length || deliveryStatusFilter.some((filterOption) => 
              this.deliveryStatusOptionMatch(filterOption, lineItem.calculatedDeliveryStatus)
        )))
    );

  unfilteredLineItemsCount$: Observable<number> = this.orderDetailsSubject.pipe(map((orderDetails) => orderDetails.lineItems.length));

  totalResultsShown$: Observable<{lineItemCount: number, unfilteredLineItemCount: number}> = combineLatest([this.lineItems$, this.unfilteredLineItemsCount$])
    .pipe(
      map(([lineItems, unfilteredLineItemCount]) => {
        return { lineItemCount: lineItems.length, unfilteredLineItemCount }
      }
      )
    );

  hasSomeFreight(orderDetails) {
    return orderDetails.lineItems.filter((lineItem) => lineItem.partNumber !== 'FREIGHT' && lineItem.partNumber !== 'DELIVERY:90' )
    .some((lineItem) => lineItem.deliveryOption === 'freight' );
  }

  deliveryStatusFilterApplied$: Observable<boolean> = this.deliveryStatusFilterSubject
    .pipe(
      map((deliveryStatusFilter) => Boolean(deliveryStatusFilter.length))
    );

  deliveryStatusOptions$: Observable<OrderLineItemDeliveryStatus[]> = this.orderDetails$.pipe(
    map((orderDetails) => orderDetails.lineItems
          .filter((lineItem) => lineItem.partNumber !== 'FREIGHT' && lineItem.partNumber !== 'DELIVERY:90' )
          .map((lineItem) => lineItem.calculatedDeliveryStatus)
    )
  );

  subtotal$: Observable<number> = this.orderDetailsSubject
    .pipe(
      map((orderDetails) => orderDetails.lineItems.reduce((cost, i) => cost + ( i.partNumber !== 'FREIGHT' &&
      i.partNumber !== 'DELIVERY:90' ? i.unitPrice * i.quantity : 0), 0) + this.partCouponTotal(orderDetails))
    );
  totalSavings$: Observable<number> = this.orderDetailsSubject
    .pipe(
      map((orderDetails) => Math.abs(orderDetails.cartCouponTotal + this.partCouponTotal(orderDetails)))
    );

   freightLinesPrice$ = this.lineItems$
   .pipe(
       map( (lines) => lines.filter( i => i.partNumber === 'FREIGHT')[0]?.unitPrice ),
       map( (price) => price || 0)
    );

   freight$: Observable<number> = this.orderDetailsSubject
   .pipe(
      map((orderDetails) => orderDetails?.orderHeader?.freightAmt ?? 0 )
   );

   deliveryLinesPrice$ = this.lineItems$
   .pipe(
      map( (lines) => lines.filter( i => i.partNumber === 'DELIVERY:90')[0]?.unitPrice ),
      map( (price) => price || 0)
    );

   delivery$: Observable<number> = this.orderDetailsSubject
   .pipe(
      map((orderDetails) => orderDetails?.orderHeader?.deliveryAmt ?? 0 )
   );

   deliveryFee$: Observable<number> = this.orderDetailsSubject
   .pipe(
    map((orderDetails) => orderDetails.lineItems.filter(f => f.deliveryFee).reduce((acc, val) => acc + val?.deliveryFee, 0))
   );

   deliveryFeeCredit$: Observable<number> = this.orderDetailsSubject
   .pipe(
    map((orderDetails) => orderDetails.lineItems.filter(f => f.deliveryFeeCredit).reduce((acc, val) => acc + val?.deliveryFeeCredit, 0))
   );

   bagFee$: Observable<number> = this.orderDetailsSubject.pipe(
     map(
       (orderDetails) => orderDetails.lineItems.find((f) => f.bagFee > 0)?.bagFee ?? 0
     )
   );

   isCODeliveryAndBagFee$: Observable<boolean> = this.store.select(FeatureFlagSelectors.isFeatureActive('CODeliveryAndBagFee'));

  constructor(
    private store: Store<AppState>,
    private configurationService: ConfigurationService,
  ) { }

  vm$ = combineLatest([
    this.orderHistoryDeliveryEnabled$,
    this.shippingManagementEnabled$,
    this.currencyCode$,
    this.deliveryStatusFilterApplied$,
    this.deliveryStatusOptions$,
    this.orderDetails$,
    this.lineItems$,
    this.subtotal$,
    this.totalResultsShown$,
    this.totalSavings$,
    this.isInternal$,
    this.configurationService.user$,
    this.freightLinesPrice$,
    this.freight$,
    this.deliveryLinesPrice$,
    this.delivery$,
    this.isCODeliveryAndBagFee$,
    this.deliveryFee$,
    this.deliveryFeeCredit$,
    this.bagFee$
  ])
    .pipe(
      map(([orderHistoryDeliveryEnabled, shippingManagementEnabled, currencyCode, deliveryStatusFilterApplied, deliveryStatusOptions, orderDetails, lineItems, subtotal
        , filterResultTotals, totalSavings, isInternal, user, freightLinesPrice, freight, deliveryLinesPrice, delivery, isCODeliveryAndBagFee, deliveryFee, deliveryFeeCredit, bagFee]) =>
      ({
        orderHistoryDeliveryEnabled, shippingManagementEnabled, currencyCode, deliveryStatusFilterApplied, deliveryStatusOptions, orderDetails, lineItems, subtotal
        , filterResultTotals, totalSavings, isInternal, user, freightLinesPrice, freight, deliveryLinesPrice, delivery, isCODeliveryAndBagFee, deliveryFee, deliveryFeeCredit, bagFee
      }))
    );

  total$ = this.vm$.pipe(
    map((m) => {
      const isColoradoRegion = this.isColoradoRegion(
        m.orderDetails?.orderHistory?.region
      );
      let deliveryFee = 0;
      let deliveryFeeCredit = 0;
      let bagFee = 0;
      if (isColoradoRegion) {
        deliveryFee = m.deliveryFee;
        deliveryFeeCredit = m.deliveryFeeCredit;
        bagFee = m.bagFee;
      }
      return (
        m.subtotal +
        m.freight +
        m.freightLinesPrice +
        m.delivery +
        m.deliveryLinesPrice +
        m.orderDetails.orderHistory.tax +
        deliveryFee +
        deliveryFeeCredit +
        bagFee
      );
    })
  );

  partCouponTotal(orderDetails: OrderDetails) {
    return orderDetails.lineItems
      .flatMap(i => i.sapCoupons)
      .reduce((itemTotal, c) => itemTotal + c.amount, 0);
  }

  deliveryStatusFilterChange(filters: OrderLineItemDeliveryStatusOption[]) {
    this.deliveryStatusFilterSubject.next(filters);
  }

  private deliveryStatusOptionMatch(option: OrderLineItemDeliveryStatusOption, status: OrderLineItemDeliveryStatus): boolean {
    return option === status;
  }

  isFreightShipped(item: OrderDetailsLineItem): boolean {
    return item.deliveryOption === 'freight' && item.hasFreightTrackingNumber === true;
  }

  isFeatureFlagForCustomerOrBranch( orderDetails$: Observable<OrderDetails> ) {
    return  orderDetails$
      .pipe(
            switchMap((orderDetails) =>
            // eslint-disable-next-line max-len
              this.store.select(FeatureFlagSelectors.isFeatureActiveForCustomerOrBranch('ShippingManagement', orderDetails.orderHistory.customerNumber, orderDetails.orderHistory.branch))
              .pipe( map( (isShippingManagementEnabled) => ({orderDetails, isShippingManagementEnabled}) ))
            )
          );
  }

  isColoradoRegion(region?: string) {
    return UsStatePostal.CO === region;
  }

}
