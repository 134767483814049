import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { NoteMessage, NotesConnectionEventId } from "app/_components/notes/notes.component.state";
import { BackCounterNote } from "entities/notes";
import { Observable, Subject, filter, merge, map, of, tap } from "rxjs";
import { BackCounterDashboardService } from "services/back-counter-dashboard.service";
import { RequestConnectionEvent } from "store/back-counter/back-counter.entities";

@Injectable({
  providedIn: "root"
})
export class BackCounterNotesService {

  private http: HttpClient = inject(HttpClient);
  private messages: Subject<NoteMessage> = new Subject();
  public messages$: Observable<NoteMessage> = merge(
    this.messages.asObservable(),
    this.backCounterDashboardService.refreshEventSubject.pipe(
         filter( x =>x.type === RequestConnectionEvent.newNoteOnItem || x.type === RequestConnectionEvent.newNoteOnRequest ),
         map( (x) => {
          const message: NoteMessage = {eventId: "-1", orderNumber: x.requestId, noteId:x.noteId };
                  if( x.type === RequestConnectionEvent.newNoteOnItem ){
                    message.eventId = NotesConnectionEventId.newNoteItem;
                   return message;
                  }else if( x.type === RequestConnectionEvent.newNoteOnRequest){
                    message.eventId = NotesConnectionEventId.newNoteRequest;
                    return message;
                  }
         }))
  );

  constructor(
    private readonly backCounterDashboardService: BackCounterDashboardService,
  ){ }

  public getNotes(orderNumber: string, posId: string, type: string): Observable<BackCounterNote[]> {
    const url = `api/backcounter/request/${orderNumber}/${type}/${posId}/note`;

    return this.http.get<BackCounterNote[]>(url);
  }

  public createNote(orderNumber: string, note: BackCounterNote, posId: string, type: string, releaseLock: boolean): Observable<boolean> {
    const url = `api/backcounter/request/${orderNumber}/${type}/${posId}/note?releaseLock=${releaseLock}`;
    return this.http.post<boolean>(url, JSON.stringify( note.notetext ), {
      headers: {
          'Content-Type': 'application/json'
      }
  });
  }

}
