import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreatePurchaseOrdersBatchRequest,
  CreatePurchaseOrdersBatchResult
} from 'entities/purchase-order';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpBlobPart, HttpJsonPart, MultipartService } from 'services/multipart.service';

@Injectable()
export class PurchaseOrderService {
  constructor(
    private http: HttpClient,
    private multipartService: MultipartService
  ) { }

  public createPurchaseOrdersBatch(request: CreatePurchaseOrdersBatchRequest): Observable<CreatePurchaseOrdersBatchResult> {
    return this.http.post('api/purchaseOrders/batch', request, { observe: 'response', responseType: 'arraybuffer' })
      .pipe(
        map((response) => this.multipartService.getParts(response)),
        // Expect one json part followed by one (or none) file part
        map(([jsonPart, filePart]: [HttpJsonPart, HttpBlobPart]) => ({
          items: jsonPart.value,
          filename: filePart?.filename,
          blob: filePart?.blob
        })),
        catchError((arrayBuffer: ArrayBuffer) => {
          const errorString = String.fromCharCode(...new Uint8Array(arrayBuffer));
          const errorJson = JSON.parse(errorString);
          return throwError(errorJson);
        })
      );
  }
}
