import { Injectable } from '@angular/core';
import { BaseService } from 'services/base.service';
import { HttpClient } from '@angular/common/http';
import { FindOrdersRequest, FindOrdersResults } from 'entities/find-orders';
import { Observable } from 'rxjs';
import { ErrorInformation } from 'entities/error-information';
import { ResultMessage } from 'entities/result-message';

@Injectable({
  providedIn: "root"
})
export class MyDashboardService extends BaseService {
  private readonly dashboardUrl: string = "api/dashboards/";
  constructor(private http: HttpClient) {
    super();
  }

  GetOpenCartsQuotesOrders(branchCode: string, filterByUser: boolean): Observable<any[]> {
    const url = `${this.dashboardUrl}getopencartsquotesorders?branchCode=${branchCode || ''}&filterByUser=${filterByUser}`;
    return this.http.get<any[]>(url);
  }

  IsOpenCartQuoteOrder(cartId: string): Promise<ResultMessage & ErrorInformation> {
      let url: string = `${this.dashboardUrl}isopencartquoteorder?cartId=${cartId == null? '': cartId}`;
      return this.http.get<ResultMessage & ErrorInformation>(url)
          .toPromise()
          .catch(this.handleError);
  }

  // TODO: should this be moved to quote service???
  updateLockStatusForOthers(cartId: string): Observable<ResultMessage & ErrorInformation> {
      return this.http.get<ResultMessage & ErrorInformation>(`${this.dashboardUrl}quote/unlock?cartId=${cartId == null? '': cartId}`);
  }

  GetOpenItemsCount(branchCode: string): Observable<{ openCartCount: number, openQuoteCount: number }> {
    const url: string = `${this.dashboardUrl}getopenitemscount?branchCode=${branchCode}`;
    return this.http.get<{ openCartCount: number, openQuoteCount: number }>(url);
  }

    GetOrderHistory(FindOrdersRequest: FindOrdersRequest): Observable<FindOrdersResults> {
        let url: string = `${this.dashboardUrl}GetOrderHistory`;
        return this.http.put<FindOrdersResults>(url, FindOrdersRequest);
    }
}
