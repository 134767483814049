import { Injectable, OnDestroy, EventEmitter } from '@angular/core';
import { Observable, Subscription, merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { User } from 'entities/user';
import { CartDetails } from 'entities/cart-details';
import { CartResultLineItem } from 'entities/cart-result';
import { Branch } from "entities/branch";
import { Customer } from "entities/customer";
import { ConfigurationService } from 'services/configuration.service';
import { Store } from '@ngrx/store';
import { AppState } from 'store/app-state';
import * as CartSelectors from 'store/cart/cart.selectors';
import * as BranchSelectors from 'store/branch/branch.selectors';
import * as CustomerSelectors from 'store/customer/customer.selectors';
import { clearVerifiedPrices } from 'store/verified-prices/verified-prices.actions';
import * as CommonDataServiceActions from './common-data.service.actions';

@Injectable({
  providedIn: "root"
})
export class CommonDataService implements OnDestroy {
  /** @deprecated get from store.select(CartSelectors.selectCartBranch) instead */
  public branch$: Observable<Branch> = this.store.select(BranchSelectors.selectedBranch);
  public headerSearchChange: EventEmitter<any> = new EventEmitter();
  /** @deprecated get from store.select(CartSelectors.selectCartCustomer) instead */
  public customer$: Observable<Customer> = this.store.select(CustomerSelectors.selectedCustomer);

  private _defaultCustomer: Customer;
  /** @deprecated get from configurationService.customer$ instead */
  public get DefaultCustomer(): Customer {
    return this._defaultCustomer;
  }

  private _customer: Customer;
  /** @deprecated get from store.select(CartSelectors.selectCartCustomer) instead */
  public get Customer(): Customer {
    return this._customer;
  };
  /** @deprecated dispatch action directly from component or service instead */
  public set Customer(customer: Customer) {
    this.store.dispatch(CommonDataServiceActions.selectCustomer({ customer }));
  }

  /** @deprecated dispatch action directly from component or service instead */
  public setCustomerValue(customer: Customer) {
    this.store.dispatch(CommonDataServiceActions.selectCustomer({ customer }));
  }

  private _branch: Branch;
  /** @deprecated get from store.select(CartSelectors.selectCartBranch) instead */
  public get Branch(): Branch { return this._branch; }

  /** @deprecated dispatch action directly from component or service instead */
  public setBranch(branch: Branch) {
    this.store.dispatch(CommonDataServiceActions.selectBranch({ branch }));
  }

  public get User(): User { return this.isNullorUndefined(localStorage.getItem('User')) ? null : JSON.parse(localStorage.getItem('User')); };
  public set User(value: User) { localStorage.setItem('User', JSON.stringify(value)); }

  private _cartDetails: CartDetails;
  /** @deprecated get from cart header or cart coupons instead, not as CartDetails object */
  public get CartDetails(): CartDetails {
    return this._cartDetails;
  }

  /** @deprecated dispatch action directly from component or service instead */
  public updateCartDetailsCartId(cartId: string) {
    this.store.dispatch(CommonDataServiceActions.selectCartId({ cartId }));
  }

  private _cartItems: CartResultLineItem[] = [];
  /** @deprecated get from store.select(CartSelectors.selectCartItems) instead */
  public get CartItems() {
    return this._cartItems;
  }

  private subscription: Subscription;

  constructor(
    private configurationService: ConfigurationService,
    private store: Store<AppState>
  ) {
    const defaultCustomerObs = this.configurationService.customer$
      .pipe(
        tap((customer) => {
          this._defaultCustomer = customer;
        })
      );

    const customerObs = this.customer$
      .pipe(
        tap((customer) => {
          // TODO: moved verified prices to cart context?
           this.store.dispatch(clearVerifiedPrices());
           this._customer = customer;
        })
      );

      const branchObs = this.branch$
        .pipe(
          tap((branch) => {
            this._branch = branch;
          })
        );


      const cartItemsObs = this.store.select(CartSelectors.selectCartItems)
        .pipe(
          tap((cartItems) => {
            this._cartItems = cartItems;
          })
        );

      this.subscription = merge(defaultCustomerObs, customerObs, branchObs, cartItemsObs).subscribe();
  }

  /** @deprecated dispatch action directly from component or service instead */
  public cleanCheckoutData() {
    this.store.dispatch(CommonDataServiceActions.clearCart());
  }

  isNullorUndefined(obj: any) {
    return obj === undefined || obj == null || obj === 'null';
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
}
