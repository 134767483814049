<h4 class="strong">Order Details:</h4>
<div *ngIf="vm$ | async as vm">
  <table class="table table-custom-light">
    <thead class="table-light">
      <tr *ngIf="!(vm.orderHistoryDeliveryEnabled); else deliveryEnabled">
        <th style="width: 25%;">Part Number</th>
        <th style="width: 45%;">Item Description</th>
        <th style="width: 10%;" class="text-center">Price</th>
        <th style="width: 10%;" class="text-center">Quantity</th>
        <th style="width: 10%;" class="text-center">Total</th>
      </tr>
      <ng-template #deliveryEnabled>
        <tr>
          <th>Part Number</th>
          <th>Item Description</th>
          <th class="text-center">Price</th>
          <th class="text-center">Quantity</th>
          <th class="text-center">Total</th>
          <th class="text-center">Delivery Method</th>
          <th>
            <div class="d-flex align-items-start">
              <div style="margin-top: 0rem; margin-right: 1rem;" class="text-center">Delivery Status</div>
              <div ngbDropdown autoClose="outside" display="dynamic" placement="bottom-right">
                <button class="btn border border-secondary" ngbDropdownToggle>
                  <i class="fa-filter" [ngClass]="{ 'fas': vm.deliveryStatusFilterApplied, 'far': !vm.deliveryStatusFilterApplied }"></i>
                </button>
                <order-line-items-delivery-status-filter ngbDropdownMenu [deliveryStatusOptions]="vm.deliveryStatusOptions" ngModel (ngModelChange)="deliveryStatusFilterChange($event)"></order-line-items-delivery-status-filter>
              </div>
            </div>
          </th>
        </tr>
      </ng-template>
    </thead>
    <tbody *ngIf="vm.orderDetails">
      <ng-container *ngFor="let item of vm.lineItems; index as i">
        <tr id="orderDetailsTable_tr_lineItem_{{i}}" *ngIf="item.partNumber !== 'FREIGHT' && item.partNumber !== 'DELIVERY:90'">
          <td class="text-truncate" [title]="item.partNumber">{{ item.partNumber }}</td>
          <td class="text-truncate order-details-table-item-description" [title]="item.description">{{ item.description }}</td>
          <td class="text-end">{{ item.unitPrice | currency: vm.currencyCode :'symbol':'1.2-2' }}</td>
          <td class="text-center">{{ item.quantity | number:'.0' }}</td>
          <td class="text-end">{{ (item.quantity * item.unitPrice) | currency: vm.currencyCode :'symbol':'1.2-2' }}</td>
          <td class="text-truncate" [title]="item.partNumber">{{ DeliveryMethodsLookup[item.deliveryOption] }}</td>

          <td *ngIf="vm.orderHistoryDeliveryEnabled" >{{ item.deliveryStatusLabel }}</td>
        </tr>
        <tr id="orderDetails_tr_lineItem{{i}}_coupon{{j}}" *ngFor="let coupon of item.sapCoupons">
          <td></td>
          <td>{{ coupon.description }}</td>
          <td colspan="2"></td>
          <td class="text-end">{{ coupon.amount | currency: vm.currencyCode :'symbol':'1.2-2' }}</td>
          <td *ngIf="vm.orderHistoryDeliveryEnabled"></td>
        </tr>
      </ng-container>
    </tbody>
    <tbody class="table-borderless" style="border-top: 1px solid #ccc;" *ngIf="vm.orderDetails">
      <tr>
        <td colspan="2"></td>
        <td colspan="2" class="strong text-end">Subtotal</td>
        <td class="text-end">{{ vm.subtotal | currency: vm.currencyCode :'symbol':'1.2-2' }}</td>
        <td *ngIf="vm.orderHistoryDeliveryEnabled"></td>
      </tr>

      <ng-container *ngIf="vm.freightLinesPrice > 0">
        <tr>
          <td colspan="2"></td>
          <td colspan="2" class="text-right">Freight</td>
          <td class="text-right">{{vm.freightLinesPrice | currency: vm.currencyCode :'symbol':'1.2-2' }}</td>
        </tr>
      </ng-container>

      <ng-container *ngIf="vm.freight > 0">
        <tr>
          <td colspan="2"></td>
          <td colspan="2" class="text-right">Freight</td>
          <td class="text-right">{{vm.freight | currency: vm.currencyCode :'symbol':'1.2-2' }}</td>
        </tr>
      </ng-container>

      <ng-container *ngIf="vm.deliveryLinesPrice > 0">
        <tr>
          <td colspan="2"></td>
          <td colspan="2" class="text-right">Delivery</td>
          <td class="text-right">{{vm.deliveryLinesPrice | currency: vm.currencyCode :'symbol':'1.2-2' }}</td>
        </tr>
      </ng-container>

      <ng-container *ngIf="vm.delivery > 0">
        <tr>
          <td colspan="2"></td>
          <td colspan="2" class="text-right">Delivery</td>
          <td class="text-right">{{vm.delivery | currency: vm.currencyCode :'symbol':'1.2-2' }}</td>
        </tr>
      </ng-container>

      <ng-container *ngIf="vm.deliveryFee !== 0">
        <tr>
          <td colspan="2"></td>
          <td colspan="2" class="text-right">Retail Delivery Fee</td>
          <td class="text-right">{{ vm.deliveryFee | currency: vm.currencyCode :'symbol':'1.2-2' }}</td>
        </tr>
        <tr *ngIf="vm.deliveryFeeCredit !== 0">
          <td colspan="2"></td>
          <td colspan="2" class="text-right">Delivery Fee Credit</td>
          <td class="text-right">{{ vm.deliveryFeeCredit | currency: vm.currencyCode :'symbol':'1.2-2' }}</td>
        </tr>
      </ng-container>
      <ng-container *ngIf="vm.bagFee !== 0 && vm.isCODeliveryAndBagFee">
        <tr>
          <td colspan="2"></td>
          <td colspan="2" class="text-right">Bag Fee</td>
          <td class="text-right">{{ vm.bagFee | currency: vm.currencyCode :'symbol':'1.2-2' }}</td>
        </tr>
      </ng-container>

      <ng-container *ngIf="vm.orderDetails.cartCouponTotal">
        <tr class="table-borderless">
          <td colspan="2"></td>
          <td colspan="2" class="strong text-end">Cart Coupons</td>
          <td class="text-end">{{ vm.orderDetails.cartCouponTotal | currency: vm.currencyCode :'symbol':'1.2-2' }}</td>
          <td *ngIf="vm.orderHistoryDeliveryEnabled"></td>
        </tr>
      </ng-container>
      <tr>
        <td colspan="2"></td>
        <td colspan="2" class="text-end">Estimated Sales Tax</td>
        <td class="text-end">{{ vm.orderDetails.orderHistory.tax | currency: vm.currencyCode :'symbol':'1.2-2' }}</td>
        <td *ngIf="vm.orderHistoryDeliveryEnabled"></td>
      </tr>
      <tr>
        <td colspan="2"></td>
        <td colspan="2" class="strong text-right">Total*</td>
        <td class="text-right" *ngIf="total$ | async as total">{{ total | currency: vm.currencyCode :'symbol':'1.2-2' }}</td>
        <td class="text-right" *ngIf="vm.orderHistoryDeliveryEnabled">
          <ng-container *ngIf="vm.deliveryStatusFilterApplied">
            <ng-container *ngLet="vm.filterResultTotals">
              (showing {{vm.filterResultTotals.lineItemCount}} of {{vm.filterResultTotals.unfilteredLineItemCount}} results)
            </ng-container>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
  <br/>
  <div class="text-end" *ngIf="vm.totalSavings">
    <div class="fw-bold">Your Total Savings</div>
    <div>{{ vm.totalSavings | currency: vm.currencyCode :'symbol':'1.2-2' }}</div>
  </div>
</div>
