import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Part } from 'entities/part';
import { Cart } from 'entities/cart-search';
import { SourceLocationType } from 'services/logger.service';

@Component({
  selector: 'part-card',
  templateUrl: './part-card.component.html'
})
export class PartCardComponent {
  private _index: number = -1;
  @Input() set index(value: number) {
    this._index = value;
  }
  @Input() part: Part;
  @Input() partType: SourceLocationType;
  @Input("allowPriceAdjust") set allowPriceAdjustAttribute(value: any) {
    this.allowPriceAdjust = value === "" || value;
  }
  @Input() sourcePart: Part = null;
  @Output() addedToCart: EventEmitter<Part> = new EventEmitter();
  @Output() partChange = new EventEmitter<Part>();

  get idSuffix(): string {
    return this._index > -1 ? `_${this._index}` : "";
  }

  addToCartParameters: Cart = new Cart();
  allowPriceAdjust: boolean = false;

  constructor() { }
  changeFavorite(part: Part): void {
    this.partChange.emit(part);
  }

}
