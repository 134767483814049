import { Component, Input } from "@angular/core";

@Component({
  selector: 'spinner',
  template: `
    <div [class.full]="full">
      <div class="align-center">
        <div class="spinner-border" role="status"></div>
      </div>
    </div>
  `,
  styles: [
    '.align-center { text-align: center; margin: 20px 0; }',
    '.align-center div { display: inline-block }',
    '.full { position: absolute; z-index: 10; background-color: rgba(255,255, 255, 0.5); width: 100%; height: 100%; left: 0; top: 0}',
    '.full .align-center {width: 30px; height: 30px; position: absolute; z-index: 1; margin: auto; left: 0; right: 0; top: 0; bottom: 0;}'
  ],
})
export class SpinnerComponent {

  @Input()
  full: boolean = false;
}
