import { createAction, props } from '@ngrx/store';
import { CartResultLineClient, CartResultLineItemSelection } from 'entities/cart-result';
import { AddStoCartItemRequest } from 'entities/carts/add-sto-cart-item-request';
import { HotFlagResult } from 'entities/hot-flag/hot-flag-result';


export const getCartDataSuccess = createAction(
  '[MyCart] Get Cart Data Success',
  props<{ cartItems: CartResultLineClient[]; }>()
);

export const selectAllItems = createAction(
  '[MyCart] Select All Items'
);
export const unSelectAllItems = createAction(
  '[MyCart] Unselect All Items'
);
export const showPriceVerifyModal = createAction(
  '[MyCart] Show Price Verify Modal'
);
export const toggleSelection = createAction(
  '[MyCart] Toggle Selection',
  props<{cartItemId: string;}>()
);

export const getInventory = createAction(
  '[MyCart] Get Inventory'
);

export const getInventorySuccess = createAction(
  '[MyCart] Get Inventory Success',
  props<{cartItemsWithInventory: CartResultLineItemSelection[]}>()
);

export const getInventoryFailed = createAction(
  '[MyCart] Get Inventory Failed',
  props<{error: any}>()
);

export const getVendorPrices = createAction(
  '[MyCart] Get Vendor Prices',
  props<{vendorCode: string}>()
);
export const getVendorPricesSuccess = createAction(
  '[MyCart] Get Vendor Prices Success',
  props<{ fixedVendor: boolean, cartItemsWithVendorPrices: CartResultLineItemSelection[] }>()
);
export const getVendorPricesFailed = createAction(
  '[MyCart] Get Vendor Prices Failed',
  props<{error: any}>()
);

export const searchVendor = createAction(
  '[MyCart] Search Vendor',
  props<{vendorCode: string}>()
);
export const searchVendorSuccess = createAction(
  '[MyCart] Search Vendor Success',
  props<{ partNumber: string; vendorCode: string; vendorName: string; }>()
);
export const searchVendorFailed = createAction(
  '[MyCart] Search Vendor Failed',
  props<{error: any}>()
);

export const deleteSelectedItems = createAction(
  '[MyCart] Delete Selected Items'
);
export const deleteSelectedItemsSuccess = createAction(
  '[MyCart] Delete Selected Items Success'
);
export const deleteSelectedItemsFailed = createAction(
  '[MyCart] Delete Selected Items Failed'
);

export const closeQuoteItems = createAction(
  '[MyCart] Close Quote Items'
);

export const addStoToCart = createAction(
  '[MyCart] Add Sto To Cart',
  props<{addStoRequest: AddStoCartItemRequest, selectedCartItem: CartResultLineItemSelection}>()
);
export const addStoToCartSuccess = createAction(
  '[MyCart] Add Sto To Cart Success'
);
export const addStoToCartFailed = createAction(
  '[MyCart] Add Sto To Cart Failed'
);

export const getHotFlags = createAction(
  '[MyCart] Get Hot Flags'
);
export const getHotFlagsSuccess = createAction(
  '[MyCart] Get Hot Flags Success',
  props<{hotFlags: HotFlagResult[]}>()
);
export const getHotFlagsFailed = createAction(
  '[MyCart] Get Hot Flags Failed',
  props<{error: any}>()
);

export const sendHotFlag = createAction(
  '[MyCart] Send HotFlag'
);
export const sendHotFlagSuccess = createAction(
  '[MyCart] Send HotFlag Success'
);
export const sendHotFlagFailed = createAction(
  '[MyCart] Send HotFlag Failed',
  props<{error: any}>()
);

export const updateCartItemHotFlag = createAction(
  '[MyCart] Update Cart Item HotFlag',
  props<{cartItemId: string; hotFlagCode: string;}>()
);

export const updateAndSendCartItemHotFlag = createAction(
  '[MyCart] Update And Send Cart Item HotFlag',
  props<{cartItem: CartResultLineClient; hotFlagCode: string; vin: string | null;}>()
);

export const confirmPartsBuyout = createAction(
  '[MyCart] Confirm Parts Buyout'
);
export const confirmPartsBuyoutSuccess = createAction(
  '[MyCart] Confirm Parts Buyout Success'
);
export const confirmPartsBuyoutFailed = createAction(
  '[MyCart] Confirm Parts Buyout Failed',
  props<{error: any}>()
);
export const updateBuyoutCartItem = createAction(
  '[MyCart] Update Buyout CartItem',
  props<{ cartItem: any }>()
);

