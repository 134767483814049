import { Injectable } from '@angular/core';
import { LoggerService, SourceLocationType } from './logger.service';
import { CommonDataService } from './common-data.service';
import { AppInsightPartSearch } from 'entities/app-insight-part-search';

@Injectable({
  providedIn: 'root'
})
export class TrackerService {

  constructor(private loggerService: LoggerService, private commonDataService: CommonDataService) { }

  public trackPartSearch(parts: any, searchTerm: string, source: string) {
    const metricName = 'PartsComponent_getParts_getParts';
    const appInsightPartSearch = Object.assign(new AppInsightPartSearch(), {
      userId: this.commonDataService.User.id,
      searchTerm,
      customerNumber: this.commonDataService.Customer.customerNumber,
      branchNumber: this.commonDataService.Branch.code,
      cartDetails: JSON.stringify(this.commonDataService.CartDetails),
      source,
      plMetricName: metricName
    });
    appInsightPartSearch.results = this.loggerService.getAppInsightParts(parts, JSON.stringify(appInsightPartSearch).length);
    this.loggerService.trackMetric(metricName, appInsightPartSearch);
  }
}
