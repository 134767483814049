import { Directive } from '@angular/core';
import { AbstractControl, ValidationErrors, Validator, NG_VALIDATORS } from '@angular/forms';

export function requiredTrimmed(c: AbstractControl): ValidationErrors|null {
  const valid = c.value && ((typeof c.value === 'string' && c.value.trim()) || (typeof c.value !== 'string'));

  return valid ? null : { requiredTrimmed: true };
}

@Directive({
  selector: '[requiredTrimmed]',
  providers: [{provide: NG_VALIDATORS, useExisting: RequiredTrimmedValidator, multi: true}]
})
export class RequiredTrimmedValidator implements Validator {
  validate(c: AbstractControl) {
    return requiredTrimmed(c);
  }
}
