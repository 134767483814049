import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, filter, take, tap } from "rxjs";
import { AppState } from "store/app-state";
import * as ConfigurationSelectors from "store/configuration/configuration.selectors";

export const internalUserGuard: CanActivateFn = (
  route,
  state
): Observable<boolean> => {
  const store = inject(Store<AppState>);
  const router = inject(Router);

  return store.select(ConfigurationSelectors.isInternal).pipe(
    filter((isInternal) => isInternal !== undefined),
    take(1),
    tap((isInternal) => {
      if (isInternal) {
        return true;
      } else {
        router.navigate(["/"]);
        return false;
      }
    })
  );
};
