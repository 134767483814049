import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, map, withLatestFrom } from 'rxjs';
import { Branch } from "entities/branch";
import { Store } from '@ngrx/store';
import { AppState } from 'store/app-state';
import { selectBranchesForOrdering } from 'store/branch/branch.selectors';

export interface BranchItem extends Branch {
  selected: boolean;
}

@Component({
  selector: 'branch-multiselect',
  templateUrl: './branch-multiselect.html',
  styleUrls: ['./branch-multiselect.scss']
})
export class BranchMultiSelectComponent implements OnInit, OnChanges {

  @Input()
  selectedBranch: Branch;

  @Output()
  onChange: EventEmitter<Branch[]> = new EventEmitter();

  @ViewChild("searchInput")
  searchInput: ElementRef;

  public branches: Branch[] = [];
  public branches$: Observable<Branch[]> = this.store.select(selectBranchesForOrdering);
  public loading = false;

  public search$: BehaviorSubject<string> = new BehaviorSubject(null);
  public remove$: BehaviorSubject<string> = new BehaviorSubject(null);
  public searchResults$: Observable<BranchItem[]> = combineLatest([
    this.search$.asObservable(),
    this.remove$.asObservable(),
  ]).pipe(
    withLatestFrom(this.branches$),
    map(([[text, _], branches]) => (!text || text === "") ? [] : this.filterResultsFn(text, branches))
  );

  constructor(
    private store: Store<AppState>,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if (this.selectedBranch) {
      this.branches.push(this.selectedBranch);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedBranch.currentValue === null) {
      this.reset();
    }
  }

  reset(): void {
    this.branches = [];
    this.remove$.next("");
  }

  removeBranch(e: Event, id: string): void {
    e.stopPropagation();
    this.branches = this.branches.filter(b => b.id !== id);
    this.remove$.next(id);
    this.onChange.emit(this.branches);
  }

  public trackById(item: Branch) {
    return item.id;
  }

  public onInputChange(value: string): void {
    this.search$.next(value);
  }

  public onKeyDown(event, branches: Branch[]): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      const value = event.target.value;
      const result = this.filterResultsFn(value, branches);

      if (result.length === 1) {
        this.toggleBranchSelection(event, result[0], false);
        this.search$.next(value);
        this.onChange.emit(this.branches);
      }
      return;
    }
    if ((event.key === `ArrowUp`) || (event.code === `ArrowDown`)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  public toggleBranchSelection(e: Event, b: BranchItem, emitChange: boolean = true): void {
    e.preventDefault();
    b.selected = !b.selected;

    if (b.selected && !this.branches.find(branch => branch.id === b.id)) {
      this.branches.push(b);
    } else {
      this.branches = this.branches.filter(branch => branch.id !== b.id);
    }

    if (emitChange) {
      this.onChange.emit(this.branches);
    }
  }

  private filterResultsFn(text: string, branches: Branch[]): BranchItem[] {
    return branches.filter((branch: Branch) =>
      !branch.code.startsWith('90') &&
      !branch.displayName.includes('RTL') &&
      (
        branch.code.startsWith(text) ||
        branch.code.endsWith(text) ||
        branch.code === text ||
        branch.code.includes(text) ||
        branch.displayName.toUpperCase().includes(text.toUpperCase())
      )
    ).map(b => ({
      ...b,
      selected: !!this.branches.find(branch => branch.id === b.id)
    }))
  }

  public onMenuToggle(isOpen: boolean): void {
    if (!isOpen) {
      return;
    }
    this.cdRef.detectChanges();
    this.searchInput.nativeElement.focus();
  }

}
