import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: 'multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss']
})
export class MultiSelectComponent implements OnInit {

  @Input()
  selectedItems: any[] = [];

  @Input()
  items: any[] = [];

  @Input()
  label: string = "Find item";

  @Output()
  onChange: EventEmitter<any[]> = new EventEmitter();

  ngOnInit(): void {
    this.selectedItems =  [];
    this.items.forEach(item => {
      if (item.selected && !this.selectedItems.find(i => i.value === item.value)) {
        this.selectedItems.push(item);
      }
    });
  }

  public removeItem(e: Event, value: string): void {
    e.stopPropagation();
    this.selectedItems = this.selectedItems.filter(b => b.value !== value);
    this.items.forEach(i => {
      if (i.value === value) {
        i.selected = false;
      }
    })
    this.onChange.emit(this.selectedItems);
  }

  public trackById(item: any) {
    return item;
  }

  public toggleItemSelection(e: Event, b: any): void {
    e.preventDefault();
    b.selected = !b.selected;

    if (b.selected && !this.selectedItems.find(item => item.value === b.value)) {
      this.selectedItems.push(b);
    } else {
      this.selectedItems = this.selectedItems.filter(item => item.value !== b.value);
    }

    this.onChange.emit(this.selectedItems);
  }

  public onMenuToggle(isOpen: boolean): void {
    if (!isOpen) {
      return;
    }
  }
}
