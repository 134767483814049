import { OrderHistory } from "entities/order-history";
import * as moment from 'moment';

export const i2pResendEnabled = function(
  isInternal: boolean,
  history: OrderHistory,
  isSuccess: boolean
): boolean {

  if (!history.ccInviteToPayDateTime || history.completionDate) {
    return false;
  }

  const currentDate = moment();
  const expired = history?.expirationDate && currentDate.diff(moment(history.expirationDate), "days") >= 0;

  return isInternal && !isSuccess && !expired && (!!history.ccSmsStatus || !!history.ccEmailStatus);
}
