import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[autocompleteOff]'
})
export class AutocompleteOffDirective {
  constructor(private el: ElementRef) {
    if (navigator.userAgent.indexOf('Chrome') > -1) {
      this.el.nativeElement.setAttribute('autocomplete', 'offoff');
    }
  }
}
