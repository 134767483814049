import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AppState } from "store/app-state";
import { FeaturedPartsState } from "./featured-parts.state";

export const selectFeaturedPartsState = createFeatureSelector< FeaturedPartsState>('featuredParts');

export const isLoadingFeaturedParts = createSelector(
  selectFeaturedPartsState,
  state => state.featuredPartsLoading
  );
  
export const selectFeaturedParts = createSelector(
  selectFeaturedPartsState,
  state => state.featuredParts
);

export const featurePartsCheckedForCurrentBranchAndCustomer = createSelector(
  selectFeaturedPartsState,
  state => state.featurePartsCheckedForCurrentBranchAndCustomer
)