<div>
  <div  class="modal-header">
    <div>
      <span id="title">PROCESSING</span>
      <i id="spinner" class="fas fa-spinner-third fa-fw fa-2x fa-spin" ></i>
    </div>
  </div>
  <div class="modal-body">
    <span>Waiting for response from card reader.</span>
  </div>
</div>
