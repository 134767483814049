const debug: string = '__$debug$__';

export const environment = {
  production: true,
  debug: debug === 'true',
  isRunningInUnitTest: false,
  API_URL: '__$PartsLinkApiBaseUrl$__',
  CORE_API_URL: '__$PartsConnectCoreApiBaseUrl$__',
  WEB_URL: '__$PartsLinkBaseUrl$__',
  RUSHCARE_URL: '__$RushCareConnectBaseUrl$__/cc-management',
  VIN_PARTS_SEARCH_URL: '__$RushVinPartsSearchBaseUrl$__',
  APP_ID: '__$AzureAD.B2C.RushCare.AppId$__',
  openIdConnect: {
    b2c: {
      domain: '__$OpenIdConnect.B2C.Domain$__',
      tenant: '__$OpenIdConnect.B2C.Tenant$__',
      policy: '__$OpenIdConnect.B2C.Policy$__',
      passwordResetPolicy: '__$OpenIdConnect.B2C.PasswordResetPolicy$__',
      profileEditPolicy: '__$OpenIdConnect.B2C.ProfileEditPolicy$__'
    },
    clientId: '__$AzureAD.B2C.RushCare.ClientId$__',
    logoutUrlsJsonUrl: '__$OpenIdConnect.LogoutUrlsJsonUrl$__'
  },
  APPDYNAMICS_KEY: '__$AppDynamics.Key$__',
  ApplicationInsights_Key: '__$ApplicationInsightsKey$__',
  NewUserRedirectUrl: '__$NewUserRedirectUrl$__',
  punchout: {
    navistarUrl: '__$Punchout.NavistarRedirectUrl$__'
  },
  notificationTimeIntervalInMin: Number('__$NotificationTimeIntervalInMin$__'),
};
