import { Action, createReducer, on } from "@ngrx/store";
import {
  basketsLoad,
  basketsLoadSuccess,
  changeBasketItemQty,
  selectItemInBasket,
} from "./basket.actions";
import { BasketState } from "./basket.state";

export const initialState: BasketState = {
  loading: false,
  customerNumber: "",
  basketList: [],
  basketLoaded: false,
  refreshList: false
};

export const basketReducer = createReducer<BasketState, Action>(
  initialState,
  on(basketsLoad, (state, { branchCode }) => ({
    ...state,
    loading: true,
    branchCode
  })),
  on(basketsLoadSuccess, (state, { baskets }) => ({
    ...state,
    loading: false,
    basketLoaded: true,
    basketList: baskets,
    refreshList: false
  })),
  on(selectItemInBasket, (state, { item }) => ({
    ...state,
    refreshList: true,
    basketList: state.basketList.map((basket) => {
      var newLineItems = basket.lineItems.map((lineItem) => {
        if (
          lineItem.basketId === item.basketId &&
          lineItem.quantity === item.quantity &&
          lineItem.partNumber === item.partNumber
        ) {
          var newLineItem = { ...lineItem };
          newLineItem.addToCart = !item.addToCart;
          return newLineItem;
        }
        return lineItem;
      });
      var newBasket = { ...basket };
      newBasket.lineItems = newLineItems;
      return newBasket;
    }),
  })),
  on(changeBasketItemQty, (state, { item, newQuantity }) => ({
    ...state,
    refreshList: true,
    basketList: state.basketList.map((basket) => {
      var newLineItems = basket.lineItems.map((lineItem) => {
        if (
          lineItem.basketId === item.basketId &&
          lineItem.quantity === item.quantity &&
          lineItem.partNumber === item.partNumber
        ) {
          var newLineItem = { ...lineItem };
          newLineItem.quantitySelected = newQuantity;
          return newLineItem;
        }
        return lineItem;
      });
      var newBasket = { ...basket };
      newBasket.lineItems = newLineItems;
      return newBasket;
    }),
  }))
);
