import { Pipe, PipeTransform } from '@angular/core';
import { OrderDetailsLineItem } from 'entities/order-details-line-item';

@Pipe({ name: 'deliveryCount' })
export class DeliveryCountPipe implements PipeTransform {
  transform(lineItems: OrderDetailsLineItem[]) {
    if (!lineItems) {
      return '...';
    }

    return lineItems
      .filter((lineItem) => lineItem.deliveryStatus !== 'notApplicable')
      .filter((lineItem) => lineItem.deliveryStatus !== 'notDelivered')
      .length;
  }
}
