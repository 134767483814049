import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import * as CartActions from 'store/cart/cart.actions';
import { FeatureFlagsState } from './feature-flags.state';
import * as FeatureFlagsActions from './feature-flags.actions';

export const initialState: FeatureFlagsState = {
  expirationTime: null,
  flags: {}
};

const _featureFlagReducer = createReducer<FeatureFlagsState, Action>(
  initialState,
  on(
    FeatureFlagsActions.loadFeatureFlags,
    (state, flags: any) => {
      return ({...state, flags: {...flags.featureFlags} });
    }
  )
);

function persistStateReducer(key: string, _reducer: ActionReducer<FeatureFlagsState, Action>) {
  return (state: FeatureFlagsState | undefined, action: Action) => {
    const nextState = _reducer(state, action);
    // Save data to session storage if necessary
    return nextState;
  };
}

export function featureFlagReducer(state: FeatureFlagsState | undefined, action: Action) {
  return persistStateReducer('FeatureFlagsState', _featureFlagReducer)(state, action);
}
