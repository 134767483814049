import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { createAction, props } from '@ngrx/store';
import { Branch } from 'entities/branch';
import { CartResult, CartResultLine, CartResultLineItem, SapCalculateTaxesResult } from 'entities/cart-result';
import { DeliveryTypes } from 'entities/cart-search';
import { AddCartItemRequest } from 'entities/carts/add-cart-item-request';
import { AddNonInventoryBuyoutItemRequest } from 'entities/carts/add-non-inventory-buyout-item-request';
import { AddStoCartItemRequest } from 'entities/carts/add-sto-cart-item-request';
import { Cart } from 'entities/carts/cart';
import { DeliveryLineItem } from 'entities/carts/cart-item-delivery-option';
import { ItemToRemoveFromCart } from 'entities/carts/item-to-remove-from-cart';
import { ShippingRateRequest } from 'entities/carts/shipping-rate-request';
import { ShippingRate } from 'entities/carts/shipping-rates';
import { UpdateFlagCartItem } from 'entities/carts/update-flag-cart-item';
import { CheckoutSmsOpt } from 'entities/checkout-sms-opt';
import { Customer } from 'entities/customer';
import { DeliveryItems } from 'entities/delivery-items';
import { OrderResult } from 'entities/order-result';
import { OrderType } from 'entities/order-type';
import { Part } from 'entities/part';
import { PartBinLocationCollection, PartBinLocationItem } from 'entities/part-bin-location-item';
import { FavoritePartView } from 'entities/parts/favorite-part-view';
import { ShipToCustomer } from 'entities/ship-to-customer';
import { SourceLocationType } from 'services/logger.service';

export const getCartDataSuccess = createAction(
  '[Cart] Get Cart Data Success',
  props<{ cartResult: CartResult }>()
);

export const getCartDataForBranchAndCustomer = createAction(
  '[Cart] Get Cart Data For Customer And Branch'
);

export const getCartDataForBranchAndCustomerFailed = createAction(
  '[Cart] Get Cart Data For Customer And Branch Failed',
  props<{ error: any }>()
);

export const getCartDataFailed = createAction(
  '[Cart] Get Cart Data Failed',
  props<{ error: any }>()
);

export const clearCartData = createAction(
  '[Cart] Clear Cart Data'
);

export const updatePONumber = createAction(
  '[Cart] Update PO Number',
  props<{ poNumber: string }>()
);

export const updatePONumberSuccess = createAction(
  '[Cart] Update PO Number Success',
  props<{ cart: CartResult }>()
);

export const updatePONumberFailed = createAction(
  '[Cart] Update PO Number Failed',
  props<{ error: any }>()
);

export const updateUnitNumber = createAction(
  '[Cart] Update Unit Number',
  props<{ unitNumber: string }>()
);

export const updateUnitNumberSuccess = createAction(
  '[Cart] Update Unit Number Success',
  props<{ cart: CartResult }>()
);

export const updateUnitNumberFailed = createAction(
  '[Cart] Update Unit Number Failed',
  props<{ error: any }>()
);

export const updateShipTo = createAction(
  '[Cart] Update Ship To',
  props<{shipToCustomer: ShipToCustomer; isEdited: boolean; isCartUpdate: boolean}>()
);

export const updateShipToSuccess = createAction(
  '[Cart] Update Ship To Success',
  props<{ cart: any; isCartUpdate: boolean }>()
);

export const getFreightAndAddToCart = createAction(
  '[Cart] Get Freight',
  props<{ cartId: string }>()
);

export const setInboundFreight = createAction(
  '[Cart] Set Inbound Freight',
  props<{inboundFreightLineItem: CartResultLineItem}>()
);

export const getFreightAndAddToCartSuccess = createAction(
  '[Cart] Get Freight Success',
  props<{ cart: CartResult; isInternal: boolean }>()
);

export const getFreightAndAddToCartFailed = createAction(
  '[Cart] Get Freight Failed',
  props<{ error: any }>()
);

export const getShippingRates = createAction(
  '[Cart] Get Shipping Rates',
  props<{ cartId: string }>()
);

export const getShippingRatesSuccess = createAction(
  '[Cart] Get Shipping Rates Success',
  props<{ shippingRates: ShippingRate[] }>()
);

export const getShippingRatesFailed = createAction(
  '[Cart] Get Shipping Rates Failed',
  props<{ error: any }>()
);

export const updateShippingRate = createAction(
  '[Cart] Update Shipping Rate',
  props<{ shippingRateRequest: ShippingRateRequest }>()
);

export const updateShippingRateSuccess = createAction(
  '[Cart] Update Shipping Rate success',
  props<{ shippingRate: ShippingRate }>()
);

export const updateShippingRateFailed = createAction(
  '[Cart] Update Shipping Rate Failed',
  props<{ error: any }>()
);

export const getBinLocations = createAction(
  '[Cart] Get Bin Locations',
);

export const opetBinLocationsModal = createAction(
  '[Bin Locations] Open Bin Locations Modal',
  props<{ partBinLocations: PartBinLocationItem[] }>()
);

export const getPartBinLocationsSuccess = createAction(
  '[Cart] Get Part Bin Locations Success',
  props<{ partBinLocations: PartBinLocationCollection }>()
);

export const getPartBinLocationsFailed = createAction(
  '[Cart] Get Part Bin Locations Failed',
  props<{ error: any }>()
);

export const updateShipToFailed = createAction(
  '[Cart] Update Ship To Failed',
  props<{ error: any }>()
);

export const updateBillTo = createAction(
  '[Cart] Update Bill To',
  props<{ billToCustomerNumber: string, preselectingDefault?: boolean, paymentMethod: string }>()
);

export const updateBillToSuccess = createAction(
  '[Cart] Update Bill To Success',
  props<{ cart: CartResult }>()
);

export const updateBillToFailed = createAction(
  '[Cart] Update Bill To Failed',
  props<{ error: any }>()
);

export const updateAlternateEmail = createAction(
  '[Cart] Update Alternate Email',
  props<{ alternateEmail: string }>()
);

export const updateCart = createAction(
  '[Cart] Update Cart',
  props<{formGroupValue: any}>()
);

export const updateCartSuccess = createAction(
  '[Cart] Update Cart Success',
  props<{ cart: CartResult, deliveryItems: DeliveryItems }>()
);
export const updateCartFailed = createAction(
  '[Cart] Update Cart Failed',
  props<{error: any}>()
);

export const updateAlternateEmailSuccess = createAction(
  '[Cart] Update Alternate Email Success',
  props<{ cart: CartResult }>()
);

export const updateAlternateEmailFailed = createAction(
  '[Cart] Update Alternate Email Failed',
  props<{ error: any }>()
);

export const updateSpecialInstruction = createAction(
  '[Cart] Update Special Instruction',
  props<{ specialInstruction: string }>()
);

export const updateSpecialInstructionSuccess = createAction(
  '[Cart] Update Special Instruction Success',
  props<{ cart: CartResult }>()
);

export const updateSpecialInstructionFailed = createAction(
  '[Cart] Update Special Instruction Failed',
  props<{ error: any }>()
);

export const updateSmsOpt = createAction(
  '[Cart] Update Sms Opt',
  props<{ smsOpt: CheckoutSmsOpt }>()
);

export const updateSmsOptSuccess = createAction(
  '[Cart] Update Sms Opt Success',
  props<{ cart: CartResult }>()
);

export const updateSmsOptFailed = createAction(
  '[Cart] Update Sms Opt Failed',
  props<{ error: any }>()
);

export const updateVIN = createAction(
  '[Cart] Update VIN',
  props<{ vin: string }>()
);

export const preSubmitTransaction = createAction(
  '[Cart] Submit Pre Transaction',
  props<{ clearShoppingBasket: boolean, transactionType: OrderType }>()
);

export const submitTransaction = createAction(
  '[Cart] Submit Transaction',
  props<{ clearShoppingBasket: boolean; transactionType: OrderType }>()
);

export const submitOrderSuccess = createAction(
  '[Cart] Submit Order Success',
  props<{ orderCreationResult: OrderResult }>()
);

export const submitOrderFailed = createAction(
  '[Cart] Submit Order Failed',
  props<{ error: any }>()
);

export const submitQuoteSuccess = createAction(
  '[Cart] Submit Quote Success',
  props<{ quoteCreationResult: OrderResult }>()
);

export const submitQuoteFailed = createAction(
  '[Cart] Submit Quote Failed',
  props<{ error: any }>()
);

export const setDelivery = createAction(
  '[Cart] Set Delivery',
  props<{ deliveryOptionHeader: DeliveryTypes; deliveryLineItems?: DeliveryLineItem[] }>()
);

export const setDeliverySuccess = createAction(
  '[Cart] Set Delivery Success',
  props<{ cart: CartResult }>()
);

export const setDeliveryFailed = createAction(
  '[Cart] Set Delivery Failed',
  props<{ error: any }>()
);

export const getTaxes = createAction(
  '[Cart] Get Cart Taxes',
);

export const getTaxesSuccess = createAction(
  '[Cart] Get Cart Taxes Success',
  props<{ taxes: SapCalculateTaxesResult }>()
);

export const getTaxesFailed = createAction(
  '[Cart] Get Cart Taxes Failed',
  props<{ error: any; }>()
);

export const getOpenCarts = createAction(
  '[Cart] Get Open Carts'
);

export const getOpenCartsSuccess = createAction(
  '[Cart] Get Open Carts Success',
  props<{ getOpenCartsResult: Cart[] }>()
);

export const getOpenCartsFailed = createAction(
  '[Cart] Get Open Carts Failed',
  props<{ error: any }>()
);

export const selectOpenCart = createAction(
  '[Cart] Select Open Cart',
  props<{ openCartId?: string; branchCode?: string; customerNumber?: string; navigateToMyCart?: boolean; autoSelectQuote?: boolean}>()
);

export const addItemToCart = createAction(
  '[Cart] Add Item To Cart',
  props<{ addCartItemRequest: AddCartItemRequest; sourceLocationType: SourceLocationType }>()
);

export const addItemToCartSuccess = createAction(
  '[Cart] Add Item To Cart Success',
  props<{ cart: CartResult }>()
);

export const addItemToCartFailed = createAction(
  '[Cart] Add Item To Cart Failed',
  props<{ error: any }>()
);

export const addStoItemToCart = createAction(
  '[Cart] Add Sto Item To Cart',
  props<{ addCartItemRequest: AddStoCartItemRequest; cartItem: CartResultLineItem }>()
);

export const addStoItemToCartSuccess = createAction(
  '[Cart] Add Sto Item To Cart Success',
  props<{ cart: CartResult }>()
);

export const addStoItemToCartFailed = createAction(
  '[Cart] Add Sto Item To Cart Failed',
  props<{ error: any }>()
);

export const addStoItemFromNationalInventory = createAction(
  '[Cart] Add Sto Item From National Inventory',
  props<{ addCartItemRequest: AddStoCartItemRequest; partData: Part }>()
);

export const addStoItemFromNationalInventorySuccess = createAction(
  '[Cart] Add Sto Item From National Inventory Success',
  props<{ cart: CartResult }>()
);

export const addStoItemFromNationalInventoryFailed = createAction(
  '[Cart] Add Sto Item From National Inventory Failed',
  props<{ error: any }>()
);

export const addItemToCartFromPartsBuyout = createAction(
  '[Cart] Add Item To Cart From Parts Buyout',
  props<{
    partNumber: string;
    addNonInventoryPartBuyout: AddNonInventoryBuyoutItemRequest;
    modal: NgbActiveModal;
    successMessage: string;
    price: any;
    vendor: any;
    buyoutAmount: any;
    quantity: any;
  }>()
);

export const addItemToCartFromPartsBuyoutSuccess = createAction(
  '[Cart] Add Item To Cart From Parts Buyout Success',
  props<{ cart: CartResult }>()
);

export const addItemToCartFromPartsBuyoutFailed = createAction(
  '[Cart] Add Item To Cart From Parts Buyout Failed',
  props<{ error: any }>()
);

export const addFavoritePartsToCart = createAction(
  '[Cart] Add Favorite Parts To Cart',
  props<{ favoritePartsToAdd: FavoritePartView[] }>()
);

export const addFavoritePartsToCartSuccess = createAction(
  '[Cart] Add Favorite Parts To Cart Success',
  props<{ cart: CartResult }>()
);

export const addFavoritePartsToCartFailed = createAction(
  '[Cart] Add Favorite Parts To Cart Failed',
  props<{ error: any }>()
);

export const updateCartItem = createAction(
  '[Cart] Update Cart Item',
  props<{ updatedCartItem: CartResultLineItem }>()
);

export const updateCartItemSuccess = createAction(
  '[Cart] Update Cart Item Success',
  props<{ cart: CartResult }>()
);

export const updateCartItemFailed = createAction(
  '[Cart] Update Cart Item Failed',
  props<{ error: any }>()
);

export const removeItemsFromCart = createAction(
  '[Cart] Remove Item From Cart',
  props<{ isPickupDeliveryType: boolean; itemsToRemoveFromCart: ItemToRemoveFromCart[] }>()
);

export const removeItemsFromCartSuccess = createAction(
  '[Cart] Remove Item From Cart Success',
  props<{ cart: CartResult }>()
);

export const removeItemsFromCartFailed = createAction(
  '[Cart] Remove Item From Cart Failed',
  props<{ error: any }>()
);

export const closeQuoteItems = createAction(
  '[Cart] Close Quote Items',
  props<{ itemsToClose: CartResultLineItem[] }>()
);

export const closeQuoteItemsSuccess = createAction(
  '[Cart] Close Quote Items Success',
  props<{ cart: CartResult }>()
);

export const closeQuoteItemsFailed = createAction(
  '[Cart] Close Quote Items Failed',
  props<{ error: any }>()
);

export const deleteCart = createAction(
  '[Cart] Delete Cart',
  props<{ cartId: string; routeTo?: string }>()
);

export const deleteCartSuccess = createAction(
  '[Cart] Delete Cart Success',
  props<{ cartId: string }>()
);

export const deleteCartFailed = createAction(
  '[Cart] Delete Cart Failed',
  props<{ error: any }>()
);

export const loadCartForQuote = createAction(
  '[Cart] Load Quote Into Cart',
  props<{ cartId: string }>()
);

export const loadCartForQuoteSuccess = createAction(
  '[Cart] Load Quote Into Cart Success',
  props<{ cartResult: CartResult }>()
);

export const loadCartForQuoteFailed = createAction(
  '[Cart] Load Quote Into Cart Failed',
  props<{ error: any }>()
);

export const printSAPQuoteFromCheckout = createAction(
  '[Cart] Print SAP Quote From Checkout'
);

export const printSAPQuoteFromCheckoutSuccess = createAction(
  '[Cart] Print SAP Quote From Checkout Success'
);

export const printSAPQuoteFromCheckoutFailed = createAction(
  '[Cart] Print SAP Quote From Checkout Failed',
  props<{ error: any; }>()
);

export const closeQuote = createAction(
  '[Cart] Close Quote'
);

export const closeQuoteSuccess = createAction(
  '[Cart] Close Quote Success'
);

export const closeQuoteFailed = createAction(
  '[Cart] Close Quote Failed',
  props<{ error: any; }>()
);

export const updateQuote = createAction(
  '[Cart] Update Quote'
);

export const updateQuoteSuccess = createAction(
  '[Cart] Update Quote Success',
  props<{ cart: CartResult }>()
);

export const updateQuoteFailed = createAction(
  '[Cart] Update Quote Failed',
  props<{ error: any }>()
);

export const unlockQuoteForOthers = createAction(
  '[Cart] Unlock Quote'
);

export const unlockQuoteForOthersSuccess = createAction(
  '[Cart] Unlock Quote Success'
);

export const unlockQuoteForOthersFailed = createAction(
  '[Cart] Unlock Quote Failed',
  props<{ error: any }>()
);

export const openViewAllPartsModal = createAction(
  '[Order Confirmation] Open ViewAll Parts Modal'
);

export const loadCachedState = createAction(
  '[Cart] Loading State From Session Storage'
);

export const loadCachedStateSuccess = createAction(
  '[Cart] Loading State From Session Storage Success',
  props<{ cartState: CartResult }>()
);

export const updateOrderChannel = createAction(
  '[Cart] Updating Order Channel',
  props<{ orderChannel: string; }>()
);

export const setFreightFee = createAction(
  '[Cart] Set Freight Costs',
  props<{ cartItem: CartResultLineItem, freightCost: number | null }>()
);

export const setFreightFeeSuccess = createAction(
  '[Cart] Set Freight Costs Success',
  props<{ cart: CartResult }>()
);

export const setFreightFeeFailure = createAction(
  '[Cart] Set Freight Costs Failure',
  props<{error: any }>()
);

export const setDeliveryFee = createAction(
  '[Cart] Set Delivery Costs',
  props<{ deliveryCost: number | null }>()
);

export const setDeliveryFeeSuccess = createAction(
  '[Cart] Set Delivery Costs Success',
  props<{ cart: CartResult }>()
);

export const setDeliveryFeeFailure = createAction(
  '[Cart] Set Delivery Costs Failure',
  props<{ error: any }>()
);

export const deleteCartItem = createAction(
  '[Cart] Delete a Cart Line Item',
  props<{ cartLineItem : CartResultLineItem }>()
);

export const deleteCartItemSuccess = createAction(
  '[Cart] Delete a Cart Line Item Success',
  props<{ cartLineItemId : string }>()
);

export const deleteCartItemFailure = createAction(
  '[Cart] Delete a Cart Line Item Failure',
  props<{ error: any }>()
);


export const cacheCart = createAction(
  '[Cart] Cache Cart Data'
);

export const showHotFlagModal = createAction(
  '[Cart] Show Hot Flag Modal',
  props<{ editHotFlagCode?: string }>()
);

export const showKnownPartBuyoutModal = createAction(
  '[Cart] Show Known Parts Buyout Modal',
);

export const confirmPartsBuyout = createAction(
  '[Cart] Confirm Parts Buyout',
  props<{ selectedCartItems: UpdateFlagCartItem[] }>()
);

export const confirmPartsBuyoutSuccess = createAction(
  '[Cart] Confirm Parts Buyout Success',
  props<{ cart: CartResult }>()
);

export const confirmPartsBuyoutFailed = createAction(
  '[Cart] Confirm Parts Buyout Failed',
  props<{ error: any }>()
);

export const showStockTransferModal = createAction(
  '[Cart] Show Stock Transfer Modal',
);

export const showLineItemNotesModal = createAction(
  '[Cart] Show Line Item Notes Modal',
  props<{ cartItem: CartResultLineItem }>()
);

export const showNationalInventoryModal = createAction(
  '[Cart] Show National Inventory Modal',
  props<{ part: Part & { source?: string } | FavoritePartView & { source?: string } }>()
)

export const showPriceVerifyModal = createAction(
  '[Cart] Show Price Verify Modal',
  props<{ items: CartResultLine[] }>()
);

export const priceVerifySuccess = createAction(
  '[Cart] Price Verify Success',
  props<{ cart: CartResult }>()
);

export const priceVerifyFailed = createAction(
  '[Cart] Price Verify Failed',
  props<{ error: any }>()
);

export const priceOverride = createAction(
  '[Cart] Price Override',
  props<{ part: CartResultLineItem }>()
);

export const priceOverrideSuccess = createAction(
  '[Cart] Price Override Success',
  props<{ cart: CartResult }>()
);

export const priceOverrideFailed = createAction(
  '[Cart] Price Override Failed',
  props<{ error: any }>()
);

export const selectOpenCartSuccess = createAction(
  '[Cart] Select Open Cart Success',
  props<{ branch?: Branch; customers?: Customer[]; cartResult?: CartResult; payers: Customer[]}>()
);

export const selectCartSuccess = createAction(
  '[Cart] Select Cart Success',
  props<{ cart: CartResult }>()
);

export const selectCartFailed = createAction(
  '[Cart] Select Cart Failed',
  props<{ error: any }>()
);

export const selectCartBranchSuccess = createAction(
  '[Cart] Select Cart Branch Success',
  props<{ branch: Branch }>()
)

export const selectCartCustomerSuccess = createAction(
  '[Cart] Select Cart Customer Success',
  props<{ customer: Customer, payers: Customer[] }>()
)

export const selectCartBranchAndCustomerFailed = createAction (
  '[Cart] Select Branch And Customer Failed',
  props<{ error: any }>()
);

export const addPartToFavorites = createAction(
  '[Cart] Add Part To Favorites',
  props<{ cartItem: CartResultLineItem }>()
);

export const addPartToFavoritesSuccess = createAction(
  '[Cart] Add Part To Favorites Success',
  props<{ partId: string }>()
);

export const addPartToFavoritesFailed = createAction(
  '[Cart] Add Part To Favorites Failed',
  props<{ error: any }>()
);

export const removePartFromFavorites = createAction(
  '[Cart] Remove Part From Favorites',
  props<{ cartItem: CartResultLineItem }>()
);

export const removePartFromFavoritesSuccess = createAction(
  '[Cart] Remove Part From Favorites Success',
  props<{ partId: string }>()
);

export const removePartFromFavoritesFailed = createAction(
  '[Cart] Remove Part From Favorites Failed',
  props<{ error: any }>()
);

export const updateHotFlagOnCartItem = createAction(
  '[Cart] Update Hot Flag On Cart Item',
  props<{ updates: UpdateFlagCartItem[] }>()
);

export const updateHotFlagOnCartItemSuccess = createAction(
  '[Cart] Update Hot Flag On Cart Item Success',
  props<{ cart: CartResult; }>()
);

export const updateHotFlagOnCartItemFailed = createAction(
  '[Cart] Update Hot Flag On Cart Item Failed',
  props<{error: any}>()
);

export const applyLoyaltyDiscounts = createAction(
  '[Cart] Apply Loyalty Discounts',
  props<{ discounts: any }>()
);

export const updateTransactionType = createAction(
  '[Cart] Update Transaction Type',
  props<{ orderType: OrderType | null  }>()
);

export const updateTransactionTypeSuccess = createAction(
  '[Cart] Update Transaction Type Success'
);

export const updateTransactionTypeError = createAction(
  '[Cart] Update Transaction Type Failed',
  props<{error: any}>()
);

export const setSubmitLock = createAction(
  '[Cart] Lock Submit',
  props<{isSubmitLocked: boolean}>()
);

export const setDeliveryItems = createAction(
  '[Cart] Set Delivery Items',
  props<{deliveryItems: DeliveryItems}>()
);