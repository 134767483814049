import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { NotesComponentConfig, NotesComponentState } from "./notes.component.state";
import { Observable, catchError, switchMap, tap, of, map } from "rxjs";
import { BackCounterNotesService } from "services/back-counter-notes.service";
import { BackCounterNote } from "entities/notes";
import { ToastType } from "entities/toast-type";
import { ToastService } from "services/toast.service";
import { getDateInfoForNewNote } from "../back-counter-requests-table/back-counter-requests-table.utils";
import { FeatureFlagService } from "services/feature-flag.service";

@Injectable()
export class NotesComponentStore extends ComponentStore<NotesComponentState> {

  // SELECTORS
  public notes$ = this.select((state) => state.notes);
  public loading$ = this.select((state) => state.loading);
  public messages$ = this.service.messages$;
  public config: NotesComponentConfig;

  constructor(
    public service: BackCounterNotesService,
    private toastService: ToastService,
    private featureFlagService: FeatureFlagService,
  ) {
    super({
      notes: [],
      loading: false,
      locked: false,
      id: null
    });

  }

  // API
  public setConfig(config: NotesComponentConfig): void {
    this.config = config;
  }

  public addNewNote(note: BackCounterNote): void {
    this.updateState("createNoteSuccess", { note });
  }

  public readonly getNotes = this.effect((params$: Observable<{
    id: string;
  }>) => params$.pipe(
    tap(() => this.patchState({ loading: true })),
    switchMap(({ id }) =>
      this.service.getNotes(id, this.config.pos, this.config.type).pipe(
        map((notes: BackCounterNote[]) => notes.map( note => {
          const year = Number(note.utcTimestamp.substring(0, 4));
          const month = Number(note.utcTimestamp.substring(4, 6)) - 1;
          const day = Number(note.utcTimestamp.substring(6, 8));
          const hour = Number(note.utcTimestamp.substring(8, 10));
          const minute = Number(note.utcTimestamp.substring(10, 12));
          const second = Number(note.utcTimestamp.substring(12, 14));
          const theDate = new Date(Date.UTC(year, month, day, hour, minute, second));
          note.utcTimestamp = theDate.toLocaleString();
          return note;
        })),
        tap((notes: BackCounterNote[]) => this.updateState("getNotesSuccess", { notes: notes || [] })),
        catchError((error) => this.onError("Error while getting note", error, true))
      )
    ))
  );

  public readonly createNote = this.effect((params$: Observable<{
    id: string;
    note: BackCounterNote;
  }>) => params$.pipe(
    switchMap(({ id, note }) =>
      this.service.createNote(id, note, this.config.pos, this.config.type, this.config.releaseLock).pipe(
        tap(() => this.updateState("createNoteSuccess", { note })),
        catchError((error) => this.onError("Error while creating note", error))
      )
    ))
  );

  private updateState(actionType: string, params: any): void {
    const state = this.get();

    switch (actionType) {
      case "getNotesSuccess":
        this.patchState({
          loading: false,
          notes: params.notes
        });
        break;

      case "createNoteSuccess":
        this.patchState({ notes: [
          ...state.notes,
          getDateInfoForNewNote(params.note)
        ]});
        break;

      default:
        return;
    }
  }

  public onError(message: string, error: any, clearLoading = false): any {
    if( error?.detail){
      this.toastService.showToast(message + ": " + error.detail, ToastType.Error);
    }else{
      this.toastService.showToast(message, ToastType.Error);
    }

    if (clearLoading) {
      this.patchState({ loading: false });
    }
    return of(error) ;
  }

}
