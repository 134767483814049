import { CartDetails } from "entities/cart-details";

export class AppInsightBilling {
    userId?: string = "";
    customerNumber?: string = "";
    customerName?: string = "";
    cartDetails?: string;
    orderNumber?: string = "";
    PONumber?: string = "";
    products?: any = null;
    searchTerm?: string = "";
    previousBranch?: string = "";
    currentBranch?: string = "";
    plMetricName?: string = "";
    unitNumber?: string = "";
}
