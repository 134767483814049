<div id="partCardList_div_IsNotLoading" class="w-100" [hidden]="!hasParts">
  <div *ngIf="title" class="title-of-box-hold">
    <h5 class="strong">{{title}}</h5>
  </div>
  <div class="position-relative">
    <div class="related-alternate-parts-pagination">
      <nav id="partCardList_nav_Pagination" class="d-flex align-items-center" aria-label="Page navigation" *ngIf="pager.pages && pager.pages.length">
        <div>
          <span [ngClass]="{'text-light': partType === featuredProductType}" class="pagination-result">Showing {{pager.startIndex + 1}} - {{pager.endIndex + 1}} of {{pager.totalItems}} Results</span>
        </div>
        <div>
          <ul class="pagination">
            <li class="page-item" [ngClass]="{disabled:pager.currentPage == 1}">
              <a id="partCardList_a_PagePrevious" [ngClass]="{'dark': partType === featuredProductType}" class="page-link" aria-label="Previous" (click)="setPage(pager.currentPage - 1)"><span aria-hidden="true">&lsaquo;</span></a>
            </li>
            <li id="partCardList_li_PageList_{{i}}" *ngFor="let page of pager.pages;let i = index" class="page-item" [ngClass]="{active:pager.currentPage == page}">
              <a id="partCardList_a_PageItem_{{i}}" class="page-link" (click)="setPage(page)">{{page}}</a>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage == pager.totalPages}">
              <a id="partCardList_a_PageNext" [ngClass]="{'dark': partType === featuredProductType}" class="page-link" aria-label="Next" (click)="setPage(pager.currentPage + 1)"><span aria-hidden="true">&rsaquo;</span></a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
  <div class="p-3 bg-white">
    <div class="row">
      <div class="col-12 col-md-3" *ngFor="let part of pagedParts;let i = index">
        <div>
          <part-card [index]="i" [part]="part" (partChange)="changeFavorite($event)" [partType]="partType" [sourcePart]="sourcePart"></part-card>
        </div>
      </div>
    </div>
  </div>
</div>
