import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ToastService } from 'services/toast.service';
import { CustomerService } from 'services/customer.service';
import * as CustomerSelectors from 'store/customer/customer.selectors';
import { Store } from '@ngrx/store';
import { AppState } from 'store/app-state';
import { CustomerNotes } from 'entities/customer-search';
@Component({
  selector: 'customer-notes-modal',
  templateUrl: './customer-notes-modal.component.html'
})
export class CustomerNotesModalComponent implements OnInit {
  public customerNotes$: Observable<CustomerNotes>;
  public isLoading: boolean;
  public customerNumber$ = this.store.select(CustomerSelectors.selectedCustomer).pipe(map(customer => customer.customerNumber));

  constructor(
    private store: Store<AppState>,
    private toastService: ToastService,
    private customerService: CustomerService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.customerNotes$ = this.customerNumber$
      .pipe(
        tap(() => {
          setTimeout(() => this.isLoading = true);
        }),
        switchMap((customerNumber) =>
          this.customerService.getCustomerNotes(customerNumber)
            .pipe(
              catchError((error) => {
                this.toastService.errorMessage('CustomerNotesModalComponent', 'getCustomerNotes', 'getCustomerNotes', error);
                return EMPTY;
              })
            )
        ),
        tap(() => {
          setTimeout(() => this.isLoading = false);
        })
      );
  }

  closeModal() {
    this.activeModal.dismiss();
  }
}
