import { CartDetails } from 'entities/cart-details';

export class AppInsightAddToCart {
    userId: string = '';
    customerNumber: string = '';
    customerName: string = '';
    branchNumber: string = '';
    cartDetails: CartDetails;
    source: string = '';
    product: any = null;
    plMetricName: string = '';
}
