import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, of, Observable, merge } from 'rxjs';
import { switchMap, catchError, shareReplay, map, tap, withLatestFrom } from 'rxjs/operators';
import { CommonDataService } from 'services/common-data.service';
import { BranchService } from "services/branch.service";
import { DiscretePage } from 'entities/discrete-page';
import { User } from 'entities/user';
import { OrderService } from 'services/order.service';
import { LoaderService } from 'services/loader.service';
import { ToastService } from 'services/toast.service';
import { Branch } from 'entities/branch';
import { AppState } from 'store/app-state';
import { isCashCustomer } from 'helpers/is-cash-customer';
import * as CartActions from 'store/cart/cart.actions';
import { OrderHistoryRequest, OrderHistory } from 'entities/order-history';
import { OrderType } from 'entities/order-type';
import * as BranchSelectors from "store/branch/branch.selectors";
import { OrderStatus } from 'entities/order-confirmation/order-status';
import { MyDashboardService } from 'services/my-dashboard.service';
import { ToastType } from 'entities/toast-type';
import { LoggerService } from 'services/logger.service';
import { ErrorInformation } from 'entities/error-information';

@Component({
  selector: 'quotes',
  templateUrl: './quotes.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class QuotesComponent {
  readonly OrderStatus = OrderStatus;

  currentUser: User;
  currentPageNumber: number = 1;
  pageSize: number = 10;
  private lastRequest: OrderHistoryRequest;

  public branches$: Observable<Branch[]>;
  public currencyCode$ = this.store.select(BranchSelectors.selectCurrencyCode);
  private findQuotesSubject: Subject<OrderHistoryRequest> = new Subject();
  private getQuoteSubject: Subject <string> = new Subject();
  private quotesPage$: Observable<DiscretePage<OrderHistory>> = this.findQuotesSubject.pipe(
    tap(() => {
      this.loaderService.loading = true;
    }),
    switchMap((request: OrderHistoryRequest) => this.orderService.getOrderHistory(request)),
    catchError((err: ErrorInformation): Observable<DiscretePage<OrderHistory>> => {
      this.notification.errorMessage("quotes", "getQuotes", "getQuotes", err);
      return of({ count: 0, items: [] });
    }),
    tap(() => {
      this.loaderService.loading = false;
    }),
    shareReplay(1)
  );

  private getPage$: Observable<DiscretePage<OrderHistory>> = this.getQuoteSubject.pipe(
    tap(() => {
      this.loaderService.loading = true;
    }),
    switchMap((request: string) => this.orderService.getOrderDetails(request, OrderType.Quote)
      .pipe(
        map((response): DiscretePage<OrderHistory> => ({ count: 1, items: [response.orderHistory] })),
        catchError((err: ErrorInformation): Observable<DiscretePage<OrderHistory>> => {
          return of({ count: 0, items: [] });
        }),
      )),
    tap(() => {
      this.loaderService.loading = false;
    }),
    shareReplay(1)
  );

  public quotes$: Observable<OrderHistory[]> = merge(this.quotesPage$, this.getPage$)
    .pipe(
      map((quotesPage: DiscretePage<OrderHistory>) => quotesPage.items),
      withLatestFrom(this.branchService.branches$),
      map(([orders, branches]: [OrderHistory[], Branch[]]) => (
        orders.map((order: OrderHistory) => ({...order, branchName: branches.find((b: Branch) => b.code === order.branch).displayName}))
      ))
    );

  public quoteCount$: Observable<number> = merge(this.quotesPage$, this.getPage$)
    .pipe(
      map((quotesPage: DiscretePage<OrderHistory>) => quotesPage.count)
    );

  constructor(
    private store: Store<AppState>,
    public commonDataService: CommonDataService,
    private orderService: OrderService,
    private loaderService: LoaderService,
    private notification: ToastService,
    private branchService: BranchService,
    private toastService: ToastService,
    private myDashboardService: MyDashboardService,
    private loggerService: LoggerService
  ) {
    this.currentUser = this.commonDataService.User;
    this.branches$ = branchService.branches$;
  }

  searchQuotes(request: OrderHistoryRequest) {
    this.lastRequest = request;
    this.currentPageNumber = request.startNum / request.count + 1;
    this.findQuotesSubject.next(request);
  }

  findQuote(quoteNumber: string) {
    this.lastRequest = null;
    this.currentPageNumber =1;
    this.getQuoteSubject.next(quoteNumber);
  }

  pageChange(event: number) {
    this.currentPageNumber = event;

    const req = this.lastRequest;
    req.startNum = (this.currentPageNumber - 1) * req.count;
    this.searchQuotes(req);
  }

  goToCheckoutFromQuotesPanel(quote: OrderHistory) {
    this.loaderService.loading = true;
    this.myDashboardService.IsOpenCartQuoteOrder(quote.cartId)
      .then(oc => {
        this.loaderService.loading = false;
        if (oc.ErrorType !== undefined && oc.ErrorType != null && oc.ErrorType !== 200) {
          this.toastService.errorMessage('QuotesComponent', 'goToCheckoutFromQuotesPanel', 'isOpenCartQuoteOrder', oc);
        } else {
          if (oc.level === 'success') {
            this.store.dispatch(CartActions.loadCartForQuote({ cartId: quote.cartId}));
          }
          if (oc.level === 'error') {
            this.toastService.showToast(oc.message, ToastType.Error);
          }
        }
        this.loggerService.verbose('IsOpenCartQuoteOrder : ', oc);
      },
      error => { this.loaderService.loading = false; });
  }

  isCashCustomer(customerNumber: string) {
    return isCashCustomer(customerNumber);
  }
}
