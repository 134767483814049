/**
 * 
 * @param arr 
 * @param project 
 * @example distinct(arr, (s) => s.toLowerCase())
 * @example distinct(arr, (item) => item.key)
 */
export function distinct<T, U>(arr: T[], project: (item: T) => U = (item) => item as unknown as U): T[] {
  return arr
    .map((value) => ({ value, projected: project(value) }))
    .filter(({ projected }, index, self) => 
      self.findIndex(({ projected: innerProjected}) => innerProjected === projected) === index
    )
    .map(({ value }) => value);
}
