import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment-timezone";

@Pipe({
  name: "dateFormat",
})
export class DateFormatPipe implements PipeTransform {
  transform(
    value: string,
    parseFormat: string,
    outputFormat: string,
    defaultValue: string,
    showInvalidText: boolean = true
  ): string {
    const invalidDateText = "Invalid date";
    const formattedDate = moment(value, parseFormat).format(outputFormat);
    if (value && invalidDateText === formattedDate) {
      return showInvalidText ? formattedDate : "";
    } else if (value) {
      return formattedDate;
    } else {
      return defaultValue;
    }
  }
}
