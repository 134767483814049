import {Component, Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartBinLocationItem } from 'entities/part-bin-location-item';

@Component({
  selector: 'part-bin-locations-modal',
  templateUrl: './part-bin-locations-modal.component.html'
})

export class PartBinLocationsModalComponent {

  @Input() partBinLocationItems: PartBinLocationItem[];

  constructor(
    private activeModal: NgbActiveModal
    ) { }


  closeModal() {
    this.activeModal.dismiss();
  }
}
