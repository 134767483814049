<div style="background-color: white;">
  <div *ngIf="panelType == 'order' "class="title-of-box-hold py-3">
    <h5 class="strong">Find Orders</h5>
  </div>
  <div *ngIf="panelType == 'quote' "class="title-of-box-hold py-3">
    <h5 class="strong">Find Quotes</h5>
  </div>
  <div class="p-2">
    <form *ngIf="searchRequest$ | async as searchRequest" class="needs-validation"
      [ngClass]="{ 'was-validated': !findOrderForm.pending }" (ngSubmit)="findOrder()"
      #findOrderForm="ngForm">
      <div class="row mt-3" style="align-items: center; text-align: end;">
        <div class="col-4" style="margin-left: 5px; margin-right: -10px">
          <ng-container *ngIf="panelType == 'quote'">
            <label class="strong">Quote Number</label>
          </ng-container>
          <ng-container *ngIf="panelType == 'order'">
            <label class="strong">Order Number</label>
          </ng-container>
        </div>
        <div class="col-8" style="text-align: center;">
          <input id="findOrder_text_OrderNumber" type="text" maxlength="10" digitsOnly required name="findOrders_text_OrderNumber" style="display: inline-block; width: 85%" class="form-control number-input-without-button" [ngModel]="orderNumber" (ngModelChange)="setOrderNumber($event)"/>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 pb-3" style="text-align: center;">
          <button [disabled]="!findOrderForm.valid || !orderNumber || orderNumber === ''" class="btn btn-primary" id="findOrder_button_Find" tabindex="0" type="submit" style="width: 100px;">
            Find
          </button>
        </div>
      </div>
    </form>
  </div>
  <hr style="height: 1px; width:100%; border-width:0; color:black; background-color:black">
  <div style="padding-top: 4px">
    <form *ngIf="searchRequest$ | async as searchRequest" class="needs-validation"
      [ngClass]="{ 'was-validated': !searchOrderForm.pending }" (ngSubmit)="searchOrders()"
      #searchOrderForm="ngForm">
    <div class="row position-relative mt-3" style="align-items: center; text-align: end;">
      <div class="col-4" style="margin-left: 5px; margin-right: -10px">
        <label class="strong" for="date_range_input" style="display:inline-block">Date Range</label>
      </div>
      <div id="date_range_input" class="col-8" style="text-align: center;">
        <div class="mx-1 order-history-date-btn">
          <label class="mb-0 d-block" for="findOrders_text_StartDate">Start Date</label>
          <input class="form-control d-block" tabindex="1" container="body" id="findOrders_text_StartDate" type="text" placeholder="MM/DD/YYYY" required
            ngbDatepicker #d1="ngbDatepicker" autocomplete="off" (click)="d1.toggle()"
            name="findOrders_text_StartDate" [ngModel]="searchRequest.startDate" (ngModelChange)="setStartDate($event)" [ngModelOptions]="{ updateOn: 'blur' }"
          />
        </div>
        <div class="mx-1 order-history-date-btn">
          <label class="mb-0 d-block" for="findOrders_text_EndDate">End Date</label>
          <input tabindex="2" class="form-control d-block" container="body" id="findOrders_text_EndDate" type="text" placeholder="MM/DD/YYYY" required
            ngbDatepicker #d2="ngbDatepicker" autocomplete="off" (click)="d2.toggle()"
            name="findOrders_text_EndDate" [ngModel]="searchRequest.endDate" (ngModelChange)="setEndDate($event)" [ngModelOptions]="{ updateOn: 'blur' }"
          />
        </div>
        <ng-container *ngIf="panelType == 'quote'">
            <i class="fas fa-info-circle" style="position: absolute; padding: 10px; right: 9px;" [ngbTooltip]="DateTipContent" [openDelay]="300" [closeDelay]="300" placement="right" tooltipClass="part-number-tooltip"></i>
            <ng-template #DateTipContent>
              <div class="p-2"><b>Quotes expire within 30 days of creation.</b></div>
            </ng-template>
        </ng-container>
      </div>    
    </div>
    <div class="row mt-3" style="align-items: center; text-align: end;">
      <div class="col-4" style="margin-left: 5px; margin-right: -10px">
        <label class="strong">Customer Number</label>
      </div>
      <div class="col-8" style="text-align: center;">
        <input tabindex="3" *ngIf="isInternal$ | async" id="findOrders_text_CustomerNumber" type="text" maxlength="10" name="findOrders_text_CustomerNumber" style="display: inline-block; width: 85%" class="form-control" [ngModel]="searchRequest.customerNumber" (ngModelChange)="setCustomerNumber($event)"/>
        <div *ngIf="!(isInternal$ | async)" style="text-align: start; margin-left: 1rem;">
          <div class="strong" id="findOrders_div_CustomerNumber">
            {{ searchRequest.customerNumber | customerNumber }}
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="(isInternal$ | async)" class="row mt-3" style="align-items: center; text-align: end;">
      <div class="col-4" style="margin-left: 5px; margin-right: -10px">
        <label class="strong">Ship To Number</label>
      </div>
      <div class="col-8" style="text-align: center;">
        <input tabindex="4" id="findOrders_text_ShipTo" type="text" maxlength="10" name="findOrders_text_ShipTo" style="display: inline-block; width: 85%" class="form-control" [ngModel]="searchRequest.shipTo" (ngModelChange)="setShipTo($event)"/>
      </div>
    </div>
    <div class="row mt-3" style="align-items: center; text-align: end;">
      <div class="col-4" style="margin-left: 5px; margin-right: -10px">
        <label class="strong">Branch Number</label>
      </div>
      <div class="col-8" style="text-align: center;">
        <input tabindex="5" id="findOrders_text_BranchNumber" type="text" maxlength="10" name="findOrders_text_BranchNumber" style="display: inline-block; width: 85%" class="form-control" [ngModel]="searchRequest.branch" (ngModelChange)="setBranchCode($event)"/>
      </div>
    </div>
    <ng-container *ngIf="panelType == 'order'">
      <div class="row mt-3" style="align-items: center; text-align: end;">
        <div class="col-4" style="margin-left: 5px; margin-right: -10px">
          <label class="strong">PO Number</label>
        </div>
        <div class="col-8 position-relative" style="text-align: center;">
          <input tabindex="6" id="findOrders_text_PoNumber" type="text" maxlength="20" name="findOrders_text_PoNumber" style="display: inline-block; width: 85%" class="form-control" [ngModel]="searchRequest.poNumber" (ngModelChange)="setPONumber($event)"/>
          <i class="fas fa-info-circle" style="position: absolute; padding: 10px; right: 20px;" [ngbTooltip]="PoTipContent" [openDelay]="300" [closeDelay]="300" placement="right" tooltipClass="part-number-tooltip"></i>
          <ng-template #PoTipContent>
            <div class="p-2"><b>PO number search is case-sensitive. To search by partial PO number, you must add the wild card symbol (*) to the beginning and/or end of the partial PO number.</b></div>
          </ng-template>
        </div>
      </div>
      <div class="row mt-3" style="align-items: center; text-align: end;">
        <div class="col-4" style="margin-left: 5px; margin-right: -10px">
          <label class="strong">Part Number</label>
        </div>
        <div class="col-8 position-relative" style="text-align: center;">
          <input tabindex="7" id="findOrders_text_PartNumber" type="text" maxlength="30" name="findOrders_text_PartNumber" style="display: inline-block; width: 85%" class="form-control" [ngModel]="searchRequest.partNumber" (ngModelChange)="setPartNumber($event)"/>
          <i class="fas fa-info-circle" style="position: absolute; padding: 10px; right: 20px;" [ngbTooltip]="PartTipContent" [openDelay]="300" [closeDelay]="300" placement="right" tooltipClass="part-number-tooltip"></i>
          <ng-template #PartTipContent>
            <div class="p-2"><b>To search by partial Part number, you must add the wild card symbol (*) to the beginning and/or end of the partial part number.</b></div>
          </ng-template>
        </div>
      </div>
    </ng-container>
    <div class="row mt-3">
      <div class="col-12 pb-3" style="text-align: center;">
        <button tabindex="8" [disabled]="!searchOrderForm.valid" class="btn btn-primary" id="findOrders_button_Find" type="submit" style="width: 100px;">
          Find
        </button>
      </div>
    </div>
  </form>
</div>
</div>
