import { StoredPaymentMethod } from "./stored-payment-method";

export enum PaymentTypeEnum {
  Cash = 0,
  Payer = 1,
  InviteToPay = 2,
  CcOnline = 3,
  CcCardInHand = 4,
  None = 5
}

export enum InviteToPayTypeEnum {
  None = 0,
  EMail = 1,
  Sms = 2
}

export interface PaymentChoice {
  paymentTypeEnum: PaymentTypeEnum;          
  selectedToken: StoredPaymentMethod;
  inviteToPayType: InviteToPayTypeEnum;
  inviteToPayEmail: string;
  inviteToPayPhone: string;
}
