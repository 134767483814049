<div class="modal-header">
  <div class="title-of-box pull-left">
    Create Parts Result
  </div>
  <button id="CreatePartsResultModal_Button_Close" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <table class="table">
    <thead>
      <tr>
        <th class="result-status"></th>
        <th class="w-25">
          Part #
        </th>
        <th>
          Message
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let model of models; let i = index">
        <td>
          <div class="{{model.created ? 'text-success' : 'text-danger'}}">
            <span id="CreatePartsResultModal_Span_ThumbsUpOrDown_{{i}}" class="fas fa-thumbs-{{model.created ? 'up' : 'down'}}"></span>
          </div>
        </td>
        <td id="CreatePartsResultModal_TD_PartNumber_{{i}}">{{model.partNumber}}</td>
        <td id="CreatePartsResultModal_TD_CreatedMessage_{{i}}">
          {{model.created ? 'Part has been created' : model.errorMessage}}
          {{model.message ? model.message : ''}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
