import { ChangeDetectionStrategy, Component, OnDestroy, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Store } from '@ngrx/store';
import { OnChange, OnTouched } from 'entities/control-value-accessor-funcs';
import { Customer } from 'entities/customer';
import { ShipToCustomer } from 'entities/ship-to-customer';
import { Observable } from 'rxjs';
import { FeatureFlagService } from 'services/feature-flag.service';
import { AppState } from 'store/app-state';
import * as ConfigurationSelectors from 'store/configuration/configuration.selectors';
import * as CustomerActions from 'store/customer/customer.actions';
import * as CustomerSelectors from 'store/customer/customer.selectors';
import { SelectedCustomer } from 'store/customer/customer.state';

@Component({
  selector: 'customer-select',
  templateUrl: './customer-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomerSelectComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerSelectComponent implements ControlValueAccessor, OnDestroy {
  isInternal$: Observable<boolean> =  this.store.select(ConfigurationSelectors.isInternal);
  selectedCustomer$ = this.store.select(CustomerSelectors.selectedCustomer);
  isCustomerSelected$: Observable<boolean> = this.store.select(CustomerSelectors.isCustomerSelected);
  isCustomerNotesEnabled: boolean = false;
  isDefaultCustomer$: Observable<boolean> = this.store.select(CustomerSelectors.isDefaultCustomer);

  isSelectedCustomerFavorite$: Observable<boolean> = this.store.select(CustomerSelectors.isSelectedCustomerFavorite);
  public shipToAddress$: Observable<ShipToCustomer> = this.store.select(CustomerSelectors.selectShipTo);
  public disabled: boolean;
  private onChange: OnChange<Customer> = (newValue: Customer) => {};
  private onTouched: OnTouched = () => {};

  constructor(
    private store: Store<AppState>,
    private featureFlagService: FeatureFlagService
  )
  {
    this.isCustomerNotesEnabled = featureFlagService.isFeatureActive("FeatureFlag.CustomerNotes.Enabled");
  }

  writeValue(newValue: Customer): void {

  }
  registerOnChange(fn: OnChange<Customer>): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: OnTouched): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onCustomerSelect(customer) {

  }

  addToFavorites(customer: SelectedCustomer) {
    this.store.dispatch(CustomerActions.addFavoriteCustomer({customerNumber: customer.customerNumber}));
  }

  removeFavorites(selectedCustomer: SelectedCustomer) {
    this.store.dispatch(CustomerActions.removeFavoriteCustomer({customerNumber: selectedCustomer.customerNumber}));
  }

  onDifferentCustomerClick() {
    this.store.dispatch(CustomerActions.clearCustomer());
  }

  showCustomerNotes(customerNumber: string): void {
    this.store.dispatch(CustomerActions.getCustomerNotes({ customerNumber }));
  }

  onChangeShipToAddress() {
    this.store.dispatch(CustomerActions.loadShipToCustomers({ openShipToModal: true }));
  }

  ngOnDestroy() {

  }
}
