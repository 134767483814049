import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, firstValueFrom, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from 'services/base.service';
import { StoredPaymentMethod} from 'entities/stored-payment-method';
import { CartResult } from 'entities/cart-result';
import { I2pResendRequest } from 'app/_components/invite-to-pay/invite-to-pay.model';
import { PaymentDevice } from 'entities/payment-device';
import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions } from "@microsoft/signalr";
import { AuthenticationService } from "app/_modules/authentication/authentication.service";
import { environment } from "environments/environment";
import * as OrderConfirmationActions from 'store/order-confirmation/order-confirmation.actions';
import { AppState } from "store/app-state";
import { Store } from "@ngrx/store";
import { DeviceResponse } from 'entities/order-confirmation/payment-device';

interface GetStoredPaymentTokensResponse {
  storedPaymentMethods: StoredPaymentMethod[];
}

interface GetPaymentDevicesResponse {
  devices: PaymentDevice[];
}

@Injectable({
  providedIn: "root"
})
export class PaymentService extends BaseService {

  private deviceId: string;
  private connection: HubConnection;

  constructor(
    private store: Store<AppState>,
    private http: HttpClient,
    private authenticationService: AuthenticationService) {
    super();
  }

  public createConnection(deviceId: string ): void {
    this.deviceId = deviceId;
    if (this.connection) {
      return;
    }

    const options: IHttpConnectionOptions = {
      withCredentials: true,
      accessTokenFactory: async () => await firstValueFrom(this.authenticationService.accessToken$)
    };

    const url = environment.API_URL + "api/payment";
    this.connection = new HubConnectionBuilder()
      .withUrl(url, options)
      .withAutomaticReconnect([2000, 5000, 10000, 30000, 60000, null])
      .build();

    this.connection.on("DevicePaymentMessage", (message: DeviceResponse) => {
      if (message.deviceId === this.deviceId) {
        if (message.status === "TRUE") {
         this.store.dispatch(OrderConfirmationActions.acceptedDevicePaymentMessageReceived({eventInfo:message}));
        } else if(message.status === "FALSE") {
          this.store.dispatch(OrderConfirmationActions.failingDevicePaymentMessageReceived({eventInfo:message}));
        } else {
          this.store.dispatch(OrderConfirmationActions.unknownPaymentMessageReceived({eventInfo:message}));
        }
      }
    });

    this.connection.start().then(() => {
      console.log("signalr connection started for payments.");
    }).catch((err) => {
      console.log(err);
    });
  }

  getStoredPaymentMethods(customerNumber): Observable<StoredPaymentMethod[]> {
    const url: string = `api/payment/stored-payment-tokens?customerNumber=${customerNumber}`;
    return this.http.get<GetStoredPaymentTokensResponse>(url).pipe(
      map( (resp) =>  resp.storedPaymentMethods )
    );
  }

  setPaymentChoices(paymentChoices): Observable<CartResult>{
    const url: string = `api/payment/payment-choices`;
    return this.http.post<CartResult>(url, paymentChoices, this.options);
  }

  inviteToPayResend(i2pRequest: I2pResendRequest): Observable<any> {
    const url: string = `api/payment/resend-invite-to-pay`;
    return this.http.post<CartResult>(url, i2pRequest);
  }

  getDeviceList(branchNumber) : Observable<PaymentDevice[]>{
    const url: string = `api/payment/payment-device-list?branchNumber=${branchNumber}`;
    return this.http.get<GetPaymentDevicesResponse>(url).pipe(
      map( (resp) =>  resp.devices )
    );
  }
}
