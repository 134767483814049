export function getImageHelper(
  imageName: string,
  imageBaseUrl: string,
  size: string = "100X100"
): string {
  if (imageBaseUrl === null || imageBaseUrl === "") return "";

  if (imageName === null || imageName === "" || imageName === "default.gif") {
    return imageBaseUrl + size + "/" + "unavailable-image.png ";
  } else {
    return imageBaseUrl + size + "/" + imageName;
  }
}
