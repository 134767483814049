import { createAction, props } from '@ngrx/store';

export const updateCartItemSuccess = createAction(
  '[My Cart Component] Update Cart Item Success',
  props<{ cartId: string }>()
);

export const updateFavoriteItemSuccess = createAction(
  '[My Cart Component] Update Favorite Cart Item Success',
  props<{ cartId: string }>()
);

export const removeCartItemsSuccess = createAction(
  '[My Cart Component] Remove Cart Items Success',
  props<{ itemIds: string[] }>()
);

export const verifyCartPricesSuccess = createAction(
  '[My Cart Component] Verify Cart Item Prices Success',
  props<{ cartId: string }>()
);

export const updateCartItemPriceSuccess = createAction(
  '[My Cart Component] Update Cart Item Price Success'
);

export const updateCartItemsHotFlagSuccess = createAction(
  '[My Cart Component] Update Cart Items Hot Flag Success',
  props<{ cartId: string }>()
);

export const finalPitchSuccess = createAction(
  '[My Cart Component] Final Pitch Success',
  props<{ isSuccess?: boolean }>()
);

export const deleteCart = createAction(
  '[My Cart Component] Delete Cart',
  props<{ cartId: string }>()
);
