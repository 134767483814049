/* eslint-disable @typescript-eslint/member-ordering */
import { Component, ChangeDetectionStrategy, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, map, scan, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Branch } from 'entities/branch';
import { Customer } from 'entities/customer';
import { Part } from 'entities/part';
import { PartSearch, Refinement } from 'entities/part-search';
import { PartSearchResponse } from 'entities/parts/part-search-response';
import { SourceLocationType } from 'services/logger.service';
import { TrackerService } from 'services/tracker.service';
import { PartService } from 'services/part.service';
import { ToastService } from 'services/toast.service';
import { OnChange, OnTouched } from 'entities/control-value-accessor-funcs';
import { DisplayType } from 'entities/back-counter-tool';

@Component({
  selector: 'part-select',
  templateUrl: './part-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PartSelectComponent),
    multi: true
  }]
})
export class PartSelectComponent implements ControlValueAccessor, OnInit  {
  @Input() customer: Customer;
  @Input() branch: Branch;
  @Input() initialSearchTerm: string;
  @Input() displayType: DisplayType;

  private searchSubject: Subject<Partial<PartSearch>> = new ReplaySubject(1);

  private readonly initialSearch = {
    partId: '',
    partSearchTerm: '',
    branchCode: '',
    customerNumber: '',
    pageNumber: 1,
    pageSize: 20,
    isExpandedSearch: true,
    isCountCheck: false,
    refinements: []
  };

  public loading: boolean = true;
  public partsQuery$: Observable<{ searchCriteria: PartSearch, partsData: PartSearchResponse }> = this.searchSubject
    .pipe(
      scan((oldSearch, newSearch) => ({
        ...oldSearch,
        partSearchTerm: newSearch.partSearchTerm !== undefined ? newSearch.partSearchTerm : oldSearch.partSearchTerm,
        branchCode: newSearch.branchCode !== undefined ? newSearch.branchCode : oldSearch.branchCode,
        customerNumber: newSearch.customerNumber !== undefined ? newSearch.customerNumber : oldSearch.customerNumber,
        pageNumber: newSearch.pageNumber !== undefined ? newSearch.pageNumber : oldSearch.pageNumber,
        pageSize: newSearch.pageSize !== undefined ? newSearch.pageSize : oldSearch.pageSize,
        refinements: newSearch.refinements !== undefined ? newSearch.refinements : oldSearch.refinements
      }), this.initialSearch),
      filter((searchCriteria) => Boolean(searchCriteria.partSearchTerm)),
      tap(() => {
        this.loading = true;
        this.part = null;
        this.onChange(null);
      }),
      switchMap((searchCriteria) =>
        this.partService.searchParts(searchCriteria)
          .pipe(
            map((partsData: any): PartSearchResponse => {
              if (partsData.ErrorType && partsData.ErrorType !== 200) {
                this.toastService.errorMessage('PartBaseComponent', 'getParts', 'getParts', partsData);
                return {
                  parts: [],
                  totalParts: 0,
                  facets: {
                    manufacturer: { field: null, buckets: [] },
                    category: { field: null, buckets: [] }
                  },
                  isExpandedSearch: false
                };
              }
              return partsData;
            }),
            map((partsData) => ({ searchCriteria, partsData }))
          )
      ),
      tap(({ partsData, searchCriteria }) => {
        this.loading = false;

        const source = SourceLocationType[SourceLocationType.PartListResult];
        this.trackerService.trackPartSearch(partsData.parts, searchCriteria.partSearchTerm, source);
      }),
      shareReplay(1)
    );

  public part: Part | string;
  private onChange: OnChange<Part | string> = (newValue: Part | string) => { };
  private onTouched: OnTouched = () => { };
  public disabled: boolean = false;

  constructor(
    private partService: PartService,
    private toastService: ToastService,
    private trackerService: TrackerService
  ) { }

  ngOnInit() {
    if(this.displayType === 'back-counter-tool'){
      this.loading = false;
    }
    this.searchSubject.next({
      partSearchTerm: this.initialSearchTerm,
      branchCode: this.branch.code,
      customerNumber: this.customer.customerNumber
    });
  }

  updateSearchTerm(searchTerm: string) {
    this.onTouched();
    this.searchSubject.next({ partSearchTerm: searchTerm, pageNumber: 1, refinements: [] });
  }

  updatePage(newPage: number) {
    this.onTouched();
    this.searchSubject.next({ pageNumber: newPage });
  }

  updateRefinements(refinements: Refinement[]) {
    this.onTouched();
    this.searchSubject.next({ refinements, pageNumber: 1 });
  }

  selectPart(part: Part | string) {
    if (
      this.part && part
      && typeof this.part !== 'string' && typeof part !== 'string'
      && this.part.partId === part.partId
    ) {
      this.part = null;
    }
    else {
      this.part = part;
    }
    this.onChange(this.part);
    this.onTouched();
  }

  writeValue(obj: Part): void {
    this.part = obj;
  }

  registerOnChange(fn: OnChange<Part | string>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouched): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
