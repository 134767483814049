import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import * as PartCardActions from 'app/_components/part-card-add-to-cart/part-card-add-to-cart.component.actions';
import { LOYALTY_PROGRAMS } from "app/app.constants";
import { FeaturedPart } from "entities/featured-part";
import { LoyaltyAccount, LoyaltyProgram } from "entities/loyalty-account";
import { isCashCustomer } from "helpers/is-cash-customer";
import { SelectLoyaltyAccountsModalComponent } from "modals/select-loyalty-accounts-modal/select-loyalty-accounts-modal.component";
import { EMPTY, combineLatest, of } from "rxjs";
import { catchError, filter, finalize, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { LoaderService } from "services/loader.service";
import { LoyaltyService } from "services/loyalty.service";
import { ModalService } from "services/modal.service";
import { PartService } from "services/part.service";
import { AppState } from "store/app-state";
import * as BranchActions from 'store/branch/branch.actions';
import * as BranchSelectors from 'store/branch/branch.selectors';
import * as CartActions from "store/cart/cart.actions";
import * as ConfigurationSelectors from 'store/configuration/configuration.selectors';
import * as CustomerActions from 'store/customer/customer.actions';
import * as CustomerSelectors from 'store/customer/customer.selectors';
import * as FeatureFlagSelectors from 'store/feature-flags/feature-flags.selectors';
import * as FeaturedPartsActions from 'store/featured-parts/featured-parts.actions';
import * as FeaturedPartsSelectors from 'store/featured-parts/featured-parts.selectors';
import * as HomeActions from "store/home/home-page.actions";
import * as CartSelectors from '../cart/cart.selectors';
import * as LoyaltyActions from './loyalty.actions';
import * as LoyaltySelectors from './loyalty.selectors';

@Injectable()
export class LoyaltyEffects {

  getLoyaltyDiscounts$ =
    createEffect(() =>
      this.action$
        .pipe(
          ofType(
            LoyaltyActions.getLoyaltyDiscountsWithPromoParts
          ),
          tap(() => {
            if(!this.router.url.includes("parts")){
              this.loaderService.loading = true;
            }
          }),
          withLatestFrom(
            this.store.select(LoyaltySelectors.loyaltyProfile),
            this.store.select(CartSelectors.selectCartItems),
            this.store.select(BranchSelectors.selectedBranch),
            this.store.select(FeaturedPartsSelectors.selectFeaturedParts)
          ),
          switchMap(([_ ,loyaltyAccounts, cartItems, branch, featuredParts]) => {
            if(loyaltyAccounts.account){
              return this.loyaltyService.getDiscounts({
                memberId: loyaltyAccounts.account.loyaltyAccountIdentifier,
                partNumbers: cartItems.map(x => x.partNumber).concat(featuredParts.map(x => x.number)),
                branchCode: branch.code,
                loyaltyProgram: loyaltyAccounts.account.loyaltyProgram
              }).pipe(
                tap(() => this.loaderService.loading = false),
                map(discounts => LoyaltyActions.loadLoyaltyDiscountsSuccess({ discounts })),
                catchError(error => of(FeaturedPartsActions.getFeaturedPartsFailure({error})))
              )
            }
            this.loaderService.loading = false;
            return EMPTY;
          })
        )
    );

  getLoyaltyDiscountsWithFeaturedParts$ =
    createEffect(() =>
      this.action$
        .pipe(
          ofType(
            LoyaltyActions.getLoyaltyDiscounts,
            PartCardActions.addCartItemSuccess
          ),
          withLatestFrom(
            this.store.select(BranchSelectors.selectedBranch),
            this.store.select(CustomerSelectors.selectedCustomer),
            this.store.select(FeaturedPartsSelectors.featurePartsCheckedForCurrentBranchAndCustomer),
            this.store.select(LoyaltySelectors.hasLoyaltyAccountSelected)
          ),
          filter(([_, branch, customer, featurePartsChecked, hasLoyalty]) => hasLoyalty),
          switchMap(([_, branch, customer, featurePartsChecked]) => {
            if (!featurePartsChecked && branch && customer) {
              return this.partService.getFeaturedParts(branch?.code, customer?.customerNumber?.padStart(10, '0'))
              .pipe(
                switchMap(
                  (featuredParts: FeaturedPart[]) => [
                  FeaturedPartsActions.getFeaturedPartsSuccess({ featuredParts }),
                  LoyaltyActions.getLoyaltyDiscountsWithPromoParts()
                ]),
                catchError(error => of(FeaturedPartsActions.getFeaturedPartsFailure({error}))))
            } else {
              return of(LoyaltyActions.getLoyaltyDiscountsWithPromoParts());
            }
          })
        )
  );

  getLoyaltyDiscountsForCartItems$ =
    createEffect(() =>
      this.action$
        .pipe(
          ofType(
            CartActions.selectCartSuccess,
            CartActions.addItemToCartSuccess,
            CartActions.removeItemsFromCartSuccess,
            CartActions.deleteCartSuccess,
            CartActions.updatePONumberSuccess,
            CartActions.updateUnitNumberSuccess,
            CartActions.updateAlternateEmailSuccess,
            CartActions.updateBillToSuccess,
            CartActions.updateShipToSuccess,
            CartActions.setDeliverySuccess,
            CartActions.updateSpecialInstructionSuccess,
            CartActions.updateCartItemSuccess,
            CartActions.confirmPartsBuyoutSuccess,
            CartActions.addStoItemToCartSuccess,
            CartActions.updateHotFlagOnCartItemSuccess,
            LoyaltyActions.selectLoyaltyAccountSuccess,
            LoyaltyActions.setLoyaltyDiscounts
          ),
          withLatestFrom(
            this.store.select(CartSelectors.selectCartBranchCode),
            this.store.select(LoyaltySelectors.loyaltyAccount),
            this.store.select(CartSelectors.selectCartPartItems)
          ),
          filter(([_, branchCode, loyaltyAccount, cartItems]) => loyaltyAccount?.loyaltyAccountIdentifier != null && cartItems.length > 0),
          switchMap(([_, branchCode, loyaltyAccount, cartItems]) =>
              {
                return this.loyaltyService.getDiscounts({
                  memberId: loyaltyAccount.loyaltyAccountIdentifier,
                  partNumbers: cartItems.map(x => x.partNumber),
                  branchCode,
                  loyaltyProgram: loyaltyAccount.loyaltyProgram
                }).pipe(
                  map(discounts => LoyaltyActions.loadLoyaltyDiscountsSuccess({ discounts })),
                  catchError(error => of(FeaturedPartsActions.getFeaturedPartsFailure({error})))
                )
            }
          )
        )
  );

  linkLoyaltyAccount$ = createEffect(() =>
    this.action$.pipe(
      ofType(LoyaltyActions.linkLoyaltyAccount),
      withLatestFrom(this.store.select(CustomerSelectors.selectedCustomer)),
      filter(([_, customer]) => customer?.customerNumber !== null),
      switchMap(([{ loyaltyAccount }, customer]) => {
        if(!isCashCustomer(customer.customerNumber)){
          return this.loyaltyService.linkCustomerLoyaltyProgramAccount({
            customerSapCode: customer.customerNumber,
            loyaltyAccountIdentifier: loyaltyAccount.memberId,
            loyaltyProgramCode: loyaltyAccount.loyaltyProgram
          }).pipe(map((success) => {
            if (success) {
              return loyaltyAccount;
            }
            return null;
          }));
        } else {
          return of(loyaltyAccount);
        }
      }),
      map((loyaltyAccount) => LoyaltyActions.selectLoyaltyAccountSuccess({loyaltyAccount})),
      finalize(() =>  {}),
      tap(() => {this.store.dispatch(LoyaltyActions.linkLoyaltyAccountSuccess())})
    ));

  selectAccounts$ = createEffect(() =>
    this.action$
      .pipe(
        ofType(LoyaltyActions.openLoyaltyAccountModal),
        withLatestFrom(
          this.store.select(BranchSelectors.selectedBranch),
          this.store.select(CustomerSelectors.selectedCustomer),
        ),
        map(([_, branch, customer]) => {

          const loyaltyProgram: LoyaltyProgram = 'peterbilt';
          return {
            branchCode: branch?.code,
            customerNumber: customer?.customerNumber,
            loyaltyAccounts: null,
            loyaltyProgram
          }
        }),
        switchMap((modalInputs) => this.ngbModalService.open(SelectLoyaltyAccountsModalComponent, modalInputs, { size: 'lg' })
          .pipe(
            map((selectedAccount: LoyaltyAccount) => LoyaltyActions.linkLoyaltyAccount({ loyaltyAccount: selectedAccount })))
        )
      )
  );

  getAvailableAccounts$ = createEffect(() =>
    combineLatest([
      this.store.select(CustomerSelectors.selectedCustomer).pipe(map(x => x.customerNumber)),
      this.store.select(BranchSelectors.selectedBranch).pipe(map(x => x.code))
    ]).pipe(
      filter(([customerNumber, branchCode]) => customerNumber != null && branchCode != null),
      switchMap(([customerNumber, branchCode]) => this.loyaltyService.searchLoyaltyProgramAccounts({
        branchCode: branchCode,
        companyName: null,
        customerSapCode: customerNumber,
        emailAddress: null,
        loyaltyAccountIdentifier: null,
        loyaltyProgram: 'peterbilt',
        phone: null,
        postalCode: null,
        userId: null
      }).pipe(
        map(loyaltyAccounts => LoyaltyActions.getAvailableLoyaltyAccountsSuccess({loyaltyAccounts})),
        catchError(error => of(LoyaltyActions.getAvailableLoyaltyAccountsFailed({error})))
      ))
    ));

  setLoyaltyAccountIfExists$ = createEffect(() => this.store.select(LoyaltySelectors.loyaltyAccounts).pipe(
    filter((accounts) => accounts.length > 0),
    map((accounts) =>
      LoyaltyActions.linkLoyaltyAccount({ loyaltyAccount: accounts[0] })
    )
  ));

  clearLoyaltyWhenInNonPaccarBranch$ = createEffect(() => this.action$.pipe(
    ofType(BranchActions.selectBranch),
    withLatestFrom(this.store.select(FeatureFlagSelectors.isFeatureActive('Loyalty'))),
    filter(([{branch}, isInLoyaltyEnabled]) => {
      return !isInLoyaltyEnabled || !this.loyaltyPrograms.some((loyaltyProgram) => branch.brands.includes(loyaltyProgram));
    }),
    tap(() => {
      return this.store.dispatch(LoyaltyActions.clearLoyaltyAccount());
    })
  ), {dispatch: false});

  clearLoyaltyAccount$ = createEffect(() =>
    this.action$.pipe(
      ofType(
        CustomerActions.setDefaultCustomer,
        CustomerActions.findCustomersSuccess,
        CustomerActions.clearCustomer,
        CartActions.submitOrderSuccess,
        HomeActions.loaded
      ),
      withLatestFrom(
        this.store.select(LoyaltySelectors.hasLoyaltyAccountSelected),
        this.store.select(ConfigurationSelectors.isInternal)
      ),
      filter(([_, hasLoyaltyAccountSelected, isInternal]) => hasLoyaltyAccountSelected && isInternal),
      map(() => LoyaltyActions.clearLoyaltyAccount())
    ));

  clearCache$ = createEffect(() =>
    this.action$.pipe(
      ofType(LoyaltyActions.clearLoyaltyAccount),
      tap(() => localStorage.removeItem('loyaltyDataKey'))
    ),
    {dispatch: false}
  );

  constructor(
    private router: Router,
    private action$: Actions,
    private store: Store<AppState>,
    private loyaltyService: LoyaltyService,
    private partService: PartService,
    private ngbModalService: ModalService,
    private loaderService: LoaderService,
    @Inject(LOYALTY_PROGRAMS) private loyaltyPrograms: LoyaltyProgram[],

  ) { }

}
