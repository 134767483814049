import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { Branch } from 'entities/branch';
import { Customer } from 'entities/customer';
import { LoyaltyAccount } from 'entities/loyalty-account';
import { ModalService } from 'services/modal.service';
import { AppState } from 'store/app-state';

interface LoyaltyAccountsComponentState {
  branch: Branch;
  customer: Customer;
  inputValue: LoyaltyAccount;
  value: LoyaltyAccount;
  defaultCustomer: Customer;
}

@Injectable()
export class LoyaltyAccountsComponentStore extends ComponentStore<LoyaltyAccountsComponentState> {
  constructor(
    private ngbModalService: ModalService,
    private store: Store<AppState>
  ) {
    super({
      branch: null,
      customer: null,
      inputValue: null,
      value: null,
      defaultCustomer: null
    });
  }

  public setBranch = this.updater((state, branch: Branch) => ({
    ...state,
    branch,
    value: branch?.code !== state.branch?.code
      ? null
      : state.value
  }));

  public setCustomer = this.updater((state, customer: Customer) => ({
    ...state,
    customer,
    value: customer?.customerNumber !== state.customer?.customerNumber
      ? null
      : state.value
  }));

  public writeValue = this.updater((state, loyaltyAccounts: any) => ({
    ...state,
    inputValue: loyaltyAccounts,
    value: loyaltyAccounts
  }));

  public setDefaultCustomer = this.updater((state, defaultCustomer: Customer) => ({
    ...state,
    defaultCustomer
  }));


  public inputAndLatest$ = this.select(({ inputValue, value }) => ({ inputValue, latestValue: value }));
}
