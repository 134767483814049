import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FeaturedOpportunityType, FeaturedPart } from 'entities/featured-part';
import { Part } from 'entities/part';
import { ToastType } from 'entities/toast-type';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ConfigurationService } from 'services/configuration.service';
import { FavoritePartsService } from 'services/favorite-parts.service';
import { SourceLocationType } from 'services/logger.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'part-card-header',
  templateUrl: './part-card-header.component.html'
})
export class PartCardHeaderComponent implements OnInit, OnDestroy {
  @Input() idSuffix: string = "";
  @Input() partType: SourceLocationType;
  @Input() part: Part;
  @Output() partChange = new EventEmitter<Part>();
  isFavoritePart: boolean;
  public showCopyIcon: boolean;
  public isGrowth$: Observable<boolean>;
  public isRecovery$: Observable<boolean>;

  removePartFromFavoritesSubject = new Subject<Part>();
  addToFavoritePartsSubject = new Subject<Part>();

  removePartFromFavorites$ = this.removePartFromFavoritesSubject.pipe(
    switchMap(part => this.favoritePartService.removePartFromFavorites(part))
  );

  addToFavoriteParts$ = this.addToFavoritePartsSubject.pipe(
    switchMap(part => this.favoritePartService.addPartToFavorites(part))
  );

  subscriptions: Subscription = merge(this.removePartFromFavorites$, this.addToFavoriteParts$).subscribe();

  constructor(
    private toastService: ToastService,
    private favoritePartService: FavoritePartsService,
    private configurationService: ConfigurationService
  ) { }

  ngOnInit() {
    this.showCopyIcon = this.part && this.part.rushPartNumber && this.part.rushPartNumber.length < 16;

    const featuredProduct = <FeaturedPart>this.part;

    this.isGrowth$ = this.configurationService.user$.pipe(
      map((user) => {
        return featuredProduct && user.isInternal && featuredProduct.opportunityType === FeaturedOpportunityType.growth;
      }));

    this.isRecovery$ = this.configurationService.user$.pipe(
      map((user) => {
        return featuredProduct && user.isInternal && featuredProduct.opportunityType === FeaturedOpportunityType.recovery;
      }));
      this.isFavoritePart = this.part != null ? this.part.isFavoritePart : false;
  }

  copyToClipBoard(contentToCopy: string) {
    window.navigator['clipboard'].writeText(contentToCopy);
    this.toastService.showToast(`${contentToCopy} copied to clipboard.`, ToastType.Success);
  }

  removeFavoritePart(part: Part) {
    this.removePartFromFavoritesSubject.next(part);
    this.part.isFavoritePart = false;
    this.isFavoritePart = false;
    this.partChange.emit(part);
  }

  addToFavoriteParts(part: Part) {
    this.addToFavoritePartsSubject.next(part);
    this.part.isFavoritePart = true;
    this.isFavoritePart = true;
    this.partChange.emit(part);
  }

  ngOnDestroy() {
    this.subscriptions?.unsubscribe();
  }
}
