
import { NgModule } from '@angular/core';
import { NgbActiveModal, NgbScrollSpyModule } from '@ng-bootstrap/ng-bootstrap';
import { CreatePartComponent } from 'app/_components/create-part/create-part.component';
import { ImageFeedbackComponent } from 'app/_components/image-feedback/image-feedback.component';
import { PartBaseComponent } from 'app/_components/part-base/part-base.component';
import { PartCardAddToCartComponent } from 'app/_components/part-card-add-to-cart/part-card-add-to-cart.component';
import { PartCardDescriptionComponent } from 'app/_components/part-card-description/part-card-description.component';
import { PartCardFooterComponent } from 'app/_components/part-card-footer/part-card-footer.component';
import { PartCardHeaderComponent } from 'app/_components/part-card-header/part-card-header.component';
import { PartCardItemComponent } from 'app/_components/part-card-item/part-card-item.component';
import { PartCardListComponent } from 'app/_components/part-card-list/part-card-list.component';
import { PartCardComponent } from 'app/_components/part-card/part-card.component';
import { PartDetailsComponent } from 'app/_components/part-details/part-details.component';
import { PartListComponent } from 'app/_components/part-list/part-list.component';
import { PartNotesComponent } from 'app/_components/part-notes/part-notes.component';
import { PartSearchModalComponent } from 'app/_components/part-search-modal/part-search-modal.component';
import { PartSelectComponent } from 'app/_components/part-select/part-select.component';
import { CouponsComponent } from 'app/_components/pricing/coupons.component';
import { PricingComponent } from 'app/_components/pricing/pricing.component';
import { SharedModule } from 'app/_modules/shared.module';
import { PartRouteModule } from './part-route.module';

@NgModule({
  imports: [
    SharedModule,
    PartRouteModule,
    NgbScrollSpyModule
  ],
  declarations: [
    PricingComponent,
    CouponsComponent,
    CreatePartComponent,
    PartBaseComponent,
    PartDetailsComponent,
    PartListComponent,
    PartNotesComponent,
    PartCardAddToCartComponent,
    PartCardComponent,
    PartCardDescriptionComponent,
    PartCardFooterComponent,
    PartCardHeaderComponent,
    PartCardItemComponent,
    PartCardListComponent,
    PartSearchModalComponent,
    PartSelectComponent,
    ImageFeedbackComponent
  ],
  providers: [NgbActiveModal],
  exports: [
    PartCardAddToCartComponent,
    PartCardComponent,
    PartCardDescriptionComponent,
    PartCardFooterComponent,
    PartCardHeaderComponent,
    PartCardItemComponent,
    PartCardListComponent,
    PricingComponent
  ]
})
export class PartModule { }
