<button id="PriceVerifyModal_Button_Close" aria-label="Close" class="close" data-dismiss="modal" tabindex="-1" type="button" (click)="closeModal()">
  <span aria-hidden="true">&times;</span>
</button>
<div class="modal-body">
  <div class='price-verify-msc'>
    <b>Verify or edit the account number below.</b>
    <form style="text-align:left; width:50%; margin:10px auto;" *ngLet="{selectedCustomerNumber: selectedCustomerNumber$ | async} as vm">
      <div class="form-group">
        <label for="PriceVerifyModal_Text_MSCCardNumber">MSC/FC Card Number</label>
        <input id="PriceVerifyModal_Text_MSCCardNumber" class="form-control" name="PriceVerifyModal_Text_MSCCardNumber" #txtmscCardNo [(ngModel)]="payer.mscCardNumber">
      </div>
      <button id="PriceVerifyModal_Button_Continue" type="submit" class="btn btn-default yellow-btn" style="width:100%" (click)="mscPriceVerification(vm.selectedCustomerNumber)"
        [disabled]="loading">Continue</button>
    </form>
  </div>
</div>
