import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Branch } from 'entities/branch';
import { Pager } from 'entities/pager';
import { sortData } from 'helpers/sortData';
import { TableService } from 'services/table.service';
import { CreatePartModalComponentService } from './create-part-modal.component.service';
import { PriceTapeResult } from 'entities/parts/create-extend-part-result';

@Component({
  selector: 'create-part-modal',
  templateUrl: './create-part-modal.component.html',
  providers: [ CreatePartModalComponentService ]
})
export class CreatePartModalComponent implements OnInit, OnDestroy {
  @Input() multiplePartsData: PriceTapeResult[] = []
  @Input() branch: Branch;
  pager: Pager = {} as Pager;
  // paged items
  createPartResults: PriceTapeResult[];
  filteredMultipleParts: PriceTapeResult[];
  filterKey: string = '';
  sortBy: string = '';
  sortAsc: boolean = false;

  private extendPartSubject: Subject<PriceTapeResult> = new Subject();

  private subscription: Subscription;

  constructor(
    private service: CreatePartModalComponentService,
    private pagerService: TableService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    const {
      extendPartComplete$
    } = this.service.getSelectors(this.extendPartSubject, this.branch);
    this.subscription = extendPartComplete$
      .pipe(
        tap((externalPartNumber) => {
          this.activeModal.close(externalPartNumber);
        })
      )
      .subscribe();

    this.filteredMultipleParts = this.multiplePartsData;
    this.setPage(1);
  }

  setPage(page: number) {
    if (page < 1 || (page > this.pager.totalPages && this.pager.totalPages > 0)) {
      return;
    }
    // get pager object from service
    this.pager = this.pagerService.getPager(this.filteredMultipleParts.length, page, 6);

    // get current page of items
    this.createPartResults = this.filteredMultipleParts.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  filter = function () {
    if (this.filterKey != '') {
      this.filteredMultipleParts = this.multiplePartsData.filter(function (e) {
        return ((e.materialNumber && e.materialNumber.toLowerCase().indexOf(this.filterKey.toLowerCase()) > -1) ||
          (e.materialDescription && ('' + e.materialDescription).toLowerCase().indexOf(this.filterKey.toLowerCase()) > -1) ||
          (e.manufacturerNumber && e.manufacturerNumber.toLowerCase().indexOf(this.filterKey.toLowerCase()) > -1) ||
          (e.priceTapeIndicator && e.priceTapeIndicator.toLowerCase().indexOf(this.filterKey.toLowerCase()) > -1) ||
          (e.supersededPartNo && e.supersededPartNo.toLowerCase().indexOf(this.filterKey.toLowerCase()) > -1) ||
          (e.informationText && ('' + e.informationText).toLowerCase().indexOf(this.filterKey.toLowerCase()) > -1));

      }.bind(this));

      this.setPage(1);
    } else {
      this.filteredMultipleParts = this.multiplePartsData;
      this.setPage(1);
    }
  };

  sortDataBy(sortBy: string) {
    if (this.sortBy == sortBy) {
      this.sortAsc = !this.sortAsc;
    } else {
      this.sortAsc = true;
    }

    this.sortBy = sortBy;

    this.filteredMultipleParts = sortData(this.filteredMultipleParts, this.sortBy, this.sortAsc);

    if (this.pager.pages && this.pager.pages.length) {
      this.setPage(this.pager.currentPage);
    } else {
      this.setPage(1);
    }
  }

  onPartNoSelect(priceTapeResult: PriceTapeResult): void {
    this.extendPartSubject.next(priceTapeResult);
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
    this.extendPartSubject.complete();
  }
}
