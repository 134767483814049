import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ViewConfigurationService {

  private fullSizeWidth: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public fullSizeWidth$: Observable<boolean> = this.fullSizeWidth.asObservable();

  public setFullSizeWidth(isFullSize: boolean): void {
    this.fullSizeWidth.next(isFullSize);
  }

}
