import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import * as MyDashboardActions from 'store/my-dashboard/my-dashboard.actions';
import { ToastService } from "services/toast.service";
import { MyDashboardService } from "services/my-dashboard.service";
import { LoaderService } from "services/loader.service";
import { ToastType } from "entities/toast-type";
import { of } from "rxjs";

@Injectable()
export class MyDashboardEffects {
  unlockQuoteForOthers$ = createEffect(() =>
    this.action$.pipe(
      ofType(MyDashboardActions.unlockQuoteForOthers),
      tap(() => this.loaderService.loading = true),
      switchMap(
        (action) => this.myDashboardService.updateLockStatusForOthers(action.cartId)
          .pipe(
            map(
              () => {
                this.toastService.showToast('This quote is unlocked for others.', ToastType.Success);
                return MyDashboardActions.unlockQuoteForOthersSuccess();
              }
            ),
            catchError(error => of(MyDashboardActions.unlockQuoteForOthersFailed({ error }))),
            tap(() => this.loaderService.loading = false)
          )
      )
    )
  )

  constructor(
    private action$: Actions,
    private toastService: ToastService,
    private myDashboardService: MyDashboardService,
    private loaderService: LoaderService
  ) {}
}
