import { Injectable, OnDestroy } from "@angular/core";
import { User, UserManager, UserManagerSettings, WebStorageStateStore } from "oidc-client";
import { Observable, ReplaySubject, Subject, Subscription } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Injectable()
export class AuthenticationServiceOptions {
  settings: UserManagerSettings;
  policies?: { signInSignUp: string, editProfile?: string, resetPassword?: string, redeemInvitation?: string };
  scopes: string[];
  audiences?: {
    domain: string;
    scopes: string[];
  }[];
  postSingleLogoutRedirectUrl?: string | null;
}

@Injectable({
  providedIn: "root"
})
export class AuthenticationService implements OnDestroy {
  private userManager: UserManager;
  private audienceUserManagers: { [key: string]: UserManager };
  private audienceScopes: { [key: string]: string[] };
  private getSigninSettings: (domain?: string) => { response_type: string, scope: string, extraQueryParams?: { [key: string]: string } };
  private userLoadSubject: Subject<User> = new ReplaySubject(1);
  public userProfile$: Observable<any> = this.userLoadSubject
    .pipe(
      map((user) => user && user.profile)
    );
  public accessToken$: Observable<string> = this.userLoadSubject
      .pipe(
        map((user) => user && user.access_token)
      );
  private accessTokenExpiringSubject: Subject<void> = new Subject();
  private subscription: Subscription;
  private readonly USER_LOADED_TOKEN = "rush.auth.userLoadedDate";
  private readonly RENEW_TOKEN = "rush.auth.renewTokenDate";
  private readonly TOKEN_INVALIDATED = "rush.auth.tokeninvalidated";

  constructor(
    private options: AuthenticationServiceOptions
  ) {
    var userManagerSettings: UserManagerSettings = { ...this.options.settings };
    delete userManagerSettings.authority;
    this.userManager = new UserManager(this.options.settings);

    this.audienceUserManagers = this.options.audiences && this.options.audiences
      .reduce<{ [key: string]: UserManager }>((managers, audience) => {
        const store = new WebStorageStateStore({ prefix: `oidc.${audience.domain}.`, store: sessionStorage});
        const userManager = new UserManager({ ...this.options.settings, userStore: store });

        managers[audience.domain] = userManager;
        return managers;
      }, {});

    this.audienceScopes = this.options.audiences && this.options.audiences
      .reduce<{ [key: string]: string[] }>((scopes, audience) => {
        scopes[audience.domain] = audience.scopes;
        return scopes;
      }, {});

    this.getSigninSettings = (domain?: string) => {
      const scopes = domain && this.audienceScopes[domain] || this.options.scopes;
      return {
        response_type: "id_token token",
        scope: scopes.concat("openid", "profile").join(" ")
      };
    }

    this.userManager.events.addUserLoaded((user: User) => {
      sessionStorage.setItem(this.USER_LOADED_TOKEN, JSON.stringify(new Date()));
      this.userLoadSubject.next(user);
    });

    this.userManager.events.addAccessTokenExpiring(() => {
      this.accessTokenExpiringSubject.next();
    });

    const expiringObs = this.accessTokenExpiringSubject
      .pipe(
        // If renew fired more recently than user load, then silently renew the token (which triggers a new user load).
        // If user load fired more recently than renew, then logout.
        // Use localStorage so it works across browser windows/tabs
        map(() => {
          const userLoadedDate: Date = JSON.parse(sessionStorage.getItem(this.USER_LOADED_TOKEN));
          const renewTokenDate: Date = JSON.parse(localStorage.getItem(this.RENEW_TOKEN));
          return renewTokenDate > userLoadedDate;
        }),
        switchMap((renewToken) => {
          if (renewToken) {
            return this.silent(true);
          }
          else {
            return this.logout();
          }
        })
      );

    this.subscription = expiringObs.subscribe();
  }

  public initialize(): Promise<User> {
    // Register listener that clears any existing token
    // This is triggered from logout_silent.html
    window.addEventListener("storage", (event) => {
      if (event.key !== "rush.auth.logout") {
        return;
      }

      this.removeUser()
        .then(() => {
          this.logout(false);
        });
    });

    // Set when a policy other than sign-in/sign-up is completed
    var tokenInvalidated: boolean = JSON.parse(sessionStorage.getItem(this.TOKEN_INVALIDATED));

    return this.userManager.getUser()
      .then((user) => {
        if (!user || user.expired || tokenInvalidated) {
          // Check for existing session from authority
          return this.silent();
        }
        // Initialize with cached user
        this.userManager.events.load(user);
        return user;
      })
      .then((user) => {
        if (!user) {
          // Initialize with null
          this.userLoadSubject.next(null);
        }
        return user;
      });
  }

  public login(): Promise<any> {
    // Login policy has a link to password reset that needs to bounce through the application
    if (this.options.policies && this.options.policies.resetPassword) {
      localStorage.setItem("rush.auth.signinsettings", JSON.stringify(this.getSigninSettings()));
      const passwordAuthority = this.options.settings.authority.replace(this.options.policies.signInSignUp, this.options.policies.resetPassword);
      var passwordSettings = { ...this.options.settings, authority: passwordAuthority };
      localStorage.setItem("rush.auth.passwordsettings", JSON.stringify(passwordSettings));
    }
    return this.signinRedirect();
  }

  public editProfile(): Promise<any> {
    if (!this.options.policies || !this.options.policies.editProfile) {
      throw "Edit Profile policy was not defined in authentication module settings";
    }
    return this.signinRedirect(this.options.policies.editProfile);
  }

  public resetPassword(): Promise<any> {
    if (!this.options.policies || !this.options.policies.resetPassword) {
      throw "Reset Password policy was not defined in authentication module settings";
    }
    return this.signinRedirect(this.options.policies.resetPassword);
  }

  public redeemInvitation(token: string): Promise<any> {
    if (!this.options.policies || !this.options.policies.redeemInvitation) {
      throw "Redeem Invitation policy was not defined in authentication module settings";
    }
    return this.signinRedirect(this.options.policies.redeemInvitation, window.location.origin, token);
  }

  private signinRedirect(policy?: string, redirectUrl?: string, clientAssertionToken?: string) {
    // Referenced in signin_redirect.html
    localStorage.setItem("rush.auth.redirecturl", redirectUrl || window.location.href);

    var userManager: UserManager;
    if (policy) {
      const authority = this.options.settings.authority.replace(this.options.policies.signInSignUp, policy);
      var userManagerSettings = { ...this.options.settings };
      userManagerSettings.authority = authority;
      userManager = new UserManager(userManagerSettings);
    }
    else {
      userManager = this.userManager;
    }

    var signinSettings = this.getSigninSettings();
    if (clientAssertionToken) {
      signinSettings.extraQueryParams = {
        "client_assertion_type": "urn:ietf:params:oauth:client-assertion-type:jwt-bearer",
        "client_assertion": clientAssertionToken
      };
    }

    return userManager.signinRedirect(signinSettings);
  }

  private silentPromise: Promise<User> = Promise.resolve(null);
  public silent(): Promise<User>;
  public silent(logoutOnUnauthenticated: boolean): Promise<User>;
  public silent(domain: string): Promise<User>;
  public silent(booleanOrString?: boolean | string) {
    const logoutOnUnauthenticated = typeof booleanOrString === "boolean" && booleanOrString || false;
    const domain = typeof booleanOrString === "string" && booleanOrString || null;

    let args: any = {
      ...this.getSigninSettings(domain),
      silentRequestTimeout: 8000
    };

    const userManager = domain
      ? this.audienceUserManagers[domain]
      : this.userManager;

    // Wait until previous silent promises complete
    // Simultaneous silent promises can fail because of overwritten state / nonce values
    this.silentPromise = this.silentPromise
      .then(() => userManager.signinSilent(args))
      .catch((reason) => {
        if (logoutOnUnauthenticated) {
          this.logout();
        }
        return null;
      });

    return this.silentPromise;
  }

  public getAudienceToken(domain: string): Promise<string>
  {
    return this.audienceUserManagers[domain].getUser()
      .then((user) => {
        if (!user || user.expired) {
          // Check for existing session from authority
          return this.silent(domain);
        }
        return user;
      })
      .then((user) => {
        return user && user.access_token
      });
  }

  private get postSingleLogoutRedirectUrl() {
    return this.options.postSingleLogoutRedirectUrl || window.location.href;
  }

  public logout(propogateLogout: boolean = true): Promise<any> {
    sessionStorage.setItem("rush.auth.propogateLogout", JSON.stringify(propogateLogout));
    sessionStorage.setItem("rush.auth.postSingleLogoutRedirectUrl", this.postSingleLogoutRedirectUrl);

    const removeAudienceUsers = this.audienceUserManagers && Object.values(this.audienceUserManagers)
      .map((audienceUserManager) => audienceUserManager.removeUser())
      || [];

    return Promise.all(removeAudienceUsers)
      .then(() => this.userManager.signoutRedirect());
  }

  public removeUser(): Promise<void> {
    return this.userManager.removeUser();
  }

  /**
   * Queues token renewal attempt on expiration of the current token.
   * This should be called on meaningful user activity to keep the session alive, e.g. on route event.
   */
  public renewOnExpiring() {
    localStorage.setItem("rush.auth.renewTokenDate", JSON.stringify(new Date()));
  }

  public ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
}