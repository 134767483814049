import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Payer } from 'entities/customer-search';

@Component({
  selector: 'msc-payers-modal',
  templateUrl: './msc-payers-modal.component.html'
})
export class MscPayersModalComponent {
  @Input() mscCardNumber: string
  @Input() payerOptions: Payer[];
  @Input() payer: Payer;
  
  constructor(
    private activeModal: NgbActiveModal
  ) { }

  closeModal(): void {
   this.activeModal.dismiss();
  }
  
  setPayer(): void {
    this.activeModal.close({
      payerNumber: this.payer && this.payer.payerNumber || '',
      mscCardNumber: this.mscCardNumber
    });
  }
}
