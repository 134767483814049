import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";
import { FeatureFlagService } from "services/feature-flag.service";

@Directive({
  selector: '[autofocus]',
  exportAs: 'autofocusDirective'
})
export class AutofocusDirective implements AfterViewInit {

  @Input() autoselectOnFocus: boolean = true;

  constructor(
    private elementRef: ElementRef,
    private featureFlagService: FeatureFlagService,
  ) { }

  ngAfterViewInit() {
    this.focus();
  }

  public focus() {

    const el = this.elementRef?.nativeElement;
    if (!el) {
      return;
    }

    el.focus();

    if (this.autoselectOnFocus
      && (el instanceof HTMLInputElement
        || el instanceof HTMLTextAreaElement)) {
      el.select();
    }
  }
}
