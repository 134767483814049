import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Cart } from 'entities/carts/cart';
import { CurrencyCode } from 'entities/currency-code';
import { Observable } from 'rxjs/internal/Observable';
import { AppState } from 'store/app-state';
import * as BranchSelectors from "store/branch/branch.selectors";

@Component({
  selector: 'cart-card',
  templateUrl: './cart-card.component.html'
})
export class CartCardComponent {

  @Input() cart: Cart;
  @Input() canDelete: boolean = true;
  @Input() isSelected: boolean = false;
  @Input() id: string;
  @Output() isSelectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() deleteCart: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  public currencyCode$: Observable<CurrencyCode> = this.store.select(BranchSelectors.selectCurrencyCode);

  constructor(
    private store: Store<AppState>
  ) { }

  onSelectCart() {
    this.isSelected = true;
    this.isSelectedChange.emit(this.isSelected);
  }

  onDeleteCart(event: MouseEvent) {
    event.stopPropagation();
    this.deleteCart.emit(event);
  }

}
