import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customerNumber'
})
export class CustomerNumberPipe implements PipeTransform {
  transform(value: string, args?: string[]): any {
    let _pattern = /^[0-9]+$/;
    if (value == undefined || value == null || value == '') {
      return '';
    } else if (_pattern.test(value)) {
      return parseInt(value, 10); // convert to int
    } else {
      return value;
    }
  }
}
