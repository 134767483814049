import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { sortData } from 'helpers/sortData';
import { CommonDataService } from 'services/common-data.service';
import { LoggerService } from 'services/logger.service';
import { AppInsightCustomerSearch } from 'entities/app-insight-customer-search';
import { TableService } from 'services/table.service';
import { Customer } from 'entities/customer';
import { ShipToCustomer } from 'entities/ship-to-customer';
import { Store } from '@ngrx/store';
import { AppState } from 'store/app-state';
@Component({
  selector: 'shipping-address-modal',
  templateUrl: './shipping-address-modal.component.html'
})
export class ShippingAddressModalComponent implements OnInit {
  @Input() source: string;
  @Input() customers: Customer[];
  @Input() customerSearchTerm: any;
  // pager object
  pager: any = {};
  // paged items
  pagedAdvancedCustomers: any[];
  filteredAdvancedCustomers: any[];
  filterKey: string = '';
  sortBy: string = '';
  sortAsc: boolean = false;
  selectedCustomer: Customer | ShipToCustomer = null;
  selectedAddressNumber: string = null;
  constructor(
    private pagerService: TableService,
    private commonDataService: CommonDataService,
    private loggerService: LoggerService,
    private activatedRoute: ActivatedRoute,
    private activeModal: NgbActiveModal,
    private store: Store<AppState>  ) { }
  ngOnInit() {
    this.filteredAdvancedCustomers = this.customers.filter((c) => !c.addressNumber.startsWith('CASH-') );
    this.selectedCustomer = this.customers.find((customer: Customer) => customer.isSelected) || null;
    this.selectedAddressNumber = this.selectedCustomer?.addressNumber || null;
    this.setPage(1);
  }
  setPage(page: number) {
    if (page < 1 || (page > this.pager.totalPages && this.pager.totalPages > 0)) {
      return;
    }
    // get pager object from service
    this.pager = this.pagerService.getPager(this.filteredAdvancedCustomers.length, page, 6);
    // get current page of items
    this.pagedAdvancedCustomers = this.filteredAdvancedCustomers.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }
  filter() {
    if (this.filterKey) {
      const filterKeyLowerCase = this.filterKey.toLowerCase();
      this.filteredAdvancedCustomers = this.customers.filter((e) => this.getFilteredByProps([
        e.customerName,
        e.customerName2,
        e.customerNumber,
        e.streetAddress,
        e.city,
        e.state,
        e.postalCode,
        e.phoneNumber,
        e.orderCount,
        e.accountManagerName,
        e.accountManager,
        e.mscAccountNumber
      ], filterKeyLowerCase)
      );
      this.setPage(1);
    } else {
      this.filteredAdvancedCustomers = this.customers;
      this.setPage(1);
    }
  }
  getFilteredByProps(props: any[], filterKeyLowerCase: string): boolean {
    return !!props.find(prop => (prop && ('' + prop).toLowerCase().indexOf(filterKeyLowerCase) > -1));
  }
  sortDataBy(sortBy: string) {
    if (this.sortBy == sortBy) {
      this.sortAsc = !this.sortAsc;
    } else {
      this.sortAsc = true;
    }
    this.sortBy = sortBy;
    this.filteredAdvancedCustomers = sortData(this.filteredAdvancedCustomers, this.sortBy, this.sortAsc);
    if (this.pager.pages && this.pager.pages.length) {
      this.setPage(this.pager.currentPage);
    } else {
      this.setPage(1);
    }
  }
  closeModal(): void {
    this.activeModal.close();
  }
  onCustomerSelect(customer: Customer, customers: Customer[], event: Event, setEditMode: boolean = false ): void {
    if(setEditMode) {
      event.stopPropagation();
    }
    customers.forEach(c => {
      c.editMode = (setEditMode && c.addressNumber === customer.addressNumber);
    })
    this.selectedCustomer = customer;
    this.selectedAddressNumber = customer.addressNumber;
  }
  onCustomerConfirm(): void {
    if (this.customerSearchTerm) {
      const searchTerm = this.activatedRoute.snapshot.queryParams['searchTerm'];
      let sourceName = 'CustomerModalComponent_onCustomerSelect';
      let metricName = this.loggerService.getMetricValue(sourceName);
      let appInsightCustomerSearch = Object.assign(new AppInsightCustomerSearch(), {
        userId: this.commonDataService.User.id,
        branchNumber: this.commonDataService.Branch.code,
        searchTerm: '',
        customerSearchTerm: JSON.stringify(this.customerSearchTerm),
        source: this.source,
        result: JSON.stringify(this.selectedCustomer),
        customerNotFound: false,
        plMetricName: sourceName
      });
      this.loggerService.trackMetric(metricName, appInsightCustomerSearch);
    }
    this.activeModal.close(this.selectedCustomer);
  }
  onUpdate(account: ShipToCustomer): void {
    this.selectedCustomer = account;
    this.pagedAdvancedCustomers = this.pagedAdvancedCustomers.map(customer => {
      return customer.addressNumber !== account.addressNumber ? customer : {
        ...customer,
        ...account
      }
    });
    this.activeModal.close(this.selectedCustomer);
  }
}
