import { Component, OnDestroy, ChangeDetectionStrategy, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { Branch } from 'entities/branch';
import { Customer } from 'entities/customer';
import { LoyaltyAccount, LoyaltyAccounts } from 'entities/loyalty-account';
import { LoyaltyAccountsComponentStore } from './loyalty-accounts.component.store';
import * as LoyaltySelectors from 'store/loyalty/loyalty.selectors';
import { AppState } from 'store/app-state';
import { Store } from '@ngrx/store';
import { ConfigurationService } from 'services/configuration.service';
import * as LoyaltyActions from 'store/loyalty/loyalty.actions';
import * as FeatureFlagSelectors from 'store/feature-flags/feature-flags.selectors';
import { SelectedCustomer } from 'store/customer/customer.state';
import { LoyaltyStateAccount } from 'store/loyalty/loyalty.state';
import { OnChange, OnTouched } from 'entities/control-value-accessor-funcs';


@Component({
  selector: 'loyalty-accounts',
  templateUrl: './loyalty-accounts.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    LoyaltyAccountsComponentStore,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LoyaltyAccountsComponent),
      multi: true
    }
  ]
})
export class LoyaltyAccountsComponent implements ControlValueAccessor, OnDestroy {
  @Input() set branch(value: Branch) {
    this.componentStore.setBranch(value);
  }
  @Input() set customer(value: SelectedCustomer) {
    this.componentStore.setCustomer(value);
  }
  @Input() set defaultCustomer(customer: Customer) {
    this.componentStore.setDefaultCustomer(customer);
  }
  private onChange: OnChange<LoyaltyAccount> = () => { };
  private onTouched: OnTouched = () => { };
  private disabledSubject: Subject<boolean> = new BehaviorSubject(false);
  public disabled$: Observable<boolean> = this.disabledSubject.asObservable();
  public isInternal$: Observable<boolean> = this.configurationService.user$
    .pipe(
      map((user) => user.isInternal)
    );

  private subscription: Subscription = this.componentStore.inputAndLatest$
    .pipe(
      distinctUntilChanged(({ latestValue: x }, { latestValue: y }) => x === y),
      filter(({ inputValue, latestValue }) => latestValue !== inputValue),
      tap(({ latestValue }) => {
        this.onChange(latestValue);
      })
    )
    .subscribe();
  public selectedLoyaltyAccount$: Observable<LoyaltyStateAccount> = this.store.select(LoyaltySelectors.loyaltyAccount);
  loyaltyEnabled$: Observable<boolean> = this.store.select(FeatureFlagSelectors.isFeatureActive('Loyalty'));
  numberOfLoyaltyAccounts$: Observable<number> = this.store.select(LoyaltySelectors.loyaltyAccounts).pipe(
    map(x => x.length)
  );

  constructor(
    private componentStore: LoyaltyAccountsComponentStore,
    private configurationService: ConfigurationService,
    private store: Store<AppState>
  ) { }

  writeValue(obj: LoyaltyAccounts): void {
    this.componentStore.writeValue(obj);
  }
  registerOnChange(fn: OnChange<LoyaltyAccount>): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: OnTouched): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabledSubject.next(isDisabled);
  }

  selectLoyaltyAccounts(){
    this.store.dispatch(LoyaltyActions.openLoyaltyAccountModal());
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
}
