<div class="modal-body m-3">
  <div class="title-of-box-hold float-start">
    <div class="title-of-box pull-left" style="font-size: 130%;">Basket/Returns/Showroom</div>
  </div>
  <form #chipClipForm="ngForm">
    <ng-container *ngLet="{currencyCode: currencyCode$ | async} as vm">
      <table class="table table-hover table-responsive-xl table-striped">
        <thead>
          <tr class="w-100">
            <th scope="col">
              Part Number
            </th>
            <th scope="col">
              Item
            </th>
            <th scope="col">
              Unit Price
            </th>
            <th scope="col">
              Quantity
            </th>
            <th scope="col">
              Storage Type
            </th>
            <th scope="col">
              Storage Bin
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="chipClipSBRLineItems?.length > 0">
            <tr *ngFor="let lineItem of chipClipSBRLineItems; index as i;">
              <td class="text-truncate" style="max-width: 20em;" title="{{lineItem.partNumber}}">
                {{lineItem.partNumber}}
              </td>
              <td class="text-truncate" style="max-width: 85px;" title="{{lineItem.description}}">
                {{lineItem.description}}
              </td>
              <td>
                {{lineItem.price | currency: vm.currencyCode :'symbol':'1.2-2'}}
              </td>
              <td>
                {{lineItem.quantity}}
              </td>
              <td style="width: 70px;">
                <select id="storageType_{{i}}" name="storageType_{{i}}" class="form-control"
                  [(ngModel)]="lineItem.storageType" (change)="populateStorageBin(lineItem, $event.target.value);"
                  required>
                  <option *ngIf="lineItem?.storageType == 'BAS'" selected value="BAS">
                    BAS
                  </option>
                  <option *ngIf="lineItem?.storageType != 'BAS'" value="SHO">
                    SHO
                  </option>
                  <option *ngIf="lineItem?.storageType != 'BAS'" value="CLP">
                    CLP
                  </option>
                </select>
              </td>
              <td *ngIf="lineItem?.storageType == 'BAS'">
                <input id="storageBin" name="storageBin_{{i}}" [readonly]="lineItem.storageType == 'BAS'"
                  class="form-control" type="text" maxlength="10" (focusout)="setCLPBin(lineItem);"
                  [ngModel]="lineItem.storageBin | uppercase"
                  style="min-width: 5rem;" required>
              </td>
              <td *ngIf="lineItem?.storageType != 'BAS'">
                <select *ngIf="binLocationDropDown[lineItem.partNumber.toLowerCase()]?.length > 0" style="width: 91.5px;"
                  id="storageBinSelect" name="storageBinSelect_{{i}}" class="form-control"
                  (change)="setBinLocation(lineItem ,$event.target.value, i)" [(ngModel)]="lineItem.storageBin"
                  style="min-width: 7rem;" required>
                  <option *ngFor="let item of binLocationDropDown[lineItem.partNumber.toLowerCase()]"
                    [ngValue]="item.binLocation">
                    {{ item.binLocation | uppercase }}</option>
                </select>
                <input *ngIf="!binLocationDropDown[lineItem.partNumber.toLowerCase()]" id="storageBin"
                  name="storageBin_{{i}}" class="form-control" type="text" maxlength="10"
                  (focusout)="setCLPBin(lineItem);" [ngModel]="lineItem.storageBin | uppercase"
                  (ngModelChange)="upperCaseStorageBin($event, i)" style="min-width: 7rem;" required>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <ng-container *ngIf="chipClipSelfPickLineItems?.length > 0">
        <br />
        <div class="title-of-box-hold float-start">
          <div class="title-of-box pull-left" style="font-size: 130%;">Front Counter Self Pick</div>
        </div>
        <table class="table table-hover table-responsive-xl table-striped">
          <thead>
            <tr class="w-100">
              <th scope="col">
                Part Number
              </th>
              <th scope="col">
                Item
              </th>
              <th scope="col">
                Unit Price
              </th>
              <th scope="col">
                Quantity
              </th>
              <th scope="col">
                Storage Type
              </th>
              <th scope="col">
                Storage Bin
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let spLineItem of chipClipSelfPickLineItems; index as i;">
              <td class="text-truncate" style="max-width: 20em;" title="{{spLineItem.partNumber}}">
                {{spLineItem.partNumber}}
              </td>
              <td class="text-truncate" style="max-width: 85px;" title="{{spLineItem.description}}">
                {{spLineItem.description}}
              </td>
              <td>
                {{spLineItem.price | currency: vm.currencyCode :'symbol':'1.2-2'}}
              </td>
              <td>
                {{spLineItem.quantity}}
              </td>
              <ng-container>
                <td style="width: 70px;">
                  <input readonly id="fcsp_storageType_{{i}}" name="fcsp_storageType_{{i}}" class="form-control"
                    type="text" [ngModel]="spLineItem.storageType" required>
                </td>
                <td>
                  <input readonly id="spStorageBin_{{i}}" name="spStorageBin_{{i}}" class="form-control" type="text" maxlength="10"
                    [ngModel]="spLineItem.storageBin | uppercase" style="min-width: 5rem;" required>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </ng-container>
  </form>
</div>
<div class="modal-footer">
  <div class="d-block w-100">
    <button type="button" class="btn btn-default yellow-btn margin-r-5 pull-right" (click)="closeModal();"
      [disabled]="!chipClipForm.valid">
      Continue
    </button>
  </div>
</div>
