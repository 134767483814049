import { Inject, Injectable, inject } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { CheckoutValidationMessages } from 'app/_components/checkout-validation/checkout-validation.messages';
import {
  CheckoutFormGroup,
  PaymentDetailsFormGroup
} from 'app/_components/checkout/checkout.component';
import { REGEX_CANADA_POSTAL_PATTERN, REGEX_PHONE_PATTERN, REGEX_USA_POSTAL_PATTERN, UNIT_NUMBER_PATTERN } from 'app/app.constants';
import { billToType, i2pType, selectedPayment } from 'entities/checkout-billing-enums';
import { createCheckoutForm } from './checkout-form.service.helper';
import { FeatureFlagService } from './feature-flag.service';

@Injectable({
  providedIn: "root"
})
export class CheckoutFormService {
  public checkoutForm: FormGroup<CheckoutFormGroup>;

  constructor(
    private featureFlagService: FeatureFlagService,
    private formBuilder: FormBuilder,
    @Inject(REGEX_CANADA_POSTAL_PATTERN) public regexCanadaPostalPattern: RegExp,
    @Inject(REGEX_USA_POSTAL_PATTERN) public regexUSAPostalPattern: RegExp,
    @Inject(REGEX_PHONE_PATTERN) public regexPhonePattern: RegExp
  ) {
    this.initForm();
  }

  private unitNumberPattern = inject(UNIT_NUMBER_PATTERN);


  public initForm(): void {
    this.checkoutForm = createCheckoutForm({
      regex: {
        regexPhonePattern: this.regexPhonePattern,
        regexUSAPostalPattern: this.regexUSAPostalPattern,
        regexCanadaPostalPattern: this.regexCanadaPostalPattern
      }
    });
  }

  getAllValidators(): any {
    return this.recursiveValidators(this.checkoutForm);
  }

  private recursiveValidators(control: any): any {
    if (control instanceof FormGroup) {
      let validators = {};
      const formGroup = control as FormGroup;

      Object.keys(formGroup.controls).forEach(key => {
        const subControl = formGroup.get(key);
        const subValidators = this.recursiveValidators(subControl);

        if (subValidators) {
          validators = { ...validators, [key]: subValidators };
        }
      });

      return validators;
    } else {
      const controlValidators = control.validator && control.validator(control);

      return controlValidators ? { [control.name]: controlValidators } : null;
    }
  }

  i2pValidator(control: FormGroup<PaymentDetailsFormGroup>): ValidationErrors | null {
    const paymentDetailsFormGroup = control.parent as FormGroup<PaymentDetailsFormGroup>;
    const emailValue = paymentDetailsFormGroup?.value.i2pEmail;
    const smsValue = paymentDetailsFormGroup?.value.i2pSms;
    const emailEmpty = emailValue === null || emailValue === undefined || emailValue === '';
    const smsEmpty = smsValue === null || smsValue === undefined || smsValue === '';

    const i2pValue = paymentDetailsFormGroup?.value.i2p;
    const i2pEmailControl = paymentDetailsFormGroup?.controls.i2pEmail;
    const i2pSmsControl = paymentDetailsFormGroup?.controls.i2pSms;
    const billToValue = paymentDetailsFormGroup?.value.billTo;
    const billToCCValue = paymentDetailsFormGroup?.value.billToCC

    const isCCI2P = selectedPayment.CCI2P === billToCCValue;
    const isCASH_CCOnline = billToType.CASH_CCOnline === billToValue;

    const isEmailSelected = i2pType.Email.toLowerCase() === i2pValue;
    const isSmsSelected = i2pType.SMS.toLocaleLowerCase() === i2pValue;

    const validEmail = isCASH_CCOnline && isEmailSelected && !emailEmpty && i2pEmailControl.valid;
    const validSms = isCASH_CCOnline && isSmsSelected && !smsEmpty && i2pSmsControl.valid;

    if(isCCI2P && (!validEmail && !validSms)) {
      return { required: CheckoutValidationMessages['i2p.required'] };
    }
    return null;
  }
}
