import { CartDetails } from "entities/cart-details";
export class AppInsightCustomerChange {
    userId?: string = "";
    searchTerm?: string = "";
    branchNumber?: string = "";
    source?: string = "";
    products?: any = null;
    cartDetails?: string;
    previousCustomerNumber?: string = "";
    previousCustomerName?: string = "";
    currentCustomerNumber?: string = "";
    currentCustomerName?: string = "";
}
