import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorInformation } from 'entities/error-information';
import { ExtendPartRequest } from 'entities/extend-part-request';
import { FeaturedPart } from 'entities/featured-part';
import { PartBinLocationCollection } from 'entities/part-bin-location-item';
import { PartSearch } from 'entities/part-search';
import { AlternatePartsRequest } from 'entities/parts/alternate-parts-request';
import { SapCreateExtendPartResults } from 'entities/parts/create-extend-part-result';
import { PartSearchResponse } from 'entities/parts/part-search-response';
import { RelatedPartsRequest } from 'entities/parts/related-parts-request';
import { PriceAdjustmentRequest } from 'entities/price-adjustment';
import { MscPriceVerifyData } from 'entities/price-verify/msc-price-verify-data';
import { MscPriceVerifyResult } from 'entities/price-verify/msc-price-verify-result';
import { distinct } from 'helpers';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from 'services/base.service';
import { LoggerService } from "./logger.service";

@Injectable({
  providedIn: "root"
})
export class PartService extends BaseService {

  constructor(
    private http: HttpClient,
    private loggerService: LoggerService
  ) { super(); }

  createExtendedPart(extendPartData: ExtendPartRequest): Promise<SapCreateExtendPartResults & ErrorInformation> {
    const url: string = 'api/parts/CreateExtendPartResult';
    return this.http.put<SapCreateExtendPartResults & ErrorInformation>(url, extendPartData, this.options)
      .toPromise()
      .catch(this.handleError);
  }

  createExtendedPart2(extendPartData: ExtendPartRequest): Observable<SapCreateExtendPartResults & ErrorInformation> {
    const url = 'api/parts/CreateExtendPartResult';
    return this.http.put<SapCreateExtendPartResults & ErrorInformation>(url, extendPartData, this.options);
  }

  getParts(searchData: PartSearch): Promise<any> {
    const url: string = 'api/parts/SearchParts';
    return this.http.post(url, searchData, this.options)
      .toPromise()
      .catch(this.handleError);
  }

  searchParts(searchData: PartSearch): Observable<any> {
    const url: string = 'api/parts/SearchParts';
    return this.http.post(url, searchData, this.options);
  }

  getRelatedParts(searchData: RelatedPartsRequest): Observable<PartSearchResponse> {
    const url: string = 'api/parts/GetRelatedParts';
    return this.http.put<PartSearchResponse>(url, searchData);
  }

  getAlternateParts(searchData: AlternatePartsRequest): Observable<PartSearchResponse> {
    const url: string = 'api/parts/GetAlternateParts';
    return this.http.put<PartSearchResponse>(url, searchData);
  }

  getFeaturedParts(branchCode: string, customerNumber: string): Observable<FeaturedPart[]> {
    const url: string = `api/parts/GetFeaturedParts?branchCode=${branchCode}&customerNumber=${customerNumber}`;

      return this.http.get<FeaturedPart[]>(url)
      .pipe(
        catchError(() => {
          return of([]);
        })
      );
  }

  getMscPriceVerification(mscPriceVerifyData: MscPriceVerifyData): Promise<MscPriceVerifyResult & ErrorInformation> {
    this.loggerService.verbose('mscPriceVerifyData.mscPriceVerifyParts: ', mscPriceVerifyData.mscPriceVerifyParts);
    const url: string = `api/parts/MscPriceVerification`;

    return this.http.put<MscPriceVerifyResult & ErrorInformation>(url, mscPriceVerifyData).toPromise();
  }

  BCTMscPriceVerification(mscPriceVerifyData: MscPriceVerifyData) {
    this.loggerService.verbose('mscPriceVerifyData.mscPriceVerifyParts: ', mscPriceVerifyData.mscPriceVerifyParts);
    const url: string = `api/parts/MscPriceVerification`;
    return this.http.put<MscPriceVerifyResult>(url, mscPriceVerifyData);
  }

  getPartBinLocations(partNumbers: string[], branchCode: string): Observable<PartBinLocationCollection> {
    const url: string = `api/parts/GetPartBinLocations`;
    const request = {
      partNumbers: distinct(partNumbers),
      branchCode
    };
    return this.http.post<PartBinLocationCollection>(url, request, this.options);
  }

  autocomplete(searchTerm: string, branchCode: string, customerNumber: string, isExpandedSearch: boolean): Observable<string[]> {
    const url: string = `api/parts/autocomplete`;
    const searchData = {
      partSearchTerm: searchTerm,
      branchCode: branchCode,
      customerNumber: customerNumber,
      pageNumber: 1,
      pageSize: 10,
      isExpandedSearch: isExpandedSearch
    } as PartSearch;

    return this.http.post<string[]>(url, searchData, this.options);
  }

  adjustPrice(request: PriceAdjustmentRequest): Observable<any> {
    const url: string = 'api/parts/AdjustPrice';
    return this.http.put(url, request, this.options);
  }
}
