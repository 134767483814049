/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CartItemAndDelivery } from 'app/_components/checkout-line-item-delivery/checkout-line-item-delivery.component.state';
import { CartResultLineClient, CartResultLineItem } from 'entities/cart-result';
import { isFreightOrDelivery } from 'helpers';
import { mapErrorKeysToMessages } from 'helpers/map-error-messages';





/*
    validation helpers
*/
export function validateLineItem(
    item: CartResultLineClient | CartItemAndDelivery,
    isQuote: boolean,
    isProcured: boolean,
    isShoppingBasketItem: boolean,
    outOfStock: boolean,
    isInternalUser: boolean,
    orderNumber: string): ValidationErrors | null {
    let requireProcurement = outOfStock && !isProcured && !isQuote && isInternalUser;
    // isQuote determines if you selected the quote transaction type, this check for ordernumber
    // checks if you are inside an opened quote
    if(orderNumber !== null && orderNumber !== undefined && orderNumber !== ''){
      requireProcurement = false;
    }

    const options = ["W", "A", "L", "K", "D", "C", "S"];
    const errorsMap = requireProcurement ? {
        requireProcurement: item.deliveryOption !== "" && requireProcurement,
        requireDeliveryOption: !item.deliveryOption || item.deliveryOption === ""
    } : {
        disallowWillCall: (isShoppingBasketItem || requireProcurement) && item.deliveryOption === "W",
        disallowShoppingBasketReturns: (isProcured || requireProcurement) && item.deliveryOption === "A",
        disallowSelfPick: (isProcured || isShoppingBasketItem || requireProcurement) && (item.deliveryOption === "L"),
        disallowWarehousePick: (isShoppingBasketItem || requireProcurement) && item.deliveryOption === "K",
        disallowFreightDelivery: (isShoppingBasketItem || requireProcurement) && (item.deliveryOption === "C"),
        disallowRTCDelivery: (isShoppingBasketItem || requireProcurement) && (item.deliveryOption === "D"),
        disallowShipDirect: isInternalUser && (isShoppingBasketItem || requireProcurement) && item.deliveryOption === "S" ,
        requireDeliveryOption: !item.deliveryOption || item.deliveryOption === "" || options.indexOf(item.deliveryOption) === -1,
        shoppingBasketItemQuote: (isShoppingBasketItem && item.deliveryOption === "A") && isQuote,
        disallowFreightDeliveryHazmat: (item.hazmat && item.deliveryOption === "C")
    };

    return mapErrorKeysToMessages(errorsMap, "cartData.");
}

function procured(value: CartResultLineItem): boolean {
    return value.isHotFlag || value.isSTO || value.isBuyout;
}

function shoppingBasketItem(value: CartResultLineItem): boolean {
    return value.binLocation && value.binLocation.startsWith('SB-');
}

export function ValidateDeliveryLinebyLineItem(isQuote: boolean, isInternalUser: boolean, orderNumber: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
      const item: CartItemAndDelivery = control.value;
      const _procured = procured(item.cartItem);
      const _shoppingBasketItem = shoppingBasketItem(item.cartItem);
      const outOfStock = item.cartItem.quantity > item.cartItem.quantityAvailable;

      return validateLineItem(item, isQuote, _procured, _shoppingBasketItem, outOfStock, isInternalUser, orderNumber);
  };
}
