import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbDateParserFormatter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { map, tap, withLatestFrom} from 'rxjs/operators';
import { NgbDateCustomParserFormatter, JsDateAdapter } from 'entities/dateformat';
import { ConfigurationService } from 'services/configuration.service';
import { FindOrderPanelComponentService, FindOrderPanelActions, FindOrderPanelState } from './find-order-panel.component.service';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { OrderHistoryRequest } from 'entities/order-history';
import { Configuration } from 'entities/configuration';


@Component({
  selector: 'find-order-panel',
  templateUrl: './find-order-panel.component.html',
  styleUrls: ['./find-order-panel.component.scss'],
  providers: [
    FindOrderPanelComponentService,
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    { provide: NgbDateAdapter, useClass: JsDateAdapter }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FindOrderPanelComponent implements AfterViewInit, OnDestroy {
  @Output() search: EventEmitter<OrderHistoryRequest> = new EventEmitter();
  @Output() find: EventEmitter<string> = new EventEmitter();
  @Input() public panelType;
  @ViewChild("findOrderForm", { static: true }) private findOrderForm: NgForm;

  public isQuote = false;
  private actionSubject: Subject<FindOrderPanelActions> = new Subject();

  private selectors = this.service.getSelectors(this.actionSubject);

  public searchRequest$: Observable<OrderHistoryRequest> = this.selectors.searchRequest$;
  private searchRequest: OrderHistoryRequest;
  private populateSearchRequest$ = this.searchRequest$.pipe(
    tap((request: OrderHistoryRequest) => { this.searchRequest = request; })
  );

  public orderNumber$: Observable<string> = this.selectors.orderNumber$;
  private orderNumber: string;
  private populateOrderNumber$ = this.orderNumber$.pipe(
    tap((orderNumber: string) => { this.orderNumber = orderNumber; })
  );

  private clientConfig$: Observable<Configuration> = this.configurationService.configuration$;
  public isInternal$: Observable<boolean> = this.clientConfig$
    .pipe(
      map((config) => config.user.isInternal)
    );

  private initialSearchOrder$: Observable<[FindOrderPanelState, boolean]> = this.selectors.initialized$.pipe(
    withLatestFrom(this.isInternal$),
    tap(([state, isInternal]) => {
      if (!isInternal) {
        this.searchOrders();
      }
    })
  );

  private subscription: Subscription;

  constructor(
    private configurationService: ConfigurationService,
    private service: FindOrderPanelComponentService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngAfterViewInit() {
    this.isQuote = this.panelType == 'quote';
    const getQueryParameters$ = this.activatedRoute.queryParams
      .pipe(
        tap((params) => {
          this.setShipTo(params['shipTo']);
        })
      );

    this.setType(this.panelType);
    this.subscription = merge(this.populateOrderNumber$, this.populateSearchRequest$, getQueryParameters$, this.initialSearchOrder$).subscribe();

    this.findOrderForm?.control.updateValueAndValidity();
  }

  setOrderNumber(orderNumber: string) {
    this.actionSubject.next({type : 'setOrderNumber', orderNumber});
  }

  setEndDate(endDate: Date | string) {
    if (typeof endDate === 'string') {
      return;
    }
    this.actionSubject.next({ type: 'setEndDate', endDate, isQuote: this.isQuote });
  }

  setStartDate(startDate: Date | string) {
    if (typeof startDate === 'string') {
      return;
    }
    this.actionSubject.next({ type: 'setStartDate', startDate, isQuote: this.isQuote });
  }

  setCustomerNumber(customerNumber: string) {
    this.actionSubject.next({ type: 'setCustomerNumber', customerNumber });
  }

  setShipTo(shipTo: string) {
    if (this.isInternal$) {
      this.actionSubject.next({ type: 'setShipTo', shipTo });
    }
  }

  setBranchCode(branchCode: string) {
   this.actionSubject.next({ type: 'setBranch', branchCode });
  }

  setPONumber(poNumber: string) {
    this.actionSubject.next({ type: 'setPoNumber', poNumber });
  }

  setPartNumber(partNumber: string) {
    this.actionSubject.next({ type: 'setPartNumber', partNumber });
  }

  setType(orderType: "order" | "quote") {
    this.actionSubject.next({ type: 'setType', orderType});
  }

  searchOrders() {
    this.search.emit(this.searchRequest);
  }

  findOrder() {
    this.find.emit(this.orderNumber);
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
}
