import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { validateMinMax } from "validators/min-max-validator";
import { validateQuantity } from "validators/quantity.validator";

@Component({
    selector: 'part-quantity',
    templateUrl: './part-quantity.component.html',
    styleUrls: ['./part-quantity.component.scss'],
  })

  export class PartQuantityComponent implements OnInit {
    @Input() index: number = 0;
    @Input() idModifier: string = "";
    @Input() value: number = 1;
    @Input() min: number = 1;
    @Input() max: number = 10000;
    @Input() inputName: string = "";
    @Input() inputPlaceholder: string = "";
    @Input() inputAutoComplete: string = "on";
    @Input() disabled: boolean = false;
    @Input() checkRangeClass?: string = "";
    @Output("onChangeQuantity") onChangeQuantity: EventEmitter<number> = new EventEmitter();
    @ViewChild('quantityInput') quantityInput: ElementRef<HTMLInputElement>;
    decrementDisabled = true;
    incrementDisabled = false;
    containerId: string;
    inputId: string;
    incrementId: string;
    decrementId: string;

    constructor() {

    }
    ngOnInit() {
        this.setDecrementDisabled();
        const idMod = this.idModifier ? `${this.idModifier}_` : '';
        this.containerId = `quantityContainer_${idMod}${this.index}`;
        this.inputId = `quantityInput_${idMod}${this.index}`;
        this.incrementId = `quantityIncrement_${idMod}${this.index}`;
        this.decrementId = `quantityDecrement_${idMod}${this.index}`;
    }

    setDecrementDisabled() {
        this.decrementDisabled = this.value === 1;
    }

    setIncrementDisabled() {
        if(this.max) {
            this.incrementDisabled = this.value === this.max;
        }
    }

    updateQuantity(qty: number) {
        if (validateQuantity(qty) && validateMinMax(qty, this.min, this.max)) {
            this.value = Number(qty);
            this.onChangeQuantity.emit(this.value);
        }
        this.setDecrementDisabled();
        this.setIncrementDisabled();
    }

    incrementQuantity() {
        if(this.value < this.max) {
            const newQuantity = this.value + 1;
            this.updateQuantity(newQuantity);
        }
    }

    decrementQuantity() {
        if (this.value > 1) {
            const newQuantity = this.value - 1;
            this.updateQuantity(newQuantity);
        }
    }

    quantityKeyup(event: KeyboardEvent) {
        switch (event.key) {
            case "ArrowUp":
                this.incrementQuantity();
                break;
            case "ArrowDown":
                this.decrementQuantity();
                break;
            default:
                break;
        }
    }

    quantityFocusout(e: FocusEvent) {
        const newQuantity: any = (e.target as HTMLInputElement).value;
        if (validateQuantity(newQuantity) && validateMinMax(newQuantity, this.min, this.max)) {
            if(validateMinMax(newQuantity, this.min, this.max)) {
                this.updateQuantity(Number(newQuantity));
            }
        } else {
            if(Number(newQuantity) < this.min) {
                this.updateQuantity(this.min);
                (e.target as HTMLInputElement).value = '' + this.min;
            }
            if(Number(newQuantity) > this.max) {
                this.updateQuantity(this.max);
                (e.target as HTMLInputElement).value = '' + this.max;
            }
        }
    }

    _keyPress(event: KeyboardEvent) {
        const pattern = /\d/;
        if (!pattern.test(event.key)) {
          // invalid character, prevent input
          event.preventDefault();
        }
    }
  }
