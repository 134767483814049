<form
  id="autcompleteForm"
  #autcompleteForm="ngForm"
  class="needs-validation"
  style="height: calc(2.25rem + 2px);"
  [ngClass]="{ 'was-validated': !autcompleteForm.pending }">
  <div class="input-group" #selectionGroup>
    <div class="position-relative d-block w-100" (keydown)="onKeydown($event, selectionGroup)">
      <input id="autocomplete_text"
        class="form-control w-100 position-absolute"
        type="text" name="autocomplete_text"
        [placeholder]="label"
        [disabled]="disabled" [ngModel]="value"
        (focus)="selectAllContent($event.target)"
        [ngbTypeahead]="search" [resultFormatter]="displaySelected"
        [inputFormatter]="displaySelected" (selectItem)="onSelectChange($event)" (change)="onInputChanged($event.target.value)" />
      <span id="autocomplete_span_IsSearching" class="w-100 position-absolute text-end"
        style="padding-right: .75rem; padding-top: .375rem; pointer-events: none;"
        *ngIf="isSearching">
        <i class="fas fa-spinner fa-pulse"></i>
      </span>
    </div>
  </div>
</form>
