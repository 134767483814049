import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Branch } from 'entities/branch';
import { OrderDetails } from 'entities/order-details';
import { OrderDetailsLineItem } from 'entities/order-details-line-item';
import { OrderDetailsTrackingLink } from 'entities/order-details-tracking-link';
import { OrderFreight } from 'entities/order-freight';
import { OrderPartner } from 'entities/order-partner';
import { OrderType } from 'entities/order-type';
import { ProofOfDeliveryResponse } from 'entities/proof-of-delivery-response';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { finalize, map, shareReplay, tap, withLatestFrom } from 'rxjs/operators';
import { BranchService } from 'services/branch.service';
import { OrderService } from 'services/order.service';
import { isInternal } from 'store/configuration/configuration.selectors';
import { InviteToPayForm, InviteToPayOptions } from '../invite-to-pay/invite-to-pay.model';
import { i2pResendEnabled } from '../invite-to-pay/invite-to-pay.utils';

@Component({
  selector: 'order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDetailsComponent implements OnInit {
  public orderNumber: string;

  public orderDetails$: Observable<OrderDetails>;
  public soldTo$: Observable<OrderPartner>;
  public branch$: Observable<Branch>;
  public podLinks$: Observable<ProofOfDeliveryResponse>;
  public podLinksLoading: boolean = true;

  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public loading$: Observable<boolean> = this.loadingSubject.asObservable();

  public orderDetailsLineItems$: Observable<OrderDetailsLineItem[]>;
  public orderDetailsTrackingLinks$: Observable<OrderDetailsTrackingLink[]>;
  public orderTotal$: Observable<number>;
  public i2pSuccess$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public i2pEnabled$: Observable<boolean>;
  public i2pDetails$: Observable<InviteToPayForm>;

  constructor(
    private orderService: OrderService,
    private branchService: BranchService,
    private activeModal: NgbActiveModal,
    private store: Store) { }

  getTrackingLinks(orderFreightDetails: OrderFreight[]): OrderDetailsTrackingLink[] {
    let trackingLinks: OrderDetailsTrackingLink[] = [];
    orderFreightDetails?.forEach(fd => {
      if (fd.trackingId.trim() && !trackingLinks.some(tl => tl.trackingUrl === fd.trackingUrl)) {
        trackingLinks.push({
          trackingId: fd.trackingId,
          trackingUrl: fd.trackingUrl
        });
      }
    });
    return trackingLinks;
  }

  ngOnInit() {
    this.orderDetails$ = this.orderService.getOrderDetails(this.orderNumber, OrderType.Order)
      .pipe(
        finalize(() => this.loadingSubject.next(false)),
        shareReplay(1)
      );

    this.i2pEnabled$ = combineLatest([
      this.orderDetails$,
      this.i2pSuccess$,
    ]).pipe(
      withLatestFrom(this.store.select(isInternal)),
      map(([[orderDetails, isSuccess], isInternal]) => i2pResendEnabled(isInternal, orderDetails.orderHistory, isSuccess))
    );

    this.i2pDetails$ = this.orderDetails$.pipe(
      map((orderDetails) => orderDetails.orderHistory),
      map((history) => ({
        i2p: history.ccSmsStatus === "X" ? InviteToPayOptions.sms : InviteToPayOptions.email,
        i2pEmail: history.ccEmail,
        i2pSms: history.ccPhoneNo
      }))
    );

    this.soldTo$ = this.orderDetails$
      .pipe(
        map(orderDetails => orderDetails.partners),
        map(partners => partners.find(p => p.partnerFunction === "SoldTo")),
        shareReplay(1)
      );

    this.branch$ = this.orderDetails$
      .pipe(
        withLatestFrom(this.branchService.branches$),
        map(([orderDetails, branches]) => branches.find(b => b.code === orderDetails.orderHistory.branch)),
        shareReplay(1)
      );

    this.orderDetailsLineItems$ = this.orderDetails$
      .pipe(
        map(orderDetails => orderDetails.lineItems),
        shareReplay(1)
      );

    this.orderDetailsTrackingLinks$ = this.orderDetails$
      .pipe(
        map(orderDetails => this.getTrackingLinks(orderDetails.freightDetails)),
        shareReplay(1)
      );

    this.orderTotal$ = this.orderDetailsLineItems$
      .pipe(
        map(items =>
          items.map(item => item.quantity * item.unitPrice)
            .reduce((acc, val) => acc + val))
      );

    this.podLinks$ = this.orderService.getProofOfDeliveryLinks(this.orderNumber)
      .pipe(
        tap(() => this.podLinksLoading = false)
      );
  }

  onI2pSuccess(): void {
    this.i2pSuccess$.next(true);
  }

  closeModal() {
    this.activeModal.close();
  }
}
