export class Pager {
  private _totalItems: number;
  public get totalItems(): number { return this._totalItems; }
  public set totalItems(value: number) { this._totalItems = value; this.recalculate(); }

  private _currentPage: number;
  public get currentPage(): number { return this._currentPage; }
  public set currentPage(value: number) { this._currentPage = value; this.recalculate(); }

  private _pageSize: number;
  public get pageSize(): number { return this._pageSize; }
  public set pageSize(value: number) { this._pageSize = value; this.recalculate(); }

  private _totalPages: number;
  public get totalPages(): number { return this._totalPages; }

  private _startPage: number;
  public get startPage(): number { return this._startPage; }

  private _endPage: number;
  public get endPage(): number { return this._endPage; }

  private _startIndex: number;
  public get startIndex(): number { return this._startIndex; }

  private _endIndex: number;
  public get endIndex(): number { return this._endIndex; }

  private _pages: number[];
  public get pages(): number[] { return this._pages; }

  constructor (
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 10
  ) {
    this._totalItems = totalItems;
    this._currentPage = currentPage;
    this._pageSize = pageSize;
    this.recalculate();
  }

  private recalculate() {
    this._totalPages = Math.ceil(this.totalItems / this.pageSize);
    this._startPage = Math.max(1, Math.min(this.totalPages - 4, this.currentPage - 2));
    this._endPage = Math.min(this.totalPages, this.currentPage + 2);
    this._startIndex = (this.currentPage - 1) * this.pageSize;
    this._endIndex = Math.min(this.startIndex + this.pageSize - 1, this.totalItems - 1);
    this._pages = Array(this.endPage - this.startPage + 1).fill(0).map((page, index) => index + this.startPage);
  }
}
