import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'creditCard'})
export class CreditCardPipe implements PipeTransform {
  transform(value: string, xNeeded: boolean = false, spaceNeeded: boolean = false): string {
    const cardNumberLength = 16;
    let resp = value;
    if(!resp) return "N/A";
    // result (if we only have '123'): xxxxxxxxxxxxx123
    if(resp.length < cardNumberLength) {
        resp = "x".repeat(cardNumberLength - resp.length);
        resp += value;
    }
    // result: 'xxxx xxxx xxxx 1234'
    if(spaceNeeded) {
        console.log(resp);
        resp = resp.match(/.{1,4}/g).join(" ");
    }
    // result: '1234'
    if(!xNeeded) {
        return resp.slice(-4);
    }
    return resp;
  }
}
