import { createAction, props } from "@ngrx/store";
import { NotesComponentConfig } from "app/_components/notes/notes.component.state";
import { Part } from "entities/part";
import { MscPriceVerifyResult } from "entities/price-verify/msc-price-verify-result";
import { BackCounterDashboardPage, BackCounterRequestDetails, BackCounterRequestPayload, RequestAttachment, RequestNote, RequestOrderItem, RequestPartItemsUnsavedChanges, SapEventMessage } from "./back-counter.entities";

export const getBackCounterRequests = createAction(
  "[Back Counter] Get Requests",
  props<{ payload: BackCounterRequestPayload }>()
);

export const getBackCounterRequestsSuccess = createAction(
  "[Back Counter] Get Requests Success",
  props<{ requests: BackCounterDashboardPage }>()
);

export const getBackCounterRequestsFailed = createAction(
  "[Back Counter] Get Requests Failed",
  props<{ error: any }>()
);

export const openBackCounterRequest = createAction(
  "[Back Counter] Open Request",
  props<{ orderNumber: string }>()
);

export const editBackCounterRequest = createAction(
  "[Back Counter] Edit Request",
  props<{ orderNumber: string; inEditMode: boolean }>()
);

export const updateBackCounterRequest = createAction(
  "[Back Counter] Update Request",
  props<{ orderNumber: string; onComplete?: Function, payload: RequestPartItemsUnsavedChanges}>()
);

export const updateBackCounterRequestSuccess = createAction(
  "[Back Counter] Update Request Success",
  props<{ part: any, requestId: string }>()
);

export const updateBackCounterRequestFailed = createAction(
  "[Back Counter] Update Request Failed",
  props<{ error: any }>()
);

export const ignoredUpdateBackCounterRequest = createAction(
  "[Back Counter] Ignored Update Request"
);

export const closeBackCounterRequest = createAction(
  "[Back Counter] Close Request",
  props<{ requestId: string }>()
);

export const getRequestDetails = createAction(
  "[Back Counter] Get Request Details",
  props<{ requestId: string }>()
);

export const getRequestDetailsSuccess = createAction(
  "[Back Counter] Get Request Details Success",
  props<{ requestDetails: BackCounterRequestDetails, US129696Enabled?: boolean }>()
);

export const getRequestDetailsFailed = createAction(
  "[Back Counter] Get Request Details Failed",
  props<{ error: any }>()
);
export const removeRequestLock = createAction(
  "[Back Counter] Unlock Request Details",
  props<{ orderNumber: string }>()
);
export const getRequestAttachments = createAction("[Back Counter] Get Request Attachments",
  props<{ orderNumber: string }>()
);
export const getRequestAttachmentsSuccess = createAction("[Back Counter] Get Request Attachments Success",
  props<{ attachments: RequestAttachment[]; orderNumber: string }>()
);
export const getRequestAttachmentsFailed = createAction("[Back Counter] Get Request Attachments Failed",
  props<{ error: any }>()
);

export const partSearchModalClosed = createAction("[Back Counter] Part Search Modal Closed");

export const openBCTPriceVerifyModal = createAction("[Back Counter] Open BCT Price Verify Modal",
  props<{requestId: string; branchCode: string; customerNumber: string; onVerificationSuccess: Function}>()
);

export const BCTPriceVerifySuccess = createAction("[Back Counter] BCT Price Verify Success",
  props<{ priceVerifyParts: MscPriceVerifyResult; requestId: string;}>()
);

export const BCTPriceVerifyFailed = createAction("[Back Counter] BCT Price Verify Failed",
  props<{ error: any }>()
);

export const addPartToRequest = createAction("[Back Counter] Add Part To Request",
  props<{branchCode: string, orderNumber: string; customerNumber: string; jobNumber: string; mainPrItem: string; onSelectPart: (requestOrderItem: RequestOrderItem & {quantityAvailable: number}) => void}>()
);

export const syncUnsavedChanges = createAction("[Back Counter] Sync Unsaved Changes",
props<{orderNumber: string; editedParts: RequestOrderItem[]; part?: RequestOrderItem; props?: any;}>());

export const getVendorsCode = createAction("[Back Counter] Get Vendors Code", props<{materialNumber: string; onComplete: Function}>());

export const openGeneralRequestModal = createAction("[Back Counter] Open General Request Modal"
);

export const updateGeneralRequest = createAction("[Back Counter] Open General Request Modal for Update",
  props<{orderNumber: string; refOrderNumber: string; requestor: string; updateGeneralRequest: boolean; isComplete: boolean; department: string}>()
);

export const updateGeneralRequestSuccess = createAction("[Back Counter] Open General Request Modal for Update Success",
  props<{success: boolean}>()
);
export const updateGeneralRequestFailed = createAction("[Back Counter] Open General Request Modal for Update Failed",
  props<{error: any}>()
);

export const getRequestNotes = createAction("[Back Counter] Get Request Notes",
  props<{orderNumber: string}>()
);

export const getRequestNotesSuccess = createAction("[Back Counter] Get Request Notes Success",
  props<{notes: RequestNote[]}>()
);

export const getRequestNotesFailed = createAction("[Back Counter] Get Request Notes Failed",
  props<{error: any}>()
);

export const addGeneralRequestSuccess = createAction("[Back Counter] Add General Request Success");
export const addGeneralRequestFailed = createAction("[Back Counter] Add General Request Failed",
  props<{error: any}>());

export const backCounterMessageReceived = createAction("[Back Counter] signalR message received",
 props<{eventInfo: SapEventMessage}>());
export const openNotesModal = createAction("[Back Counter] Open Notes Modal", props<{ config: NotesComponentConfig}>());
export const closeNotesModal = createAction("[Back Counter] Close Notes Modal", props<{ config: NotesComponentConfig}>());
export const completeGeneralRequest = createAction("[Back Counter] Complete General Request",
  props<{orderNumber: string, department: string}>());
export const completeGeneralRequestSuccess = createAction("[Back Counter] Complete General Request Success",
  props<{success: boolean}>());
export const completeGeneralRequestFailed = createAction("[Back Counter] Complete General Request Failed",
  props<{error: any}>());
export const openConfirmCompleteModal = createAction("[Back Counter] Open Confirm Complete Request Modal", props<{ orderNumber: string; onCompleted: Function}>());

export const openAttachment = createAction("[Back Counter] Open Attachment",
  props<{photoId: string; orderNumber: string; fileName: string; mimetype: string}>()
);
export const openAttachmentSuccess = createAction("[Back Counter] Open Attachment Success");
export const openAttachmentFailed = createAction("[Back Counter] Open Attachment Failed",
  props<{error: string}>()
);
export const completeConfirmed = createAction("[Back Counter] Request Completed", props<{ lockConfirm: boolean}>());

export const changeLoading = createAction(
  "[Back Counter] Change Loading",
  props<{ loading: boolean }>()
);
export const partAddedToRequest = createAction("[Back Counter] Part Added To Request", props<{ part: Part}>());

