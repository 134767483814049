<div class="imageFeedbackForm p-3">
  <form id="imageFeedback_form_ImageFeedbackForm" class="gy-1" #imageFeedbackForm="ngForm" (ngSubmit)="submitFeedback()">
      <h5>Please provide your image feedback below:</h5>
      <div>
          <div>
            <input id="imageFeedback_radio_FeedbackTypeIncorrectImage" type="radio" name="feedbackType" value="Incorrect Image" [(ngModel)]="feedbackType" required>
            <label for="imageFeedback_radio_FeedbackTypeIncorrectImage">Incorrect Image</label>
          </div>
          <div>
            <input id="imageFeedback_radio_FeedbackTypeMissingImage" type="radio" name="feedbackType" value="Missing Image" [(ngModel)]="feedbackType" required>
            <label for="imageFeedback_radio_FeedbackTypeMissingImage">Missing Image</label>
          </div>
          <div>
            <input id="imageFeedback_radio_FeedbackTypeInappropriateImage" type="radio" name="feedbackType" value="Inappropriate Image" [(ngModel)]="feedbackType" required>
            <label for="imageFeedback_radio_FeedbackTypeInappropriateImage">Inappropriate Image</label>
          </div>
          <div>
            <input id="imageFeedback_radio_FeedbackTypeIncompleteImage" type="radio" name="feedbackType" value="Incomplete Image" [(ngModel)]="feedbackType" required>
            <label for="imageFeedback_radio_FeedbackTypeIncompleteImage">Incomplete Image</label>
          </div>
          <div>
            <input id="imageFeedback_radio_FeedbackTypeOther" type="radio" name="feedbackType" value="Other" [(ngModel)]="feedbackType" required>
            <label for="imageFeedback_radio_FeedbackTypeOther">Other</label>
          </div>
      </div>
      <h5>Additional Details:</h5>
      <textarea class="w-100" rows="5" name="additionalDetails" [(ngModel)]="additionalDetails"></textarea>
      <div class="d-flex justify-content-end gx-2">
          <button id="imageFeedback_button_Cancel" type="button" class="btn btn-default black-btn" (click)="close.emit()">Cancel</button>
          <button id="imageFeedback_button_Submit" type="submit" class="btn btn-default yellow-btn" [disabled]="!imageFeedbackForm.valid">Submit</button>
      </div>
  </form>
</div>
