import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PurchaseOrderDeliveryType } from 'entities/purchase-order';
import { HotFlagRadioButtonType } from 'entities/purchase-order/hot-flag';
import { PurchaseOrderCreateModalComponentStore } from './purchase-order-create-modal.component.store';

@Component({
  templateUrl: './purchase-order-create-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PurchaseOrderCreateModalComponentStore]
})
export class PurchaseOrderCreateModalComponent implements OnInit {
  @Input() orderNumber: string;
  @Input() isOnlyHotFlagCreatePO: boolean;

  public busy$ = this.componentStore.busy$;
  public groups$ = this.componentStore.groups$;

  constructor (
    private componentStore: PurchaseOrderCreateModalComponentStore,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.componentStore.getProcurementGroups(this.orderNumber);
  }

  showMore(index: number) {
    this.componentStore.showMore(index);
  }

  showLess(index: number) {
    this.componentStore.showLess(index);
  }

  updateDeliveryType(index: number, deliveryType: PurchaseOrderDeliveryType) {
    this.componentStore.updateDeliveryType({ key: index, value: deliveryType });
  }

  updateHotFlagItems(index: number, hotFlagType: HotFlagRadioButtonType) {
    this.componentStore.updateHotFlagItems({ key: index, value: hotFlagType });
  }

  cancel() {
    this.activeModal.dismiss();
  }

  submit() {
    this.componentStore.submit();
  }
}
