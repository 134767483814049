<div class="py-1">
    <ul ngbNav #nav="ngbNav" [activeId]="activatedRoute.fragment | async" class="mt-3 dash-nav" type="pills">
      <li ngbNavItem="open">
        <a ngbNavLink routerLink="." fragment="open" id="myDashboard_tab_MyDashboard">My Dashboard</a>
        <ng-template ngbNavContent>
          <my-dashboard-tab *ngIf="branchCode$ | async"></my-dashboard-tab>
        </ng-template>
      </li>
      <li ngbNavItem="quotes">
        <a ngbNavLink routerLink="." fragment="quotes" id="myDashboard_tab_Quotes">Quotes</a>
        <ng-template ngbNavContent>
          <quotes></quotes>
        </ng-template>
      </li>
      <li ngbNavItem="history">
        <a ngbNavLink routerLink="." fragment="history" id="myDashboard_tab_OrderHistory">Order History</a>
        <ng-template ngbNavContent>
          <order-history></order-history>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="pb-4"></div>
</div>
