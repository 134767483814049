<div class="container">
  <div class="row py-1" *ngIf="header">
    <div class="col-12 row-heading d-flex justify-content-between">
      <div *ngIf="header != 'HOT FLAG'" class="col-12 row-heading d-flex justify-content-between">
        <span>{{header}}</span>
        <span *ngIf="headerRight">{{headerRight}}</span>
      </div>
      <div *ngIf="header == 'HOT FLAG'" class="col-12 row-heading d-flex justify-content-between">
        <span>{{header}}</span>
        <span *ngIf="notHotFlag95And99">* Requisition will be created automatically for these items.</span>
      </div>
    </div>
  </div>
  <order-item-header
    [showCouponColumn]="showCouponColumn" [showHotFlagColumn]="showHotFlagColumn">
  </order-item-header>
  <order-item
    [orderItem]="orderItem"
    [idSuffix]="idSuffix"
    [index]="i"
    [showCouponColumn]="showCouponColumn"
    [couponValue]="orderItemCouponValues[orderItem.id] || 0"
    *ngFor="let orderItem of orderItems; let i = index">
  </order-item>
</div>
