<div class="modal-header modal-lg">
  <button id="CreatePartModal_Button_Close" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()"><span aria-hidden="true">&times;</span></button>
  <p class="modal-title pull-left margin-t-10" id="myModalLabel"><i class="fas fa-info-circle" aria-hidden="true" style="margin: -3px 5px 0 5px;"></i>Select the part you would like to create.</p>
  <div class="input-group col-sm-12 col-md-6 col-lg-6 module-search">
    <input id="CreatePartModal_Text_Search" type="text" class="form-control customer-search-textbox" placeholder="Search ..." [(ngModel)]="filterKey"
      (keyup)="filter()" />
        <span class="input-group-text">
          <i class="fas fa-search" aria-hidden="true"></i>
        </span>
  </div>
</div>
<div class="modal-body modal-lg">
  <table class="table table-responsive">
    <thead>
      <tr>
        <th>
          <a id="CreatePartModal_A_PartNumberColumn" role="button" (click)="sortDataBy('materialNumber');">
            Part Number
            <span [hidden]="sortBy != 'materialNumber'" class="fas" [ngClass]="{'fa-caret-up': sortBy == 'materialNumber' && sortAsc, 'fa-caret-down': sortBy == 'materialNumber' && !sortAsc}">
            </span>
          </a>
        </th>
        <th>
          <a id="CreatePartModal_A_DescriptionColumn" role="button" (click)="sortDataBy('materialDescription');">
            Description
            <span [hidden]="sortBy != 'materialDescription'" class="fas" [ngClass]="{'fa-caret-up': sortBy == 'materialDescription'&& sortAsc, 'fa-caret-down': sortBy == 'materialDescription' && !sortAsc}">
            </span>
          </a>
        </th>
        <th style="white-space: nowrap">
          <a id="CreatePartModal_A_ManufacturerColumn" role="button" (click)="sortDataBy('manufacturerNumber');">
            Manufacturer
            <span [hidden]="sortBy != 'manufacturerNumber'" class="fas" [ngClass]="{'fa-caret-up': sortBy == 'manufacturerNumber'&& sortAsc, 'fa-caret-down': sortBy == 'manufacturerNumber' && !sortAsc}">
            </span>
          </a>
        </th>
        <th style="white-space: nowrap">
          <a id="CreatePartModal_A_PriceTapeColumn" role="button" (click)="sortDataBy('priceTapeIndicator');">
            Price Tape
            <span [hidden]="sortBy != 'priceTapeIndicator'" class="fas" [ngClass]="{'fa-caret-up': sortBy == 'priceTapeIndicator'&& sortAsc, 'fa-caret-down': sortBy == 'priceTapeIndicator' && !sortAsc}">
            </span>
          </a>
        </th>
        <th style="white-space: nowrap">
          <a id="CreatePartModal_A_SupersededPartNumberColumn" role="button" (click)="sortDataBy('supersededPartNo');">
            Superseded Part Number
            <span [hidden]="sortBy != 'supersededPartNo'" class="fas" [ngClass]="{'fa-caret-up': sortBy == 'supersededPartNo'&& sortAsc, 'fa-caret-down': sortBy == 'supersededPartNo' && !sortAsc}">
            </span>
          </a>
        </th>
        <th style="white-space: nowrap">
          <a id="CreatePartModal_A_InformationTextColumn" role="button" (click)="sortDataBy('informationText');">
            Information Text
            <span [hidden]="sortBy != 'informationText'" class="fas" [ngClass]="{'fa-caret-up': sortBy == 'informationText'&& sortAsc, 'fa-caret-down': sortBy == 'informationText' && !sortAsc}">
            </span>
          </a>
        </th>

      </tr>
    </thead>
    <tbody>
      <tr id="CreatePartModal_TR_Part_{{i}}" *ngFor="let multiplePartResults of createPartResults; let i = index" (click)="onPartNoSelect(multiplePartResults);">

        <td scope="row">
          <span id="CreatePartModal_Span_MaterialNumber_{{i}}">{{multiplePartResults.materialNumber}}<br /></span>
        </td>
        <td>
          <span id="CreatePartModal_Span_MaterialDescription_{{i}}">{{multiplePartResults.materialDescription}}<br /></span>
        </td>
        <td id="CreatePartModal_TD_ManufacturerNumber_{{i}}">{{multiplePartResults.manufacturerNumber}}</td>
        <td id="CreatePartModal_TD_PriceTapeIndicator_{{i}}">{{multiplePartResults.priceTapeIndicator}}</td>
        <td>
          <span id="CreatePartModal_Span_SupersededPartNumber_{{i}}">{{multiplePartResults.supersededPartNo}}<br /></span>
        </td>
        <td id="CreatePartModal_TD_InformationText_{{i}}">{{multiplePartResults.informationText}}</td>

      </tr>

    </tbody>
  </table>
</div>
<div class="modal-footer modal-lg">
  <nav aria-label="Page navigation" *ngIf="pager.pages && pager.pages.length">
    <div class="pull-right padding-0">
      <ul class="pagination pull-right">
        <li [ngClass]="{disabled:pager.currentPage == 1}">
          <a id="CreatePartModal_A_PagingFirstPage" aria-label="First" (click)="setPage(1)"><span aria-hidden="true">&laquo;</span></a>
        </li>
        <li [ngClass]="{disabled:pager.currentPage == 1}">
          <a id="CreatePartModal_A_PagingPreviousPage" aria-label="Previous" (click)="setPage(pager.currentPage - 1)"><span aria-hidden="true">&lsaquo;</span></a>
        </li>
        <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage == page}">
          <a id="CreatePartModal_A_PagingCurrentPage" (click)="setPage(page)">{{page}}</a>
        </li>
        <li [ngClass]="{disabled:pager.currentPage == pager.totalPages}">
          <a id="CreatePartModal_A_PagingNextPage" aria-label="Next" (click)="setPage(pager.currentPage + 1)"><span aria-hidden="true">&rsaquo;</span></a>
        </li>
        <li [ngClass]="{disabled:pager.currentPage == pager.totalPages}">
          <a id="CreatePartModal_A_PagingLastPage" aria-label="Last" (click)="setPage(pager.totalPages)"><span aria-hidden="true">&raquo;</span></a>
        </li>
      </ul>
    </div>
    <div class="pull-right">
      <span class="pagination-result">Showing {{pager.startIndex + 1}} - {{pager.endIndex + 1}} of
        {{pager.totalItems}} Results</span>
    </div>
  </nav>
</div>

<!--Create Part Module Box End -->
