<div class="couponPopover strong col-12 p-0" *ngLet="{currencyCode: currencyCode$ | async} as vm">
  <ul class="list-group">
    <li *ngIf="unitPrice" class="list-group-item">
      <div class="d-flex justify-content-between">
        <div class="col-md-8 popoverlabels">Unit Price</div>
        <div class="col-md-4 fw-bold">
          <span class="popovervalue text-danger">
            {{unitPrice || 0 | currency: vm.currencyCode :'symbol':'1.2-2'}}
          </span>
        </div>
      </div>
    </li>
    <li class="list-group-item popoverCouponHeader">
      <div class="col-12 fw-bold popoverlabels">
        <div class="d-flex justify-content-between">
          <div class="col-md-8 ps-0">
            Coupons
          </div>
        </div>
      </div>
    </li>
    <li id="pricing_li_Coupon{{idSuffix}}_{{i}}" class="list-group-item" *ngFor="let coupon of coupons; let i = index;">
      <div class="d-flex justify-content-between">
        <div class="col-md-8 popoverlabels">
          {{ coupon.description }}
        </div>
        <div class="col-md-4 fw-bold">
          <span id="pricing_span_CouponDiscountFlatAmount{{idSuffix}}_{{i}}" class="popovervalue">
            {{ coupon.amount * ( quantity > 0 ? quantity : 1 ) || 0 | currency: vm.currencyCode :'symbol':'1.2-2' }}
          </span>
        </div>
      </div>
    </li>
    <ng-container *ngFor="let programDiscounts of allProgramDiscounts">
      <li id="pricing_li_Coupon{{idSuffix}}_{{programDiscounts.program}}_discount_{{i}}" class="list-group-item" *ngFor="let discount of programDiscounts.discounts; let i = index">
        <div class="d-flex justify-content-between">
          <div class="col-md-8 popoverlabels">
            <ng-container [ngSwitch]="programDiscounts.program">
              <span *ngSwitchCase="'peterbilt'">Peterbilt Loyalty</span>
              <span *ngSwitchDefault>{{ programDiscounts.program }}</span>
            </ng-container>
            <span>-</span>
            <span>{{ discount.code }}</span>
          </div>
          <div class="col-md-4 fw-bold">
            <span id="pricing_span_Coupon{{idSuffix}}_discount_amount_{{i}}" class="popovervalue">
              {{ discount.amount * ( quantity > 0 ? quantity : 1 ) | currency: vm.currencyCode :'symbol':'1.2-2' }}
            </span>
          </div>
        </div>
      </li>
    </ng-container>
  </ul>
</div>
