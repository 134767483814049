import { Action, createReducer, on } from "@ngrx/store";
import * as ConfigurationActions from "store/configuration/configuration.actions";
import * as BranchActions from "../branch/branch.actions";
import * as CartActions from "../cart/cart.actions";
import * as CustomerActions from "./customer.actions";
import { CustomerState } from "./customer.state";

export const initialState: CustomerState = {
  findCustomerResults: [],
  selectedPayers: [
    {
      accountManager: '',
      accountManagerName: '',
      addressNumber: 'CASH',
      city: 'NEW BRAUNFELS',
      controlBranchCode: '',
      customerId: null,
      customerName: 'CASH CUSTOMER - RUSH',
      customerName2: '',
      customerNumber: '200000',
      customerPONumber: '',
      customerType: 'RG',
      isDefault: true,
      isPONumberRequired: false,
      mscAccountNumber: '',
      payerNotValidInBranch: false,
      phoneNumber: '',
      postalCode: '78130',
      pricingProcedure: 'Legacy',
      state: 'TX',
      streetAddress: ''
    }
  ],
  selectedCustomer: {
    accountManager: null,
    accountManagerName: null,
    controlBranchCode: null,
    customerId: null,
    customerNumber: null,
    customerPONumber: null,
    customerType: null,
    isDefault: false,
    isPONumberRequired: false,
    mscAccountNumber: null,
    payerNotValidInBranch: null,
    phoneNumber: null,
    pricingProcedure: null,
    customerName: null,
    customerName2: null,
    streetAddress: null,
    addressNumber: null,
    city: null,
    state: null,
    postalCode: null,
    mscPayer: null
  },
  isLoading: false,
  shipToCustomers: [],
  shipToIsLoading: false,
  selectedShipTo: null,
  shipToEditing: false,
  storedPaymentMethods: [],
  storedPaymentMethod: null,
  isShowingCreditCardAuth: false,
  isShowingAddCreditCard: false,
  isAddedPaymentMethod: false,
  isInviteToPay: false,
  inviteToPayType: null,
  inviteToPayEmail: null,
  inviteToPaySms: null,
  selectedPaymentMethod: null,
  inviteToPayResendLoading: false,
  favoriteCustomers: []
};

export const customerReducer = createReducer<CustomerState, Action>(
  initialState,
  on(ConfigurationActions.loadCacheSuccess, (state, {appstate}) => {
    return {...appstate.customer};
  }),
  on(CustomerActions.findCustomersSuccess, (state, { customer, payers }) => {
    return ({
      ...state,
      selectedCustomer: {...customer, mscPayer: null},
      findCustomerResults: [customer],
      selectedPayers: [...payers],
      shipToCustomers: []
    })
  }),
  on(CustomerActions.selectCustomer, (state, { customer }) => ({
    ...state,
    selectedCustomer: { ...customer, mscPayer: null },
    shipToCustomers: []
  })),
  on(CustomerActions.clearCustomer, (state) => ({
    ...state,
    shipToCustomers: []
  })),
  on(
    CustomerActions.getPayers, (state) => ({
    ...state,
    isLoading: true
  })),
  on(
    CustomerActions.getPayersForQuoteFailed,
    CustomerActions.getPayersFailed, (state) => ({
    ...state,
    isLoading: false
  })),
  on(
    CustomerActions.getPayersForQuoteSuccess,
    CustomerActions.getPayersSuccess, (state, { payers }) =>
      ({
        ...state,
        payers: [...payers],
        selectedPayers: [...payers],
        storedPaymentMethod: null,
        isShowingCreditCardAuth:false,
        isShowingAddCreditCard:false,
        isAddedPaymentMethod:false,
        isInviteToPay:false,
        inviteToPayType:null,
        inviteToPayEmail:null,
        inviteToPaySms:null,
        selectedPaymentMethod:"BillTo",
        isLoading: false
      })
    ),
  on(BranchActions.selectBranch,
    (state) => ({
      ...state,
      selectedCustomer: { ...initialState.selectedCustomer }
  })),
  on(CartActions.submitOrderSuccess, (state ) => ({
    ...state,
    selectedShipTo: null,
    shipToEditing: false
  })),
  on(CustomerActions.setDefaultCustomer, (state, { defaultCustomer }) => ({
    ...state,
    selectedCustomer: {...defaultCustomer, mscPayer: null}
  })),
  on(CustomerActions.loadShipToCustomers, (state) => ({
    ...state,
    shipToIsLoading: true
  })),
  on(CustomerActions.cancelLoadShipToCustomers, (state) => ({
    ...state,
    shipToIsLoading: false
  })),
  on(CustomerActions.loadShipToCustomersSuccess, (state, { customers }) => ({
    ...state,
    shipToCustomers: customers.map(customer => ({
        ...customer,
        salesperson: customer.salesperson ||
                        (customer.accountManagerName && customer.accountManager) ?
                        `${customer.accountManagerName} (${customer.accountManager})`
                        : null
    })),
    selectedShipTo: null,
    shipToIsLoading: false
  })),
  on(CustomerActions.setShipToAddress, (state, { shipToAddress }) => ({
    ...state,
    selectedShipTo: shipToAddress
  })),
  on(CustomerActions.setShipToEditing, (state, { shipToEditing }) => ({
    ...state,
    shipToEditing
  })),
  on(CustomerActions.setShipToAddressCancel, CustomerActions.loadShipToCustomersFailure, (state) => ({
    ...state,
    shipToIsLoading: false
  })),
  on(CartActions.selectCartCustomerSuccess, (state, {customer, payers}) => ({
    ...state,
    shipToIsLoading: false,
    selectedCustomer: {...customer, mscPayer: null},
    selectedPayers: payers
  })),
  on(CustomerActions.getFavoriteCustomersSuccess, (state, {favoriteCustomers}) => ({
    ...state,
    favoriteCustomers: [...favoriteCustomers]
  })),
  on(CustomerActions.addFavoriteCustomerSuccess, (state, {customer}) => ({
    ...state,
    favoriteCustomers: [...state.favoriteCustomers, customer]
  })),
  on(CustomerActions.removeFavoriteCustomerSuccess, (state, {customerId}) => ({
    ...state,
    favoriteCustomers: [...state.favoriteCustomers.filter(x => x.customerId !== customerId)]
  })),
  on(CustomerActions.loadCustomerStateFromCache, (state, {customerData}) => ({...customerData})),
  on(CustomerActions.getMscPayersSuccess, (state, mscPayer) => ({...state, selectedCustomer: { ...state.selectedCustomer, mscPayer }})),
  on(CustomerActions.getStoredPaymentMethods, (state) => ({
    ...state,
    isLoading: true
  })),
  on(CustomerActions.getStoredPaymentMethodsFailed, (state) => ({
    ...state,
    isLoading: false
  })),
  on(CustomerActions.getStoredPaymentMethodsSuccess, (state, { storedPaymentMethods }) => ({
    ...state,
    storedPaymentMethods: [...storedPaymentMethods],
    isLoading: false
  })),
  on(CustomerActions.getStoredPaymentMethodsEmpty, (state) => ({
    ...state,
    storedPaymentMethods: [],
    isLoading: false
  })),
  on(CustomerActions.setStoredPaymentToken, (state, { storedPaymentMethod }) => ({
    ...state,
    storedPaymentMethod: storedPaymentMethod || null,
    isInviteToPay: false,
    isAddedPaymentMethod: false
  })),
  on(CustomerActions.showAddPaymentMethod, (state, { showiFrame }) => ({
    ...state,
    isShowingAddCreditCard: showiFrame
  })),
  on(CustomerActions.inviteToPay, (state) => ({
    ...state,
    storedPaymentMethod: null,
    isInviteToPay: true,
    isAddedPaymentMethod: false
  })),
  on(CustomerActions.addPaymentMethod, (state) => ({
    ...state,
    storedPaymentMethod: null,
    isInviteToPay: false,
    isAddedPaymentMethod: true
  })),
  on(CustomerActions.setInviteToPay, (state, { inviteToPayType, email, sms }) => ({
    ...state,
    inviteToPayType: inviteToPayType,
    inviteToPayEmail: email,
    inviteToPaySms: sms,
  })),
  on(CustomerActions.clearInviteToPay, (state) => ({
    ...state,
    inviteToPayType: null,
    inviteToPayEmail: null,
    inviteToPaySms: null,
  })),
  on(CustomerActions.setSelectedPaymentMethod, (state, { paymentMethod }) => ({
    ...state,
    selectedPaymentMethod: paymentMethod,
  })),
  on(CustomerActions.inviteToPayResend, (state) => ({
    ...state,
    inviteToPayResendLoading: true
  })),
  on(CustomerActions.inviteToPayResendSuccess, CustomerActions.inviteToPayResendFailed, (state) => ({
    ...state,
    inviteToPayResendLoading: false
  })),
);
