<div class="modal-header">
  <div class="title-of-box-hold w-100">
    <h5 class="strong">General Request</h5>
  </div>
</div>
<div class="modal-body">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="mb-4">
      <div class="row justify-content-between" *ngIf="!updateGeneralRequest; else readOnly">
        <div class="col-6">
          <label class="form-label" for="general-request-order-number">Order #</label>
          <input id="general-request-order-number" placeholder="Order Number" name="orderNumber" class="form-control" [ngClass]="{ 'form-invalid': form.controls.orderNumber.touched && form.controls.orderNumber.invalid }"
            type="text" digitsOnly formControlName="orderNumber" maxlength="10"/>
          <div class="text-danger" id="order-required-text" *ngIf="form.controls.orderNumber.touched && form.controls.orderNumber.invalid">
            Order number is required
          </div>
        </div>
        <div class="col-6">
          <label class="form-label" for="general-request-department">Department<span class="required">*</span></label>
          <select id="general-request-department" name="department" class="form-select" formControlName="department" [ngClass]="{ 'form-invalid': form.controls.department.touched && form.controls.department.invalid }">
            <option value="" selected disabled>Select department</option>
            <option value="14">Body Shop</option>
            <option value="12">Mobile</option>
            <option value="12">Service</option>
          </select>
          <div class="text-danger" *ngIf="form.controls.department.touched && form.controls.department.invalid">
            Department is required
          </div>
        </div>
      </div>
      <ng-template #readOnly>
        <div class="row">
          <b class="pr-2 col-2">Request #:</b><b class="col-6">{{orderNumber}}</b>
        </div>
        <div class="row">
          <b class="pr-2 col-2">Order #:</b><b class="col-6">{{refOrderNumber}}</b>
        </div>
        <div class="row">
          <b class="pr-2 col-2">Department:</b><b class="col-6">{{department | bctDepartment}}</b>
        </div>
        <div class="row">
          <b class="pr-2 col-2">Submitter:</b><b class="col-6">{{requestor}}</b>
        </div>
      </ng-template>
    </div>
    <div *ngIf="updateGeneralRequest" id="general-request-messages" class="row" #generalRequestMessagesContainer>
      <div class="w-100">
        <div class="w-100">
          <div class="card mb-4" *ngFor="let note of notes;" #generalRequestMessages>
            <div class="card-header d-flex justify-content-between p-3">
              <div class="w-100" *ngIf="note.techName !== null || note.userId !== null; else pendingNote">
                <div class="row">
                  <div class="col-6">
                    <p class="fw-bold mb-0">{{note.techName ? note.techName : note.userId}}</p>
                  </div>
                  <div class="col-6 right-text-align">
                    <p class="text-muted small mb-0">
                      <i class="far fa-clock"></i>
                      {{note.time}} <br>
                      <i class="far fa-calendar"></i>
                      {{note.date}}
                    </p>
                  </div>
                </div>
              </div>
              <ng-template #pendingNote>
                <p>Pending Note</p>
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </ng-template>

            </div>
            <div class="card-body">
              <pre class="mb-0">
                {{note.noteText}}
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!isComplete">
      <div class="col-12">
        <label class="form-label" for="general-request-note">Note<span class="required" *ngIf="!updateGeneralRequest">*</span></label>
        <textarea placeholder="Enter Notes Here" class="form-control" id="general-request-note" name="note" rows="3" formControlName="note" [ngClass]="{ 'form-invalid': form.controls.note.touched && form.controls.note.invalid }"></textarea>
        <div class="text-danger" *ngIf="form.controls.note.touched && form.controls.note.invalid && !updateGeneralRequest">
          Note is required
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-secondary" id="general-request-cancel" (click)="dismissModal()">Cancel</button>
  <button id="general-request-submit" (click)="submit()" class="btn btn-primary" type="submit" [disabled]="form.invalid || isComplete">Submit</button>
  <button id="general-request-complete" class="btn btn-secondary" [disabled]="!updateGeneralRequest || isComplete" (click)="complete()">Complete</button>
</div>