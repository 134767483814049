import { Component, Input, EventEmitter, Output } from "@angular/core";
import { FeatureFlagService } from "services/feature-flag.service";
import { BackCounterRequestsFilter } from "store/back-counter/back-counter.entities";

@Component({
  selector: "app-filters",
  templateUrl: "./filters.component.html",
  styleUrls: ["./filters.component.scss"]
})
export class FiltersComponent {

  @Input()
  filters: BackCounterRequestsFilter[] = [];

  @Input()
  branches: string[] = [];

  @Output()
  onChange: EventEmitter<BackCounterRequestsFilter[]> = new EventEmitter();

  @Output()
  onReset: EventEmitter<void> = new EventEmitter();

  constructor(
    private featureFlagService: FeatureFlagService
  ){}

  public applyFilters(): void {
    this.onChange.emit(this.filters);
  }

  public resetFilters(): void {
    this.onReset.emit();
  }

  public updateMultiSelectValue(event, filter) {
    filter.value = event;
  }

  public updateDate(event, filter: BackCounterRequestsFilter): void {
    if (filter.overwriteValue) {
      // TODO: Remove this boolean
      const US130180_enabled = true;
      this.filters = filter.overwriteValue(event.target.value, filter, this.filters, US130180_enabled);
    } else {
      filter.value = event.target.value;
    }
  }

  get activeFilters(): number {
    return this.filters.filter(f => f.active).length;
  }

  public updateFilterValue(event, prop: string): void {
    this.filters = this.filters.map(f => f.prop === prop ? { ...f, value: event } : f);
  }

  onFilterKeypressNames(event: KeyboardEvent, includeComma: boolean = true) {
    let regex = new RegExp('[a-zA-Z0-9\', ]');
    if (!includeComma) {
      regex = new RegExp('[a-zA-Z0-9\' ]');
    }
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }

  onFilterKeypressNumeric(event: KeyboardEvent, includeComma: boolean = true) {
    let regex = new RegExp('[0-9, ]');
    if (!includeComma) {
      regex = new RegExp('[0-9 ]');
    }
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }

  onInputFilterValueChange(event, filter): void {
    const filterVal = filter.value;
    const temp = filterVal.split(",").map((v: string) => v.substring(0, 10).trim());
    const outValue = temp.toString();
    filter.value = new String(outValue);
  }

}
