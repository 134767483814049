import { Injectable } from '@angular/core';
import { BaseService } from 'services/base.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { OrderResult } from 'entities/order-result';
import { ErrorInformation } from 'entities/error-information';
import { ResultMessage } from 'entities/result-message';
import { CheckoutTransactionData } from 'entities/carts/checkout-transaction-data';
import { CloseQuoteReasonParameters } from 'entities/quotes/close-quote-reason-parameters';
import { CartResult } from 'entities/cart-result';


@Injectable({
  providedIn: "root"
})
export class QuoteService extends BaseService {

  private readonly quoteUrl: string = "api/quotes/";

  constructor(private httpClient: HttpClient) {
    super();
  }

  closeQuote(closeQuoteParameters: CloseQuoteReasonParameters): Observable<ResultMessage & ErrorInformation> {
    return this.httpClient.put<ResultMessage & ErrorInformation>(`${this.quoteUrl}close`, closeQuoteParameters)
  }

  markQuoteLineItemClose(closeQuoteReasonParameters: CloseQuoteReasonParameters): Observable<CartResult> {
    return this.httpClient.put<CartResult>(`${this.quoteUrl}items/close`, closeQuoteReasonParameters);
  }

  placeQuote(quote: CheckoutTransactionData): Observable<OrderResult> {
    return this.httpClient.post<OrderResult>(`${this.quoteUrl}`, quote);
  }

  updateQuote(cartId: string, orderId: string): Observable<CartResult> {
    const url: string = `${this.quoteUrl}?cartId=${cartId}&orderId=${orderId || ""}`;
    return this.httpClient.put<CartResult>(`${this.quoteUrl}?cartId=${cartId}&orderId=${orderId || ""}`, {});
  }

  printSAPQuote(orderId: string, isPrintQuote: boolean): Promise<any> {
    const url: string = `${this.quoteUrl}sap/print?orderId=${orderId}&isPrintQuote=${isPrintQuote}`;
    return this.httpClient.get(url)
      .toPromise()
      .catch(this.handleError);
  }

  printSAPQuoteFromCheckout(orderNumber: string): Observable<any> {
    const url: string = `${this.quoteUrl}sap/print/checkout?orderNumber=${orderNumber}&isPrintQuote=${true}`;
    return this.httpClient.get(url);
  }
}
