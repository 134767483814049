<div class="items-multiselect">

  <div class="input-group" ngbDropdown
    [autoClose]="true" display="dynamic" placement="bottom-left"
    #itemsDropdown="ngbDropdown" (openChange)="onMenuToggle($event)">
    <div class="position-relative d-block w-100" (click)="itemsDropdown.open();" style="cursor: pointer;">
      <div class="form-control">
        <span *ngIf="!selectedItems.length">{{ label }}</span>
        <div *ngFor="let item of selectedItems; trackBy: trackById" class="item-tag">
          <span>{{ item.label }}</span>
          <button type="button" (click)="removeItem($event, item.value)">
            <span aria-hidden="true">x</span>
          </button>
        </div>
      </div>
    </div>
    <div class="items-menu w-100" ngbDropdownMenu>
      <div class="items-options-wrapp">
        <a class="d-flex flex-row" href="return false;"
          *ngFor="let item of items; trackBy: trackById; let i = index;"
          (click)="toggleItemSelection($event, item)">
          <input id="item-{{index}}" type="checkbox" [checked]="item.selected" />
          <label style="margin-left: .2rem;">{{item.label }}</label>
        </a>
      </div>
    </div>
  </div>

</div>
