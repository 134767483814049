import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as HeaderSearchActions from 'app/_components/header-search/header-search.component.actions';
import { HotFlagComponent } from 'app/_components/hot-flag/hot-flag.component';
import { KnownPartBuyoutComponent } from 'app/_components/known-part-buyout/known-part-buyout.component';
import { StockTransferOrderComponent } from 'app/_components/stock-transfer-order/stock-transfer-order.component';
import { AppInsightOrder } from 'entities/app-insight-order';
import { AppInsightQuote } from 'entities/app-insight-quote';
import { Branch } from 'entities/branch';
import { CartResult, CartResultLineClient, CartResultLineItem } from 'entities/cart-result';
import { CreateCart, DeliveryTypes } from 'entities/cart-search';
import { AddCartItemRequest } from 'entities/carts/add-cart-item-request';
import { AddStoCartItemRequest } from 'entities/carts/add-sto-cart-item-request';
import { CheckoutTransactionData } from 'entities/carts/checkout-transaction-data';
import { ItemToRemoveFromCart } from 'entities/carts/item-to-remove-from-cart';
import { RemoveItemsFromCartRequest } from 'entities/carts/remove-items-from-cart-request';
import { SetCartItemPriceRequest } from 'entities/carts/set-cart-item-price-request';
import { UpdateCartItemFlagRequest } from 'entities/carts/updat-cart-item-flag-request';
import { UpdateFlagCartItem } from 'entities/carts/update-flag-cart-item';
import { CreateFavoritePartRequest } from 'entities/create-favorite-part-request';
import { Customer } from 'entities/customer';
import { LoyaltyDiscountSearchCriteria } from 'entities/loyalty-account';
import { OrderResult } from 'entities/order-result';
import { OrderType } from 'entities/order-type';
import { Part } from 'entities/part';
import { FavoritePartView } from 'entities/parts/favorite-part-view';
import { InviteToPayTypeEnum, PaymentTypeEnum } from 'entities/payment-choice';
import { MscPriceVerifyPart } from 'entities/price-verify/msc-price-verify-part';
import { CloseQuoteReasonParameters } from 'entities/quotes/close-quote-reason-parameters';
import { StoredPaymentMethod } from 'entities/stored-payment-method';
import { ToastType } from 'entities/toast-type';
import { SetTransactionTypeRequest } from 'entities/transaction-type';
import { UpdateCart } from 'entities/update-cart';
import { blankId } from 'helpers/blank-id';
import { getHeaderDelivery } from 'helpers/get-header-delivery';
import { isCashCustomer } from 'helpers/is-cash-customer';
import { CloseReasonModalComponent } from 'modals/close-reason-modal/close-reason-modal.component';
import { DeleteCartConfirmModalComponent } from 'modals/delete-cart-confirm-modal/delete-cart-confirm-modal.component';
import { LineItemNotesModalComponent } from 'modals/line-item-notes-modal/line-item-notes-modal.component';
import { NationalInventoryModalComponent } from 'modals/national-inventory-modal/national-inventory-modal.component';
import { PartBinLocationsModalComponent } from 'modals/part-bin-locations-modal/part-bin-locations-modal.component';
import { PriceOverrideModalComponent } from 'modals/price-override-modal/price-override-modal.component';
import { PriceVerifyModalComponent } from 'modals/price-verify-modal/price-verify-modal.component';
import { ViewAllPartsComponent } from "modals/view-all-parts/view-all-parts.component";
import { EMPTY, Observable, combineLatest, forkJoin, of } from 'rxjs';
import { catchError, debounceTime, filter, map, pairwise, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CartService } from 'services/cart.service';
import { CheckoutFormService } from 'services/checkout-form.service';
import { CustomerService } from 'services/customer.service';
import { DownloadService } from 'services/download.service';
import { FavoritePartsService } from 'services/favorite-parts.service';
import { FeatureFlagService } from 'services/feature-flag.service';
import { LoaderService } from 'services/loader.service';
import { LoggerService, SourceLocationType } from 'services/logger.service';
import { ModalService } from 'services/modal.service';
import { MyDashboardService } from 'services/my-dashboard.service';
import { PartService } from 'services/part.service';
import { QuoteService } from 'services/quote.service';
import { ToastService } from 'services/toast.service';
import { VendorService } from 'services/vendor.service';
import { AppState } from 'store/app-state';
import * as BasketActions from 'store/basket/basket.actions';
import * as BasketSelectors from "store/basket/basket.selectors";
import * as BranchActions from 'store/branch/branch.actions';
import * as BranchSelectors from 'store/branch/branch.selectors';
import * as CartSelectors from 'store/cart/cart.selectors';
import * as ConfigurationActions from 'store/configuration/configuration.actions';
import * as ConfigurationSelectors from 'store/configuration/configuration.selectors';
import * as CustomerActions from 'store/customer/customer.actions';
import * as CustomerSelectors from 'store/customer/customer.selectors';
import * as FeatureFlagSelectors from 'store/feature-flags/feature-flags.selectors';
import * as HomeActions from "store/home/home-page.actions";
import * as LoyaltyActions from "store/loyalty/loyalty.actions";
import * as LoyaltySelectors from 'store/loyalty/loyalty.selectors';
import * as MyCartActions from "store/my-cart/my-cart.actions";
import * as SystemActions from 'store/system.actions';
import * as PunchoutReturnComponentActions from '../../_components/punchout-return/punchout-return.component.actions';
import * as CartActions from './cart.actions';

@Injectable()
export class CartEffects {
  getOpenCarts$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.getOpenCarts),
      switchMap(
        () => this.cartService.getOpenCarts().pipe(
          map(getOpenCartsResult => CartActions.getOpenCartsSuccess({ getOpenCartsResult })),
          catchError((error) => of(CartActions.getOpenCartsFailed({ error })))
        )
      )
    )
  );

  selectOpenCart$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.selectOpenCart),
      tap(() => {
        this.loaderService.loading = true
      }),
      switchMap(({ openCartId, branchCode, customerNumber, navigateToMyCart }) => {
        return combineLatest([
          of(navigateToMyCart),
          openCartId != null ?
            this.cartService.getCartConsolidatedData(openCartId) :
            this.cartService.getOrCreateCart({ branchCode, customerNumber, forceCreate: false })
        ])
      }), catchError((_, caught) => {
        this.loaderService.setLoading(false);
        return caught;
      }),
      withLatestFrom(
        this.store.select(BranchSelectors.selectBranchesForOrdering),
      ),
      switchMap(([[navigateToMyCart, cartResult], branchesForOrdering]) => {
        const branchCode = cartResult.branchCode;
        const branch = branchesForOrdering.find((x) => x.code === branchCode);
        return this.findCartCustomer(cartResult.customerNumber, cartResult.branchCode).pipe(
          tap(([customers, payers]) => {
            if (navigateToMyCart) {
              this.router.navigate(["/cart"]);
            }
            this.loaderService.setLoading(false);
            this.store.dispatch(CartActions.selectOpenCartSuccess({ branch, customers, cartResult, payers }));
          }),
          catchError((error, caught) => {
            this.loaderService.setLoading(false);
            this.store.dispatch(CustomerActions.findCustomersFailed({ error }));
            return caught;
          }),
        )
      }), catchError((error, caught) => {
        this.loaderService.setLoading(false);
        this.store.dispatch(CartActions.getCartDataFailed({ error }));
        return caught;
      })
    ),
    { dispatch: false }
  );

  selectOpenCartSuccess$ = createEffect(() => this.action$.pipe(
    ofType(CartActions.selectOpenCartSuccess),
    tap(({ branch, customers, cartResult, payers }: { branch?: Branch, customers?: Customer[], cartResult?: CartResult, payers: Customer[]}) => {
      if (branch != null) {
        this.store.dispatch(CartActions.selectCartBranchSuccess({ branch }));
      }
      const cartCustomer = customers?.find(c => c.customerNumber === cartResult?.customerNumber);
      if (cartCustomer) {
        this.store.dispatch(CartActions.selectCartCustomerSuccess({ customer: cartCustomer, payers }));
      }
      if (cartResult) {
        this.store.dispatch(CartActions.selectCartSuccess({ cart: cartResult }));
      }
      if (!cartCustomer && branch === null) {
        this.store.dispatch(CartActions.selectCartBranchAndCustomerFailed({ error: "No Customer Found" }));
      }
    })
  ),
    { dispatch: false }
  );

  selectCartOnBranchHeaderChange$ = createEffect(() => this.action$.pipe(
    filter(() => this.router.url !== "/"),
    ofType(BranchActions.selectBranch),
    filter(({ isPreventSelectCart }) => isPreventSelectCart !== true),
    tap(() => this.loaderService.setLoading(true)),
    withLatestFrom(this.store.select(ConfigurationSelectors.selectCustomer)),
    switchMap(([{ branch }, defaultCustomer]) => this.cartService.getCarts({
      branchCode: branch.code,
      customerNumber: defaultCustomer.customerNumber
    }).pipe(catchError((_, caught) => caught))),
    map((cartResults: CartResult) => CartActions.selectCartSuccess({
      cart: {
        ...cartResults,
        lineItems: cartResults.lineItems ? cartResults.lineItems : []
      }
    })),
    tap(() => this.loaderService.setLoading(false))
  ));

  selectCartOnCustomerHeaderChange$ = createEffect(() => this.action$.pipe(
    filter(() => this.router.url !== "/"),
    ofType(CustomerActions.findCustomersSuccess),
    tap(() => this.loaderService.setLoading(true)),
    withLatestFrom(this.store.select(BranchSelectors.selectedBranch)),
    switchMap(([{ customer }, branch]) => this.cartService.getOrCreateCart({
      branchCode: branch.code,
      customerNumber: customer.customerNumber,
      forceCreate: false
    }).pipe(catchError((_, caught) => caught))),
    map((cartResults: CartResult) => CartActions.selectCartSuccess({
      cart: {
        ...cartResults,
        lineItems: cartResults.lineItems ? cartResults.lineItems : []
      }
    })),
    tap(() => this.loaderService.setLoading(false))
  ));

  selectDefaultCustomer$ = createEffect(() => this.action$.pipe(
    ofType(HeaderSearchActions.selectDefaultCustomer),
    tap(() => this.loaderService.setLoading(true)),
    withLatestFrom(
      this.store.select(ConfigurationSelectors.selectCustomer),
      this.store.select(BranchSelectors.selectedBranch)
    ),
    switchMap(([_, defaultCustomer, branch]) => this.findCartCustomer(defaultCustomer.customerNumber, branch.code).pipe(
      map(([customers, payers]) => CustomerActions.findCustomersSuccess({
        customer: customers[0],
        payers
      }),
        catchError((error) => of(CartActions.getCartDataFailed({ error })))
      ))
    )
  ));

  deleteCart$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.deleteCart),
      switchMap(({ cartId, routeTo }) => this.modalObservable.open(DeleteCartConfirmModalComponent, {}, { size: 'sm' }).pipe(
        switchMap(
          isDelete => {
            if (isDelete) {
              return this.cartService.deleteCart(cartId).pipe(
                map(cartId => {
                  routeTo !== null ? this.router.navigate([routeTo]) : null
                  return CartActions.deleteCartSuccess({ cartId });
                }),
                catchError((error) => of(CartActions.deleteCartFailed({ error })))
              )
            } else {
              return EMPTY;
            }
          }
        )
      )
      )
    )
  );

  addItemToCart$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.addItemToCart),
      withLatestFrom(
        this.store.select(CartSelectors.selectedCartId),
        this.store.select(BranchSelectors.selectedBranch),
        this.store.select(CustomerSelectors.selectedCustomer),
        this.store.select(ConfigurationSelectors.isInternal),
        this.store.select(CustomerSelectors.selectShipTo)
      ),
      switchMap(
        ([{ addCartItemRequest, sourceLocationType }, cartId, branch, customer, isInternal, shipTo]) => {
          const isCashCust = isCashCustomer(customer.customerNumber);
          const hasSelectedCart = this.hasSelectedCart(cartId);
          if(hasSelectedCart){
            return this.addItemToCart(cartId, {...addCartItemRequest}, isInternal, sourceLocationType);
          } else {
            const updateShipToRequest = !isCashCust ? shipTo: null;
            return this.cartService
                .getOrCreateCart({ branchCode: branch.code, customerNumber: customer.customerNumber, forceCreate: false, updateShipToRequest }).pipe(
                  switchMap((cartResult: CartResult) => {
                      this.store.dispatch(CartActions.getCartDataSuccess({ cartResult }));
                      return this.addItemToCart(cartResult.cartId, {...addCartItemRequest, updateShipToRequest}, isInternal, sourceLocationType);
                    }
                  ),
                  catchError((error) => of(CartActions.addItemToCartFailed({ error })))
                );
          }
        }
      ),
    )
  );

  addItemToCart(
    cartId: string,
    addCartItemRequest: AddCartItemRequest,
    isInternal: boolean,
    sourceLocationType: SourceLocationType) {
    return this.cartService.addToCart(cartId, addCartItemRequest).pipe(
      tap((cartInfoResponse: CartResult) => {
        const notifyMessage = `${addCartItemRequest.partNumber} ${addCartItemRequest.description} with ${addCartItemRequest.quantity} quantity added successfully.`;
        if (addCartItemRequest.quantity > addCartItemRequest.quantityAvailable && isInternal && sourceLocationType === SourceLocationType.FeaturedProduct) {
          const procurementMessage =
            "You are requesting a greater quantity than is available at your branch. " +
            "You will need to procure the remaining before you can place your order.";
          this.toastService.showMultilineToast([
            { message: notifyMessage, type: ToastType.Success },
            { type: ToastType.Warning, message: procurementMessage }
          ]);
        } else {
          this.toastService.showToast(notifyMessage, ToastType.Success);
        }
        return cartInfoResponse;
      }),
      map(cart => CartActions.addItemToCartSuccess({ cart })),
      catchError((error) => {
        this.toastService.errorMessage(
          SourceLocationType.Cart.toString(),
          "addToCart",
          "addToCart",
          error.statusText
        );

        return of(CartActions.addItemToCartFailed({ error }));
      }

      )
    );
  }

  addFavoritePartToCart$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.addFavoritePartsToCart),
      tap(() => this.loaderService.setLoading(true)),
      withLatestFrom(
        this.store.select(CartSelectors.selectedCartId),
        this.store.select(BranchSelectors.selectedBranch),
        this.store.select(CustomerSelectors.selectedCustomer)
      ),
      switchMap(
        ([{ favoritePartsToAdd }, cartId, branch, customer]) => {
          if (!this.hasSelectedCart(cartId)) {
            const createCartRequest: CreateCart = {
              branchCode: branch.code,
              customerNumber: customer.customerNumber,
              forceCreate: false
            }
            return this.cartService.getOrCreateCart(createCartRequest).pipe(
              switchMap(cartResult => {
                this.store.dispatch(CartActions.selectCartSuccess({ cart: cartResult }));
                return this.addFavoritesToCart(cartResult.cartId, favoritePartsToAdd);
              }),
              catchError(error => of(CartActions.addFavoritePartsToCartFailed({ error }))),
              tap(() => this.loaderService.setLoading(false))
            )
          } else {
            return this.addFavoritesToCart(cartId, favoritePartsToAdd);
          }
        }
      )
    )
  )

  addFavoritesToCart(cartId: string, selectedFavoriteParts: FavoritePartView[]) {
    return this.cartService.addFavoritesToCart(cartId, selectedFavoriteParts).pipe(
      map(cart => CartActions.addFavoritePartsToCartSuccess({ cart })),
      catchError(error => of(CartActions.addFavoritePartsToCartFailed({ error }))),
      tap(() => this.loaderService.setLoading(false))
    )
  }


  getPartBinLocations$ = createEffect(() =>
    this.action$.pipe(
      ofType(
        CartActions.addItemToCartSuccess,
        CartActions.updateCartItemSuccess,
        CartActions.getCartDataSuccess,
        CartActions.selectCartSuccess,
        CartActions.removeItemsFromCartSuccess,
        CartActions.deleteCartSuccess,
        CartActions.updatePONumberSuccess,
        CartActions.updateAlternateEmailSuccess,
        CartActions.updateBillToSuccess,
        CartActions.updateShipToSuccess,
        CartActions.setDeliverySuccess,
        CartActions.updateSpecialInstructionSuccess,
        CartActions.getBinLocations,
        BasketActions.addBasketToCartSuccess
      ),
      withLatestFrom(
        this.store.select(CartSelectors.selectCartItems),
        this.store.select(BranchSelectors.selectedBranch)
      ),
      filter(([_, cartItems]) => !!cartItems),
      switchMap(
        ([_, cartItems, branch]) => this.partService.getPartBinLocations(cartItems?.map((x) => x.partNumber), branch.code)
          .pipe(
            map(partBinLocations => CartActions.getPartBinLocationsSuccess({ partBinLocations })),
            catchError(error => of(CartActions.getPartBinLocationsFailed({ error })))
          )
      )
    )
  );

  openBinLocationsModal$ =
    createEffect(() =>
      this.action$.pipe(
        ofType(CartActions.opetBinLocationsModal),
        tap((action) => {
          const modalRef = this.modalService.open(PartBinLocationsModalComponent);
          modalRef.componentInstance.partBinLocationItems = action.partBinLocations;
        })
      ),
      { dispatch: false }
    )

  updateCartItem$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.updateCartItem),
      tap(() => this.loaderService.setLoading(true)),
      withLatestFrom(this.store.select(BasketSelectors.selectBasketPartsQuantityAvailable)),
      switchMap(
        ([{ updatedCartItem }, basketList]) => {
          const updateForMycart: UpdateCart = this.createUpdateCartRequest(updatedCartItem);
          if(updatedCartItem.binLocation?.includes("SB-")){
            const binNumber = updatedCartItem.binLocation.split("SB-").pop();
            const shoppingBasketQuantity = basketList[binNumber][updatedCartItem.partNumber];
            updateForMycart.shoppingBasketQuantity = shoppingBasketQuantity;
            if (!updatedCartItem.isSplit) {
              if(updateForMycart.shoppingBasketQuantity < updateForMycart.quantity + updateForMycart.quantityDelta){
                updateForMycart.isRequestSplit = true;
              }
            }
          }
          return this.cartService.updateCartItem(updateForMycart)
            .pipe(
              map((updatedCart: CartResult) => CartActions.updateCartItemSuccess({ cart: updatedCart })),
              catchError(error => of(CartActions.updateCartItemFailed({ error }))),
              tap(() => this.loaderService.setLoading(false))
            )
        }
      )
    )
  )

  loadCartForQuoteSuccess$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.loadCartForQuoteSuccess),
      switchMap(({cartResult}) => this.myDashboardService.IsOpenCartQuoteOrder(cartResult.cartId))
    ), {
      dispatch: false
  });

  loadQuoteIntoCart$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.loadCartForQuote),
      tap(() => this.loaderService.setLoading(true)),
      withLatestFrom(
        this.store.select(ConfigurationSelectors.selectUserInfo)
      ),
      switchMap(([{ cartId }, { id }]) =>
        this.cartService.getCartConsolidatedData(cartId)
          .pipe(
            map((cartResult: CartResult) => {
              if(id !== cartResult.updatedById && cartResult.isLocked) {
                const message = `This Quote is locked by ${cartResult.updatedByEmail}. Please refresh the dashboard or wait until another user unlocks it.`;
                this.toastService.showToast(message, ToastType.Error);
                return CartActions.loadCartForQuoteFailed({error: null});
              }
              this.router.navigate(['/checkout']);
              return CartActions.loadCartForQuoteSuccess({ cartResult });
            }),
            catchError((error) => of(CartActions.loadCartForQuoteFailed({ error }))),
            tap(() => this.loaderService.setLoading(false))
          )
      )
    )
  );

  setDelivery$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.setDelivery),
      tap(() => {
        if(this.router.url.includes("checkout")){
          this.loaderService.setLoading(true);
        }
      }),
      withLatestFrom(
        this.store.select(BranchSelectors.selectedBranch),
        this.store.select(CartSelectors.selectedCartId),
        this.store.select(CustomerSelectors.selectedCustomer),
        this.store.select(CartSelectors.selectCartPartItems)
      ),
      switchMap(([{ deliveryOptionHeader, deliveryLineItems }, branch, cartId, customer, cartItems]) => {
        let updatedDeliveryLineItems = deliveryLineItems || cartItems.map((item) => ({
          cartItemId: item.cartItemId,
          deliveryOption: item.lineId === 801 || item.lineId === 901 ? '' : deliveryOptionHeader
        }));
        return this.cartService
          .setDelivery({
            branchCode: branch.code,
            cartId,
            customerNumber: customer.customerNumber,
            headerDeliveryType: deliveryOptionHeader,
            deliveryTypes: updatedDeliveryLineItems.reduce((acc, item) => ({ ...acc, [item.cartItemId]: item.deliveryOption }), {}),
            addDeliveryFees: true
          })
          .pipe(
            map((cart: CartResult) => {
              return CartActions.setDeliverySuccess({ cart })
            }),
            catchError((error) => {
              return of(CartActions.setDeliveryFailed({ error }))
            }),
            tap(() => {
              this.loaderService.setLoading(false);
            })
          );
      })
    )
  );

  updateDeliveryAfterCartItemAddToCart$ = createEffect(() =>
    this.action$.pipe(
      ofType(
        BasketActions.addBasketToCartSuccess,
        CartActions.addItemToCartSuccess,
        CartActions.addItemToCartFromPartsBuyoutSuccess
      ),
      withLatestFrom(
        this.store.select(CartSelectors.selectDeliverySummary)
      ),
      tap(([{ cart }, deliverySummary]) => {
        if (!!cart.deliveryType && !deliverySummary.multipleOptionsSelected) {
          this.store.dispatch(CartActions.setDelivery({ deliveryOptionHeader: cart.deliveryType as DeliveryTypes }))
        };
      })
    ),
    { dispatch: false }
  );


  updatePONumber$ = createEffect(
    () => this.action$.pipe(
      ofType(CartActions.updatePONumber),
      withLatestFrom(
        this.store.select(CartSelectors.selectCart),
        this.store.select(CartSelectors.selectPONumber)
      ),
      switchMap(
        ([{ poNumber }, cart, currentPONumber]) => {
          if (currentPONumber === poNumber) {
            return of(CartActions.updatePONumberSuccess({ cart }));
          } else {
            this.loaderService.setLoading(true)
            return this.cartService.updatePONumber(poNumber, cart.cartId)
              .pipe(
                map(updatedCart => CartActions.updatePONumberSuccess({ cart: updatedCart })),
                catchError(error => of(CartActions.updatePONumberFailed({ error }))),
                tap(() => this.loaderService.setLoading(false))
              )
          }
        }
      )
    )
  )

  updateUnitNumber$ = createEffect(
    () => this.action$.pipe(
      ofType(CartActions.updateUnitNumber),
      withLatestFrom(
        this.store.select(CartSelectors.selectCart),
        this.store.select(CartSelectors.selectUnitNumber)
      ),
      switchMap(
        ([action, cart, currentUnitNumber]) => {
          if (currentUnitNumber === action.unitNumber) {
            return of(CartActions.updateUnitNumberSuccess({ cart }));
          } else {
            this.loaderService.setLoading(true);
            return this.cartService.updateUnitNumber(action.unitNumber, cart.cartId)
              .pipe(
                map(updatedCart => CartActions.updateUnitNumberSuccess({ cart: updatedCart })),
                catchError(error => of(CartActions.updateUnitNumberFailed({ error }))),
                tap(() => this.loaderService.setLoading(false))
              )
          }
        }
      )
    )
  );

  getTaxesOnOpenCart$ = createEffect(() => this.action$.pipe(
    ofType(CartActions.selectOpenCartSuccess),
    tap(() => {
      this.store.dispatch(CartActions.getTaxes());
    })
  ), {dispatch: false});

  preGetTaxes$ = createEffect(() =>
    this.store.select(CartSelectors.selectCart).pipe(
    filter((cart) => cart.cartId !== blankId && cart.cartId !== null),
    pairwise(),
    tap(([prevCart, currCart]) => {
      const prevSelectedShippingRate = prevCart?.shippingRate?.service;
      const prevDeliveryOption = prevCart.deliveryType;
      const prevZipCode = prevCart.zip;
      const prevLineItems = prevCart.lineItems;
      const currSelectedShippingRate = currCart?.shippingRate?.service;
      const currDeliveryOption = currCart.deliveryType;
      const currZipCode = currCart.zip;
      const currLineItems = currCart.lineItems;

      const itemsAdded = prevLineItems.length !== currLineItems.length;
      const itemQuantitiesChanged = prevLineItems.map(x => x.quantity).some((prevLineItemQuantity, index) => currLineItems[index].quantity !== prevLineItemQuantity);
      const zipChanged =  prevZipCode !== currZipCode;
      const shippingRateChanged = prevSelectedShippingRate !== currSelectedShippingRate;
      const deliveryChanged = currDeliveryOption !== prevDeliveryOption;
      const deliveryFeeChanged = prevCart.lineItems.find((lineItem) => lineItem.partNumber === "DELIVERY:90")?.listPrice !==
        currCart.lineItems.find((lineItem) => lineItem.partNumber === "DELIVERY:90")?.listPrice;

      if(itemsAdded || itemQuantitiesChanged || zipChanged || deliveryChanged || shippingRateChanged || deliveryFeeChanged){
        this.store.dispatch(CartActions.getTaxes());
      }
    })
  ), {dispatch: false});

  getTaxes$ = createEffect(() =>
       this.action$
      .pipe(
        ofType(CartActions.getTaxes),
        withLatestFrom(
          this.store.select(CartSelectors.selectCartId),
          this.store.select(BranchSelectors.selectedBranch),
          this.store.select(LoyaltySelectors.loyaltyProfile)),
        switchMap(([_, cartId, branch, loyaltyAccount]) => {
          const loyaltyAccounts: LoyaltyDiscountSearchCriteria = {
            branchCode: branch.code,
            loyaltyProgram: loyaltyAccount?.account?.loyaltyProgram,
            memberId: loyaltyAccount?.account?.loyaltyAccountIdentifier,
            partNumbers: []
          };
          return this.cartService.getTax(cartId, loyaltyAccounts).pipe(
            map(getTaxesResults => CartActions.getTaxesSuccess({ taxes: getTaxesResults })),
            catchError((error) => of(CartActions.getTaxesFailed({ error }))),
          );
        })
    )
  );

  removeItemsFromCart$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.removeItemsFromCart),
      withLatestFrom(
        this.store.select(CartSelectors.selectCart),
        this.store.select(ConfigurationSelectors.selectUserInfo)
      ),
      tap(() => this.loaderService.setLoading(true)),
      switchMap(([{ isPickupDeliveryType, itemsToRemoveFromCart }, cart, { id }]) =>
        this.cartService.removeItemsFromCart({ cartId: cart.cartId, isPickupDeliveryType, itemsToRemoveFromCart })
          .pipe(
            map((cart: CartResult) => CartActions.removeItemsFromCartSuccess({ cart })),
            catchError(error => of(CartActions.removeItemsFromCartFailed({ error }))),
            tap(() => {
              this.loaderService.setLoading(false);
              this.logAppInsightDataForRemoveItemsFromCart(id, cart, itemsToRemoveFromCart);
            })
          )
      )
    )
  );

  addStoItemFromStoModal$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.addStoItemToCart),
      tap(() => this.loaderService.setLoading(true)),
      withLatestFrom(this.store.select(CartSelectors.selectCart)),
      switchMap(
        ([{ addCartItemRequest, cartItem }, cart]) =>
          this.vendorService.getVendorPrice('', cartItem.corePartNumber, cartItem.corePrice > 0, cartItem.rushPartNumber, cart.branchCode).pipe(
            switchMap(
              () => this.cartService.addStoToCart(cart.cartId, addCartItemRequest).pipe(
                switchMap(
                  cartResult => {
                    this.toastService.showToast(`${cartItem.partNumber} ${cartItem.description} with ${addCartItemRequest.addCartItemRequest.quantity} quantity added successfully.`, ToastType.Success);

                    if (cartItem.adjustedPrice) {
                      const setPriceRequest: SetCartItemPriceRequest = {
                        cartItemId: cartItem.cartItemId,
                        adjustedPrice: cartItem.adjustedPrice,
                        splitToCartItemId: null
                      };

                      return this.cartService.setPrice(setPriceRequest).pipe(
                        map(cart => CartActions.addStoItemToCartSuccess({ cart })),
                        catchError(error => of(CartActions.addStoItemToCartFailed({ error })))
                      )
                    } else {
                      return of(CartActions.addStoItemToCartSuccess({ cart: cartResult }));
                    }
                  }
                ),
                catchError(error => of(CartActions.addStoItemToCartFailed({ error })))
              )
            ),
            catchError(error => of(CartActions.addStoItemToCartFailed({ error }))),
            tap(() => this.loaderService.setLoading(false))
          )
      )
    )
  );

  addStoFromNationalInventoryModal$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.addStoItemFromNationalInventory),
      tap(() => this.loaderService.setLoading(true)),
      withLatestFrom(
        this.store.select(CartSelectors.selectCart),
        this.store.select(BranchSelectors.selectedBranch),
        this.store.select(CustomerSelectors.selectedCustomer)
      ),
      switchMap(
        ([{ addCartItemRequest, partData }, cart, branch, customer]) =>
          this.vendorService.getVendorPrice('', partData.corePartNumber, partData.corePrice > 0, partData.rushPartNumber, cart.branchCode).pipe(
            switchMap(
              () => {
                if (cart.cartId == blankId) {
                  return this.cartService.getOrCreateCart({ branchCode: branch.code, customerNumber: customer.customerNumber, forceCreate: false })
                    .pipe(
                      switchMap((newCart) => this.addStoItemToCart(newCart, partData, addCartItemRequest))
                    )
                }
                return this.addStoItemToCart(cart, partData, addCartItemRequest);
              }
            ),
            catchError(error => of(CartActions.addStoItemFromNationalInventoryFailed({ error }))),
            tap(() => this.loaderService.setLoading(false))
          )
      )
    )
  );

  addStoItemToCart(cart: CartResult, partData: Part, addCartItemRequest: AddStoCartItemRequest) {
    return this.cartService.addStoToCart(cart.cartId, addCartItemRequest).pipe(
      map(
        cart => {
          this.toastService.showToast(`${partData.rushPartNumber} ${partData.description} with ${addCartItemRequest.addCartItemRequest.quantity} quantity added successfully.`, ToastType.Success);
          return CartActions.addStoItemFromNationalInventorySuccess({ cart });
        }
      ),
      catchError(error => of(CartActions.addStoItemFromNationalInventoryFailed({ error })))
    );
  }

  markQuoteLineItemClose$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.closeQuoteItems),
      withLatestFrom(this.store.select(CartSelectors.selectCart)),
      switchMap(
        ([{ itemsToClose }, cart]) => this.modalObservable.open(CloseReasonModalComponent,
          { reasonFor: SourceLocationType.CartItemCloseReason, cartItemData: itemsToClose })
          .pipe(
            switchMap(
              (result) => {
                const closeQuoteParameters: CloseQuoteReasonParameters = {
                  cartId: cart.cartId,
                  cartItemIds: itemsToClose.map(i => i.cartItemId),
                  reasonId: result.closeReasonId,
                };
                this.loaderService.setLoading(true);
                return this.quoteService.markQuoteLineItemClose(closeQuoteParameters)
                  .pipe(
                    map(cart => CartActions.closeQuoteItemsSuccess({ cart })),
                    catchError(error => of(CartActions.closeQuoteItemsFailed({ error }))),
                    tap(() => this.loaderService.setLoading(false))
                  )
              }
            )
          )
      )
    )
  );

  updateShipTo$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.updateShipTo),
      withLatestFrom(this.store.select(CartSelectors.selectedCartId)),
      filter(([_, cartId]) => cartId !== null && cartId !== blankId),
      tap(() => this.loaderService.checkoutLoaderSubject.next(true)),
      switchMap(([{ shipToCustomer, isCartUpdate }, cartId]) =>
        this.cartService.updateShipTo(cartId, shipToCustomer, shipToCustomer?.isEdited)
          .pipe(
            map((updateShipToResponse) => CartActions.updateShipToSuccess({ cart: updateShipToResponse, isCartUpdate }) ),
            catchError((error) => of(CartActions.updateShipToFailed({ error }))),
            tap(() => this.loaderService.checkoutLoaderSubject.next(false))
          )
      )
    )
  );

  getShippingRates$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.getShippingRates),
      withLatestFrom(
        this.store.select(CartSelectors.selectCartShipTo)
      ),
      switchMap(([action, cartShipTo]) => {
        this.loaderService.checkoutLoaderSubject.next(true);
        return this.cartService.getShippingRates(action.cartId)
          .pipe(
            tap(() => this.loaderService.checkoutLoaderSubject.next(false)),
            map((shippingRates) => CartActions.getShippingRatesSuccess({ shippingRates })),
            catchError((error) => of(CartActions.getShippingRatesFailed({ error }))),
          )}
      )
    )
  );

  getFreight$ = createEffect(
    () => this.action$.pipe(
      ofType(
        CartActions.getFreightAndAddToCart,
        CartActions.updateShipToSuccess),
      filter((action) => {
        if(action.type === '[Cart] Get Freight'){
          return action.cartId !== blankId;
        } else {
          return action.cart.cartId !== blankId;
        }
      }),
      withLatestFrom(this.store.select(CartSelectors.selectCart)),
      map(([_, cart]) => cart),
      tap(() => {
        if(this.router.url.includes("checkout")){
         this.loaderService.inboundFreightLoaderSubject.next(true);
        }
      }),
      switchMap( (cart) =>
          // eslint-disable-next-line max-len
           this.store.select(FeatureFlagSelectors.isFeatureActiveForCustomerOrBranch('ShippingManagement', cart.customerNumber, cart.branchCode))
          .pipe(map((isShippingManagementEnabled) => ({ cart, isShippingManagementEnabled })))
      ),
      switchMap(( {cart, isShippingManagementEnabled} ) => {
        if (isShippingManagementEnabled) {
          return this.cartService.getFreight(cart.cartId)
            .pipe(
              withLatestFrom(this.store.select(ConfigurationSelectors.isInternal)),
              map(([cartResult, isInternal]) => CartActions.getFreightAndAddToCartSuccess({ cart: cartResult, isInternal })),
              catchError((error) => of(CartActions.getFreightAndAddToCartFailed({ error }))),
              tap(() => this.loaderService.inboundFreightLoaderSubject.next(false))
            );
        }
        else {
          this.loaderService.inboundFreightLoaderSubject.next(false);
          return of();
        }
      })
  ));

  deliveryOptionSetAndPreselectedShipToIsSet$ = createEffect(
    () => combineLatest(
       [this.store.select(CartSelectors.selectCartShipTo), this.store.select(CartSelectors.selectCartHeaderDeliveryType)]).pipe(
      pairwise(),
      filter(([[prevShipTo, prevDeliveryType], [currShipTo, currDeliveryType]]) =>
        (JSON.stringify(prevShipTo) !== JSON.stringify(currShipTo)) || (JSON.stringify(prevDeliveryType) !== JSON.stringify(currDeliveryType))
      ),
      map(([_, curr]) => curr),
      withLatestFrom(this.store.select(CartSelectors.selectCartId)),
      debounceTime(500),
      map(([_, cartId]) =>
        CartActions.getFreightAndAddToCart({cartId})
      )
    )
  );

  setTransactionType$ = createEffect(
    () => this.action$.pipe(
      ofType(CartActions.updateTransactionType),
      tap(() => {
        if(this.router.url.includes("checkout")){
         this.loaderService.setLoading(true);
        }
      }),
      withLatestFrom(  this.store.select(CartSelectors.selectCartId) ),
      switchMap(([action, cartId ]) =>
        {
          const setTransactionTypeRequest: SetTransactionTypeRequest = {
            cartId: cartId,
            transactionType :  action.orderType !== null && action.orderType !== undefined ? action.orderType?.toString() :  "0"
          };

          return this.cartService.setTransactionType( setTransactionTypeRequest )
            .pipe(
              map(() => CartActions.updateTransactionTypeSuccess()),
              catchError((error) => of(CartActions.updateTransactionTypeError({ error }))),
              tap(() => this.loaderService.setLoading(false))
            )
        }
      )
  ));


  refreshShippingRates$ = createEffect(() =>
    this.action$.pipe(
      ofType(
        ConfigurationActions.loadCacheSuccess,
        CartActions.addItemToCartSuccess,
        CartActions.updateCartItemSuccess,
        CartActions.getCartDataSuccess,
        CartActions.selectCartSuccess,
        CartActions.removeItemsFromCartSuccess,
        CartActions.deleteCartSuccess,
        CartActions.updatePONumberSuccess,
        CartActions.updateAlternateEmailSuccess,
        CartActions.updateBillToSuccess,
        CartActions.updateShipToSuccess,
        CartActions.updateSpecialInstructionSuccess,
        CartActions.getBinLocations,
        BasketActions.addBasketToCartSuccess),
      withLatestFrom(this.store.select(CartSelectors.selectCart)),
      map(([action, cart]) => ({
        cart,
        deliveryType: cart.deliveryType,
        taxes: cart.taxes,
        cartId: cart.cartId,
        action: action.type
      })),
      filter(({ deliveryType, cart, taxes, action }) => {
        const addressPopulated =
        (cart.address !== null && cart.address !== '') &&
        (cart.city !== null && cart.city !== '') &&
        (cart.state !== null && cart.state !== '') &&
        (cart.zip !== null && cart.zip !== '') &&
        (cart.shipToAddressNo !== null && cart.shipToAddressNo !== '') &&
        cart.cartId !== blankId;
        return addressPopulated && !!deliveryType && deliveryType === "shipto";
      }),
      tap((cart) => this.store.dispatch(CartActions.getShippingRates({ cartId: cart.cartId })))
    ), { dispatch: false });

  getShippingRatesOnAddressAndDeliveryChange$ = createEffect(() => combineLatest(
      [this.action$.pipe(ofType(CartActions.updateCartSuccess)),
      this.store.select(CartSelectors.selectCartHeaderDeliveryType)]
    ).pipe(
    filter(([{cart}, deliveryType]) => {
      const addressPopulated =
        (cart.address !== null && cart.address !== '') &&
        (cart.city !== null && cart.city !== '') &&
        (cart.state !== null && cart.state !== '') &&
        (cart.zip !== null && cart.zip !== '') &&
        (cart.shipToAddressNo !== null && cart.shipToAddressNo !== '') &&
        cart.cartId !== blankId;
      return deliveryType === DeliveryTypes.shipTo && addressPopulated;
    }),
    withLatestFrom(this.store.select(CartSelectors.selectCartId)),
    map(([_, cartId]) =>
      CartActions.getShippingRates({cartId}))
  ));

    updateShippingRate$ = createEffect(() =>
      this.action$.pipe(
        ofType(CartActions.updateShippingRate),
        tap(() => this.loaderService.setLoading(true)),
        withLatestFrom(
          this.store.select(CartSelectors.selectShippingRates),
          this.store.select(CartSelectors.selectCartShipTo)
        ),
        filter(([_,__, shipTo]) => {
          const addressPopulated = (shipTo.streetAddress !== null || shipTo.streetAddress !== '') &&
            (shipTo.city !== null || shipTo.city !== '') &&
            (shipTo.state !== null || shipTo.state !== '') &&
            (shipTo.postalCode !== null || shipTo.postalCode !== '') &&
            shipTo.addressNumber !== blankId;
          return addressPopulated;
        }),
        switchMap(([request, rates]) =>
          this.cartService.updateCartShippingRate(request.shippingRateRequest)
          .pipe(
            map((response) => CartActions.updateShippingRateSuccess({ shippingRate: rates.find(x => x.service == request.shippingRateRequest.service)})),
            catchError((error) => of(CartActions.updateShippingRateFailed({ error }))),
            tap(() => this.loaderService.setLoading(false))
          ))
      )
    )

  updateBillTo$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.updateBillTo),
      tap((action) => {
        if(!action.preselectingDefault){
          this.loaderService.setLoading(true);
        }
      }),
      withLatestFrom(
        this.store.select(CartSelectors.selectedCartId),
        this.store.select(CustomerSelectors.selectPayers)
      ),
      switchMap(([{ billToCustomerNumber, paymentMethod }, cartId, payers ]) => {
        const customer: Customer = payers.find(payer => payer.addressNumber === billToCustomerNumber);
        return this.cartService.updateBillTo(cartId, { customerName: customer.customerName, addressNumber: billToCustomerNumber })
          .pipe(
            map(cart => CartActions.updateBillToSuccess({ cart })),
            catchError((error) => of(CartActions.updateBillToFailed({ error }))),
            tap(() => this.loaderService.setLoading(false)),
            tap(() => { if (paymentMethod.length) {
              this.store.dispatch(CustomerActions.setSelectedPaymentMethod({ paymentMethod }));
              } } )
          )
      })
    )
  );

  openViewAllPartsModal$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(CartActions.openViewAllPartsModal),
        withLatestFrom(this.store.select(CartSelectors.selectCart)),
        tap(([_, cartsData]) => {
          const modalRef = this.modalService.open(ViewAllPartsComponent, {
            size: "lg",
            backdrop: "static",
            keyboard: false,
          });
        })
      ),
    { dispatch: false }
  );

  updateSmsOpt$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.updateSmsOpt),
      withLatestFrom(
        this.store.select(CartSelectors.selectSmsOpt),
        this.store.select(CartSelectors.selectCart)
      ),
      switchMap(([{ smsOpt }, currentSmsOpt, cart]) => {
        if (
          smsOpt.optIn === currentSmsOpt.optIn &&
          smsOpt.contactName === currentSmsOpt.contactName &&
          smsOpt.mobileNumber === currentSmsOpt.mobileNumber
        ) {
          return of(CartActions.updateSmsOptSuccess({ cart }));
        } else {
          this.loaderService.setLoading(true);
          return this.cartService.updateSmsOpt(cart.cartId, smsOpt).pipe(
            map(updatedCart => CartActions.updateSmsOptSuccess({ cart: updatedCart })),
            catchError(error => of(CartActions.updateSmsOptFailed({ error }))),
            tap(() => (this.loaderService.setLoading(false)))
          );
        }
      })
    )
  );



  clearOnHomePageLoad$ = createEffect(() =>
    this.action$.pipe(
      ofType(
        HomeActions.loaded
      ),
      withLatestFrom(this.store.select(ConfigurationSelectors.isInternal)),
      filter(([_, isInternal]) => isInternal),
      tap(() => {
        this.store.dispatch(CustomerActions.clearCustomer());
        this.store.dispatch(CartActions.clearCartData());
      })
    ),
    { dispatch: false }
  );

  fireHomeLoadedWhenNavigatingToIt$ = createEffect(() => this.router.events.pipe(
    tap(event => {
      if (event instanceof NavigationEnd) {
        // Check if the navigation event is to the home component
        if (event.urlAfterRedirects === '/') {
          this.store.dispatch(HomeActions.loaded());
        }
      }
    })), {dispatch: false});

  getDefaultCartForBranchAndCustomerIfExists$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.getCartDataForBranchAndCustomer),
      withLatestFrom(
        this.store.select(CartSelectors.selectCart),
        this.store.select(CustomerSelectors.selectedCustomer),
        this.store.select(BranchSelectors.selectedBranch)
      ),
      filter(([_, cart, customer, branch]) => !cart.isQuote),
      switchMap(
        ([_, cart, customer, branch]) => {
          if (this.hasSelectedCart(cart?.cartId) && cart?.customerNumber === customer.customerNumber && cart?.branchCode === branch.code) {
            return EMPTY;
          }
          return this.cartService.getDefaultCartForBranchAndCustomerIfExists(branch.code, customer.customerNumber).pipe(
            map(cartResult => CartActions.getCartDataSuccess({ cartResult })),
            catchError(error => of(CartActions.getCartDataForBranchAndCustomerFailed({ error })))
          );
        }
      ),
    )
  )

  getPayers$ = createEffect(() =>
    this.action$.pipe(
      ofType(CustomerActions.getPayers),
      withLatestFrom(
        this.store.select(CustomerSelectors.selectedCustomer),
        this.store.select(BranchSelectors.selectedBranch)
      ),
      switchMap(([_, customer, branch]) => this.customerService.searchCustomers({
        customerType: 'RG',
        customerNumber: customer.customerNumber,
        branchCode: branch.code
      }).pipe(
        map((payers) => CustomerActions.getPayersSuccess({ payers })),
        catchError((error) => of(CustomerActions.getPayersFailed({ error })))
      ))
    )
  )

  updateFreightFee$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.setFreightFee),
      withLatestFrom(
        this.store.select(CartSelectors.selectedCartId),
        // TODO - check if we need selector bellow
        // this.store.select(CartSelectors.selectFreightCartItem)
      ),
      tap(() => this.loaderService.setLoading(true)),
      switchMap(
        ([{ freightCost, cartItem }, cartId]) => this.cartService.UpdateCartFreightAndDelivery(cartId, cartItem.cartItemId, cartItem.lineId, freightCost)
          .pipe(
            map(cart => CartActions.setFreightFeeSuccess({ cart })),
            catchError((error) => of(CartActions.setFreightFeeFailure({ error }))),
            tap(() => this.loaderService.setLoading(false))
          )
      )
    )
  );

  updateDeliveryFee$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.setDeliveryFee),
      withLatestFrom(
        this.store.select(CartSelectors.selectedCartId),
        this.store.select(CartSelectors.selectDeliveryCartItem)
      ),
      tap(() => this.loaderService.setLoading(true)),
      switchMap(([costs, cartId, deliveryCartItem]) => this.cartService.UpdateCartFreightAndDelivery(
        cartId,
        deliveryCartItem[0].cartItemId,
        deliveryCartItem[0].lineId,
        costs.deliveryCost
      ).pipe(
        map(cart => CartActions.setDeliveryFeeSuccess({ cart })),
        catchError((error) => of(CartActions.setDeliveryFeeFailure({ error }))),
        tap(() => this.loaderService.setLoading(false))
      ))
    )
  );

  updateSpecialInstruction$ = createEffect(() => this.action$.pipe(
    ofType(CartActions.updateSpecialInstruction),
    withLatestFrom(
      this.store.select(CartSelectors.selectSpecialInstruction),
      this.store.select(CartSelectors.selectCart)
    ),
    switchMap(
      ([{ specialInstruction }, currentSpecialInstruction, cart]) => {
        if (specialInstruction === currentSpecialInstruction) {
          return of(CartActions.updateSpecialInstructionSuccess({ cart }))
        } else {
          this.loaderService.setLoading(true);
          return this.cartService.updateSpecialInstruction(specialInstruction, cart.cartId)
            .pipe(
              map(updatedCart => CartActions.updateSpecialInstructionSuccess({ cart: updatedCart })),
              catchError(error => of(CartActions.updateSpecialInstructionFailed({ error }))),
              tap(() => this.loaderService.setLoading(false))
            )
        }
      }
    )
  ));

  updateAlternateEmail$ = createEffect(() => this.action$.pipe(
    ofType(CartActions.updateAlternateEmail),
    withLatestFrom(
      this.store.select(CartSelectors.selectAlternateEmail),
      this.store.select(CartSelectors.selectCart)
    ),
    switchMap(
      ([{ alternateEmail }, currentAlternateEmail, cart]) => {
        if (alternateEmail === currentAlternateEmail) {
          return of(CartActions.updateAlternateEmailSuccess({ cart }))
        } else {
          this.loaderService.setLoading(true);
          return this.cartService.updateAlternateEmail(alternateEmail, cart.cartId)
            .pipe(
              map(updatedCart => CartActions.updateAlternateEmailSuccess({ cart: updatedCart })),
              catchError(error => of(CartActions.updateAlternateEmailFailed({ error }))),
              tap(() => this.loaderService.setLoading(false))
            )
        }
      }
    )
  ));

  deleteCartLine$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.deleteCartItem),
      tap(() => this.loaderService.setLoading(true)),
      switchMap(
        ({ cartLineItem }) => {
          const removeItemsFromCartRequest: RemoveItemsFromCartRequest = {
            cartId: cartLineItem.cartId,
            itemsToRemoveFromCart: [{ cartItemId: cartLineItem.cartItemId, quantityAvailable: cartLineItem.quantity }],
            isPickupDeliveryType: true
          };

          return this.cartService.removeItemsFromCart(removeItemsFromCartRequest).pipe(
            map(() => CartActions.deleteCartItemSuccess({ cartLineItemId: cartLineItem.cartItemId })),
            catchError((error) => of(CartActions.setDeliveryFeeFailure({ error }))),
            tap(() => this.loaderService.setLoading(false))
          )
        }
      )
    )
  );

 paymentType(isInviteToPay: boolean, isSelectedCreditCard : boolean, isAddedCreditCard: boolean ) : PaymentTypeEnum {
    if (isInviteToPay) { return PaymentTypeEnum.InviteToPay; }
    if (isSelectedCreditCard ) { return PaymentTypeEnum.CcOnline; }
    if (isAddedCreditCard) { return PaymentTypeEnum.CcCardInHand; }
    return PaymentTypeEnum.Payer;
 }

 inviteToPayType(inviteToPayType: string) : InviteToPayTypeEnum {
   if (inviteToPayType === InviteToPayTypeEnum[InviteToPayTypeEnum.EMail].toString()) { return InviteToPayTypeEnum.EMail }
   if (inviteToPayType === InviteToPayTypeEnum[InviteToPayTypeEnum.Sms].toString())   { return InviteToPayTypeEnum.Sms }
   return InviteToPayTypeEnum.None
 }

  placeOrderOrQuote$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.submitTransaction),
      withLatestFrom(this.loaderService.checkoutLoaderSubject),
      filter(([_, checkoutLoading]) => !checkoutLoading),
      map(([action]) => action),
      tap(() => this.loaderService.setLoading(true)),
      withLatestFrom(
        this.store.select(CartSelectors.selectCart),
        this.store.select(BranchSelectors.selectedBranch),
        this.store.select(CustomerSelectors.selectedCustomer),
        this.store.select(LoyaltySelectors.loyaltyAccount),
        this.store.select(CartSelectors.selectTotal),
        this.store.select(CartSelectors.selectCartLines),
        this.store.select(ConfigurationSelectors.selectUserInfo),
        this.store.select(CartSelectors.selectDeliverySummary),
        this.store.select(CustomerSelectors.isInviteToPay),
        this.store.select(CustomerSelectors.isSelectedCreditCard),
        this.store.select(CustomerSelectors.isAddedCreditCard),
        this.store.select(CustomerSelectors.inviteToPayType),
        this.store.select(CustomerSelectors.inviteToPayEmail),
        this.store.select(CustomerSelectors.inviteToPaySms),
        this.store.select(CustomerSelectors.storedPaymentMethod),
        this.store.select(CustomerSelectors.selectStoredPaymentMethods),
      ),
      debounceTime(200),
      switchMap(
        ([{ clearShoppingBasket, transactionType }, cart, branch, customer, loyaltyAccount, cartTotal, cartLines, { id, isInternal }, { multipleOptionsSelected }, isInviteToPay, isSelectedCreditCard, isAddedCreditCard, inviteToPayType, inviteToPayEmail, inviteToPaySms, storedPaymentMethod, storedPaymentMethods ]) => {
          if (cart.isQuote) {
            transactionType = OrderType.Order;
          }

          if (!isInternal && transactionType == OrderType.Quote) {
            const message = "Quote cannot be created by external user.";
            this.toastService.showToast(message, ToastType.Error);
            return of(CartActions.submitQuoteFailed({ error: message }));
          }

          const externalUserOrderChannelForOrder = 'ZEC';
          // eslint-disable-next-line eqeqeq
          const internalUserorderChannel = transactionType == OrderType.Order ? this.checkoutFormService.checkoutForm.value.checkoutDetails.orderChannel : '';


          let i2pType = InviteToPayTypeEnum.None;
          let paymentType = PaymentTypeEnum.Payer;

          if(cart.i2pEmail !== null){
            i2pType = InviteToPayTypeEnum.EMail;
          } else if(cart.i2pSmsNumber !== null){
            i2pType = InviteToPayTypeEnum.Sms;
          } else {
            i2pType = InviteToPayTypeEnum.None;
          }

          if(i2pType !== InviteToPayTypeEnum.None){
            paymentType = PaymentTypeEnum.InviteToPay;
          } else if(cart.paymentSelection === "AddedCC"){
            paymentType = PaymentTypeEnum.CcCardInHand;
          }

          const transactionData: CheckoutTransactionData = {
            cartId: cart.cartId,
            isClearBasket: transactionType == OrderType.Quote ? false : clearShoppingBasket?? false,
            loyaltyDiscountSearchCriteria: {
              memberId: loyaltyAccount?.loyaltyAccountIdentifier,
              branchCode: branch.code,
              loyaltyProgram: loyaltyAccount?.loyaltyProgram,
              partNumbers: null
            },
            orderChannel: isInternal ? internalUserorderChannel : externalUserOrderChannelForOrder,
            orderType: transactionType,
            total: cartTotal,
            customerName1: customer.customerName,
            customerName2: customer.customerName2,
            customerAddress: customer.addressNumber,
            customerCity: customer.city,
            customerPhone: customer.phoneNumber,
            customerState: customer.state,
            customerZip: customer.postalCode,

            payment: {
              paymentTypeEnum :  transactionType == OrderType.Order ? paymentType: PaymentTypeEnum.None,
              inviteToPayType : transactionType == OrderType.Order ? i2pType: InviteToPayTypeEnum.None,
              inviteToPayEmail : transactionType == OrderType.Order ? cart.i2pEmail : null,
              inviteToPayPhone : transactionType == OrderType.Order ? cart.i2pSmsNumber : null,
              selectedToken : transactionType == OrderType.Order ? storedPaymentMethod : null
            }
          };

          if ( cart.paymentSelection?.length > 20 ) {
            const selectedToken = storedPaymentMethods.find(
              (paymentMethod: StoredPaymentMethod) => paymentMethod.id === cart.paymentSelection);
            transactionData.payment.paymentTypeEnum = PaymentTypeEnum.CcOnline;
            transactionData.payment.selectedToken = selectedToken;
          }

          const setDelivery$ = this.cartService
            .setDelivery({
              branchCode: branch.code,
              cartId: cart.cartId,
              customerNumber: customer.customerNumber,
              headerDeliveryType: multipleOptionsSelected ? getHeaderDelivery(cartLines) : cart.deliveryType as DeliveryTypes,
              deliveryTypes: cartLines.reduce((acc, item) => ({ ...acc, [item.cartItemId]: item.deliveryOption }), {}),
              addDeliveryFees: false
            }).pipe(
              catchError((error) => {
                this.loaderService.setLoading(false);
                return of(CartActions.setDeliveryFailed({error}));
              })
            );

           if (transactionType == OrderType.Quote) {
                   return this.quoteService.placeQuote(transactionData)
                    .pipe(
                      map((quoteCreationResult: OrderResult) => {
                        this.logAppInsightDataForPlacedOrder(id, cart, quoteCreationResult.sapOrderNumber, cartLines);
                        this.router.navigate(['orderconfirmation'], { queryParams: { orderId: quoteCreationResult.orderId, orderType: 'Quote' } })
                          .then(() => this.loaderService.setLoading(false));
                        return CartActions.submitQuoteSuccess({ quoteCreationResult })
                      }),
                      catchError(error => of(CartActions.submitQuoteFailed({ error })))
                    );
          } else {
                return this.cartService.placeOrder(transactionData)
                .pipe(
                  map((orderCreationResult: OrderResult) => {
                    this.logAppInsightDataForPlacedOrder(null, cart, orderCreationResult.sapOrderNumber, cartLines);
                    this.router.navigate(['orderconfirmation'], { queryParams: { orderId: orderCreationResult.orderId, orderType: 'SalesOrder' } })
                      .then(() => this.loaderService.setLoading(false));
                    return CartActions.submitOrderSuccess({ orderCreationResult })
                  }),
                  catchError(error => of(CartActions.submitOrderFailed({ error })))
                );
          }
        }
      ),
      tap(() => {
        this.loaderService.setLoading(false);
      })
    )
  );

  closeQuote$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.closeQuote),
      withLatestFrom(this.store.select(CartSelectors.selectCart)),
      switchMap(
        ([_, cart]) => this.modalObservable.open(CloseReasonModalComponent, { reasonFor: SourceLocationType.CartCloseReason }, { size: 'sm' })
          .pipe(
            switchMap(
              (result) => {
                const closeQuoteParameters: CloseQuoteReasonParameters = {
                  cartId: cart.cartId,
                  reasonId: result.closeReasonId,
                  orderNumber: cart.orderNumber
                };
                this.loaderService.setLoading(true);
                return this.quoteService.closeQuote(closeQuoteParameters)
                  .pipe(
                    map(
                      (response) => {
                        if (response.level === 'success') {
                          this.toastService.showToast('Quote is successfully closed.', ToastType.Success);
                          //TODO: check what this was doing and if it is needed at all now
                          // this.setUnsavedChanges();
                          this.router.navigate(['/']);
                          return CartActions.closeQuoteSuccess();
                        } else {
                          //TODO: this needs to be checked if it will be called or interceptor will handle that
                          this.toastService.showToast(response.message, ToastType.Error);
                          return CartActions.closeQuoteFailed({ error: response })
                        }
                      }
                    ),
                    catchError(error => of(CartActions.closeQuoteFailed({ error }))),
                    tap(() => this.loaderService.setLoading(false))
                  )
              }
            )
          )
      )
    )
  );

  updateQuote$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.updateQuote),
      tap(() => this.loaderService.setLoading(true)),
      withLatestFrom(this.store.select(CartSelectors.selectCart)),
      switchMap(
        ([_, cart]) => this.quoteService.updateQuote(cart.cartId, cart.orderId)
          .pipe(
            map(
              updatedCart => {
                this.toastService.showToast('Quote is updated successfully.', ToastType.Success);
                return CartActions.updateQuoteSuccess({ cart: updatedCart });
              }
            ),
            catchError(error => of(CartActions.updateQuoteFailed({ error }))),
            tap(() => this.loaderService.setLoading(false))
          )
      )
    )
  );

  printSAPQuoteFromCheckout$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.printSAPQuoteFromCheckout),
      tap(() => this.loaderService.setLoading(true)),
      withLatestFrom(this.store.select(CartSelectors.selectCart)),
      switchMap(
        ([_, cart]) => this.quoteService.printSAPQuoteFromCheckout(cart.orderNumber)
          .pipe(
            map(
              result => {
                if (result.pdf?.length) {
                  const filename = `PrintTicket_${cart.orderNumber}.pdf`;
                  this.downloadService.downloadPDF(result.pdf, filename );
                  return CartActions.printSAPQuoteFromCheckoutSuccess();
                }
              }
            ),
            catchError(error => of(CartActions.printSAPQuoteFromCheckoutFailed({ error }))),
            tap(() => this.loaderService.setLoading(false))
          )
      )
    )
  );

  unlockSelectedQuoteForOthers$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.unlockQuoteForOthers),
      tap(() => this.loaderService.setLoading(true)),
      withLatestFrom(this.store.select(CartSelectors.selectedCartId)),
      switchMap(
        ([_, cartId]) => this.myDashboardService.updateLockStatusForOthers(cartId)
          .pipe(
            map(
              () => {
                this.toastService.showToast('This quote is unlocked for others.', ToastType.Success);
                return CartActions.unlockQuoteForOthersSuccess();
              }
            ),
            catchError(error => of(CartActions.unlockQuoteForOthersFailed({ error }))),
            tap(() => this.loaderService.setLoading(false))
          )
      )
    )
  );


  loadCachedCustomer$ = createEffect(() =>
    this.action$.pipe(
      ofType(SystemActions.initialization),
      filter((_) => localStorage.getItem("customerData") !== null),
      map(() => {
        const customerData = JSON.parse(localStorage.getItem("customerData"));
        return CustomerActions.loadCustomerStateFromCache({ customerData });
      })
    ));

  showHotFlagModal$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.showHotFlagModal),
      switchMap(({ editHotFlagCode }) => {
        const modalRef = this.modalService.open(HotFlagComponent);
        modalRef.componentInstance.editHotFlagCode = editHotFlagCode;
        return modalRef.dismissed.pipe(
          withLatestFrom(this.store.select(CartSelectors.selectCartPartItems)),
          tap(([_, cartItems]) => {
            this.store.dispatch(MyCartActions.getCartDataSuccess({ cartItems: cartItems }));
          }));
      })
    ), { dispatch: false }
  );

  updateHotFlagOnCartItem$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.updateHotFlagOnCartItem),
      withLatestFrom(this.store.select(CartSelectors.selectCart)),
      switchMap(([{ updates }, selectedCart]: [{updates: any}, CartResult]) => {
        let updateCartData: UpdateCartItemFlagRequest = {
          cartId: selectedCart.cartId,
          cartItemFlag: 'IsHotFlag',
          vin: selectedCart.vin,
          updateFlagCartItems: updates
        };
        return this.cartService.updateCartItemFlag(updateCartData).pipe(
          map((cart) => {
            this.toastService.showToast('HotFlag Updated Successfully for selected part(s).', ToastType.Success);
            return CartActions.updateHotFlagOnCartItemSuccess({ cart });
          }),
          catchError(error => {
            this.toastService.errorMessage('HotFlagComponent', 'updateCartItemsHotFlag', 'UpdateCartItemFlag', error);
            return of(CartActions.updateAlternateEmailFailed({ error }))
          })
        )
      })
    ));

  showKnownPartBuyoutModal$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.showKnownPartBuyoutModal),
      withLatestFrom(this.store.select(CartSelectors.selectCart)),
      tap(
        ([_, cart]) => {
          this.modalService.open(KnownPartBuyoutComponent);
        }
      )
    ), { dispatch: false }
  )

  confirmPartsBuyout$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.confirmPartsBuyout),
      withLatestFrom(
        this.store.select(CartSelectors.selectCart),
        this.store.select(ConfigurationSelectors.selectUserInfo),
        this.store.select(CustomerSelectors.selectedCustomer)
      ),
      tap(() => this.loaderService.setLoading(true)),
      switchMap(
        ([{ selectedCartItems }, cart, user, customer]) => {
          const updateCartData: UpdateCartItemFlagRequest = {
            cartId: cart.cartId,
            cartItemFlag: 'IsBuyOut',
            vin: null,
            updateFlagCartItems: selectedCartItems
          };
          let shouldDeselect: boolean = true;

          return this.cartService.updateCartItemFlag(updateCartData).pipe(
            map(
              updatedCart => {
                this.logAppInsightForPartsBuyout(selectedCartItems, updatedCart, user.id, customer);
                return CartActions.confirmPartsBuyoutSuccess({ cart: updatedCart });
              }
            ),
            catchError(error => {
              shouldDeselect = false;
              return of(CartActions.confirmPartsBuyoutFailed({ error }))
            }),
            tap(() => {
              this.modalService.dismissAll();
              this.loaderService.setLoading(false);
              if (shouldDeselect) {
                this.store.dispatch(MyCartActions.unSelectAllItems());
              }
            })
          )
        }
      )
    )
  )

  showStockTransferModal$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.showStockTransferModal),
      withLatestFrom(
        this.store.select(CartSelectors.selectCart),
        this.store.select(ConfigurationSelectors.selectUserInfo)
      ),
      tap(
        ([_, cart, { id }]) => {
          this.modalService.open(StockTransferOrderComponent);
        }
      )
    ), { dispatch: false }
  )

  showLineItemNotesModal$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.showLineItemNotesModal),
      tap(
        ({ cartItem }) => {
          const lineItemNotesModalRef = this.modalService.open(
            LineItemNotesModalComponent,
            { size: "md" }
          );
          lineItemNotesModalRef.componentInstance.cartItemId = cartItem.cartItemId;
          lineItemNotesModalRef.componentInstance.partNumber = cartItem.partNumber;
          lineItemNotesModalRef.componentInstance.hasNotes = Boolean(cartItem.cartItemNotesCount);
        }
      )
    ), { dispatch: false }
  )

  prepareNationalInventoryModal$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.showNationalInventoryModal),
      withLatestFrom(
        this.store.select(CartSelectors.selectCart),
        this.store.select(ConfigurationSelectors.selectUserInfo),
        this.store.select(BranchSelectors.selectedBranch),
        this.store.select(CustomerSelectors.selectedCustomer)
      ),
      tap(
        ([{ part }, cart, { id }, branch, customer]) => {
          const showAddToCartColumns = !this.router.url.includes('dashboard?request');
          this.openNationalInventoryModal(part, cart, id, customer.customerName, customer.customerNumber, branch.code, showAddToCartColumns);
        }
      )
    ), { dispatch: false }
  )

  openNationalInventoryModal(part: Part | FavoritePartView, cart: CartResult, userId: string, customerName: string, customerNumber: string, branchCode: string, showAddToCartColumns: boolean) {
    const modalRef = this.modalService.open(NationalInventoryModalComponent, { size: 'lg' });
    modalRef.componentInstance.respart = part;
    modalRef.componentInstance.cartId = cart.cartId;
    modalRef.componentInstance.userId = userId;
    modalRef.componentInstance.customerName = customerName;
    modalRef.componentInstance.customerNumber = customerNumber;
    modalRef.componentInstance.branchCode = branchCode;
    modalRef.componentInstance.showAddToCartColumns = showAddToCartColumns;
  }

  priceOverride$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.priceOverride),
      switchMap(
        ({ part }) => this.modalObservable.open(PriceOverrideModalComponent, { part }, { windowClass: "mdModalClass" }).pipe(
          switchMap(
            result => {
              const setCartItemPriceRequest: SetCartItemPriceRequest = {
                cartItemId: part.cartItemId,
                adjustedPrice: result.overridePrice,
                splitToCartItemId: null,
              };
              this.loaderService.setLoading(true);
              return this.cartService.setPrice(setCartItemPriceRequest)
                .pipe(
                  map(cart => CartActions.priceOverrideSuccess({ cart })),
                  catchError(error => of(CartActions.priceOverrideFailed({ error }))),
                  tap(() => this.loaderService.setLoading(false))
                )
            }
          )
        )
      )
    )
  )

  priceVerify$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.showPriceVerifyModal),
      tap(
        async ({ items }) => {

          const mscVerifyParts: MscPriceVerifyPart[] = items.map(
            (cartItem) => {
              const newVerifyPart: MscPriceVerifyPart = {
                partNumber: cartItem.rushPartNumber ?? cartItem.partNumber,
                quantity: cartItem.quantity,
                unitPrice: cartItem.lowestPrice
              }
              return newVerifyPart;
            }
          );

          const cartId = items[0]?.cartId;
          const modalRef = this.modalService.open(PriceVerifyModalComponent, { size: "sm" })
          modalRef.componentInstance.source = SourceLocationType.PartDetailPriceVerify;
          modalRef.componentInstance.mscPriceVerifyParts = mscVerifyParts;
          modalRef.componentInstance.cartId = cartId;

        }
      )
    ), { dispatch: false }
  )

  addPartToFavorites$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.addPartToFavorites),
      tap(() => this.loaderService.setLoading(true)),
      switchMap(
        ({ cartItem }) => {
          const favoritePart: CreateFavoritePartRequest = {
            partId: cartItem.partId || '',
            partNo: cartItem.partNumber,
            partDescription: cartItem.description || '',
            image: cartItem.image,
            vmrsCode: cartItem.vmrsCode?.toString() || '',
            vmrsDescription: cartItem.vmrsDescription || '',
            manufacturer: cartItem.manufacturer || '',
          };
          return this.favoritePartsService.addFavoritePart(favoritePart).pipe(
            map(
              ({ favoritePart }) => {
                const message = `Part number ${favoritePart.partNo} has been added to your favorite part list.`;
                this.toastService.showToast(message, ToastType.Success);
                return CartActions.addPartToFavoritesSuccess({ partId: favoritePart.partId });
              }
            ),
            catchError(error => of(CartActions.addPartToFavoritesFailed({ error }))),
            tap(() => this.loaderService.setLoading(false))
          )
        }
      )
    )
  )

  removePartFromFavorites$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.removePartFromFavorites),
      tap(() => this.loaderService.setLoading(true)),
      switchMap(
        ({ cartItem }) => this.favoritePartsService.deleteFavoritePart(cartItem.partId, cartItem.partNumber).pipe(
          map(
            () => {
              this.toastService.showToast(`Part number ${cartItem.partNumber} has been removed from your favorite part list.`, ToastType.Success);
              return CartActions.removePartFromFavoritesSuccess({ partId: cartItem.partId });
            }
          ),
          catchError(error => of(CartActions.removePartFromFavoritesFailed({ error }))),
          tap(() => this.loaderService.setLoading(false))
        )
      )
    )
  )

  applyDiscounts$ = createEffect(() =>
    this.action$
      .pipe(
        ofType(
          LoyaltyActions.loadLoyaltyDiscountsSuccess
        ),
        withLatestFrom(
          this.store.select(LoyaltySelectors.loyaltyDiscounts)
        ),
        map(([_, discounts]) => CartActions.applyLoyaltyDiscounts({ discounts })
        )
      )
  );

  loadCartAfterPunchoutItemsWereAdded$ = createEffect(() =>
    this.action$
      .pipe(
        ofType(PunchoutReturnComponentActions.addToCartSuccess),
        map(({ cartId }) =>
          CartActions.selectOpenCart({ openCartId: cartId })
        )
      ));

  featuredPartAddedEffect$ = createEffect(() =>
    this.action$
      .pipe(
        ofType(CartActions.addStoItemFromNationalInventorySuccess),
        tap(({ cart }) =>
          this.cartService.featuredPartAdded.next(true)
        ),
      ),
    { dispatch: false }
  );

  preselectBillingOptions$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.addItemToCartSuccess),
      withLatestFrom(
        this.store.select(CartSelectors.selectBillTo),
        this.store.select(CustomerSelectors.selectPayers)
      ),
      map(([_, selected, payers]) => !selected && payers?.find(option => option.isDefault && !option.payerNotValidInBranch)),
      filter((defaultPayer) => !!defaultPayer),
      map((defaultPayer) => CartActions.updateBillTo({ billToCustomerNumber: defaultPayer.addressNumber, preselectingDefault: true , paymentMethod: ''}))
  ));

  showToastOnOrderFailure$ = createEffect(() => this.action$.pipe(
    ofType(
      CartActions.submitQuoteFailed,
      CartActions.submitOrderFailed),
    tap(({error}) => {
      if(Array.isArray(error)){
        const distinctErrors = Array.from(new Set<any>(error));
        this.toastService.showMultilineToast(distinctErrors.map((message) => {
          return {message, type: ToastType.Error}
        }));
      } else {
        const errorMessage = (error as any)?.message;
        const message = errorMessage && errorMessage !== undefined && errorMessage != null && errorMessage !== ''
          ? errorMessage
          : 'An unexpected error occurred during the operation.';
        this.toastService.showToast(message, ToastType.Error);
      }
    })
  ), {dispatch: false});

  constructor(
    private checkoutFormService: CheckoutFormService,
    private action$: Actions,
    private loaderService: LoaderService,
    private cartService: CartService,
    private quoteService: QuoteService,
    private router: Router,
    private modalService: NgbModal,
    private modalObservable: ModalService,
    private store: Store<AppState>,
    private partService: PartService,
    private toastService: ToastService,
    private downloadService: DownloadService,
    private loggerService: LoggerService,
    private myDashboardService: MyDashboardService,
    private favoritePartsService: FavoritePartsService,
    private customerService: CustomerService,
    private vendorService: VendorService,
    private featureFlagService: FeatureFlagService,
  ) { }

  createUpdateCartRequest(updatedCartItem: CartResultLineItem) {
    const updateForMycart: UpdateCart = {
      cartItemId: updatedCartItem.cartItemId,
      cartId: updatedCartItem.cartId,
      price: updatedCartItem.quantity * updatedCartItem.finalPrice,
      quantity: updatedCartItem.quantity,
      corePrice: updatedCartItem.corePrice,
      coreOption: updatedCartItem.coreOption,
      partBuyoutCoreOption: updatedCartItem.partBuyoutCoreOption,
      quantityAvailable: updatedCartItem.quantityAvailable,
      deliveryType: "",
      overridePriceApproval: "",
      overridePriceUser: "",
      binLocation: updatedCartItem.binLocation,
      isShoppingBasketSourced:
        updatedCartItem.binLocation !== null &&
          updatedCartItem.binLocation.startsWith("SB-")
          ? true
          : false,
      quantityDelta: updatedCartItem.quantityDelta,
      shoppingBasketQuantity: updatedCartItem.shoppingBasketQuantity,
      isRequestSplit: updatedCartItem.isRequestSplit,
      couponData: null,
      unitNumber: null
    };

    return updateForMycart;
  }

  logAppInsightDataForPlacedOrder(userId: string, cart: CartResult, sapOrderNumber: string, completeCartData: CartResultLineClient[]) {
    const sourceName = 'CheckoutComponent_placeOrder_placeOrder_Order';
    const metricName = this.loggerService.getMetricValue(sourceName);
    const appInsightOrder: AppInsightOrder = {
      userId: userId,
      customerNumber: cart.customerNumber,
      customerName: cart.customerName,
      branchNumber: cart.branchCode,
      //TODO: cart details are in cart now? Verify this
      cartDetails: JSON.stringify(cart),
      PONumber: cart.poNumber,
      orderNumber: sapOrderNumber,
      lineItems: cart.lineItems,
      unitNumber: cart.unitNumber,
      plMetricName: sourceName,
    };
    const appInsightOrderLog = JSON.stringify(appInsightOrder);
    appInsightOrder.lineItems = this.loggerService.getAppInsightParts(completeCartData, appInsightOrderLog.length);
    this.loggerService.trackMetric(metricName, appInsightOrder);
  }

  logAppInsightForPartsBuyout(filteredCartItems: UpdateFlagCartItem[], cart: CartResult, userId: string, customer: Customer) {
    let sourceName = 'CartComponent_confirmPartsBuyout__KnownPartBuyout';
    let metricName = this.loggerService.getMetricValue(sourceName);

    filteredCartItems.forEach((row: any) => {
      let appInsightPartBuyout: any = {
        userId: userId,
        customerNumber: customer.customerNumber,
        customerName: customer.customerName,
        branchNumber: cart.branchCode,
        cartNumber: cart.cartId,
        partId: row.partId,
        partNumber: row.partNumber,
        // TODO: Figure out how to get these properties from the source call
        // vendor: JSON.stringify({knownPartPrice: row.knownknownPartPrice,}).replace(/'/g, '"'),
        // isDefaultVendor: row.isDefaultVendorSelected,
        // defaultVendorPartPurchasePrice: row.defaultPrices != null ? row.defaultPrices.vendorPartPurchasePrice : 0,
        // defaultCustomerPartPurchasePrice: row.defaultPrices != null ? row.defaultPrices.customerPartPurchasePrice : 0,
        // defaultVendorCorePurchasePrice: row.defaultPrices != null ? row.defaultPrices.vendorCorePurchasePrice : 0,
        // defaultCustomerCorePrice: row.defaultPrices != null ? row.defaultPrices.customerCorePrice : 0,
        // vendorPartPurchasePrice: row.knownPartBuyoutAmount,
        // customerPartPurchasePrice: row.knownPartPrice,
        // vendorCorePurchasePrice: row.coreknownPartBuyoutAmount,
        // customerCorePrice: row.coreknownPartPrice,
        quantity: row.quantity,
        quantityAvailable: row.quantityAvailable,

        plMetricName: sourceName
      };

      this.loggerService.trackMetric(metricName, appInsightPartBuyout);
    });
  }

  logAppInsightDataForRemoveItemsFromCart(userId: string, cart: CartResult, itemsToRemoveFromCart: ItemToRemoveFromCart[]) {
    const sourceName = "CartComponent_removeCartItems";
    const metricName = this.loggerService.getMetricValue(sourceName);
    const appInsightQuote: AppInsightQuote = {
      userId,
      customerNumber: cart.customerNumber,
      customerName: cart.customerName,
      branchNumber: cart.branchCode,
      cartDetails: JSON.stringify(cart),
      plMetricName: sourceName,
      lineItems: cart.lineItems,
      orderNumber: cart.orderNumber,
      PONumber: cart.poNumber,
      unitNumber: cart.unitNumber
    };
    appInsightQuote.lineItems = this.loggerService.getAppInsightParts(itemsToRemoveFromCart, JSON.stringify(appInsightQuote).length);
    this.loggerService.trackMetric(metricName, appInsightQuote);
  }

  findCartCustomer(customerNumber: string, branchCode: string): Observable<any> {
    return forkJoin([
      this.customerService.searchCustomers({ customerNumber: customerNumber, customerType: "AG", branchCode: branchCode, accountGroups: ["Z001", "Z002"] }),
      this.customerService.searchCustomers({ customerType: 'RG', customerNumber: customerNumber, branchCode: branchCode })
    ])
  }

  hasSelectedCart(cartId: string): boolean {
    return cartId && cartId !== "" && cartId !== blankId;
  }
}
