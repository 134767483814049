
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" tabindex="-1" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
  <fieldset [disabled]="loading$ | async">
    <div class="modal-header">
      <h4 class="modal-title px-4">Search program for account</h4>
    </div>
    <div class="modal-body search-results-grid">
      <div class="px-4">
        <div class="title-of-box-hold py-3">
          <h5 class="strong">Find Accounts</h5>
        </div>
        <form *ngIf="searchCriteria$ | async as searchCriteria" #searchNgForm="ngForm" [ngClass]="{ 'was-validated': !searchNgForm.form.pending }" (ngSubmit)="searchNgForm.form.valid && search()">
          <div class="form-group">
            <label for="searchLoyaltyProgramAccountsModal_emailAddress">Email Address</label>
            <input id="searchLoyaltyProgramAccountsModal_emailAddress" type="text" class="form-control" name="emailAddress"
              [ngModel]="searchCriteria.emailAddress" (ngModelChange)="emailAddressChange($event)" [required]="!(atLeastOneSearchCriteria$ | async)">
          </div>
          <div class="form-group">
            <label for="searchLoyaltyProgramAccountsModal_phone">Telephone Number</label>
            <input id="searchLoyaltyProgramAccountsModal_phone" type="text" class="form-control" name="phone"
              [ngModel]="searchCriteria.phone" (ngModelChange)="phoneChange($event)" [required]="!(atLeastOneSearchCriteria$ | async)">
          </div>
          <div class="form-group">
            <label for="searchLoyaltyProgramAccountsModal_companyName">Company Name</label>
            <input id="searchLoyaltyProgramAccountsModal_companyName" type="text" class="form-control" name="companyName"
              [ngModel]="searchCriteria.companyName" (ngModelChange)="companyNameChange($event)" [required]="!(atLeastOneSearchCriteria$ | async)">
          </div>
          <div class="form-group">
            <label for="searchLoyaltyProgramAccountsModal_postalCode">Postal Code</label>
            <input id="searchLoyaltyProgramAccountsModal_postalCode" type="text" class="form-control" name="postalCode"
              [ngModel]="searchCriteria.postalCode" (ngModelChange)="postalCodeChange($event)" [required]="!(atLeastOneSearchCriteria$ | async)">
          </div>
          <div class="text-center">
            <button type="submit" class="btn btn-primary" [disabled]="!searchNgForm.form.valid">Search</button>
          </div>
        </form>
      </div>
      <div>
        <table class="table">
          <thead>
            <tr>
              <th></th>
              <th>Member ID</th>
              <th>Email Address</th>
              <th>Telephone</th>
              <th>Company Name</th>
              <th>Zip Code</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let loyaltyAccount of loyaltyAccounts$ | async; let i = index">
              <td>
                <div class="d-flex align-items-center">
                  <input id="searchLoyaltyProgramAccount_radio_{{i}}" type="radio" name="loyaltyAccount" [value]="loyaltyAccount.memberId" [ngModel]="selectedMemberId$ | async" (ngModelChange)="selectAccount(loyaltyAccount)">
                  <label for="searchLoyaltyProgramAccount_radio_{{i}}"></label>
                </div>
              </td>
              <td>{{ loyaltyAccount.memberId }}</td>
              <td>{{ loyaltyAccount.emailAddress }}</td>
              <td>{{ loyaltyAccount.phone }}</td>
              <td>{{ loyaltyAccount.companyName }}</td>
              <td>{{ loyaltyAccount.postalCode }}</td>
            </tr>
          </tbody>
        </table>
        <div *ngLet="pager$ | async as pager" class="mb-2" [hidden]="!(loyaltyAccounts$ | async).length">
          <nav id="myDashboard_div_ShowResults" class="d-flex justify-content-end align-items-center" aria-label="Page navigation" *ngIf="pager.pages && pager.pages.length">
            <div>
              <span class="pagination-result">Showing {{pager.startIndex + 1}} - {{pager.endIndex + 1}} of
                {{pager.totalItems}} Results
              </span>
            </div>
            <div>
              <ul class="pagination">
                <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                  <a id="myDashboard_a_First" class="page-link" aria-label="First" (click)="setPage(1)"><span aria-hidden="true">&laquo;</span></a>
                </li>
                <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                  <a id="myDashboard_a_Previous" class="page-link" aria-label="Previous" (click)="setPage(pager.currentPage - 1)"><span aria-hidden="true">&lsaquo;</span></a>
                </li>
                <li id="myDashboard_li_Page_{{i}}" *ngFor="let page of pager.pages; let i = index" class="page-item" [ngClass]="{active:pager.currentPage === page}">
                  <a id="myDashboard_a_Page_{{i}}" class="page-link" (click)="setPage(page)">{{page}}</a>
                </li>
                <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                  <a id="myDashboard_a_Next" class="page-link" aria-label="Next" (click)="setPage(pager.currentPage + 1)"><span aria-hidden="true">&rsaquo;</span></a>
                </li>
                <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                  <a id="myDashboard_a_Last" class="page-link" aria-label="Last" (click)="setPage(pager.totalPages)"><span aria-hidden="true">&raquo;</span></a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
    <form #ngForm="ngForm" [ngClass]="{ 'was-validated': !ngForm.form.pending }" [noop]="ngForm.form.statusChanges | async" (ngSubmit)="ngForm.form.valid && submit()">
      <div class="modal-footer">
        <input type="hidden" name="selectedLoyaltyAccount" [ngModel]="selectedLoyaltyAccount$ | async" required>
        <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
        <button type="submit" class="btn btn-primary" [disabled]="!ngForm.form.valid">Add to order</button>
      </div>
    </form>
  </fieldset>
