import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CartResult } from 'entities/cart-result';
import { Cart } from 'entities/carts/cart';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppState } from 'store/app-state';
import * as CartActions from 'store/cart/cart.actions';
import * as ConfigurationSelectors from "store/configuration/configuration.selectors";
import * as CustomerSelectors from 'store/customer/customer.selectors';
import { SelectedCustomer } from 'store/customer/customer.state';
import { OpenCartFilters, OpenComponentStore } from './open-cart.component.store';
@Component({
  selector: 'open-cart',
  templateUrl: './open-cart.component.html',
  styleUrls: ['./open-cart.component.css'],
  providers: [OpenComponentStore]
})
export class OpenCartComponent implements OnInit, OnDestroy {
  private readonly cashCustomerNumber = '200000';
  public selectedCustomer$: Observable<SelectedCustomer> = this.store.select(CustomerSelectors.selectedCustomer);
  selectedCartId: string;
  loading$: Observable<boolean> = this.openCartCompStore.loading$;
  filters$: Observable<string> = this.openCartCompStore.filter$;
  openCarts$: Observable<Cart[]> = this.openCartCompStore.openCarts$;
  currentPage = 1;
  pageSize = 5;
  filteredCarts$: Observable<Cart[]> = this.openCartCompStore.filteredCarts$;
  filters: OpenCartFilters = {
    byCustomer: false,
    byBranch: false
  };

  updateFilterOnCustomerChange: Subscription = combineLatest([
    this.store.select(CustomerSelectors.selectedCustomer),
    this.store.select(ConfigurationSelectors.selectCustomer),
    this.openCarts$
  ]).pipe(
    map(([customer, defaultCustomer, carts]) => ({
        anyDefaultCustomerOpenCarts: Boolean(carts.find(c => c.customerNumber ===  defaultCustomer?.customerNumber)),
        isCashCustomer: customer.customerNumber === this.cashCustomerNumber
    })),
    tap((mapped) => {
      this.filters.byCustomer = !mapped.isCashCustomer;
      this.openCartCompStore.filterByBranchOrCustomer(this.filters);
    })
  ).subscribe();

  constructor(
    private store: Store<AppState>,
    private openCartCompStore: OpenComponentStore
  ) {}

  ngOnInit() {
    this.openCartCompStore.retrieveOpenCarts();
  }

  goToCart(cart: CartResult) {
    this.store.dispatch(CartActions.selectOpenCart({ openCartId: cart.cartId, navigateToMyCart: true }));
  }

  deleteCart(cart: Cart) {
    this.openCartCompStore.deleteCart(cart.cartId);
  }

  createNewCart() {
    this.openCartCompStore.createCart();
  }

  updateCustomerFilter(isFiltered: boolean) {
    this.filters.byCustomer = isFiltered;
    this.openCartCompStore.filterByBranchOrCustomer(this.filters);
  }
  updateBranchFilter(isFiltered: boolean) {
    this.filters.byBranch = isFiltered;
    this.openCartCompStore.filterByBranchOrCustomer(this.filters);
  }

  ngOnDestroy(): void {
    this.updateFilterOnCustomerChange.unsubscribe();
  }
}

