import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Injectable } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { Permission } from 'entities/enums';
import { CommonDataService } from 'services/common-data.service';
import { ConfigurationService } from 'services/configuration.service';
import { ToastService } from 'services/toast.service';
import { ToastType } from 'entities/toast-type';

@Injectable()
export class PriceExceptionCreateGuard  {
  constructor(
    private commonDataService: CommonDataService,
    private configurationService: ConfigurationService,
    private toastService: ToastService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    const hasCreatePriceException = (permissions: any[]) => permissions && permissions.length && permissions.includes(Permission.CreatePriceException);

    const hasGlobalPermission$ = this.configurationService.globalPermissions$
      .pipe(
        first(),
        map((globalPermissions) => hasCreatePriceException(globalPermissions))
      );

    const hasBranchPermission$ = combineLatest([this.configurationService.entityPermissions$,
    this.commonDataService.customer$, this.commonDataService.branch$])
      .pipe(
        first(),
        map(([entityPermissions, customer, branch]) => {
          if (customer?.controlBranchCode && customer?.controlBranchCode !== branch?.code) {
            const message = `Branch ${customer?.controlBranchCode} is the control branch for Customer ${customer.customerName}
             – please contact them to submit a price exception`;
            this.toastService.showToast(message, ToastType.Error);
            return false;
          }
          if (entityPermissions && hasCreatePriceException(entityPermissions[branch?.code])) {
            return true;
          } else {
            const message = `You do not have permission to create price exceptions`;
            this.toastService.showToast(message, ToastType.Error);
            return false;
          }
        }),
      );

    return hasGlobalPermission$
      .pipe(
        switchMap((hasPermission) => {
          if (hasPermission) {
            return of(true);
          }
          return hasBranchPermission$;
        })
      );
  }
}
