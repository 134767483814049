import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';
import * as constants from 'app/app.constants';
import { Observable, fromEvent, timer } from 'rxjs';
import { delayWhen, filter, mapTo, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ConfigurationService } from 'services/configuration.service';
import { FeatureFlagService } from 'services/feature-flag.service';
import { ProactiveChatComponentStore } from './proactive-chat.component.store';
import { User } from 'entities/user';

declare class Five9SocialWidget {
  static addWidget: any;
}
@Component({
  selector: 'proactive-chat',
  templateUrl: './proactive-chat.component.html',
  styleUrls: ['./proactive-chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProactiveChatComponentStore]
})

export class ProactiveChatComponent implements OnDestroy {

  public dismissed: boolean = false;

  public chatEnabled$: Observable<void> = this.configurationService.user$
    .pipe(
      filter((user: User) => this.featureFlagService.isFeatureActive("Five9Chat")
        && !user.isInternal
        && this.checkBusinessHours()
      ),
      mapTo(void 0)
    );

  public proactiveChatEnabled$: Observable<void> = this.chatEnabled$
    .pipe(
      filter(() => this.featureFlagService.isFeatureActive("ProactiveChat"))
    );

  private loadChatWidget$: Observable<void> = this.chatEnabled$
    .pipe(
      tap(() => {
        Five9SocialWidget.addWidget({
          type: 'chat',
          rootUrl: 'https://app.five9.com/consoles/',
          tenant: 'Rush Enterprises',
          title: 'Rush Enterprises',
          logo: 'https://cdn-rushsharedcdnprod.azureedge.net/PartsConnect/RTC-Logo-png-for-rtc-site_new_224x104.png',
          profiles: ["Parts"],
          showProfiles: false,
          "fields": {
            profiles: { label: 'Please select a department' },
            name: { label: 'Your name' },
            email: { label: 'Your email address' },
            question: { label: 'Thank you for contacting Rush Truck Centers! How can we help you today?' }
          },
          useBusinessHours: false,
          showEmailButton: false,
          playSoundOnMessage: true,
          allowCustomerToControlSoundPlay: true,
          allowUsabilityMenu: true,
          theme: `${this.webUrl}/assets/scss/five9.css`
        });
      }),
      shareReplay(1)
    );

  private subscription = this.loadChatWidget$.subscribe();

  private chatNowClicked$: Observable<void> = this.loadChatWidget$
    .pipe(
      switchMap(() => fromEvent<void>(document.getElementById('five9-maximize-button'), 'click'))
    );

  public displayProactiveChat$: Observable<boolean> = this.componentStore.displayPrompt$
    .pipe(
      delayWhen((show) => timer(show ? 0 : 1100))
    );

  public displayProactiveChatAnimation$: Observable<boolean> = this.componentStore.displayPrompt$;

  constructor(
    private componentStore: ProactiveChatComponentStore,
    private configurationService: ConfigurationService,
    private featureFlagService: FeatureFlagService,
    @Inject(constants.WEB_URL) private webUrl: string
  ) {
    this.componentStore.init(this.proactiveChatEnabled$);
    this.componentStore.chatOpened(this.chatNowClicked$);
  }

  checkBusinessHours(): boolean {
    const date = moment.tz("America/Chicago");
    const day = date.day();
    const hour = date.hour();

    return (5 >= day && day >= 1) && (17 > hour && hour >= 8);
  }

  chatNow(value: boolean): void {
    if (value) {
      const element: HTMLElement = document.getElementById('five9-maximize-button') as HTMLElement;
      element.click();
    } else {
      this.componentStore.promptDismissed();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
