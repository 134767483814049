import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';
import { ConfigurationService } from 'services/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ClientConfigurationGuard  {
  constructor(
    private router: Router,
    private configurationService: ConfigurationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    return this.configurationService.configuration$
      .pipe(
        mapTo(true),
        // Note the ResponseInterceptor took the HttpErrorResponse and rethrew `response.error`
        catchError((error) => {
          const tree = this.router.parseUrl('/misc/error');
          let queryParams: Params = { returnUrl: state.url };

          if (typeof error === 'string') {
            queryParams = {
              ...queryParams,
              error: error,
              hideReload: true,
              hideFeedback: true
            }
          }

          tree.queryParams = queryParams;

          return of(tree);
        })
      );
  }
}

