export class PartSearch {
    partId: string = '';
    partSearchTerm: string = '';
    branchCode: string = '';
    customerNumber: string = '';
    isCountCheck: boolean = true;
    pageNumber: number = 1;
    pageSize: number = 20;
    refinements: Refinement[] = null;
    isExpandedSearch: boolean = true;
}

export class Refinement {
    field: string = null;
    terms: string[] = [];
}
