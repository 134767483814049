import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OnChange, OnTouched } from 'entities/control-value-accessor-funcs';
import { OrderLineItemDeliveryStatusOption } from 'entities/order-details-line-item';
import { ReplaySubject, Subject } from 'rxjs';
import { map, scan, tap } from 'rxjs/operators';
import { getShippingStatusLabel } from '../order-details-table/order-details-table-utils';

type OrderLineItemsDeliveryStatusFilterAction =
  | { type: 'setValue', value: OrderLineItemDeliveryStatusOption[] }
  | { type: 'setAvailableOptions', availableOptions: OrderLineItemDeliveryStatusOption[] }
  | { type: 'toggleOption', option: OrderLineItemDeliveryStatusOption }
  | { type: 'clear' };

@Component({
  selector: 'order-line-items-delivery-status-filter',
  templateUrl: './order-line-items-delivery-status-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OrderLineItemsDeliveryStatusFilterComponent),
    multi: true
  }]
})
export class OrderLineItemsDeliveryStatusFilterComponent implements ControlValueAccessor, OnDestroy {
  public shippingStatusLabel = getShippingStatusLabel;
  private readonly initialOptions: KeyValue<OrderLineItemDeliveryStatusOption, boolean>[] = [
    { key: 'notApplicable', value: false },
    { key: 'delivered', value: false },
    { key: 'enRoute', value: false },
    { key: 'preparing', value: false },
    { key: 'shipped', value: false }
  ];

  private actionSubject: Subject<OrderLineItemsDeliveryStatusFilterAction> = new ReplaySubject();

  @Input() set deliveryStatusOptions(availableOptions: OrderLineItemDeliveryStatusOption[]) {
    this.actionSubject.next({ type: 'setAvailableOptions', availableOptions });
  }

  private state$ = this.actionSubject
    .pipe(
      scan(
        (state, action) => {
          switch (action.type) {
            case 'setValue':
              return {
                ...state,
                options: state.options
                  .map((option) => ({
                    ...option,
                    value: (action.value || []).includes(option.key)
                  }))
              };
            case 'setAvailableOptions':
              return {
                ...state,
                availableOptions: [...(action.availableOptions || [])]
              };
            case 'toggleOption':
              return {
                ...state,
                options: state.options
                .map((option) => ({
                  ...option,
                  value: option.key === action.option ? !option.value : option.value
                }))
              };
            case 'clear':
            default:
              return {
                ...state,
                options: this.initialOptions.map((option) => ({ ...option }))
              };
          }
        },
        { options: this.initialOptions, availableOptions: [] } as {
          options: KeyValue<OrderLineItemDeliveryStatusOption, boolean>[],
          availableOptions: OrderLineItemDeliveryStatusOption[]
        }
      )
    );

  options$ = this.state$
    .pipe(
      map((state) => state.options.filter((option) => state.availableOptions.includes(option.key)))
    );
  disabled: boolean = false;

  private subscription = this.options$
    .pipe(
      map((options) => options
        .filter((option) => option.value)
        .map((option) => option.key)
      ),
      tap((value) => {
        this.onTouched();
        this.onChange(value);
      })
    )
    .subscribe();

  private onChange: OnChange<OrderLineItemDeliveryStatusOption[]> = (value) => { };
  private onTouched: OnTouched = () => { };

  writeValue(value: OrderLineItemDeliveryStatusOption[]): void {
    this.actionSubject.next({ type: 'setValue', value });
  }
  registerOnChange(fn: OnChange<OrderLineItemDeliveryStatusOption[]>): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: OnTouched): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  toggleOption(option: OrderLineItemDeliveryStatusOption) {
    this.actionSubject.next({ type: 'toggleOption', option });
  }

  clear() {
    this.actionSubject.next({ type: 'clear' });
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
}
