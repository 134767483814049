export class OrderConfirmationValidator {
  order: OCValidatorParams;
  constructor(params: OCValidatorParams) {
    this.order = params;
  }

  private _validate(): OCButtonArrangement {

    const distinctJobs = [...new Set(this.order.orderItems.map(x => x.job))];
    const containsFrontCounterSelfPickItems = this.order.orderItems.map(x => x.deliveryOption).includes("L");
    const containsShoppingBasketItems = this.order.orderItems.map(x => x.deliveryOption).includes("A");
    const distinctDeliveryOptions = [...new Set(this.order.orderItems.map(x => x.deliveryOption))];

    const containAdhoc = this.order.orderItems.some(x => x.deliveryOption === "A" );
    const containAdhocOnly = this.order.orderItems.every(x => x.deliveryOption === "A" );
    const containFCSP = this.order.orderItems.some(x => x.deliveryOption === "L" );
    const containFCSPOnly = this.order.orderItems.every(x => x.deliveryOption === "L" );
    const hasNonAdhocAndFCSP = this.order.orderItems.some(x => x.deliveryOption !== "L" && x.deliveryOption !== "A");
    const containAdhocOrFCSP = this.order.orderItems.every(x => x.deliveryOption === "L" || x.deliveryOption === "A");
    const containAdhocAndFCSPOnly = containAdhoc && containFCSP && !hasNonAdhocAndFCSP;
    const isQuote = this.order.orderType === "Quote";

    if(containAdhocOrFCSP){
      if(containFCSPOnly){
        if(isQuote){
          return OCButtonArrangement.RTP_E_CB_H;
        } else {
          if(distinctJobs.includes("000001") && distinctJobs.includes("000010")){
            return OCButtonArrangement.RTP_E_CB_H;
          }
          if(distinctJobs.length === 1 && distinctJobs.includes("000010")){
            return OCButtonArrangement.RTP_H_CB_H;
          }
          return OCButtonArrangement.RTP_E_CB_D;
        }
      }
      if(containAdhocOnly){
        if(isQuote){
          return OCButtonArrangement.RTP_H_CB_H;
        } else {
          return OCButtonArrangement.RTP_H_CB_E;
        }
      }
      if(containAdhocAndFCSPOnly){
        if(isQuote){
          return OCButtonArrangement.RTP_E_CB_H;
        } else {
          return OCButtonArrangement.RTP_E_CB_D;
        }
      }

      if((containAdhoc || containFCSP) && hasNonAdhocAndFCSP){
        return OCButtonArrangement.RTP_E_CB_H;
      }
    }

    if(this.order.orderType === "Quote"){
      return OCButtonArrangement.RTP_H_CB_H;
    }

    if(containsFrontCounterSelfPickItems && containsShoppingBasketItems && this.order.orderItems.filter(x => x.deliveryOption != "A" && x.deliveryOption != "L").some(x => x.job === "000001")){
      return OCButtonArrangement.RTP_E_CB_H;
    }

    if(containsFrontCounterSelfPickItems && containsShoppingBasketItems){
      return OCButtonArrangement.RTP_E_CB_H;
    }

    if(containsFrontCounterSelfPickItems && this.order.orderItems.filter(item => item.job === "000001" && item.deliveryOption != "L").length >= 1){
      return OCButtonArrangement.RTP_E_CB_H;
    }

    if(containsFrontCounterSelfPickItems && distinctDeliveryOptions.length === 1){
      return OCButtonArrangement.RTP_E_CB_H;
    }

    if(distinctJobs.length === 1 && distinctJobs.includes("000000")){
      if(isQuote){
        return OCButtonArrangement.RTP_H_CB_H;
      } else {
        return OCButtonArrangement.RTP_H_CB_E;
      }
    }

    if(this.order.orderItems.length === 1 && distinctJobs.includes("000001")){
      return OCButtonArrangement.RTP_E_CB_H;
    }
    if(distinctJobs.length === 1 && distinctJobs.includes("000010")){
      return OCButtonArrangement.RTP_H_CB_H;
    }

    if(distinctJobs.includes("000001") && distinctJobs.includes("000010") && distinctJobs.includes("000000")){
      return OCButtonArrangement.RTP_E_CB_H;
    }
    if(distinctJobs.includes("000001") && distinctJobs.includes("000010")){
      return OCButtonArrangement.RTP_E_CB_H;
    }


    if(distinctJobs.includes("000010") && distinctJobs.length === 1){
      return OCButtonArrangement.RTP_H_CB_H;
    }

    if(distinctJobs.includes("000001") && distinctJobs.length === 1){
      return OCButtonArrangement.RTP_E_CB_H;
    }

    if(containsShoppingBasketItems && distinctJobs.includes("000010")){
      return OCButtonArrangement.RTP_H_CB_H;
    }

    if(containsShoppingBasketItems && distinctJobs.length === 1){
      return OCButtonArrangement.RTP_D_CB_H;
    }

    if(distinctJobs.includes("000001") && distinctJobs.includes("000000")){
      return OCButtonArrangement.RTP_E_CB_H;
    }




    return OCButtonArrangement.RTP_H_CB_H;
  }

  validate(): OCValidatorResponse {
    let showCreateBillingButton = true;
    let readyToPickShown = false;
    let createBillingEnabled = false;
    switch(this._validate()){
      case OCButtonArrangement.RTP_D_CB_E:
        showCreateBillingButton = true;
        createBillingEnabled = true;
        break;
      case OCButtonArrangement.RTP_D_CB_H:
        showCreateBillingButton = false;
        createBillingEnabled = false;
        readyToPickShown = true;
        break;
      case OCButtonArrangement.RTP_E_CB_D:
        readyToPickShown = true;
        createBillingEnabled = false;
        showCreateBillingButton = true;
        break;
      case OCButtonArrangement.RTP_E_CB_E:
        readyToPickShown = true;
        createBillingEnabled = true;
        showCreateBillingButton = true;
        break;
      case OCButtonArrangement.RTP_E_CB_H:
        readyToPickShown = true;
        createBillingEnabled = false;
        showCreateBillingButton = false;
        break;
      case OCButtonArrangement.RTP_H_CB_E:
        readyToPickShown = false;
        createBillingEnabled = true;
        showCreateBillingButton = true;
        break;
      case OCButtonArrangement.RTP_H_CB_H:
        readyToPickShown = false;
        createBillingEnabled = false;
        showCreateBillingButton = false;
        break;
      case OCButtonArrangement.RTP_H_CB_D:
        readyToPickShown = false;
        createBillingEnabled = false;
        showCreateBillingButton = true;
        break;
    }
    return {readyToPickShown, createBillingEnabled, showCreateBillingButton};
  }
}


/* CB = Create Billing Button
 * RTP = Ready To Pick Button
 * E = Enabled
 * D = Disabled
 * H = Hidden
 */
export enum OCButtonArrangement {
  RTP_E_CB_E,
  RTP_D_CB_E, // This Scenario Probably Would Never Happen
  RTP_H_CB_E,

  RTP_E_CB_D,
  RTP_E_CB_H,

  RTP_H_CB_H,
  RTP_D_CB_H,
  RTP_H_CB_D
}

export interface OCValidatorParams {
  orderItems: {
    job: string;
    deliveryOption: string;
  }[];
  orderType: string;
}

export interface OCValidatorResponse {
  showCreateBillingButton: boolean;
  readyToPickShown: boolean;
  createBillingEnabled: boolean;
}
