import { Injectable, Type } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { of, EMPTY } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

/**
 * @description
 * Wrapper around bootstrap NgbModal that returns an observable rather than a promise.
 * This can be used for testing with jasmine-marbles.
*/
@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private modalService: NgbModal) { }

  open<T>(type: Type<T>, inputs?: Partial<T>, options?: NgbModalOptions) {
    return of({ type, inputs, options })
      .pipe(
        mergeMap(({ type, inputs, options }) => {
          const modalRef = this.modalService.open(type, options);
          if (inputs) {
            for (const inputKey in inputs) {
              modalRef.componentInstance[inputKey] = inputs[inputKey];
            }
          }
      
          return modalRef.result;
        }),
        catchError(() => EMPTY)
      );
  }
}
