<button id="VendorsModal_Button_Close" type="button" class="close" data-dismiss="modal" aria-label="Close" tabindex="-1" (click)="closeModal()">
  <span aria-hidden="true">&times;</span>
</button>
<div class="modal-header">
  <h4 class="modal-title">Vendors</h4>
  <div class="input-group w-33">
      <input id="VendorsModal_Text_Search" type="text" class="form-control customer-search-textbox" placeholder="Search ..." [(ngModel)]="filterKey" (keyup)="filter()">
      <span class="input-group-text">
        <i class="fas fa-search" aria-hidden="true"></i>
      </span>
  </div>
</div>
<div class="modal-body customer-table">
  <table class="table">
    <thead>
      <tr>
        <th>
          <a id="VendorsModal_A_SortByName" role="button" href="javascript:void(0)" (click)="sortDataBy('name');">
            Name
            <span [hidden]="sortBy != 'name'" class="fas" [ngClass]="{'fa-caret-up': sortBy == 'name' && sortAsc, 'fa-caret-down': sortBy == 'name' && !sortAsc}">
            </span>
          </a>
        </th>
        <th>
          <a id="VendorsModal_A_SortByAddress" role="button" href="javascript:void(0)" (click)="sortDataBy('city');">
            Address
            <span [hidden]="sortBy != 'city'" class="fas" [ngClass]="{'fa-caret-up': sortBy == 'city'&& sortAsc, 'fa-caret-down': sortBy == 'city' && !sortAsc}">
            </span>
          </a>
        </th>
        <th style="white-space: nowrap">
          <a id="VendorsModal_A_SortByCurrency" role="button" href="javascript:void(0)" (click)="sortDataBy('currency');">
            Currency
            <span [hidden]="sortBy != 'currency'" class="fas" [ngClass]="{'fa-caret-up': sortBy == 'currency'&& sortAsc, 'fa-caret-down': sortBy == 'currency' && !sortAsc}">
            </span>
          </a>
        </th>
        <th style="white-space: nowrap">
          <a id="VendorsModal_A_SortByStatus" role="button" href="javascript:void(0)" (click)="sortDataBy('status');">
            Status
            <span [hidden]="sortBy != 'status'" class="fas" [ngClass]="{'fa-caret-up': sortBy == 'status'&& sortAsc, 'fa-caret-down': sortBy == 'status' && !sortAsc}">
            </span>
          </a>
        </th>
        <th style="white-space: nowrap">
          <a id="VendorsModal_A_SortByTerms" role="button" href="javascript:void(0)" (click)="sortDataBy('terms');">
            Terms
            <span [hidden]="sortBy != 'terms'" class="fas" [ngClass]="{'fa-caret-up': sortBy == 'terms'&& sortAsc, 'fa-caret-down': sortBy == 'terms' && !sortAsc}">
            </span>
          </a>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr id="VendorsModal_TR_VendorSelect_{{i}}" *ngFor="let vendor of pagedAdvancedVendors; let i = index" (click)="onVendorSelect(vendor);">
        <td scope="row">
          <div id="VendorsModal_Div_Name_{{i}}" *ngIf="vendor.name">{{vendor.name}}</div>
          <div id="VendorsModal_Div_Name2_{{i}}" *ngIf="vendor.name2">{{vendor.name2}}</div>
          <div id="VendorsModal_Div_Number_{{i}}" *ngIf="vendor.number">{{vendor.number | customerNumber}}</div>
        </td>
        <td>
          <div id="VendorsModal_Div_Address_{{i}}" *ngIf="vendor.address | trim">{{vendor.address}}</div>
          <div id="VendorsModal_Div_CityStateZip_{{i}}" *ngIf="(vendor.city | trim) || (vendor.state | trim) || (vendor.zipCode | trim)">{{[([vendor.city, vendor.state] | join:', '), vendor.zipCode] | join:' '}}</div>
          <div id="VendorsModal_Div_Phone_{{i}}" *ngIf="vendor.phone">{{vendor.phone}}</div>
        </td>
        <td id="VendorsModal_TD_Currency_{{i}}">{{vendor.currency}}</td>
        <td id="VendorsModal_TD_Status_{{i}}">{{vendor.status}}</td>
        <td>
          <div id="VendorsModal_Div_Terms_{{i}}" *ngIf="vendor.terms">{{vendor.terms}}</div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <nav id="VendorsModal_Nav_Navigation" class="d-flex justify-content-end align-items-center" aria-label="Page navigation" *ngIf="pager.pages && pager.pages.length">
    <div>
      <span class="pagination-result">Showing {{pager.startIndex + 1}} - {{pager.endIndex + 1}} of {{pager.totalItems}} Results</span>
    </div>
    <div>
      <ul class="pagination ">
        <li class="page-item" [ngClass]="{disabled:pager.currentPage == 1}">
          <a id="VendorsModal_A_FirstPage" class="page-link" aria-label="First" (click)="setPage(1)"><span aria-hidden="true">&laquo;</span></a>
        </li>
        <li class="page-item" [ngClass]="{disabled:pager.currentPage == 1}">
          <a id="VendorsModal_A_PreviousPage" class="page-link" aria-label="Previous" (click)="setPage(pager.currentPage - 1)"><span aria-hidden="true">&lsaquo;</span></a>
        </li>
        <li *ngFor="let page of pager.pages" class="page-item" [ngClass]="{active:pager.currentPage == page}">
          <a id="VendorsModal_A_CurrentPage" class="page-link" (click)="setPage(page)">{{page}}</a>
        </li>
        <li class="page-item" [ngClass]="{disabled:pager.currentPage == pager.totalPages}">
          <a id="VendorsModal_A_NextPage" class="page-link" aria-label="Next" (click)="setPage(pager.currentPage + 1)"><span aria-hidden="true">&rsaquo;</span></a>
        </li>
        <li class="page-item" [ngClass]="{disabled:pager.currentPage == pager.totalPages}">
          <a id="VendorsModal_A_LastPage" class="page-link" aria-label="Last" (click)="setPage(pager.totalPages)"><span aria-hidden="true">&raquo;</span></a>
        </li>
      </ul>
    </div>
  </nav>
</div>
<!--Search Vendor Module Box End -->
