<div id="toaster">

  <div id="alertToasts" *ngIf="toastService.show">
      <div class="d-table mx-auto alert alert-secondary" id="ToastTypeContainer" [ngClass]="{
          'alert-success': toastService.type == toastType.Success,
          'alert-info': toastService.type == toastType.Info,
          'alert-warning': toastService.type == toastType.Warning,
          'alert-danger': toastService.type == toastType.Abort || toastService.type == toastType.Error
          }">
          <button class="close close-button" aria-label="close" type="button" (click)="closeToast()" id="btnCloseToast"
              name="btnCloseToast">
              &times;
          </button>
          <div *ngFor="let msg of toastService.message;" class="d-flex align-items-center gx-2" style="min-width: 122px;">
              <i class="fas" id="toastIcon" [ngClass]="{
                  'fa-check-circle text-success': msg.type == toastType.Success,
                  'text-info': msg.type == toastType.Info,
                  'text-warning': msg.type == toastType.Warning,
                  'fa-exclamation-triangle': msg.type == toastType.Info || msg.type == toastType.Warning,
                  'fa-times-circle text-danger': msg.type == toastType.Abort || msg.type == toastType.Error
                  }"></i>
              <div id="toastMessage" [innerHTML]="msg.message" class="text-secondary" [ngClass]="{
                  'text-success': msg.type == toastType.Success,
                  'text-info': msg.type == toastType.Info,
                  'text-warning': msg.type == toastType.Warning,
                  'text-danger': msg.type == toastType.Abort || msg.type == toastType.Error
                  }"></div>
          </div>
      </div>
  </div>

  <div id="alertToastsStatic" *ngIf="toastService.showStatic">
    <div class="d-table mx-auto alert alert-secondary" id="ToastTypeContainerStatic" [ngClass]="{
        'alert-success': toastService.type == toastType.Success,
        'alert-info': toastService.type == toastType.Info,
        'alert-warning': toastService.type == toastType.Warning,
        'alert-danger': toastService.type == toastType.Abort || toastService.type == toastType.Error
        }">
        <button class="close close-button" aria-label="close" type="button" (click)="closeStaticToast()" id="btnCloseToast"
            name="btnCloseToast">
            &times;
        </button>
        <div *ngFor="let msg of toastService.staticMessage;" class="d-flex align-items-center gx-2" style="min-width: 122px;">
          <i class="fas" id="toastIcon" [ngClass]="{
              'fa-check-circle text-success': msg.type == toastType.Success,
              'text-info': msg.type == toastType.Info,
              'text-warning': msg.type == toastType.Warning,
              'fa-exclamation-triangle': msg.type == toastType.Info || msg.type == toastType.Warning,
              'fa-times-circle text-danger': msg.type == toastType.Abort || msg.type == toastType.Error
              }"></i>
          <div id="toastMessage" [innerHTML]="msg.message" class="text-secondary" [ngClass]="{
              'text-success': msg.type == toastType.Success,
              'text-info': msg.type == toastType.Info,
              'text-warning': msg.type == toastType.Warning,
              'text-danger': msg.type == toastType.Abort || msg.type == toastType.Error
              }"></div>
      </div>
    </div>
  </div>

  </div>
