/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @angular-eslint/component-selector */
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ToastType } from 'entities/toast-type';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { tap, withLatestFrom } from 'rxjs/operators';
import { AutocompleteService } from 'services/auto-complete.service';
import { SourceLocationType } from 'services/logger.service';
import { ToastService } from 'services/toast.service';

@Component({
    selector: 'parts-search-base',
    template: ""
})
export class PartsSearchBaseComponent {

    public isExpandedSearchSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isExpandedSearch$: Observable<boolean> = this.isExpandedSearchSubject.asObservable();
    public partSearchSubject: Subject<void> = new Subject<void>();

    public searchTermSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
    public searchTerm$: Observable<string> = this.searchTermSubject.asObservable();

    navigationProperties$: Observable<[string, boolean]> = combineLatest([this.searchTerm$, this.isExpandedSearch$]);
    partsSearchNavigate$ = this.partSearchSubject.pipe(
        withLatestFrom(this.navigationProperties$),
        tap(([, [searchTerm, isExpandedSearch]]) => this.navigateToPartSearch(searchTerm, isExpandedSearch))
    );

    public autocomplete = (text$: Observable<string>): Observable<string[]> => text$.pipe(
        withLatestFrom(this.isExpandedSearch$),
        this.autoCompleteService.autocomplete
    );

    constructor(
        public toastService: ToastService,
        public router: Router,
        public autoCompleteService: AutocompleteService,
    ) { }


    updateIsExpandedSearch(isExpandedSearch: boolean) {
        this.isExpandedSearchSubject.next(isExpandedSearch);
    }

    updateSearchTerm(searchTerm: string) {
        this.searchTermSubject.next(searchTerm);
    }

    navigateToPartSearch(searchTerm: string, isExpandedSearch: boolean) {
        const trimmed = searchTerm && searchTerm.trim();
        if (!trimmed) {
            this.toastService.showToast('Enter a search term before searching', ToastType.Warning);
            return;
        }
        this.autoCompleteService.clearFilters();
        this.router.navigate(['parts'], {
            queryParams: {
                searchTerm: trimmed,
                src: SourceLocationType.PartSearch,
                isExpandedSearch
            }
        });
    }

    selectSearchTerm(event: NgbTypeaheadSelectItemEvent) {
        this.searchTermSubject.next(event.item);
        this.partSearchSubject.next();
    }

    partSearch() {
        this.partSearchSubject.next();
    }

}
