import { ApplicationInitStatus, Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { Branch } from 'entities/branch';
import { Pager } from 'entities/pager';
import { ToastType } from 'entities/toast-type';
import { sortData } from 'helpers';
import { Observable, OperatorFunction, Subscription } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { MyDashboardService } from 'services/my-dashboard.service';
import { ToastService } from 'services/toast.service';
import { AppState } from 'store/app-state';
import * as BranchSelectors from 'store/branch/branch.selectors';
import * as MyDashboardActions from 'store/my-dashboard/my-dashboard.actions';

export interface MyDashboardTabComponentState {
  branch: Branch;
  filterByUser: boolean;
  filterByBranch: boolean;
  filterByType: 'cart' | 'quote' | null;
  searchTerm: string;
  pageNumber: number;
  sort: { by: string, ascending: boolean } | null;
  loading: boolean;
  items: any[];
}

@Injectable()
export class MyDashboardTabComponentStore extends ComponentStore<MyDashboardTabComponentState> {
  private readonly pageSize = 10;

  constructor(
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private myDashboardService: MyDashboardService,
    private toastService: ToastService,
    applicationInitStatus: ApplicationInitStatus
  ) {
    super({
      branch: null,
      filterByUser: true,
      filterByBranch: true,
      filterByType: null,
      searchTerm: null,
      pageNumber: 1,
      sort: null,
      loading: false,
      items: []
    });
    applicationInitStatus.donePromise
      .then(() => this.onInit());
  }

  public readonly initialization = this.effect((source$: Observable<{ branch: Branch }>) => source$
    .pipe(
      tap(({ branch }) => this.patchState({ branch })),
      this.getItems()
    )
  )

  onInit() {
    this.initialization(
      this.store.select(BranchSelectors.selectedBranch)
        .pipe(
          filter((branch) => Boolean(branch)),
          first(),
          map((branch) => ({ branch }))
        )
    );
    this.parametersChanged(this.activatedRoute.queryParams);
  }

  public readonly filterByUserClicked = this.effect((source$: Observable<void>) => source$
    .pipe(
      tap(() => this.patchState((state) => ({
        filterByUser: !state.filterByUser,
        filterByBranch: state.filterByUser ? true : state.filterByBranch
      }))),
      this.getItems()
    )
  );

  public readonly filterByBranchClicked = this.effect((source$: Observable<void>) => source$
    .pipe(
      tap(() => this.patchState((state) => ({
        filterByBranch: state.filterByUser ? !state.filterByBranch : true
      }))),
      this.getItems()
    )
  );

  public readonly branchSelected = this.effect((branch$: Observable<Branch>) => branch$
    .pipe(
      filter((branch) => Boolean(branch)),
      tap((branch) => {
        this.patchState({
          branch,
          filterByBranch: true
        });

        this.store.dispatch(MyDashboardActions.setDashboardBranch({branchCode: branch.code}))
      }
      ),
      this.getItems()
    )
  );

  public readonly refresh = this.effect<void, Observable<void>, void, (observableOrValue: void | Observable<void>) => Subscription>((source$: Observable<void>) => source$
    .pipe(
      this.getItems()
    )
  );

  public readonly parametersChanged = this.updater((state, params: Params) => ({
    ...state,
    filterByType: params['openItemsFilterType'],
    pageNumber: 1
  }));

  public readonly searchTermChanged = this.updater((state, searchTerm: string) => ({
    ...state,
    searchTerm,
    pageNumber: 1
  }));

  public readonly sortSelected = this.updater((state, sortBy: string) => ({
    ...state,
    sort: {
      by: sortBy,
      ascending: state.sort?.by === sortBy ? !state.sort.ascending : true
    }
  }));

  public readonly pageSelected = this.updater((state, pageNumber: number) => ({
    ...state,
    pageNumber
  }));

  public readonly loading$ = this.select((state) => state.loading);

  public readonly filterByUser$ = this.select((state) => state.filterByUser);

  public readonly filterByBranch$ = this.select((state) => state.filterByBranch);

  public readonly branch$ = this.select((state) => state.branch);

  public filteredItems$ = this.select((state) =>
    this.filterItemsFn(state.filterByType, state.searchTerm)(state.items)
  );

  public sort$ = this.select((state) => state.sort);

  public pager$ = this.select(this.state$, this.filteredItems$, (state, filteredItems) =>
    new Pager(filteredItems.length, state.pageNumber, this.pageSize)
  );

  public items$ = this.select(this.state$, this.filteredItems$, this.pager$, (state, filteredItems, pager) => {
    return this.curryPipe(
      this.sortItemsFn(state.sort),
      this.pageItemsFn(pager)
    )(filteredItems);
  });

  private getItems(): OperatorFunction<any, any> {
    return (source$: Observable<any>) => source$
      .pipe(
        tap(() => {
           this.patchState({ loading: true });
        }),
        map(() => this.get((state) => ({
          branchCode: state.filterByBranch && state.branch.code || null,
          filterByUser: state.filterByUser
        }))),
        switchMap(({ branchCode, filterByUser }) => this.myDashboardService.GetOpenCartsQuotesOrders(branchCode, filterByUser)
          .pipe(
            tapResponse(
              (items) => {
                this.patchState({ loading: false, items, pageNumber: 1 });
              },
              (error) => {
                this.toastService.showToast('Error while loading open carts/quotes', ToastType.Error);
                this.patchState({ loading: false, items: [] });
              }
            )
          )
        )
      );
  }


  private toFlatString(obj: { [key: string]: string }) {
    return Object.values(obj)
      .reduce((acc, curr) => acc + curr, '');
  }

  private filterItemsFn(filterByType: 'cart' | 'quote' | null, searchTerm: string) {
    return (items: any[]) =>
      items
        .filter((item) => !filterByType || item.type === filterByType)
        .filter((item) =>
          !searchTerm
          || (item.updatedBy && item.updatedBy.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
          || (item.sapOrderNo && item.sapOrderNo.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
          || (this.toFlatString(item.contactInformation).toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
          || (item.customerNumber && item.customerNumber.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
          || (item.customerName && item.customerName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
          || (item.cartItemCount && item.cartItemCount.toString().toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
          || (item.price && ('$' + item.price).toString().toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)  // Added $ to the price for matching search on UI
          || (item.expiryDate && (item.expiryDate.substr(5, 2) + '/' + item.expiryDate.substr(8, 2) + '/' + item.expiryDate.substr(0, 4)).toString().toLowerCase()
            .indexOf(searchTerm.toLowerCase()) > -1)      // Changed the date format according to the UI
          || (item.createdDate && (item.createdDate.substr(5, 2) + '/' + item.createdDate.substr(8, 2) + '/' + item.createdDate.substr(0, 4)).toString().toLowerCase()
            .indexOf(searchTerm.toLowerCase()) > -1)       // Changed the date format according to the UI
          || (item.type && item.type.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
        );
  }

  private compareAddress(item1: any, item2: any, sortAsc: boolean) {
    const reverse = sortAsc ? 1 : -1;
    let comparison = 0;

    if (item1.contactInformation.city !== item2.contactInformation.city) {
      comparison = item1.contactInformation.city < item2.contactInformation.city ? -1 : 1;
    } else if (item1.contactInformation.state !== item2.contactInformation.state) {
      comparison = item1.contactInformation.state < item2.contactInformation.state ? -1 : 1;
    } else if (item1.contactInformation.streetAddress !== item2.contactInformation.streetAddress) {
      comparison = item1.contactInformation.streetAddress < item2.contactInformation.streetAddress ? -1 : 1;
    }

    return comparison * reverse;
  }

  private sortItemsFn(sort: { by: string, ascending: boolean } | null) {
    return (items: any[]) => {
      if (!sort) {
        return items;
      }

      if (sort.by === 'contactInformation') {
        return items.sort((a, b) => this.compareAddress(a, b, sort.ascending));
      }

      return sortData(items, sort.by, sort.ascending);
    };
  }

  private pageItemsFn(pager: Pager) {
    return (items: any[]) =>
      items
        .slice(pager.startIndex, pager.endIndex + 1);
  }

  private curryPipe<T>(...fns: ((a: T) => T)[]) {
    return (input: T) =>
      fns.reduce(
        (y, fn) => fn(y),
        input
      );
  }


}
