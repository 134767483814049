<button
  id="ConfirmCompleteReqestModal_Button_Close" type="button"
  class="close" data-dismiss="modal" aria-label="Close"
  tabindex="-1" (click)="closeModal()">
  <span aria-hidden="true">&times;</span>
</button>
<div class="modal-header">
  <h4 class="modal-title"></h4>
</div>
<div class="modal-body">
  <p style="font-size: 1.2rem">Please press "Confirm" to submit your request and copy items to the order.</p>
</div>
<div class="modal-footer">
  <button
    id="ConfirmCompleteReqestModal_Button_Cancel" type="button"
    class="btn btn-md black-text btnBg" (click)="closeModal()"
    data-dismiss="modal">
    Go back
  </button>
  <button
    id="ConfirmCompleteReqestModal_Button_Confirm" type="button"
    class="btn btn-md black-text btnBg yellow-btn" (click)="completeRequest()">
    Confirm
  </button>
</div>
