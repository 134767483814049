import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from "@angular/forms";

/** Input object must have the specified property; useful for Typeahead */
export function requiredPropertyValidator(propertyName: string, inverted: boolean = false): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
        if (!control.value) {
            return null;
        }

        const truthyProperty = control.value[propertyName];

        // truthyProperty XOR inverted
        return (truthyProperty || inverted) && !(truthyProperty && inverted) ? null : { "requiredProperty": { value: control.value } };
    };
}

@Directive({
    selector: "[requiredProperty]",
    providers: [{provide: NG_VALIDATORS, useExisting: RequiredPropertyValidatorDirective, multi: true}]
})
export class RequiredPropertyValidatorDirective implements Validator {
    @Input("requiredProperty") propertyName: string;
    
    private invertedValue: boolean;
    @Input()
    set requiredPropertyInverted(value: boolean | string) {
        this.invertedValue = value === null || value === "" || value && true;
    };
    validate(control: AbstractControl): {[key: string]: any} | null {
        return requiredPropertyValidator(this.propertyName, this.invertedValue)(control);
    }
}
