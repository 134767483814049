import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from 'services/authorization.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuard  {

  constructor(
    private authorizationService: AuthorizationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let permission = route.data['permission'];
    return this.authorizationService.hasPermissionAsync(permission);
  }
}
