import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { DiscretePage } from "entities/discrete-page";
import { OrderType } from "entities/order-type";
import { OrderDetails } from "entities/order-details";
import { OrderHistoryRequest, OrderHistory } from "entities/order-history";
import { ProcurementGroup } from "entities/procurement-group";
import { ProofOfDeliveryMetadata } from "entities/proof-of-delivery-metadata";
import { ProofOfDeliveryResponse } from "entities/proof-of-delivery-response";
import { OrderConfirmationResult } from "entities/purchase-order/order-confirmation-result";

@Injectable()
export class OrderService {
  private readonly apiBaseUrl = "api/dashboards/";
  private readonly ordersUrl: string = "api/orders/";
  constructor(private http: HttpClient) { }

  getOrderHistory(findOrdersRequest: OrderHistoryRequest): Observable<DiscretePage<OrderHistory>> {
    const url: string = `${this.apiBaseUrl}GetOrderHistory`;
    return this.http.post<DiscretePage<OrderHistory>>(url, findOrdersRequest);
  }

  getOrderDetails(orderNumber: string, orderType: OrderType): Observable<OrderDetails> {
    const url: string = `${this.apiBaseUrl}GetOrderDetails/${orderNumber}/${orderType}`;
    return this.http.get<OrderDetails>(url);
  }

  getInvoice(invoiceNumber: string): Observable<any> {
    const url: string = `${this.apiBaseUrl}GetInvoice?invoiceNumber=${invoiceNumber}`;
    return this.http.get(url)
  }

  getProcurementGroups(orderNumber: string): Observable<ProcurementGroup[]> {
    return this.http.get<ProcurementGroup[]>(`${this.ordersUrl}${orderNumber}/procurement`);
  }

  getOrderConfirmation(orderId: string): Observable<OrderConfirmationResult> {
    return this.http.get<OrderConfirmationResult>(`${this.ordersUrl}confirmation?orderId=${orderId}`);
  }

  getProofOfDeliveryLinks(orderNumber: string): Observable<ProofOfDeliveryResponse> {
    return this.http.get<ProofOfDeliveryResponse>(`${this.ordersUrl}GetProofOfDeliveryLinks/${orderNumber}`);
  }

  getProofOfDeliveryData(id: string): Observable<{ data: string }> {
    return this.http.get<{ data: string }>(`${this.ordersUrl}GetProofOfDeliveryData/${id}`);
  }
}
