import { createAction, props } from "@ngrx/store";
import { Configuration } from "entities/configuration";
import { AppState } from "store/app-state";

export const getConfiguration = createAction(
  "[Configuration] Get Configuration"
);

export const getConfigurationSuccess = createAction(
  "[Configuration] Get Configuration Success",
  props<{ configuration: Configuration }>()
);

export const getConfigurationFailed = createAction(
  "[Configuration] Get Configuration Failed",
  props<{ error: any; }>()
);

export const loadCache = createAction(
  "[Configuration] Load Cache"
);

export const loadCacheSuccess = createAction(
  "[Configuration] Load Cache Success",
  props<{ appstate: AppState }>()
);
