import { createAction, props } from '@ngrx/store';
import { FeatureFlag } from './feature-flags.state';

export const loadFeatureFlags = createAction(
  '[Feature Flags] Load Feature Flags',
  props<{ featureFlags: { [partNumber: string]: FeatureFlag}}>()
);

export const applyFeatureFlags = createAction(
  '[Feature Flags] Apply Feature Flags',
  props<{ customerNumber: string, branchCode: string}>()
);

export const getFeatureFlags = createAction(
  '[Feature Flags] Get Feature Flags'
);

export const getFeatureFlagsFailed = createAction(
  '[Feature Flags] Get Feature Flags Failed',
  props<{error: any}>()
);
