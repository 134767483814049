// https://github.com/ng-bootstrap/ng-bootstrap/issues/2169
import {
  ApplicationRef,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Injector,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';

import { DOCUMENT } from '@angular/common';
import { NgbPopover, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[stickyPopover]',
    exportAs: 'stickyPopover'
})
export class StickyPopoverDirective extends NgbPopover implements OnInit, OnDestroy {
    @Input() stickyPopover: TemplateRef<any>;

    popoverTitle: string;

    // eslint-disable-next-line max-len
    placement: 'auto' | 'top' | 'bottom' | 'left' | 'right' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | 'left-top' | 'left-bottom' | 'right-top' | 'right-bottom' | ('auto' | 'top' | 'bottom' | 'left' | 'right' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | 'left-top' | 'left-bottom' | 'right-top' | 'right-bottom')[];

    triggers: string;

    container: string;

    shown: EventEmitter<void>;

    hidden: EventEmitter<void>;
    ngpPopover: TemplateRef<any>;

    canClosePopover: boolean;

    constructor(
        private _elRef: ElementRef,
        private _render: Renderer2,
        injector: Injector,
        componentFactoryResolver: ComponentFactoryResolver,
        viewContainerRef: ViewContainerRef,
        config: NgbPopoverConfig,
        ngZone: NgZone,
        @Inject(DOCUMENT) _document: any,
        changeDetector: ChangeDetectorRef,
        applicationRef: ApplicationRef) {
        super();
        this.triggers = 'manual';
        this.container = 'body';
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.ngbPopover = this.stickyPopover;

        this._render.listen(this._elRef.nativeElement, 'mouseenter', () => {
        this.canClosePopover = true;
        this.open();
        });

        this._render.listen(this._elRef.nativeElement, 'mouseleave', (event: Event) => {
            setTimeout(() => { if (this.canClosePopover) { this.close(); } }, 100);

        });

        this._render.listen(this._elRef.nativeElement, 'click', () => {
            this.close();
        });
    }

    open() {
        super.open();
        const popover = window.document.querySelector('.popover');
        this._render.listen(popover, 'mouseover', () => {
            this.canClosePopover = false;
        });

        this._render.listen(popover, 'mouseout', () => {
            this.canClosePopover = true;
            setTimeout(() => { if (this.canClosePopover) { this.close(); } }, 0);
        });
    }

    close() {
        super.close();
    }

    toggle() {
        super.toggle();
    }

    isOpen(): boolean {
        return super.isOpen();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
