import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Discount } from 'entities/discount';
import { LoyaltyProgram, LoyaltyAccount, LoyaltyProgramAccountsSearchCriteria, LoyaltyDiscountSearchCriteria, LinkCustomerLoyaltyAccount } from 'entities/loyalty-account';
import { Part } from 'entities/part';

@Injectable({ providedIn: 'root' })
export class LoyaltyService {

  constructor(private httpClient: HttpClient) { }

  public searchLoyaltyProgramAccounts(search: LoyaltyProgramAccountsSearchCriteria): Observable<LoyaltyAccount[]> {
    return this.httpClient.post<LoyaltyAccount[]>(
      `api/loyalty/search`,
      search
    );
  }

  public getDiscounts(search: LoyaltyDiscountSearchCriteria): Observable<{ [partNumber: string]: Discount[] }> {
    return this.httpClient.post<{ [partNumber: string]: Discount[] }>(
      `api/loyalty/partDiscounts`,
      search
    );
  }

  public linkCustomerLoyaltyProgramAccount(account: LinkCustomerLoyaltyAccount): Observable<boolean> {
    return this.httpClient.post<boolean>(
      `api/loyalty/linkCustomerLoyaltyAccount`,
      account
    );
  }
}


export function getPartLoyaltyPrograms(part: Part): LoyaltyProgram[] {
  return ['peterbilt'];
}
