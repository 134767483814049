import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FeedbackService } from 'services/feedback.service';
import { CommonDataService } from 'services/common-data.service';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from 'services/loader.service';
import { ToastService } from 'services/toast.service';
import { LoggerService } from 'services/logger.service';
import { AppInsightsImageFeedback } from 'entities/app-insight-image-feedback';
import { Location } from '@angular/common';
import { PartDetailsComponent } from '../part-details/part-details.component';
import { ToastType } from 'entities/toast-type';

@Component({
  selector: 'image-feedback',
  templateUrl: './image-feedback.component.html'
})
export class ImageFeedbackComponent {
  @Input() image: string;
  @Output() close: EventEmitter<{}> = new EventEmitter();
  public feedbackType: string;
  public additionalDetails: string;

  constructor(
    private feedbackService: FeedbackService,
    private commonDataService: CommonDataService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private loader: LoaderService,
    private toastService: ToastService,
    private detailsComponent: PartDetailsComponent,
    private loggerService: LoggerService) { }

  submitFeedback() {
    const newFormData = new FormData();
    newFormData.append('customerNumber', this.commonDataService.Customer.customerNumber);
    newFormData.append('branchCode', this.commonDataService.Branch.code);
    newFormData.append('partSearchTerm', this.activatedRoute.snapshot.queryParams['searchTerm']);
    newFormData.append('previousPageUrl', this.location.path());
    newFormData.append('feedbackType', this.feedbackType);

    newFormData.append('additionalDetails', this.additionalDetails);
    this.loader.loading = true;



    this.feedbackService.sendImageFeedback(newFormData)
      .then((f: any) => {
        if (f.ErrorType != undefined && f.ErrorType != null && f.ErrorType != 200) {
          this.loader.loading = false;
          this.toastService.errorMessage('ImageFeedbackComponent', 'submitFeedback', 'sendImageFeedback', f);
        } else {
          this.toastService.showToast(`Feedback sent successfully with reference number ${f.id}.`, ToastType.Success);
          this.loader.loading = false;
          this.detailsComponent.isImageFeedbackActive = false;
        }
        this.loggerService.verbose('feedback Data : ', f);
      },
        () => { });
    let sourceName = 'ImageFeedbackComponentData';
    let metricName = this.loggerService.getMetricValue(sourceName);
    let ImageFeedbackData = Object.assign(new AppInsightsImageFeedback(), {
      userId: this.commonDataService.User.id,
      customerNumber: this.commonDataService.DefaultCustomer.customerNumber,
      branchCode: this.commonDataService.Branch.code,
      partSearchTerm: this.activatedRoute.snapshot.queryParams['searchTerm'],
      feedbackType: this.feedbackType,
      additionalDetails: this.additionalDetails
    });
    this.loggerService.trackMetric(metricName, ImageFeedbackData);
  }
}
