export class AppInsightCustomerSearch {
    userId: string = "";
    branchNumber: string = "";
    searchTerm: string = "";
    //clickingFavorites: boolean = false;
    //clickingAdvancedSearch: boolean = false;
    source: string = "";
    customerNotFound: boolean = false;
    result: any = null;
    plMetricName: string = "";
    customerSearchTerm: any = null;
}
