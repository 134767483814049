<div class="row">
  <div class="col-12 col-md-9 py-3 bg-white">
    <div class="title-of-box-hold">
      <h5 class="strong">Parts Search</h5>
    </div>
    <div class="p-3">
      <div class="row">
        <div id="home_div_Customer" class="col-12 col-lg-7">
          <customer-select [ngModel]="customer$ | async" (ngModelChange)="onCustomerSelect($event)">
            <!-- This button is always disabled, but the resulting visual experience for the user is that the button becomes "enabled" when a non-default customer is selected -->
            <button id="customerPanel_button_PriceExceptionRequest" *ngIf="commonDataService.User?.isInternal && authorizationService.hasPermission(permission.CreatePriceException)" type="button" class="btn btn-outline-primary" disabled>
              Price Exception Request
            </button>
          </customer-select>

          <div class="form-group d-flex justify-content-between align-items-start">
            <ng-container *ngIf="loyaltyEnabled$ | async">
              <loyalty-accounts *ngIf="hasLoyaltyProgram$ | async" [ngModel]="loyaltyAccounts$ | async" (ngModelChange)="onLoyaltyAccountsChange($event)"
                [branch]="branch$ | async" [customer]="customer$ | async" [defaultCustomer]="commonDataService.DefaultCustomer">
              </loyalty-accounts>
            </ng-container>
            <a id="home_a_PriceExceptionRequest" class="btn btn-primary" routerLink="/price-exception/customer" *ngIf="!(isDefaultCustomer$ | async) && commonDataService.User?.isInternal && authorizationService.hasPermission(permission.CreatePriceException)">Price Exception Request</a>
          </div>
        </div>
        <div class="col-12 col-lg-5">
          <h5 class="strong">Branch</h5>
          <branch id="home_branch_UserShowBranch" *ngIf="commonDataService.User" [ngModel]="branch$ | async" (ngModelChange)="onBranchSelect($event)"></branch>
        </div>
        <div class="col-12 col-lg-7">
          <parts-search id="home_parts-search_PartsSearch"></parts-search>
        </div>
      </div>
    </div>
  </div>
  <div id="home_div_HasPermission" class="col-12 col-md-3 py-3" *ngIf="isInternal$ | async">
    <open-cart id="home_open-cart_OpenCart"></open-cart>
  </div>
  <featured-parts-home id="home_featured-parts-home_FeaturedPartsHome" *ngIf="featuredProductsEnabled$ | async" class="w-100"></featured-parts-home>
</div>
