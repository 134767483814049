import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, interval, of, EMPTY } from 'rxjs';
import { catchError, filter, first, map, merge, shareReplay, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { BaseService } from 'services/base.service';
import { ConfigurationService } from 'services/configuration.service';
import { ToastService } from 'services/toast.service';
import { NOTIFICATION_TIME_INTERVAL_IN_MIN } from 'app/app.constants';
import { NotificationResult } from 'entities/notification-result';
import { NotificationModel } from "entities/notification-model";
import { UserSalesMetrics } from 'entities/user-sales-metrics';
import { ErrorInformation } from 'entities/error-information';

@Injectable({
  providedIn: "root"
})
export class UserNotificationService extends BaseService {
  private updateNotificationsSubject: Subject<{}> = new Subject();
  public userNotifications$: Observable<NotificationModel[]>;
  public unreadUserNotifications$: Observable<any[]>
  public loadingNotifications: boolean;
  private salesMetricsSubject: Subject<string> = new Subject();
  public salesMetrics$: Observable<any>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastService: ToastService,
    private configurationService: ConfigurationService,
    @Inject(NOTIFICATION_TIME_INTERVAL_IN_MIN) public Notification_Time_Interval_In_Min: number
  ) {
    super();

    const routeChangeObs = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      );
    const notificationInveralMs = Notification_Time_Interval_In_Min * 60 * 1000;
    this.userNotifications$ = this.configurationService.user$
      .pipe(
        filter((user) => user && true),
        merge(this.updateNotificationsSubject, routeChangeObs),
        switchMap(() => interval(notificationInveralMs).pipe(startWith(null))),
        tap(() => {
          this.loadingNotifications = true;
        }),
        switchMap(() => {
          return this.http.get<NotificationResult[]>('api/Notifications/GetNotifications')
            .pipe(
              tap((response: any) => {
                if (response.ErrorType && response.ErrorType != 200) {
                  throw response;
                }
              }),
              catchError((error) => {
                this.toastService.errorMessage('UserNotificationService', 'userNotifications$', 'userNotifications$', error);
                return of([]);
              })
            );
        }),
        // Mapping to easier schema; maybe update API to return in this format instead
        map((notifications: NotificationResult[]) => {
          return notifications && notifications.map((notification: NotificationResult) => {
            return {
              ... notification.notificationUser,
              notification: {
                ... notification.notification,
                notificationType: {
                  ...notification.notificationType
                }
              }
            } as NotificationModel;
          });
        }),
        tap(() => {
          this.loadingNotifications = false;
        }),
        shareReplay(1)
      );

    this.unreadUserNotifications$ = this.userNotifications$
      .pipe(
        map((userNotifications) => userNotifications && userNotifications.filter(un => !un.isRead))
      );

    this.salesMetrics$ = this.userNotifications$
      .pipe(
        map((userNotifications) => userNotifications.filter(un => un.notification.notificationTypeId == 1)),
        filter((salesNotifications) => salesNotifications.length && true),
        map((salesNotifications) => salesNotifications[0].notificationId),
        first(),
        merge(this.salesMetricsSubject),
        switchMap((salesNotificationId) => {
          const userSalesMetricsUrl = `api/Notifications/GetUserSalesMetrics?notificationId=${salesNotificationId}`;
          return this.http.get<UserSalesMetrics>(userSalesMetricsUrl)
            .pipe(
              tap((response: any) => {
                if (response.ErrorType && response.ErrorType != 200) {
                  throw response;
                }
              }),
              catchError((error) => {
                this.toastService.errorMessage('UserNotificationService', 'salesMetrics$', 'salesMetrics$', error);
                return EMPTY;
              })
            );
        })
      );
  }

  updateNotifications(): void {
    this.updateNotificationsSubject.next({});
  }

  showSalesMetrics(notificationId: string): void {
    this.salesMetricsSubject.next(notificationId);
  }

  markNotification(notificationId: string): Observable<any> {
    let url: string = `api/Notifications/MarkNotification?notificationId=${notificationId}`;
    return this.http.get(url)
      .pipe(
        tap((response) => {
          if (response.ErrorType && response.ErrorType != 200) {
            throw response;
          }
        })
      );
  }

  markAllNotifications(): Observable<any> {
    let url: string = `api/Notifications/MarkAllNotification`;
    return this.http.get(url)
      .pipe(
        tap((response) => {
          if (response.ErrorType && response.ErrorType != 200) {
            throw response;
          }
        })
      )
  }
}
