<ng-container *ngIf="authorizationService.hasPermission(permission.ReadVendors)">
<div *ngLet="{ branch: branch$ | async , countryCode: countryCode$ | async } as vm">
  <ul id="vendorPanel_tab_ReadVendors"
    ngbNav #nav="ngbNav" class="nav-links" (navChange)="onNavChange($event)">
    <li ngbNavItem="vendorNumber">
      <a ngbNavLink id="vendorPanel_tab_Search">Vendor Number</a>
      <ng-template ngbNavContent>
        <div class="form-group">
          <label for="vendorPanel_text_VendorNumber">Vendor number</label>
          <span class="badge bg-danger small">Required</span>
          <input id="vendorPanel_text_VendorNumber" type="text" class="form-control" name="vendorPanel_text_VendorNumber" [(ngModel)]="vendorNumber"
            maxlength="10" (keypress)="onVendorKeypress($event, vm.branch?.code)" (keydown)="onVendorKeydown($event, vm.branch?.code)" />
        </div>
        <div class="form-group">
          <button id="vendorPanel_button_SearchVendor" type="button" class="btn btn-primary" (click)="vendorSearch(vm.branch?.code)" [disabled]="!vendorNumber">Search
            Vendor</button>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="advancedVendorSearch">
      <a ngbNavLink>Advanced Vendor Search</a>
      <ng-template ngbNavContent>
        <form (ngSumbit)="submit($event)" *ngLet="{ countryCode: countryCode$ | async } as vm">
        <div>Provide any of the following information to find vendors</div>
        <br>
        <div class="form-group">
          <label for="vendorPanel_text_AdvancedVendorName">Vendor Name</label>
          <input id="vendorPanel_text_AdvancedVendorName" class="form-control" name="vendorPanel_text_AdvancedVendorName" type="text" [(ngModel)]="advVendorName"
            (keypress)="onVendorNameKeypress($event, vm.branch?.code)">
        </div>
        <div class="row">
          <div class="form-group col-md-6">
            <label for="advVendorCity">City</label>
            <input id="vendorPanel_text_AdvancedVendorCity" class="form-control" name="vendorPanel_text_AdvancedVendorCity" type="text" [(ngModel)]="advVendorCity"
              (keypress)="onCityKeypress($event, vm.branch?.code)">
          </div>
          <div class="form-group col-md-6">
            <label for="vendorPanel_select_AdvancedVendorState">{{vm.countryCode === 'US' ? 'State': 'Province'}}</label>
            <select id="vendorPanel_select_AdvancedVendorState" *ngIf="vm.countryCode === 'US'; else canadaProvinces" class="form-control" name="vendorPanel_select_AdvancedVendorState" [(ngModel)]="advVendorState"
              (keypress)="onStateKeypress($event, vm.branch?.code)">
              <option value="">State</option>
              <option *ngFor="let abv of USStatesAbrreviations" [value]="abv">{{USStatesEnum[abv]}}</option>
            </select>
            <ng-template #canadaProvinces>
              <select id="customerPanel_select_AdvancedCustomerState" class="form-control" name="customerPanel_select_AdvancedCustomerState" [(ngModel)]="advVendorState"
                (keypress)="onStateKeypress($event, vm.branch?.code)">
                <option value="">Province</option>
                <option *ngFor="let abv of CanadianProvincesAbrreviations" [value]="abv">{{CanadianProvincesEnum[abv]}}</option>
              </select>
            </ng-template>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6" >
            <label for="vendorPanel_text_AdvancedVendorPostalCode">Postal Code</label>
            <input id="vendorPanel_text_AdvancedVendorPostalCode" class="form-control" name="vendorPanel_select_AdvancedVendorPostalCode" type="text"
              [(ngModel)]="advVendorPostalCode" (keypress)="onPostalCodeKeypress($event, vm.branch?.code)"
              (input)="postalCodeChanged($event, vm.countryCode)">
              <span class="text-danger col" id="errorMessage" *ngIf="postalCodeInvalid">Invalid Postal Code</span>
          </div>
          <div class="form-group col-md-6">
            <label for="advVendorPhoneNumber">Phone Number</label>
            <input id="vendorPanel_text_AdvancedVendorPhoneNumber" class="form-control" name="vendorPanel_text_AdvancedVendorPhoneNumber" type="text"
              [(ngModel)]="advVendorPhoneNumber" (keypress)="onPhoneNoKeypress($event, vm.branch?.code)">
          </div>
        </div>
        <div class="form-group" *ngLet="(branch$ | async).code as branchCode">
          <button id="vendorPanel_button_AdvancedVendorSearch" class="btn btn-primary" type="submit" id="btnAdvanceCustomerSearch"
            [disabled]="postalCodeInvalid || (!advVendorName && !advVendorCity && !advVendorState && !advVendorPostalCode && !advVendorPhoneNumber)"
            (click)="vendorSearch(branchCode)">Search Vendor</button>
        </div>
        </form>
      </ng-template>
    </li>
    <li ngbNavItem="favoriteVendors">
      <a ngbNavLink>View Favorite Vendors</a>
      <ng-template ngbTabContent></ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
</ng-container>
<div class="row">
  <span id="vendorPanel_span_ErrorMessage" class="text-danger" id="errorMessage" *ngIf="hasMessage">{{vendorMesssage}}</span>
</div>
