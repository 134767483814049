<div class="p-3 text-truncate"><b>{{part.quantityAvailable}} Available</b> - {{(branch$ | async)?.code + ' - ' + part.branchName}}</div>
<div class="row g-0" style="width: 17rem; margin: auto">
  <a id="partCardItem_a_ProductImage{{idSuffix}}" class="col-6 product-image product-image-sm" [routerLink]="['/parts']" [queryParams]="{searchTerm:part.rushPartNumber,partId:part.partId}"
    tabindex="-1">
    <img [src]="getImage(part.image, imageBaseUrl, '100X100')" [default]="'assets/img/100X100_No_Image.png'" tabindex="-1" alt="No Image" >
  </a>
  <div class="col-6 d-flex flex-wrap align-items-center">
    <pricing [part]="part" (partChange)="onPartChange($event);" [priceAdjust]="allowPriceAdjust" [branch]="branch$ | async" (extend)="onExtendPart($event)"></pricing>
  </div>
</div>
<div id="partCardItem_div_CorePrice{{idSuffix}}" *ngIf="part.corePrice" class="p-3">
  <select id="partCardItem_select_CoreOption{{idSuffix}}" class="form-control"
    [(ngModel)]="part.coreOption" [disabled]="!(canReturnCore$ | async)">
    <option id="partCartItem_option_DetailNoCoreReturned{{idSuffix}}" selected value="NOCORER">No Core Returned</option>
    <option id="partCartItem_option_DetailCoreReturned{{idSuffix}}" value="CORE1">Core Returned</option>
  </select>
</div>
