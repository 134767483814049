import { ErrorHandler, inject, Injectable } from "@angular/core";
import { LoaderService } from "services/loader.service";
import { LoggerService } from "services/logger.service";

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  loggerService = inject(LoggerService);
  loader = inject(LoaderService);

  handleError(error: Error): void {
    this.loggerService.trackException(error);
    const message = error?.stack !== undefined ? error.stack : error;
    this.loggerService.critical("Uncaught Exception", message);
    this.loader.loading = false;
  }
}
