<h5 class="strong">Customer</h5>
<ng-container *ngLet="{
  customer: selectedCustomer$ | async,
  isInternal: isInternal$ | async,
  isDefaultCustomer: isDefaultCustomer$ | async,
  isSelectedCustomerFavorite: isSelectedCustomerFavorite$ | async,
  shipToAddress: shipToAddress$ | async
} as vm">
  <div *ngIf="!vm.customer">Loading customer data...</div>
  <div class="form-group row g-0" *ngIf="!vm.isDefaultCustomer || !vm.isInternal; else searchCustomer">
    <div class="col-12 col-md-6" *ngIf="vm.customer">
      <div class="strong" id="customer_select_div_CustomerName">{{vm.customer.customerName}}</div>
      <div id="customer_select_div_CustomerName2">{{vm.customer.customerName2}}</div>
      <div id="customer_select_div_CustomerNumber">{{vm.customer.customerNumber}}</div>
      <div id="customer_select_div_CustomerStreetAddress">{{vm.customer.streetAddress}}</div>
      <div id="customer_select_div_CustomerCity"> {{vm.customer.city}}, {{vm.customer.state}}, {{vm.customer.postalCode}} </div>
      <div id="customer_select_div_CustomerPhoneNumber">{{vm.customer.phoneNumber}}</div>
      <div *ngIf="vm.shipToAddress" id="customer_select_div_ShipToAddress">Ship-To Number: {{ vm.shipToAddress?.addressNumber | trimLeadingZeros }}</div>
    </div>
    <div class="col-12 col-md-6 gy-3" id="customer_select_div_UserIsInternalCustomer" *ngIf="vm.customer && vm.isInternal">
      <div>
        <a *ngIf="vm.isSelectedCustomerFavorite; else notFavoriteCustomer" class="hyperlink" href="javascript:void(0)" (click)="removeFavorites(vm.customer)" id="home_a_RemoveFavorite" role="button">
          Remove '{{vm.customer.customerName}}' from Favorites
        </a>
        <ng-template #notFavoriteCustomer>
          <a class="hyperlink" href="javascript:void(0)" (click)="addToFavorites(vm.customer)" id="customer_select_a_AddFavorite" role="button">
            Add '{{vm.customer.customerName}}' to Favorites
          </a>
        </ng-template>
      </div>
    <div>
      <a class="hyperlink" href="javascript:void(0)" (click)="onDifferentCustomerClick()" id="customer_select_a_UseDifferentCustomer" role="button">Use a different customer</a>
    </div>
    <ng-container *ngIf="isCustomerNotesEnabled && vm.isInternal && !vm.isDefaultCustomer">
      <div id="customer_select_div_CustomerNotesFeatureEnabled">
        <a id="customer_select_a_CustomerNotes" class="hyperlink" href="javascript:void(0)" (click)="showCustomerNotes(vm.customer.customerNumber)">Customer Notes</a>
      </div>
      <a class="hyperlink" href="javascript:void(0)"
        (click)="onChangeShipToAddress()" id="customer_select_shipToAddress" role="button">
        {{ vm.shipToAddress ? 'Change' : 'Select' }} Ship-To Address
      </a>
    </ng-container>
    </div>
  </div>
  <ng-template #searchCustomer>
    <h6 class="strong my-1">Default Cash Customer</h6>
    <customer-panel (customerSelect)="onCustomerSelect($event)">
      <ng-content></ng-content>
    </customer-panel>
  </ng-template>
</ng-container>
