import { Directive } from '@angular/core';
import { AbstractControl, FormGroup, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { CheckoutValidationMessages } from 'app/_components/checkout-validation/checkout-validation.messages';
import { CheckoutFormGroup, PaymentDetailsFormGroup } from 'app/_components/checkout/checkout.component';

export const EMAIL_REGEXP = /^[A-z0-9._%+-]+@[A-z0-9.-]*(\.[A-z]{2,4})+$/;

export const isValidEmail = (c: AbstractControl) => {
  const parent = c.root as FormGroup<CheckoutFormGroup>;
  const isInternal = parent?.value?.shippingAndDelivery?.cartData?.isUserInternal;
  const deliveryType = parent?.value?.shippingAndDelivery?.deliveryType;
  if (deliveryType === "shipto" && c.value === '' && isInternal) {
    return { required: CheckoutValidationMessages['altEmail.required'] };
  }
  if (c.value === '') {
      return null;
  }
  const valid = c.value && EMAIL_REGEXP.test(c.value);
  return valid ? null : { email: { valid: false } };
};

export function i2pEmailValidator(control: AbstractControl): ValidationErrors | null {
  const i2pEmail = control.value;
  const parent = control.parent as FormGroup<PaymentDetailsFormGroup>;
  if((parent?.value?.i2p === null || parent?.value?.i2p === '' || parent?.value?.i2p === undefined) && (i2pEmail?.value === null || i2pEmail?.value === '' || i2pEmail?.value === undefined)){
    return null;
  }
  const valid = i2pEmail && EMAIL_REGEXP.test(i2pEmail);
  if(valid || i2pEmail === null || i2pEmail === ''){
    return null;
  } else {
    return { email: { valid: false } };
  }
}

@Directive({
    selector: '[validateEmail]',
    providers: [{ provide: NG_VALIDATORS, useExisting: EmailValidator, multi: true }]
})
export class EmailValidator implements Validator {
    validate(c: AbstractControl): ValidationErrors | null {
        return isValidEmail(c);
    }
}
