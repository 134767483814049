import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Store } from "@ngrx/store";
import { filter, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { CartService } from "services/cart.service";
import { AppState } from "store/app-state";
import * as BranchSelectors from "app/_store/branch/branch.selectors";
import * as CustomerSelectors from 'store/customer/customer.selectors';
import { Observable, of } from "rxjs";
import { DeleteCartConfirmModalComponent } from 'modals/delete-cart-confirm-modal/delete-cart-confirm-modal.component';
import { Cart } from "entities/carts/cart";
import { OpenCartsState } from "./open-carts.state";
import { ModalService } from "services/modal.service";
import { CartResult } from "entities/cart-result";
import * as CartAction from "store/cart/cart.actions";

export interface OpenCartFilters {
  byBranch: boolean;
  byCustomer: boolean;
}
@Injectable()
export class OpenComponentStore extends ComponentStore<OpenCartsState> {
  constructor(
    private cartService: CartService,
    private store: Store<AppState>,
    private modalObservable: ModalService,
  ) {
    super({
      openCarts: [],
      filteredOpenCarts: [],
      filterByCustomer: null,
      filterByBranch: null,
      loading: false,
    });
  }

  // selectors
  public readonly openCarts$ = this.select(state => state.openCarts);
  public readonly filteredCarts$ = this.select(state => state.filteredOpenCarts);
  public readonly loading$ = this.select(state => state.loading);
  public readonly filter$ = this.select(state => state.filterByBranch ? state.filterByBranch : state.filterByCustomer );
  public filters$: Observable<{ byCustomer: boolean, byBranch: boolean}> = new Observable<{ byCustomer: boolean, byBranch: boolean}>();

  // effects
  public readonly createCart = this.effect((opencart$: Observable<void>) =>
      opencart$.pipe(
        withLatestFrom(
          this.store.select(BranchSelectors.selectedBranch),
          this.store.select(CustomerSelectors.selectedCustomer)),
          switchMap(([_, branch, customer]) => {
            this.patchState({ loading: true });
              return this.cartService.createOpenedCart({
                  branchCode: branch.code,
                  customerNumber: customer.customerNumber,
                  forceCreate: true
                }).pipe(
                    tap((openCart: Cart) => {
                      this.patchState({
                        openCarts: [ openCart, ...this.get().openCarts],
                        filteredOpenCarts: [ openCart, ...this.get().filteredOpenCarts],
                        loading: false
                      });
                    })
                )
          })
      )
  )

  public readonly retrieveOpenCarts = this.effect((opencart$: Observable<void>) =>
    opencart$.pipe(
      tap(() => {
        this.patchState({ loading: true });
      }),
      switchMap(() => this.cartService.getOpenCarts()),
      tap((openCarts: Cart[]) => {
        this.patchState({ openCarts: openCarts, loading: false, filteredOpenCarts: openCarts });
      })
    )
  )

  public readonly deleteCart = this.effect((cartId$: Observable<string>) =>
      cartId$.pipe(
        switchMap((cartId) =>
          this.modalObservable.open(DeleteCartConfirmModalComponent, {}, { size: 'sm'}).pipe (
            switchMap( isDelete => {
                if(isDelete){
                  return this.cartService.deleteCart(cartId).pipe(
                    filter(x => x != null),
                    tap( (cartId) => {
                      const cachedCart: CartResult = JSON.parse(localStorage.getItem('cartData'));
                      if (cachedCart?.cartId === cartId)  {
                        localStorage.removeItem('cartData');
                      }
                      this.patchState({
                        openCarts: this.get().openCarts.filter(d => d.cartId != cartId ),
                        filteredOpenCarts: this.get().openCarts.filter(d => d.cartId != cartId )
                      })
                      this.store.dispatch(CartAction.deleteCartSuccess({ cartId }));
                    })
                  )
                } else {
                  return of(null);
                }
              }
            )
        ),
      )
    )
  )

  public readonly filterByBranchOrCustomer = this.effect((filter$: Observable<OpenCartFilters>) =>
    filter$.pipe(
      withLatestFrom(
        this.store.select(CustomerSelectors.selectedCustomer),
        this.store.select(BranchSelectors.selectedBranch)
      ),
      tap(([filters, customer, branch]) => {
        const filteredOpenCarts = this.get().openCarts.filter(x => {
          const isFilteredByCustomer = filters.byCustomer ? customer.customerNumber === x.customerNumber : true;
          const isFilteredByBranch = filters.byBranch ? branch.code === x.branchCode : true;
          return isFilteredByBranch && isFilteredByCustomer;
        });
        this.patchState({
          filteredOpenCarts
        });
      }))
  );
}
