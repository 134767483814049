import { Component, Output, EventEmitter, Input, Inject } from '@angular/core';
import { NgbModal, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { VendorsModalComponent } from 'modals/vendors-modal/vendors-modal.component';
import { Permission } from 'entities/enums';
import { LoaderService } from 'services/loader.service';
import { ToastService } from 'services/toast.service';
import { VendorService } from 'services/vendor.service';
import { AuthorizationService } from 'services/authorization.service';
import { FavouriteVendorsModalComponent } from 'modals/favourite-vendors-modal/favourite-vendors-modal.component';
import { LoggerService } from "services/logger.service";
import { CommonDataService } from 'services/common-data.service';
import { Store } from '@ngrx/store';
import { AppState } from 'store/app-state';
import { Observable } from 'rxjs';
import { Branch } from 'entities/branch';
import * as BranchSelectors from 'store/branch/branch.selectors';
import { REGEX_CANADA_POSTAL_PATTERN, REGEX_USA_POSTAL_PATTERN } from 'app/app.constants';
import { USStateOptions, CanadianProvinceOptions } from "entities/enums";
import { map } from "rxjs/operators";
import { GetVendorResponseItem } from 'entities/get-vendor';

@Component({
  selector: 'vendor-panel',
  templateUrl: './vendor-panel.component.html'
})
export class VendorPanelComponent {
  @Input() index: number;
  randomNumber: number = new Date().getTime();
  vendorNumber: string = '';
  advVendorName: string = '';
  advVendorCity: string = '';
  advVendorState: string = '';
  advVendorPostalCode: string = '';
  advVendorPhoneNumber: string = '';
  hasMessage: boolean = false;
  vendorMesssage: string = '';
  noRecordFlag: boolean = false;
  permission: any = Permission;
  public postalCodeInvalid: boolean = false;
  @Output() vendorSelect: EventEmitter<GetVendorResponseItem> = new EventEmitter();
  countryCode$: Observable<string> = this.store.select(BranchSelectors.selectedBranchCountryCode);
  branchCode = '';
  branch$: Observable<Branch> = this.store.select(BranchSelectors.selectedBranch).pipe(map((selectedBranch) => {
    this.branchCode = selectedBranch.code;
    return selectedBranch;
  }));
  USStatesEnum = USStateOptions;
  USStatesAbrreviations = Object.keys(USStateOptions);
  CanadianProvincesEnum = CanadianProvinceOptions;
  CanadianProvincesAbrreviations = Object.keys(CanadianProvinceOptions);

  constructor(
    public authorizationService: AuthorizationService,
    private loader: LoaderService,
    private toastService: ToastService,
    private vendorsService: VendorService,
    private modalService: NgbModal,
    private loggerService: LoggerService,
    public commonDataService: CommonDataService,
    private store: Store<AppState>,
    @Inject(REGEX_CANADA_POSTAL_PATTERN) public regexCanadaPostalPattern: RegExp,
    @Inject(REGEX_USA_POSTAL_PATTERN) public regexUSAPostalPattern: RegExp
  ) {}

  onNavChange(event: NgbNavChangeEvent) {
    if (event.nextId === 'favoriteVendors') {
        this.viewFavorites();
        event.preventDefault();
    } else {
      this.vendorNumber = '';
      this.vendorNumber = '';
      this.advVendorName = '';
      this.advVendorCity = '';
      this.advVendorState = '';
      this.advVendorPostalCode = '';
      this.advVendorPhoneNumber = '';
      this.hasMessage = false;
      this.vendorMesssage = '';
      this.noRecordFlag = false;
      this.permission = Permission;
    }
  }

  viewVendorsModal(vendors: GetVendorResponseItem[]) {
    let modalRef = this.modalService.open(VendorsModalComponent, { size: 'lg' });
    modalRef.componentInstance.vendors = vendors;

    modalRef.result
      .then((vendor: GetVendorResponseItem) => {
        this.vendorSelect.emit(vendor);
      })
      .catch(() => { });
  }

  viewFavorites() {
    let modalRef = this.modalService.open(FavouriteVendorsModalComponent, { size: 'lg' });
    modalRef.componentInstance.branchCode = this.branchCode;

    modalRef.result
      .then((vendor: GetVendorResponseItem) => {
        this.vendorSelect.emit(vendor);
      })
      .catch(() => { });
  }

  vendorSearch(branchCode: string) {
    if (this.vendorNumber || this.advVendorName || this.advVendorCity || this.advVendorState || this.advVendorPostalCode || this.advVendorPhoneNumber || branchCode) {
      this.hasMessage = false;
      this.vendorMesssage = '';
      this.loader.loading = true;
      this.loggerService.verbose('Vendor component vendor number: ', this.vendorNumber);
      this.vendorsService.getVendors(this.vendorNumber, this.advVendorName, '', '', this.advVendorCity, this.advVendorState, this.advVendorPostalCode, this.advVendorPhoneNumber, branchCode)
        .then(c => {
          if (c.ErrorType && c.ErrorType != 200) {
            this.toastService.errorMessage('VendorComponent', 'vendorSearch', 'getVendors', c);
          } else {
            if (!c.length) {
              this.hasMessage = true;
              this.vendorMesssage = 'No vendor found';
            } else {
              this.hasMessage = false;
              this.vendorMesssage = '';
              if (c.length === 1) {
                this.vendorSelect.emit(c[0]);
              } else {
                this.viewVendorsModal(c);
              }
            }
          }
          this.loader.loading = false;
          this.loggerService.verbose('Vendor component : ', c);
        },
          () => {
            this.loader.loading = false;
            this.hasMessage = false;
            this.vendorMesssage = '';
          });
    }
  }

  postalCodeChanged(event: Event, countryCode: string) {
    const value = (event.target as HTMLInputElement).value;
    const valid = countryCode === 'US' ? this.regexUSAPostalPattern.test(value) : this.regexCanadaPostalPattern.test(value);
    this.postalCodeInvalid = !valid && value !== "";
}

  onVendorKeypress(event: KeyboardEvent, branchCode: string) {
    if (event.keyCode == 13) {
      this.vendorSearch(branchCode);
    }
    let regex = new RegExp('^[a-zA-Z0-9*]+$');
    let key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }

  onVendorNameKeypress(event: KeyboardEvent, branchCode: string) {
    if (event.keyCode == 13) {
      this.vendorSearch(branchCode);
    }
  }

  onPhoneNoKeypress(event: KeyboardEvent, branchCode: string) {
    if (event.keyCode == 13) {
      this.vendorSearch(branchCode);
    }
  }

  onPostalCodeKeypress(event: KeyboardEvent, branchCode: string) {
    if (event.keyCode == 13) {
      this.vendorSearch(branchCode);
    }
  }

  onCityKeypress(event: KeyboardEvent, branchCode: string) {
    if (event.keyCode == 13) {
      this.vendorSearch(branchCode);
    }
  }

  onStateKeypress(event: KeyboardEvent, branchCode: string) {
    if (event.keyCode == 13) {
      this.vendorSearch(branchCode);
    }
  }

  onVendorKeydown(event: KeyboardEvent, branchCode: string) {
    if (event.keyCode == 9) {
      this.vendorSearch(branchCode);
    }
  }

  submit(e: Event){
    e.preventDefault();
  }


}
