import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bct-price-verify-modal',
  standalone: true,
  imports: [],
  templateUrl: './bct-price-verify-modal.component.html',
  styleUrl: './bct-price-verify-modal.component.scss'
})
export class BctPriceVerifyModalComponent {

  @Input() requestId: string = null;
  @Input() mscCardNumber: string = null;

  constructor(private activeModal: NgbActiveModal){}

  closeModal(mscCardNumber: string){
    if(mscCardNumber !== null){
      console.log("Inside Modal:", mscCardNumber);
      this.activeModal.close(mscCardNumber);
    } else {
      this.activeModal.dismiss();
    }
  }
}
