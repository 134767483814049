import { Component, OnInit, Input } from '@angular/core';
import { OrderHistory } from 'entities/order-history';

@Component({
  selector: 'order-customer',
  templateUrl: './order-customer.component.html',
  styles: []
})
export class OrderCustomerComponent implements OnInit {
  @Input() orderInfo: OrderHistory;

  constructor() { }

  ngOnInit() {
  }

}
