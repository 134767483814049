import { createAction, props } from '@ngrx/store';
import { VerifiedPrice } from 'entities/parts/verified-price';

export const upsertVerifiedPrice = createAction(
  '[Verified Prices] Upsert',
  props<{ verifiedPrice: VerifiedPrice }>()
);

export const clearVerifiedPrices = createAction(
  '[Verified Prices] Clear'
);

export type VerifiedPricesActions =
  | typeof upsertVerifiedPrice
  | typeof clearVerifiedPrices;
