<div>
  <loyalty-accounts-display></loyalty-accounts-display>
  <button id="loyaltyAccounts_button_selectloyalty" type="button" class="btn btn-primary position-relative" (click)="selectLoyaltyAccounts()" *ngIf="loyaltyEnabled$ | async" [disabled]="disabled$ | async">
    Select Loyalty Account
    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{numberOfLoyaltyAccounts$ | async}}</span>
  </button>
  <ng-container *ngIf="!(isInternal$ | async)">
    <i class="fas fa-info-circle" style="padding: 10px; right: 9px;" [ngbTooltip]="ExternalLoyaltyTipContent" [openDelay]="300" [closeDelay]="300" placement="right"></i>
    <ng-template #ExternalLoyaltyTipContent>
      <div class="p-2"><b>Click this button to add your existing account number to this order.</b></div>
    </ng-template>
  </ng-container>
</div>
