import { Component, OnInit, Inject, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import { Store } from '@ngrx/store';
import { APPLICATION_NAME } from 'app/app.constants';
import { OpenItemType } from 'entities/enums';
import { LoaderService } from 'services/loader.service';
import { ToastService } from 'services/toast.service';
import { CommonDataService } from 'services/common-data.service';
import { MyDashboardService } from 'services/my-dashboard.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LoggerService } from 'services/logger.service';
import { Branch } from 'entities/branch';
import { ToastType } from 'entities/toast-type';
import { AppState } from 'store/app-state';
import * as MyDashboardTabComponentActions from './my-dashboard-tab.component.actions';
import { MyDashboardTabComponentStore } from './my-dashboard-tab.component.store';
import * as MyDashboardActions from 'store/my-dashboard/my-dashboard.actions';
import * as CartActions from 'store/cart/cart.actions';
import { Observable } from 'rxjs';
import * as CartSelectors from 'store/cart/cart.selectors';
import * as BranchSelectors from "store/branch/branch.selectors";
import { AuthenticationService } from '../../_modules/authentication/authentication.service';
import { map } from 'rxjs/operators';

import { Pager } from 'entities/pager';
import { CurrencyCode } from 'entities/currency-code';

@Component({
  selector: 'my-dashboard-tab',
  templateUrl: './my-dashboard-tab.component.html',
  styleUrls: ['./my-dashboard-tab.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MyDashboardTabComponentStore]
})
export class MyDashboardTabComponent implements OnInit, OnDestroy {
  public loading$: Observable<boolean> = this.componentStore.loading$;
  public filterByUser$: Observable<boolean> = this.componentStore.filterByUser$;
  public filterByBranch$: Observable<boolean> = this.componentStore.filterByBranch$;
  public branch$: Observable<Branch> = this.componentStore.branch$;
  public items$ = this.componentStore.items$;
  public sort$ = this.componentStore.sort$;
  public pager$: Observable<Pager> = this.componentStore.pager$;
  public hasUnsavedChanges$: Observable<boolean> = this.store.select(CartSelectors.hasUnsavedChanges);
  public currencyCode$: Observable<CurrencyCode> = this.store.select(BranchSelectors.selectCurrencyCode);
  public userProfile$: Observable<string> = this.authenticationService.userProfile$.pipe(map(x => x.unique_name));

  constructor(
    private authenticationService: AuthenticationService,
    private store: Store<AppState>,
    private componentStore: MyDashboardTabComponentStore,
    private loader: LoaderService,
    private toastService: ToastService,
    public commonDataService: CommonDataService,
    private myDashboardService: MyDashboardService,
    private router: Router,
    title: Title,
    @Inject(APPLICATION_NAME) appName: string,
    private loggerService: LoggerService
  ) {
    title.setTitle(`${appName} - My Dashboard`);
  }

  ngOnInit() {
    this.loader.register(this.componentStore.loading$);
  }

  setPage(page: number) {
    this.componentStore.pageSelected(page);
  }

  refreshOpenItems() {
    this.componentStore.refresh();
  }

  filterByUserClicked() {
    this.componentStore.filterByUserClicked();
  }

  filterByBranchClicked() {
    this.componentStore.filterByBranchClicked();
    this.store.dispatch(MyDashboardActions.toggleShowAllQuotesFlag())
  }

  branchSelected(branch: Branch) {
    this.componentStore.branchSelected(branch);
  }

  filter(filterKey: string) {
    this.componentStore.searchTermChanged(filterKey);
  }

  sortDataBy(sortBy: string) {
    this.componentStore.sortSelected(sortBy);
  }

  editClick(cart: any, isLocked = false, lockedName = null) {
    if(isLocked){
      this.toastService.showToast(`This Quote is locked by ${lockedName}. Please refresh the dashboard or wait until another user unlocks it.`, ToastType.Error);
      return;
    }
    this.loader.loading = true;
    if (cart.type === OpenItemType[OpenItemType.cart]) {
      this.store.dispatch(CartActions.selectOpenCart({ openCartId: cart.cartId, navigateToMyCart: true }));
    } else if (cart.type === OpenItemType[OpenItemType.quote]) {
      this.store.dispatch(CartActions.loadCartForQuote({ cartId: cart.cartId }));
    }

  }

  unlockClick(cart: any) {
    this.store.dispatch(MyDashboardActions.unlockQuoteForOthers({ cartId: cart.cartId}));
  }

  timeTickEvent(data: any) {
      data.applyRedClass = true;
  }

  ngOnDestroy() {
    this.store.dispatch(MyDashboardActions.disableShowAllQuotesFlag());
    this.store.dispatch(MyDashboardActions.clearDashboardBranch());
  }
}
