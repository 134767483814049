import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { MyCartComponentStore } from 'app/_components/my-cart/my-cart.component.store';
import { AppInsightAddToCart } from 'entities/app-insight-add-to-cart';
import { MscPayer } from 'entities/customer-search';
import { VerifiedPrice } from 'entities/parts/verified-price';
import { MscPriceVerifyData } from 'entities/price-verify/msc-price-verify-data';
import { MscPriceVerifyPart } from 'entities/price-verify/msc-price-verify-part';
import { ResultMessage } from 'entities/result-message';
import { ToastType } from 'entities/toast-type';
import { map, tap } from 'rxjs/operators';
import { CommonDataService } from 'services/common-data.service';
import { LoaderService } from 'services/loader.service';
import { LoggerService, SourceLocationType } from 'services/logger.service';
import { PartService } from 'services/part.service';
import { ToastService } from 'services/toast.service';
import { AppState } from 'store/app-state';
import * as CartActions from 'store/cart/cart.actions';
import * as CustomerSelectors from 'store/customer/customer.selectors';
import * as VerifiedPricesActions from 'store/verified-prices/verified-prices.actions';
@Component({
  selector: 'price-verify-modal',
  templateUrl: './price-verify-modal.component.html'
})
export class PriceVerifyModalComponent implements OnDestroy {
  public loading: boolean = true;
  public payer: MscPayer;
  getPayer$ = this.store.select(CustomerSelectors.selectMscPayer)
    .pipe(
      tap((payer) => {
        this.loading = false;
        this.payer = payer;
      })
    );
  selectedCustomerNumber$ = this.store.select(CustomerSelectors.selectedCustomer)
      .pipe(map(x => x.customerNumber));
  @Input() source: string;

  @Input() mscPriceVerifyParts: MscPriceVerifyPart[];
  @Input() parts: any;
  @Output() mscPriceVerifiedOut: EventEmitter<any> = new EventEmitter();

  @Input() cartId;



  private subscription = this.getPayer$.subscribe();

  constructor(private loader: LoaderService,
    private toastService: ToastService,
    public commonDataService: CommonDataService,
    private partService: PartService,
    private loggerService: LoggerService,
    private activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private myCartComponentStore: MyCartComponentStore
  ) { }

  closeModal(): void {
    this.activeModal.dismiss();
  }

  mscPriceVerification(customerNumber: string) {
    let mscPriceVerifyData: MscPriceVerifyData =
    {
      mscPriceVerifyParts: this.mscPriceVerifyParts,
      payerAccountNumber: this.payer.payerNumber,
      mscCardNumber: this.payer.mscCardNumber,
      branchCode: this.commonDataService.Branch.code,
      cartId: this.cartId,
      salesOrganization: null,
      customerNumber
    };

    this.loader.loading = true;
    this.partService.getMscPriceVerification(mscPriceVerifyData)
      .then(p => {
        this.loader.loading = false;
        if(p.priceVerifyItems.some(x => !x.isPriceVerified) && p.messages.length === 0){
          this.toastService.showToast('Unable to price-verify some parts', ToastType.Info);
        }
        if (p.ErrorType != undefined && p.ErrorType != null && p.ErrorType != 200) {
          this.toastService.errorMessage('PriceVerifyModalComponent', 'mscPriceVerification', 'getMscPriceVerification', p);
          this.store.dispatch(CartActions.priceVerifyFailed({ error: p }));
        } else {
          if (p.priceVerifyItems != null && p.priceVerifyItems != undefined && p.priceVerifyItems.length > 0) {
            const mscPriceVerifiedData = {
              isPriceVerified: p.priceVerifyItems['0'].isPriceVerified,
              itemCategory: p.priceVerifyItems['0'].itemCategory,
              partNumber: p.priceVerifyItems['0'].partNumber,
              quantity: p.priceVerifyItems['0'].quantity,
              rebate: p.priceVerifyItems['0'].rebate,
              unitPrice: p.priceVerifyItems['0'].unitPrice
            };

            const isPriceVerified = p.priceVerifyItems
              .filter((priceVerifyItem) => priceVerifyItem.isPriceVerified);
            if (isPriceVerified.length > 0) {

              let appInsightAddToCart = Object.assign(new AppInsightAddToCart(), {
                userId: this.commonDataService.User.id,
                customerNumber: this.commonDataService.Customer.customerNumber,
                customerName: this.commonDataService.Customer.customerName,
                branchNumber: this.commonDataService.Branch.code,
                cartDetails: JSON.stringify(this.commonDataService.CartDetails),
                source: SourceLocationType[this.source]
              });
              appInsightAddToCart.product = this.loggerService.getAppInsightParts(this.parts, JSON.stringify(appInsightAddToCart).length);
              this.loggerService.trackMetric('PriceVerifyComponent_priceVerify_mscPriceVerification', appInsightAddToCart);
            }
            this.mscPriceVerifiedOut.emit(mscPriceVerifiedData);
            this.showResponseMessage(isPriceVerified, p.messages);
            if(p.cartUpdatedWithVerifiedPrices != null){
              this.store.dispatch(CartActions.priceVerifySuccess({ cart: p.cartUpdatedWithVerifiedPrices }));
            } else {
              const verifiedItem = p.priceVerifyItems.shift();
              if(verifiedItem.isPriceVerified){
                const verifiedPrice: VerifiedPrice = {
                  partNumber: verifiedItem?.partNumber,
                  rebate: verifiedItem?.rebate,
                  unitPrice: verifiedItem?.unitPrice,
                  coupons: verifiedItem.coupons
                }
                this.store.dispatch(VerifiedPricesActions.upsertVerifiedPrice({verifiedPrice}));
              }
            }

            this.activeModal.close({ cartId: mscPriceVerifyData.cartId });
          }
        }
        this.loggerService.verbose('PriceVerify Component: ', p);

      },
        () => { this.loader.loading = false; });
  }

  showResponseMessage(isPriceVerified: any[], messages: ResultMessage[]) {
    const successMessageObject = { message: 'The Price has been verified and adjusted.', type: ToastType.Success };

    if (isPriceVerified && isPriceVerified.length > 0 && messages.length <= 0) {
      this.toastService.showToast(successMessageObject.message, successMessageObject.type);
    } else if (isPriceVerified && isPriceVerified.length > 0 && messages.length > 0) {
      const toastMessages = messages
        .map((message) => this.toastService.toToastMessage(message))
        .concat(successMessageObject);
      this.toastService.showMultilineToast(toastMessages);
    } else if (messages.length > 1) {
      const toastMessages = messages
        .map((message) => this.toastService.toToastMessage(message));
      this.toastService.showMultilineToast(toastMessages);
    } else if (messages.length == 1) {
      this.toastService.showToast(messages[0].message, this.toastService.toToastType(messages[0].level));
    }
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
}
