import { Component, ChangeDetectionStrategy, OnDestroy, Input, ChangeDetectorRef, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, EMPTY, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { LoyaltyProgram, LoyaltyProgramAccountsSearchCriteria } from 'entities/loyalty-account';
import { LoyaltyService } from 'services/loyalty.service';
import { ToastService } from 'services/toast.service';
import { ToastType } from 'entities/toast-type';
import { AppState } from 'store/app-state';
import { AppInsightSto } from 'entities/app-insight-sto';
import { Store } from '@ngrx/store';
import * as CartSelectors from 'store/cart/cart.selectors';
import { LoaderService } from 'services/loader.service';
import * as BranchSelectors from 'store/branch/branch.selectors'

@Component({
  selector: 'find-loyalty-program-account-modal',
  templateUrl: './find-loyalty-program-account-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FindLoyaltyProgramAccountModalComponent implements OnDestroy, OnInit {
  @Input() readonly searchCriteria: LoyaltyProgramAccountsSearchCriteria;

  public memberId: string;
  public disabled: boolean = false;
  public customerNumber: string = null;

  private submitSubject: Subject<{ memberId: string }> = new Subject();
  private loadingSubject = new BehaviorSubject(false);

  private subscriptions = [this.submitSubject
    .pipe(
      tap(() => {
        this.disabled = true;
        this.changeDetectorRef.markForCheck();
      }),
      withLatestFrom(this.store.select(BranchSelectors.selectedBranch)),
      switchMap(([{memberId}, branch]) =>{
        this.loadingSubject.next(true);
        return this.loyaltyService.searchLoyaltyProgramAccounts({
          loyaltyAccountIdentifier: memberId,
          loyaltyProgram: 'peterbilt',
          branchCode: branch.code,
          customerSapCode: this.customerNumber,
          companyName: null,
          emailAddress: null,
          phone: null,
          postalCode: null,
          userId: null,
        })
          .pipe(
            tap((loyaltyAccount) => {
              // If loyalty account is not found, show toast message before closing modal
              if (!loyaltyAccount) {
                this.toastService.showToast(`Could not find loyalty account ${memberId} in ${this.searchCriteria.loyaltyProgram}.`, ToastType.Warning);
                this.disabled = false;
                this.changeDetectorRef.markForCheck();
                return;
              }
              this.loadingSubject.next(false);
              this.ngbActiveModal.close(loyaltyAccount[0]);
            }),
            catchError((error) => {
              this.loadingSubject.next(false);
              this.toastService.showToast('Error while checking for loyalty account', ToastType.Error);
              this.disabled = false;
              this.changeDetectorRef.markForCheck();
              return EMPTY;
            })
          )
        }
      )
    )
    .subscribe(), this.loadingSubject.asObservable().subscribe()];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private ngbActiveModal: NgbActiveModal,
    private loyaltyService: LoyaltyService,
    private toastService: ToastService,
    private store: Store<AppState>,
    private loaderService: LoaderService
  ) { }
  ngOnInit(): void {
    this.loaderService.register(this.loadingSubject.asObservable());
  }

  cancel() {
    this.ngbActiveModal.dismiss();
  }

  submit() {
    this.submitSubject.next({ memberId: this.memberId });
  }

  ngOnDestroy() {
    if (this.subscriptions.length) {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }
}
