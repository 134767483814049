<ng-container *ngLet="{
  isInternalUser: isInternalUser$ | async,
  isWmBranch: isWmBranch$ | async,
  currencyCode: currencyCode$ | async
} as vm">
  <div class="row order-confirmation-order-item-row py-2" id="orderItem_tr_{{idSuffix}}_{{index}}">
    <div [ngClass]="createColumnClass('partNumber', vm.isInternalUser)">
      <span style="padding-right: 4px; font-weight: bold;" *ngIf="isHotFlagValid">*</span>
      {{orderItem.partNumber}}
    </div>
    <div [ngClass]="createColumnClass('item', vm.isInternalUser)">
      {{orderItem.description}}
      <br>
      <span *ngIf="(orderItem.coreOption == 'NOCORER' || orderItem.coreOption == 'CORE1')
              && orderItem.partNumber != 'PARTSBUYOUTTX'">
        {{
        (orderItem.coreOption
        == "NOCORER") ? "No Core Returned" : "Core Returned"
        }}
      </span>
      <span *ngIf="(orderItem.coreOption == 'NOCORE' || orderItem.coreOption == 'NICORE'
                    || orderItem.coreOption == 'NICORER') && orderItem.partNumber == 'PARTSBUYOUTTX'">
        {{ orderItem.partBuyoutCoreOption == 'NOCORE' ? 'No Core' : '' }}
        {{ orderItem.partBuyoutCoreOption == 'NICORE' ? 'Non-Inventory Core' : '' }}
        {{ orderItem.partBuyoutCoreOption == 'NICORER' ? 'Non-Inventory Core w Return' : '' }}
      </span>
    </div>
    <div class="text-end" *ngIf="orderItem?.isHotFlag" [ngClass]="createColumnClass('hotFlag', vm.isInternalUser)">{{getHotFlagValueByCode(orderItem?.hotFlagCode)}}</div>
    <div class="text-end" [ngClass]="createColumnClass('qty', vm.isInternalUser)">{{orderItem.quantity}}</div>
    <div class="text-end" id="orderItem_td_{{idSuffix}}WithCoupons_{{index}}" *ngIf="showCouponColumn" [ngClass]="createColumnClass('totalCoupon', vm.isInternalUser)">
      <span id="orderItem_div_{{idSuffix}}CouponValue_{{index}}" *ngIf="couponValue" class="text-danger">
        {{couponValue || 0 | currency: vm.currencyCode :'symbol':'1.2-2'}}
      </span>
    </div>
    <div class="text-end" [ngClass]="createColumnClass('total', vm.isInternalUser)">
      <div id="orderItem_div_{{idSuffix}}IsSpecialPricing_{{index}}" class="text-danger" *ngIf="orderItem.isSpecialPricing">Special Sale Price</div>
      <div id="orderItem_div_{{idSuffix}}Price_{{index}}" [ngClass]="orderItem.isSpecialPricing ? 'text-danger' : ''">
        {{orderItemValue | currency: vm.currencyCode :'symbol':'1.2-2'}}
        <a id="orderItem_a_{{idSuffix}}Verify_{{index}}" class="btn price-verify-icon" style="cursor:default; color:#fff;" *ngIf="orderItem.isPriceVerified">v</a>
      </div>
    </div>
    <div class="text-start" id="orderItem_td_{{idSuffix}}JobNumber_{{index}}" *ngIf="vm.isInternalUser" [ngClass]="createColumnClass('jobNumber', vm.isInternalUser)">
      {{orderItem.jobNumber}}
      <span *ngIf="vm.isWmBranch">{{orderItem.deliveryOption}}</span>
      <span style="padding-left: 10px; font-weight: bold;" *ngIf="isHotFlagValid">*</span>
    </div>
  </div>
</ng-container>
