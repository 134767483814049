/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @angular-eslint/component-selector */
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { DEFAULT_IMAGE_URL, IMAGE_BASE_URL } from 'app/app.constants';
import { KeysWithType } from 'entities/keys-with-type';
import { FavoritePartView } from 'entities/parts/favorite-part-view';
import { ToastType } from 'entities/toast-type';
import { getImageHelper } from 'helpers/get-image';
import { merge, Observable, of, Subject, Subscription } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { CommonDataService } from 'services/common-data.service';
import { FavoritePartsService } from 'services/favorite-parts.service';
import { SourceLocationType } from 'services/logger.service';
import { ToastService } from 'services/toast.service';
import { AppState } from 'store/app-state';
import * as CartActions from 'store/cart/cart.actions';

@Component({
  selector: 'favorite-parts',
  templateUrl: './favorite-parts.component.html'
})
export class FavoritePartsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('ngForm', { static: true }) ngForm: NgForm;
  @ViewChild('txtSearchTerm') favoritePartsSearchRef: ElementRef<HTMLInputElement>;
  partList: FavoritePartView[] = null;
  selectedFavoriteParts: FavoritePartView[];
  selectAllFavoritePart: boolean;
  searchTerm: string = '';
  unfilteredParts: FavoritePartView[] = [];



  removePartFromFavoritesSubject = new Subject<any>();
  getFavoritePartsSubject = new Subject<void>();

  removePartFromFavorites$: Observable<void> = this.removePartFromFavoritesSubject.pipe(
    map(part => (<FavoritePartView & { number: string }>{
      ...part,
      number: part.partNo
    })),
    switchMap(
      (part: FavoritePartView & { number: string }) => this.favoritePartService.removePartFromFavorites(part).pipe(
        map((success: boolean) => {
            if (success) {
              this.unfilteredParts = this.unfilteredParts.filter((value) => value.id !== part.id);
              this.partSearch();
            }
          }
        )
      )
    )
  );

  getFavoriteParts$: Observable<void> = this.getFavoritePartsSubject.pipe(
    switchMap(
      () => this.favoritePartService.getFavoriteParts().pipe(
        map(
          parts => {
            this.unfilteredParts = parts || [];
            this.partList = this.unfilteredParts;
          }
        )
      )
    )
  );

  subscriptions: Subscription = merge(this.removePartFromFavorites$, this.getFavoriteParts$).subscribe();

  constructor(
    private store: Store<AppState>,
    private favoritePartService: FavoritePartsService,
    public commonDataService: CommonDataService,
    private toastService: ToastService,
    @Inject(IMAGE_BASE_URL) public imageBaseUrl: string,
    @Inject(DEFAULT_IMAGE_URL) public defaultImage: string
  ) {
    this.selectedFavoriteParts = [];
    this.selectAllFavoritePart = false;
    this.store.dispatch(CartActions.getCartDataForBranchAndCustomer());
  }

  addToCart() {
    this.store.dispatch(CartActions.addFavoritePartsToCart({ favoritePartsToAdd: [...this.selectedFavoriteParts] }));
  }

  ngOnInit() {
    this.getFavoriteParts();
  }

  ngAfterViewInit() {
    this.favoritePartsSearchRef.nativeElement.focus();
  }

  onFavouritepartItemSelectionChange(favoritePart: FavoritePartView, isChecked: boolean) {
    if (isChecked) {
      this.selectedFavoriteParts.push(favoritePart);
    } else {
      this.selectedFavoriteParts = this.selectedFavoriteParts.filter((value: FavoritePartView) => value.id !== favoritePart.id);
    }
  }

  toggleSelectFavoritePart(isChecked: boolean) {
    const templist: FavoritePartView[] = [];
    this.partList.forEach((element: FavoritePartView) => {
      element.selected = isChecked;
      templist.push(element);
    });
    if (isChecked) {
      this.selectedFavoriteParts = templist;
    } else {
      this.selectedFavoriteParts = [];
    }
  }

  getFavoriteParts() {
    this.getFavoritePartsSubject.next();
  }

  copyToClipBoard(contentToCopy: string) {
    window.navigator['clipboard'].writeText(contentToCopy);
    this.toastService.showToast(`${contentToCopy} copied to clipboard.`, ToastType.Success);
  }

  openNationalInventory(part: FavoritePartView & { source?: string }) {
    part.source = SourceLocationType[SourceLocationType.PartDetailResult];
    this.store.dispatch(CartActions.showNationalInventoryModal({ part }));
  }

  removeFromFavorites(part: FavoritePartView) {
    this.removePartFromFavoritesSubject.next(part);
  }

  autocomplete = (text$: Observable<string>) =>
    text$.pipe(
      withLatestFrom(of(false)),
      switchMap(() => of(this.getAutocompleteSuggestions())
      )
    );

  getAutocompleteSuggestions(): string[] {
    const trimmed = this.searchTerm.trim();
    if (trimmed) {
      return this.getSearchResults(this.unfilteredParts, trimmed, ["partId", "partNo"])?.map(part => part.partNo);
    } else {
      return [];
    }
  }

  getSearchResults(parts: FavoritePartView[], trimmed: string, keywords: KeysWithType<FavoritePartView, string>[]): FavoritePartView[] {
    return parts.filter((part) =>
      keywords.find(keyword =>
        part[keyword] && part[keyword].toUpperCase().indexOf(trimmed.toUpperCase()) !== -1)
    );
  }

  partSearch() {
    const trimmed = this.searchTerm.trim();
    this.selectedFavoriteParts = [];
    this.selectAllFavoritePart = false;
    this.unfilteredParts.forEach(part => {
      part.selected = false;
    });
    if (trimmed) {
      this.partList = this.getSearchResults(this.unfilteredParts, trimmed, ["partId", "partNo", "vmrsDescription", "partDescription", "manufacturer"]);
    } else {
      this.partList = this.unfilteredParts;
    }
  }

  onSearchTermKeypress(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.partSearch();
    }
    if (this.searchTerm === "" && this.partList.length !== this.unfilteredParts.length) {
      this.partList = this.unfilteredParts;
    }
  }

  selectSearchTerm(event: NgbTypeaheadSelectItemEvent<string>) {
    this.searchTerm = event.item;
    this.partSearch();
  }

  getImage(image: string, imageBaseUrl: string, imageSize: string): string {
    return getImageHelper(image, imageBaseUrl, imageSize);
  }

  ngOnDestroy() {
    this.subscriptions?.unsubscribe();
  }
}
