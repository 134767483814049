import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subject, Subscription, of } from 'rxjs';
import { BasketService } from 'services/basket.service';
import { AppState } from 'store/app-state';
import * as OrderConfirmationSelectors from 'store/order-confirmation/order-confirmation.selectors';
import * as BranchSelectors from 'store/branch/branch.selectors';
import { catchError, map, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { PartBinLocationCollection, PartBinLocationItem } from 'entities/part-bin-location-item';
import { PartService } from 'services/part.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-override-bin-location-modal',
  templateUrl: './override-bin-location-modal.component.html',
  styleUrls: ['./override-bin-location-modal.component.scss']
})
export class OverrideBinLocationModalComponent implements OnInit, OnDestroy {

  model: BinLocationDictionary = {};

  binLocations$ = this.store.select(OrderConfirmationSelectors.selectOrder).pipe(
    withLatestFrom(this.store.select(OrderConfirmationSelectors.selectOverrideBins), this.store.select(BranchSelectors.selectedBranch)),
    switchMap(([order, overrideBins, branch]) => {
      const partNumbers = order?.orderItems?.map((x) => x.partNumber);
      return this.partService.getPartBinLocations(partNumbers, branch.code)
        .pipe(
          map((partBinLocations: PartBinLocationCollection) => {
            const _overrideBins: BinLocationDictionary = {};
            const orderItems = order?.orderItems?.map(x => {
              return {
                partNumber: x.partNumber,
                itemNum: x.itemNumber,
                orderNum: order.order.sapOrderNo,
                quantity: x.quantity
              };
            });

            orderItems?.forEach(item => {
              const lookupBins: any[] = overrideBins[item.partNumber]?.map(ob => ({ ...ob, quantityAvailable: 0 }));
              const partBinLocationItems: PartBinLocationItem[] = partBinLocations[item.partNumber.toLowerCase()];

              if (lookupBins?.length) {
                const selectedBin = lookupBins.filter(x => x.isDefaultBin)[0]?.binLocation;
                const selectedBinType = lookupBins.filter(x => x.isDefaultBin)[0]?.storageType;

                if (partBinLocationItems?.length) {
                  lookupBins.forEach((lookupBin) => {
                    let partBinLocationItem = partBinLocationItems.find(binLocationItem => binLocationItem.binLocation === lookupBin.binLocation);
                    if (partBinLocationItem) {
                      lookupBin.quantityAvailable = partBinLocationItem.quantityAvailable;
                    }
                  });
                }

                _overrideBins[item.partNumber] = { ...item, bins: lookupBins, selectedBin, selectedBinType };
              }
            });

            return _overrideBins;
          }),
          catchError(error => {
            this.toastService.errorMessage('OverrideBinLocationModalComponent', 'binLocations', 'binLocations', error);
            return of([]);
          })
        )
    }),
    shareReplay(1)
  );

  dismissModal = new Subject<void>();
  dismissModal$ = this.dismissModal.pipe(
    withLatestFrom(this.binLocations$),
    tap(([_, binLocations]) => {
      const selectedBins = Object.values(binLocations).map(x => {
        return {
          ItemNumber: x.itemNum,
          Quantity: x.quantity,
          StorageBin: x.selectedBin,
          StorageType: x.selectedBinType
        };
      });
      this.activeModal.close(selectedBins);
    }
    ));
  subscriptions: Subscription;
  constructor(private store: Store<AppState>,
    private basketService: BasketService,
    private activeModal: NgbActiveModal,
    private partService: PartService,
    private toastService: ToastService) { }


  ngOnInit() {
    this.subscriptions = this.dismissModal$.subscribe();
  }

  binSelected(lineItem, binLocation, storageType) {
    lineItem.selectedBin = binLocation;
    lineItem.selectedBinType = storageType;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  closeModal() {
    this.dismissModal.next();
  }

}

export interface BinLocationDictionary {
  [key: string]: {
    partNumber: string;
    itemNum: number;
    orderNum: string;
    quantity: number;
    bins: any[];
    selectedBin: string;
    selectedBinType: string;
  };
}
