import { Component, Input, inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "back-counter-confirm-complete-modal",
  templateUrl: './back-counter-confirm-complete-modal.component.html'
})
export class BackCounterConfirmCompleteModalComponent {

  @Input()
  orderNumber: string = "";

  private activeModal: NgbActiveModal = inject(NgbActiveModal);

  closeModal(): void {
    this.activeModal.dismiss();
  }

  completeRequest(): void {
    this.activeModal.close(true);
  }
}
