import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { Action, Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SharedModule } from 'app/_modules/shared.module';
import { AppRouteModule } from 'app/app-route.module';
import { environment } from 'environments/environment';
import { UserInitializerService } from "initializers/user.initializer";
import { ResponseInterceptor } from 'interceptors/ResponseInterceptor';
import { TimeoutInterceptor } from 'interceptors/timeout-interceptor';
import { User } from 'oidc-client';
import { ConfigurationService } from "services/configuration.service";
import { LoggerService } from "services/logger.service";
import { AppState } from 'store/app-state';
import { BackCounterEffects } from 'store/back-counter/back-counter.effects';
import { backCounterReducer } from 'store/back-counter/back-counter.reducer';
import { BasketEffects } from 'store/basket/basket.effects';
import { basketReducer } from 'store/basket/basket.reducer';
import { BranchEffects } from 'store/branch/branch.effects';
import { branchReducer } from 'store/branch/branch.reducer';
import { CartEffects } from 'store/cart/cart.effects';
import { cartReducer } from 'store/cart/cart.reducer';
import { CheckoutFormEffects } from 'store/checkout-form/checkout-form.effects';
import * as ConfigurationActions from 'store/configuration/configuration.actions';
import { ConfigurationEffects } from 'store/configuration/configuration.effects';
import { configurationReducer } from 'store/configuration/configuration.reducer';
import { CustomerEffects } from 'store/customer/customer.effects';
import { customerReducer } from 'store/customer/customer.reducer';
import { FeatureFlagsEffects } from 'store/feature-flags/feature-flags.effects';
import { featureFlagReducer } from 'store/feature-flags/feature-flags.reducer';
import { FeaturedPartsEffects } from 'store/featured-parts/featured-parts.effects';
import { featuredPartsReducer } from 'store/featured-parts/featured-parts.reducer';
import { LoyaltyEffects } from 'store/loyalty/loyalty.effects';
import { loyaltyReducer } from 'store/loyalty/loyalty.reducer';
import { MyCartEffects } from 'store/my-cart/my-cart.effects';
import { myCartReducer } from 'store/my-cart/my-cart.reducer';
import { MyDashboardEffects } from 'store/my-dashboard/my-dashboard.effects';
import { myDashboardReducer } from 'store/my-dashboard/my-dashboard.reducer';
import { OrderConfimationEffects } from 'store/order-confirmation/order-confirmation.effects';
import { orderConfirmationReducer } from 'store/order-confirmation/order-confirmation.reducer';
import { PartsBuyoutEffects } from 'store/parts-buyout/parts-buyout.effects';
import { ProvinceEffects } from 'store/province/province.effects';
import { provinceReducer } from 'store/province/province.reducer';
import { verifiedPricesReducer } from 'store/verified-prices/verified-prices.reducer';
import { FavoritePartsComponent } from './_components/favorite-parts/favorite-parts.component';
import { FeaturedProductsHomeComponent } from './_components/featured-parts-home/featured-parts-home.component';
import { FooterComponent } from './_components/footer/footer.component';
import { HeaderNavComponent } from './_components/header-nav/header-nav.component';
import { HeaderSalesComponent } from './_components/header-sales/header-sales.component';
import { HomeComponent } from './_components/home/home.component';
import { LoaderComponent } from './_components/loader/loader.component';
import { MyDashboardTabComponent } from './_components/my-dashboard-tab/my-dashboard-tab.component';
import { MyDashboardComponent } from './_components/my-dashboard/my-dashboard.component';
import { PartsSearchBaseComponent } from './_components/part-search-base/parts-search.component.base';
import { PartsSearchComponent } from './_components/parts-search/parts-search.component';
import { ProactiveChatComponent } from './_components/proactive-chat/proactive-chat.component';
import { ToastComponent } from './_components/toasts/toast.component';
import { DeleteCartConfirmModalComponent } from './_modals/delete-cart-confirm-modal/delete-cart-confirm-modal.component';
import { AuthenticationModule } from './_modules/authentication/authentication.module';
import { OrdersModule } from './_modules/orders.module';
import { PartModule } from './_modules/part.module';
import { FeatureFlagService } from './_services/feature-flag.service';
import { AppComponent } from './app.component';
import * as constants from "./app.constants";
import { AppErrorHandler } from './app.error-handler';

export function initializeUser(
  userInitializerService: UserInitializerService,
  configurationService: ConfigurationService,
  loggerService: LoggerService,
  featureFlagService: FeatureFlagService
  ) {
  return () => userInitializerService.initialize()
    .then((user: User) => {
      if (!user) {
        return;
      }
      configurationService.initialize();
      return Promise.all([loggerService.setServerTelemetry(), featureFlagService.initialize()]);
    });
}

export function initializeCart(store: Store<AppState>) {
  return () => {
    store.dispatch(ConfigurationActions.loadCache());
  };
}

@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
    AppRouteModule,
    AuthenticationModule.forRoot({
      oidcSettingsFactory: constants.AUTH_SETTINGS,
      scopes: constants.AUTH_SCOPES,
      defaultApiDomain: environment.API_URL,
      unauthenticatedAction: "login",
      policies: {
        signInSignUp: constants.AUTH_SIGNIN_POLICY,
        resetPassword: constants.AUTH_PASSWORDRESET_POLICY,
        editProfile: constants.AUTH_PROFILEEDIT_POLICY
      },
      audiences: constants.AUTH_AUDIENCES,
      postSingleLogoutRedirectUrl: environment.WEB_URL
    }),
    OrdersModule,
    PartModule,
    StoreModule.forRoot<AppState, Action>({
      selectedCart: cartReducer,
      basket: basketReducer,
      verifiedPrices: verifiedPricesReducer,
      loyaltyProfile: loyaltyReducer,
      featureFlags: featureFlagReducer,
      orderConfirmation: orderConfirmationReducer,
      featuredParts: featuredPartsReducer,
      myDashboard: myDashboardReducer,
      branch: branchReducer,
      customer: customerReducer,
      configuration: configurationReducer,
      myCart: myCartReducer,
      province: provinceReducer,
      backCounter: backCounterReducer
    }),
    EffectsModule.forRoot([
      CartEffects,
      BasketEffects,
      LoyaltyEffects,
      FeatureFlagsEffects,
      OrderConfimationEffects,
      FeaturedPartsEffects,
      BranchEffects,
      CustomerEffects,
      ConfigurationEffects,
      PartsBuyoutEffects,
      MyCartEffects,
      ProvinceEffects,
      MyDashboardEffects,
      BackCounterEffects,
      CheckoutFormEffects
    ]),
    NgbModule,
    StoreDevtoolsModule.instrument({
      name: 'PartsConnect DevTools',
      maxAge: false,
      logOnly: environment.production,
    })
  ],
  declarations: [
    AppComponent,
    HeaderNavComponent,
    HeaderSalesComponent,
    HomeComponent,
    FooterComponent,
    LoaderComponent,
    ToastComponent,
    PartsSearchComponent,
    PartsSearchBaseComponent,
    MyDashboardComponent,
    FavoritePartsComponent,
    FeaturedProductsHomeComponent,
    MyDashboardTabComponent,
    ProactiveChatComponent,
    DeleteCartConfirmModalComponent
  ],
  providers: [
    FeatureFlagService,
    // Initializers
    { provide: APP_INITIALIZER, useFactory: initializeUser, deps: [UserInitializerService, ConfigurationService, LoggerService, FeatureFlagService], multi: true },
    { provide: APP_INITIALIZER, useFactory: initializeCart, deps: [Store], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
