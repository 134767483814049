import { Component, OnInit, Input } from '@angular/core';
import { Part } from 'entities/part';
import { SourceLocationType } from 'services/logger.service';

@Component({
  selector: 'part-card-description',
  templateUrl: './part-card-description.component.html'
})
export class PartCardDescriptionComponent implements OnInit {
  @Input() idSuffix: string = "";
  @Input() part: Part;

  constructor() { }

  ngOnInit() {
  }

}
