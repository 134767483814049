import { createFeatureSelector, createSelector } from "@ngrx/store";
import { Configuration } from "entities/configuration";
import { CountryCode } from 'entities/country-code';
import { distinct } from 'helpers';
import { selectedBranch } from "store/branch/branch.selectors";

export const selectConfiguration = createFeatureSelector< Configuration>('configuration');

export const selectUserInfo = createSelector(
  selectConfiguration,
  (state) => state.user
);

export const isInternal = createSelector(
  selectConfiguration,
  (state) => state?.user?.isInternal
);

export const selectBranch = createSelector(
  selectConfiguration,
  (state) => state.branch
);

export const selectCustomer = createSelector(
  selectConfiguration,
  (state) => state.customer
);

export const isCanadaUser = createSelector(
  selectedBranch,
  (branch) => branch?.country?.toUpperCase() === CountryCode.CA
);

export const selectGlobalPermissions = createSelector(
  selectConfiguration,
  (state): string[] => state.globalPermissions || []
);

export const selectEntityPermissions = createSelector(
  selectConfiguration,
  (state): { [entityCode: string]: string[] } => state.entityPermissions
);

export const selectAllRoles = createSelector(
  selectConfiguration,
  (state): string[] => state.allRoles || []
);

export const selectAllPermissions = createSelector(
  selectConfiguration,
  selectGlobalPermissions,
  (state, globalPermissions): string[] => {
    let allPermissions: string[] = globalPermissions;

    for (const entityCode in state.entityPermissions) {
      if (state.entityPermissions.hasOwnProperty(entityCode)) {
        allPermissions = allPermissions.concat(state.entityPermissions[entityCode]);
      }
    }

    return distinct(allPermissions);
  }
);

export const hasPermission = (permission: string) => createSelector(
  selectAllPermissions,
  (permissions): boolean =>  permissions.includes(permission)
);

export const hasRole = (role: string) => createSelector(
  selectAllRoles,
  (allRoles): boolean =>  allRoles.includes(role)
);
