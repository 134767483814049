<button id="purchaseOrderCreateModal_cancel" type="button" class="close" data-dismiss="modal" aria-label="Close" tabindex="-1" (click)="cancel()">
  <span aria-hidden="true">&times;</span>
</button>
<form #ngForm="ngForm" (ngSubmit)="ngForm.form.valid && submit()">
  <fieldset class="modal-scrollable-content" [disabled]="busy$ | async">
    <div class="modal-header">
      <h4 *ngIf="!isOnlyHotFlagCreatePO" class="modal-title">Create Purchase Orders</h4>
      <h4 *ngIf="isOnlyHotFlagCreatePO" class="modal-title">Create Req or Purchase Orders</h4>
      <span *ngIf="busy$ | async" class="countLoader"></span>
    </div>
    <div class="modal-body">
      <ng-container *ngFor="let group of (groups$ | async); index as index; last as last">
        <div>
          <div *ngIf="group.info.procurementType === 'sto'">
            Please select a delivery option for STO PO from supplying plant {{ group.info.vendorNumber }}.
          </div>
          <div *ngIf="group.info.procurementType === 'buyout'">
            Please select a delivery option for Buyout PO for vendor {{ group.info.vendorNumber }}.
          </div>
          <div *ngIf="group.info.procurementType === 'hotflag'">
            Please select a Requisition or Purchase Order option for each Hot Flag item.
          </div>
          <div>
            <order-item-list idSuffix="purchaseOrderCreateModal_groups[{{index}}]_orderItems" [orderItems]="group.orderItems" [showHotFlagColumn]="group.info.procurementType === 'hotflag'"></order-item-list>
          </div>
          <div class="form-group">
            <button *ngIf="group.state.showMore" id="purchaseOrderCreateModal_groups[{{index}}]_showLess"
              class="btn btn-secondary" type="button" (click)="showLess(index)">
              Show Less
            </button>
            <button *ngIf="group.hasMore && !group.state.showMore" id="purchaseOrderCreateModal_groups[{{index}}]_showMore"
              class="btn btn-secondary" type="button" (click)="showMore(index)">
              Show {{ group.moreCount }} More
            </button>
          </div>
          <ng-container *ngIf="group.info.procurementType !== 'hotflag'">
            <div *ngIf="group.info.procurementType === 'sto'" class="d-flex align-items-center">
              <input id="purchaseOrderCreateModal_groups[{{index}}]_deliveryType_delivery" type="radio" name="deliveryType[{{index}}]" value="delivery"
                [ngModel]="group.state.deliveryType" (ngModelChange)="updateDeliveryType(index, $event)" required>
              <label for="purchaseOrderCreateModal_groups[{{index}}]_deliveryType_delivery">
                Delivery (Parts will be delivered to requesting branch by fulfilling branch delivery driver)
              </label>
            </div>
            <div class="d-flex align-items-center">
              <input id="purchaseOrderCreateModal_groups[{{index}}]_deliveryType_pickup" type="radio" name="deliveryType[{{index}}]" value="pickup"
                [ngModel]="group.state.deliveryType" (ngModelChange)="updateDeliveryType(index, $event)" required>
              <label for="purchaseOrderCreateModal_groups[{{index}}]_deliveryType_pickup">
                Pickup (Parts will be picked up by requesting branch delivery driver)
              </label>
            </div>
            <div class="d-flex align-items-center">
              <input id="purchaseOrderCreateModal_groups[{{index}}]_deliveryType_freight" type="radio" name="deliveryType[{{index}}]" value="freight"
                [ngModel]="group.state.deliveryType" (ngModelChange)="updateDeliveryType(index, $event)" required>
              <label for="purchaseOrderCreateModal_groups[{{index}}]_deliveryType_freight">
                Ship (Parts will be shipped)
              </label>
            </div>
            <div class="d-flex align-items-center">
              <input id="purchaseOrderCreateModal_groups[{{index}}]_deliveryType_other" type="radio" name="deliveryType[{{index}}]" value="other"
                [ngModel]="group.state.deliveryType" (ngModelChange)="updateDeliveryType(index, $event)" required>
              <label for="purchaseOrderCreateModal_groups[{{index}}]_deliveryType_other">
                Other (Parts will be picked up by the customer)
              </label>
            </div>
          </ng-container>
          <ng-container *ngIf="group.info.procurementType === 'hotflag'">
            <div class="d-flex align-items-center">
              <input id="purchaseOrderCreateModal_groups[{{index}}]_hotflag_req" type="radio" name="deliveryType[{{index}}]" value="requisition"
                [ngModel]="group.state.createRequestOnly" (ngModelChange)="updateHotFlagItems(index, $event)" required>
              <label for="purchaseOrderCreateModal_groups[{{index}}]_hotflag_req">
                Create requisition only
              </label>
            </div>
            <div class="d-flex align-items-center">
              <input id="purchaseOrderCreateModal_groups[{{index}}]_hotflag_po" type="radio" name="deliveryType[{{index}}]" value="po"
                [ngModel]="group.state.createRequestOnly" (ngModelChange)="updateHotFlagItems(index, $event)" required>
              <label for="purchaseOrderCreateModal_groups[{{index}}]_hotflag_po">
                Create purchase order
              </label>
            </div>
          </ng-container>
        </div>
        <hr *ngIf="!last">
      </ng-container>
    </div>
    <div class="modal-footer">
      <div *ngIf="!ngForm.form.valid">Select an option for each line item or group</div>
      <button id="purchaseOrderCreateModal_submit" class="btn btn-primary" type="submit" [disabled]="!ngForm.form.valid">Submit</button>
    </div>
  </fieldset>
</form>
