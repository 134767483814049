import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { CommonDataService } from 'services/common-data.service';
import { ToastService } from 'services/toast.service';
import { ToastType } from 'entities/toast-type';

@Injectable()
export class CashCustomerGuard  {
  constructor(
    private commonDataService: CommonDataService,
    private toastService: ToastService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const options: { inverse: boolean } = route.data && route.data.cashCustomerGuard;
    const inverse = options && options.inverse;

    return this.commonDataService.customer$
      .pipe(
        first(),
        map((customer) => {
          const isCashCustomer = ['200000','200001'].includes(customer.customerNumber)
          return { customer, canActivate: inverse ? !isCashCustomer : isCashCustomer };
        }),
        tap(({ customer, canActivate }) => {
          if (!canActivate) {
            const message = `Cannot perform the selected action for Customer ${customer.customerNumber}.`;
            const reason = inverse ? "Customer cannot be a Cash Customer." : "Customer must be a Cash Customer.";
            const toasts = [message, reason]
              .map((message) => ({ message, type: ToastType.Error }));
            this.toastService.showMultilineToast(toasts);
          }
        }),
        map(({ canActivate }) => canActivate || this.router.parseUrl("/"))
      );
  }
}
