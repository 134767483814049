import { Pipe, PipeTransform } from '@angular/core';
import { OrderDetailsLineItem } from 'entities/order-details-line-item';

@Pipe({ name: 'deliveredCount' })
export class DeliveredCountPipe implements PipeTransform {
  transform(lineItems: OrderDetailsLineItem[]) {
    if (!lineItems) {
      return '...';
    }

    return lineItems
      .filter((lineItem) => lineItem.deliveryStatus === 'delivered')
      .length;
  }
}
