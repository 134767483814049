import { Component, Input, inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "checkout-bag-quantity-modal",
  templateUrl: "./checkout-bag-quantity-modal.component.html",
  styleUrls: ["./checkout-bag-quantity-modal.component.scss"],
})
export class CheckoutBagQuantityComponent {
  bagQuantityForm = new FormGroup({
    bagQuantity: new FormControl(0, [Validators.required, Validators.min(0)]),
  });

  @Input()
  orderNumber: string = "";

  private activeModal: NgbActiveModal = inject(NgbActiveModal);

  closeModal(): void {
    this.activeModal.dismiss();
  }

  onSubmit(): void {
    this.activeModal.close({
      bagQuantity: this.bagQuantityForm.controls.bagQuantity.value,
      bagQuantitySpecified: true,
    });
  }

  keyPressed(evt: KeyboardEvent) {
    const numericKey = +evt.key;
    if(evt.key === ' ' || !(numericKey >= 0 && numericKey <= 9)) evt.preventDefault();
  }

  onPaste(evt: ClipboardEvent) {
    const clipboardData = evt.clipboardData;
    const pastedValue = +clipboardData.getData('text');
    if(!pastedValue || pastedValue < 0) evt.preventDefault();
  }
}
