import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "input[type=text][digitsOnly]"
})
export class DigitsOnlyDirective {
  @HostListener("input", ["$event.target"])
  public onInput(target: { value: string }) {
    const pattern = /^\d*$/;
    if (!pattern.test(target.value)) {
      target.value = target.value.replace(/[^\d]/g, "");
    }
  }
}
