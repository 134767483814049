import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AppState } from 'store/app-state';
import { LoyaltyState } from './loyalty.state';

export const loyaltyProfile = createFeatureSelector< LoyaltyState>('loyaltyProfile');

export const loyaltyAccounts = createSelector(
  loyaltyProfile,
  (state) => state.availableAccounts
);

export const loyaltyAccount = createSelector(
  loyaltyProfile,
  (state) => state.account
);

export const loyaltyDiscounts = createSelector(
  loyaltyProfile,
  (state) => state.discounts
);

export const loyaltyLoading = createSelector(
  loyaltyProfile,
  (state) => state.loading
);

export const hasLoyaltyAccountSelected = createSelector(
  loyaltyProfile,
  (state) => state.account != null
);
