import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subject, Subscription, tap } from 'rxjs';
import { AppState } from 'store/app-state';
import * as BackCounterToolActions from 'store/back-counter/back-counter.actions';
import { RequestNote } from 'store/back-counter/back-counter.entities';

@Component({
  selector: 'app-back-counter-general-request-modal',
  templateUrl: './back-counter-general-request-modal.component.html',
  styleUrls: ['./back-counter-general-request-modal.component.scss']
})
export class BackCounterGeneralRequestModalComponent implements AfterViewInit, OnDestroy, OnInit {

  @Input() updateGeneralRequest: boolean;
  @Input() isComplete: boolean;
  @Input() refOrderNumber: string;
  @Input() department: string;
  @Input() requestor: string;
  @Input() orderNumber: string;
  @Input() notes: RequestNote[];
  @Input() updateNote: Subject<{orderNumber: string; note: string; department: string}>;
  // this subject is for adding notes (locally) that are pending a refresh event from sap
  @Input() addNote: Subject<{orderNumber: string; note: string}>;
  @ViewChild('generalRequestMessages') generalRequestMessagesRef: ElementRef;
  @ViewChild('generalRequestMessagesContainer') generalRequestMessagesContainerRef: ElementRef;
  
  subscription: Subscription;

  form: FormGroup;

  constructor(
    private store: Store<AppState>,
    private activeModal: NgbActiveModal){

  }

  ngOnInit(): void {
    this.form = new FormGroup({
      orderNumber: new FormControl(this.orderNumber, [Validators.maxLength(10)]),
      department: new FormControl('', this.updateGeneralRequest ? [] : [Validators.required]),
      note: new FormControl('', this.updateGeneralRequest ? Validators.maxLength(1000) : [Validators.required, Validators.maxLength(1000)]),
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    if(this.updateNote) {
        const addNoteObs = this.addNote.pipe(
          tap(({ note }) => {
            const date = new Date();
            this.notes.unshift({
              date: date.toDateString(),
              noteText: note,
              techName: null,
              techNumber: null,
              userId: null,
              time: date.getTime().toString(),
            });

            const el: HTMLDivElement =
              this.generalRequestMessagesContainerRef.nativeElement;
            el.scrollTop = 0;

            this.form.controls.note.setValue("");
          })
        );
        this.subscription = addNoteObs.subscribe();
    }
  }

  dismissModal(){
    this.activeModal.dismiss();
  }

  submit(){
    if(this.updateGeneralRequest){
      this.updateNote.next({orderNumber: this.orderNumber, note: this.form.controls.note.value, department: this.department });
    } else {
      this.activeModal.close(this.form.value);
    }
  }

  complete(){
    this.store.dispatch(BackCounterToolActions.completeGeneralRequest({orderNumber: this.orderNumber, department: this.department}));
    this.activeModal.close();
  }

}
