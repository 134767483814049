import { Component, OnInit, Input } from '@angular/core';
import { OrderService } from 'services/order.service';
import { Observable, Subject, of } from 'rxjs';
import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { ToastService } from 'services/toast.service';
import { DownloadService } from 'services/download.service';

@Component({
  selector: 'proof-of-delivery',
  templateUrl: './proof-of-delivery.component.html',
})
export class ProofOfDeliveryComponent implements OnInit {
  @Input() documentId: string;

  public deliveryLoading: boolean = false;

  private deliverySubject: Subject<{}> = new Subject();
  public delivery$: Observable<boolean> = this.deliverySubject
  .pipe(
    switchMap(() => {
      this.deliveryLoading = true;
      return this.orderService.getProofOfDeliveryData(this.documentId)
        .pipe(
          map((pdfData: { data: string }) => {
            const filename = `ProofOfDelivery_${this.documentId}.pdf`;
            this.downloadService.downloadPDF(pdfData.data, filename);
            return true;
          }),
          catchError(error => {
            this.toastService.errorMessage("ProofOfDeliveryComponent", "getProofOfDeliveryData", "getProofOfDeliveryData", error);
            return of(false);
          })
        );
    }),
    tap(() => this.deliveryLoading = false)
  );

  constructor(
    private orderService: OrderService,
    private toastService: ToastService,
    private downloadService: DownloadService) {
  }

  ngOnInit() {
  }

  downloadPdf() {
    this.deliverySubject.next(null);
  }
}
