<div *ngLet="{currencyCode: currencyCode$ | async} as vm">
  <ng-container *ngIf="part$ | async as part">
  <!-- Coupons -->
  <div class="mb-3">
    <div class="strong text-danger text-truncate">
      Coupon Total
    </div>
    <h3 class="strong d-inline">
      <a id="pricing_a_CouponPopover{{idSuffix}}" [ngbPopover]="pricingPopover" placement="bottom" class="text-danger popoverlink" popoverClass="couponPopover">
        <span id="pricing_span_couponTotal{{idSuffix}}">
          {{ couponTotal$ | async | currency: vm.currencyCode :'symbol':'1.2-2' }}
        </span>
        <i style="font-size: 150%; " class="fas fa-caret-down align-text-bottom"></i>
      </a>
    </h3>
  </div>

  <!-- Final Price -->
  <div>
    <div class="strong text-danger">Final Price</div>
    <h3 class="m-0 strong d-flex align-items-center gx-1">
      <span id="pricing_span_finalPrice" class="text-danger">
          {{ finalPrice$ | async | currency: vm.currencyCode :'symbol':'1.2-2'}}
      </span>
      <span id="pricing_span_IsFinalPriceVerified{{idSuffix}}" *ngIf="part.isPriceVerified" class="btn price-verify-icon" title="Price Verified">v</span>
    </h3>
  </div>

  <!-- Coupon popover-->
  <ng-template #pricingPopover>
    <coupon-popover [coupons]="part.sapCoupons" [allProgramDiscounts]="allProgramDiscounts$ | async" [idSuffix]="idSuffix"></coupon-popover>
  </ng-template>
  </ng-container>
</div>
