<form #ngForm="ngForm" (ngSubmit)="submit()">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" tabindex="-1" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="modal-header">
    <h4 class="modal-title">Select Peterbilt loyalty account</h4>
  </div>
  <div class="modal-body">
    <div class="form-group gx-1">
      <button id="findLoyaltyProgramAccountBtn" type="button" class="btn btn-primary" (click)="findLoyaltyProgramAccount('peterbilt')">Add by account number</button>
      <button id="searchLoyaltyProgramAccountBtn" *ngIf="isInternal$ | async" type="button" class="btn btn-primary" (click)="searchLoyaltyProgramAccount('peterbilt')">Search program for account</button>
    </div>
    <loyalty-accounts-display *ngIf="loyaltyAccounts?.length > 0" ></loyalty-accounts-display>
    <table class="table" *ngIf="loyaltyAccounts?.length > 0">
      <thead>
        <tr>
          <th></th>
          <th>Member ID</th>
          <th>Email Address</th>
          <th>Telephone</th>
          <th>Company Name</th>
          <th>Zip Code</th>
        </tr>
      </thead>
      <tbody *ngLet="(selectedLoyaltyAccount$ | async) as selectedAccount">
        <ng-container *ngFor="let loyaltyAccount of loyaltyAccounts | slice:(pager.currentPage-1) * pageSize : pager.currentPage * pageSize; let i = index;">
          <ng-container *ngIf="loyaltyAccount">
            <tr id="selectLoyaltyProgramAccount_row_{{i}}" [ngClass]="{'table-active': selectedAccount?.loyaltyAccountIdentifier == loyaltyAccount?.memberId}">
              <td>
                <div class="d-flex align-items-center">
                  <input id="selectLoyaltyProgramAccount_radio_{{i}}" type="radio" name="loyaltyAccount" [value]="loyaltyAccount?.memberId" [ngModel]="value" (ngModelChange)="selectLoyaltyAccount(loyaltyAccount)">
                  <label for="selectLoyaltyProgramAccount_radio_{{i}}"></label>
                </div>
              </td>
              <td>{{ loyaltyAccount.memberId }}</td>
              <td>{{ loyaltyAccount.emailAddress }}</td>
              <td>{{ loyaltyAccount.phone }}</td>
              <td>{{ loyaltyAccount.companyName }}</td>
              <td>{{ loyaltyAccount.postalCode }}</td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
    <div class="mb-2" *ngIf="(initialSearch$ | async)?.length > 0">
      <nav id="myDashboard_div_ShowResults" class="d-flex justify-content-end align-items-center" aria-label="Page navigation" *ngIf="pager.pages && pager.pages.length">
        <div>
          <span class="pagination-result">Showing {{pager.startIndex + 1}} - {{pager.endIndex + 1}} of
            {{pager.totalItems}} Results
          </span>
        </div>
        <div>
          <ul class="pagination">
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
              <a id="myDashboard_a_First" class="page-link" aria-label="First" (click)="setPage(1)"><span aria-hidden="true">&laquo;</span></a>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
              <a id="myDashboard_a_Previous" class="page-link" aria-label="Previous" (click)="setPage(pager.currentPage - 1)"><span aria-hidden="true">&lsaquo;</span></a>
            </li>
            <li id="myDashboard_li_Page_{{i}}" *ngFor="let page of pager.pages; let i = index" class="page-item" [ngClass]="{active:pager.currentPage === page}">
              <a id="myDashboard_a_Page_{{i}}" class="page-link" (click)="setPage(page)">{{page}}</a>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
              <a id="myDashboard_a_Next" class="page-link" aria-label="Next" (click)="setPage(pager.currentPage + 1)"><span aria-hidden="true">&rsaquo;</span></a>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
              <a id="myDashboard_a_Last" class="page-link" aria-label="Last" (click)="setPage(pager.totalPages)"><span aria-hidden="true">&raquo;</span></a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
  <div class="modal-footer">
    <button id="cancelSelectLoyaltyAccountBtn" type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
    <button id="addSelectedLoyaltyAccountBtn" type="submit" class="btn btn-primary" [disabled]="!selectedLoyaltyAccount">Add to order</button>
  </div>
</form>
