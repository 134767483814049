import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { filter, startWith, switchMap, switchMapTo, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subscription, timer } from 'rxjs';

interface ProactiveChatComponentState {
  chatOpened: boolean;
  displayPrompt: boolean;
}

@Injectable()
export class ProactiveChatComponentStore extends ComponentStore<ProactiveChatComponentState> {
  constructor (
    private router: Router
  ) {
    super({
      chatOpened: false,
      displayPrompt: false
    });
  }

  public readonly chatOpened = this.updater<void, void, void, (observableOrValue: void | Observable<void>) => Subscription>((state) => ({
    ...state,
    chatOpened: true,
    displayPrompt: false
  }));

  public readonly promptDismissed = this.updater((state) => ({
    ...state,
    displayPrompt: false
  }));

  public readonly chatOpened$ = this.select((state) => state.chatOpened)
    .pipe(
      filter((chatOpened) => chatOpened)
    );

  public readonly displayPrompt$ = this.select((state) => state.displayPrompt);

  public readonly init = this.effect<void, Observable<void>, void, (observableOrValue: void | Observable<void>) => Subscription>((enabled$) =>
    enabled$
      .pipe(
        switchMap(() => this.router.events
          .pipe(
            filter((event) => event instanceof NavigationEnd),
            startWith(void 0),
          )
        ),
        switchMapTo(timer(60000)),
        takeUntil(this.chatOpened$),
        take(1),
        tap(() => {
          this.patchState({ displayPrompt: true });
        })
      )
  );
}
