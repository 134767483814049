import { Action, createReducer, on } from "@ngrx/store";
import { MyDashboardState } from "./my-dashboard.state";
import * as MyDashboardActions from './my-dashboard.actions';

export const initialState: MyDashboardState = {
  showQuotesCountForAllBranches: false,
  branchCode: null
};

export const myDashboardReducer = createReducer<MyDashboardState, Action>(
  initialState,
  on(
    MyDashboardActions.toggleShowAllQuotesFlag,
    (state, {}) => ({...state, showQuotesCountForAllBranches: !state.showQuotesCountForAllBranches })
  ),
  on(
    MyDashboardActions.disableShowAllQuotesFlag,
    (state, {}) => ({...state, showQuotesCountForAllBranches: false })
  ),
  on(
    MyDashboardActions.setDashboardBranch,
    (state, { branchCode }) => ({...state, branchCode })
  ),
  on(
    MyDashboardActions.clearDashboardBranch,
    (state ) => ({...state, branchCode: null })
  )
);

