<form #ngForm="ngForm" [ngClass]="{ 'was-validated': !ngForm.form.pending }" (ngSubmit)="ngForm.form.valid && submit()">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" tabindex="-1" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
  <fieldset [disabled]="disabled">
    <div class="modal-header">
      <h4 class="modal-title">Add by account number</h4>
    </div>
    <div class="modal-body">
      <label for="findLoyaltyAccountModal_memberId">Account number</label>
      <input id="findLoyaltyAccountModal_memberId" type="text" class="form-control" name="memberId" [(ngModel)]="memberId" required>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
      <button type="submit" class="btn btn-primary">Add to order</button>
    </div>
  </fieldset>
</form>
