import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly DEFAULT_TIMEOUT = 60000;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((request) => {
        if(request.type === HttpEventType.Response && (request.status === 0 || request.status === null || request.status === undefined)){
          return {...request, status: 500, body: null} as HttpEvent<any>;
        } else {
          return request;
        }
      }),
      timeout(this.DEFAULT_TIMEOUT),
      catchError(error => {
        if (error.name === 'TimeoutError') {
          return throwError(() => new Error('Request timed out'));
        }
        return throwError(() => error);
      })
    );
  }
}
