import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor() { }

  getPdf(data: BlobPart, filename: string): boolean {
    const mediaType = 'application/pdf';
    const blob = new Blob([data], { type: mediaType });
    return this.downloadFile(filename, blob);
  }

  getPdfUrl(pdfBase64: string) {
    try {
      const byteArray = new Uint8Array(atob(pdfBase64).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], {type: 'application/pdf'});
      return URL.createObjectURL(blob);
    }
    catch(err) {
      return "";
    }
  }

  downloadPDF(pdfBase64: string, filename: string){
    const byteArray = new Uint8Array(atob(pdfBase64).split('').map(char => char.charCodeAt(0)));
    const blob = new Blob([byteArray], {type: 'application/pdf'});
    const anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = window.URL.createObjectURL(blob);
    anchor.click();
  }

  downloadFile(filename: string, blob: Blob) {
    const linkElement = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    linkElement.setAttribute('href', url);
    linkElement.setAttribute('download', filename);

    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false
    });
    return linkElement.dispatchEvent(clickEvent);
  }
  downloadFileBase64(fileName: string, type: string, base64: string) {

      const src = `data:${type};base64,${base64}`;
      const link = document.createElement("a");
      link.href = src;
      link.download = fileName;
      link.click();
      link.remove();

  }
}
