import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import {  Store } from '@ngrx/store';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'store/app-state';
import * as FeatureFlagSelectors from 'store/feature-flags/feature-flags.selectors';
import { FeatureFlagService } from 'services/feature-flag.service';
import * as CustomerSelectors from 'store/customer/customer.selectors';
import * as BranchSelectors from 'store/branch/branch.selectors';
import { Actions, ofType } from '@ngrx/effects';
import * as FeatureFlagsActions from 'store/feature-flags/feature-flags.actions';
import { FeatureFlagApiService } from 'services/feature-flag-api.service';

@Injectable()
export class BackCounterFeatureFlagGuard  {
  constructor(
    private action$: Actions,
    private router: Router,
    private featureFlagService: FeatureFlagApiService,
    private store: Store<AppState>
  ) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.canActivate(route, state);
  }

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

    return combineLatest([
      this.store.select(FeatureFlagSelectors.featureFlags),
      this.store.select(BranchSelectors.selectedBranch)])
    .pipe(
      filter(([featureFlags, branch]) => branch.code !== null && Object.keys(featureFlags).length > 0),
      map(([featureFlags, branch]) => {
        const featureFlag = featureFlags["BackCounterTool.Dashboard"];
        if(!featureFlag.sapBranchNumbers.includes(branch.code)){
          return this.router.parseUrl('/');
          } else {
            return true;
          }
        }
      )
    );

  }
}
