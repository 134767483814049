<button id="CustomerModal_Button_Close" type="button" class="close" data-dismiss="modal" aria-label="Close" tabindex="-1" (click)="closeModal()">
  <span aria-hidden="true">&times;</span>
</button>
<div class="modal-header">
<h4 class="modal-title">Select shipping address</h4>
</div>
<div class="modal-body">
<div class="input-group mb-3">
  <input id="CustomerModal_Text_Search" type="text" class="form-control" placeholder="Search ..." [(ngModel)]="filterKey" (keyup)="filter()">
    <span class="input-group-text">
      <i class="fas fa-search" aria-hidden="true"></i>
    </span>
</div>
<div class="customer-table">
  <table class="table no-rollover">
    <thead>
      <tr>
        <th></th>
        <th></th>
        <th>
          <a id="CustomerModal_A_SortByName" role="button" href="javascript:void(0)" (click)="sortDataBy('addressNumber');">
            Customer code
            <span [hidden]="sortBy != 'addressNumber'" class="fas" [ngClass]="{'fa-caret-up': sortBy == 'addressNumber' && sortAsc, 'fa-caret-down': sortBy == 'addressNumber' && !sortAsc}">
            </span>
          </a>
        </th>
        <th>
          <a id="CustomerModal_A_SortByAddress" role="button" href="javascript:void(0)" (click)="sortDataBy('city');">
            Address
            <span [hidden]="sortBy != 'city'" class="fas" [ngClass]="{'fa-caret-up': sortBy == 'city' && sortAsc, 'fa-caret-down': sortBy == 'city' && !sortAsc}">
            </span>
          </a>
        </th>
        <th style="white-space: nowrap">
          <a id="CustomerModal_A_SortBySalesPersonNumber" role="button" href="javascript:void(0)" (click)="sortDataBy('accountManager');">
            Salesperson#
            <span [hidden]="sortBy != 'accountManager'" class="fas" [ngClass]="{'fa-caret-up': sortBy == 'accountManager'&& sortAsc, 'fa-caret-down': sortBy == 'accountManager' && !sortAsc}">
            </span>
          </a>
        </th>
        <th>
          <a id="CustomerModal_A_SortByMSCAccountNumber" role="button" href="javascript:void(0)" (click)="sortDataBy('mscAccountNumber');">
            MSC Acct#
            <span [hidden]="sortBy != 'mscAccountNumber'" class="fas" [ngClass]="{'fa-caret-up': sortBy == 'mscAccountNumber'&& sortAsc, 'fa-caret-down': sortBy == 'mscAccountNumber' && !sortAsc}">
            </span>
          </a>
        </th>
        <th style="white-space: nowrap">
          &nbsp;
        </th>
      </tr>
    </thead>
    <tbody id="CustomerModal_TBody_CustomerRow_{{i}}" *ngFor="let customer of pagedAdvancedCustomers; let i = index">
      <tr id="CustomerModal_TR_CustomerRow_{{i}}" [ngClass]="{'disabledresult':customer.payerNotValidInBranch, 'clickable': !customer.payerNotValidInBranch}" (click)="onCustomerSelect(customer, customers, $event)">
        <td>
          <input id="CustomerModal__radio_customerModalSelectedCustomer_{{i}}"
            type="radio"
            name="selectedAddressNumber"
            [value]="customer.addressNumber"
            [ngModel]="selectedAddressNumber"
            (ngModelChange)="onCustomerSelect(customer, customers, $event )"
          >
          <label for="CustomerModal__radio_customerModalSelectedCustomer_{{i}}"></label>
        </td>
        <td>
          <div title="Payer is not valid for this branch" *ngIf="customer.payerNotValidInBranch">
            <i id="CustomerModal_I_PayerNotValidWarningIcon_{{i}}" class="fas fa-exclamation-triangle text-warning" aria-hidden="true"></i>
          </div>
        </td>
        <td>
            <div id="CustomerModal_Div_CustomerName_{{i}}" *ngIf="customer.customerName | trim">{{customer.customerName}}</div>
            <div id="CustomerModal_Div_CustomerName2_{{i}}" *ngIf="customer.customerName2 | trim">{{customer.customerName2}}</div>
            <div id="CustomerModal_Div_CustomerNumber_{{i}}" *ngIf="customer.addressNumber | trim">{{customer.addressNumber | customerNumber}}</div>
        </td>
        <td>
          <div id="CustomerModal_Div_CustomerStreetAddress_{{i}}" *ngIf="customer.streetAddress | trim">{{customer.streetAddress}}</div>
          <div id="CustomerModal_Div_CustomerCity_{{i}}" *ngIf="(customer.city | trim) || (customer.state | trim) || (customer.postalCode | trim)">{{[([customer.city, customer.state] | join:', '), customer.postalCode] | join:' '}}</div>
          <div id="CustomerModal_Div_CustomerPhoneNumber_{{i}}" *ngIf="customer.phoneNumber | trim">{{customer.phoneNumber}}</div>
        </td>
        <td>
          <div id="CustomerModal_Div_AccountManagerName_{{i}}" *ngIf="customer.accountManagerName">{{customer.accountManagerName}}</div>
          <div id="CustomerModal_Div_AccountManagerNumber_{{i}}" *ngIf="customer.accountManager">{{customer.accountManager}}</div>
        </td>
        <td id="CustomerModal_TD_MSCAccountNumber_{{i}}">{{customer.mscAccountNumber}}</td>
      </tr>
    </tbody>
  </table>
</div>
<div>Note : The symbol (<i class="fas fa-exclamation-triangle text-warning" aria-hidden="true"></i>) represents that payer number is invalid in this branch.</div>
</div>
<div class="modal-footer">
<nav id="CustomerModal_Nav_Navigation" class="d-flex justify-content-end align-items-center" aria-label="Page navigation" *ngIf="pager.pages && pager.pages.length">
  <div>
    <span class="pagination-result">Showing {{pager.startIndex + 1}} - {{pager.endIndex + 1}} of {{pager.totalItems}} Results</span>
  </div>
  <div>
    <ul class="pagination">
      <li class="page-item" [ngClass]="{disabled:pager.currentPage == 1}">
        <a id="CustomerModal_A_FirstPage" class="page-link" aria-label="First" (click)="setPage(1)"><span aria-hidden="true">&laquo;</span></a>
      </li>
      <li class="page-item" [ngClass]="{disabled:pager.currentPage == 1}">
        <a id="CustomerModal_A_PreviousPage" class="page-link" aria-label="Previous" (click)="setPage(pager.currentPage - 1)"><span aria-hidden="true">&lsaquo;</span></a>
      </li>
      <li *ngFor="let page of pager.pages" class="page-item" [ngClass]="{active:pager.currentPage == page}">
        <a id="CustomerModal_A_CurrentPage" class="page-link" (click)="setPage(page)">{{page}}</a>
      </li>
      <li class="page-item" [ngClass]="{disabled:pager.currentPage == pager.totalPages}">
        <a id="CustomerModal_A_NextPage" class="page-link" aria-label="Next" (click)="setPage(pager.currentPage + 1)"><span aria-hidden="true">&rsaquo;</span></a>
      </li>
      <li class="page-item" [ngClass]="{disabled:pager.currentPage == pager.totalPages}">
        <a id="CustomerModal_A_LastPage" class="page-link" aria-label="Last" (click)="setPage(pager.totalPages)"><span aria-hidden="true">&raquo;</span></a>
      </li>
    </ul>
  </div>
</nav>
</div>
<div class="d-flex justify-content-center mt-3 mb-4">
<button id="CustomerModal_ShippingAddressSelected" class="btn btn-primary" type="button" [disabled]="!selectedCustomer" (click)="onCustomerConfirm()">
  Select address
</button>
<button id="CustomerModal_ShippingAddressCancel" class="btn hyperlink" type="button" (click)="closeModal()">
  Cancel
</button>
</div>
