<div id="Notes" *ngLet="{
  notes: notes$ | async,
  loading: loading$ | async,
  user: user$ | async,
  messages: config.listenForNewMessages && messages$ | async,
  editBackcounterRequestNotes: editBackcounterRequestNotes$ | async
} as vm">
  <div class="d-flex flex-column m-3 mb-0">
    <div class="title-of-box-hold mb-2 mt-1">
      <div class="title-of-box pull-left">{{ title }}</div>
    </div>
    <div class="col md-1 pull-left" *ngIf="vm.loading">
      <div id="FavoritePartsModal_Div_LoadingFavoriteParts">
        Loading notes...<i class="fas fa-spinner fa-pulse"></i>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div id="Notes_div_Note_{{i}}" *ngFor="let note of vm.notes; let i = index"
      class="mb-2 pb-1 border-bottom d-flex justify-content-between align-items-baseline">
      <ng-container >
        <ng-container *ngTemplateOutlet="showNote; context: {note: note, vm: vm}"></ng-container>
      </ng-container>
    </div>
    <div *ngIf="vm.editBackcounterRequestNotes && config.editingEnabled">
      <ng-container *ngTemplateOutlet="addNoteForm; context: {vm: vm}" ></ng-container>
    </div>
  </div>
  <div class="modal-footer">
    <button id="btnCloseNotes" type="button" *ngIf="closeBtn" class="btn btn-secondary" (click)="closeModal()">
      Close
    </button>
  </div>
</div>

<ng-template #showNote
  let-note="note"
  let-vm="vm">
  <div>
    <ng-container *ngIf="notForRelease">
      <div *ngIf="note.utcTimestamp; else date">
        {{note.utcTimestamp | dateFormat: 'MM/DD/YYYY hh:mm:ss A': 'MM/DD/YYYY hh:mm A': ''}}
      </div>
    </ng-container>
    <ng-container *ngIf="!notForRelease">
      <div *ngIf="note.utcTimestamp; else date">
        {{note.utcTimestamp | dateFormat: 'YYYYMMDDhhmmss': 'MM/DD/YYYY hh:mm A': ''}}
      </div>
    </ng-container>
    <ng-template #date>
      {{note.wdate}} {{note.wtime}}
    </ng-template>
    <div style="background-color: #eee;" class="p-1">{{note.notetext}}</div>
    <div>Submitted by: {{ note.techname ? note.techname : note.userid }} {{ note.email | emailToUserName | uppercase }}</div>
  </div>
</ng-template>


<ng-template #addNoteForm
  let-vm="vm">
  <form id="notes_form_CreateNotes" #ngForm="ngForm" (ngSubmit)="createNote(ngForm.form, vm.user?.userName)" class="p-3"
    style="border: 1px solid #ddd">
    <fieldset>
      <div class="input-group input-group-sm">
        <input id="notes_text_NoteText" type="text" class="form-control" name="description" placeholder="Add a Note"
          maxlength="10000"
          ngModel required autofocus>
        <span class="input-group-text">
          <i class="far fa-comment" aria-hidden="true"></i>
        </span>
        <button id="notes_button_AddNoteSubmit" type="submit" class="btn btn-secondary"
          [disabled]="!ngForm.form.dirty || !ngForm.form.valid">
          Add Note
        </button>
      </div>
    </fieldset>
  </form>
</ng-template>
