import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SimulateTransferOrderRequest } from "entities/simulate-transfer-order/simulate-transfer-order-request";
import { BaseService } from "./base.service";
import { Observable } from 'rxjs';
import { BasketAddToCartRequest } from 'entities/basket-item-to-add-to-cart';
import { CartResult } from 'entities/cart-result';
import { SimulateTransferOrderResponseItem } from "entities/simulate-transfer-order/simulate-transfer-order-response-item";
import { BasketItem } from "entities/baskets/basket-item";

@Injectable({
  providedIn: "root",
})
export class BasketService extends BaseService {
  private readonly basketsUrl: string = "api/shoppingbaskets/";
  private readonly cartUrl: string = "api/cart/";

  constructor(private httpClient: HttpClient) {
    super();
  }

  getBaskets(branchCode): Observable<{[key: string]: BasketItem[]}> {
    return this.httpClient.get<{[key: string]: any[]}>(`${this.basketsUrl}?branchCode=${branchCode}`);
  }

  addSelectedBasketItemsToCart(basketToCartReq: BasketAddToCartRequest): Observable<CartResult> {
    return this.httpClient.put<CartResult>(`${this.cartUrl}basketitems/add`, basketToCartReq);
  }

  simulateTransferOrder(request: SimulateTransferOrderRequest): Observable<Record<string, SimulateTransferOrderResponseItem[]>> {
    return this.httpClient.post<any>(`${this.basketsUrl}transferorder/simulate`, request);
  }
}
