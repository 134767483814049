import { createAction, props } from "@ngrx/store";
import { Basket } from "entities/basket";
import { BasketLineItem } from "entities/basket-line-item";
import { Branch } from "entities/branch";
import { BranchState } from "./branch.state";

export const getBranches = createAction(
  "[Branch] Load Branches"
);

export const getBranchesSuccess = createAction(
  "[Branch] Load Branches Success",
  props<{branches: Branch[]}>()
);

export const getBranchesFailed = createAction(
  "[Branch] Load Branches Failed",
  props<{error: any;}>()
);

export const selectBranch = createAction(
  "[Branch] Select Branch",
  props<{branch: Branch; isPreventSelectCart?: boolean;}>()
);

export const selectBranchForQuote = createAction(
  "[Branch] Select Branch For Quote",
  props<{branch: Branch; isPreventSelectCart?: boolean;}>()
);

export const cacheBranchData = createAction(
  "[Branch] Cache Branch"
)

export const loadCachedStateSuccess = createAction(
  "[Branch] Load Branch State From Cache",
  props<{branchState: BranchState}>()
);

export const setDefaultBranch = createAction(
  "[Branch] Set Default Branch From Configuration"
);
