import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Part } from 'entities/part';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'store/app-state';
import * as CartSelectors from 'store/cart/cart.selectors';
import * as CartActions from 'store/cart/cart.actions';

@Component({
  selector: 'part-card-footer',
  templateUrl: './part-card-footer.component.html'
})
export class PartCardFooterComponent implements OnDestroy {
  @Input() idSuffix: string = "";
  @Input() part: Part;
  @Output() addedToCart: EventEmitter<Part> = new EventEmitter();
  private subscription: Subscription;
  isQuote$ = this.store.select(CartSelectors.selectIsQuote);
  constructor(private modalService: NgbModal,
              private store: Store<AppState>) { }

  openNationalInventory(part: Part) {
    this.store.dispatch(CartActions.showNationalInventoryModal({ part }));
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
}
