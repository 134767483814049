import { NgbDateParserFormatter, NgbDateStruct, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('/');
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return {month: toInteger(dateParts[0]), day: null, year: null};
      } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
        return {month: toInteger(dateParts[0]), day: toInteger(dateParts[1]), year: null};
      } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
        return {month: toInteger(dateParts[0]), day: toInteger(dateParts[1]), year: toInteger(dateParts[2])};
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    return date ?
        `${isNumber(date.month) ? padNumber(date.month) : ''}/${isNumber(date.day) ? padNumber(date.day) : ''}/${date.year}` :
        '';
  }
}

@Injectable()
export class JsDateAdapter extends NgbDateAdapter<Date> {

  fromModel(value: Date): NgbDateStruct {
    if (value && typeof(value) === "object") {
      // Delimit date and time, disregard time
      return {
        year : value.getUTCFullYear(),
        month : value.getUTCMonth() + 1,
        day : value.getUTCDate()
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct): Date {
    if (date) {
      return new Date(Date.UTC(date.year, date.month - 1, date.day, 0, 0, 0, 0));
    }
    return null;
  }
}

function isNumber(value: any): value is number {
    return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }

function padNumber(value: number) {
    if (isNumber(value)) {
      return `0${value}`.slice(-2);
    } else {
      return '';
    }
}
