import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ConfigurationService } from 'services/configuration.service';
import { BaseService } from 'services/base.service';
import { CustomerNotes, CustomerSearch, MscPayerSearch, MscPayerSearchResult } from 'entities/customer-search';
import { Customer } from "entities/customer";
import { CreateFavouriteCustomerRequest } from "entities/create-favourite-customer-request";
import { ErrorInformation } from "entities/error-information";
import { FavouriteCustomer } from "entities/favourite-customer";
import { LoggerService, SourceLocationType } from './logger.service';
import { AppInsightCustomerSearchTerm } from 'entities/app-insight-customer-search-term';
import { CustomerNumberPipe } from 'pipes';

@Injectable({
  providedIn: "root"
})
export class CustomerService extends BaseService {

  constructor(
    private http: HttpClient,
    private loggerService: LoggerService,
    private configurationService: ConfigurationService) {
    super();
  }

  validateCustomer(customerId: string, branchCode: string): Promise<{ isValid: boolean, customer: Customer}> {
    let url = `api/customer/validate?customerId=${customerId}&branchCode=${branchCode}`;
    return this.http.get<{ isValid: boolean, customer: Customer}>(url)
      .toPromise()
      .then((response:any) => {
        return response || {};
      })
      .catch(this.handleError);
  }

  getCustomers(customerSearch: CustomerSearch): Promise<Customer[] & ErrorInformation> {
    let url: string = '';

    const queryParameters = Object.keys(customerSearch)
      .map((key): [string, string[]] => {
        const value = customerSearch[key];
        return [key, Array.isArray(value) ? value : [value]];
      })
      .map(([key, values]) => values.map((value) => `${key}=${encodeURIComponent(value)}`))
      .reduce((acc, curr) => acc.concat(curr), [])
      .join("&");

    url = `api/customers/GetCustomers?${queryParameters}`;

    return this.http.get<Customer[] & ErrorInformation>(url)
      .toPromise()
      .catch(this.handleError);
  }

  getFavoriteCustomers() {
    return this.http.get<Customer[]>(`api/FavoriteCustomers`);
  }

  loadCustomer(customerNumber: string, branchCode?: string) : Observable<Customer> {
    if (!customerNumber || !customerNumber.length) {
      return EMPTY;
    }
    const request = this.convertToCustomerSearch(customerNumber, branchCode);
    return this.searchCustomers(request).pipe(
      map(customers => customers[0])
    );
  }

  searchCustomers(customerSearch: CustomerSearch): Observable<Customer[]> {
    let url: string = '';

    const queryParameters = Object.keys(customerSearch)
      .map((key): [string, string[]] => {
        const value = customerSearch[key];
        return [key, Array.isArray(value) ? value : [value]];
      })
      .map(([key, values]) => values.map((value) => `${key}=${encodeURIComponent(value)}`))
      .reduce((acc, curr) => acc.concat(curr), [])
      .join("&");

    url = `api/customers/GetCustomers?${queryParameters}`;

    return this.http.get<Customer[]>(url, this.options);
  }

  getMscPayers(mscPayerSearch: MscPayerSearch): Observable<MscPayerSearchResult> {
    let url: string = `api/customers/GetMscPayers?customerNumber=${mscPayerSearch.CustomerNumber}&branchCode=${mscPayerSearch.BranchCode}&salesOrganization=${mscPayerSearch.SalesOrganization}&includeShipTo=${mscPayerSearch.IncludeShipTo}`;
    if (mscPayerSearch.AccountGroups != null) {
      for (let accountGroup of mscPayerSearch.AccountGroups) {
        url += `&accountGroups=${accountGroup}`;
      }
    }
    return this.http.get<MscPayerSearchResult>(url);
  }

  getCustomerNotes(customerNumber): Observable<CustomerNotes> {
    let url: string = `api/Customers/GetCustomerNotes?customerNumber=${customerNumber}`;
    return this.http.get<CustomerNotes>(url);
  }

  CreateFavouriteCustomer(customerNumber: string, branchCode: string) {
    return this.http.put<Customer>('api/FavoriteCustomers/Create', { customerNumber, branchCode });
  }

  DeleteFavouriteCustomer(customerNumber: string, branchCode: string) {
    return this.http.put<string>('api/FavoriteCustomers/Delete', { customerNumber, branchCode });
  }

  convertToCustomerSearch(customerNumber: string, branchCode?: string): CustomerSearch {
    return {
      customerType: 'AG',
      customerNumber: customerNumber,
      branchCode: branchCode || "",
      accountGroups: ['Z001', 'Z002']
    };
  }

  getSourceLocationType(customerNumber: string, parentSource: string): string {
    let source = '';

    if (customerNumber) {
      if (SourceLocationType[parentSource] === SourceLocationType[SourceLocationType.PartSearch]) {
          source = SourceLocationType[SourceLocationType.PartSearch_CustomerSearch];
      } else {
          source = SourceLocationType[SourceLocationType.HeaderSearch_CustomerSearch];
      }
    } else {
      if (SourceLocationType[parentSource] === SourceLocationType[SourceLocationType.PartSearch]) {
          source = SourceLocationType[SourceLocationType.PartSearch_AdvancedCustomerSearch];
      } else {
          source = SourceLocationType[SourceLocationType.HeaderSearch_AdvancedCustomerSearch];
      }
    }

    return source;
  }
}
