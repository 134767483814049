import { Action, createReducer, on } from '@ngrx/store';
import { CartResultLineItemSelection } from 'entities/cart-result';
import * as CartActions from '../cart/cart.actions';
import { MyCartState } from './my-cart-state';
import * as MyCartActions from './my-cart.actions';

export const initialState: MyCartState = {
  cartItems: [],
  fixedVendor: false,
  hotFlags: [],
  autoSelectQuote: false,
  inboundFreight: null
};

export const myCartReducer = createReducer<MyCartState, Action>(
  initialState,
  on(CartActions.selectOpenCart, (state, {autoSelectQuote}) => ({...state, autoSelectQuote: autoSelectQuote?? false})),
  on(MyCartActions.getCartDataSuccess, (state, {cartItems}) => {
    const selectedCartItems = state.cartItems?.filter(x => x.selected);
    const initialCartItemSelections: any[] = cartItems?.map(x => {
      const cartItem = selectedCartItems.find(selectedItem => selectedItem.cartItemId === x.cartItemId);
      const selected = cartItem?.selected;
      const inventory = cartItem?.inventory;
      return ({...x, selected, inventory});
    });
    const autoSelectQuote = initialCartItemSelections.length ? state.autoSelectQuote : false;
    return ({...state, cartItems: initialCartItemSelections || [], autoSelectQuote: autoSelectQuote });
  }),
  on(MyCartActions.selectAllItems, (state) => ({ ...state, cartItems: state.cartItems.map(x => ({...x, selected: true, branchCode: ''})) })),
  on(MyCartActions.unSelectAllItems, (state) => ({ ...state, cartItems: state.cartItems.map(x => ({...x, selected: false, branchCode: ''})) })),
  on(MyCartActions.getInventorySuccess, (state, { cartItemsWithInventory }: { cartItemsWithInventory: CartResultLineItemSelection[] }) =>
    ({
      ...state,
      cartItems: state.cartItems.map(cartItem => cartItemsWithInventory.find(ci => ci.cartItemId === cartItem.cartItemId) ?? cartItem)
    })
  ),
  on(MyCartActions.getHotFlagsSuccess, (state, {hotFlags}) => ({...state, hotFlags})),
  on(MyCartActions.getVendorPricesSuccess, (state, {cartItemsWithVendorPrices}) => ({...state, cartItems: [...cartItemsWithVendorPrices, ...state.cartItems.filter(x => !x.selected)]})),
  on(MyCartActions.searchVendorSuccess,
    (state, {partNumber, vendorCode, vendorName}) =>
      {
        const newItems = state.cartItems.map(cartItem => {
          if(cartItem.partNumber === partNumber){
            return {...cartItem, vendorCode, vendorName, isDefaultVendorSelected: cartItem.isDefaultVendorSelected == null? true: false};
          }
          return {...cartItem}
        });
        return ({...state, cartItems: [...newItems]});
      }),
  on(MyCartActions.toggleSelection, (state, {cartItemId}) => {
    return {...state, cartItems: state.cartItems.map(
      cartItem => {
        if(cartItem.cartItemId === cartItemId){
          return {...cartItem, selected: !cartItem.selected, branchCode: '' }
        } else {
          return {...cartItem, branchCode: '' }
        }
        return cartItem;
      }
    )};
  }),
  on(MyCartActions.updateBuyoutCartItem, (state, {cartItem}) => {
    return {...state, cartItems: state.cartItems.map(
      ci => {
        if(ci.cartItemId === cartItem.cartItemId){
          return {...ci, knownPartPrice: cartItem.knownPartPrice, knownPartBuyoutAmount: cartItem.knownPartBuyoutAmount }
        }
        return ci;
      }
    )};
  }),
  on(MyCartActions.updateCartItemHotFlag, (state, {cartItemId, hotFlagCode}) => {
    const cartItems = state.cartItems.map(x => {
      if(cartItemId === x.cartItemId){
        return {...x, hotFlagCode: hotFlagCode};
      }
      return x;
    });
    return ({...state, cartItems});
  }),
  on(CartActions.getFreightAndAddToCartSuccess, (state, {cart}) => ({...state, inboundFreight: cart.lineItems.find(x => x.partNumber === "FREIGHT")}))
);
