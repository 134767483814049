import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Pager } from 'entities/pager';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styles: []
})
export class PaginationComponent implements OnInit {

  @Input() idPrefix: string = "";
  @Output() currentPageChange: EventEmitter<number> = new EventEmitter();
  @Input() pageSize: number = 20;
  @Input('totalCount') totalCount$: Observable<number>;
  pager$: Observable<Pager>;
  @Input() currentPage: number = 1;

  constructor() { }

  ngOnInit() {
    this.pager$ = this.totalCount$
      .pipe(
        map((count: number) => {
          return new Pager(count, this.currentPage, this.pageSize);
        })
      );
  }

  setPage(pageNumber: number) {
    this.currentPage = pageNumber;
    this.currentPageChange.emit(pageNumber);
  }
}
