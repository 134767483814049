import { Injectable } from '@angular/core';
import { Pager } from "entities/pager";

@Injectable({
  providedIn: "root"
})
export class TableService {

  constructor() { }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10): Pager {
    return new Pager(totalItems, currentPage, pageSize);
  }
}
