import { Action, createReducer, on } from "@ngrx/store";
import {
  provincesLoad,
  provincesLoadSuccess
} from "./province.actions";
import { ProvinceState } from "./province.state";

export const initialState: ProvinceState = {
  loading: false,
  provinceList: [],
  provinceLoaded: false,
};


export const provinceReducer = createReducer<ProvinceState, Action>(
  initialState,
  on(provincesLoad, (state, { countryCode }) => ({
    ...state,
    loading: true,
    countryCode,
  })),
  on(provincesLoadSuccess, (state, { provinces }) => ({
    ...state,
    loading: false,
    provinceLoaded: true,
    provinceList: provinces,
  }))
);
