import { Injectable, EventEmitter } from '@angular/core';
import { BaseService } from 'services/base.service';
import { CommonDataService } from 'services/common-data.service';
import { HttpClient } from '@angular/common/http';
import { ErrorInformation } from 'entities/error-information';
import { PartVendorPurchasePriceResult } from 'entities/parts/part-vendor-purchase-price-result';
import { Observable } from 'rxjs';
import { VendorPartPriceResult } from 'entities/hot-flag/get-vendor-part-prices-result';
import { AddVendorFavoriteRequest } from 'entities/add-favorite-vendor-request';
import { GetVendorResponseItem } from 'entities/get-vendor';

@Injectable({
  providedIn: "root"
})
export class VendorService extends BaseService {
  public notifyFavouriteVendorChangeEventEmitter: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private commonDataService: CommonDataService) {
    super();
  }

  public notifyFavouriteVendorChange(data: any) {
    if (data) {
      this.notifyFavouriteVendorChangeEventEmitter.emit(data);
    }
  }

  getVendors(vendorNumber: string, name: string, name2: string, address: string, city: string, state: string, zipCode: string, phone: string, branch: string): Promise<GetVendorResponseItem[] & ErrorInformation> {
    var searchRequest = {
      number: vendorNumber,
      name, name2,
      address, city, state, zipCode,
      phone, branch
    };
    return this.http.post<GetVendorResponseItem[] & ErrorInformation>('api/vendors/search', searchRequest)
      .toPromise()
      .catch(this.handleError);
  }

  getVendor(vendorNumber: string, branch: string) {
    var searchRequest = {
      number: vendorNumber,
      branch,
      name: "",
      name2: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      phone: ""
    };
    return this.http.post<GetVendorResponseItem[]>('api/vendors/search', searchRequest);
  }

  getFavouriteVendors(): Promise<any[] & ErrorInformation> {
    let url: string = `api/vendors/favorites`;
    return this.http.get<any[] & ErrorInformation>(url)
      .toPromise()
      .catch(this.handleError);
  }

  createFavouriteVendors(addFavoriteVendorRequest: AddVendorFavoriteRequest): Promise<ErrorInformation> {
    return this.http.post<ErrorInformation>(`api/vendors/favorites`, addFavoriteVendorRequest)
      .toPromise()
      .catch(this.handleError);
  }

  deleteFavouriteVendors(vendorNumber: string): Promise<{ errorMessage?: { message: string, type: any } } & ErrorInformation> {
    return this.http.delete<{ errorMessage?: { message: string, type: any } } & ErrorInformation>(`api/vendors/favorites/${vendorNumber}`, this.options)
      .toPromise()
      .catch(this.handleError);
  }

  getVendorPrice(vendorCode: string, corePartNumber: string, hasCore: boolean, partNumber: string, branchCode: string): Observable<PartVendorPurchasePriceResult> {
    let url: string = `api/vendors/getVendorPrice?vendorCode=${vendorCode}&branchCode=${branchCode}&corePartNumber=${corePartNumber}&hasCore=${hasCore}&partNumber=${partNumber}`;
    return this.http.get<PartVendorPurchasePriceResult>(url);
  }

  getPartVendorPrice(PartVendorPriceRequest: any): Promise<any> {
    let url: string = 'api/vendors/getVendorPrice';
    return this.http.put(url, PartVendorPriceRequest, this.options)
      .toPromise()
      .catch(this.handleError);
  }

  getPartVendorPriceObs(PartVendorPriceRequest: any) {
    let url: string = 'api/vendors/getVendorPrice';
    return this.http.put<VendorPartPriceResult[]>(url, PartVendorPriceRequest, this.options);
  }

  validateVendor(favourite: any, branchCode: string): Promise<any> {
    let url = `api/vendors/validate/${favourite.number}/${branchCode}`;
    return this.http.get(url)
      .toPromise()
      .catch(this.handleError);
  }
}
