import { Component, Input } from "@angular/core";
import { ColumnName, createOrderItemColumnClass } from "helpers/order-item-column-creator";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ConfigurationService } from "services/configuration.service";

@Component({
  selector: "order-item-header",
  templateUrl: "./order-item-header.component.html",
  styles: [],
})
export class OrderItemHeaderComponent {
  @Input() public idSuffix: string;
  @Input() public showCouponColumn: boolean;
  @Input() public showHotFlagColumn: boolean;

  public isInternalUser$: Observable<boolean> =
    this.configurationService.user$.pipe(map((user) => user.isInternal));

  constructor(private configurationService: ConfigurationService) {}

  createColumnClass(name: ColumnName, isInternalUser: boolean) {
    return createOrderItemColumnClass(name, isInternalUser, this.showCouponColumn, this.showHotFlagColumn);
  }
}
