import { createAction, props } from '@ngrx/store';
import { Branch } from 'entities/branch';
import { Customer } from 'entities/customer';

export const selectCustomer = createAction(
  '[Header Search Component] Select Customer', props<{ customer: Customer }>()
);

export const selectBranch = createAction(
  '[Header Search Component] Select Branch', props<{ branch: Branch }>()
);

export const selectDefaultCustomer = createAction(
  '[Header Search Component] Select Default Customer'
);