import { createAction, props } from "@ngrx/store";
import { Province } from "entities/province";

export const provincesLoad = createAction(
  "[Province] Load Provinces",
  props<{ countryCode: string }>()
);

export const provincesLoadSuccess = createAction(
  "[Province] Load Provinces Success",
  props<{ provinces: Province[] }>()
);

export const provincesLoadFailure = createAction(
  "[Province] Load Provinces Failure",
  props<{ error: any }>()
);