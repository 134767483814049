<div *ngLet="{
    loading: loading$ | async,
    openCarts: openCarts$ | async,
    filteredCarts: filteredCarts$ | async,
    selectedCustomer: selectedCustomer$ | async
  } as vm" class="open-cart">
  <div class="title-of-box-hold">
    <h5 class="strong">Open Carts</h5>
    <h5 id="openCart_h5_OpenCarts" class="strong">
      <a id="openCart_a_OpenCarts" class="hyperlink text-white" href="javascript:void(0)" (click)="createNewCart()" *ngIf="vm.selectedCustomer.customerNumber">New Cart</a>
    </h5>
  </div>
  <div id="openCart_div_ShowOpenCartLoader" class="form-group p-4" *ngIf="vm.loading">
    Loading open carts...
    <span class="countLoader"></span>
  </div>
  <div id="openCart_div_DoNotShowOpenCartLoader" *ngIf="!(vm.loading)" class="py-3">
    <div *ngIf="vm.openCarts.length" class="row g-0 ps-3 mb-2">
      <div class="col-6">
        <input id="openCart_checkbox_SearchCustomerFilter" type="checkbox" [(ngModel)]="filters.byCustomer"
          (ngModelChange)="updateCustomerFilter($event)">
        <label for="openCart_checkbox_SearchCustomerFilter">Search Customer</label>
      </div>
      <div class="col-6">
        <input id="openCart_checkbox_SearchBranchFilter" type="checkbox" [ngModel]="filters?.byBranch"
          (ngModelChange)="updateBranchFilter($event)">
        <label for="openCart_checkbox_SearchBranchFilter">Search Branch</label>
      </div>
    </div>
     <div id="openCart_div_NoOpenCarts" class="px-4" *ngIf="!vm.filteredCarts?.length">
      No records found.
    </div>
    <ng-container id="openCart_div_OpenCarts">

      <div *ngFor="let cart of vm.filteredCarts | slice: (currentPage-1) * pageSize : pageSize * currentPage; let i = index"
        class="open-cart-details">
        <cart-card [id]="'openCart_div_OpenCartDetails_' + i" [cart]="cart" (isSelectedChange)="goToCart(cart)"
          (deleteCart)="deleteCart(cart);" [isSelected]="selectedCartId === cart.cartId"></cart-card>
      </div>
      <div class="mt-3 justify-content-center" *ngIf="vm.filteredCarts?.length">
        <ngb-pagination [(page)]="currentPage" [pageSize]="pageSize" [collectionSize]="vm.filteredCarts.length">
        </ngb-pagination>
      </div>
    </ng-container>
  </div>
</div>
