import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonDataService } from 'services/common-data.service';
import { ConfigurationService } from 'services/configuration.service';
import { tap } from 'rxjs/operators';
import { PriceOverrideResult } from 'entities/price-override-result';
import { LoggerService } from "services/logger.service";
import { PartService } from 'services/part.service';
import { PriceAdjustmentRequest } from 'entities/price-adjustment';

@Component({
  selector: 'price-override-modal',
  templateUrl: './price-override-modal.component.html'
})
export class PriceOverrideModalComponent implements OnInit, OnDestroy {

  overridePrice: number = null;
  reason: number = null;
  competitor: string = '';
  warningMessage: string = '';
  managerRolesSubscription: any;
  entityPermissions: { [entityCode: string]: string[] };
  currentBranchManager: boolean = false;
  branchManagerOverride: boolean = false;
  @Input() part: any;

  constructor(
    private activeModal: NgbActiveModal,
    private commonDataService: CommonDataService,
    private partService: PartService,
    private configurationService: ConfigurationService,
    private loggerService: LoggerService
    ) { }

  ngOnInit() {

    if (this.commonDataService.Customer.pricingProcedure === "Legacy") {
      this.part.walkIn = this.part.yourPrice = this.part.listPrice;
    }

    this.managerRolesSubscription = this.configurationService.entityPermissions$
    .pipe(
      tap(entityPermissions => this.entityPermissions = entityPermissions)
      ).subscribe();
    this.loggerService.verbose('entity permissions: ', this.entityPermissions)
    this.loggerService.verbose("part: ", this.part);
    this.loggerService.verbose('current branch: ', this.commonDataService.Branch)

    if (this.entityPermissions.hasOwnProperty(this.commonDataService.Branch.code)) {
      this.loggerService.verbose('branch permissions: ', this.entityPermissions[this.commonDataService.Branch.code])
      if (this.entityPermissions[this.commonDataService.Branch.code].indexOf('Cost+Condition Price Override') > -1) {
        this.currentBranchManager = true;
        this.loggerService.verbose(this.currentBranchManager);
      } else {
        this.loggerService.verbose('manager privilages are not enabled for this branch');
        this.currentBranchManager = false;
      }
    }
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  adjustPrice() {
    this.loggerService.verbose(this.overridePrice, this.reason, this.competitor);

    const request: PriceAdjustmentRequest = {
      partNumber: this.part.rushPartNumber || this.part.partNumber,
      branchCode: this.commonDataService.Branch.code,
      customerNumber: this.commonDataService.Customer.customerNumber,
      adjustedPrice: this.overridePrice
    };
    if (this.part.sapCoupons) {
      this.partService.adjustPrice(request).pipe(
        tap((result) => {
          const overrideResult: PriceOverrideResult = {
            overridePrice: this.overridePrice,
            reason: this.reason,
            competitor: this.competitor,
            branchManagerOverride: this.branchManagerOverride,
            coupons: result.coupons
          };
          this.loggerService.verbose(overrideResult);
          this.activeModal.close(overrideResult);
        })
      ).subscribe();
    } else {
      const overrideResult: PriceOverrideResult = {
        overridePrice: this.overridePrice,
        reason: this.reason,
        competitor: this.competitor,
        branchManagerOverride: this.branchManagerOverride,
        coupons: null
      };
      this.loggerService.verbose(overrideResult);
      this.activeModal.close(overrideResult);
    }
  }

  changeOverridePrice(input: string) {
    this.loggerService.verbose("part details", this.part);
    const partNumber = this.part.number || this.part.partNumber;
    this.overridePrice = Number(input.replace("$", ""));
    if (this.overridePrice <= this.part.overrideFloorActive) {
      if (partNumber.substr(partNumber.length - 3) !== ':90' && this.currentBranchManager === true) {
        this.branchManagerOverride = true;
        this.warningMessage = 'Price Override is below allowable range, but you are approved to do it.';
      } else {
        this.warningMessage = 'Warning: Price Override is below allowable range.  ' +
                              'Parts Manager approval required.  ' +
                              'Goods movement and billing will be on hold until your Parts Manager approves this in SAP.';
      }
    } else if (this.overridePrice < this.part.overrideFloor && this.overridePrice > this.part.overrideFloorActive) {
      this.warningMessage = 'Warning: Price Override is below minimum system price.  Parts Manager will receive notification.';
    } else if (this.overridePrice < this.part.overrideTarget && this.overridePrice >= this.part.overrideFloor) {
      this.warningMessage = 'Warning: Price Override is below average of similar customers.';
    } else {
      this.warningMessage = '';
    }
  }

  changeReason(value) {
    this.reason = value;
    this.loggerService.verbose(this.reason);
  }

  ngOnDestroy() {
  }
}
