<!-- Known Part buyout Popup -->
<ng-container *ngLet="{
  fixedVendor: fixedVendor$ | async,
  currencyCode: currencyCode$ | async,
  currencySymbol: currencySymbol$ | async,
  firstPartWithFixedVendor: firstPartWithFixedVendor$ | async
} as vm">
<button id="knownPartBuyout_button_CloseModal" type="button" class="close" data-dismiss="modal" aria-label="Close" tabindex="-1" (click)="closeModal()">
  <span aria-hidden="true">&times;</span>
</button>
<div class="modal-header">
  <h4 class="modal-title"><i class="fas fa-truck" aria-hidden="true"></i> Parts Buyout</h4>
  <div class="input-group w-50 w-md-33">
    <input id="knownPartBuyout_text_SearchFavoriteId" type="text" class="form-control customer-search-textbox" [(ngModel)]=filterKey
      (keyup)="filter()" placeholder="Search...">
      <span class="input-group-text">
        <i class="fas fa-search" aria-hidden="true"></i>
      </span>
  </div>
</div>

<div class="modal-body">
  <div id="knownPartBuyout_div_Procurement" class="gy-2">
    <form id="knownPartBuyout_form_KnownPartBuyoutForm" #KnownPartBuyoutForm="ngForm">
      <div ngbAccordion #acc="ngbAccordion" class="accordion-sm gy-1" [closeOthers]="true">
        <div ngbAccordionItem="knowPartBuyout_panel_KnownPartBuyoutPanel_{{i}}"  #accItem="ngbAccordionItem" *ngFor="let cartItem of (filteredCartItems$ | async); trackBy: trackById; let i=index" [destroyOnHide]="false">
          <div ngbAccordionHeader class="bg-header">
            <button ngbAccordionToggle type="button" class="btn accordion-toggle">
              <div class="d-flex">
                <div class="px-2">
                  <i class='fa' [ngClass]="accItem.collapsed ? 'fa-plus' : 'fa-minus'"
                    aria-hidden="true"></i>
                </div>
                <div class="flex-grow-1 px-3 d-flex gx-3 text-start">
                  <div>
                    <div>
                      <b>Part Number:</b>
                    </div>
                    <div>{{cartItem.partNumber}}</div>
                  </div>
                  <div>
                    <div>
                      <b>Vendor:</b>
                    </div>
                    <div class="text-truncate" title="{{cartItem.vendorName}}">
                      {{cartItem.vendorName}}
                    </div>
                  </div>
                </div>
                <div class="ms-auto">
                  <button id="knowPartBuyout_button_RemoveCartItem_{{i}}" style="background: transparent; border: none !important;" role="button" (click)="removeCartItem(cartItem)">
                    <i class="fas fa-times text-danger" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div>
                  <div class="row">
                    <div class="col-md-12">
                      <a id="knowPartBuyout_a_EditVendor_{{i}}" role="button" *ngIf="!cartItem.showVendorPanel" class="hyperlink  pull-right" (click)="changeVendor(cartItem);"><i
                          class="fa fa-pencil" style="color:#333" aria-hidden="true"></i>Edit Vendor</a>
                      <a id="knowPartBuyout_a_Cancel_{{i}}" role="button" *ngIf="cartItem.showVendorPanel" class="hyperlink  pull-right" (click)="cartItem.showVendorPanel=false;">Cancel</a>
                    </div>
                  </div>
                  <div id="knownPartBuyout_div_ShowVendorPanel_{{i}}" class="row" *ngIf="cartItem.showVendorPanel">
                    <div class="col-md-12 pull-right">
                      <vendor-panel (vendorSelect)="onKnownPartVendorSelect($event)"></vendor-panel>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <b>Vendor Part Purchase Price </b>
                    </div>
                    <div class="col-sm-6">
                      <b>Customer Part Purchase Price </b>
                    </div>
                  </div>
                  <div class="row">
                    <div id="knowPartBuyout_div_KnownPartBuyoutAmount_{{i}}" class="col-sm-6 pt-1" *ngIf="cartItem.isDefaultVendorSelected">
                      <span class="ps-4">{{cartItem.knownPartBuyoutAmount | currency: vm.currencyCode :'symbol':'1.2-2'}}</span>
                    </div>
                    <div class="col-sm-6" *ngIf="!cartItem.isDefaultVendorSelected">
                      <div class="input-group">
                          <span class="input-group-text">{{ vm.currencySymbol }}</span>
                        <input id="knownPartBuyout_text_KnownPartBuyoutAmount_{{i}}" name="knownPartBuyout_text_KnownPartBuyoutAmount_{{i}}"
                          #txtKnownPartBuyoutAmount class="form-control " type="text" ng-minlength="1" [ngModel]="cartItem.knownPartBuyoutAmount| number: '1.2-2'"
                          (keypress)="knownPartPriceKeyPress($event)" (paste)="knownPartPriceOnPaste(cartItem, $event)"
                          (keyup)="ShowWarningMessage(cartItem)" (focusout)="knownPartBuyoutCorrectPrice(cartItem, txtKnownPartBuyoutAmount.value)"
                          maxlength="14">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="input-group">
                          <span class="input-group-text">{{ vm.currencySymbol }}</span>
                        <input id="knownPartBuyout_text_KnownPartPrice_{{i}}" name="knownPartBuyout_text_KnownPartPrice_{{i}}" #txtKnownPartPrice
                          class="form-control" type="text" ng-minlength="1.5" [ngModel]="cartItem.knownPartPrice | number: '1.2-2'"
                         (keypress)="knownPartPriceKeyPress($event)"
                          (paste)="knownPartPriceOnPaste(cartItem,$event)"
                          (keyup)="ShowWarningMessage(cartItem)"
                         (focusout)="knownPartCorrectPrice(cartItem, txtKnownPartPrice.value)"
                          maxlength="14">
                      </div>
                    </div>
                  </div>
                  <div id="knownPartBuyout_div_VendorCorePurchasePrice_{{i}}" class="row" *ngIf="cartItem.isPartBuyOutCorePart">
                    <div class="col-sm-6">
                      <b>Vendor Core Purchase Price </b>
                    </div>
                    <div class="col-sm-6">
                      <b>Customer Core Price </b>
                    </div>
                  </div>
                  <div>
                    <div></div>
                    <div></div>
                  </div>
                  <div id="knownPartBuyout_div_IsPartBuyoutCorePart_{{i}}" class="row" *ngIf="cartItem.isPartBuyOutCorePart">
                    <div id="knownPartBuyout_div_IsDefaultVendorSelected_{{i}}" class="col-sm-6" *ngIf="cartItem.isDefaultVendorSelected">
                      <span class="ps-4">{{cartItem.coreknownPartBuyoutAmount | currency: vm.currencyCode :'symbol':'1.2-2'}}</span>
                    </div>
                    <div id="knownPartBuyout_div_IsDefaultVendor_{{i}}" class="col-sm-6" *ngIf="!cartItem.isDefaultVendorSelected">
                      <div class="input-group">
                          <span class="input-group-text">{{ vm.currencySymbol }}</span>
                        <input id="knownPartBuyout_text_CoreKnownPartBuyoutAmount_{{i}}" name="knownPartBuyout_text_CoreKnownPartBuyoutAmount_{{i}}"
                          #txtCoreKnownPartBuyoutAmount class="form-control" ng-minlength="1" type="text" [ngModel]="cartItem.coreknownPartBuyoutAmount | number:'1.2-2'"
                          placeholder="Core Buyout Price" (keypress)="coreknownPartPriceKeyPress($event)" (keyup)="ShowWarningMessage(cartItem)"
                          (paste)="coreknownPartPriceOnPaste(cartItem, $event)" (focusout)="coreknownPartBuyoutCorrectPrice(cartItem, txtCoreKnownPartBuyoutAmount.value)"
                          maxlength="14">
                      </div>
                    </div>
                    <div id="knownPartBuyout_div_IsDefaultVendorSelected_{{i}}" class="col-sm-6" *ngIf="cartItem.isDefaultVendorSelected">
                      <span class="ps-4">{{cartItem.coreknownPartPrice | currency: vm.currencyCode :'symbol':'1.2-2'}}</span>
                    </div>
                    <div id="knownPartBuyout_div_DefaultVendorNotSelected_{{i}}" class="col-sm-6" *ngIf="!cartItem.isDefaultVendorSelected">
                      <div class="input-group">
                          <span class="input-group-text">{{ vm.currencySymbol }}</span>
                        <input id="knownPartBuyout_text_CorePrice_{{i}}" name="knownPartBuyout_text_CorePrice{{i}}"
                          #txtCoreknownPartPrice class="form-control" ng-minlength="1" type="text" [ngModel]="cartItem.coreknownPartPrice | number:'1.2-2'"
                          placeholder="Core Sales Price" (keypress)="coreknownPartPriceKeyPress($event)" (keyup)="ShowWarningMessage(cartItem)"
                          (paste)="customerCorePriceOnPaste(cartItem, $event)" (focusout)="customerCorePriceCorrectPrice(cartItem, txtCoreknownPartPrice.value)"
                          maxlength="14">
                      </div>
                    </div>
                  </div>
                </div>
                <div id="knownPartBuyout_div_IsBuyoutGreaterThanSales_{{i}}" class="row" *ngIf="(isBuyoutGreaterthanSales)">
                  <div class="pull-left full-width">
                    <span class="pull-left" style="width:6%"><i class="fas fa-exclamation-triangle text-danger"
                        aria-hidden="true"></i></span>
                    <span class="pull-left" style="width:94%"><b>Warning: The Buyout Amount is more than the
                        Sales Price</b></span>
                  </div>
                  <div class="pull-left full-width">
                    <input id="knownPartBuyout_checkbox_SalesPrice_{{i}}" name="knownPartBuyout_checkbox_SalesPrice_{{i}}"
                        #chkSalesPrice type="checkbox" [(ngModel)]="cartItem.continueWithLessSalesPrice" required />
                    <label for="knownPartBuyout_checkbox_SalesPrice_{{i}}">Yes, I realize that the Buyout Amount is more than
                      Sales Price and I wish to continue with this Parts Buyout.</label>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div>
      <div>
        <button id="knownPartBuyout_button_ConfirmPartsBuyout" type="button" class="btn yellow-btn w-100" [disabled]="!KnownPartBuyoutForm.form.valid || vm.fixedVendor==false"
          (click)="confirmPartsBuyout();">Confirm
          Parts Buyout</button>
      </div>
    </div>
    <div>
      <div [hidden]="vm.fixedVendor" class="alert alert-danger m-0">
        <div [hidden]="vm.fixedVendor">{{vm.firstPartWithFixedVendor}} cannot be procured, it does not have a fixed vendor. Send
          a request to PartAdds to add fixed vendor.</div>
      </div>
    </div>
  </div>
</div>
</ng-container>
<!-- end Known Part buyout -->
