<div class="form-group m-0"
  (click)="onSelectCart();"
  [ngClass]="{'highlighted': isSelected}"
  *ngLet="{currencyCode: currencyCode$ | async} as vm"
  >
  <div class="d-flex">
    <div class="px-3 my-auto">
        <div class="bg-primary position-relative ps-2 pt-1 pe-4 pb-3">
            <i class="text-dark fas fa-shopping-cart" style="font-size: 24px;"></i>
            <span class="position-absolute bg-danger text-white cart-count" style="right:4px; bottom:1px;">{{cart.cartItemCount}}</span>
        </div>
    </div>
    <div class="col-8 p-0">
        <div class="text-truncate">
          <strong>Customer&nbsp;Number:&nbsp;</strong>
            <span [id]="id + '_customer_number_title'" title="{{cart.customerNumber | customerNumber}}">{{cart.customerNumber | customerNumber}}</span>
        </div>
        <div class="text-truncate">
            <strong>Customer&nbsp;Name:&nbsp;</strong>
            <span [id]="id + '_customer_name_title'" title="{{cart.customerName}}">{{cart.customerName}}</span>
        </div>
        <div class="text-truncate">
            <strong>Branch:&nbsp;</strong>
            <span [id]="id + '_branch_title'" title="{{cart.branchCode}} - {{cart.branchName}}">{{cart.branchCode}} - {{cart.branchName}}</span>
        </div>
        <span style="padding-right:5px;min-width:90px;">
            <strong>Subtotal:&nbsp;</strong>{{cart.price || 0 | currency: vm.currencyCode :'symbol':'1.2-2'}}
        </span>
        <a class="hyperlink" href="javascript:void(0)" (click)="onDeleteCart($event);" id="{{id}}_delete"> Delete Cart</a>
    </div>
  </div>
</div>
