<button id="DeleteCartConfirmModal_Button_Close" type="button" class="close" data-dismiss="modal" aria-label="Close"
  tabindex="-1" (click)="cancel()">
  <span aria-hidden="true">&times;</span>
</button>
<div class="modal-wrapper" id="deleteCartConfirmModal">

  <div class="modal-header">
    <h4 class="modal-title"><i class="fas fa-exclamation-triangle text-danger"></i> Warning</h4>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to delete this cart?</p>
    <p>Press YES to continue or NO to cancel.</p>
  </div>
  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-secondary" (click)="cancel()">No</button>
    <button type="submit" class="btn text-light btn-danger" (click)="confirm()">
      Yes
    </button>
  </div>
</div>