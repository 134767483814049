import { Part } from "./part";

export interface FeaturedPart extends Part {
  customerNumber: string;
  amount: number;
  vendorCode: string;
  opportunityType: FeaturedOpportunityType;
  branch: string;
  code: string;
  couponId?: string;
}

export enum FeaturedOpportunityType {
  growth = "growth",
  recovery = "recovery"
}
