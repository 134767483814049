import { ErrorInformation } from 'entities/error-information';
import { Injectable } from '@angular/core';
import { LoggerService } from "./logger.service";
import { ToastType } from 'entities/toast-type';
import { ResultMessage, ResultMessageLevel } from 'entities/result-message';
@Injectable({
  providedIn: "root"
})
export class ToastService {
  public show: boolean = false;
  public shown: boolean = false;
  public type: ToastType;
  public message: any;
  public staticMessage: any;
  public showStatic: boolean = false;

  constructor(private loggerService: LoggerService) { }

  public showStaticToast(message: string, type: ToastType) {
    const arr: Array<{ message: string, type: ToastType }> = [{ message, type }];
    this.showStatic = true;
    this.type = type;
    this.staticMessage = arr;
  }

  public showMultilineStaticToast(messageArray: Array<{ message: string, type: ToastType }>) {
    this.showStatic = true;
    this.type = messageArray[0].type;
    this.staticMessage = messageArray.filter((item, index, arr) => arr.findIndex(a => a.message === item.message) === index);
  }

  public showToast(message: string, type: ToastType) {
    const arr: Array<{ message: string, type: ToastType }> = [{ message, type }];
    this.toastShown();
    this.show = true;
    this.type = type;
    this.message = arr;
    setTimeout(() => {
      this.show = false;
      this.hideToast();
    }, 10000);
  }

  public showMultilineToast(messageArray: Array<{ message: string, type: ToastType }>) {
    this.toastShown();
    this.show = true;
    this.message = messageArray.filter((item, index, arr) => arr.findIndex(a => a.message === item.message) === index);
    setTimeout(() => {
      this.show = false;
      this.hideToast();
    }, 10000);
  }

  public hideToast() {
    this.show = false;
    this.type = null;
    this.message = '';
    this.shown = false;
  }

  public hideStaticToast() {
    this.showStatic = false;
    this.type = null;
    this.message = '';
    this.shown = false;
  }

  errorMessage(componentName: string, componentFunctionName: string, serviceFunctionName: string, res: ErrorInformation) {
    const commonMsg: string = ' If you cannot continue with the order, please submit a feedback form.';
    switch (res && res.ErrorType) {
      case 0:
        this.showToast('Error has occurred while processing your request. Please contact your system administrator.', ToastType.Error);
        break;
      case 401:
        // this.showToast(res.Message + "  Please wait, system will re-authenticate the user and redirect to the current screen.", ToastType.Error);
        break;
      case 403:
        this.showToast(res.Message, ToastType.Error);
        break;
      default:
        switch (componentName + '_' + componentFunctionName + '_' + serviceFunctionName) {
          case 'BranchComponent_GetUserBranchesForOrdering_GetUserBranchesForOrdering':
            this.showToast('Error:1, An error occurred during the get branches request.' + commonMsg, ToastType.Error);
            break;
          case 'MyCartComponent_getDefaultBranchValue_CheckDefaultBranchValue':
            this.showToast('Error:2, An error occurred during the get default branch value request.' + commonMsg, ToastType.Error);
            break;
          case 'MyCartComponent_getCarts_getCarts':
            this.showToast('Error:3, An error occurred during the get carts request.' + commonMsg, ToastType.Error);
            break;
          case 'MyCartComponent_onPriceAdjustmentConfirmationYes_SetPrice':
            this.showToast('Error:4, An error occurred during the adjust price request.' + commonMsg, ToastType.Error);
            break;
          case 'MyCartComponent_removeCartItems_DeleteCartItem':
            this.showToast('Error:5, An error occurred during the remove cart item request.' + commonMsg, ToastType.Error);
            break;
          case 'MyCartComponent_updateCartItems_UpdateCartItemformycart':
            this.showToast('Error:6, An error occurred during the update cart item request.' + commonMsg, ToastType.Error);
            break;
          case 'CheckoutComponent_getCarts_getCarts':
            this.showToast('Error:7, An error occurred during the get carts request.' + commonMsg, ToastType.Error);
            break;
          case 'CheckoutComponent_getAlternateShippingCustomers_getCustomers':
            this.showToast('Error:8, An error occurred during the get alternate shipping addresses request.' + commonMsg, ToastType.Error);
            break;
          case 'CheckoutComponent_getBillingTypeCustomers_getCustomers':
            this.showToast("Error:9, An error occurred during the get customer's billing types request." + commonMsg, ToastType.Error);
            break;
          case 'CheckoutComponent_placeOrder_placeOrder':
            this.showToast('Error:10, An error occurred during the place order request.' + commonMsg, ToastType.Error);
            break;
          case 'CheckoutComponent_UpdateCartItem_UpdateCartItem':
            this.showToast('Error:11, An error occurred during the update cart item request.' + commonMsg, ToastType.Error);
            break;
          case 'CustomerPanelComponent_customerSearch_getCustomers':
            this.showToast('Error:12, An error occurred during the get customer request.' + commonMsg, ToastType.Error);
            break;
          case 'FeedbackComponent_sendFeedback_sendFeedback':
            this.showToast('Error:13, An error occurred during the send feedback request.' + commonMsg, ToastType.Error);
            break;
          case 'LoginComponent_getToken_getToken':
            this.showToast('Error:14, An error occurred during the get token request.' + commonMsg, ToastType.Error);
            break;
          case 'OrderConfirmationComponent_GetOrderConfirmation_getOrderConfirmationResult':
            this.showToast('Error:15, An error occurred during the get order confirmation result request.' + commonMsg, ToastType.Error);
            break;
          case 'PartAlternateRelatedComponent_addToCart_addToCart':
            this.showToast('Error:16, An error occurred during the add to cart request.' + commonMsg, ToastType.Error);
            break;
          case 'PartsDetailsComponent_getRelatedParts_getRelatedParts':
            this.showToast('Error:17, An error occurred during the get related parts request.' + commonMsg, ToastType.Error);
            break;
          case 'PartsDetailsComponent_getAlternateParts_getAlternateParts':
            this.showToast('Error:18, An error occurred during the get alternate parts request.' + commonMsg, ToastType.Error);
            break;
          case 'PartsDetailsComponent_getNotes_getNotes':
            this.showToast('Error:19, An error occurred during the get notes request.' + commonMsg, ToastType.Error);
            break;
          case 'PartNotesComponent_createNotes_createNotes':
            this.showToast('Error:20, An error occurred during the create note request.' + commonMsg, ToastType.Error);
            break;
          case 'PartsDetailsComponent_addToCart_addToCart':
            this.showToast('Error:21, An error occurred during the add to cart request.' + commonMsg, ToastType.Error);
            break;
          case 'NationalInventoryModalComponent_getInventory_getInventory':
            this.showToast('Error:22, An error occurred during the get national inventory request.' + commonMsg, ToastType.Error);
            break;
          case 'PartBaseComponent_getParts_getParts':
            this.showToast('Error:23, An error occurred during the get parts request.' + commonMsg, ToastType.Error);
            break;
          case 'HeaderSearchComponent_getCarts_getCarts':
            this.showToast('Error:24, An error occurred during the get carts request.' + commonMsg, ToastType.Error);
            break;
          case 'HeaderSearchComponent_removeCartItems_DeleteCartItem':
            this.showToast('Error:25, An error occurred during the remove cart item request.' + commonMsg, ToastType.Error);
            break;
          case 'HeaderSearchComponent_getCustomer_getCustomers':
            this.showToast('Error:26, An error occurred during get customers request.' + commonMsg, ToastType.Error);
            break;
          case 'FavouriteCustomersModalComponent_getFavourites_getFavourites':
            this.showToast('Error:27, An error occurred during the get favorites request.' + commonMsg, ToastType.Error);
            break;
          case 'FavouriteCustomersModalComponent_removeFavourites_DeleteFavourites':
            this.showToast('Error:28, An error occurred during the remove customer from favorite request.' + commonMsg, ToastType.Error);
            break;
          case 'UnknownPartBuyoutModalComponent_addToCart_addToCart':
            this.showToast('Error:29, An error occurred during the add part buyout to cart request.' + commonMsg, ToastType.Error);
            break;
          case 'PartsSearchComponent_getCartCount_CartCount':
            this.showToast('Error:30, An error occurred during the get cart count request.' + commonMsg, ToastType.Error);
            break;
          case 'PartsSearchComponent_addToFavourites_CreateFavourites':
            this.showToast('Error:31, An error occurred during the add to favorites request.' + commonMsg, ToastType.Error);
            break;
          case 'PartsSearchComponent_removeFavourites_DeleteFavourites':
            this.showToast('Error:32, An error occurred during the remove customer from favorite request.' + commonMsg, ToastType.Error);
            break;
          case 'PartsSearchComponent_partSearch_getPartCount':
            this.showToast('Error:33, An error occurred during the get part count request.' + commonMsg, ToastType.Error);
            break;
          case 'CheckoutComponent_getCustomerDetails_getCustomers':
            this.showToast("Error:34, An error occurred during the get customer's details request." + commonMsg, ToastType.Error);
            break;
          case 'CheckoutComponent_DeleteCartItem_DeleteCartItem':
            this.showToast('Error:35, An error occurred during the remove Freight/Delivery line request.' + commonMsg, ToastType.Error);
            break;
          case 'CheckoutComponent_addToCart_addToCart':
            this.showToast('Error:36, An error occurred during the add to cart request.' + commonMsg, ToastType.Error);
            break;
          case 'VendorComponent_vendorSearch_getVendors':
            this.showToast('Error:37, An error occurred during the get vendors request.' + commonMsg, ToastType.Error);
            break;
          case 'UnknownPartBuyoutModalComponent_removeFavouriteVendors_deleteFavouriteVendors':
            this.showToast('Error:38, An error occurred during the remove vendor from favorite request.' + commonMsg, ToastType.Error);
            break;
          case 'UnknownPartBuyoutModalComponent_addToFavouriteVendors_createFavouriteVendors':
            this.showToast('Error:39, An error occurred during the add to favorites request.' + commonMsg, ToastType.Error);
            break;
          case 'FavouriteVendorsModalComponent_getFavouriteVendors_getFavouriteVendors':
            this.showToast('Error:40, An error occurred during the get favorite vendors request.' + commonMsg, ToastType.Error);
            break;
          case 'FavouriteVendorsModalComponent_removeFavouriteVendors_deleteFavouriteVendors':
            this.showToast('Error:41, An error occurred during the remove vendor from favorite request.' + commonMsg, ToastType.Error);
            break;
          case 'NationalInventoryModalComponent_addToCart_getVendorPrice':
            this.showToast('Error:43, An error occurred during the get vendor price request.' + commonMsg, ToastType.Error);
            break;
          case 'NationalInventoryModalComponent_addToCart_addToCart':
            this.showToast('Error:44, An error occurred during the add to cart request.' + commonMsg, ToastType.Error);
            break;
          case 'NationalInventoryModalComponent_getParts_getParts':
            this.showToast('Error:45, An error occurred during the get parts request.' + commonMsg, ToastType.Error);
            break;
          case 'HeaderSearchComponent_partSearch_getPartCount':
            this.showToast('Error:46, An error occurred during the get part count request.' + commonMsg, ToastType.Error);
            break;
          case 'NationalInventoryModalComponent_addToCart_DeleteCartItem':
            this.showToast('Error:47, An error occurred during the remove cart item request.' + commonMsg, ToastType.Error);
            break;
          case 'OrderConfirmationComponent_downloadInvoice_downloadInvoice':
          case 'OrderInvoiceComponent_downloadInvoice_downloadInvoice':
            this.showToast('Error:48, An error occurred during the download invoice request.' + commonMsg, ToastType.Error);
            break;
          case 'OrderConfirmationComponent_getPickTicket_getPickTicket':
            this.showToast('Error:49, An error occurred during the get pick ticket request.' + commonMsg, ToastType.Error);
            break;
          case 'OrderConfirmationComponent_getPrintTicket_getPrintTicket':
            this.showToast('Error:50, An error occurred during the get print ticket request.' + commonMsg, ToastType.Error);
            break;
          case 'AppComponent_removeCarts_DeleteCart':
            this.showToast('Error:51, An error occurred during the remove cart request.' + commonMsg, ToastType.Error);
            break;
          case 'UserToastService_salesMetrics$_salesMetrics$':
            this.showToast('Error:52, An error occurred during the user sales metrics request.' + commonMsg, ToastType.Error);
            break;
          case 'UserToastService_userToasts$_userToasts$':
            this.showToast('Error:53, An error occurred during the user toast request.' + commonMsg, ToastType.Error);
            break;
          case 'HeaderNavComponent_userToastObs_markToast':
            this.showToast('Error:54, An error occurred during the mark toast as read request.' + commonMsg, ToastType.Error);
            break;
          case 'HeaderNavComponent_markAllToastsObs_markAllToasts':
            this.showToast('Error:55, An error occurred during the mark all toasts as read request.' + commonMsg, ToastType.Error);
            break;
          case 'UserListComponent_getUsers_getUsers':
            this.showToast('Error:56, An error occurred during the get user request.' + commonMsg, ToastType.Error);
            break;
          case 'UserEditComponent_updateUserDetails_updateUser':
            this.showToast('Error:57, An error occurred during the update user request.' + commonMsg, ToastType.Error);
            break;
          case 'UserDetailsComponent_createUser_createUser':
            this.showToast('Error:58, An error occurred during the create user request.' + commonMsg, ToastType.Error);
            break;
          case 'UserEditComponent_syncUserWithSap_syncUserWithSap':
            this.showToast('Error:59, An error occurred during the snyc user with SAP request.' + commonMsg, ToastType.Error);
            break;
          case 'UserDetailsComponent_syncUserWithSap_getUserEntityRoles':
            this.showToast('Error:60, An error occurred during the get entity roles (snyc user with SAP) request.' + commonMsg, ToastType.Error);
            break;
          case 'UserEditComponent_getUserRoles_getUserRoles':
            this.showToast('Error:61, An error occurred during the get user roles request.' + commonMsg, ToastType.Error);
            break;
          case 'UserEditComponent_getUserRoles_getUserEntityRoles':
            this.showToast('Error:62, An error occurred during the get entity roles (user roles) request.' + commonMsg, ToastType.Error);
            break;
          case 'UserAddComponent_searchUsers_searchUsersInActiveDirectory':
            this.showToast('Error:63, An error occurred during the search user request.' + commonMsg, ToastType.Error);
            break;
          case 'MscPayersModalComponent_getMscPayer_getMscPayers':
            this.showToast('Error:64, An error occurred during the mscpayers search request.' + commonMsg, ToastType.Error);
            break;
          case 'PriceVerifyModalComponent_mscPriceVerification_getMscPriceVerification':
            this.showToast('Error:65, An error occurred during the mscpayers search request.' + commonMsg, ToastType.Error);
            break;
          case 'CartComponent_searchCoupons_getCoupons':
            this.showToast('Error:67, An error occurred during coupon search request.' + commonMsg, ToastType.Error);
            break;
          case 'OpenCartComponent_getOpenCarts_getOpenCarts':
            this.showToast('Error:69, An error occurred during get open carts request.' + commonMsg, ToastType.Error);
            break;
          case 'OpenCartComponent_customerSearch_getCustomers':
            this.showToast("Error:70, An error occurred during customer's details request." + commonMsg, ToastType.Error);
            break;
          case 'SuggestedSellingComponent_getPromotionalParts_getPromotionalParts':
            this.showToast('Error:72, An error occurred during the get suggested parts request.' + commonMsg, ToastType.Error);
            break;
          case 'SuggestedSellingComponent_addToCart_addToCartWithoutToast':
            this.showToast('Error:73, An error occurred during the add suggested parts request.' + commonMsg, ToastType.Error);
            break;
          case 'SuggestedSellingComponent_onDeclineReasonOptionChange_declineSuggestedPart':
            this.showToast('Error:74, An error occurred during the decline suggested parts request.' + commonMsg, ToastType.Error);
            break;
          case 'HotFlagComponent_initHotFlag_getPartVendorPrice':
            this.showToast('Error:75, An error occurred during get part vendor price request.' + commonMsg, ToastType.Error);
            break;
          case 'HotFlagComponent_updateCartItemsHotFlag_UpdateCartItemFlag':
            this.showToast('Error:76, An error occurred during update to hot flag request.' + commonMsg, ToastType.Error);
            break;
          case 'KnownPartBuyoutComponent_getVendorPrice_getVendorPrice':
            this.showToast('Error:77, An error occurred during get vendor price request.' + commonMsg, ToastType.Error);
            break;
          case 'KnownPartBuyoutComponent_initBuyout_getPartVendorPrice':
            this.showToast('Error:78, An error occurred during get part vendor price request.' + commonMsg, ToastType.Error);
            break;
          case 'KnownPartBuyoutComponent_initBuyout_getVendors':
            this.showToast('Error:79, An error occurred during get vendors request.' + commonMsg, ToastType.Error);
            break;
          case 'KnownPartBuyoutComponent_confirmPartsBuyout_UpdateCartItemFlag':
            this.showToast('Error:80, An error occurred during update to buyout flag request.' + commonMsg, ToastType.Error);
            break;
          case 'StockTransferOrderComponent_getInventory_getInventoryResponse':
            this.showToast('Error:81, An error occurred during get national inventory request.' + commonMsg, ToastType.Error);
            break;
          case 'StockTransferOrderComponent_addToCart_getVendorPrice':
            this.showToast('Error:82, An error occurred during get vendor price request.' + commonMsg, ToastType.Error);
            break;
          case 'StockTransferOrderComponent_addToCart_DeleteCartItem':
            this.showToast('Error:83, An error occurred during deleting cart item request.' + commonMsg, ToastType.Error);
            break;
          case 'StockTransferOrderComponent_addToCart_addToCart':
            this.showToast('Error:84, An error occurred during add to cart item request.' + commonMsg, ToastType.Error);
            break;
          case 'MyCartComponent_getHotFlags_getHotFlags':
            this.showToast('Error:85, An error occurred during get hot flags request.' + commonMsg, ToastType.Error);
            break;
          case 'MyCartComponent_onChangeCartItemsHotFlag_UpdateCartItemFlag':
            this.showToast('Error:86, An error occurred during update hot flag option request.' + commonMsg, ToastType.Error);
            break;
          case 'CheckoutComponent_placeQuote_placeQuote':
            this.showToast('Error:87, An error occurred during the place quote request.' + commonMsg, ToastType.Error);
            break;
          case 'CheckoutComponent_updateUnitNOPOSI_updateUnitNOPOSI':
            this.showToast('Error:90, An error occurred during update unit number/PO number/special instructions request.' + commonMsg, ToastType.Error);
            break;
          case 'CouponUploadComponent_fileChange_importCouponJsonFile':
            this.showToast('Error:91, An error occurred during import coupon JSON file request.' + commonMsg, ToastType.Error);
            break;
          case 'CouponFileImportComponent_getCouponFileImportsSummary_getCouponFileImportsSummary':
            this.showToast('Error:92, An error occurred during get coupon file imports summary request.' + commonMsg, ToastType.Error);
            break;
          case 'CouponManagementComponent_getCouponsforCouponManagement_getCouponsforCouponManagement':
            this.showToast('Error:93, An error occurred during get coupons for coupon management request.' + commonMsg, ToastType.Error);
            break;
          case 'EulaComponent_acceptEulaVersion_accepteula':
            this.showToast('Error:94, An error occurred during accept eula version request.' + commonMsg, ToastType.Error);
            break;
          case 'OrderConfirmationComponent_printSAPQuote_printSAPQuote':
            this.showToast('Error:95, An error occurred during print SAP quote request.' + commonMsg, ToastType.Error);
            break;
          case 'OrderConfirmationComponent_downloadSAPShortCut_downloadSAPShortCut':
            this.showToast('Error:96, An error occurred during download SAP short cut request.' + commonMsg, ToastType.Error);
            break;
          case 'OrderConfirmationComponent_submitIBSOverrideCreateBilling_submitIBSOverrideCreateBilling':
            this.showToast('Error:97, An error occurred during submit IBS override request.' + commonMsg, ToastType.Error);
            break;
          case 'PartsSearchComponent_getCustomer_getCustomers':
            this.showToast('Error:98, An error occurred during get customers request.' + commonMsg, ToastType.Error);
            break;
          case 'PartsSearchComponent_createPart_createExtendedPart':
            this.showToast('Error:99, An error occurred during create extended part request.' + commonMsg, ToastType.Error);
            break;
          case 'UserEditComponent_bindBranchOverridByUserId_getUserBranchesForOverriding':
            this.showToast('Error:100, An error occurred during get user branches for override request.' + commonMsg, ToastType.Error);
            break;
          case 'UserProfileComponent_userProfile$_getUserProfile':
            this.showToast('Error:101, An error occurred during get user profile request.' + commonMsg, ToastType.Error);
            break;
          case 'UserProfileComponent_submitObs_updateUserProfile':
            this.showToast('Error:102, An error occurred during update user profile request.' + commonMsg, ToastType.Error);
            break;
          case 'AppComponent_subscriptionCreateExtendedPart_createExtendedPart':
            this.showToast('Error:103, An error occurred during create extended part request.' + commonMsg, ToastType.Error);
            break;
          case 'AppComponent_getUserDetails_getUser':
            this.showToast('Error:104, An error occurred during get user details request.' + commonMsg, ToastType.Error);
            break;
          case 'AppComponent_createPart_createExtendedPart':
            this.showToast('Error:105, An error occurred during create part request.' + commonMsg, ToastType.Error);
            break;
          case 'CheckoutComponent_unlockClick_UnlockOpenCartQuoteOrder':
            this.showToast('Error:112, An error occurred during unlock open cart/quote/order request.' + commonMsg, ToastType.Error);
            break;
          case 'CloseReasonModalComponent_getCloseReasons_getCloseReasons':
            this.showToast('Error:113, An error occurred during get close reasons request.' + commonMsg, ToastType.Error);
            break;
          case 'CustomerNotesModalComponent_getCustomerNotes_getCustomerNotes':
            this.showToast('Error:114, An error occurred during get customer notes request.' + commonMsg, ToastType.Error);
            break;
          case 'LineItemNotesModalComponent_SaveLineItemNotes_updateCartLineItemNotes':
            this.showToast('Error:115, An error occurred during save line item notes request.' + commonMsg, ToastType.Error);
            break;
          case 'UnknownPartBuyoutModalComponent_getPartData_getParts':
            this.showToast('Error:116, An error occurred during get parts request.' + commonMsg, ToastType.Error);
            break;
          case 'AppComponent_getOpenItemsCount_getOpenItemsCount':
            this.showToast('Error:118, An error occurred during get open items count request.' + commonMsg, ToastType.Error);
            break;
          case 'MyCartComponent_getCloseReasons_getCloseReasons':
            this.showToast('Error:119, An error occurred during get close reasons request.' + commonMsg, ToastType.Error);
            break;
          case 'MyCartComponent_onCloseReasonOptionChange_markQuoteLineItemClose':
            this.showToast('Error:120, An error occurred during mark quote line item close request.' + commonMsg, ToastType.Error);
            break;
          case 'MyCartComponent_deleteCart_DeleteSingleCart':
            this.showToast('Error:121, An error occurred during delete single cart request.' + commonMsg, ToastType.Error);
            break;
          case 'HeaderSearchComponent_deleteCart_deleteSingleCart':
            this.showToast('Error:123, An error occurred during the delete single cart request.' + commonMsg, ToastType.Error);
            break;
          case 'CheckoutComponent_getCartConsolidatedData_getCartConsolidatedData':
            this.showToast('Error:124, An error occurred during get cart consolidated data request.' + commonMsg, ToastType.Error);
            break;
          case 'ImageFeedbackComponent_submitFeedback_sendImageFeedback':
            this.showToast('Error:125, An error occurred during the send image feedback request.' + commonMsg, ToastType.Error);
            break;
          case 'UserEditComponent_getBranchRoles_getBranchRoles':
            this.showToast('Error:126, An error occurred during the get branch roles request.' + commonMsg, ToastType.Error);
            break;
          case 'UserAddComponent_syncUserWithSap_syncUserWithSap':
            this.showToast('Error:127, An error occurred during the snyc user with SAP request.' + commonMsg, ToastType.Error);
            break;
          case 'UserAddComponent_createNewUser_createNewUser':
            this.showToast('Error:128, An error occurred during the create new user request.' + commonMsg, ToastType.Error);
            break;
          case 'UserAddComponent_onSearchChange_getCustomerDetails':
            this.showToast('Error:129, An error occurred during the get user details(on search) request.' + commonMsg, ToastType.Error);
            break;
          case 'UserAddComponent_getGeneralRoles_getAllGeneralRoles':
            this.showToast('Error:130, An error occurred during the get general roles request.' + commonMsg, ToastType.Error);
            break;
          case 'UserAddComponent_getBranchRoles_getBranchRoles':
            this.showToast('Error:131, An error occurred during the get branch roles request.' + commonMsg, ToastType.Error);
            break;
          case 'UserAddComponent_getCustomerRoles_getCustomerRoles':
            this.showToast('Error:132, An error occurred during the get customer roles request.' + commonMsg, ToastType.Error);
            break;
          case 'UserAddComponent_bindBranchecodes_getUserBranchesForOverriding':
            this.showToast('Error:133, An error occurred during the bind banch codes request.' + commonMsg, ToastType.Error);
            break;
          case 'PriceExceptionDashboardComponent_getPriceExceptionRequests_getPriceExceptionRequests':
            this.showToast('Error:134, An error occurred during the get price exception request.' + commonMsg, ToastType.Error);
            break;
          case 'PriceExceptionCustomerComponent_getParts_getParts2':
            this.showToast('Error:135, An error occurred during the get parts request.' + commonMsg, ToastType.Error);
            break;
          case 'PriceExceptionCustomerComponent_addToFavourites_createFavouriteCustomer':
            this.showToast('Error:136, An error occurred during the create favourite customer request.' + commonMsg, ToastType.Error);
            break;
          case 'PriceExceptionCustomerComponent_removeFavourites_deleteFavouriteCustomer':
            this.showToast('Error:137, An error occurred during the delete favourite customer request.' + commonMsg, ToastType.Error);
            break;
          case 'PriceExceptionDashboardComponent_subtoSAP_submitToSAP':
            this.showToast('Error:139, An error occurred during the submit to SAP request.' + commonMsg, ToastType.Error);
            break;
          case 'OpenCartComponent_createNewCart_createNewCart':
            this.showToast("Error:140, An error occurred during create new cart request." + commonMsg, ToastType.Error);
            break;
          case 'OpenCartComponent_deleteCart_deleteSingleCart':
            this.showToast("Error:141, An error occurred during create new cart request." + commonMsg, ToastType.Error);
            break;
            case 'CheckoutComponent_updateUnitNumber_updateUnitNumber':
            this.showToast('Error:142, An error occurred during update unit number request.' + commonMsg, ToastType.Error);
            break;
          case 'CheckoutComponent_updatePONumber_updatePONumber':
            this.showToast('Error:143, An error occurred during update PO number request.' + commonMsg, ToastType.Error);
            break;
          case 'CheckoutComponent_editQuote_editQuote':
            this.showToast('Error:144, An error occurred during edit quote request.' + commonMsg, ToastType.Error);
            break;
          case 'CheckoutComponent_updateQuote_updateQuote':
            this.showToast('Error:145, An error occurred during update quote request.' + commonMsg, ToastType.Error);
            break;
          case 'CheckoutComponent_onCloseReasonOptionChange_closeQuoteReason':
            this.showToast('Error:146, An error occurred during close quote reason request.' + commonMsg, ToastType.Error);
            break;
          case 'CheckoutComponent_printSAPQuote_printSAPQuote':
            this.showToast('Error:147, An error occurred during print SAP quote request.' + commonMsg, ToastType.Error);
            break;
          case 'PartsSearchComponent_subscriptionCreateExtendedPart_createExtendedPart':
            this.showToast('Error:148, An error occurred during create extended part request.' + commonMsg, ToastType.Error);
            break;
          case 'MyDashboardComponent_getOpenCartsQuotesOrders_getOpenCartsQuotesOrders':
            this.showToast('Error:149, An error occurred during get open carts/quotes/orders request.' + commonMsg, ToastType.Error);
            break;
          case 'MyDashboardComponent_editClick_isOpenCartQuoteOrder':
            this.showToast('Error:150, An error occurred during is open carts/quotes/orders request.' + commonMsg, ToastType.Error);
            break;
          case 'MyDashboardComponent_unlockClick_unlockOpenCartQuoteOrder':
            this.showToast('Error:151, An error occurred during unlock open carts/quotes/orders request.' + commonMsg, ToastType.Error);
            break;
          case 'MyDashboardComponent_customerSearch_getCustomers':
            this.showToast('Error:152, An error occurred during get customers request.' + commonMsg, ToastType.Error);
            break;
          case 'CheckoutComponent_getCloseReasons_getCloseReasons':
            this.showToast('Error:153, An error occurred during get close reasons request.' + commonMsg, ToastType.Error);
            break;
          case 'PriceExceptionDetailsComponent_createQuote_createCartFromPriceException':
            this.showToast('Error:154, An error occurred during create quote request.' + commonMsg, ToastType.Error);
            break;
          case 'PriceExceptionCustomerComponent_subForApproval_submitpriceexceptionforapproval':
            this.showToast('Error:114, An error occurred during submit price exception for approval request.' + commonMsg, ToastType.Error);
            break;
          case 'PriceExceptionCustomerComponent_subtoSAP_submitToSAP':
            this.showToast('Error:155, An error occurred during submit price exception to SAP request.' + commonMsg, ToastType.Error);
            break;
          case 'UserEditComponent_getCustomerRoles_getCustomerRoles':
            this.showToast('Error:156, An error occurred during the get customer roles request.' + commonMsg, ToastType.Error);
            break;
          case 'BuyAgainComponent_batchAddToCart_batchAddToCart':
            this.showToast('Error:157, An error occurred adding "Buy Again" order to cart.' + commonMsg, ToastType.Error);
            break;
          case 'CheckoutComponent_updateSmsOpt_updateSmsOpt':
              this.showToast('Error:158, An error occurred during update sms opt request.' + commonMsg, ToastType.Error);
              break;
          case 'CheckoutComponent_updateBillTo_updateBillTo':
            this.showToast('Error:159, An error occurred during update billing type request.' + commonMsg, ToastType.Error);
            break;
          case 'CheckoutComponent_updateShipTo_updateShipTo':
            this.showToast('Error:160, An error occurred during update alternate shipping address request.' + commonMsg, ToastType.Error);
            break;
          case 'PartNotesComponent_udpateNote_updateNote':
            this.showToast('Error:161, An error occurred during the update note request.' + commonMsg, ToastType.Error);
            break;
          case 'CartEffects_upsertCustomer$_insertUpdateCustomer':
            this.showToast('Error:162, An error occurred during insert-update customer request.' + commonMsg, ToastType.Error);
            break;
          case 'StockTransferOrderComponent_addToCart_setPrice':
            this.showToast('Error:163, An error occurred during set cart item price request.' + commonMsg, ToastType.Error);
            break;
          case 'PartCardAddToCartComponent_addToCart_setPrice':
            this.showToast('Error:164, An error occurred during set cart item price request.' + commonMsg, ToastType.Error);
            break;
          case 'PartsDetailsComponent_addToCart_setPrice':
            this.showToast('Error:165, An error occurred during set cart item price request.' + commonMsg, ToastType.Error);
            break;

          default:
            this.loggerService.verbose('An unusual error at ==> ' + componentName + '_' + componentFunctionName + '_' + serviceFunctionName);
            this.showToast('An unusual error occurred. Please submit a feedback form so we can check into it.', ToastType.Error);
            break;
        }
        break;
    }
  }

  private toastShown() {
    setTimeout(() => {
      this.shown = true;
    }, 1000);
  }

  public toToastMessage(resultMessage: ResultMessage): { message: string, type: ToastType } {
    return {
      message: resultMessage.message,
      type: this.toToastType(resultMessage.level)
    };
  }

  public toToastType(resultMessageLevel: ResultMessageLevel): ToastType {
    switch (resultMessageLevel) {
      case 'success':
        return ToastType.Success;
      case 'info':
        return ToastType.Info;
      case 'warning':
        return ToastType.Warning;
      case 'error':
        return ToastType.Error;
      case 'critical':
        return ToastType.Abort;
      default:
        return ToastType.Success;
    }
  }
}
