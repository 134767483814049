import { Injectable } from '@angular/core';
import { BaseService } from 'services/base.service';
import { HttpClient } from '@angular/common/http';
import { InventoryResult } from 'entities/national-inventory/inventory-result';
import { Observable } from 'rxjs';
import { GetInventoryRequest } from 'entities/national-inventory/get-inventory-request';

@Injectable({
  providedIn: "root"
})
export class NationalInventoryService extends BaseService {
  constructor(
    private http: HttpClient) {
    super();
  }

  getInventory(partId: string, partNumber: string, branchCode: string, customerNumber: string): Promise<any> {

    let url: string = `api/parts/GetNationalInventory?partId=${partId}&partNumber=${partNumber}&branchCode=${branchCode}&customerNumber=${customerNumber}`;
    return this.http.get(url)
      .toPromise()
      .catch(this.handleError);
  }

  getInventoryObs(GetInventoryRequest: GetInventoryRequest) {
    let url: string = 'api/parts/GetNationalInventory';
    return this.http.put<InventoryResult[]>(url, GetInventoryRequest);
  }

  getInventoryResponse(GetInventoryRequest: GetInventoryRequest) {
    let url: string = 'api/parts/GetNationalInventory';
    return this.http.put<InventoryResult[]>(url, GetInventoryRequest);
  }
}
