<div
  class="branch-multiselect"
  style="height: calc(2.25rem + 2px);"
  *ngLet="{
    searchResults: searchResults$ | async,
    branches: branches$ | async
  } as vm">

  <div class="input-group"
    ngbDropdown [autoClose]="true"
    display="dynamic" placement="top-left"
    #branchesDropdown="ngbDropdown"
    (openChange)="onMenuToggle($event)">
    <div class="position-relative d-block w-100"
      (click)="branchesDropdown.open();">
      <div class="form-control">
        <span *ngIf="!branches.length">Find My Branch</span>
        <div *ngFor="let branch of branches; trackBy: trackById" class="branch-tag">
          <span>{{ branch.code }}</span>
          <button type="button" (click)="removeBranch($event, branch.id)">
            <span aria-hidden="true">x</span>
          </button>
        </div>
      </div>
    </div>
    <div class="branches-menu w-100" ngbDropdownMenu>
      <input #searchInput
        type="text" class="form-control search-input"
        debounceInput (onDebouncedInput)="onInputChange($event.target.value)"
        (keydown)="onKeyDown($event, vm.branches)">
      <div class="search-results-wrapp">
        <a class="d-flex flex-row"
          href="return false;"
          *ngFor="let item of vm.searchResults; trackBy: trackById; let i = index;"
          (click)="toggleBranchSelection($event, item)">
          <input id="item-{{index}}" type="checkbox" [checked]="item.selected"/>
          <label style="margin-left: .2rem;">{{item.code}} - {{item.displayName }}</label>
        </a>
      </div>
    </div>
  </div>

</div>
