import { createAction, props } from '@ngrx/store';
import { Branch } from 'entities/branch';
import { Customer } from 'entities/customer';

export const selectCustomer = createAction(
  '[Common Data Service] Select Customer',
  props<{ customer: Customer }>()
);

export const selectBranch = createAction(
  '[Common Data Service] Select Branch',
  props<{ branch: Branch }>()
);

export const selectCartId = createAction(
  '[Common Data Service] Select Cart Id',
  props<{ cartId: string }>()
);

export const clearCart = createAction(
  '[Common Data Service] Clear Cart'
);
