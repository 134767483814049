import { createAction, props } from "@ngrx/store";
import { FeaturedPart } from 'entities/featured-part';

export const showLastPitch = createAction(
    '[Featured parts] Show Last Pitch'
);

export const openPromosModal = createAction(
    '[Featured parts] Open Modal'
);

export const closePromosModal = createAction(
    '[Featured parts] Close Modal', props<{ result: boolean }>()
);

export const getFeaturedParts = createAction(
    '[Featured parts] Get Featured Parts'
);

export const getFeaturedPartsSuccess = createAction(
    '[Featured parts] Get Featured Parts Success', props<{ featuredParts: FeaturedPart[] }>()
);

export const getFeaturedPartsFailure = createAction(
    '[Featured parts] Get Featured Parts Failure',
    props<{ error: any }>()
);

export const clearFeaturedParts = createAction(
    '[Featured parts] Clear Featured Parts'
)

export const  applyLoyaltyDiscountsOnFeaturedParts = createAction(
    '[Featured parts] Apply Loyalty Discounts On Featured Parts', props<{ discounts: any }>()
);

export const hideFinalPitch = createAction(
    '[Featured parts] Hide Final Pitch',
    props<{ cartId: string }>()
);
export const hideFinalPitchSuccess = createAction(
    '[Featured parts] Hide Final Pitch Success'
);
export const hideFinalPitchFailed = createAction(
    '[Featured parts] Hide Final Pitch Failed',
    props<{error: any}>()
);
