import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { i2pType } from "entities/checkout-billing-enums";
import { mapErrorKeysToMessages } from "helpers/map-error-messages";
import { EMAIL_REGEXP } from "validators/email.validator";
import { isNullOrEmpty } from "validators/null-or-empty.validator";

export const inviteToPayValidator = (
    regexPhonePattern: RegExp
): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
        const form = control.root as FormGroup;
        const { i2p, i2pEmail, i2pSms } = form.value;

        const errorsMap = {
            "i2pEmail.required": i2p === i2pType.Email && isNullOrEmpty(i2pEmail),
            "i2pEmail.email": i2p === i2pType.Email && !isNullOrEmpty(i2pEmail) && !EMAIL_REGEXP.test(i2pEmail),
            "i2pSms.required": i2p === i2pType.SMS && isNullOrEmpty(i2pSms),
            "i2pSms.pattern": i2p === i2pType.SMS && !isNullOrEmpty(i2pSms) && !regexPhonePattern.test(i2pSms)
        }

        let errors = mapErrorKeysToMessages(errorsMap);
        if (errors && Object.keys(errors).length > 0) {
            return errors;
        }

        return null;
    }
};