import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { ProcurementGroup } from 'entities/procurement-group';
import { CreatePurchaseOrdersBatchRequest, PurchaseOrderDeliveryType, PurchaseOrderItem } from 'entities/purchase-order';
import { HotFlagRadioButtonType } from 'entities/purchase-order/hot-flag';
import { ToastType } from 'entities/toast-type';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { LoggerService } from 'services/logger.service';
import { OrderService } from 'services/order.service';
import { PurchaseOrderService } from 'services/purchase-order.service';
import { ToastService } from 'services/toast.service';

export interface PurchaseOrderCreateModalComponentState {
  loading: boolean;
  orderNumber: string;
  procurementGroups: ProcurementGroup[];
  groupStates: {
    deliveryType: PurchaseOrderDeliveryType;
    showMore: boolean;
    orderItemNumber?: string;
    createRequestOnly?: HotFlagRadioButtonType;
  }[];
  submitting: boolean;
}

@Injectable()
export class PurchaseOrderCreateModalComponentStore extends ComponentStore<PurchaseOrderCreateModalComponentState> {
  constructor(
    private orderService: OrderService,
    private purchaseOrderService: PurchaseOrderService,
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    private logger: LoggerService
  ) {
    super({
      loading: false,
      orderNumber: null,
      procurementGroups: [],
      groupStates: [],
      submitting: false
    });
  }

  // Actions/Effects
  public readonly getProcurementGroups = this.effect((orderNumber$: Observable<string>) =>
    orderNumber$.pipe(
      tap((orderNumber) => this.patchState({ loading: true, orderNumber })),
      switchMap((orderNumber) =>
        this.orderService.getProcurementGroups(orderNumber)
          .pipe(
            map((procGroups) => procGroups.filter(x => x.orderItems != null)),
            tapResponse(
              (procurementGroups) => this.patchState({
                loading: false,
                procurementGroups,
                groupStates: procurementGroups.map(() => ({ deliveryType: null, showMore: false }))
              }),
              (error) => {
                this.logger.error('getProcurementGroups error', error);
                if (Array.isArray(error)) {
                  const toasts = error
                    .map((message) => ({ message, type: ToastType.Error }));
                  this.toastService.showMultilineToast(toasts);
                }
                this.patchState({ loading: false });
              }
            )
          )
      )
    )
  );

  public readonly submit = this.effect((source$) =>
    source$.pipe(
      tap(() => this.patchState({ submitting: true })),
      map(() => this.get<CreatePurchaseOrdersBatchRequest>((state) => ({
        orderNumber: state.orderNumber,
        items: state.groupStates
          .map((groupState, index) => ({ ...state.procurementGroups[index], ...groupState }))
          .map(({ procurementType, vendorNumber, deliveryType, orderItems, createRequestOnly }) => ({
            procurementType,
            vendorNumber,
            purchaseOrderDeliveryType: deliveryType != null ? deliveryType : 'other',
            purchaseOrderItems: orderItems.map((orderItem) => {
              return { orderItemNumber: ""+orderItem.itemNumber, createRequestOnly: createRequestOnly === 'requisition' ? "X": ""  } as PurchaseOrderItem;
            })
          }))
      }))),
      switchMap((batchRequest) =>
        this.purchaseOrderService.createPurchaseOrdersBatch(batchRequest)
          .pipe(
            tapResponse(
              (batchResult) => {
                this.activeModal.close(batchResult);
              },
              (error) => {
                this.logger.error('createPurchaseOrdersBatch error', error);
                if (Array.isArray(error)) {
                  const toasts = error
                    .map((message) => ({ message, type: ToastType.Error }));
                  this.toastService.showMultilineToast(toasts);
                } else {
                  const errorMessage = (error as any)?.message;
                  const message = errorMessage && errorMessage !== undefined && errorMessage != null && errorMessage !== ''
                    ? errorMessage
                    : 'An unexpected error occurred during creating purchase order.';
                  this.toastService.showToast(message, ToastType.Error);
                }
                this.patchState({ submitting: false });
              }
            )
          )
      )
    )
  );

  // Actions/Reducers
  public readonly showMore = this.updater((state, index: number) => ({
    ...state,
    groupStates: state.groupStates
      .map((groupState, groupIndex) =>
        groupIndex === index
        ? { ...groupState, showMore: true }
        : groupState
      )
  }));

  public readonly showLess = this.updater((state, index: number) => ({
    ...state,
    groupStates: state.groupStates
      .map((groupState, groupIndex) =>
        groupIndex === index
        ? { ...groupState, showMore: false }
        : groupState
      )
  }));

  public readonly updateDeliveryType = this.updater((state, { key: index, value: deliveryType }: KeyValue<number, PurchaseOrderDeliveryType>) => ({
    ...state,
    groupStates: state.groupStates
      .map((groupState, groupIndex) =>
        groupIndex === index
        ? { ...groupState, deliveryType }
        : groupState
      )
  }));

  public readonly updateHotFlagItems = this.updater((state, { key: index, value: hotFlagType }: KeyValue<number, HotFlagRadioButtonType>) => ({
    ...state,
    groupStates: state.groupStates
      .map((groupState, groupStateIndex) =>
      groupStateIndex === index
        ? { ...groupState, createRequestOnly: hotFlagType }
        : groupState
      )
  }));

  // Selectors
  public readonly busy$ = this.select((state) => state.loading || state.submitting);

  public readonly groups$ = this.select((state) =>
    state.groupStates
      .map((groupState, index) => ({ info: state.procurementGroups[index], groupState }))
      .map(({ info, groupState }) => ({
        info,
        state: groupState,
        orderItems: groupState.showMore
          ? info.orderItems
          : info.orderItems.slice(0, 3),
        hasMore: info.orderItems.length > 3,
        moreCount: info.orderItems.length - 3
      }))
  );

}
