import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrderConfirmationState } from './order-confirmation.state';

export const selectOrderConfirmation = createFeatureSelector< OrderConfirmationState>('orderConfirmation');

export const selectOverrideBins = createSelector(
  selectOrderConfirmation,
  (state) => state.partBinLocations
);

export const selectSelectedOverrideBins = createSelector(
  selectOrderConfirmation,
  (state) => state.selectedPartBinLocations
);

export const selectOrder = createSelector(
  selectOrderConfirmation,
  (state) => state.order
);

export const selectCreateBillingEnabled = createSelector(
  selectOrderConfirmation,
  (state) => state.createBillingEnabled
);

export const selectCreateBillingShown = createSelector(
  selectOrderConfirmation,
  (state) => state.createBillingShown
);

export const selectReadyToPickEnabled = createSelector(
  selectOrderConfirmation,
  (state) => state.readyToPickEnabled
);
export const selectReadyToPickShown = createSelector(
  selectOrderConfirmation,
  (state) => state.readyToPickShown
);

export const selectHasMultipleBins = createSelector(
  selectOverrideBins,
  (overrideBins) => {
    const test2 = Object.values(overrideBins);
    const test = test2.some(bins => {
      const test = bins;
      return bins?.length > 1;
    });
    return test;
  }
);

export const getPaymentTypeInfo = createSelector(
  selectOrderConfirmation,
  (state) =>
     ({ billingType: state.order?.order?.billingType, billToAddressNo: state.order?.order?.billToAddressNo, paymentSelection: state.order?.order?.paymentSelection, tokenId: state.order?.order?.tokenId, i2PSmsNumber: state.order?.order?.i2pSmsNumber, i2PEmail: state.order?.order?.i2pEmail })
);

export const selectOrderConfirmationTotal = createSelector(
  selectOrderConfirmation,
  (state) => state.orderConfirmationTotal
);
