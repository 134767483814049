<ul ngbNav #nav="ngbNav" class="nav-links" (navChange)="onNavChange($event)">
  <li *ngIf="isInternal$ | async" ngbNavItem="customerNumber">
    <a ngbNavLink id="customerPanel_tab_CustomerNumber">Customer Number</a>
    <ng-template ngbNavContent>
      <form  id="customerPanel_form_CustomerNumber" #customerNumberForm="ngForm" class="needs-validation"
        [ngClass]="{ 'was-validated': !customerNumberForm.pending }" *ngLet="clearOnBranchChange$ | async as clearCustomer">
        <div class="form-group">
          <label for="customerPanel_text_CustomerNumber">Customer Number</label>
          <input id="customerPanel_text_CustomerNumber" type="text" class="form-control" #txtCustomerNumber name="customerPanel_text_CustomerNumber"
            [(ngModel)]="customerNumber" maxlength="10" (keyup)="onCustomerKeyUp($event)"
            (keypress)="onCustomerKeypress($event)" (keydown)="onCustomerKeydown($event)" />
        </div>
        <div class="form-group d-flex justify-content-between">
          <div>
            <button id="customerPanel_button_SearchCustomer" type="button" class="btn btn-primary" (click)="findCustomers()" [disabled]="!customerNumber">
              Search Customer
            </button>
            <label id="customerPanel_label_PayerInValidInBranch" *ngIf="PayerInValidInBranch"> &nbsp; <i class="fas fa-exclamation-triangle text-warning" aria-hidden="true"></i>
              Payer is not valid for this branch
            </label>
          </div>
          <ng-content></ng-content>
        </div>
      </form>
    </ng-template>
  </li>
  <li *ngIf="isInternal$ | async" ngbNavItem="advancedCustomerSearch">
    <a ngbNavLink id="customerPanel_tab_AdvancedCustomerSearch" >Advanced Customer Search</a>
    <ng-template ngbNavContent>
      <form id="customerPanel_form_SapCustomer" (ngSubmit)="advCustSearchForm.form.valid && customerSearch()"
      #advCustSearchForm="ngForm" *ngLet="{ countryCode: countryCode$ | async } as vm">
        <div id="customerPanel_div_SapCustomerForm">
          <div>Provide any of the following information to find customer</div>
          <br>
          <div class="form-group">
            <label for="customerPanel_text_AdvancedCustomerName">Customer Name</label>
            <input id="customerPanel_text_AdvancedCustomerName" class="form-control" name="customerPanel_text_AdvancedCustomerName" type="text"
              [(ngModel)]="advCustomerName" (keypress)="onCustomerNameKeypress($event)">
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <label for="customerPanel_text_AdvancedCustomerCity">City</label>
              <input id="customerPanel_text_AdvancedCustomerCity"  class="form-control" name="customerPanel_text_AdvancedCustomerCity" type="text" [(ngModel)]="advCity"
                (keypress)="onCityKeypress($event)">
            </div>
            <div class="form-group col-md-6">
              <label for="customerPanel_select_AdvancedCustomerState">{{ vm.countryCode === 'US' ? 'State': 'Province'}}</label>
              <select id="customerPanel_select_AdvancedCustomerState" class="form-control" name="customerPanel_select_AdvancedCustomerState" [(ngModel)]="advState"
                (keypress)="onStateKeypress($event)">
                <option value="">{{ vm.countryCode === 'US' ? 'State': 'Province'}}</option>
                <option *ngFor="let province of provinceList$ | async; let i = index" [ngValue]="province.code">
                  {{ province.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <label for="customerPanel_text_AdvancedCustomerPostalCode">Postal Code</label>
              <input id="customerPanel_text_AdvancedCustomerPostalCode" class="form-control" name="customerPanel_text_AdvancedCustomerPostalCode" type="text" [(ngModel)]="advPostalCode"
                (keypress)="onPostalCodeKeypress($event)" (input)="postalCodeChanged($event, vm.countryCode)">
            </div>
            <div class="form-group col-md-6">
              <label for="advPostalCode">Phone Number</label>
              <input id="customerPanel_text_AdvancedCustomerPhoneNumber" class="form-control" name="customerPanel_text_AdvancedCustomerPhoneNumber" type="text" [(ngModel)]="advPhoneNumber"
                (keypress)="onPhoneNoKeypress($event)">
            </div>
          </div>
          <div class="form-group">
            <button id="customerPanel_button_AdvancedCustomerSearch" class="btn btn-primary" type="button"
                    [disabled]="postalCodeInvalid || (!advCustomerName && !advCity && !advState && !advPostalCode && !advPhoneNumber)"
                    (click)="findCustomers()">
              Search Customer
            </button>
          </div>
        </div>
      </form>
    </ng-template>
  </li>
  <li *ngIf="isInternal$ | async" ngbNavItem="favoriteCustomer">
    <a ngbNavLink id="customerPanel_tab_Favorites">View favorites</a>
  </li>
</ul>
<div [ngbNavOutlet]="nav"></div>
<div class="row mb-3">
  <span id="customerPanel_span_HasErrorMessage" class="text-danger col" *ngIf="hasMessage">{{customerMesssage}}</span>
  <span id="customerPanel_span_HasInvalidPostalCode" class="text-danger col" *ngIf="postalCodeInvalid">Invalid Postal Code</span>
</div>
