import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";

@Component({
  selector: "count-down",
  template: `{{ displayString }}`,
})
export class CountDownComponent implements OnInit, OnDestroy {
  _to: any;
  _increment: any;

  @Input("hasDiplayCondition")
  hasDiplayCondition: boolean = false;

  @Input("diplayThresholdHours")
  diplayThresholdHours: number;

  @Input("data")
  data: any;

  @Input()
  showDuration: boolean = false;

  @Output()
  timeTickEvent = new EventEmitter();

  @Input("units")
  units: any;

  @Input()
  start: any;

  @Input("end")
  end: any;

  @Input("text")
  text: any;

  @Input()
  hasLiveDuration: boolean = false;

  @Output()
  endCount = new EventEmitter();

  private startDate: any;
  private endDate: Date;
  public displayString: string;

  constructor() {}

  ngOnInit(): void {
    if (typeof this.units === "string") {
      this.units = this.units.toLowerCase().split("|");
    }

    // For some reason this.end is being converted from utc time to local time.
    // Convert back to UTC
    this.startDate = this.getUTCDate(this.start);
    this.endDate = this.getUTCDate(this.end);

    if (this.startDate > this.endDate) {
      this.displayString = "Invalid data";
      return;
    }

    if (this.showDuration) {
      this.displayString = this.getDuration();
      if (this.hasLiveDuration && !this.end) {
        let increment = 0;
        let oneSec = 1000;
        this._increment = setInterval(() => {
          this.displayString = this.getDuration(increment * oneSec);
          increment = increment + 1;
        }, oneSec);
      }
      return;
    }

    if (!this.text) {
      this.text = {
        Weeks: "Weeks",
        Days: "Days",
        Hours: "Hours",
        Minutes: "Minutes",
        Seconds: "Seconds",
        MilliSeconds: "Milliseconds",
      };
    }

    this._to = setInterval(
      () => {
        this._displayString();
      },
      this.units["milliseconds"] ? 1 : 1000
    );
  }

  ngOnDestroy(): void {
    if (this._increment) {
      clearInterval(this._increment);
    }
  }

  getUTCDate(_date?: string): Date {
    return this.convertDateToUTC(_date ? new Date(_date) : new Date());
  }

  _displayString() {
    let _date = this.start ? this.startDate : new Date();
    let _utc = new Date(
      _date.getUTCFullYear(),
      _date.getUTCMonth(),
      _date.getUTCDate(),
      _date.getUTCHours(),
      _date.getUTCMinutes(),
      _date.getUTCSeconds()
    );

    // eslint-disable-next-line one-var
    const now: any = _utc, // new Date(),//this.startDate
      lastUnit = this.units[this.units.length - 1],
      unitsLeft = [],
      unitConstantMillis = {
        weeks: 6048e5,
        days: 864e5,
        hours: 36e5,
        minutes: 6e4,
        seconds: 1e3,
        milliseconds: 1,
      };

    // eslint-disable-next-line one-var
    let msLeft: any = this.endDate.getTime() - now,
      returnString = "";

    if (msLeft <= 0) {
      this.endCount.emit(this.data);
      clearInterval(this._to);
    }

    this.units.forEach((unit: string) => {
      if (!unitConstantMillis[unit]) {
        // $interval.cancel(countDownInterval);
        throw new Error("Cannot repeat unit: " + unit);
      }

      if (!unitConstantMillis.hasOwnProperty(unit)) {
        throw new Error(
          "Unit: " +
            unit +
            " is not supported. Please use following units: weeks, days, hours, minutes, seconds, milliseconds"
        );
      }

      unitsLeft[unit] = msLeft / unitConstantMillis[unit];

      unitsLeft[unit] =
        lastUnit === unit
          ? Math.ceil(unitsLeft[unit])
          : Math.floor(unitsLeft[unit]);

      msLeft -= unitsLeft[unit] * unitConstantMillis[unit];

      unitConstantMillis[unit] = false;

      returnString += " " + unitsLeft[unit] + " " + unit;
    });

    this.displayString = returnString
      .replace("Weeks", this.text.Weeks)
      .replace("Days", this.text.Days)
      .replace("Hours", this.text.Hours)
      .replace("Minutes", this.text.Minutes)
      .replace("Seconds", this.text.Seconds)
      .replace("Milliseconds", this.text.MilliSeconds);

    this.displayString = this.displayString
      .replace(" weeks", "w:")
      .replace(" days", "d:")
      .replace(" hours", "h:")
      .replace(" minutes", "m:")
      .replace(" seconds", "s:")
      .replace(" milliseconds", "ms:")
      .slice(0, -1);

    if (this.hasDiplayCondition) {
      let remainingHours = unitsLeft["hours"];

      if (this.units.indexOf("weeks") != -1) {
        remainingHours =
          (unitsLeft["weeks"] * 7 + unitsLeft["days"]) * 24 +
          unitsLeft["hours"];
      } else if (this.units.indexOf("days") != -1) {
        remainingHours = unitsLeft["days"] * 24 + unitsLeft["hours"];
      }
      if (remainingHours >= this.diplayThresholdHours) {
        this.displayString = "";
      } else {
        this.timeTickEvent.emit(this.data);
      }
    }
  }

  getDuration(offsetSeconds: number = 0): string {
    const distance =
      this.endDate.getTime() + offsetSeconds - this.startDate.getTime();
    const weeks = Math.floor(distance / (1000 * 60 * 60 * 24 * 7));
    const days = Math.floor(
      (distance % (1000 * 60 * 60 * 24 * 7)) / (1000 * 60 * 60 * 24)
    );
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return `${weeks}w: ${days}d: ${hours}h: ${minutes}m: ${seconds}s`;
  }

  convertDateToUTC(date: Date): Date {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
  }
}
