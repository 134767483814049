import { Directive, Input } from "@angular/core";
import { Validator, AbstractControl, NG_VALIDATORS } from "@angular/forms";

@Directive({
  selector: "[minDate]",
  providers: [
    { provide: NG_VALIDATORS, useExisting: MinDateDirective, multi: true }
  ]
})
export class MinDateDirective implements Validator {
  @Input()
  minDate: Date;
  validate(c: AbstractControl): { [key: string]: any } {
    const date = c.value ? new Date(c.value) : null;

    if (!date || !this.minDate) {
      return null;
    }

    const dateFromValue = date.toISOString().split('T')[0];
    const dateFromMinValue = this.minDate.toISOString().split('T')[0];

    if (dateFromValue === dateFromMinValue) {
      return null;
    }

    if (date < this.minDate) {
      return { minDate: true };
    }

    return null;
  }
}
