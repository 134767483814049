import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { Observable } from 'rxjs';
import { Province } from "entities/province";

@Injectable({
  providedIn: "root",
})
export class ProvinceService extends BaseService {
  private readonly partsUrl: string = "api/address/";

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  getProvincesByCountry(countryCode): Observable<Province[]> {
    const queryParams = [`countryCode=${countryCode}`,];
    const queryString = queryParams.join("&");
    return this.httpClient.get<Province[]>(`${this.partsUrl}LoadProvincesByCountry?${queryString}`);
  }

}