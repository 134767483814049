import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { Discount } from 'entities/discount';
import { LoyaltyDiscountSearchCriteria, LoyaltyProgram } from 'entities/loyalty-account';
import { Part } from 'entities/part';
import { ToastType } from 'entities/toast-type';
import { Observable, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { LoyaltyService, getPartLoyaltyPrograms } from 'services/loyalty.service';
import { ToastService } from 'services/toast.service';
import { AppState } from 'store/app-state';
import * as BranchSelectors from 'store/branch/branch.selectors';
import * as LoyaltySelectors from 'store/loyalty/loyalty.selectors';

interface LoyaltyDiscountsState {
  loading: { [loyaltyProgram in LoyaltyProgram]: boolean };
  partNumbers: string[];
  discounts: { [loyaltyProgram in LoyaltyProgram]: { [partNumber: string]: Discount[] } };
  searchDiscountCriteria: LoyaltyDiscountSearchCriteria;
}

@Injectable()
export class LoyaltyDiscountsStore extends ComponentStore<LoyaltyDiscountsState> {
  private searchDiscountCriteria: LoyaltyDiscountSearchCriteria;

  constructor (
    private store: Store<AppState>,
    private loyaltyService: LoyaltyService,
    private toastService: ToastService
  ) {
    super({
      loading: {
        'peterbilt': false
      },
      partNumbers: [],
      discounts: {
        'peterbilt': {}
      },
      searchDiscountCriteria: {
        branchCode: null,
        loyaltyProgram: null,
        memberId: null,
        partNumbers: []
      }
    });
  }

  public readonly getDiscounts = this.effect((parts$: Observable<Part[]>) => parts$
    .pipe(
      // TODO: Get known loyalty discounts from store
      withLatestFrom(
        this.store.select(BranchSelectors.selectedBranch),
        this.store.select(LoyaltySelectors.loyaltyAccount),
        of({ 'peterbilt': { '35P8': [{ code: 'knownCode', description: 'knownDescription', amount: 4.56 }] } })
      ),
      map(([searchDiscountCriteria]) => ({
        searchDiscountCriteria
      })),
      map(() => this.get()),
      map(({ searchDiscountCriteria }) =>
        Object.entries(searchDiscountCriteria)
          .map(() =>
            this.loyaltyService.getDiscounts(searchDiscountCriteria)
        )
          .map(
                tapResponse(
                  (discounts) => {
                    this.setState((state) => ({
                      ...state,
                      loading: {
                        ...state.loading
                      }
                    }));
                    // TODO: Dispatch action to NGRX store reducer for known part loyalty discounts
                  },
                  (error) => {
                    this.toastService.showToast(`Error loading offers for search criteria '${searchDiscountCriteria}'`, ToastType.Error);
                    this.setState((state) => ({
                      ...state,
                      loading: {
                        ...state.loading
                      }
                    }));
                  }
                )
              )
          )
      )
    );

  public readonly loadingDiscounts$ = this.select((state) => state.loading);
  public readonly loyaltyDiscounts$ = this.select((state) => state.discounts);
  public getPartDiscounts(part: Part): Observable<{ [loyaltyProgram in LoyaltyProgram]?: Discount[] }> {
    return this.loyaltyDiscounts$
      .pipe(
        map((loyaltyDiscounts) => Object.entries(loyaltyDiscounts)
          .map(([program, partDiscounts]: [LoyaltyProgram, { [partNumber: string]: Discount[] }]) => ({
            program,
            discounts: partDiscounts[part.partNumberBase] ?? []
          }))
          .filter(({ program, discounts }) => getPartLoyaltyPrograms(part).includes(program) && discounts.length)
          .reduce(
            (programDiscounts, { program, discounts }) => ({ ...programDiscounts, [program]: discounts }),
            {}
          )
        )
      );
  }
}
