import { FormBuilder } from "@angular/forms";
import * as FormTypes from "app/_components/checkout/checkout.component";
import { DeliveryItems } from "entities/delivery-items";
import { OrderType } from "entities/order-type";
import * as validators from "validators/checkout";

export const createCheckoutForm = (
    { regex }
) => {
    const formBuilder = new FormBuilder();
    return formBuilder.group<FormTypes.CheckoutFormGroup>({
        checkoutDetails: formBuilder.group<FormTypes.CheckoutDetailsFormGroup>({
            transactionType: formBuilder.control<OrderType | null>(null),
            orderChannel: formBuilder.control(null),
            poNumber: formBuilder.control(''),
            unitNumber: formBuilder.control('')
        }, {
            validators: [
                validators.validateTransactionTypeRequired(),
                validators.validateOrderChannelRequired(),
                validators.validatePONumberRequired(),
                validators.validateUnitNumberPattern()
            ]
        }),
        shippingAndDelivery: formBuilder.group<FormTypes.ShippingAndDeliveryFormGroup>({
            deliveryItems: formBuilder.control(DeliveryItems.all),
            shipComplete: formBuilder.control(false),
            deliveryType: formBuilder.control(''),
            cartData: formBuilder.control(null),
            shipTo: formBuilder.group<FormTypes.ShippingAndDeliveryShipToFormGroup>({
                addressNumber: formBuilder.control(null),
                city: formBuilder.control(null),
                customerName: formBuilder.control(null),
                customerName2: formBuilder.control(null),
                customerNumber: formBuilder.control(null),
                mscAccountNumber: formBuilder.control(null),
                phoneNumber: formBuilder.control(null),
                postalCode: formBuilder.control(null),
                salesperson: formBuilder.control(null),
                state: formBuilder.control(null),
                streetAddress: formBuilder.control(null),
                isEdited: formBuilder.control(false),
                isCanadaUser: formBuilder.control(false),
                shippingManagementEnabled: formBuilder.control(false)
            }),
            freightdeliveryoption: formBuilder.control('')
        }, {
            validators: [
                validators.procurementValidator(),
                validators.shipToValidator(
                    regex.regexPhonePattern,
                    regex.regexUSAPostalPattern,
                    regex.regexCanadaPostalPattern
                )
            ]
        }),
        paymentDetails: formBuilder.group<FormTypes.PaymentDetailsFormGroup>({
            selectedPayment: formBuilder.control(''),
            billTo: formBuilder.control(''),
            billToCC: formBuilder.control(null),
            i2p: formBuilder.control('Email'),
            i2pEmail: formBuilder.control(''),
            i2pSms: formBuilder.control(''),
            addedCCResult: formBuilder.control(''),
        }, {
            validators: validators.paymentDetailsValidators
        }),
        additionalDetails: formBuilder.group<FormTypes.AdditionalDetailsFormGroup>({
            altEmail: formBuilder.control(""),
            specialInstructions: formBuilder.control("")
        }),
        smsOpt: formBuilder.group<FormTypes.CheckoutSmsOptFormGroup>({
            optIn: formBuilder.control(false),
            contactName: formBuilder.control(null),
            mobileNumber: formBuilder.control(null)
        }),
        procuredAllLines: formBuilder.control(false),
        clearShoppingBasket: formBuilder.control(false),
        customer: formBuilder.control(null)
    }, {
        validators: validators.additionalDetailsValidators
    });

};
