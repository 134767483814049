<div ngbDropdown [autoClose]="false"
  display="dynamic" placement="bottom-end"
  #filtersDropdown="ngbDropdown">
  <button id="BCTable_btn_filters" type="button" ngbDropdownToggle class="form-control">
    SHOW FILTERS
  </button>
  <form action="#" #form="ngForm" ngbDropdownMenu class="filtersForm">
    <div class="filters">
      <div *ngFor="let filter of filters" class="form-group" [class.active]="filter.active">
        <div class="d-flex">
          <input type="checkbox" id="filter-{{filter.prop}}" [(ngModel)]="filter.active" name="filter-{{filter.prop}}" />
          <label for="filter-{{filter.prop}}">{{ filter.label }}</label>
        </div>
        <div *ngIf="filter.active" [ngSwitch]="filter.type" class="filter-control-wrapp">
          <select *ngSwitchCase="'select'"
            class="form-control"
            [(ngModel)]="filter.value"
            name="{{filter.prop}}">
            <option *ngFor="let option of filter.options" [value]="option.value">{{ option.label }}</option>
          </select>
          <multiselect *ngSwitchCase="'multiselect'"
            [selectedItems]="filter.value ? filter.value : []"
            [items]="filter.options"
            [label]="'Select ' + filter.label"
            (onChange)="updateMultiSelectValue($event, filter)">
          </multiselect>
          <input *ngSwitchCase="'date'"
            class="form-control d-block" tabindex="1" id="BCTable_filter_{{filter.prop}}"
            type="date" placeholder="MM/DD/YYYY"
            name="BCTable_filter_{{filter.prop}}"
            [(ngModel)]="filter.value"
            (change)="updateDate($event, filter)"
          />
          <autocomplete *ngSwitchCase="'autocomplete'"
            [url]="filter.autocomplete.url"
            [prop]="filter.autocomplete.prop"
            [branches]="branches"
            [label]="filter.label"
            [value]="filter.value.userId"
            [selected]="filter.value.name"
            (keypress)="onFilterKeypressNames($event, false)"
            (onChange)="updateFilterValue($event, filter.prop)">
          </autocomplete>
          <input *ngSwitchDefault
            type="text"
            name="{{filter.prop}}"
            [(ngModel)]="filter.value"
            class="form-control"
            (keypress)="onFilterKeypressNumeric($event)"
            (change)="onInputFilterValueChange($event, filter)"
          />
        </div>
      </div>
    </div>
    <div class="actions pt-2">
      <button type="submit" class="btn btn-primary ms-2" (click)="filtersDropdown.close(); applyFilters();">Apply Filters</button>
      <button type="button" class="btn btn-secondary ms-2" (click)="filtersDropdown.close(); resetFilters();">Clear</button>
      <button type="button" class="btn btn-secondary ms-2" (click)="filtersDropdown.close();">Close</button>
    </div>
  </form>
</div>
