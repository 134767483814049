<!--Nav Start-->
<div class="d-flex flex-column"></div>
<div id="orderConfirmation_div_Order" *ngIf="order">
  <div class="d-flex flex-column" *ngLet="{
    isInternal: isInternal$ | async,
    isWarehouseManaged: (branch$ | async).isWarehouseManaged,
    printPickTicket: printPickTicket$ | async,
    readSAPOrder: readSAPOrder$ | async,
    createPurchaseOrder: createPurchaseOrder$ | async,
    currencyCode: currencyCode$ | async,
    procurementGroups: procurementGroups$ | async,
    orderConfirmationTotal: orderConfirmationTotal$ | async
  } as vm" >
    <div #printsection>
      <div class="occ-top-title bg-primary">
        <h4>
          <span id="orderConfirmation_a_OrderPlaced" *ngIf="order.orderType === 'SalesOrder'">Your order has been placed.</span>
          <span id="orderConfirmation_a_QuoteGenerated" *ngIf="order.orderType === 'Quote'">Your quote has been generated.</span>
          <span>&nbsp;</span>
          <span id="orderConfirmation_a_OrderType0" *ngIf="order.orderType === 'SalesOrder'">Order confirmation</span>
          <span id="orderConfirmation_a_OrderType1" *ngIf="order.orderType === 'Quote'">Quote confirmation</span>
          <span>&nbsp;</span>
          <a id="orderConfirmation_a_DownloadSAPOrder" *ngIf="vm.readSAPOrder"
            class="hyperlink strong" href="javascript:void(0)" (click)="downloadSAPShortCut()">#{{order.sapOrderNo}}</a>
          <span id="orderConfirmation_a_ReadSAPOrder" *ngIf="!vm.readSAPOrder" class="strong">#{{order.sapOrderNo}}</span>
          <span>&nbsp;</span>
          <a id="orderConfirmation_a_CopyToClipboard" class="far fa-clone text-body" href="javascript:void(0)" (click)="copyToClipBoard(order.sapOrderNo)"></a>
        </h4>
        <div id="orderConfirmation_div_CustomerNotes" *ngIf="isCustomerNotesFeatureEnabled && commonDataService.User?.isInternal && order.customerId != commonDataService.DefaultCustomer.customerNumber">
          <a id="orderConfirmation_a_CustomerNotes" class="hyperlink" href="javascript:void(0)" (click)="showCustomerNotes(order.customerId)">Customer Notes</a>
        </div>
      </div>
      <div class="d-flex flex-wrap">
        <div class="col-sm-12 col-md-7 col-lg-7">
          <div id="orderConfirmation_div_IsLoadingOrderItems" class="row" *ngIf="orderItems == null">
            <h5>Loading order items...</h5>
          </div>
          <div class="billing-table table-responsive">
            <div class="billing-right d-flex py-3 my-3 gx-3">
              <h5 class="strong mb-0">My Order</h5>
              <span id="orderConfirmation_span_CartCount" *ngIf="cartCount">
                {{cartCount}} {{(cartCount==1 || cartCount==0)?'ITEM' :'ITEMS'}}
              </span>
            </div>
            <div class="order-confirmation-table-scroll table-responsive my-cart-list-table">
              <order-item-list *ngIf="orderItems && orderItems.length"
                [orderItems]="orderItems"
                [idSuffix]="KnownParts"
                [showCouponColumn]="includesCouponData"
                [orderItemCouponValues]="orderItemCouponValues"
              ></order-item-list>
              <order-item-list *ngIf="partsBuyOutData && partsBuyOutData.length"
                [orderItems]="partsBuyOutData"
                [idSuffix]="PartsBuyout"
                [showCouponColumn]="includesCouponData"
                [orderItemCouponValues]="orderItemCouponValues"
                header="PARTS BUYOUT"
              ></order-item-list>
              <order-item-list *ngIf="hotFlagData && hotFlagData.length"
                [orderItems]="hotFlagData"
                [idSuffix]="HotFlag"
                [showCouponColumn]="includesCouponData"
                [orderItemCouponValues]="orderItemCouponValues"
                [showHotFlagColumn]="true"
                [notHotFlag95And99]="notHotFlag95And99"
                header="HOT FLAG"
              ></order-item-list>
              <order-item-list *ngIf="stockTransferData && stockTransferData.length"
                [orderItems]="stockTransferData"
                [idSuffix]="StockTransferOrder"
                [showCouponColumn]="includesCouponData"
                [orderItemCouponValues]="orderItemCouponValues"
                header="STOCK TRANSFER ORDER"
                headerRight="Purchase Order will be created for the below items."
              ></order-item-list>
              <div class="container" *ngIf="orderItems || partsBuyOutData || hotFlagData || stockTransferData">
                <hr>
                <div class="row">
                  <div id="orderConfirmation_div_StockTransferOrderCouponsExistAndQuote" class="col-md-10 text-danger" *ngIf="includesCouponData && orderType === 'Quote'">
                    Coupons may expire before the end of the quote period and affect the final price.
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-5"></div>
                  <div class="col-3 text-end bold-font">Subtotal</div>
                  <div class="col-2 text-end bold-font">{{vm.orderConfirmationTotal.subTotal | currency: vm.currencyCode :'symbol':'1.2-2'}}</div>
                  <div class="col-2"></div>
                </div>
                <div class="row mb-4" *ngIf="vm.orderConfirmationTotal.cartCouponTotal != 0">
                  <div class="col-5"></div>
                  <div class="col-3 text-end bold-font text-nowrap">Cart Coupon Total</div>
                  <div class="col-2 text-end text-danger bold-font">{{vm.orderConfirmationTotal.cartCouponTotal | currency: vm.currencyCode :'symbol':'1.2-2'}}</div>
                  <div class="col-2"></div>
                </div>
                <div class="row mb-4" [hidden]="!showFreightRow">
                  <div class="col-5"></div>
                  <div class="col-3 text-end bold-font text-nowrap">Estimated freight
                    <i class="fas fa-info-circle info-btn" *ngIf="vm.orderConfirmationTotal.estimatedFreight == 0 && commonDataService.User?.isInternal"
                      placement="right" ngbTooltip="$0.00 freight/delivery will not be included on the order in SAP">
                    </i>
                  </div>
                  <div class="col-2 text-end bold-font">{{vm.orderConfirmationTotal.estimatedFreight | currency: vm.currencyCode :'symbol':'1.2-2'}}</div>
                  <div class="col-2"></div>
                </div>
                <div class="row mb-4" [hidden]="!showDeliveryRow">
                  <div class="col-5"></div>
                  <div class="col-3 text-end bold-font text-nowrap">Estimated delivery
                    <i class="fas fa-info-circle info-btn" *ngIf="vm.orderConfirmationTotal.estimatedDelivery === 0 && commonDataService.User?.isInternal"
                      placement="right" ngbTooltip="$0.00 freight/delivery will not be included on the order in SAP">
                    </i>
                  </div>
                  <div class="col-2 text-end bold-font">{{vm.orderConfirmationTotal.estimatedDelivery | currency: vm.currencyCode :'symbol':'1.2-2'}}</div>
                  <div class="col-2"></div>
                </div>
                <ng-container *ngIf="deliveryFeeWithCreditVisible(order?.deliveryOptions, order?.deliveryFee) && isCODeliveryAndBagFee$ | async">
                  <div class="row mb-4">
                    <div class="col-5"></div>
                    <div class="col-3 text-end bold-font text-nowrap">Retail Delivery Fee</div>
                    <div class="col-2 text-end bold-font">{{ vm.orderConfirmationTotal.deliveryFee | currency: vm.currencyCode :'symbol':'1.2-2'}}</div>
                    <div class="col-2"></div>
                  </div>
                  <div class="row mb-4" *ngIf="order.deliveryFeeCredit !== 0">
                    <div class="col-5"></div>
                    <div class="col-3 text-end bold-font text-nowrap">Delivery Fee Credit</div>
                    <div class="col-2 text-end bold-font">{{ vm.orderConfirmationTotal.deliveryFeeCredit | currency: vm.currencyCode :'symbol':'1.2-2'}}</div>
                    <div class="col-2"></div>
                  </div>
                </ng-container>
                <div class="row mb-4">
                  <div class="col-5"></div>
                  <div class="col-3 text-end bold-font text-nowrap">Estimated sales tax</div>
                  <div class="col-2 text-end bold-font">{{vm.orderConfirmationTotal.taxAmount | currency: vm.currencyCode :'symbol':'1.2-2'}}</div>
                  <div class="col-2"></div>
                </div>
                <div class="row mb-4">
                  <div class="col-5"></div>
                  <div class="col-3 text-end bold-font">
                    TOTAL<span style="position: absolute" *ngIf="!commonDataService.User?.isInternal">*</span>
                  </div>
                  <div class="col-2 text-end bold-font">{{vm.orderConfirmationTotal.total | currency: vm.currencyCode :'symbol':'1.2-2'}}</div>
                  <div class="col-2"></div>
                </div>
                <div class="row mb-4" *ngIf="vm.orderConfirmationTotal.couponTotal != 0">
                  <div class="col-5"></div>
                  <div class="col-3 text-end bold-font text-nowrap">Your Savings</div>
                  <div class="col-2 text-end text-danger bold-font">{{vm.orderConfirmationTotal.couponTotal | currency: vm.currencyCode :'symbol':'1.2-2'}}</div>
                  <div class="col-2"></div>
                </div>
              </div>
            </div>
            <div id="orderConfirmation_div_UserInternalNoTaxNoShipping" *ngIf="!commonDataService.User?.isInternal" style="text-align: right; padding-top: 20px; padding-right: 135px; font-size: 12px">
              *Does not include actual tax or shipping
            </div>
          </div>
        </div>
        <!--<div class="col-sm-12 col-md-1 col-lg-1 text-align-left"></div>-->
        <div class="col-sm-12 col-md-5 col-lg-5 text-align-left">
          <div id="orderConfirmation_div_LoadingOrderDetails" *ngIf="order == null">
            <h5>Loading order details...</h5>
          </div>
          <table class="table order">
            <tr>
              <td>
                <h1>Customer Name:</h1>
              </td>
              <td class="order-billing-summary-text">
                {{order.soldToName1}}
              </td>
            </tr>
            <tr>
              <td>
                <h1>Customer Number:</h1>
              </td>
              <td class="order-billing-summary-text">
                {{order.customerId}}
              </td>
            </tr>
            <tr>
              <td>
                <h1>Branch:</h1>
              </td>
              <td class="order-billing-summary-text">
                <span class="p-0" *ngLet="branch$ | async as branch">{{branch?.code}} - {{branch?.displayName}}</span>
              </td>
            </tr>
            <tr>
              <td>
                <h1>Billing:</h1>
              </td>
              <td class="order-billing-summary-text">
                {{order.soldToName1}}
                <br />{{order.soldToAddress}}
                <br />{{order.soldToCity !='' && order.soldToCity !=null && order.soldToCity !=undefined?
                  order.soldToCity + ',':''}} {{order.soldToState}} {{order.soldToZip}}
                <br />{{order.soldToPhone}}
              </td>
            </tr>
            <tr>
              <td>
                <h1>Payer:</h1>
              </td>
              <td class="order-billing-summary-text">
                {{order.billingType}}
              </td>
            </tr>
            <tr>
              <td>
                <h1>Delivery:</h1>
              </td>
              <td class="order-billing-summary-text">
                {{uniqueDeliveryItems.length >= 2 ? 'Multiple' : deliveryOption}}
              </td>
            </tr>
            <tr>
              <td>
                <h1>Alternate Shipping Address:</h1>
              </td>
              <td class="order-billing-summary-text">
                {{order.shipToName1}}
                <br />{{order.shipToAddress}}
                <br />{{order.shipToCity !='' && order.shipToCity !=null && order.shipToCity !=undefined ?
                  order.shipToCity + ',':''}} {{order.shipToState}} {{order.shipToZip}}
                <br />{{order.shipToPhone}}
              </td>
            </tr>
            <tr id="orderConfirmation_tr_UserInternalSalespersonAndSpecialInstructions" *ngIf="commonDataService.User?.isInternal">
              <td>
                <h1>Salesperson:</h1>
              </td>
              <td class="order-billing-summary-text">
                {{order.salesPerson != null && order.salesPerson != '' ? order.salesPerson : "Not available"}}
              </td>
            </tr>
            <tr>
              <td>
                <h1>Special Instructions</h1>
              </td>
              <td class="order-billing-summary-text">
                {{order.specialInstructions==""? "None" : order.specialInstructions}}
              </td>
            </tr>

          </table>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center order-confirmation-btn" *ngLet="{
      createBillingShown: createBillingShown$ | async,
      createBillingEnabled: createBillingEnabled$ | async,
      canCreateBilling: canCreateBilling$ | async
    } as billingvm">
        <button id="orderConfirmation_button_PrintQuote" type="button" class="btn btn-default yellow-btn margin-r-5"
          [disabled]="createBillingFlag" *ngIf="order.orderType === 'Quote'"
          (click)="printSAPQuote()">
        Print Quote
      </button>
        <button id="orderConfirmation_button_PrintConfirmation" type="button" class="btn btn-default yellow-btn margin-r-5"
          *ngIf="order.orderType === 'SalesOrder' && !vm.isInternal"
          (click)="printDiv()">
        Print Confirmation
      </button>
      <span>
        <button id="orderConfirmation_button_CreatePO" type="button" class="btn btn-default yellow-btn margin-r-5"
        [disabled]="((createBillingFlag && !vm.isWarehouseManaged) || printPOFlag) && vm.createPurchaseOrder"
            *ngIf="showCreatePOButton && vm.createPurchaseOrder && isShowCreatePOButton && vm.procurementGroups"
            (click)="createPurchaseOrders()">
            <span *ngIf="!isShowHotFlagCreatePOButton && hotFlag95And99Data.length == 0">Create PO</span>
            <span *ngIf="!isShowHotFlagCreatePOButton && hotFlag95And99Data.length > 0">Create Req or PO</span>
        </button>
        <button id="orderConfirmation_button_CreatePO_hotFlag" type="button" class="btn btn-default yellow-btn margin-r-5"
            [disabled]="((createBillingFlag && !vm.isWarehouseManaged) || printPOFlag) && vm.createPurchaseOrder"
            *ngIf="isShowHotFlagCreatePOButton && vm.procurementGroups"
            (click)="createPurchaseOrders()">
          Create Req or PO
        </button>
        <ng-container id="orderConfirmation_container_PrintPickingTicket"
          *ngIf="vm.printPickTicket &&
                  vm.isWarehouseManaged && (readyToPickShown$ | async); else legacyPrintPickTicketButton">
          <span
            *ngIf="order.orderType === 'SalesOrder' &&
                  (partsBuyOutData.length > 0 || stockTransferData.length > 0 || hotFlag95And99Data.length > 0) &&
                  (showCreatePOButton && vm.createPurchaseOrder && isShowCreatePOButton && vm.procurementGroups)">Or</span>
          <button id="orderConfirmation_button_PrintPickingTicket" type="button" class="btn btn-default yellow-btn margin-r-5"
            *ngIf="order.orderType === 'SalesOrder' && vm.printPickTicket" [disabled]="!(readyToPickEnabled$ | async) || isRTPDisabled"
              (click)="readyToPick()">
            Ready To Pick
          </button>
        </ng-container>
        <ng-template #legacyPrintPickTicketButton>
          <span id="orderConfirmation_span_LegacyPrintPickingTicket"
            *ngIf="order.orderType === 'SalesOrder' && (partsBuyOutData.length > 0 || stockTransferData.length > 0) &&
                vm.printPickTicket && !vm.isWarehouseManaged">Or</span>
          <button id="orderConfirmation_button_LegacyPrintPickingTicket" type="button" class="btn btn-default yellow-btn margin-r-5"
            [disabled]="createBillingFlag || printPickTicketFlag"
            *ngIf="order.orderType === 'SalesOrder' && vm.printPickTicket && !vm.isWarehouseManaged"
            (click)="getPickTicket()">
            Print Pick Ticket
          </button>
        </ng-template>
        <ng-container *ngIf="!isCODeliveryAndBagFeeFeatureEnabled && billingvm.createBillingShown && billingvm.canCreateBilling" id="orderConfirmation_container_CreateBilling">
          <button id="orderConfirmation_button_CreateBilling" type="button" class="btn btn-default yellow-btn margin-r-5"
            [disabled]="!billingvm.createBillingEnabled || createBillingButtonClicked"
            (click)="createBilling();">
            Create Billing
          </button>
        </ng-container>
        <ng-container *ngIf="isCODeliveryAndBagFeeFeatureEnabled && billingvm.createBillingShown && billingvm.canCreateBilling" id="orderConfirmation_container_CreateBilling">
          <ng-container *ngIf="branch$ | async as branch">
          <button id="orderConfirmation_button_CreateBilling" type="button" class="btn btn-default yellow-btn margin-r-5"
            [disabled]="!billingvm.createBillingEnabled || createBillingButtonClicked"
            (click)="onCreateBilling(branch?.code);">
            Create Billing
          </button>
        </ng-container>
        </ng-container>
      </span>

      <span>
        <a class="mb-0 ms-5 hyperlink cursor-pointer" (click)="clearCustomerValue()" href="#">
          New Search
        </a>
      </span>
    </div>
  </div>
</div>

<ng-template id="orderConfirmation_template_CreateBillingModal" #createBillingModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">IBS/MSC Authorization Number</h4>
    <button id="orderConfirmation_button_Close" type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <div id="orderConfirmation_div_CreateBillingErrorMessages_{{i}}" *ngFor="let errMsg of createBillingErrorMsgs; let i = index">
          <div class=" alert" [ngClass]="errMsg.type == 5 ? 'alert-multi-line'
                              : errMsg.type == 0 ? 'alert-success'
                              : errMsg.type == 1 ? 'alert-info'
                              : errMsg.type == 2 ? 'alert-warning' : 'alert-danger'" style="margin-bottom: 5px;">
        <i id="orderConfirmation_i_NotificationIcon_{{i}}" class="fas" [ngClass]="errMsg.type == 5 ? ''
                           : errMsg.type == 0 ? 'fa fa-check-circle success'
                          : errMsg.type == 1 ? 'fa fa-exclamation-triangle info'
                          : errMsg.type == 2 ? 'fa fa-exclamation-triangle warning'
                          : errMsg.type == 3 ? 'fa fa-times-circle danger'
                          : 'fa fa-exclamation-circle danger'"></i>
        &nbsp;
        <span [ngClass]="errMsg.type == 5 ? ''
                              : errMsg.type == 0 ? 'success'
                              : errMsg.type == 1 ? 'info'
                              : errMsg.type == 2 ? 'warning' : 'danger'">{{errMsg.message}}</span><br />
      </div>
    </div><br />
    <form>
      <div class="form-group">
         <div class="input-group">
            <input id="orderConfirmation_text_AuthorizationNumber_{{i}}" class="form-control" type="text" [(ngModel)]="authorizationNumber"
            name="authorizationNumber" maxlength="10" (keypress)="onCreateBillingKeypress($event)">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
      <button id="orderConfirmation_button_Submit" type="button" class="btn btn-md black-text btnBg yellow-btn"
      [disabled]="!authorizationNumber" (click)="submitIBSOverrideCreateBilling()" data-dismiss="modal">
      Submit
      Override & Create Billing
    </button>
  </div>
</ng-template>
