import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CouponService {
  calculatePartCouponTotals<T extends { sapCoupons: any[] }>(cartLineItem: T): T & { hasCoupon: boolean, couponTotal: number } {
    return calculatePartCouponTotals(cartLineItem);
  }
}

export function calculatePartCouponTotals<T extends { sapCoupons: { type: 'part' | string, amount: number }[] }>(cartLineItem: T): T & { hasCoupon: boolean, couponTotal: number } {
  return {
    ...cartLineItem,
    hasCoupon: Boolean(cartLineItem?.sapCoupons?.length),
    couponTotal:  cartLineItem?.sapCoupons ? cartLineItem?.sapCoupons
      ?.filter(coupon => coupon.type === 'part')
      .reduce((sum, currentCoupon) => sum + currentCoupon.amount, 0) : 0
  };
}
