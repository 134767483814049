<div [id]="containerId">
    <input
        [id]="inputId"
        [name]="inputName"
        type="text"
        [(value)]="value"
        [placeholder]="inputPlaceholder"
        [autocomplete]="inputAutoComplete"
        (keypress)="_keyPress($event)"
        (keyup)="quantityKeyup($event)"
        (focusout)="quantityFocusout($event)"
        (ngModelChange)="updateQuantity($event)"
        [tabIndex]="disabled ? -1 : 0"
        [disabled]="disabled"
        [ngClass]="checkRangeClass"
        [maxLength]="(''+max).length"
        #quantityInput
    />
    <button
        [id]="incrementId"
        (click)="incrementQuantity()"
        [tabIndex]="disabled ? -1 : 0"
        [disabled]="disabled || incrementDisabled"
        #quantityIncrement
    >
        &nbsp;
        <span class="noDisplay" aria-hidden="false">Increase Quantity</span>
    </button>
    <button
        [id]="decrementId"
        (click)="decrementQuantity()"
        #quantityDecrement
        [tabIndex]="(disabled || decrementDisabled) ? -1 : 0"
        [disabled]="disabled || decrementDisabled"
    >
        &nbsp;
        <span class="noDisplay" aria-hidden="false">Decrease Quantity</span>
    </button>
</div>
