<ng-container *ngLet="isInternalUser$ | async as isInternalUser">
  <div class="row order-confirmation-order-item-row p-1" id="orderConfirmation_tr_{{idSuffix}}OrderItems">
    <div class="text-start" [ngClass]="createColumnClass('partNumber', isInternalUser)"><b>Part&nbsp;#</b></div>
    <div class="text-start" [ngClass]="createColumnClass('item', isInternalUser)"><b>Item</b></div>
    <div class="text-end" [ngClass]="createColumnClass('hotFlag', isInternalUser)" *ngIf="showHotFlagColumn"><b>HotFlag Value</b></div>
    <div class="text-end" [ngClass]="createColumnClass('qty', isInternalUser)"><b>Quantity</b></div>
    <ng-container *ngIf="showCouponColumn">
      <div class="text-end" [ngClass]="createColumnClass('totalCoupon', isInternalUser)" id="orderConfirmation_td_{{idSuffix}}TotalCoupons">
        <b>Total&nbsp;Coupons</b>
      </div>
      <div class="text-end" [ngClass]="createColumnClass('finalPrice', isInternalUser)" id="orderConfirmation_td_{{idSuffix}}CouponFinalPrice">
        <b>Final&nbsp;Price</b>
      </div>
    </ng-container>
    <div class="text-end" [ngClass]="createColumnClass('total', isInternalUser)" id="orderConfirmation_td_{{idSuffix}}Total" *ngIf="!showCouponColumn"><b>Total</b></div>
    <div class="text-start" [ngClass]="createColumnClass('jobNumber', isInternalUser)" id="orderConfirmation_td_{{idSuffix}}Job#" *ngIf="isInternalUser"><b>Job&nbsp;#</b>
    </div>
  </div>
</ng-container>