import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { AuthenticationService } from '../_modules/authentication/authentication.service';
import * as constants from "app/app.constants";
import { User } from "oidc-client";

@Injectable({ providedIn: "root" })
export class UserInitializerService {
  constructor(
    private authenticationService: AuthenticationService,
    @Inject(constants.RUSH_IDENTITY_PROVIDER_TOKEN) private rushIdentityProvider: string,
    @Inject(constants.NEW_USER_REDIRECT_URL_TOKEN) private newUserRedirectUrl: string,
    @Inject(DOCUMENT) private document: { location: Location }
  ) { }

  initialize() {
    return this.authenticationService.initialize()
      // Redirect new external users to the new-user-redirect-url
      .then((user: User) => {
        if (user && user.profile && user.profile.newUser && user.profile.idp !== this.rushIdentityProvider) {
          return this.authenticationService.removeUser()
            .then(() => {
              this.document.location.assign(this.newUserRedirectUrl);
              return null;
            });
        }
        return user;
      });
  }
}
