<div class="modal-header heading-users d-block">
  <div class="title-of-box-hold">
    <div class="title-of-box">
      <span>Promotional Part</span>
    </div>
  </div>
</div>

<div *ngLet="(featuredPartNationalInventoryAdded$ | async) as featuredPartNationalInventoryAdded">
  <div class="modal-body" *ngLet="(cartId$ | async) as cartId">
    <div class="d-flex" *ngIf="(featuredParts$ | async) as featuredParts">
      <div id="suggestedSelling_div_featuredPart_{{i}}" class="d-flex mx-auto" *ngFor="let featuredPart of featuredParts; let i = index">
        <part-card allowPriceAdjust [part]="featuredPart" (addedToCart)="onAcceptOffer($event, cartId)" [partType]="partType"></part-card>
      </div>
    </div>
    <div class="d-flex">
      <button id="suggestedSelling_button_HasNotAcceptedOffer" *ngIf="!hasAcceptedOffer" class="btn btn-default yellow-btn mx-auto mt-3"
              [ngbPopover]="declineReasonPopover" placement="top" autoClose="outside">No Thanks</button>
      <button id="suggestedSelling_button_HasAcceptedOffer" *ngIf="hasAcceptedOffer" class="btn btn-default yellow-btn mx-auto mt-3"
              (click)="goToCheckout()">Continue</button>
    </div>
  </div>
</div>

<ng-template id="suggestedSelling_popover_DeclineReason" #declineReasonPopover>
  <div class="suggestedSellingCloseReason" style="width: 6em" *ngLet="(cartId$ | async) as cartId">
    <ng-container id="suggestedSelling_container_IsInternalUser" *ngIf="commonDataService.User?.isInternal">
      <div>
        <input id="suggestedSelling_radio_Price" type="radio" name="declineReasonOptions" value="priceReasontext" [(ngModel)]="declineReasontext" (change)="onDeclineOffer(cartId)">
        <label for="suggestedSelling_radio_Price">Price</label>
      </div>
      <div>
        <input id="suggestedSelling_radio_NotNeeded" type="radio" name="declineReasonOptions" value="notNeededText" [(ngModel)]="declineReasontext" (change)="onDeclineOffer(cartId)">
        <label for="suggestedSelling_radio_NotNeeded">Not needed</label>
      </div>
      <div>
        <input id="suggestedSelling_radio_DidNotOffer" type="radio" name="declineReasonOptions" value="didNotOfferText" [(ngModel)]="declineReasontext" (change)="onDeclineOffer(cartId)">
        <label for="suggestedSelling_radio_DidNotOffer">Did not offer</label>
      </div>
    </ng-container>
    <ng-container id="suggestedSelling_container_IsNotInternalUser" *ngIf="!commonDataService.User?.isInternal">
      <div>
        <input id="suggestedSelling_radio_NoThanks" type="radio" name="declineReasonOptions" value="noThanksText" [(ngModel)]="declineReasontext" (change)="onDeclineOffer(cartId)">
        <label for="suggestedSelling_radio_NoThanks">No thanks</label>
      </div>
      <div>
        <input id="suggestedSelling_radio_NotInterested" type="radio" name="declineReasonOptions" value="notInterestedText" [(ngModel)]="declineReasontext" (change)="onDeclineOffer(cartId)">
        <label for="suggestedSelling_radio_NotInterested">Not interested</label>
      </div>
    </ng-container>
  </div>
</ng-template>
