import { BackCounterNote } from "entities/notes";

export interface NotesComponentConfig {
  type: string;
  id: string;
  pos: string;
  releaseLock: boolean;
  listenForNewMessages: string[];
  editingEnabled: boolean;
  notForRelease: boolean;
}

export interface NotesComponentState {
  loading: boolean;
  id: string;
  locked: boolean;
  notes: BackCounterNote[];
}

export interface NotesConnectionConfig {
  url: string;
}

export interface NoteMessage {
  eventId: string;
  orderNumber: string;
  noteId: string;
}

export enum NotesConnectionEventId {
  newNoteItem = "NEW_NOTE_ITEM",
  newNoteRequest = "NEW_NOTE_REQUEST"
}

