
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, switchMap, catchError } from 'rxjs/operators';
import { CreateFavoritePartRequest } from 'entities/create-favorite-part-request';
import { Part } from 'entities/part';
import { BaseService } from 'services/base.service';
import { LoaderService } from './loader.service';
import { ToastService } from './toast.service';
import { ToastType } from 'entities/toast-type';
import { FavoritePartView } from 'entities/parts/favorite-part-view';
import { Store } from "@ngrx/store";
import { AppState } from 'store/app-state';
import * as CartActions from "store/cart/cart.actions";


@Injectable({
  providedIn: "root"
})
export class FavoritePartsService extends BaseService {

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private loader: LoaderService,
    private store: Store<AppState> ) {
    super();
  }

  getFavoriteParts(): Observable<FavoritePartView[]> {
    return this.http.get<FavoritePartView[]>(`api/user/favoriteparts`);
  }

  addFavoritePart(favoritePart: CreateFavoritePartRequest): Observable<{ favoritePart: FavoritePartView }> {
    return this.http.post<{ favoritePart: FavoritePartView }>(`api/user/favoritepart`, favoritePart);
  }

  deleteFavoritePart(partId: string, partNumber: string): Observable<boolean> {
    const data = {
      partId: partId || '',
      partNo: partNumber || ''
    };

    return this.http.post<boolean>(`api/user/delete-favorite-part`, data);
  }

  addPartToFavorites(part: Part): Observable<any> {
    const favoritePart: CreateFavoritePartRequest = {
      partId: part.partId || '',
      partNo: part.number,
      partDescription: part.description || '',
      image: part.image,
      vmrsCode: part.vmrsCode || '',
      vmrsDescription: part.vmrsDescription || '',
      manufacturer: part.manufacturer || '',
    };
    this.loader.loading = true;
    return this.addFavoritePart(favoritePart).pipe(
      tap(response => {
        if (part.partId  === response.favoritePart.partId || part.number === response.favoritePart.partNo) {
          part.isFavoritePart = true;
          const message = `Part number ${part.number} has been added to your favorite part list.`;
          this.toastService.showToast(message, ToastType.Success);
        }
        this.loader.loading = false;
      }),
      catchError(() => {
        this.loader.loading = false;
        return of({})
      })
    )
  }

  removePartFromFavorites(part: Part | { partId: string; number: string; isFavoritePart?: boolean }): Observable<boolean> {
    this.loader.loading = true;
    return this.deleteFavoritePart(part.partId, part.number).pipe(
      tap((response: boolean) => {
        if (response) {
          part.isFavoritePart = false;
          this.toastService.showToast(`Part number ${part.number} has been removed from your favorite part list.`, ToastType.Success);
          this.store.dispatch(CartActions.removePartFromFavoritesSuccess({ partId: part.partId }));        
        }
        this.loader.loading = false;
      }),
      catchError(() => {
        this.loader.loading = false;
        return of(false)
      })
    );
  }
}
