import { CartResultLineClient } from 'entities/cart-result';
import { DeliveryTypes } from 'entities/cart-search';
import { CartItemDeliveryOption, DeliveryLineItem } from 'entities/carts/cart-item-delivery-option';

export interface CartItemAndDelivery {
    selected: boolean;
    cartItem: CartResultLineClient;
    deliveryOption: string | CartItemDeliveryOption;
    lineId: number;
    cartItemId: string;
    hasChanges?: boolean;
    hazmat: boolean;
}

export type DeliveryOption = {
    key: string;
    option: string;
    identifier: string;
    label: string;
};

export const DeliveryOptions: DeliveryOption[] = [
    { key: "W", option: 'Pickup', identifier: "WillCall", label: "Will call" },
    { key: "A", option: 'AdHoc', identifier: "adhocLineItems", label: "Basket/Returns/Showroom" },
    { key: "L", option: 'SelfPick', identifier: "SelfPick", label: "Front counter self pick" },
    { key: "K", option: 'FcKnown', identifier: "FCKnown", label: "Front counter warehouse pick" },
    { key: "D", option: 'Delivery', identifier: "RTCDelivery", label: "Rush Truck Centers delivery" },
    { key: "C", option: 'ShipTo', identifier: "FreightDelivery", label: "Freight delivery" },
    { key: "S", option: 'ShipDirect', identifier: "ShipDirectToCustomer", label: "Ship direct to customer" }
];

export interface CartItemAndDeliveryResult {
    deliveryHeader: DeliveryTypes,
    lineItems: DeliveryLineItem[]
}