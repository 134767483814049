import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Permission } from 'entities/enums';
import { ToastService } from 'services/toast.service';
import { AuthorizationService } from 'services/authorization.service';
import { LoggerService } from "services/logger.service";
import { TableService } from 'services/table.service';
import { VendorService } from 'services/vendor.service';
import { ToastType } from 'entities/toast-type';

@Component({
  selector: 'favourite-vendors-modal',
  templateUrl: './favourite-vendors-modal.component.html'
})
export class FavouriteVendorsModalComponent implements OnInit, OnDestroy {
  @ViewChild('search', { static: true }) searchRef: ElementRef;
  permission: any = Permission;
  readVendor: any;
  favouriteVendors: any[];
  // pager object
  pager: any = {};
  // paged items
  pagedFavouriteVendors: any[];
  filteredFavouriteVendors: any[];
  filterKey: string = '';
  branchCode: string = '';

  vendorsServicesubscription: any = this.vendorService.notifyFavouriteVendorChangeEventEmitter.subscribe((res) => {
    this.getFavouriteVendors();
  });

  constructor(
    private toastService: ToastService,
    private pagerService: TableService,
    private vendorService: VendorService,
    private authorizationService: AuthorizationService,
    private activeModal: NgbActiveModal,
    private loggerService: LoggerService
  ) {
    this.readVendor = this.permission.ReadVendors;
  }

  ngOnInit() {
    this.getFavouriteVendors();

    setTimeout(() => this.searchRef.nativeElement.focus(), 0);
  }

  getFavouriteVendors(): void {
    if (!this.authorizationService.hasPermission(this.readVendor)) {
      this.favouriteVendors = [];
      return;
    }

    this.filterKey = '';
    this.favouriteVendors = null;
    this.vendorService.getFavouriteVendors()
      .then((favVendors) => {
        if (favVendors.ErrorType != undefined && favVendors.ErrorType != null && favVendors.ErrorType != 200) {
          this.toastService.errorMessage('FavouriteVendorsModalComponent', 'getFavouriteVendors', 'getFavouriteVendors', favVendors);
        } else {
          if (favVendors != undefined && favVendors != null && favVendors.length > 0) {
            this.favouriteVendors = favVendors;
            this.filteredFavouriteVendors = favVendors;
            this.setPage(1);
          } else {
            this.favouriteVendors = [];
          }
        }
        this.loggerService.verbose('Favourite Vendors modal component data : ', favVendors);
      },
        (error) => { });
  }

  setPage(page: number) {
    if (page < 1 || (page > this.pager.totalPages && this.pager.totalPages > 0)) {
      return;
    }
    // get pager object from service
    this.pager = this.pagerService.getPager(this.filteredFavouriteVendors.length, page, 6);

    // get current page of items
    this.pagedFavouriteVendors = this.filteredFavouriteVendors.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  filter() {
    if (this.filterKey) {
      const filterKeyLowerCase = this.filterKey.toLowerCase();
      this.filteredFavouriteVendors = this.favouriteVendors.filter(e => {
        return ((e.name && e.name.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.name2 && e.name2.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.number && e.number.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.address && e.address.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.city && e.city.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.state && e.state.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.phone && e.phone.toLowerCase().indexOf(filterKeyLowerCase) > -1) ||
          (e.zipCode && e.zipCode.toLowerCase().indexOf(filterKeyLowerCase) > -1));
      });

      this.setPage(1);
    } else {
      this.filteredFavouriteVendors = this.favouriteVendors;
      this.setPage(1);
    }

  };

  removeFavouriteVendors(selectedVendor) {
    this.vendorService.deleteFavouriteVendors(selectedVendor.number)
      .then(favVendors => {
        if (favVendors?.ErrorType && favVendors.ErrorType != 200) {
          this.toastService.errorMessage('FavouriteVendorsModalComponent', 'removeFavouriteVendors', 'deleteFavouriteVendors', favVendors);
        } else {
          this.getFavouriteVendors();
        }
      });
  }

  onSelectFavouriteVendor(vendor) {
    this.vendorService.validateVendor(vendor, this.branchCode)
      .then(isValid => {
        if (isValid) {
          this.activeModal.close(vendor);
        } else {
          this.toastService.showToast('This vendor account is no longer valid. Please select a different vendor account.', ToastType.Error);
        }
      });
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  ngOnDestroy() {
    if (this.vendorsServicesubscription != null && this.vendorsServicesubscription != undefined) {
      this.vendorsServicesubscription.unsubscribe();
    }
  }
}
