import { BackCounterRequestPartItemAction, RequestOrderItem, RequestPartItemsUnsavedChanges } from "./back-counter.entities";

const isMatch = (p: RequestOrderItem, part: RequestOrderItem): boolean => {
  return part._id === p._id;
}

export const getMatchedPart = (
  part: RequestOrderItem,
  parts: RequestOrderItem[]
): RequestOrderItem | undefined => parts.find(p => isMatch(p, part));

export const patchRequestPartItems = (
  parts: RequestOrderItem[],
  part: RequestOrderItem,
  props?: any,
): RequestOrderItem[] => {

  if (!part) {
    return parts;
  }

  // ADD NEW PART
  if (part.unsavedChanges === BackCounterRequestPartItemAction.add) {
    if( parts.filter((p: RequestOrderItem) => isMatch(p, part))?.length <= 0 ){
      parts.push({ ...part, _id: `${part.jobNumber}_${parts.length}` });
      return parts;
    }
  }

  // REMOVE PART
  if (part.unsavedChanges === BackCounterRequestPartItemAction.remove) {
    if( part.itemNumber){
      return parts.map((p: RequestOrderItem) => isMatch(p, part) ? { ...p, unsavedChanges: BackCounterRequestPartItemAction.remove } : p);
    }else{
      return parts.filter((p: RequestOrderItem) => !isMatch(p, part));
    }
  };

  // UPDATE PART
  return parts.map((p: RequestOrderItem) => {
    if (isMatch(p, part)) {
      const updatedPart = { ...part };
      for (let key in props) {
        if (props.hasOwnProperty(key)) {
          updatedPart[key] = props[key]
        }
      }
      return updatedPart;
    }
    return p;
  });
};


export const getUnsavedChanges = (updatedParts: RequestOrderItem[], parts: RequestOrderItem[]): RequestPartItemsUnsavedChanges => {

  const partsToAdd = parts.filter((p: RequestOrderItem) => p.unsavedChanges === BackCounterRequestPartItemAction.add).map(p => {
    const partIsUpdated = updatedParts.find((updatedPart: RequestOrderItem) => updatedPart._id === p._id);
    return partIsUpdated ? partIsUpdated : p;
  });

  const partsToRemove = parts.filter((p: RequestOrderItem) => p.unsavedChanges === BackCounterRequestPartItemAction.remove);

  const partsForUpdate = updatedParts
    .filter(((p: RequestOrderItem) => !partsToAdd.find(addedPart => addedPart._id === p._id)))
    .filter(((p: RequestOrderItem) => !partsToRemove.find(addedPart => addedPart._id === p._id))).map(
      (part) => {
        const p = parts.find(x => x.materialNumber === part.materialNumber);
        const priceVerifyComp = p.priceVerifyComp ?? "";
        return {...part, priceVerifyComp};
      }
    );

  return {
    itemsToAdd: partsToAdd,
    itemsToUpdate: partsForUpdate,
    itemsToRemove: partsToRemove
  };
};

export const mapRequestPartItemsForUpdate = (
  parts: RequestOrderItem[]
): RequestOrderItem[] => {
  return parts.map(p => {
    return {
      deliveryDate: p.deliveryDate || "",
      itemNumber: p.unsavedChanges === "ADD" ? "" : p.itemNumber,
      jobNumber: p.jobNumber || "",
      materialNumber: p.materialNumber || "",
      itemCategory: p.itemCategory || "P002",
      orderQuantity: p.orderQuantity,
      orderUom: p.orderUom || "EA",
      mainPrItem: p.mainPrItem || "",
      purchaseVendor: p.purchaseVendor || "",
      coreOption: mapCoreOption(p.coreOption),
      manPrice: p.frieght,
      _id: p._id,
      notesExits: p.noteExists,
      itemNotifflag: p.itemNotifflag,
      manualFreightFlag: "",
      freightInbound:  0,
      priceVerifyComp: p.priceVerifyComp,
      priceVerifyRel: p.priceVerifyRel
    }
  });
}

export const mapCoreOption = (coreOption: string) => {
  if (coreOption === "CORER") {
    return "CORE1";
  } else if (coreOption === "NOCORER") {
    return "NOCORER";
  } else {
    return coreOption;
  }
}
