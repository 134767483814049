<div >
  <button id="payment_failed_button_close" type="button" class="close" data-dismiss="modal" aria-label="Close"
    tabindex="-1" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  
  <div class="modal-header">
    <span id="title">PAYMENT FAILED.</span>
  </div>

  <div class="modal-body">
    <span id="paymentFailedBody">Credit Card Processing Failed. Please select an alternative device.</span>
  </div>

  <div class="modal-footer">
    <button id="payment_failed_choose_another_device" type="button" class="btn btn-default black-btn"
      (click)="chooseAnotherDevice()">Choose another device</button>
    <button id="payment_failed_retry" type="button" class="btn btn-default yellow-btn"
      (click)="retry()">Retry</button>
  </div>
</div>
