<div (click)="appClicked()" (keyup)="appClicked()">
  <!--Header Start-->
  <div #fixedTop class="fixed-top">
    <!-- Full-width headers -->
    <header-nav></header-nav>
    <header-sales></header-sales>
    <!-- Container-width headers -->
    <header-search *ngIf="showSearchHeader$ | async"></header-search>
  </div>
  <!--//Header End-->

  <div #fixedBottom class="fixed-bottom">
      <pc-footer></pc-footer>
  </div>

  <div class="proactivechat-position">
    <proactive-chat></proactive-chat>
  </div>

  <!-- show loading bar or circle -->
  <pc-loader></pc-loader>

  <!-- alert toast -->
  <pc-toast></pc-toast>

  <div class="parts container bg-light2 "
    [class.full-width]="isFullSizeWidth$ | async"
    [ngStyle]="{ 'margin-top.px': fixedTop.offsetHeight, 'margin-bottom.px': fixedBottom.offsetHeight }">
    <router-outlet></router-outlet>
  </div>
</div>
