import { Component, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as OrderConfirmationActions from './../../../app/_store/order-confirmation/order-confirmation.actions';
import { Store } from "@ngrx/store";
import { AppState } from 'store/app-state';

@Component({
  selector: 'app-processing-payment-modal-component',
  templateUrl: './processing-payment-modal-component.component.html',
  styleUrls: ['./processing-payment-modal-component.component.scss']
})
export class ProcessingPaymentModalComponent {
  public isDisabled: boolean = true;
  constructor( private store: Store<AppState>, private activeModal: NgbActiveModal) { }

  @Output() resultType = '';

  public closeModal(): void {
    this.resultType = 'canceled';
    this.activeModal.dismiss();
  }


}
