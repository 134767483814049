<div id="headerSales_div_UserSalesMetrics"
  class="bg-secondary py-2"
  *ngLet="{
    currencyCode: currencyCode$ | async
  } as vm" >
    <div class="bg-primary p-1" *ngIf="salesMetrics$ | async as userSalesMetrics">
        <table class="table-bordered-between w-100">
            <tr>
                <td class="px-1 position-relative">
                    <div>
                        <h5 class="m-0">Commitment</h5>
                        <div>{{(userSalesMetrics.compliance | number:'1.2-2') + '%'}}</div>
                    </div>
                    <div class="note" [ngbTooltip]="adoptionPopover" placement="right"></div>
                    <ng-template #adoptionPopover>
                        <span class="text-nowrap">= Parts Connect Sales / Total SAP Sales. Goal is 100%</span>
                    </ng-template>
                </td>
                <td class="px-1 position-relative">
                    <div>
                        <h5 class="m-0">Related Parts Sales</h5>
                        <div>{{userSalesMetrics.relatedParts | currency: vm.currencyCode :'symbol':'1.2-2'}}
                            ({{(userSalesMetrics.partsLink > 0 ? (userSalesMetrics.relatedParts/userSalesMetrics.partsLink) : 0) | percent:'1.2-2'}})</div>
                    </div>
                    <div class="note" [ngbTooltip]="relatedPartSalesPopover" placement="right"></div>
                    <ng-template #relatedPartSalesPopover>
                        <span class="text-nowrap">= Related Parts Invoice / Total SAP Sales. Goal is 7%</span>
                    </ng-template>
                </td>
                <td class="px-1">
                    <h5 class="m-0">Parts Connect Sales</h5>
                    <div>{{userSalesMetrics.partsLink | currency: vm.currencyCode :'symbol':'1.2-2'}}</div>
                </td>
                <td class="px-1">
                    <h5 class="m-0">Catalog Sales / %Goal</h5>
                    <!--<div>{{userSalesMetrics.catalogSales | currency: vm.currencyCode :'symbol':'1.2-2'}} / {{(userSalesMetrics.ctalogSalesPctGoal | number:'1.2-2') + '%'}}</div>-->
                    <div>TBD</div>
                </td>
                <td class="px-1">
                    <h5 class="m-0">Rig Tough / %Goal</h5>
                    <!--<div>{{userSalesMetrics.righToughSales | currency: vm.currencyCode :'symbol':'1.2-2'}} / {{(userSalesMetrics.righToughSalesPctGoal | number:'1.2-2') + '%'}}</div>-->
                    <div>TBD</div>
                </td>
                <td class="px-1">
                    <h5 class="m-0">Branch Rank</h5>
                    <div>{{userSalesMetrics.branchRank || '&nbsp;'}}</div>
                </td>
                <td class="px-1">
                    <h5 class="m-0">SAP Sales / GP%</h5>
                    <div>{{userSalesMetrics.sap | currency: vm.currencyCode :'symbol':'1.2-2'}} / {{(userSalesMetrics.gpPrecentage | number:'1.2-2') + '%'}}</div>
                </td>
                <td class="px-1">
                    <h5 class="m-0">Goal / Efficiency</h5>
                    <!--<div>{{userSalesMetrics.salesGoal | currency: vm.currencyCode :'symbol':'1.2-2'}} /  {{(userSalesMetrics.salesGoalEfficiency | number:'1.2-2') + '%'}}</div>-->
                    <div>TBD</div>
                </td>
                <td class="px-1 position-relative">
                    <div>
                        <h5 class="m-0">Open Orders</h5>
                        <div>{{userSalesMetrics.openOrdersTotal | currency: vm.currencyCode :'symbol':'1.2-2' }}
                            <span id="headerSales_span_OrdersCount" *ngIf="userSalesMetrics.orderCount > 0" class='red-notification-count'>{{userSalesMetrics.orderCount}}</span></div>
                    </div>
                    <div class="note" [ngbTooltip]="openOrdersPopover" placement="bottom"></div>
                    <ng-template #openOrdersPopover>
                        <span class="text-nowrap">Requires invoicing. Convert or close within 3 days</span>
                    </ng-template>
                </td>
                <td class="px-1 position-relative">
                    <div>
                        <h5 class="m-0">Open Quotes</h5>
                        <div>{{userSalesMetrics.quotesTotal | currency: vm.currencyCode :'symbol':'1.2-2'}}
                            <span id="headerSales_span_QuotesCount" *ngIf="userSalesMetrics.quoteCount > 0" class='red-notification-count'>{{userSalesMetrics.quoteCount}}</span></div>
                    </div>
                    <div class="note" [ngbTooltip]="openQuotesPopover" placement="left"></div>
                    <ng-template #openQuotesPopover>
                        <span class="text-nowrap">Follow up every 24 hours. Close out when lost sale.</span>
                    </ng-template>
                </td>
                <td class="px-1 position-relative">
                    <div>
                        <h5 class="m-0">Consumption</h5>
                        <div>{{userSalesMetrics.openOrdersGI | currency: vm.currencyCode :'symbol':'1.2-2'}}
                          <span id="headerSales_span_OrdersGICount" *ngIf="userSalesMetrics.openOrdersGICount > 0" class='red-notification-count'>{{userSalesMetrics.openOrdersGICount}}</span>
                        </div>
                    </div>
                    <div class="note" [ngbTooltip]="openOrdersPopover" placement="left"></div>
                    <ng-template #openOrdersPopover>
                        <span class="text-nowrap">Requires invoicing. Convert or close  within 3 days</span>
                    </ng-template>
                </td>
                <td>
                    <button id="headerSales_button_Close"
                      type="button" class="close mx-3" (click)="closeSubject.next()">&times;
                    </button>
                </td>
            </tr>
        </table>
    </div>
</div>
