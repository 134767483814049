import { Injectable } from '@angular/core';
import { BaseService } from 'services/base.service';
import { HttpClient } from '@angular/common/http';
import { Feedback } from 'entities/feedback';

@Injectable({
  providedIn: "root"
})
export class FeedbackService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  sendFeedback(formData: FormData): Promise<Feedback> {
    let options = this.options;
    return this.http.post<Feedback>(`api/feedback/SendFeedback`, formData)
      .toPromise()
      .catch(this.handleError);
  }

  sendImageFeedback(formData: any): Promise<any> {
    let options = this.options;
    return this.http.post(`api/feedback/SendImageFeedback`, formData)
      .toPromise()
      .catch(this.handleError);
  }

}
