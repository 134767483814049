import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderDetailsLineItem } from 'entities/order-details-line-item';

@Component({
  selector: 'buyagain-popover-message-modal',
  templateUrl: './buyagain-popover-message-modal.component.html'
})
export class BuyAgainPopoverMessageModalComponent {
  message: string;
  orderDetailsLineItems: OrderDetailsLineItem[];

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  selectNo(): void {
   this.activeModal.close(false);
  }

  selectYes(): void {
    this.activeModal.close(true);
  }
}
