import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { catchError, map, scan, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { Note, CreateNoteRequest } from 'entities/notes';
import { CommonDataService } from 'services/common-data.service';
import { NotesService } from 'services/notes.service';
import { ToastService } from 'services/toast.service';

interface PartNotesState {
  notes: Note[];
  sortAscending: boolean;
  edit: PartNotesEdit;
}

export type PartNotesAction =
  | { type: 'setNotes', notes: Note[] }
  | { type: 'setSortAscending', sortAscending: boolean }
  | { type: 'startEdit', noteId: number, description: string }
  | { type: 'cancelEdit' }
  | { type: 'submitEdit' };

export type PartNotesEdit = {
  id: number;
  description: string;
  submitting: boolean;
};
  
export type CreateNoteRequestParam = {
  partNumber: string;
  description: string;
};

export type UpdateNoteRequestParam = {
  partNumber: string,
  noteId: number,
  description: string
};

@Injectable()
export class PartNotesComponentService {
  private readonly initialState: PartNotesState = {
    notes: [],
    sortAscending: false,
    edit: null
  };

  constructor(
    private commonDataService: CommonDataService,
    private notesService: NotesService,
    private toastService: ToastService
  ) { }

  getSelectors(action$: Observable<PartNotesAction>) {
    const state$ = action$
      .pipe(
        scan(
          (state, action) => {
            switch (action.type) {
              case 'setNotes':
                return { ...state, notes: action.notes && action.notes.map((note) => ({ ...note })) || [], edit: null };
              case 'setSortAscending':
                return { ...state, sortAscending: action.sortAscending };
              case 'startEdit':
                return { ...state, edit: { id: action.noteId, description: action.description, submitting: false } };
              case 'cancelEdit':
                return { ...state, edit: null };
              case 'submitEdit':
                return { ...state, edit: { ...state.edit, submitting: true } };
              default:
                return { ...state };
            }
          },
          { ...this.initialState }
        ),
        shareReplay(1)
      );

    const notes$ = state$
      .pipe(
        map((state) =>
          state.notes
            .sort((a, b) => (a.id - b.id) * (state.sortAscending ? 1 : -1))
        )
      );

    const sortAscending$ = state$
      .pipe(
        map((state) => state.sortAscending)
      );

    const edit$ = state$
      .pipe(
        map((state) => state.edit)
      );

    return {
      notes$,
      sortAscending$,
      edit$
    };
  }

  getCreateNote$(createNoteRequest$: Observable<CreateNoteRequestParam>) {
    return createNoteRequest$
      .pipe(
        withLatestFrom(this.commonDataService.branch$, this.commonDataService.customer$),
        map(([{ partNumber, description }, branch, customer]): CreateNoteRequest => ({
          description,
          partNumber,
          branchCode: branch.code,
          customerNumber: customer.customerNumber
        })),
        switchMap((request) => 
          this.notesService.createNotes(request)
            .pipe(
              catchError((error) => {
                this.toastService.errorMessage('PartNotesComponent', 'createNotes', 'createNotes', error);
                return EMPTY;
              })
            )
        )
      );
  }
  
  getUpdateNote$(updateNoteRequest$: Observable<UpdateNoteRequestParam>) {
    return updateNoteRequest$
      .pipe(
        withLatestFrom(this.commonDataService.branch$, this.commonDataService.customer$),
        map(([{ partNumber, noteId, description }, branch, customer]): [number, CreateNoteRequest] => [
          noteId,
          {
            description,
            partNumber,
            branchCode: branch.code,
            customerNumber: customer.customerNumber
          }
        ]),
        switchMap(([noteId, request]) => 
          this.notesService.putNote(noteId, request)
            .pipe(
              catchError((error) => {
                this.toastService.errorMessage('PartNotesComponent', 'updateNote', 'updateNote', error);
                return EMPTY;
              })
            )
        )
      )
  }

  getDeleteNote$(deleteNote$: Observable<number>) {
    return deleteNote$
      .pipe(
        switchMap((noteId) => this.notesService.deleteNote(noteId))
      );
  }
}
