import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import {
  basketsLoad,
  basketsLoadSuccess,
  basketsLoadFailure,
  addBasketToCartSuccess,
  addBasketItemsToCart,
  addBasketToCartFailed,
  addBasketToNewCart,
  refreshBasketItemsList
} from "./basket.actions";
import { BasketService } from "services/basket.service";
import { PartService } from 'services/part.service';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { AppState } from "store/app-state";
import { Basket } from "entities/basket";
import * as HomeActions from "store/home/home-page.actions";
import * as BranchSelectors from "app/_store/branch/branch.selectors";
import * as BranchActions from 'app/_store/branch/branch.actions';
import { BasketLineItem } from "entities/basket-line-item";
import { LoaderService } from "services/loader.service";
import * as CartSelectors from 'store/cart/cart.selectors';
import * as BasketSelectors from 'store/basket/basket.selectors';
import * as CustomerSelectors from 'store/customer/customer.selectors';
import { EMPTY, of } from "rxjs";
import { BasketListComponent } from "modals/basket-list/basket-list.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastService } from "services/toast.service";
import { ToastType } from "entities/toast-type";
import { BasketAddToCartRequest } from "entities/basket-item-to-add-to-cart";
import { CartService } from 'services/cart.service';
import { Router } from '@angular/router';


@Injectable()
export class BasketEffects {

  loadBaskets$ = createEffect(() =>
    this.action$.pipe(
      ofType(
        basketsLoad,
        refreshBasketItemsList,
        HomeActions.loaded,
        BranchActions.selectBranch
      ),
      withLatestFrom(this.store.select(BranchSelectors.selectedBranch)),
      filter(([_, branch]) => branch?.code != null),
      switchMap(([_, branch]) =>
        this.basketService.getBaskets(branch?.code).pipe(
          map((sapBaskets) => {
            const baskets = this.constructBasketsFromSAPBaskets(sapBaskets, branch?.code);
            return basketsLoadSuccess({ baskets });
          }),
          catchError(error => of(basketsLoadFailure({ error })))
        )
      ),
    )
  );

  addBasketToNewCart$ = createEffect(() =>
    this.action$.pipe(
      ofType(addBasketToNewCart),
      withLatestFrom(this.store.select(BasketSelectors.selectBasketList)),
      switchMap(([_, basketList]) => {
        if (basketList?.length) {
          const modalRef = this.modalService.open(BasketListComponent);
          modalRef.componentInstance.basketList = basketList;
          return modalRef.result;
        } else {
          this.toastService.showToast("No open shopping baskets.", ToastType.Error);
          return of(null);
        }
      }),
      filter(x => x != null),
      tap(() => this.loaderService.loading = true),
      withLatestFrom(
        this.store.select(CustomerSelectors.selectedCustomer),
        this.store.select(BranchSelectors.selectedBranch),
      ),
      switchMap( ([_, customer, branch]) => {
        return this.cartService.getOrCreateCart({
        branchCode: branch.code,
        customerNumber: customer.customerNumber,
        forceCreate: false
        })
    }),
    withLatestFrom(
      this.store.select(CustomerSelectors.selectedCustomer),
      this.store.select(BranchSelectors.selectedBranch),
      this.store.select(BasketSelectors.selectBasketList),
    ),
      switchMap(
        ( [cartResult, customer, branch, baskets] ) => {
          const addToCartReq = {cartId: cartResult.cartId, customerNumber: customer.customerNumber, branchCode: branch.code, baskets: baskets  }
          return this.basketService.addSelectedBasketItemsToCart(addToCartReq)
            .pipe(
              map(cart => addBasketToCartSuccess({ cart })),
              catchError(error => of(addBasketToCartFailed({ error }))),
              tap(
                () => this.router.navigate(['/cart']),
                () => { }
              ),
              tap(() => this.loaderService.loading = false),
            )
        }
      )
    )
  );

  // do not re-use from home page, this is for usage from my cart and
  // considers that cart is already selected and loaded.
  // Also baskets should be loaded in store as branch is already known for the cart
  // so no need to reload them again here
  addBasketItemsToCart$ = createEffect(() => this.action$.pipe(
    ofType(addBasketItemsToCart),
    withLatestFrom(this.store.select(BasketSelectors.selectBasketList)),
    switchMap(([_, basketList]) => {
      if (basketList?.length) {
        const modalRef = this.modalService.open(BasketListComponent);
        modalRef.componentInstance.basketList = basketList;
        return modalRef.result;
      } else {
        this.toastService.showToast("No open shopping baskets.", ToastType.Error);
        return EMPTY;
      }
    }),
    filter(x => x != null),
    withLatestFrom(
      this.store.select(CartSelectors.selectCart),
      this.store.select(BasketSelectors.selectBasketList)
    ),
    switchMap(([_, cart, basketItems]) => {
      const hasItemsToAddToCart = basketItems.map(x => x.lineItems).some(basketItems => basketItems.some(x => x.addToCart));
      if (hasItemsToAddToCart) {
        const request: BasketAddToCartRequest = this.createAddBasketToCartRequest(cart.cartId, cart.customerNumber, cart.branchCode, basketItems);
        return this.basketService.addSelectedBasketItemsToCart(request)
        .pipe(
          //map(cart => addBasketToCartSuccess({ cart })),

          withLatestFrom(this.store.select(BranchSelectors.selectedBranch)),
          switchMap(( [cart, branch]) => {
            let localCart = cart;
            return this.partService.getPartBinLocations(cart.lineItems?.map((x) => x.partNumber), branch.code).pipe(
              map( (binLocs) => { return  {...localCart, availableBinLocations: binLocs} }),
              map(cart => addBasketToCartSuccess({ cart })),
            )
          }),


          catchError(error => of(addBasketToCartFailed({ error }))),
          tap(() => this.loaderService.loading = false)
        )
      } else {
        return EMPTY;
      }
  })));

  refreshListAfterItemsAddedToCart$ = createEffect(() => this.action$.pipe(
    ofType(addBasketItemsToCart),
    withLatestFrom(
      this.store.select(BasketSelectors.selectBasketCount),
      this.store.select(BasketSelectors.selectRefreshLists)
    ),
    filter(([_, basketCount, refreshList]) => basketCount > 0 && refreshList),
    map(() => refreshBasketItemsList())
  ));

  constructor(
    private action$: Actions,
    private basketService: BasketService,
    private store: Store<AppState>,
    private loaderService: LoaderService,
    private modalService: NgbModal,
    private toastService: ToastService,
    private cartService: CartService,
    private router: Router,
    private partService: PartService
  ) {}

  createAddBasketToCartRequest(cartId: string, customerNumber: string, branchCode: string, baskets: Basket[]): BasketAddToCartRequest {
    const request: BasketAddToCartRequest = {
      branchCode: branchCode,
      cartId: cartId,
      customerNumber: customerNumber,
      baskets: baskets
    }
    return request
  }

  constructBasketsFromSAPBaskets(baskets: {[key: string]: any[]}, branchCode: string): Basket[] {
    const newBaskets = Object.values(baskets).map((basketItem) => {
      const basketLineItems: BasketLineItem[] = basketItem.map(item => {
        const basketLineItem: BasketLineItem = {
          addToCart: false,
          basketId: item.binNumber,
          binNumber: item.binNumber,
          binType: item.binType,
          branchCode: branchCode,
          partNumber: item.rushPartNumber,
          description: item.description,
          quantity: item.quantityAvailableInBasket,
          quantitySelected: item.quantityAvailableInBasket
        };
        return basketLineItem;
      });
      return {
        branchCode: branchCode,
        id: basketItem[0].binNumber,
        binNumber: basketItem[0].binNumber,
        binType: basketItem[0].binType,
        lineItems: basketLineItems
      } as Basket;
    });
    return newBaskets;
  }
}
