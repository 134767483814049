export enum InviteToPayOptions {
  email = "Email",
  sms = "Sms"
}

export enum InviteToPayFlag {
  email = "E",
  sms = "T"
}

export interface I2pResendRequest {
  flag: InviteToPayFlag;
  email: string;
  phoneNumber: string;
  orderNumber: string;
  shipToCustomer: string;
}

export interface InviteToPayForm {
  i2p: InviteToPayOptions;
  i2pEmail: string;
  i2pSms: string;
}
