import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'delete-cart-confirm-modal',
  templateUrl: './delete-cart-confirm-modal.component.html'
})
export class DeleteCartConfirmModalComponent {

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  cancel() {
    this.activeModal.close(false);
  }

  confirm() {
    this.activeModal.close(true);
  }

}
