/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @angular-eslint/component-selector */
import { Component, ChangeDetectionStrategy, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, ReplaySubject, Subject, combineLatest, merge } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Permission } from 'entities/enums';
import { Note } from 'entities/notes';
import { AuthorizationService } from 'services/authorization.service';
import { CreateNoteRequestParam, PartNotesAction, PartNotesComponentService, PartNotesEdit, UpdateNoteRequestParam } from './part-notes.component.service';
import { ConfigurationService } from 'services/configuration.service';


@Component({
  selector: 'part-notes',
  templateUrl: './part-notes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ PartNotesComponentService ]
})
export class PartNotesComponent implements OnDestroy {
  @Input() partNumber: string;
  @Input() sapCuratedNote: string;

  private actionSubject: Subject<PartNotesAction> = new ReplaySubject();
  @Input('notes') set notesInput(value: Note[]) {
    this.actionSubject.next({ type: 'setNotes', notes: value });
  }

  @Output('update') updateEmitter: EventEmitter<void> = new EventEmitter();

  public userId$: Observable<string> = this.configurationService.user$
  .pipe(
    map((user) => user.id)
  );

  public isInternal$: Observable<boolean> = this.configurationService.user$
  .pipe(
    map((user) => user.isInternal)
  );

  public permission = Permission;

  private selectors = this.service.getSelectors(this.actionSubject.asObservable());

  public notes$: Observable<Note[]> = this.selectors.notes$;
  public sortAscending$: Observable<boolean> = this.selectors.sortAscending$;
  public edit$: Observable<PartNotesEdit> = this.selectors.edit$;

  public hasEditPermission$: Observable<boolean> = combineLatest([
    this.authorizationService.hasPermissionAsync(Permission.CreatePartNotes),
    this.authorizationService.hasPermissionAsync(Permission.DeletePartNotes)
  ])
    .pipe(
      map(([hasCreatePermission, hasDeletePermission]) => hasCreatePermission && hasDeletePermission)
    );
  public hasDeletePermission$: Observable<boolean> = this.authorizationService.hasPermissionAsync(Permission.DeletePartNotes);
  public hasSomeModifyPermission$: Observable<boolean> = combineLatest([this.hasEditPermission$, this.hasDeletePermission$])
    .pipe(
      map(([hasEditPermission, hasDeletePermission]) => hasEditPermission || hasDeletePermission)
    );

  private createNoteRequestSubject: Subject<CreateNoteRequestParam> = new Subject();
  private createNotes$: Observable<Note> = this.service.getCreateNote$(this.createNoteRequestSubject.asObservable());
  private updateNoteRequestSubject: Subject<UpdateNoteRequestParam> = new Subject();
  private updateNote$: Observable<Note> = this.service.getUpdateNote$(this.updateNoteRequestSubject.asObservable());
  private deleteNoteRequestSubject: Subject<number> = new Subject();
  private deleteNote$: Observable<void> = this.service.getDeleteNote$(this.deleteNoteRequestSubject.asObservable());
  private subscription = merge(this.createNotes$, this.updateNote$, this.deleteNote$)
    .pipe(
      tap(() => this.updateEmitter.emit())
    )
    .subscribe();

  constructor(
    private service: PartNotesComponentService,
    public authorizationService: AuthorizationService,
    private configurationService: ConfigurationService
  ) { }

  public setSortAscending(sortAscending: boolean) {
    this.actionSubject.next({ type: 'setSortAscending', sortAscending });
  }

  public createNote(form: UntypedFormGroup) {
    if (!form.valid) {
      return;
    }
    this.createNoteRequestSubject.next({ partNumber: this.partNumber, description: form.value.description });
    form.reset({ description: '' });
  }

  public editNote(noteId: number, description: string) {
    this.actionSubject.next({ type: 'startEdit', noteId, description });
  }

  public cancelEdit() {
    this.actionSubject.next({ type: 'cancelEdit' });
  }

  public updateNote(form: UntypedFormGroup) {
    if (!form.valid) {
      return;
    }
    this.updateNoteRequestSubject.next({ partNumber: this.partNumber, noteId: form.value.id, description: form.value.description });
    this.actionSubject.next({ type: 'submitEdit' });
  }

  public deleteNote(noteId: number) {
    this.deleteNoteRequestSubject.next(noteId);
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
}
