import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { CartItemAndDelivery } from "app/_components/checkout-line-item-delivery/checkout-line-item-delivery.component.state";
import { CheckoutValidationMessages } from "app/_components/checkout-validation/checkout-validation.messages";
import { CheckoutFormGroup } from "app/_components/checkout/checkout.component";
import { OrderType } from "entities/order-type";
import { validateLineItem } from "validators/delivery-line-by-line.validator";

export const procurementValidator = (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
        const checkoutFormGroup = control.root as FormGroup<CheckoutFormGroup>;
        const cartData = checkoutFormGroup.value?.shippingAndDelivery?.cartData;
        const freightDeliveryOption = cartData?.shippingRate;
        const isQuote = cartData?.transactionType === OrderType.Quote;
        const internalUser = cartData?.isUserInternal;
        const orderNumber = cartData?.orderNumber;
        const shippingManagementEnabled = checkoutFormGroup.value?.shippingAndDelivery?.shipTo?.shippingManagementEnabled;
        const shipToNumber = checkoutFormGroup.value?.shippingAndDelivery?.shipTo?.addressNumber;

        const pickableBinErrors = cartData?.lineItems
            ?.filter(x => x.partNumber !== "FREIGHT" && x.partNumber !== "DELIVERY:90")
            ?.filter(x => x.hasPartsInNonPickableBins && x.deliveryOption === 'L')
            .reduce((prev, curr) => {
                prev['pickableBinError.' + curr.partNumber] = `Front Counter Self Pick Not Allowed - Part ${curr.partNumber} is not in a pickable bin.`;
                return prev;
            }, {});

        const procurementErrors = cartData?.lineItems
            ?.filter(x => x.partNumber !== "FREIGHT" && x.partNumber !== "DELIVERY:90")
            .reduce((prev, lineItem) => {
                const procured = lineItem.isHotFlag || lineItem.isSTO || lineItem.isBuyout;
                const outOfStock = lineItem.quantityAvailable === 0;
                const isShoppingBasketItem = lineItem.binLocation?.startsWith("SB-");

                const mappedLineItem = {
                    cartItem: lineItem,
                    cartItemId: lineItem.cartItemId,
                    deliveryOption: lineItem.deliveryOption,
                    hazmat: lineItem.hazmat,
                    lineId: lineItem.lineId,
                    selected: false,
                    hasChanges: false
                } as CartItemAndDelivery;

                const err = validateLineItem(
                    mappedLineItem,
                    isQuote,
                    procured,
                    isShoppingBasketItem,
                    outOfStock,
                    internalUser,
                    orderNumber
                );
                return { ...prev, ...err };
            }, {});

        const freightErrors = cartData?.lineItems
            ?.filter(x => shippingManagementEnabled && !procurementErrors['disallowFreightDelivery'])
            ?.filter(x => x.partNumber !== "FREIGHT" && x.partNumber !== "DELIVERY:90" && x.deliveryOption === 'C')
            ?.filter(x => !!shipToNumber && !freightDeliveryOption)
            .reduce((prev, _) => {
                prev['cartData.freightdeliveryoption.required'] = CheckoutValidationMessages['cartData.freightdeliveryoption.required'];
                return prev;
            }, {});

        const errors = { ...pickableBinErrors, ...procurementErrors, ...freightErrors };
        if (Object.keys(errors).length > 0) {
            return errors;
        }
        return null;
    };
};