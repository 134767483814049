import { Injectable } from '@angular/core';
import { Observable, merge, of, EMPTY } from 'rxjs';
import { catchError, filter, map, mapTo, share, switchAll, tap } from 'rxjs/operators';
import { Branch } from 'entities/branch';
import { ExtendPartRequest } from 'entities/extend-part-request';
import { PartService } from 'services/part.service';
import { LoaderService } from 'services/loader.service';
import { ToastService } from 'services/toast.service';

@Injectable()
export class CreatePartModalComponentService {
  constructor(
    private loaderSerivce: LoaderService,
    private partService: PartService,
    private toastService: ToastService
  ) { }

  getSelectors(extendPart$: Observable<any>, branch: Branch): {
    extendPartComplete$: Observable<string>
  } {
    const extendPartRequest$ = extendPart$
      .pipe(
        map((priceTapeResult): ExtendPartRequest => ({
          partNumber: priceTapeResult.materialNumber + ':' + priceTapeResult.manufacturerNumber,
          branchCode: branch.code,
          allowReplacedPart: true,
          isSimulated: false
        })),
        map((extendPart) => 
          this.partService.createExtendedPart2(extendPart)
            .pipe(
              tap((responseMsgResult) => {
                if (responseMsgResult.ErrorType && responseMsgResult.ErrorType !== 200) {
                  throw responseMsgResult;
                }
              }),
              share()
            )
        ),
        share()
      );
    
    const extendPartComplete$ = extendPartRequest$
      .pipe(
        map((switchObservable) => switchObservable
          .pipe(
            catchError((error) => {
              this.toastService.errorMessage('AppComponent', 'subscriptionCreateExtendedPart', 'createExtendedPart', error);
              return EMPTY;
            })
          )
        ),
        switchAll(),
        tap((responseMsgResult) => {
          this.toastService.showMultilineToast(responseMsgResult.messages);
        }),
        map((result) => result.externalPartNumber),
        filter((externalPartNumber) => Boolean(externalPartNumber)),
        share()
      );

    const extendPartInProgress$ = merge(
      extendPart$.pipe(mapTo(true)),
      extendPartRequest$.pipe(
        map((switchObservable) => switchObservable
          .pipe(
            catchError((error) => of(false))
          )
        ),
        switchAll(),
        mapTo(false)
      )
    );

    this.loaderSerivce.register(extendPartInProgress$);

    return {
      extendPartComplete$
    };
  }
}
