import { EntityState, Dictionary } from "@ngrx/entity";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { VerifiedPrice } from "entities/parts/verified-price";
import { AppState } from "../app-state";
import { verifiedPricesAdapter } from "./verified-prices.adapter";

export const selectVerifiedPricesState = createFeatureSelector< EntityState<VerifiedPrice>>("verifiedPrices");

export const {
  selectEntities: selectVerifiedPriceEntities
} = verifiedPricesAdapter.getSelectors(selectVerifiedPricesState);

export const selectVerifiedPrice = createSelector<AppState, { partNumber: string }, Dictionary<VerifiedPrice>, VerifiedPrice>(
  selectVerifiedPriceEntities,
  (entities, { partNumber }) => entities[partNumber]
);
