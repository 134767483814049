<div [formGroup]="deviceListFormGroup">

  <div class="form-group">

    <div *ngIf="devices.length <= 0">
      <div class="modal-header">
        <span id="pick_device">LOADING PAYMENT DEVICES</span>
      </div>

      <div class="modal-body">
        <div style="display: flex; align-content: flex-start; vertical-align: middle;">
          <div>
            <div class="dummyLoader">&nbsp;</div>
            <div class="dummyLoader">&nbsp;</div>
            <div class="dummyLoader">&nbsp;</div>
            <div class="dummyLoader">&nbsp;</div>
            <div class="dummyLoader">&nbsp;</div>
            <div class="dummyLoader">&nbsp;</div>
            <div class="dummyLoader">&nbsp;</div>
          </div>
          <div style="margin-left: 100px; margin-top: 70px;"><i id="spinner"
              class="fas fa-spinner-third fa-fw fa-2x fa-spin"></i></div>
        </div>
      </div>

      <div class="modal-footer">
        <button id="CrfVerify_button_Cancel" type="button" class="btn btn-default black-btn"
          (click)="cancel()">Cancel</button>
        <button id="CrfVerify_button_Approve" type="button" class="btn btn-default yellow-btn" (click)="continue()"
          disabled>Continue</button>
      </div>
    </div>

    <div *ngIf="devices.length > 0">
      <div class="modal-header">
        <span id="pick_device">Select Payment Device</span>
      </div>

      <div class="modal-body">
        <div id="device_{{i}}" *ngFor="let device of devices; let i = index">
          <input id="device_choice_{{i}}" type="radio" name="deviceSelection" formControlName="deviceSelection"
            [value]="device.key" (change)="setSelectedDevice(device.key)">
          <label for="device_choice_{{i}}" class="device-label">
            <span class="ml-2">{{device.name}}</span>
          </label>
        </div>
      </div>

      <div class="modal-footer">
        <button id="CrfVerify_button_Cancel" type="button" class="btn btn-default black-btn"
          (click)="cancel()">Cancel</button>
        <button id="CrfVerify_button_Approve" type="button" class="btn btn-default yellow-btn"
          (click)="continue()">Continue</button>
      </div>
    </div>
  </div>
</div>
