import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { CommonDataService } from 'services/common-data.service';
import { Part } from 'entities/part';
import { SourceLocationType } from 'services/logger.service';
import { AppState } from 'store/app-state';
import * as CartActions from 'store/cart/cart.actions';

@Component({
  selector: 'part-card-add-to-cart',
  templateUrl: './part-card-add-to-cart.component.html',
  styleUrls: ['./part-card-add-to-cart.component.scss']
})
export class PartCardAddToCartComponent {
  @Input() idSuffix: string = "";
  @Input() sourcePart: Part = null;
  @Input() part: Part;
  @Input() partType: SourceLocationType;
  @Output() addedToCart: EventEmitter<Part> = new EventEmitter();

  constructor(
    private store: Store<AppState>,
    public commonDataService: CommonDataService
  ) { }

  addToCart(part: Part) {
    const trackingParameters = this.createTrackingParameters(part);

    this.store.dispatch(CartActions.addItemToCart({
      addCartItemRequest: {
        partId: part.partId,
        partNumber: part.rushPartNumber,
        binLocation: part.binLocation,
        quantity: part.quantity,
        trackingParameters: trackingParameters,
        coreOption: part.coreOption,
        adjustedPrice: part.adjustedPrice,
        description: part.description,
        quantityAvailable: part.quantityAvailable,
        sapCoupons: part.sapCoupons,
        isPriceVerified: part.isPriceVerified
      },
      sourceLocationType: this.partType
    }));
    this.addedToCart.emit(part);
    const element = document.getElementById('headerSearch_text_SearchTerm');
    element.focus();
  }

  createTrackingParameters(part: Part): string {
    switch (this.partType) {
      case SourceLocationType.FeaturedProduct: return this.getTrackingParametersForFeaturedProduct(part);
      case SourceLocationType.AlternateParts: return this.getTrackingParametersForAlternatePart(part);
      case SourceLocationType.RelatedParts: return this.getTrackingParametersForRelatedPart(part);
      default: return null;
    }
  }

  getTrackingParametersForFeaturedProduct(part: Part): string {
    const materialId = this.sourcePart && this.sourcePart.rushPartNumber || part.rushPartNumber;
    return JSON.stringify({
      AddedFrom: "FeaturedProduct",
      PartId: part.partId,
      BranchCode: this.commonDataService.Branch.code,
      MaterialId: materialId
    });
  }

  getTrackingParametersForAlternatePart(part: Part): string {
    const groupPartId = this.sourcePart && this.sourcePart.groupId || part.groupId;
    const materialId = this.sourcePart && this.sourcePart.rushPartNumber || part.rushPartNumber;
    return JSON.stringify({
      AddedFrom: "AlternatePart",
      PartId: part.partId,
      GroupPartId: groupPartId,
      BranchCode: this.commonDataService.Branch.code,
      MaterialId: materialId
    });
  }

  getTrackingParametersForRelatedPart(part: Part): string {
    const groupRelatedPartId = this.sourcePart && this.sourcePart.groupRelatedPartId || part.groupRelatedPartId;
    const materialId = this.sourcePart && this.sourcePart.rushPartNumber || part.rushPartNumber;
    return JSON.stringify({
      AddedFrom: "RelatedPart",
      PartId: part.partId,
      GroupRelatedPartId: groupRelatedPartId,
      BranchCode: this.commonDataService.Branch.code,
      MaterialId: materialId
    });
  }

  changeQuantity(qty: number) {
    this.part.quantity = qty;
  }
}
