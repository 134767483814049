<footer class="navbar navbar-expand navbar-dark bg-secondary">
    <div class="container p-0">
        <div class="w-100 d-flex navbar-nav justify-content-between align-items-center position-relative">
            <div class="navbar-text">Parts Connect {{appVersion$ | async}}</div>
            <div class="navbar-text">
                &copy; 2015-{{ copyrightYear }} Rush Enterprises Inc.<span class="d-none d-xl-inline"> rushtruckcenters.com</span>
            </div>
            <div class="d-flex gx-2">
                <a class="nav-link p-0" href="https://www.paperturn-view.com/?pid=Mjk297017" target="_blank">
                    <span>Features Guide</span>
                </a>
                <a class="nav-link p-0" routerLink="misc/eula">
                    <span>User Agreement</span>
                </a>
                <a class="nav-link p-0" href="https://www.rushtruckcenters.com/terms-of-use" target="_blank">
                    <span>Terms of Use</span>
                </a>
                <a class="nav-link p-0" href="https://www.rushtruckcenters.com/privacy-policy" target="_blank">
                  <span>Privacy Policy</span>
                </a>
                <a class="nav-link p-0" href="https://www.rushtruckcenters.com/ca-privacy-notice" target="_blank">
                  <span>CA Privacy Notice</span>
                </a>
                <a class="nav-link p-0" href="http://www.rushtruckcenters.com/Contact-Us" target="_blank">
                    <span>Contact Us</span>
                </a>
                <a class="nav-link p-0" href="javascript:void(0)" (click)="feedback()">
                    <span>Send Feedback</span>
                </a>
            </div>
        </div>
    </div>
</footer>
