import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AppState } from 'store/app-state';
import { FeatureFlagsState } from './feature-flags.state';
import * as CustomerSelectors from 'store/customer/customer.selectors';
import * as BranchSelectors from 'store/branch/branch.selectors';

export const loadedFeatureFlags = createFeatureSelector< FeatureFlagsState>('featureFlags');

export const featureFlags = createSelector(
  loadedFeatureFlags,
  (state) => state.flags
);

export const isFeatureActive = (featureName) => {
  return createSelector(loadedFeatureFlags, CustomerSelectors.selectedCustomer, BranchSelectors.selectedBranch, (state, selectedCustomer, selectedBranch) => {
    const feature = state.flags[featureName];
    if (!feature || !feature.isEnabled) {
      return false;
    }
    const customers: string[] = feature.sapCustomerNumbers;
    const branches: string[] = feature.sapBranchNumbers;

    const customerAllowed = customers?.length ? customers.find(customer => customer === selectedCustomer.customerNumber) != null : true;
    const branchAllowed = branches?.length ? branches.find(branch => branch === selectedBranch.code) != null : true;

    return customerAllowed && branchAllowed;
  });
};

export const isFeatureActiveForCustomerOrBranch = (featureName, customerNumber, branchCode ) =>
createSelector(loadedFeatureFlags, (state) => {
    const feature = state.flags[featureName];
    if (!feature || !feature.isEnabled) {
      return false;
    }
    const customers: string[] = feature.sapCustomerNumbers;
    const branches: string[] = feature.sapBranchNumbers;

    const customerAllowed = customers?.length ? customers.find(customer => customer === customerNumber) != null : true;
    const branchAllowed = branches?.length ? branches.find(branch => branch === branchCode) != null : true;

    return customerAllowed && branchAllowed;
  });
