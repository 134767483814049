<div id="container">
  <button id="x" (click)="closeModal()">
    X
  </button>

  <div class="modal-body">

    <label><strong>
        <h5>Basket Management</h5>
    </strong></label>
    <div ngbAccordion #acc="ngbAccordion" [closeOthers]="true">
      <div ngbAccordionItem="basket_panel_{{ i }}" #accItem="ngbAccordionItem" class="basket-list" *ngFor="let basket of basketList; let i = index">
        <div ngbAccordionHeader>
          <button ngbAccordionButton>
            <div style="float:left">&nbsp;{{ basket.id }}</div>
          </button>
        </div>

        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>

              <div class="d-flex">
                <div class="col-5">Part Number</div>
                <div class="col">Description</div>
                <div class="col">Quantity</div>
              </div>
              <hr>

              <ngb-panel *ngFor="let item of basket.lineItems; let j = index">
                <div class="d-flex">
                  <div class="col-1">
                    <span class="gx-1">
                      <input id="basket_checkbox_SelectedCheckBox_{{ j }}" type="checkbox" name="addedToCart" value="option"
                        [ngModel]="item.addToCart" (ngModelChange)="selectBasketItem(item, basket)" />
                      <label for="basket_checkbox_SelectedCheckBox_{{ j }}"></label>
                    </span>
                  </div>
                  <div class="col-4">
                    <span class="gx-1">
                      <span>{{ item.partNumber }}</span>
                    </span>
                  </div>
                  <div class="col">
                    <span class="gx-1">
                      <span class="breakWord">{{ item.description | commareplace }}</span>
                    </span>
                  </div>

                  <div class="col">
                    <span class="gx-1">
                      <div class="range-text-box">
                          <part-quantity
                              inputName="qtySelected"
                              [value]="item.quantitySelected"
                              (onChangeQuantity)="onQuantityFocusout($event, item)"
                              [index]="j"
                              idModifier="basket_input_SelectedQuantity"
                              ngbTooltip="{{focusMessage}}"
                              [checkRangeClass]="checkRange(item)"
                          >
                          </part-quantity>
                        </div>
                    </span>
                  </div>
                  <br>
                  <br>
                </div>
              </ngb-panel>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="modal-footer">
      <button id="CloseBasketsModal_Button" type="button" class="btn btn-default black-btn" (click)="closeModal()">
        Close
      </button>
      <button id="CloseBasketsModal_Button_Submit" type="button" class="btn btn-default yellow-btn" (click)="confirm()">
        Add to Cart
      </button>
    </div>
  </div>


</div>
