import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoyaltyAccountEntry } from 'entities/loyalty-account';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from 'store/app-state';
import * as LoyaltySelectors from 'store/loyalty/loyalty.selectors';
import { LoyaltyStateAccount } from 'store/loyalty/loyalty.state';

@Component({
  selector: 'loyalty-accounts-display',
  styleUrls: ['./loyalty-accounts-display.component.scss'],
  templateUrl: './loyalty-accounts-display.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoyaltyAccountsDisplayComponent {
  public selectedLoyaltyAccount$: Observable<LoyaltyStateAccount> = this.store.select(LoyaltySelectors.loyaltyAccount);


  constructor (private changeDetectorRef: ChangeDetectorRef,
               private store: Store<AppState>) { }
}
