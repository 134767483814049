export enum UserTypeEnum {
  Salesperson = 0,
  Customer = 1
}

export enum OpenItemType {
  cart = 0,
  quote = 1,
  order = 2
}

export enum Permission {
  ReadCustomers = 'Read Customers',
  ReadCustomer = 'Read Customer',
  CreatePart = 'Create Part',
  CreatePartBuyout = 'Create PartBuyout',
  DeletePartNotes = 'Delete Part Notes',
  CreatePartNotes = 'Create Part Notes',
  ReadPartNotes = 'Read Part Notes',
  ReadNotifications = 'Read Notifications',
  PrintPickTicket = 'Print Pick Ticket',
  CreateBilling = 'Create Billing',
  CreatePurchaseOrder = 'Create Purchase Order',
  ReadSAPOrder = 'Read SAP Order',
  ReadSwitchCart = 'Read Switch Cart',
  UpdateNotifications = 'Update Notifications',
  ApplyPriceOverride = 'Apply Price Override',
  ReadProductResearch = 'Read Product Research',
  ReadCoreReturnOption = 'Read Core Return Option',
  ReadUsers = 'Read Users',
  UpdateUsers = 'Update Users',
  DeleteUsers = 'Delete Users',
  CreateUsers = 'Create Users',
  CreateFavouriteVendors = 'Create Favourite Vendors',
  ReadVendors = 'Read Vendors',
  UpdateFavouriteVendors = 'Update Favourite Vendors',
  AllowMultipleCartsOpen = 'Allow Multiple Carts Open',
  AllowProcurementOptions = 'Allow Procurement Options',
  ReadFreight = 'Read Freight',
  UpdateFreight = 'Update Freight',
  DeleteFreight = 'Delete Freight',
  ReadDelivery = 'Read Delivery',
  UpdateDelivery = 'Update Delivery',
  DeleteDelivery = 'Delete Delivery',
  CreateQuote = 'Create Quote',
  AddToCart = 'Add To Cart',
  PlaceOrder = 'Place Order',
  ImportCoupons = 'Import Coupons',
  ReadCoupons = 'Read Coupons',
  CreatePriceException = 'Create Price Exception',
  ReadPriceExceptions = 'Read Price Exceptions',
  ApprovePriceException = 'Approve Price Exception',
  Backcounter = "Backcounter",
  EditBackcounterRequestPriority = "Edit Backcounter Request Priority",
  EditBackcounterRequestNotes = "Edit Backcounter Request Notes",
  CreateBackcounterGeneralRequest = "Create Backcounter General Request",
  EditBackcounterRequest = "Edit Backcounter Request",
  ReadBackcounterRequest = "Read Backcounter Request"

}

export enum Roles {
  ASRPriceException = <any>'ASR-Price Exception',
  CallCenterPartSales = <any>'Call Center Part Sales',
  CorporatePricingManager = <any>'Corporate Pricing Manager',
  HomeBranchCustomerOrderProcessing = <any>'Home Branch Customer Order Processing',
  OpsDirector = <any>'Ops Director',
  PartSales = <any>'Part Sales',
  PartSalesReadOnly = <any>'Part Sales (Read Only)',
  PartSalesCustomerOrderProcessing = <any>'Part Sales Customer Order Processing',
  PartSalesManager = <any>'Part Sales Manager',
  PartsConnectCustomerUser = <any>'Parts Connect Customer User',
  PriceOverride = <any>'Price Override',
  SuperAdmin = <any>'Super Admin'
}

export enum USStateOptions {
  AL = "Alabama",
  AK = "Alaska",
  AZ = "Arizona",
  AR = "Arkansas",
  CA = "California",
  CO = "Colorado",
  CT = "Connecticut",
  DE = "Delaware",
  FL = "Florida",
  GA = "Georgia",
  HI = "Hawaii",
  ID = "Idaho",
  IL = "Illinois",
  IN = "Indiana",
  IA = "Iowa",
  KS = "Kansas",
  KY = "Kentucky",
  LA = "Louisiana",
  ME = "Maine",
  MD = "Maryland",
  MA = "Massachusetts",
  MI = "Michigan",
  MN = "Minnesota",
  MS = "Mississippi",
  MO = "Missouri",
  MT = "Montana",
  NE = "Nebraska",
  NV = "Nevada",
  NH = "New Hampshire",
  NJ = "New Jersey",
  NM = "New Mexico",
  NY = "New York",
  NC = "North Carolina",
  ND = "North Dakota",
  OH = "Ohio",
  OK = "Oklahoma",
  OR = "Oregon",
  PA = "Pennsylvania",
  RI = "Rhode Island",
  SC = "South Carolina",
  SD = "South Dakota",
  TN = "Tennessee",
  TX = "Texas",
  UT = "Utah",
  VT = "Vermont",
  VA = "Virginia",
  WA = "Washington",
  DC = "Washington DC",
  WV = "West Virginia",
  WI = "Wisconsin",
  WY = "Wyoming"
}

export enum CanadianProvinceOptions {
  AB = "Alberta",
  BC = "British Columbia",
  MB = "Manitoba",
  NB = "New Brunswick",
  NL = "Newfoundland and Labrador",
  NT = "Northwest Territories",
  NS = "Nova Scotia",
  NU = "Nunavut",
  ON = "Ontario",
  PE = "Prince Edward Island",
  QC = "Quebec",
  SK = "Saskatchewan",
  YT = "Yukon"
}

export enum UsStatePostal {
  AL = "AL",
  AK = "AK",
  AZ = "AZ",
  AR = "AR",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DE = "DE",
  FL = "FL",
  GA = "GA",
  HI = "HI",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  IA = "IA",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  ME = "ME",
  MD = "MD",
  MA = "MA",
  MI = "MI",
  MN = "MN",
  MS = "MS",
  MO = "MO",
  MT = "MT",
  NE = "NE",
  NV = "NV",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NY = "NY",
  NC = "NC",
  ND = "ND",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VT = "VT",
  VA = "VA",
  WA = "WA",
  DC = "DC",
  WV = "WV",
  WI = "WI",
  WY = "WY"
}
