import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AppState } from "store/app-state";
import { ProvinceService } from "services/province.service";
import * as ProvinceActions from 'store/province/province.actions';
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import * as BranchSelectors from 'store/branch/branch.selectors';

@Injectable()
export class ProvinceEffects {
  constructor(
    private action$: Actions,
    private provinceService: ProvinceService,
    private store: Store<AppState>
  ) { }

  loadProvinces$ = createEffect(() => this.action$.pipe(
    ofType(ProvinceActions.provincesLoad),
    withLatestFrom(this.store.select(BranchSelectors.selectedBranch)),
    switchMap(([_, userBranch]) =>
      this.provinceService.getProvincesByCountry(userBranch?.country).pipe(
        map(data => ProvinceActions.provincesLoadSuccess({ provinces: data })),
        catchError(async (error) => ProvinceActions.provincesLoadFailure({ error }))
      )
    )));
}
