import { createAction, props } from '@ngrx/store';

export const addedItemNote = createAction(
  '[Line Item Notes Modal Component] Added Item Note',
  props<{ itemId: string }>()
);

export const removedItemNote = createAction(
  '[Line Item Notes Modal Component] Removed Item Note',
  props<{ itemId: string }>()
);
