<a id="partCardHeader_a_PartDetailLink{{idSuffix}}" [routerLink]="['/parts']" [queryParams]="{searchTerm:part.rushPartNumber,partId:part.partId, src:partType}">
  <h4 class="product-card-title m-0 px-3 py-2 d-flex justify-content-between">
    <span class="strong text-truncate" title="{{part.rushPartNumber}}">
      {{part.rushPartNumber}}
    </span>
    <div>
      <span id="partCardHeader_span_IsGrowthIcon{{idSuffix}}" title="Growth" *ngIf="isGrowth$ | async"><i class="fas fa-chart-line"></i>&nbsp;&nbsp;</span>
      <span id="partCardHeader_span_IsRecoveryIcon{{idSuffix}}" title="Recovery" *ngIf="isRecovery$ | async"><i class="fas fa-redo-alt"></i>&nbsp;&nbsp;</span>
      <a id="partCardHeader_a_IsFavoritePart{{idSuffix}}" *ngIf="isFavoritePart" role="button" href="javascript:void(0)" title="Remove from Favorites"
        (click)="removeFavoritePart(part); $event.stopPropagation()">
        <i class="fas fa-star"></i>
      </a>
      <a id="partCardHeader_a_IsNotFavoritePart{{idSuffix}}" *ngIf="!isFavoritePart" role="button" href="javascript:void(0)" title="Add to Favorites"
        (click)="addToFavoriteParts(part); $event.stopPropagation()">
        <i class="far fa-star"></i>
      </a>
      &nbsp;
      <a id="partCardHeader_a_ShowCopyIcon{{idSuffix}}" *ngIf="showCopyIcon" role="button" href="javascript:void(0)" title="Copy Part Number to Clipboard"
        (click)="copyToClipBoard(part.rushPartNumber); $event.stopPropagation()">
        <i class="far fa-clone"></i>
      </a>
    </div>
  </h4>
</a>
