<div class="modal-body m-3">
  <div class="title-of-box-hold float-start">

    <div class="title-of-box pull-left" style="font-size: 130%;">Front Counter Self Pick</div>
  </div>
  <form #ngForm="ngForm">
    <table class="table table-hover table-responsive-xl table-striped">
      <thead>
        <tr class="w-100">
          <th scope="col">
            Part Number
          </th>
          <th scope="col">
            Quantity
          </th>
          <th scope="col" class="text-center">
            Storage Type/Bin/QtyAvail
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let lineItem of (binLocations$ | async | keyvalue); index as i;">
          <td class="text-truncate" title="{{lineItem.partNumber}}">
            {{lineItem.key}}
          </td>
          <td>
            {{lineItem.value.quantity}}
          </td>
          <td class="text-center">
            <div ngbDropdown class="d-inline-block">
              <button class="btn btn-primary" id="dropdownBasic1" ngbDropdownToggle>{{!lineItem.value.selectedBin? "Select Bin": lineItem.value.selectedBin}}</button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <table>
                  <button class="select-bin" ngbDropdownItem (click)="binSelected(lineItem.value, item.binLocation, item.storageType);" *ngFor="let item of lineItem.value.bins">
                      <td class="col">{{item.binLocation}}</td>
                      <td class="col">{{item.storageType}}</td>
                      <td class="col">{{item.quantityAvailable}}</td>
                  </button>
                </table>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </form>
</div>


<div class="modal-footer">
  <div class="d-block w-100">
    <button type="button" class="btn btn-default yellow-btn margin-r-5 pull-right" (click)="closeModal();">
      Continue
    </button>
  </div>
</div>
