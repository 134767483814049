<div class="modal-header">
</div>
<div class="modal-body">
    <div id="PopoverMessageModal_div_body">
        <h2 class="m-3">{{message}}</h2>
        <table class="bg-light w-100">
            <thead>
                <tr>
                    <th class="text-strong">Part Number</th>
                    <th class="text-strong">Item Description</th>
                    <th class="text-strong">Quantity</th>
                  </tr>
            </thead>
            <tbody class="bg-white">
                <tr id="popoverMessageModal_tr_lineItem_{{i}}" *ngFor="let item of orderDetailsLineItems; index as i">
                    <td>{{ item.partNumber }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.quantity | number:'.0' }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer justify-content-center">
      <button id="PopoverMessageModal_Button_Yes" type="submit" class="btn btn-primary" (click)="selectYes()">Yes</button>
      <button id="PopoverMessageModal_Button_No" type="submit" class="btn btn-primary" (click)="selectNo()">No</button>
</div>
