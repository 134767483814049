<form [id]="'branch_form_Branch' + id" #branchForm="ngForm" class="needs-validation" style="height: calc(2.25rem + 2px);" [ngClass]="{ 'was-validated': !branchForm.pending }">
  <div class="input-group" #branchSelectionGroup>
    <div class="position-relative d-block w-100" (keydown)="onBranchKeydown($event, branchSelectionGroup)">
      <input [id]="'branch_text_Branch' + id" class="form-control w-100 position-absolute" type="text" name="branch_text_Branch"
        placeholder="Find My Branch" [ngModel]="branchValue" (focus)="selectAllContent($event.target)" (blur)="onBlur()"
        requiredProperty="code" [ngbTypeahead]="branchSearch" [resultFormatter]="displayBranch"
        [inputFormatter]="displayBranch" (selectItem)="onBranchChange($event)" [disabled]="disabled"/>
      <span [id]="'branch_span_IsSearching' + id" class="w-100 position-absolute text-end" style="padding-right: .75rem; padding-top: .375rem; pointer-events: none;">
        <i class="fas fa-spinner fa-pulse" *ngIf="isSearching"></i>
      </span>
    </div>
  </div>
</form>
