import { Injectable } from '@angular/core';
import { Branch } from 'entities/branch';
import { Customer } from 'entities/customer';
import { Observable, merge, combineLatest } from 'rxjs';
import { filter, map, mapTo, mergeMap, scan, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CreateCart } from 'entities/cart-search';
import { CartService } from 'services/cart.service';
import { CommonDataService } from 'services/common-data.service';
import { TableService } from 'services/table.service';
import { addBasketToCart } from "store/basket/basket.actions";
import { Store } from '@ngrx/store';
import { AppState } from 'store/app-state';
import { Router } from '@angular/router';
import { Pager } from "entities/pager";

export type OpenCartAction =
  | { type: 'create', addItemsToCart: any }
  | { type: 'setOpenCarts', openCarts: any[] }
  | { type: 'getOpenCarts'}
  | { type: 'selectCart', cart: any }
  | { type: 'setFilterByCustomer', filterByCustomer: boolean}
  | { type: 'setFilterByBranch', filterByBranch: boolean}
  | { type: 'setPage', page: number};

export interface OpenCartState {
    carts: any[];
    filterByCustomer: boolean;
    filterByBranch: boolean;
    loading: boolean;
    page: number;
}

export type OpenCartSelectors = {
  createCart$: Observable<OpenCartAction>;
  filteredOpenCarts$: Observable<any[]>;
  filterByBranch$: Observable<boolean>;
  filterByCustomer$: Observable<boolean>;
  anyOpenCarts$: Observable<boolean>;
  anyDefaultCustomerOpenCarts$: Observable<boolean>;
  loading$: Observable<boolean>;
  pager$: Observable<Pager>;
  filters$: Observable<{
    byCustomer: boolean;
    byBranch: boolean;
  }>;
};

@Injectable()
export class OpenCartComponentService {
  private readonly initialState: OpenCartState = {
    carts: [],
    filterByCustomer: false,
    filterByBranch: false,
    loading: false,
    page: 1
  };

  constructor (
    private store: Store<AppState>,
    private cartService: CartService,
    private commonDataService: CommonDataService,
    private router: Router,
    private tableService: TableService
  ) { }

  getSelectors(actions$: Observable<OpenCartAction>): OpenCartSelectors {
    // Effects
    const createCart$: Observable<OpenCartAction> = actions$
    .pipe(
      filter((action) => action.type === 'create'),
      withLatestFrom(this.commonDataService.branch$, this.commonDataService.customer$),
      switchMap(([createCartAction, branch, customer]) => {
        const itemsToAdd: any = createCartAction;
        return this.cartService.getOrCreateCart({
          branchCode: branch.code,
          customerNumber: customer.customerNumber,
          forceCreate: true
        }).pipe(
          map((createCartRes) => {
            if (itemsToAdd?.addItemsToCart?.baskets?.length) {
              itemsToAdd.addItemsToCart.cartId = createCartRes.cartId;
              this.store.dispatch(
                addBasketToCart(
                  itemsToAdd.addItemsToCart));
                  this.router.navigate(["/cart"]);
                }
              return ({
                branchCode: branch.code,
                customerNumber: customer.customerNumber,
                forceCreate: true
              });

          })
        );
      }),
      mapTo({ type: 'getOpenCarts' })
    );

    const CartChange$: Observable<OpenCartAction> = this.cartService.cartUpdate$
          .pipe(
            mapTo({ type: 'getOpenCarts' })
    );

    const openCarts$: Observable<OpenCartAction> = merge(actions$, createCart$, CartChange$)
      .pipe(
        filter((action) => action.type === 'getOpenCarts'),
        switchMap(() => this.cartService.getOpenCarts()),
        map((result) => ({ type: 'setOpenCarts', openCarts: result}))
      );

    // Reducers
    const state$ = merge(actions$, openCarts$)
      .pipe(
        scan(
          (state, action) => {
            switch (action.type) {
              case 'getOpenCarts':
                return { ...state, loading: true };
              case 'setOpenCarts':
                return { ...state, carts: action.openCarts, loading: false, page: 1 };
              case 'setFilterByCustomer':
                return { ...state, filterByCustomer: action.filterByCustomer, page: 1 };
              case 'setFilterByBranch':
                return { ...state, filterByBranch: action.filterByBranch, page: 1 };
              case 'setPage':
                return { ...state, page: action.page };
              default:
                return { ...state };
            }
          },
          this.initialState
        ),
        shareReplay(1)
      );

    const getFilteredOpenCarts = (state: OpenCartState, branch: Branch, customer: Customer) => state.carts
    .filter((cart) => !state.filterByBranch || cart.branchCode === branch.code)
    .filter((cart) => !state.filterByCustomer || cart.customerNumber === customer.customerNumber);

    const filteredOpenCarts$ = combineLatest([state$, this.commonDataService.branch$, this.commonDataService.customer$])
      .pipe(
        map(([state, branch, customer]) => {
          const filteredOpenCarts = getFilteredOpenCarts(state, branch, customer);
          const pager = this.tableService.getPager(filteredOpenCarts?.length, state.page, 10);
          return filteredOpenCarts.slice(pager.startIndex, pager.endIndex + 1);
        })
      );

    const filterByBranch$ = state$
      .pipe(
        map((state) => state.filterByBranch)
      );

    const filterByCustomer$ = state$
      .pipe(
        map((state) => state.filterByCustomer)
      );

    const anyOpenCarts$ = state$
      .pipe(
        map((state) => Boolean(state.carts?.length))
      );

    const anyDefaultCustomerOpenCarts$ = state$
      .pipe(
        map((state) => Boolean(state.carts.find(c => c.customerNumber === this.commonDataService.DefaultCustomer.customerNumber)))
      );

    const loading$ = state$
        .pipe(
          map((state) => state.loading)
        );

    const pager$ = combineLatest([state$, this.commonDataService.branch$, this.commonDataService.customer$])
        .pipe(
          map(([state, branch, customer]) => {
            const filteredOpenCarts = getFilteredOpenCarts(state, branch, customer);
            return this.tableService.getPager(filteredOpenCarts?.length, state.page, 10);
          })
        );

    const filters$ = state$
        .pipe(
          map((state) => {
            return { byCustomer: state.filterByCustomer, byBranch: state.filterByBranch };
          })
        );

    return {
      createCart$,
      filteredOpenCarts$,
      filterByBranch$,
      filterByCustomer$,
      anyOpenCarts$,
      anyDefaultCustomerOpenCarts$,
      loading$,
      pager$,
      filters$
    };
  }

}


