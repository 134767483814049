import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AppState } from 'store/app-state';
import * as BranchSelectors from 'store/branch/branch.selectors';

@Component({
  selector: 'my-dashboard',
  templateUrl: './my-dashboard.component.html',
  styleUrls: ['./my-dashboard.component.scss']
})
export class MyDashboardComponent implements OnDestroy {
  private subscription: Subscription;
  branchCode$ = this.store.select(BranchSelectors.selectedBranch).pipe(map(x => x.code));

  constructor(
    private store: Store<AppState>,
    public activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    const setDefaultFragument$: Observable<boolean> = this.activatedRoute.fragment
      .pipe(
        filter((fragment) => !fragment),
        switchMap(() => this.router.navigate(['.'], { relativeTo: this.activatedRoute, fragment: 'open' }))
      );

    this.subscription = setDefaultFragument$.subscribe();
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
}
