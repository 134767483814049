import { Action, createReducer, on } from "@ngrx/store";
import * as CartActions from "../cart/cart.actions";
import * as ConfigurationActions from '../configuration/configuration.actions';
import * as BranchActions from "./branch.actions";
import { BranchState } from "./branch.state";


export const initialState: BranchState = {
  selectedBranch: {
    id: null,
    allowDelivery: false,
    allowPickUp: false,
    brands: [],
    code: null,
    defaultDeliveryFee: null,
    displayName: null,
    isActive: false,
    isDeliveryNotificationsEnabled: false,
    isWarehouseManaged: false,
    name: null,
    notificationEmailAddresses: null,
    country: null,
    street: null,
    city: null,
    state: null,
    zip: null,
    phone: null,
    currencyCode: null
  },
  branchesForOrdering: []
};

export const _branchReducer = createReducer<BranchState, Action>(
  initialState,
  on(ConfigurationActions.loadCacheSuccess, (state, {appstate}) => (appstate?.branch != null) ? {...appstate.branch} : {...initialState}),
  on(BranchActions.getBranchesSuccess, (state, { branches }) => ({
    ...state,
    branchesForOrdering: branches
  })),
  on(
    BranchActions.selectBranchForQuote,
    BranchActions.selectBranch, (state, { branch }) => {
    return ({
      ...state,
      selectedBranch: {...branch}
    });
  }),
  on(CartActions.selectCartBranchSuccess, (state, {branch}) => ({
    ...state,
    selectedBranch: {...branch}
  }))
);

export function branchReducer(state: BranchState | undefined, action: Action) {
  return _branchReducer(state, action);
}
