import { NgModule, InjectionToken, ModuleWithProviders } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { UserManagerSettings } from "oidc-client";
import { AuthenticationInterceptor, AuthenticationInterceptorOptions } from "./authentication.interceptor";
import { AuthenticationServiceOptions } from "./authentication.service";
import { AuthenticationGuardOptions } from "./authentication.guard";
import { AudienceAuthenticationInterceptor, AudienceAuthenticationInterceptorOptions } from "./audience-authentication.interceptor";

@NgModule()
export class AuthenticationModule {
  static forRoot(options: AuthenticationModuleOptions):  ModuleWithProviders<AuthenticationModule> {
    return ({
      ngModule: AuthenticationModule,
      providers: [
        { provide: AUTHENTICATION_MODULE_OPTIONS_TOKEN, useValue: options },
        { provide: AuthenticationGuardOptions, useFactory: provideAuthenticationGuardOptions, deps: [ AUTHENTICATION_MODULE_OPTIONS_TOKEN ] },
        { provide: AuthenticationInterceptorOptions, useFactory: provideAuthenticationInterceptorOptions, deps: [ AUTHENTICATION_MODULE_OPTIONS_TOKEN ] },
        { provide: AudienceAuthenticationInterceptorOptions, useFactory: provideAudienceAuthenticationInterceptorOptions, deps: [ AUTHENTICATION_MODULE_OPTIONS_TOKEN ] },
        { provide: AuthenticationServiceOptions, useFactory: provideAuthenticationServiceOptions, deps: [ AUTHENTICATION_MODULE_OPTIONS_TOKEN ] },
        { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AudienceAuthenticationInterceptor, multi: true }
      ]
    })
  }
}

export interface AuthenticationModuleOptions {
  oidcSettingsFactory: () => UserManagerSettings;
  policies?: { signInSignUp: string, editProfile?: string, resetPassword?: string, redeemInvitation?: string };
  scopes?: string[];
  defaultApiDomain?: string;
  audiences?:
  {
    domain: string;
    scopes: string[];
  }[];
  unauthenticatedAction: "login" | "redirect" | "stop";
  unauthenticatedRedirectUrl?: string | null;
  postSingleLogoutRedirectUrl?: string | null;
}

export const AUTHENTICATION_MODULE_OPTIONS_TOKEN = new InjectionToken<AuthenticationModuleOptions>("forRoot() AuthenticationModule configuration");

export function provideAuthenticationServiceOptions(options: AuthenticationModuleOptions): AuthenticationServiceOptions {
  return {
    settings: options.oidcSettingsFactory(),
    policies: options.policies,
    scopes: options.scopes,
    audiences: options.audiences,
    postSingleLogoutRedirectUrl: options.postSingleLogoutRedirectUrl
  };
}

export function provideAuthenticationGuardOptions(options: AuthenticationModuleOptions): AuthenticationGuardOptions {
  return {
    action: options.unauthenticatedAction,
    redirectUrl: options.unauthenticatedRedirectUrl
  };
}

export function provideAuthenticationInterceptorOptions(options: AuthenticationModuleOptions): AuthenticationInterceptorOptions {
  return {
    defaultDomain: options.defaultApiDomain
  };
}

export function provideAudienceAuthenticationInterceptorOptions(options: AuthenticationModuleOptions): AudienceAuthenticationInterceptorOptions {
  return {
    audienceDomains: options.audiences && options.audiences.map((audience) => audience.domain)
  };
}
