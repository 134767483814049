<div class="col-md-12">
  <div class="dashboard-content ">
    <div class="title-of-box-hold">
      <div class="title-of-box pull-left">Favorite Parts</div>
    </div>
    <div class="col md-1 pull-left" style="font-weight: 600; padding-left: 4px">
    <div id="FavoritePartsModal_Div_LoadingFavoriteParts" *ngIf="!partList" >Loading Favorite Parts...
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <div id="FavoritePartsModal_Div_NoFavoritePartsLoaded" *ngIf="unfilteredParts.length == 0">Your Favorite Parts list is empty. &nbsp; To add a part to your Favorite Parts list, simply search, and click the "star" above the image.</div>
    </div>
    <div class="open-items-container">
      <div class="row me-0 ms-0 p-4">
        <div class="col md-3">
          <form id="favoritesSearch_form_Search" #searchForm="ngForm" class="needs-validation"
            [ngClass]="{ 'was-validated': !searchForm.pending }">
            <div class="form-group">
              <div class="input-group">
                <input id="favoritesSearch_text_SearchTerm" type="text" class="form-control"
                  name="favoritesSearch_text_SearchTerm" aria-describedby="txtSearchTerm" onfocus="this.select();"
                  #txtSearchTerm required [(ngModel)]="searchTerm" (keyup)="onSearchTermKeypress($event)" [ngbTypeahead]="autocomplete"
                  (selectItem)="selectSearchTerm($event)" placeholder="Search Favorite Parts">
                  <a (click)="partSearch()" href="javascript:void(0)" class="input-group-text">
                    <i class="fas fa-search" aria-hidden="true"></i>
                  </a>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-6 float-none">
          <div *ngIf="partList && partList.length >= 1" class="col-md-4 float-end">
            Select All
            <input id="FavoritePartsModal_CheckBox_SelectAll" type="checkbox" [(ngModel)]="selectAllFavoritePart"
              name="favoritePartToggle22" value="option" (change)="toggleSelectFavoritePart(selectAllFavoritePart)" />
            <label for="FavoritePartsModal_CheckBox_SelectAll"></label>
          </div>
        </div>

        <div class="col md-3">
          <button id="FavoritePartsModal_Button_AddToCart" class="btn btn-primary mt-6 ms-1 me-1 mb-1"
            *ngIf="partList && partList.length >= 1" [disabled]="!selectedFavoriteParts.length" (click)="addToCart()"> Add
            To Cart</button>
        </div>
      </div>
    </div>
    <div class="row heading-search me-0 ms-0">
      <div id="FavoritePartsModal_Div_SearchFavoritePartsNotFound"
        class="row me-0 ms-0 p-4"
        *ngIf="unfilteredParts && unfilteredParts.length > 0 && partList && partList.length == 0"
      >
        No records found
      </div>
      <div id="FavoritePartsModal_Div_Part_{{i}}" class="col-md-8" *ngFor="let part of partList; let i = index">
        <div class="favorite-part-box-wrapper p-4">
          <div class="product-card border rounded-lg row g-0 bg-white ">
            <div class="col-sm-12 col-md-5 col-lg-5">
              <a id="FavoritePartsModal_A_LinkToPartDetails_{{i}}" [routerLink]="['/parts']" [queryParams]="{searchTerm:part.partNo, partId:part.partId}">
                <h4 class="product-card-title m-0 px-3 py-2 d-flex justify-content-between" #productCardTitl>
                  <span class="strong">
                    {{part.partNo}}
                  </span>
                  <div>
                    <a id="FavoritePartsModal_A_RemoveFromFavorites_{{i}}" role="button" href="javascript:void(0)" title="Remove from Favorites"
                      (click)="removeFromFavorites(part); $event.stopPropagation()">
                      <i class="fas fa-star"></i>
                    </a>
                    &nbsp;
                    <a id="FavoritePartsModal_A_CopyToClipBoard_{{i}}" role="button" href="javascript:void(0)" title="Copy Part Number to Clipboard"
                      (click)="copyToClipBoard(part.partNo); $event.stopPropagation()">
                      <i class="far fa-clone"></i>
                    </a>
                  </div>
                </h4>
                <div class="product-image product-image-md" tabindex="-1">
                    <img [src]="getImage(part.image, imageBaseUrl, '100X100')" [default]="'assets/img/100X100_No_Image.png'" tabindex="-1" alt="No Image" >
                </div>
              </a>
            </div>
            <div class="col-sm-12 col-md-7 col-lg-7 border-start">
              <div class="border-bottom  d-flex align-items-right text-truncate p-2">

                <div class="f-f-box">
                  <input id="FavoritePartsModal_CheckBox_FavoritePart_{{i}}" type="checkbox" class="favoritePart-checkBox float-end" name="field"
                         value="option" [(ngModel)]="part.selected"
                         (change)="onFavouritepartItemSelectionChange(part,part.selected)">
                  <label for="FavoritePartsModal_CheckBox_FavoritePart_{{i}}"></label>
                </div>

              </div>
              <div>
                <div class="p-3 row">
                  <div class="col-6">
                    <b>VMRS Description</b>
                    <div class="text-truncate" title="{{part.vmrsDescription}}">{{part.vmrsDescription}}</div>
                  </div>
                  <div class="col-6">
                    <b>VMRS Code</b>
                    <div>{{part.vmrsCode}}</div>
                  </div>
                </div>
                <div class="p-3 row">
                  <div *ngIf="part.corePart" class="col-6">
                    <b>Core No.</b>
                    <div id="FavoritePartsModal_CorePart_{{i}}">{{part.corePart}}</div>
                  </div>
                  <div class="col-6">
                    <b>Item Summary</b>
                    <div>{{part.partDescription}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 border-top p-2 d-flex justify-content-center" *ngIf="true && part.isRushPart">
              <a id="FavoritePartsModal_NationalInventoryLink_{{i}}" role="button" class="hyperlink" href="javascript:void(0)"
                (click)="openNationalInventory(part)">National Inventory</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
