import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as ConfigurationSelectors from "store/configuration/configuration.selectors";
import { CustomerState } from "./customer.state";

export const selectCustomerState = createFeatureSelector< CustomerState>('customer');

export const selectFindCustomerResults = createSelector(
  selectCustomerState,
  (state) => state.findCustomerResults
);

export const selectedCustomer = createSelector(
  selectCustomerState,
  (state) => state.selectedCustomer
);

export const isDefaultCustomer = createSelector(
  selectedCustomer,
  ConfigurationSelectors.selectCustomer,
  (customer, defaultCustomer) => customer?.customerNumber === defaultCustomer?.customerNumber
);

export const isCustomerSelected = createSelector(
  selectCustomerState,
  (state) => state.selectedCustomer.customerNumber != "" && state.selectedCustomer.customerNumber != null
);

export const selectPayers = createSelector(
  selectCustomerState,
  (state) => state.selectedPayers
);

export const selectStoredPaymentMethods= createSelector(
  selectCustomerState,
  (state) => state.storedPaymentMethods
);

export const selectedPayer = createSelector(
  selectCustomerState,
  (state) => {
    const selectedCustomer = state.selectedPayers.some(x => x.isDefault)?
      state.selectedPayers.find(x => x.isDefault): state.selectedPayers.find(x => x.customerNumber === state.selectedCustomer.customerNumber);
    return selectedCustomer;
  }
);

export const selectShipToLoading = createSelector(
  selectCustomerState,
  (state) => state.shipToIsLoading
);

export const selectShipToCustomers = createSelector(
  selectCustomerState,
  (state) => state.shipToCustomers
);

export const selectShipTo = createSelector(
  selectCustomerState,
  (state) => {
    const selectedShipToValid = [
      state.selectedCustomer.streetAddress,
      state.selectedCustomer.city,
      state.selectedCustomer.state,
      state.selectedCustomer.postalCode,
      state.selectedCustomer.phoneNumber,
      state.selectedCustomer.mscAccountNumber
    ].every(x => x !== null && x !== undefined && x !== "");
    if(!selectedShipToValid && state.shipToCustomers.length === 1){
      return state.shipToCustomers[0];
    }
    return state.selectedShipTo ?? (state.shipToCustomers.length === 1? state.shipToCustomers[0]: null);
  }
);

export const selectShipToEditing = createSelector(
  selectCustomerState,
  (state) => state.shipToEditing
);

export const selectFavoriteCustomers = createSelector(
  selectCustomerState,
  (state) => state.favoriteCustomers
)

export const isSelectedCustomerFavorite = createSelector(
  selectCustomerState,
  (state) => state.favoriteCustomers.some((customer) => customer.customerNumber === state.selectedCustomer?.customerNumber)
);

export const selectIsMSCPayer = createSelector(
  selectCustomerState,
  (state) => state.selectedCustomer?.mscAccountNumber != null && state.selectedCustomer?.mscAccountNumber != ""
);

export const selectIsShowAddCreditCard = createSelector(
  selectCustomerState,
  (state) => state.isShowingAddCreditCard
);

export const isAddedCreditCard = createSelector(
  selectCustomerState,
  (state) => state.isAddedPaymentMethod
);

export const isInviteToPay = createSelector(
  selectCustomerState,
  (state) => state.isInviteToPay
);

export const isSelectedCreditCard = createSelector(
  selectCustomerState,
  (state) => state.storedPaymentMethod !== null
);

export const storedPaymentMethod = createSelector(
  selectCustomerState,
  (state) => state.storedPaymentMethod
);

export const customerState = createSelector(
  selectCustomerState,
  (state) => state
);

export const selectedPaymentMethod = createSelector(
  selectCustomerState,
  (state) => state.selectedPaymentMethod
);

export const inviteToPayType = createSelector(
  selectCustomerState,
  (state) => state.inviteToPayType
);

export const inviteToPayEmail = createSelector(
  selectCustomerState,
  (state) => state.inviteToPayEmail
);

export const inviteToPaySms = createSelector(
  selectCustomerState,
  (state) => state.inviteToPaySms
);

export const selectIsMscOrFleetCustomer = createSelector(
  selectCustomerState,
  (state) =>  state.selectedCustomer.mscPayer !== null
);

export const selectMscPayer = createSelector(
  selectCustomerState,
  (state) =>  state.selectedCustomer.mscPayer
);

export const selectInviteToPayResendLoading = createSelector(
  selectCustomerState,
  (state) => state.inviteToPayResendLoading
);

export const selectCustomerSelectedShipTo = createSelector(
  selectCustomerState,
  (state) => state.selectedShipTo
);
