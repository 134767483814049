import { Component, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-processing-payment-failed-modal-component',
  templateUrl: './processing-payment-failed-modal-component.component.html',
  styleUrls: ['./processing-payment-failed-modal-component.component.scss']
})
export class ProcessingPaymentFailedModalComponent {

  constructor(private activeModal: NgbActiveModal) { }

  @Output() resultType = '';

  public closeModal(): void {
    this.resultType = 'canceled';
    this.activeModal.dismiss();
  }

  public chooseAnotherDevice() : void  {
    this.resultType = 'chooseAnotherDevice';
    this.activeModal.close('chooseAnotherDevice');
  }

  public retry(): void {
    this.resultType = 'retry';
    this.activeModal.close('retry');
  }

}
