import { AutoCompleteResult } from "app/_components/autocomplete/autocomplete.entities";
import { Branch } from "entities/branch";

export interface BackCounterRequest {
  id: string;
  creationDate: string;
  open: string;
  priority: string;
  type: string;
  customer: string;
  job: string;
  jobDescription: string;
  assignedto: string;
  assignedtoname: string;
  oem: string;
  mobileunit?: string;
  mobile?: string;
  notes: any[];
  complete: string;
  isCustomerWaiting: string;
  dept: string;
  branchCode: string;
  orderNumber: string;
  requestor?: string;
  requestorName?: string;
  reqType: string;
  refordernum: string;
  status: BackCounterRequestStatus;
}

export enum BackCounterRequestStatus {
  open = "1",
  inProgress = "2",
  cancelled = "8",
  closed = "9",
  empty = ""
}

export interface BackCounterDashboardPage {
  summaryList: BackCounterRequest[];
  totalCount: number;
}

export interface SortColumn {
  by: string;
  ascending: boolean;
}

export interface BackCounterRequestsComponentState {
  branch: Branch;
  filterByBranches: string[];
  filterByDepartment: BackCounterRequestDepartment;
  searchTerm: string;
  pageNumber: number;
  sort: SortColumn | null;
  items: BackCounterRequest[];
  totalItems: number;
  loadingOverview: boolean;
  overview: BackCounterRequestsOverview;
  filters: BackCounterRequestsFilter[];
  unreadMessages: { [key: string]: number };
}

export enum BackCounterRequestDepartment {
  service = "12",
  bodyshop = "14"
}

export interface BackCounterRequestPayload {
  branchCodes?: string[];
  orderType?: string[];
  priority?: string;
  requestType?: string;
  orderNumbers?: string[];
  customerNumbers?: string[];
  technicians?: string[];
  oeMs?: string[];
  status?: string;
  isMobile?: boolean;
  assignedTo?: string;
  pageNumber: number;
  rangeEnd?: string;
  rangeStart?: string;
  orderByField?: string;
  orderByDirection?: string;
  departments?: string[];
  searchTerm?: string;
  sort?: SortColumn;
}

export interface BackCounterSummaryOfRequestsPayload {
  branchCodes: string[];
  department?: string;
  oeMs?: string[];
  rangeEnd: string;
  rangeStart: string;
}

export interface BackCounterRequestsFilter {
  prop: string;
  type: "text" | "select" | "date" | "multiselect" | "autocomplete";
  label?: string;
  active?: boolean;
  value?: any;
  options?: { label: string, value: any, id?: string, selected?: boolean }[];
  autocomplete?: { url: string, prop: string };
  mapValue?: Function;
  overwriteValue?: Function;
}

export interface BackCounterRequestsOverview {
  totalCount: number;
  quoteCount: number;
  orderCount: number;
  unassignedCount: number;
  inprocessCount: number;
  id: string;
  date: string;
  open: string;
  priority: string;
  type: string;
  order: string;
  customer: string;
  job: string;
  jobDescription: string;
  technician: string;
  oem: string;
  mobile: string;
  assignedTo: string;
  notes: RequestNote[];
  complete: boolean;
}

export interface BackCounterRequestDetails {
  priceVerifyRel: string;
  priceVerifyComp: string;
  isLocked?: boolean;
  prStatus?: string;
  prStatusdesc?: string;
  orderNumber: string;
  ordertogetitem: RequestOrderItem[];
  ordertogetjob: RequestJob[];
  attachments: RequestAttachment[];
  branch?: string;
  customerNo?: string;
  techName?: string;
  tagNo?: string;
  advisor?: string;
  customerPo?: string;
  vin?: string;
  custUnitNo?: string;
  rtlUnitNo?: string;
  license?: string;
  dateInvoice?: string;
  engineMnf?: string;
  transMnf?: string;
  fAxleMnf?: string;
  fDiffMnf?: string;
  rDiffMnf?: string;
  suspension?: string;
  starter?: string;
  clutch?: string;
  mileage?: string;
  vehicleYear?: string;
  makeModel?: string;
  vehicleType?: string;
  engineHrs?: string;
  engineMod?: string;
  transMod?: string;
  fAxleMod?: string;
  fDiffMod?: string;
  rDiffMod?: string;
  cplArrange?: string;
  alternator?: string;
  fanClutch?: string;
  primaryContact?: string;
  primaryPhone?: string;
  driverContact?: string;
  driverPhone?: string;
  engineSno?: string;
  transSno?: string;
  fAxle?: string;
  fDiffS?: string;
  rDiffS?: string;
  catCabType?: string;
  paint?: string;
  keyCode?: string;
  bayNo?: string;
  photoExists?: boolean;
  mobileUnit?: string;
}

export interface RequestOrderItem {
  frieght?: string;
  freight?: string;
  freightOverride?: string;
  itemCategory: string;
  itemNumber: string;
  jobNumber: string;
  materialNumber: string;
  orderQuantity: number;
  purchaseVendor: string|number;
  coreOption: string;
  corePrice?: number;
  unsavedChanges?: string;
  mainPrItem: string;
  manualPrice?: number;
  orderNumber?: string;
  deliveryDate?: string;
  orderUom?: string;
  manPrice?: string;
  _id?: string;
  noteExists?: string;
  itemNotifflag?: string;
  itemTotal?: number;
  manualFreightFlag?: string;
  freightInbound?: number;
  isPriceVerified?: boolean;
  priceVerifyRel?: string;
  priceVerifyComp?: string;
  purchasePrice?: number;
  rateUnit?: 'USD' | 'CAD';
  descr1?: string;
  itcat?: string;
  reqQuantity?: number;
  uom?: string;
  jobs?: string;
  ZzmainItem?: string;
  vendor?: string;
  cost?: string;
  pbCoreOption?: string;
  coreCustPrice?: number;
  corePurcPrice?: number;
  buyout?: string;
  material?: string;
  unitPrice?: number;
}

export interface RequestJob {
  jobNumber: string;
  jobDescription: string;
  warrantyind: string;
}

export interface RequestAttachment {
  itemNumber: string;
  fileName: string;
  mimetype: string;
}

export interface RequestNote {
  userId: string;
  techName: string;
  techNumber: string;
  noteText: string;
  date: string;
  time: string;
}

export interface BackCounterAutoCompleteResults {
  users?: BackCounterAutoCompleteResult[];
  technicians?:BackCounterAutoCompleteResult[];
  totalCount?: number;
}

export interface BackCounterAutoCompleteResult extends AutoCompleteResult {
  name: string;
  userid: string;
  werks: string;
}

export enum BackCounterRequestPartItemAction {
  add = "ADD",
  update = "UPDATE",
  remove = "REMOVE"
}

export interface SapEventMessage {
  type: string;
  requestId: string;
  noteId: string;
}

export enum RequestConnectionEvent {
  newNoteOnItem = "requestNoteItem",
  newNoteOnRequest = "requestNoteJob",
  newNoteOnGeneralRequest = "requestGeneral",
  updatedRequest = "request"
}

export interface RequestPartItemsUnsavedChanges {
  itemsToAdd: RequestOrderItem[];
  itemsToUpdate: RequestOrderItem[];
  itemsToRemove: RequestOrderItem[];
}

export interface GeneralRequest {
  orderNumber: string;
  noteText?: string;
  assignedTo?: string;
  priority?: string;
  branchCode?: string;
  department?: string;
}

export interface OpenRequestsAttachments {
  orderNumber: string;
  attachments: RequestAttachment[];
}
