import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CommonDataService } from 'services/common-data.service';
import { CartItemNoteType } from 'entities/cart-item-note-type';
import { CartResultLineNote } from 'entities/cart-result';
import { UpdateCartItemNotesRequest } from 'entities/update-cart-item-notes-request';
import { CartService } from 'services/cart.service';
import { ToastService } from 'services/toast.service';
import { ToastType } from 'entities/toast-type';
import { AppState } from 'store/app-state';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, mapTo, switchMap, tap } from 'rxjs/operators';
import { EMPTY, merge, Subject, Subscription } from 'rxjs';
import * as LineItemNotesModalComponentActions from './line-item-notes-modal.component.actions';

@Component({
  selector: 'line-item-notes-modal',
  templateUrl: './line-item-notes-modal.component.html'
})

export class LineItemNotesModalComponent implements OnInit, OnDestroy {

  @Input() cartItemId: string;
  @Input() partNumber: string;
  @Input() hasNotes: boolean;

  selected: CartItemNoteType = 'workshop';
  placeholderText = 'Add Workshop Text';
  existingNotes: CartResultLineNote[] = [];
  existingNoteTypes: string[] = [];
  cartItemNoteText: string = '';
  private getLineItemNotesSubject: Subject<string> = new Subject();
  private get$ = this.getLineItemNotesSubject
    .pipe(
      switchMap((cartItemId) => this.cartService.getCartItemNotes(cartItemId)),
      tap((notes) => {
        this.existingNotes = notes;
        if (this.existingNotes.length > 0) {
          this.cartItemNoteText = this.existingNotes[0].content;
          this.selected = this.existingNotes[0].noteType;
          this.existingNoteTypes = this.existingNotes.map(note => note.noteType);
        }
      })
    );

  private saveLineItemNotesSubject: Subject<UpdateCartItemNotesRequest> = new Subject();
  private save$ = this.saveLineItemNotesSubject
    .pipe(
      switchMap((data) => this.cartService.updateCartItemNotes(data)
        .pipe(
          catchError((error) => {
            this.toastService.showToast(`Notes could not be saved for line item with part number ${this.partNumber}`, ToastType.Error);
            return EMPTY;
          }),
          mapTo(data)
        )
      ),
      tap((data) => {
        this.toastService.showToast(`Notes saved successfully for line item with part number ${this.partNumber}`, ToastType.Success);
        const lineItemNote = this.existingNotes.find(item => item.noteType === data.cartItemNoteTypeId);
        if (!lineItemNote) {
          this.existingNotes.push({
            cartItemId: data.cartItemId,
            noteType: data.cartItemNoteTypeId,
            content: data.cartItemNoteText
          });
          this.existingNoteTypes.push(data.cartItemNoteTypeId);
          this.store.dispatch(LineItemNotesModalComponentActions.addedItemNote({ itemId: data.cartItemId }));
        } else {
          if (!data.cartItemNoteText) {
            this.existingNotes = this.existingNotes.filter(item => item.noteType !== this.selected);
            this.existingNoteTypes = this.existingNoteTypes.filter(item => item !== this.selected);
            this.store.dispatch(LineItemNotesModalComponentActions.removedItemNote({ itemId: data.cartItemId }));
          }
        }
      })
    );

    private subscription: Subscription = merge(this.get$, this.save$)
    .subscribe();

  constructor(
    private store: Store<AppState>,
    public commonDataService: CommonDataService,
    private cartService: CartService,
    private toastService: ToastService,
    private activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    if (this.hasNotes) {
      this.getLineItemNotesSubject.next(this.cartItemId);
    }
  }

  updateNoteTextField(noteType: string) {
    const lineItemNote = this.existingNotes.find(note => note.noteType === noteType);
    this.cartItemNoteText = lineItemNote ? lineItemNote.content : '';
  }

  saveLineItemNotes() {
    const data: UpdateCartItemNotesRequest = {
      cartItemId: this.cartItemId,
      cartItemNoteTypeId: this.selected,
      cartItemNoteText: this.cartItemNoteText
    };
    this.saveLineItemNotesSubject.next(data);
  }

  closeModal() {
    this.activeModal.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
